import { TAG_ACTION_TYPES, TAG_ACTIONS } from '../actions/tagActions';
import store from '../store';
import { APP_URL } from '../../constants';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';

const tagReducer = (state, action) => {
  const headers = HEADERS();
  switch (action.type) {
    case TAG_ACTION_TYPES.FETCH_DISCUSSION_TAGS:
      fetch(`${APP_URL}/tags/discussion_tags`, { method: 'get', headers }).then(response => {
        response.json().then(jsonResponse => {
          store.dispatch(TAG_ACTIONS.receiveDiscussionTags(jsonResponse));
        });
      });
      return state;
    case TAG_ACTION_TYPES.RECEIVE_DISCUSSION_TAGS:
      return {
        ...state,
        tagsDomain: {
          ...state.tagsDomain,
          discussionTags: action.payload.map(tag => tag.name),
        },
      };
    case TAG_ACTION_TYPES.FETCH_HASHTAGS:
      fetch(`${APP_URL}/tags/hashtags`, { method: 'get', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            store.dispatch(TAG_ACTIONS.receiveHashtags(jsonResponse));
          });
        });
      return state;
    case TAG_ACTION_TYPES.RECEIVE_HASHTAGS:
      return {
        ...state,
        tagsDomain: {
          ...state.tagsDomain,
          hashtags: action.payload,
        },
      };
    case TAG_ACTION_TYPES.FETCH_GOALS:
      fetch(`${APP_URL}/tags/goals`, { method: 'get', headers }).then(response => {
        response.json().then(jsonResponse => {
          store.dispatch(TAG_ACTIONS.receiveGoals(jsonResponse));
        });
      });
      return state;
    case TAG_ACTION_TYPES.RECEIVE_GOALS:
      return {
        ...state,
        tagsDomain: {
          ...state.tagsDomain,
          goals: action.payload,
        },
      };
    case TAG_ACTION_TYPES.FETCH_COMPANY_VALUES:
      fetch(`${APP_URL}/tags/values`, { method: 'get', headers }).then(response => {
        response.json().then(jsonResponse => {
          store.dispatch(TAG_ACTIONS.receiveCompanyValues(jsonResponse));
        });
      });
      return state;
    case TAG_ACTION_TYPES.RECEIVE_COMPANY_VALUES:
      return {
        ...state,
        tagsDomain: {
          ...state.tagsDomain,
          companyValues: action.payload,
        },
      };
    default:
      return state;
  }
};
export default tagReducer;
