import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import qs from 'qs';

import { toggleUserModal } from 'redux/app/actions';
import { BODY_CLASSES, HEADERS } from 'helpers';

import Header from '../partials/Header';
import OrganisationTable from '../partials/OrganisationTable';
import categories from '../categories';
import Page from '../../layouts/Page';
import fields from './fields';
import UsersSidebar from './UsersSidebar';
import { APP_URL } from '../../../constants';

const Users = ({ location, history, updateFlash, isMember, toggleUserModal }) => {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [filter, setFilter] = useState(queryParams.filter || 'active');
  const [page, setPage] = useState(queryParams.page || 1);
  const [searchTerm, setSearchTerm] = useState(queryParams.search_term || '');
  const [users, setUsers] = useState({ filter, rows: [], searchTerm, page });
  const [sort, setSort] = useState({
    order: queryParams.sort_order || 'asc',
    column: queryParams.sort_column || 'name',
  });
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    BODY_CLASSES();
  }, []);

  const handleSelectClick = (id, users) => {
    if (!isMember) {
      return setSelectedIndices(id);
    }
    return toggleUserModal(true, users[id[0]].id);
  };
  const fetchUsers = async () => {
    const headers = HEADERS();
    const params = {
      filter,
      page,
      search_term: searchTerm,
      sort_order: sort.order,
      sort_column: sort.column,
    };

    if (searchTerm !== users.searchTerm) {
      setPage(1);
      params.page = 1;
    }
    history.replace(`/users?${qs.stringify(params)}`);
    const response = await fetch(`${APP_URL}/users?${qs.stringify(params)}`, {
      method: 'get',
      headers,
    });
    const json = await response.json();
    const rows = json[filter].data.map(fields.rows(isMember)[filter]);
    setUsers({
      ...json,
      filter,
      rows,
      searchTerm,
      page,
    });
    setSelectedIndices([]);
    setLoading(false);
  };

  useEffect(() => {
    if (
      users[filter] &&
      users[filter].total === 0 &&
      (users.active.total !== 0 ||
        users.disabled.total !== 0 ||
        users.invited.total !== 0 ||
        users.notInvited.total !== 0)
    ) {
      const list = {
        active: users.active.total,
        disabled: users.disabled.total,
        invited: users.invited.total,
        notInvited: users.notInvited.total,
      };
      setFilter(Object.keys(list).reduce((a, b) => (list[a] > list[b] ? a : b)));
    }
  }, [users.searchTerm]);

  useEffect(() => {
    fetchUsers();
  }, [page, searchTerm, filter, sort.order, sort.column]);

  const handleFilter = () => debounce(setSearchTerm, 300);

  const handleSort = column => {
    switch (sort.order) {
      case 'desc':
        return setSort({
          column,
          order: 'asc',
        });
      case 'asc':
        return setSort({
          column: '',
          order: '',
        });
      case '':
      default:
        return setSort({
          column,
          order: 'desc',
        });
    }
  };

  const handleType = filter => {
    setPage(1);
    setFilter(filter);
  };

  return !loading ? (
    <>
      <Page.Wrapper>
        <Page.Content>
          <Header
            category={categories.USERS}
            count={users[users.filter].total}
            defaultFilter={searchTerm}
            handleFilter={handleFilter()}
          />
        </Page.Content>
        <Page.SidebarPlaceholder />
      </Page.Wrapper>
      <Page.Wrapper>
        <Page.Content>
          <OrganisationTable
            columns={fields.columns(isMember)[users.filter]}
            rows={users.rows}
            select={{
              multi: true,
              handleChange: index => handleSelectClick(index, users[filter].data),
              indices: selectedIndices,
              allowSelect: !isMember,
            }}
            pagination={{
              index: users.page,
              total: users[users.filter].last_page,
              handleChange: setPage,
            }}
            sort={{
              order: sort.order,
              handleChange: handleSort,
              column: sort.column,
            }}
          />
        </Page.Content>
        <Page.Sidebar>
          <UsersSidebar
            handleFilter={handleType}
            users={users}
            selectedUsers={users[filter].data.filter((user, index) =>
              selectedIndices.includes(index)
            )}
            updateFlash={updateFlash}
            reloadData={fetchUsers}
            filter={users.filter}
            isMember={isMember}
          />
        </Page.Sidebar>
      </Page.Wrapper>
    </>
  ) : null;
};

const mapStateToProps = state => ({
  isMember:
    !!state.usersDomain.user &&
    !state.usersDomain.user.isAccountAdmin &&
    !state.usersDomain.user.formalLeadTeamsIds.length > 0,
});

const mapDispatchToProps = dispatch => ({
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
