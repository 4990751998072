import React from 'react';
import moment from 'moment';
import { ReactComponent as Add } from 'pages/Grow/GrowProfile/add.svg';
import { clearActionBody, setAction, setShowActionModal } from 'redux/app/actions';
import { connect } from 'react-redux';

const GrowProfileVibeButton = ({
  userAlreadyVibed,
  plan,
  recordedNoteInLastTwoWeeks,
  setAction,
  setShowActionModal,
  clearActionBody,
  titleContent,
  VibeButtonContent,
  customAction,
}) => {
  const canVibe = () => !userAlreadyVibed && plan !== 'free';

  return (
    <VibeButtonContent
      onClick={e => {
        e.preventDefault();
        setShowActionModal(true);
        clearActionBody();
        if (plan === 'free') return;
        const currentDay = moment().isoWeekday();
        if (customAction) {
          return setAction(customAction);
        }
        if (canVibe() && (currentDay === 4 || currentDay === 5)) {
          return setAction('create-vibe');
        }
        if (!recordedNoteInLastTwoWeeks) {
          return setAction('create-note');
        }
      }}
    >
      <Add /> {titleContent && <span>{titleContent}</span>}
    </VibeButtonContent>
  );
};

const mapStateToProps = state => ({
  plan: state.plansDomain.plan,
  userAlreadyVibed: !!state.usersDomain.user && state.usersDomain.user.alreadyVibed,
  recordedNoteInLastTwoWeeks:
    state.usersDomain.user && state.usersDomain.user.recordedNoteInLastTwoWeeks,
});

const mapDispatch = {
  setShowActionModal,
  setAction,
  clearActionBody,
};

export default connect(mapStateToProps, mapDispatch)(GrowProfileVibeButton);
