export const MEETING_FREQUENCIES = [
  {
    value: 'oneoff',
    label: "Doesn't repeat",
  },
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'weekly',
    label: 'Weekly',
  },
  {
    value: 'fortnightly',
    label: 'Fortnightly',
  },
  {
    value: 'monthly',
    label: 'Monthly',
  },
];
