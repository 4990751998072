import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { downloadCsv, HEADERS } from 'helpers';
import { APP_URL } from 'constants.js';
import { connect } from 'react-redux';
import { USER_ACTIONS } from 'redux/actions/userActions';
import { toggleUserModal } from 'redux/app/actions';
import fetchApi from 'fetchApi';
import useCan from 'rbac/useCan';
import { general } from 'rbac/plans';
import { PLAN_ACTIONS } from 'redux/actions/planActions';
import AddNewDemoAccount from '../../shared/modals/AddNewDemoAccount';
import AddUsersModal from '../../shared/modals/AddUsersModal';
import ChangeBillingContactModal from '../../users/partials/ChangeBillingContactModal';
import MoveModal from './MoveModal';
import Sidebar from '../partials/Sidebar';

const UsersActions = ({
  history,
  selectedUsers,
  teams,
  updateFlash,
  reloadData,
  filter,
  changeUsersCapacity,
  toggleUserModal,
  allTeams,
  user,
  isAccountAdmin,
  isDemoAdmin,
  showUpgradeModal,
}) => {
  const [moveModalToggled, setMoveModalToggled] = useState(false);
  const [showUsersModal, setShowUsersModal] = useState(false);
  const [billingModalToggled, setBillingModalToggled] = useState(false);
  const [accountAdmins, setAccountAdmins] = useState([]);
  const [showNewDemoAccountModal, setShowNewDemoAccountModal] = useState(false);

  const bulkUpdate = (state, selectedTeam = null) => {
    const ids = [...selectedUsers].map(u => u.id);
    const headers = HEADERS();
    const data = new FormData();
    data.append('userIds', JSON.stringify(ids));
    data.append('commit', state);
    if (selectedTeam) {
      data.append('teamId', selectedTeam);
    }
    fetch(`${APP_URL}/users/bulk_update`, {
      headers,
      method: 'post',
      body: data,
    })
      .then(response => {
        if (response.status === 200 && response.ok === true) {
          response.json().then(jsonResponse => {
            if (jsonResponse.error) {
              let errorType = 'error';
              if (jsonResponse.error === 'change_billing_contact') {
                setAccountAdmins(jsonResponse.accountAdmins);
                setBillingModalToggled(true);
              }
              if (
                jsonResponse.error === 'disabling_logged_in_user' ||
                jsonResponse.error === 'disabling_users_failed'
              ) {
                if (jsonResponse.error === 'disabling_logged_in_user') {
                  errorType = 'warning';
                }
                reloadData();
                changeUsersCapacity(jsonResponse.isCapacityReached);
              }
              updateFlash(errorType, jsonResponse.message);
            } else {
              updateFlash('success', jsonResponse.message);
              reloadData();
              changeUsersCapacity(jsonResponse.isCapacityReached);
            }
          });
        }
      })
      .catch(() => {});
  };

  const handleChangeBillingContact = userId => {
    const data = new FormData();
    data.append('userId', userId);
    const headers = HEADERS();
    fetch(`${APP_URL}/change_billing_contact`, {
      headers,
      method: 'post',
      body: data,
    })
      .then(response => {
        if (response.status === 200 && response.ok === true) {
          response.json().then(jsonResponse => {
            if (jsonResponse.error) {
              if (jsonResponse.error === 'change_billing_contact') {
                setAccountAdmins(jsonResponse.accountAdmins);
                setBillingModalToggled(true);
              }
              updateFlash('error', jsonResponse.message);
            } else {
              updateFlash('success', jsonResponse.message);
              setBillingModalToggled(true);
              reloadData();
            }
          });
        }
      })
      .catch(() => {});
  };

  const addUsers = (users, message) => {
    reloadData();
    updateFlash('success', message);
  };

  const moveTo = team => {
    setMoveModalToggled(false);
    bulkUpdate('move-to', team);
  };

  const confirmAction = action => () => {
    if (window.confirm('Are you sure?')) {
      bulkUpdate(action);
    }
  };

  const sendInvites = async () => {
    const data = new FormData();
    data.append('_method', 'PUT');
    [...selectedUsers].forEach(user => data.append('user_ids[]', user.id));
    const response = await fetchApi(`/users/invite`, {
      method: 'post',
      body: data,
    });
    if (response.error) {
      updateFlash('error', response.error);
      return;
    }
    updateFlash('success', 'Successfully invited users.');
    reloadData();
  };

  const filterActions = () => {
    switch (filter) {
      case 'active':
        return [
          {
            label: 'Move to...',
            onClick: () => setMoveModalToggled(true),
          },
          {
            label: 'Deactivate',
            onClick: confirmAction('disable'),
          },
        ];
      case 'invited':
        return [
          {
            label: 'Resend invites',
            onClick: confirmAction('resend-invites'),
          },
          {
            label: 'Delete',
            onClick: confirmAction('delete'),
          },
        ];
      case 'notInvited':
        return [
          {
            label: 'Send invite',
            onClick: sendInvites,
          },
          {
            label: 'Delete',
            onClick: confirmAction('delete'),
          },
        ];
      case 'disabled':
        return [
          {
            label: 'Enable',
            onClick: confirmAction('enable'),
          },
          {
            label: 'Delete',
            onClick: confirmAction('delete'),
          },
        ];
    }
  };

  const viewProfile = id => ({
    label: 'View profile',
    onClick: () => toggleUserModal(true, id),
  });

  const editUser = (id, history) => [
    {
      label: 'Edit profile',
      onClick: () => history.push(`/user/${id}/edit`),
    },
  ];

  const checkIfShowUsersModal = () => {
    const capacityReached = user.planCapacityReached;
    if (capacityReached) {
      updateFlash(
        'error',
        'You’ve reached the maximum number of users for your plan. Please delete some users from your invited list or contact hello@heelix.com / your Account Manager to increase your user allocation.'
      );
    } else {
      setShowUsersModal(true);
    }
  };

  const exportUserList = async () => {
    const response = await fetchApi(`/users/csv`);
    downloadCsv(response.data, response.fileName);
  };

  const canSeeOrgChart = useCan(general.seeOrgChart);

  const accountSetup = () => {
    if (!canSeeOrgChart) {
      showUpgradeModal();
    } else {
      history.push('/company_structure?mode=setup');
    }
  };
  return (
    <>
      <Sidebar.Actions
        actions={[
          {
            label: 'Invite new user',
            onClick: () => checkIfShowUsersModal(),
          },
          ...(isDemoAdmin
            ? [
                {
                  label: 'Create new demo account',
                  onClick: () => setShowNewDemoAccountModal(true),
                },
              ]
            : []),
          ...(isAccountAdmin
            ? [
                {
                  label: 'Account setup',
                  onClick: () => accountSetup(),
                },
                {
                  label: 'Export user list',
                  onClick: () => exportUserList(),
                },
              ]
            : []),
          ...(selectedUsers.length &&
          selectedUsers.filter(user => !user.auth_can_take_actions).length === 0
            ? filterActions()
            : []),
          ...(selectedUsers.length === 1
            ? [
                viewProfile(selectedUsers[0].id),
                ...(user.isAccountAdmin ? editUser(selectedUsers[0].id, history) : []),
              ]
            : []),
        ]}
      />
      <MoveModal
        toggled={moveModalToggled}
        setToggled={setMoveModalToggled}
        teams={teams}
        moveTo={moveTo}
      />
      <ChangeBillingContactModal
        closePopup={() => setBillingModalToggled(false)}
        accountAdmins={accountAdmins}
        popup_display={billingModalToggled}
        changeBillingContact={handleChangeBillingContact}
      />
      <AddUsersModal
        showUsersModal={showUsersModal}
        close={() => setShowUsersModal(false)}
        addUsers={addUsers}
        mainTeamId={allTeams.find(team => !team.parent_id).id}
        state="invited"
      />
      <AddNewDemoAccount
        showNewDemoAccountModal={showNewDemoAccountModal}
        close={() => setShowNewDemoAccountModal(false)}
        mainTeamId={allTeams.find(team => !team.parent_id).id}
        state="invited"
      />
    </>
  );
};

UsersActions.propTypes = {
  teams: PropTypes.array.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  updateFlash: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  filter: PropTypes.oneOf(['active', 'disabled', 'invited']).isRequired,
};

const mapDispatchToProps = dispatch => ({
  changeUsersCapacity: isReached => dispatch(USER_ACTIONS.changeUsersCapacity(isReached)),
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
  showUpgradeModal: payload => dispatch(PLAN_ACTIONS.showUpgradeModal(payload)),
});

const mapStateToProps = state => ({
  allTeams: state.teamsDomain.teams,
  user: state.usersDomain.user,
  isAccountAdmin: state.usersDomain.user.isAccountAdmin,
  isDemoAdmin: state.usersDomain.user.isDemoAdmin,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersActions));
