import ManagerPrompt from 'pages/Grow/GrowPost/ManagerPrompts/ManagerPrompt';
import React from 'react';
import PropTypes from 'prop-types';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';
import ManagerAlertImprovement from 'pages/Grow/GrowPost/ManagerPrompts/ManagerAlertImprovement';
import ManagerAlertDeterioration from 'pages/Grow/GrowPost/ManagerPrompts/ManagerAlertDeterioration';
import ManagerAlertClosure from 'pages/Grow/GrowPost/ManagerPrompts/ManagerAlertClosure';
import ManagerAlertSuccess from 'pages/Grow/GrowPost/ManagerPrompts/ManagerAlertSuccess';

const matchComponent = type => {
  switch (type) {
    case noteTypes.managerPromptPreparation:
      return ManagerPrompt;
    case noteTypes.managerAlertImprovement:
      return ManagerAlertImprovement;
    case noteTypes.managerAlertDeterioration:
      return ManagerAlertDeterioration;
    case noteTypes.managerAlertClosure:
      return ManagerAlertClosure;
    case noteTypes.managerAlertSuccess:
      return ManagerAlertSuccess;
    default:
      return null;
  }
};

const ManagerPromptSelector = ({ note }) => {
  const Component = matchComponent(note.type);
  return Component ? <Component note={note} /> : null;
};

ManagerPromptSelector.propTypes = {
  note: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default ManagerPromptSelector;
