import { ReactComponent as ConnectPerformPuzzle } from './shared/ConnectPerformPuzzle.svg';
import { ReactComponent as ConnectPerformProPuzzle } from './shared/ConnectPerformProPuzzle.svg';

const planTypes = {
  25: {
    name: '1-25 users',
    prices: {
      pro: {
        aud: 99,
        usd: 69,
      },
      plus: {
        aud: 129,
        usd: 90,
      },
    },
  },
  50: {
    name: '25-50 users',
    prices: {
      pro: {
        aud: 199,
        usd: 138,
      },
      plus: {
        aud: 259,
        usd: 179,
      },
    },
  },
  100: {
    name: '50-100 users',
    prices: {
      pro: {
        aud: 399,
        usd: 276,
      },
      plus: {
        aud: 519,
        usd: 359,
      },
    },
  },
  custom: {
    name: '100+ users',
    prices: {
      pro: {
        image: ConnectPerformPuzzle,
      },
      plus: {
        image: ConnectPerformProPuzzle,
      },
    },
  },
  perUser: {
    name: 'Pay per user per month',
    prices: {
      pro: {
        aud: 6.99,
        usd: 4.75,
      },
      plus: {
        aud: 8.99,
        usd: 6.18,
      },
    },
  },
};

export default planTypes;
