import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AWS_S3_URL } from '../../../constants';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import qs from 'qs';

class Owners extends Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={() => this.props.toggle()}
        className="owners-modal"
        dialogClassName="modal-sm"
      >
        <Modal.Header>
          <Modal.Title>
            {this.props.ownersCount === 1
              ? `${this.props.ownersCount} like`
              : `${this.props.ownersCount} owners`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {this.props.owners.map(liker => (
              <li className="u-margin-vertical-xs" key={liker.uuid}>
                <div className="media">
                  <Link
                    to={
                      this.props.user.id === liker.id
                        ? `/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`
                        : `/user/${liker.id}`
                    }
                    className="user-link no-underline"
                  >
                    <div className="media-left">
                      <img
                        src={
                          liker.thumb_uid !== null
                            ? AWS_S3_URL + liker.thumb_uid
                            : `${AWS_S3_URL}public/images/defaults/user.jpg`
                        }
                        alt=""
                        className="img-circle media-object"
                        style={{ width: '28px' }}
                      />
                    </div>
                    <div className="media-body media-middle">
                      <div className="media-heading bold">
                        {liker.first_name}
                        {liker.last_name}
                      </div>
                    </div>
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>
    );
  }
}
export default Owners;
