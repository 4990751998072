const initialData = {
  loading: false,
  showNote: false,
  post: null,
  posts: [],
  hideSharedNotes: false,
  filteredUser: null,
  filteredType: 'board',
  teamMembers: [],
  columns: {
    todo: {
      id: 'todo',
      title: 'To do',
      postIds: [],
    },
    progress: {
      id: 'progress',
      title: 'In progress',
      postIds: [],
    },
    blocked: {
      id: 'blocked',
      title: 'Blocked',
      postIds: [],
    },
    complete: {
      id: 'complete',
      title: 'Complete',
      postIds: [],
    },
    not_complete: {
      id: 'not_complete',
      title: 'Not Completed',
      postIds: [],
    },
  },
  columnOrder: ['todo', 'progress', 'blocked', 'complete', 'not_complete'],
  showBlankState: true,
};

export default initialData;
