import React, { Component } from 'react';
import Select from 'react-select';
import qs from 'qs';
import ReactTooltip from 'react-tooltip';
import SingleRecommendation from './SingleRecommendation';
import request from '../../../../../images/recommendations/request.svg';
import requestGray from '../../../../../images/recommendations/request-gray.svg';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../../../../helpers';
import { APP_URL } from '../../../../../constants';

class RecommendationSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelect: false,
      selectedFactor: '',
      factors: [],
      categories: [],
    };
  }

  componentDidMount() {
    this.fetchFactors();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.queryString !== this.props.queryString) {
      this.fetchFactors(nextProps.queryString);
    }
  }

  fetchFactors(props = null) {
    if (!props) {
      props = this.props.queryString;
    }
    const headers = HEADERS();
    fetch(`${APP_URL}/sorted_categories?${qs.stringify(props)}`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          if (jsonResponse.enoughMembers) {
            this.setState({
              categories: jsonResponse.categories,
            });
          }
        });
      });
  }

  requestRecommendation(factor) {
    const headers = HEADERS();
    fetch(`${APP_URL}/request_recommendation/${factor}?${qs.stringify(this.props.queryString)}`, {
      method: 'post',
      headers,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          if (jsonResponse.enoughMembers) {
            this.hideSelect();
            this.props.updateRecommendations(jsonResponse.recommendations);
            this.fetchFactors();
          }
        });
      });
  }

  toggleSelect = () => {
    if (this.checkIfCanRequest()) {
      this.setState({ showSelect: !this.state.showSelect });
    } else {
      this.setState({
        showSelect: false,
        selectedFactor: '',
      });
    }
  };

  changeFactor(e) {
    if (this.checkIfCanRequest()) {
      this.setState({ selectedFactor: e.value });
      this.requestRecommendation(e.value);
    }
  }

  checkIfCanRequest() {
    return (
      this.props.recommendations.length === 0 ||
      this.props.recommendations.filter(a => !a.completed).length < 3
    );
  }

  hideSelect = () => {
    this.setState({
      showSelect: false,
      selectedFactor: '',
    });
  };

  render() {
    let i = 1;
    return (
      <div
        id="recommendations-section"
        className="recommendations-section"
        ref={this.props.refProp}
      >
        <h2 className={`${this.checkIfCanRequest() && 'can_request'}`}>
          Recommendations
          {!this.state.showSelect &&
            this.state.categories.length > 0 &&
            (this.checkIfCanRequest() ? (
              <span className="request_disabled_section">
                <img
                  alt="request_enabled"
                  className={`${this.checkIfCanRequest() && 'active'}`}
                  src={request}
                  onClick={() => this.toggleSelect()}
                />
                <span
                  className={`request-string ${this.checkIfCanRequest() && 'active'}`}
                  onClick={() => this.toggleSelect()}
                >
                  Request recommendation
                </span>
              </span>
            ) : (
              <span data-tip data-for="request-tooltip" className="request_disabled_section">
                <ReactTooltip
                  id="request-tooltip"
                  class="react-tooltip request-tooltip"
                  place="top"
                  type="dark"
                  effect="solid"
                >
                  <span>
                    You must complete one of your recommendations before you can request a new one.
                  </span>
                </ReactTooltip>
                <img alt="request_disabled" src={requestGray} />
                <span
                  className={`request-string ${this.checkIfCanRequest() && 'active'}`}
                  onClick={() => this.toggleSelect()}
                >
                  Request recommendation
                </span>
              </span>
            ))}
          {this.state.showSelect && (
            <span className="request-categories">
              Choose factor
              <Select
                ref="select"
                name="factor-select"
                id="factor-select"
                onChange={id => this.changeFactor(id)}
                value={this.state.selectedFactor || ''}
                options={this.state.categories}
                searchable={false}
              />
              <span className="close-outer">
                <span className="close-btn" onClick={() => this.toggleSelect()} />
              </span>
            </span>
          )}
        </h2>
        {this.props.recommendations.length > 0 ? (
          <table className="recommendations-table">
            <thead className="hidden-xs hidden-sm">
              <tr>
                <td className="recommendation-label" colSpan="2">
                  suggested action
                </td>
                <td className="recommendation-label">based on the team feeling</td>
                <td className="recommendation-label">useful?</td>
              </tr>
            </thead>
            <tbody>
              {[]
                .concat(this.props.recommendations)
                .filter(a => {
                  if (!a.completed) {
                    i += 1;
                  }
                  if (a.completed || (!a.completed && i < 5)) {
                    return true;
                  }
                  return false;
                })
                .sort((a, b) => a.completed - b.completed)
                .map(recommendation => (
                  <SingleRecommendation
                    key={recommendation.id}
                    recommendation={recommendation}
                    updatedCompleted={this.props.updatedCompleted}
                    hideSelect={this.hideSelect}
                  />
                ))}
            </tbody>
          </table>
        ) : (
          <span className="recommendations-info">no recommendations available</span>
        )}
      </div>
    );
  }
}

export default RecommendationSection;
