/** @jsx jsx */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { APP_URL } from 'constants.js';
import qs from 'qs';
import { downloadCsv, HEADERS } from 'helpers';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/core';

const ReportButton = styled.button`
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
  padding: 12px 26px;
  i {
    margin-left: 20px;
  }
`;

const ReportListElement = styled.li`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: #323232;
  padding: 10px 15px;
  a {
    color: #323232;
    &:hover {
      color: #323232;
      text-decoration: none;
    }
    i {
      padding-right: 10px;
    }
  }
`;

const ReportList = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  z-index: 1000;
  float: left;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 0;
  display: none;
  &.open {
    display: block;
  }
`;

const ReportDownload = ({ user, teams, plan, isInformal, queryString }) => {
  const [showDownloads, setShowDownloads] = useState(false);
  const [reportError, setReportError] = useState(false);
  const [reportPreparing, setReportPreparing] = useState(false);

  const downloadReport = (e, method = 'get') => {
    e.preventDefault();
    const { report } = e.target.dataset;
    const teamId = teams.find(team => team.id == queryString.team).id;
    if (teamId) {
      setReportPreparing(true);
      setReportError(false);
      const url = `/teams/${teamId}/${report}?${qs.stringify(queryString)}`;
      const headers = HEADERS();
      fetch(APP_URL + url, { method, headers })
        .then(response => {
          response.json().then(jsonResponse => {
            handleDownloadCsv(jsonResponse.data, jsonResponse.fileName);
          });
        })
        .catch(() => {
          setReportError(false);
          setReportPreparing(false);
        });
    }
    setShowDownloads(false);
  };

  const handleDownloadCsv = (data, name) => {
    setShowDownloads(false);
    downloadCsv(data, name);
    setReportPreparing(false);
  };

  let reportGenerateError = null;
  if (reportError) {
    reportGenerateError = (
      <p className="text-danger text-center no-margin small">Could not generate report</p>
    );
  }
  const dropdownClasses = showDownloads ? 'dropdown open' : 'dropdown';
  const dropdownIcon = reportPreparing ? (
    <i className="fa fa-cog fa-spin" />
  ) : (
    <i className="fa fa-caret-down" />
  );
  const team = teams.find(team => team.id == queryString.team);
  const teamId = team ? team.id : null;
  const canSeeFeedback = user.isAccountAdmin || user.formalLeadTeamsIds.includes(teamId);
  return (
    <div
      className="download-reports-dropdown pull-left"
      css={css`
        margin-right: 16px;
      `}
    >
      <div className={`${dropdownClasses} hidden-xs`} style={{ display: 'inline-block' }}>
        {(plan !== 'free' && !isInformal && canSeeFeedback) ||
        (plan === 'free' && user.isAccountAdmin) ? (
          <>
            {reportGenerateError}
            <ReportButton
              type="button"
              className="btn btn-default dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={() => setShowDownloads(!showDownloads)}
            >
              Download report as CSV {dropdownIcon}
            </ReportButton>
          </>
        ) : (
          ''
        )}
        {plan !== 'free' && (
          <ReportList className={`${dropdownClasses}`}>
            {canSeeFeedback ? (
              <ReportListElement id="download-weekly-report">
                <a href="#" data-report="generate_vibe_stats" onClick={e => downloadReport(e)}>
                  <i className="fa fa-bar-chart" />
                  Download Weekly Vibe Stats (csv)
                </a>
              </ReportListElement>
            ) : (
              ''
            )}
            {user.isAccountAdmin ? (
              <ReportListElement id="download-quaterly-report">
                <a
                  href="#"
                  data-report="generate_quarterly_vibe_stats"
                  onClick={e => downloadReport(e)}
                >
                  <i className="fa fa-bar-chart" />
                  Download Quarterly Vibe Stats (csv)
                </a>
              </ReportListElement>
            ) : (
              ''
            )}
            {user.isAccountAdmin ? (
              <ReportListElement id="download-reason-report">
                <a href="#" data-report="generate_reason_stats" onClick={e => downloadReport(e)}>
                  <i className="fa fa-bar-chart" />
                  Download Reason Stats (csv)
                </a>
              </ReportListElement>
            ) : (
              ''
            )}
            {canSeeFeedback ? (
              <ReportListElement id="download-team-report">
                <a href="#" data-report="generate_team_stats" onClick={e => downloadReport(e)}>
                  <i className="fa fa-bar-chart" />
                  Download Team Report (csv)
                </a>
              </ReportListElement>
            ) : (
              ''
            )}
            {canSeeFeedback ? (
              <ReportListElement id="download-progress-report">
                <a
                  href="#"
                  data-report="generate_team_progress"
                  onClick={e => downloadReport(e, 'post')}
                >
                  <i className="fa fa-bar-chart" />
                  Download Team Progress Report (csv)
                </a>
              </ReportListElement>
            ) : (
              ''
            )}
          </ReportList>
        )}
      </div>
    </div>
  );
};

ReportDownload.propTypes = {
  queryString: PropTypes.object.isRequired,
  isInformal: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  plan: state.plansDomain.plan,
  teams: state.teamsDomain.teams,
  user: state.usersDomain.user,
});

export default connect(mapStateToProps)(ReportDownload);
