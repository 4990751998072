import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import IntroModal from './IntroModal';
import VibeFlowContainer from './VibeFlowContainer';
import { APP_URL } from '../../constants';
import {
  BODY_CLASSES,
  SIGN_OUT,
  LOCALSTORAGE_GET,
  HEADERS,
  CHECK_UNAUTHORIZED,
} from '../../helpers';
import { PLAN_ACTIONS } from '../../redux/actions/planActions';
import { INITIAL_ACTIONS } from '../../redux/actions/initialActions';

const mapStateToProps = state => ({
  plan: state.plansDomain.plan,
  user: state.usersDomain.user,
});
const mapDispatchToProps = dispatch => ({
  showUpgradeModal: () => dispatch(PLAN_ACTIONS.showUpgradeModal()),
  hideUpgradeModal: () => dispatch(PLAN_ACTIONS.hideUpgradeModal()),
  initialFetchData: () => dispatch(INITIAL_ACTIONS.fetchData()),
});
class VibesNew extends Component {
  state = {
    loaded: false,
    redirect: false,
  };

  getParameterByName(name, url) {
    if (!url) url = document.location;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  componentWillMount() {
    BODY_CLASSES(['no-navigation', 'full-screen', 'vibe_new', 'vibes', 'new']);
    document.getElementsByTagName('html')[0].classList.add('full-screen');
    let accessToken = '';

    let mobileParam = '';
    if (this.getParameterByName('uta')) {
      mobileParam = '?mobile_app=1';
      accessToken = this.getParameterByName('uta');
    } else if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    const headers = HEADERS(accessToken);
    fetch(`${APP_URL}/vibes/get_data${mobileParam}`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (!response.ok && response.status === 409) {
          this.setState({ redirect: true });
        }
        response.json().then(jsonResponse => {
          this.vibe_factors = jsonResponse.vibe_factors;
          this.feel_factors = jsonResponse.feel_factors;
          this.links = jsonResponse.links;
          this.scale = jsonResponse.scale;
          this.raw_text = jsonResponse.raw_text;
          this.images = jsonResponse.images;
          this.enable_vibe_comments = jsonResponse.enable_vibe_comments;
          this.setState({ loaded: true });
        });
      })
      .catch(() => {});
  }

  updateLocalStorage() {
    let accessToken = '';
    let mobileParam = '';
    if (this.getParameterByName('uta')) {
      mobileParam = '?mobile_app=1';
      accessToken = this.getParameterByName('uta');
    } else if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    const headers = HEADERS(accessToken);
    fetch(`${APP_URL}/check_image${mobileParam}`, { headers }).then(response => {
      if (response.status === 200 && response.ok === true) {
        response.json().then(jsonResponse => {
          if (localStorage != null) {
            if (LOCALSTORAGE_GET('user_first_name') !== jsonResponse.first_name) {
              localStorage.setItem('user_first_name', jsonResponse.first_name);
            }
            if (LOCALSTORAGE_GET('user_image_uid') !== jsonResponse.image_uid) {
              localStorage.setItem('user_image_uid', jsonResponse.image_uid);
            }
            if (LOCALSTORAGE_GET('user_last_name') !== jsonResponse.last_name) {
              localStorage.setItem('user_last_name', jsonResponse.last_name);
            }
          } else {
            this.setImage(jsonResponse.image_uid, jsonResponse.first_name);
          }
        });
      } else if (response.status === 401 || response.status === 403) {
        SIGN_OUT();
      }
    });
  }

  componentDidMount() {
    this.updateLocalStorage();
    if (!this.props.user) this.props.initialFetchData();
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to="/" />;
    }
    if (this.state.loaded && this.props.user) {
      return (
        <div className="frame-wrapper">
          <div className="page-wrapper">
            <IntroModal />
            <VibeFlowContainer
              vibe_factors={this.vibe_factors}
              feel_factors={this.feel_factors}
              links={this.links}
              raw_text={this.raw_text}
              scale={this.scale}
              sentimentChanged=""
              images={this.images}
              enable_vibe_comments={this.enable_vibe_comments}
              fetchPostsOnClose
            />
          </div>
        </div>
      );
    }
    return <div />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VibesNew);
