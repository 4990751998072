import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as TrendFall } from 'pages/Grow/GrowPost/assets/trend_fall.svg';
import {
  BlueButtonStyled,
  Container,
  Content,
  GrayButton,
  Picture,
} from 'pages/Grow/GrowPost/ManagerPrompts/layout';
import { withRouter } from 'react-router-dom';
import { noteShape, viewGrowPost } from 'pages/Grow/GrowPost/ManagerPrompts/common';
import { GROW_ACTIONS } from 'redux/actions/growActions';
import { connect } from 'react-redux';

const ManagerAlertDeterioration = ({ note, completeActionPlan, history, location }) => {
  return (
    <Container>
      <Picture>
        <TrendFall />
      </Picture>
      <Content>{note.content}</Content>
      <BlueButtonStyled
        onClick={() =>
          viewGrowPost(history.push, note.action_plan.main_grow_post.id, location.search)
        }
      >
        Take action
      </BlueButtonStyled>
      <GrayButton onClick={() => completeActionPlan(note)}>Mark as complete</GrayButton>
    </Container>
  );
};

ManagerAlertDeterioration.propTypes = {
  note: PropTypes.shape(noteShape).isRequired,
  completeActionPlan: PropTypes.func.isRequired,
};

const mapDispatch = {
  completeActionPlan: GROW_ACTIONS.completeActionPlan,
};

export default withRouter(connect(null, mapDispatch)(ManagerAlertDeterioration));
