import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { APP_URL } from '../../../constants';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../../helpers';
import { POST_ACTIONS } from '../../../redux/actions/postActions';
import { SPACE_ACTIONS } from '../../../redux/actions/spaceActions';

const mapStateToProps = state => ({
  filters: state.postsDomain.filters,
  user: state.usersDomain.user,
});

const mapDispatchToProps = dispatch => ({
  fetchPosts: () => dispatch(POST_ACTIONS.fetchPosts()),
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  fetchSpaces: () => dispatch(SPACE_ACTIONS.fetchSpaces()),
});

class DeleteSpaceModal extends Component {
  deleteSpace = () => {
    this.props.close();
    const id = this.props.filters.space;
    const headers = HEADERS();
    fetch(`${APP_URL}/spaces/${id}`, { method: 'delete', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(() => {
          this.props.fetchSpaces();
          this.props.history.push(`/`);
        });
      });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.close}
        dialogClassName="leave-modal"
        backdrop
        keyboard
      >
        <div className="close-cross" onClick={this.props.close} />
        <h1>You're about to delete this space.</h1>
        <h2>This action cannot be reverted!</h2>
        <h3>Are you sure you want to proceed?</h3>
        <div className="buttons-wrap">
          <div className="accept-button" onClick={this.deleteSpace}>
            Yes
          </div>
          <div className="cancel-button" onClick={this.props.close}>
            No
          </div>
        </div>
      </Modal>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteSpaceModal));
