import React from 'react';
import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';
import { ReactComponent as Back } from './back.svg';

const IconWrapper = styled.button`
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background-color: #fff;
`;
const BackBB = styled(Back)`
  & rect {
    fill: white;
  }
  & circle {
    fill: #F3F3F3;
  }
  margin-right: 15px;
`;
const TitleWrapper = styled.span`
  font-size: 16px;
  color: #323232;
  font-weight: 900;
  @media (min-width: 768px) {
    font-size: 24px;
  }
`;
const BackButton = ({ history }) => (
  <IconWrapper onClick={() => history.push('/')}>
    <BackBB /> <TitleWrapper>Back to Home</TitleWrapper>
  </IconWrapper>
);
export default withRouter(BackButton);
