import React from 'react';

const ShoutoutStarImage = ({ image }) => (
  <svg height={120} width={120} viewBox="0 -2 100 100">
    <defs>
      <clipPath id="star-clip-path">
        <polygon
          id="polygonStar"
          fill="#E6E6E6"
          points="50 0, 68 28, 98 35, 77 59, 79 91, 50 78, 21 91, 23 58, 2 35, 32 28"
        />
      </clipPath>
    </defs>
    <polygon
      id="polygonStar"
      fill="#E6E6E6"
      points="50 0, 68 28, 98 35, 77 59, 79 91, 50 78, 21 91, 23 58, 2 35, 32 28"
      transform="scale(1.02) translate(-1, -1)"
    />
    <image width={100} height={100} xlinkHref={image} clipPath="url(#star-clip-path)" />
  </svg>
);

export default ShoutoutStarImage;
