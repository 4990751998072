import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { css } from '@emotion/core';
import Sidebar from 'components/organisation/partials/Sidebar';
import FeedDateRange from 'components/shared/FeedDateRange';
import styled from '@emotion/styled';
import hashtagStyles from 'pages/Grow/hashtag_filters.sass';
import { FORMAT_FILTERS } from 'helpers';
import { connect } from 'react-redux';
import GrowProfileNavigation from 'pages/Grow/GrowProfile/GrowProfileNavigation';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import TeamMemberSelect from '../ProgressBoard/TeamMemberSelect';
import ExportSection from '../ExportSection';

const HashtagFilters = styled.div`
  overflow-x: hidden;
  ${hashtagStyles}
`;
const SidebarWrapper = styled.div`
  border-left: 1px solid #e1e1e1;
  padding-left: 12px;
`;
const TagsCounter = styled.span`
  display: inline-block;
  background: white;
  border-radius: 14px;
  font-size: 11px;
  color: #c4c4c4;
  height: 18px;
  margin-left: 9px;
  padding: 0 5px;
  min-width: 28px;
  line-height: 20px;
  text-align: center;
  position: relative;
  top: -1px;
`;

const GrowProfileRightSidebar = ({
  hashtags,
  filters,
  viewAllHashtags,
  history,
  toggleViewAllHashtags,
  filterByUserOptions,
  exportModeOn,
  isExportContentVisible,
  changeExportMode,
  exportSelected,
  deleteSelected,
  isExporting,
  isDeleting,
  hasDeleteError,
}) => {
  const sidebarRef = React.createRef();
  const [selectedMember, setSelectedMember] = useState(null);
  let tagsRenderedCount = -1;
  const getSelectedMemberFilter = () => {
    const currentFilters = JSON.parse(JSON.stringify(filters));
    let memberSelectedFromFilters = null;
    if (currentFilters.member) {
      memberSelectedFromFilters = filterByUserOptions.find(
        member => member.type === 'user' && member.id == currentFilters.member
      );
    } else if (currentFilters.team) {
      memberSelectedFromFilters = filterByUserOptions.find(
        member => member.type === 'team' && member.id == currentFilters.team
      );
    }
    return memberSelectedFromFilters;
  };
  useEffect(() => {
    const targetNode = sidebarRef.current;
    if (!exportModeOn || !targetNode) return;
    targetNode.scrollIntoView(false);
  }, [exportModeOn]);

  useEffect(() => {
    const defaultSelectedMember = getSelectedMemberFilter();
    setSelectedMember(defaultSelectedMember);
  }, [filters, filterByUserOptions]);
  const handleMemberFilterSelect = member => {
    setSelectedMember(member);
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters.member = member && member.type == 'user' ? member.id : '';
    newFilters.team = member && member.type == 'team' ? member.id : '';
    history.push(`/${growProfileUrl}?${qs.stringify(newFilters) || ' '}`);
  };
  return (
    <SidebarWrapper ref={sidebarRef}>
      <GrowProfileNavigation filters={filters} />
      <TeamMemberSelect
        onSelect={handleMemberFilterSelect}
        selectedMember={selectedMember?.value}
        teamMembers={filterByUserOptions}
        sidebar
      />
      <FeedDateRange />
      {hashtags.length > 0 && (
        <>
          <Sidebar.Header
            css={css`
              margin-top: 40px;
            `}
          >
            By tag
            <TagsCounter>{hashtags.length}</TagsCounter>
          </Sidebar.Header>
          <HashtagFilters className="filters-group">
            {hashtags
              .filter(hashtag => filters.hashtags.indexOf(hashtag.id) > -1)
              .map((hashtag, index) => {
                tagsRenderedCount++;
                if (viewAllHashtags || (!viewAllHashtags && tagsRenderedCount < 5)) {
                  return (
                    <p
                      onClick={e =>
                        history.push(
                          `/${growProfileUrl}?${
                            qs.stringify(FORMAT_FILTERS(e.currentTarget, filters)) || ' '
                          }`
                        )
                      }
                      custom-checked={filters.hashtags.indexOf(hashtag.id) > -1 ? 'true' : 'false'}
                      filter-type="hashtag"
                      hashtag-slug={hashtag.id}
                      key={index}
                    >
                      #{hashtag.id}
                    </p>
                  );
                }
                return '';
              })}
            {hashtags
              .filter(hashtag => filters.hashtags.indexOf(hashtag.id) < 0)
              .map((hashtag, index) => {
                tagsRenderedCount++;
                if (viewAllHashtags || (!viewAllHashtags && tagsRenderedCount < 5)) {
                  return (
                    <p
                      onClick={e =>
                        history.push(
                          `/${growProfileUrl}?${
                            qs.stringify(FORMAT_FILTERS(e.currentTarget, filters)) || ' '
                          }`
                        )
                      }
                      custom-checked={filters.hashtags.indexOf(hashtag.id) > -1 ? 'true' : 'false'}
                      filter-type="hashtag"
                      hashtag-slug={hashtag.id}
                      key={index}
                    >
                      #{hashtag.id}
                    </p>
                  );
                }
                return '';
              })}
            {hashtags.length > 5 ? (
              <div
                onClick={toggleViewAllHashtags}
                className={`unfold-button ${viewAllHashtags ? 'unfolded' : ''}`}
              >
                {viewAllHashtags ? 'Collapse' : 'View all'}
              </div>
            ) : (
              ''
            )}
          </HashtagFilters>
        </>
      )}
      {isExportContentVisible && (
        <ExportSection
          exportSelected={exportSelected}
          deleteSelected={deleteSelected}
          exportModeOn={exportModeOn}
          changeExportMode={changeExportMode}
          isExporting={isExporting}
          isDeleting={isDeleting}
          hasDeleteError={hasDeleteError}
        />
      )}
    </SidebarWrapper>
  );
};

GrowProfileRightSidebar.propTypes = {
  hashtags: PropTypes.instanceOf(Array).isRequired,
  filters: PropTypes.instanceOf(Array).isRequired,
  viewAllHashtags: PropTypes.bool.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  toggleViewAllHashtags: PropTypes.func.isRequired,
  filterByUserOptions: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = state => ({
  filters: state.growDomain.filters,
  posts: state.growDomain.posts,
  hashtags: state.tagsDomain.hashtags,
  feedEnabled: state.usersDomain.feedEnabled,
});

export default connect(mapStateToProps)(GrowProfileRightSidebar);
