import React, { Component } from 'react';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setShowAddUserModal, setShowAddCSVUserModal, resetCSVUsers } from 'redux/app/actions';
import AddSiblingTeam from './AddSiblingTeam';
import AddUsersModal from '../../shared/modals/AddUsersModal';
import AddCSVUsersModal from '../../shared/modals/AddCSVUsersModal';
import UserTile from './UserTile';
import AddChildTeam from './AddChildTeam';
import SetupTeamTile from './SetupTeamTile';
import Auxiliary from '../../layouts/Auxiliary';
import FinalSetupModal from './FinalSetupModal';
import InstructionsModal from './InstructionsModal';
import StartGuide from './StartGuide';
import CustomScrollbar from '../../shared/CustomScrollbar';
import UploadCSV from './assets/upload_csv.svg';
import UserAvatar from './assets/user_avatar.svg';

class CompanySetup extends Component {
  state = {
    showUsersModal: false,
    showInstructionsModal: false,
    setupStep: 1,

    dragHelperVariables: {
      dragStartX: null,
      dragStartY: null,

      draggedTeamDOM: null,
      draggedTeamData: null,

      draggedUsersDOM: null,
      draggedUsersData: null,
      draggedElementStartX: null,
      draggedElementStartY: null,
    },
    sendingInvitations: false,
    showFinalSetupModal: false,

    teams: [],
  };

  componentDidMount() {
    this.setState(
      {
        teams: this.props.teams.map(team => ({
          ...team,
          childrenUnfolded: team.parent_ids.length < 2,
          users: team.users.map(user => ({
            ...user,
            is_selected: false,
            marked_to_invite: false,
          })),
        })),
      },
      () => {
        window.addEventListener('mousemove', this.handleDragElement);
        window.addEventListener('mouseup', this.dragStop);
        window.addEventListener('scroll', this.handleBucketPosition);
        this.graphWrap = document.querySelector('.setup-graph');
        this.graphWrap.addEventListener('scroll', this.handleBucketPosition);
        this.bucket = document.querySelector('#main-team');
      }
    );
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleDragElement);
    window.removeEventListener('mouseup', this.dragStop);
    window.removeEventListener('scroll', this.handleBucketPosition);
    document.querySelector('.setup-graph').removeEventListener('scroll', this.handleBucketPosition);
  }

  componentWillReceiveProps(props) {
    // ---Perform props check only after setting teams state as
    // ---cWRP is sometimes triggered by async redux action
    if (this.state.teams.length > 0) {
      this.setState({
        teams: props.teams.map(team => {
          const stateTeam = this.state.teams.find(stateTeam => stateTeam.id === team.id);
          return {
            ...team,
            users: stateTeam
              ? team.users.map(user => {
                  const stateUser = stateTeam.users.find(stateUser => stateUser.id === user.id);
                  const isLead = team.team_leads.find(teamLead => teamLead.id === user.id);
                  return {
                    ...user,
                    is_lead: !!isLead,
                    is_selected: stateUser ? stateUser.is_selected : false,
                    marked_to_invite: stateUser ? stateUser.marked_to_invite : false,
                  };
                })
              : [],
            childrenUnfolded: stateTeam ? stateTeam.childrenUnfolded : false,
          };
        }),
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if (this.props.location.search === '?mode=setup&setupStep=2' && this.state.setupStep !== 2) {
      this.setState({ setupStep: 2 });
    }
    if (
      (this.props.location.search === '?mode=setup&setupStep=1' ||
        this.props.location.search === '?mode=setup') &&
      this.state.setupStep !== 1
    ) {
      this.setState({ setupStep: 1 });
    }
    if (params.setupStep !== this.state.setupStep && prevState.setupStep !== this.state.setupStep) {
      params.setupStep = this.state.setupStep;
      this.props.history.push(`/company_structure?${qs.stringify(params)}`);
    }
  }

  handleBucketPosition = () => {
    if (this.graphWrap.scrollLeft > 0) {
      this.bucket.classList.add('hide-horizontal-branch');
    } else {
      this.bucket.classList.remove('hide-horizontal-branch');
    }
  };

  handleDragElement = e => {
    if (this.state.dragHelperVariables.draggedUsersDOM) {
      this.state.dragHelperVariables.draggedUsersDOM.forEach((element, index) => {
        element.style.top = `${
          this.state.dragHelperVariables.draggedElementStartY -
          (this.state.dragHelperVariables.dragStartY - e.clientY) +
          index * 52
        }px`;
        element.style.left = `${
          this.state.dragHelperVariables.draggedElementStartX -
          (this.state.dragHelperVariables.dragStartX - e.clientX)
        }px`;
      });
    }
    if (this.state.dragHelperVariables.draggedTeamDOM) {
      const element =
        this.state.dragHelperVariables.draggedUserDOM ||
        this.state.dragHelperVariables.draggedTeamDOM;
      element.style.top = `${
        this.state.dragHelperVariables.draggedElementStartY -
        (this.state.dragHelperVariables.dragStartY - e.clientY)
      }px`;
      element.style.left = `${
        this.state.dragHelperVariables.draggedElementStartX -
        (this.state.dragHelperVariables.dragStartX - e.clientX)
      }px`;
    }
  };

  dragStop = () => {
    document.body.classList.remove('dragging');
    Array.from(document.querySelectorAll('.drag-capture-area')).forEach(node => {
      node.classList.remove('active');
    });

    const elements = this.state.dragHelperVariables.draggedUsersDOM
      ? this.state.dragHelperVariables.draggedUsersDOM
      : this.state.dragHelperVariables.draggedTeamDOM
      ? [this.state.dragHelperVariables.draggedTeamDOM]
      : null;
    if (elements) {
      elements.forEach((element, index) => {
        element.classList.add('stop-position-transition');
        element.style.position = '';
        element.style.transform = 'rotateZ(0)';
        element.style.position = '';
        element.style.top = `${
          element.getBoundingClientRect().top +
          window.scrollY -
          element.parentNode.getBoundingClientRect().top +
          index * 52
        }px`;
        element.style.left = `${
          element.getBoundingClientRect().left +
          window.scrollX -
          element.parentNode.getBoundingClientRect().left
        }px`;
        element.style.transform = 'rotateZ(8)';
        element.classList.remove('dragged');

        setTimeout(() => {
          element.classList.remove('stop-position-transition');
          element.parentNode.style.height = 'unset';
          element.parentNode.style.width = 'unset';
          element.style.position = '';
          element.style.top = 0;
          element.style.left = 0;
          element.style.transform = 'rotateZ(0)';
          // ---Transform has to be unset after all operations, as any transform causes its fixed positioned
          // children measuring position relatively to transformed parent instead of viewport
          setTimeout(() => (element.style.transform = 'unset'), 100);
        }, 0);
      });
      this.setState({
        dragHelperVariables: {
          draggedUsersDOM: null,
          draggedUsersData: null,
          draggedTeamDOM: null,
          draggedTeamData: null,

          dragStartX: null,
          dragStartY: null,
          draggedElementStartX: null,
          draggedElementStartY: null,
        },
      });
    }
  };

  generateChildren(parentTeam) {
    const filteredTeams = this.state.teams.filter(team => team.parent_id === parentTeam.id);
    return (
      <div className="team-tree-wrap">
        {this.state.teams.filter(team => team.parent_id === parentTeam.id).length > 0 ? (
          parentTeam.childrenUnfolded ? (
            <Auxiliary>
              {filteredTeams.map((team, index) => (
                <div
                  className={`team-subtree-wrap ${
                    this.state.setupStep > 1 ? 'set-equal-branch-heights' : ''
                  } ${
                    this.state.teams
                      .filter(team => team.parent_id === parentTeam.id)
                      .map(team => team.id)
                      .indexOf(team.id) ===
                      this.state.teams.filter(team => team.parent_id === parentTeam.id).length -
                        1 && this.state.setupStep > 1
                      ? 'hide-vertical-branch'
                      : ''
                  }`}
                  key={team.id}
                  id={index === 0 && 'first-team-wrapper'}
                >
                  <div className="vertical-branch" />
                  <SetupTeamTile
                    team={team}
                    teams={this.state.teams}
                    teamFolding={this.teamFolding}
                    makeUsersLead={this.props.makeUsersLead}
                    updateUserRole={this.props.updateUserRole}
                    moveUser={this.props.moveUser}
                    moveSelectedUsers={this.props.moveSelectedUsers}
                    selectUser={this.selectUser}
                    markInvitedUsers={this.markInvitedUsers}
                    dragHelperVariables={this.state.dragHelperVariables}
                    setupStep={this.state.setupStep}
                    setDragHelperVariables={object => this.setState(object)}
                    sendingInvitations={this.state.sendingInvitations}
                    deleteSpace={this.props.deleteSpace}
                  />
                  {this.generateChildren(team)}
                </div>
              ))}
              {this.state.teams.every(
                team =>
                  team.parent_id ===
                    this.state.teams.find(team => team.parent_ids.length === 0).id ||
                  team.parent_ids.length === 0
              ) && this.props.teams.length < 3 ? (
                <StartGuide
                  className="dnd-guide"
                  calcHeight
                  delayTime={600}
                  isMounted={
                    this.state.setupStep === 1 &&
                    parentTeam.parent_ids.length === 0 &&
                    this.state.teams.find(team => team.parent_ids.length === 1).users.length === 0
                  }
                >
                  <div className="vertical-branch" />
                  <span>
                    Drag & drop people from the left column here to add them to this team. You can
                    select multiple people by using the checkbox.
                  </span>
                  <svg
                    width="59"
                    height="86"
                    viewBox="0 0 59 86"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.25746 14.9952C1.88772 15.4055 1.92057 16.0378 2.33083 16.4075L9.01643 22.4327C9.42669 22.8024 10.059 22.7696 10.4287 22.3593C10.7985 21.949 10.7656 21.3167 10.3554 20.947L4.41261 15.5913L9.76832 9.64852C10.1381 9.23826 10.1052 8.60595 9.69494 8.23621C9.28468 7.86648 8.65237 7.89933 8.28264 8.30959L2.25746 14.9952ZM31.7923 85.0713C44.8117 70.4249 48.6568 52.3104 43.6571 38.051C38.6303 23.7143 24.7565 13.5329 2.94841 14.666L3.05219 16.6633C24.1078 15.5693 37.0789 25.3341 41.7698 38.7128C46.4878 52.1688 42.9234 69.5389 30.2975 83.7425L31.7923 85.0713Z"
                      fill="#D7D6DE"
                    />
                  </svg>
                </StartGuide>
              ) : (
                ''
              )}
              {this.state.setupStep === 1 ? (
                <AddSiblingTeam
                  dragHelperVariables={this.state.dragHelperVariables}
                  team={parentTeam}
                  teams={this.state.teams}
                  addTeam={this.props.addTeam}
                  moveTeam={this.props.moveTeam}
                />
              ) : null}
            </Auxiliary>
          ) : (
            ''
          )
        ) : this.state.setupStep === 1 ? (
          <AddChildTeam
            dragHelperVariables={this.state.dragHelperVariables}
            team={parentTeam}
            teams={this.state.teams}
            addTeam={this.props.addTeam}
            moveTeam={this.props.moveTeam}
          />
        ) : null}
      </div>
    );
  }

  teamFolding = team => {
    const teamsCopy = JSON.parse(JSON.stringify(this.state.teams));
    if (team.childrenUnfolded) {
      teamsCopy.forEach(stateTeam => {
        if (stateTeam.id === team.id || stateTeam.parent_ids.indexOf(team.id) !== -1)
          stateTeam.childrenUnfolded = false;
      });
    } else {
      teamsCopy[teamsCopy.findIndex(stateTeam => stateTeam.id === team.id)].childrenUnfolded = true;
    }
    this.setState({ teams: teamsCopy });
  };

  selectUser = user => {
    this.setState({
      teams: this.state.teams.map(stateTeam => {
        if (stateTeam.id === user.team_id) {
          return {
            ...stateTeam,
            users: stateTeam.users.map(stateUser => {
              if (stateUser.id === user.id) {
                return {
                  ...stateUser,
                  is_selected: !stateUser.is_selected,
                };
              }
              return stateUser;
            }),
          };
        }
        return stateTeam;
      }),
    });
  };

  selectAllMainTeamUsers = () => {
    this.setState({
      teams: this.state.teams.map(stateTeam => {
        if (stateTeam.parent_ids.length > 0) return stateTeam;
        if (stateTeam.users.filter(stateUser => !stateUser.is_selected).length === 0) {
          return {
            ...stateTeam,
            users: stateTeam.users.map(stateUser => ({
              ...stateUser,
              is_selected: false,
            })),
          };
        }
        return {
          ...stateTeam,
          users: stateTeam.users.map(stateUser => ({
            ...stateUser,
            is_selected: true,
          })),
        };
      }),
    });
  };

  markInvitedUsers = (markSingle = false, team = null, user = null) => {
    const teamsCopy = JSON.parse(JSON.stringify(this.state.teams));
    if (markSingle) {
      const teamIndex = teamsCopy.findIndex(team => team.id === user.team_id);
      teamsCopy[teamIndex].users.find(
        stateUser => stateUser.id === user.id
      ).marked_to_invite = !teamsCopy[teamIndex].users.find(stateUser => stateUser.id === user.id)
        .marked_to_invite;
    } else {
      const teamIndex = teamsCopy.findIndex(stateTeam => stateTeam.id === team.id);
      const markToInviteUsersCount = teamsCopy[teamIndex].users.filter(
        user => user.marked_to_invite
      ).length;
      const availableUsersToInviteCount = teamsCopy[teamIndex].users.filter(
        user => user.state === 'invited' || user.state === 'pending'
      ).length;
      if (markToInviteUsersCount === availableUsersToInviteCount) {
        teamsCopy[teamIndex].users = teamsCopy[teamIndex].users.map(user => {
          user.marked_to_invite = false;
          return user;
        });
      } else {
        teamsCopy[teamIndex].users = teamsCopy[teamIndex].users.map(user => {
          if (user.state === 'invited' || user.state === 'pending') {
            user.marked_to_invite = true;
          }
          return user;
        });
      }
    }
    this.setState({ teams: teamsCopy });
  };

  prevStep() {
    if (!this.state.sendingInvitations) {
      if (this.state.setupStep === 1) {
        if (this.props.plan === 'free') {
          this.props.showUpgradeModal();
        } else {
          this.props.viewMode();
        }
      } else {
        this.props.history.push('/company_structure?mode=setup&setupStep=1');
        this.setState({ setupStep: 1 });
      }
    }
  }

  render() {
    const {
      setShowAddUserModal,
      showAddUserModal,
      setShowAddCSVUserModal,
      showAddCSVUserModal,
      hasCSVUsers,
    } = this.props;

    return this.state.teams.length > 0 ? (
      <div id="company-setup">
        <div className="company-setup-header">
          <div>
            <h1>Setup your organisation</h1>
            <h2 onClick={() => this.setState({ showInstructionsModal: true })}>
              Learn more about setup process
            </h2>
          </div>
          <div>
            <div
              className={`first-step step-circle ${this.state.setupStep >= 1 ? 'active' : ''} ${
                this.state.setupStep > 1 ? 'done' : ''
              }`}
            >
              1
              <div className="done-overlay" />
            </div>
            <div className={`step-line ${this.state.setupStep > 1 ? 'filled' : ''}`} />
            <div
              className={`second-step step-circle ${this.state.setupStep >= 2 ? 'active' : ''} ${
                this.state.setupStep > 2 ? 'done' : ''
              }`}
            >
              2
              <div className="done-overlay" />
            </div>
          </div>
        </div>
        <div
          className={`setup-graph ${this.state.teams.length === 1 ? 'first-subteam' : ''} ${
            this.props.setupTourStep > -1 &&
            this.props.setupTourStep !== 7 &&
            this.props.setupTourStep !== 10
              ? 'setup-graph-onboarding'
              : ''
          }`}
        >
          <div id="main-team-wrap">
            <div
              id="main-team"
              className={`team-tile ${
                this.state.setupStep > 1 &&
                this.state.teams
                  .map(team => team.parent_id)
                  .indexOf(this.state.teams.filter(team => team.parent_ids.length === 0)[0].id) ===
                  -1
                  ? 'hide-horizontal-branch'
                  : ''
              }`}
            >
              <div className="team-tile-header">
                <span>{this.state.setupStep === 1 ? 'Your users' : 'Unallocated users'}</span>
                {this.state.setupStep === 1 ? (
                  <div
                    className={`custom-checkbox ${
                      this.state.teams
                        .filter(team => team.parent_ids.length === 0)[0]
                        .users.filter(user => !user.is_selected).length === 0
                        ? 'selected'
                        : ''
                    } ${
                      this.state.teams.filter(team => team.parent_ids.length === 0)[0].users
                        .length > 0
                        ? 'visible'
                        : ''
                    }`}
                    onClick={() => this.selectAllMainTeamUsers()}
                  />
                ) : (
                  <div
                    className={`custom-checkbox ${
                      this.state.teams
                        .filter(team => team.parent_ids.length === 0)[0]
                        .users.filter(
                          user =>
                            !user.marked_to_invite &&
                            (user.state === 'pending' || user.state === 'invited')
                        ).length === 0
                        ? 'selected'
                        : ''
                    } ${
                      this.state.teams.filter(team => team.parent_ids.length === 0)[0].users
                        .length > 0
                        ? 'visible'
                        : ''
                    }`}
                    onClick={() =>
                      this.markInvitedUsers(
                        false,
                        this.state.teams.filter(team => team.parent_ids.length === 0)[0]
                      )
                    }
                  />
                )}
              </div>
              <p id="main-team-paragraph">
                You don’t need to create an overall account / company team. All users will
                automatically be added to this team after you finish the setup process.
              </p>
              <div
                className={`remove-button ${
                  this.state.teams
                    .find(team => team.parent_ids.length === 0)
                    .users.some(user => user.is_selected) &&
                  !this.state.teams
                    .find(team => team.parent_ids.length === 0)
                    .users.some(user => user.is_selected && user.account_admin)
                    ? 'visible'
                    : ''
                }`}
                onClick={() =>
                  this.props.removeUsers(
                    this.state.teams
                      .find(team => team.parent_ids.length === 0)
                      .users.filter(user => user.is_selected)
                      .map(user => user.id)
                  )
                }
              >
                Remove
              </div>
              <div className="main-team-members-list-wrap">
                <div className="main-team-members-list">
                  <CustomScrollbar />
                  {this.state.teams
                    .find(team => team.parent_ids.length === 0)
                    .users.sort((a, b) =>
                      a.last_name.toLowerCase() >= b.last_name.toLowerCase() ? 1 : -1
                    )
                    .map((user, index) => (
                      <UserTile
                        id={index === 0 && 'first-user-tile'}
                        team={this.state.teams.find(team => team.parent_ids.length === 0)}
                        setupStep={this.state.setupStep}
                        markInvitedUsers={this.markInvitedUsers}
                        selectUser={this.selectUser}
                        key={user.id}
                        user={user}
                        dragHelperVariables={this.state.dragHelperVariables}
                        setDragHelperVariables={object => this.setState(object)}
                      />
                    ))}
                </div>
              </div>
              {this.state.setupStep === 1 && (
                <div id="add-users-buttons-wrapper">
                  <div
                    id="add-users-button"
                    className="add-users-btn"
                    onClick={() => setShowAddUserModal(true)}
                  >
                    <img src={UserAvatar} alt="avatar" /> Add users
                  </div>
                  <div
                    id="add-csv-users-button"
                    className="add-users-btn"
                    onClick={() => setShowAddCSVUserModal(true)}
                  >
                    <img src={UploadCSV} alt="CSV" /> Upload CSV
                  </div>
                </div>
              )}
            </div>
          </div>
          {this.generateChildren(this.state.teams.filter(team => team.parent_ids.length === 0)[0])}
        </div>
        <div className="bottom-buttons-wrap">
          <div
            className={`cancel-button ${this.state.sendingInvitations ? 'disabled' : ''}`}
            onClick={() => this.prevStep()}
          >
            {this.state.setupStep === 1 ? 'Exit setup' : 'Previous step'}
          </div>
          <div
            className={`next-step-button ${
              this.state.sendingInvitations ||
              (this.state.setupStep > 1 &&
                this.state.teams.reduce(
                  (a, b) => [...a, ...b.users.filter(user => user.marked_to_invite)],
                  []
                ).length === 0)
                ? 'disabled'
                : ''
            }`}
            onClick={() => {
              if (this.state.setupStep === 1) {
                this.setState({ setupStep: 2 });
                this.props.history.push('/company_structure?mode=setup&setupStep=2');
              } else if (
                this.state.setupStep === 2 &&
                this.state.teams.reduce(
                  (a, b) => [...a, ...b.users.filter(user => user.marked_to_invite)],
                  []
                ).length !== 0
              ) {
                this.props.sendInvitations(
                  this.state.teams.reduce(
                    (a, b) => [
                      ...a,
                      ...b.users.filter(user => user.marked_to_invite).map(user => user.id),
                    ],
                    []
                  )
                );
                this.setState({
                  showFinalSetupModal: true,
                  sendingInvitations: true,
                });
              }
            }}
          >
            {this.state.setupStep === 1 ? 'Next step' : 'Send invitations'}
          </div>
        </div>
        {showAddUserModal && (
          <AddUsersModal
            showUsersModal={showAddUserModal}
            close={() => setShowAddUserModal(false)}
            addUsers={this.props.addUsers}
            mainTeamId={this.state.teams.find(team => team.parent_ids.length === 0).id}
            state="pending"
          />
        )}
        <AddCSVUsersModal
          showAddCSVUserModal={showAddCSVUserModal}
          close={() => setShowAddCSVUserModal(false)}
          addCSVUsers={this.props.addCSVUsers}
        />
        <FinalSetupModal
          showFinalSetupModal={this.state.showFinalSetupModal}
          close={() => this.setState({ showFinalSetupModal: false })}
          finishSetup={() => this.props.viewMode()}
        />
        <InstructionsModal
          showInstructionsModal={this.state.showInstructionsModal}
          close={() => this.setState({ showInstructionsModal: false })}
        />
      </div>
    ) : (
      ''
    );
  }
}

const mapStateToProps = state => ({
  hasCSVUsers: Boolean(state.app.csvUsers.length),
  showAddUserModal: state.app.showAddUserModal,
  showAddCSVUserModal: state.app.showAddCSVUserModal,
  setupTourStep: state.onboardingDomain.setupTourStep,
});

const mapDispatchToProps = {
  setShowAddUserModal,
  setShowAddCSVUserModal,
  resetCSVUsers,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanySetup));
