import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

function unmountOnOuterClick(Component) {
  return class extends React.Component {
    triggerUnmount = e => {
      const self = ReactDOM.findDOMNode(this.ref.current);
      if (e.target !== self && !self.contains(e.target)) this.props.triggerUnmount();
    };

    componentDidMount = () => {
      window.addEventListener('mousedown', this.triggerUnmount);
    };

    componentWillUnmount = () => {
      window.removeEventListener('mousedown', this.triggerUnmount);
    };

    ref = React.createRef();

    render = () => <Component {...this.props} ref={this.ref} />;
  };
}

unmountOnOuterClick.propTypes = {
  triggerUnmount: PropTypes.func.isRequired,
};

export default unmountOnOuterClick;
