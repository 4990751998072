import { ReactComponent as Everything } from 'pages/Grow/GrowProfile/everything.svg';
import { ReactComponent as Notes } from 'pages/Grow/GrowProfile/notes.svg';
import { ReactComponent as Meetings } from 'pages/Grow/GrowProfile/meetings.svg';
import { ReactComponent as Actions } from 'pages/Grow/GrowProfile/actions.svg';
import { ReactComponent as Achievements } from 'pages/Grow/GrowProfile/achievements.svg';
import { ReactComponent as Profile } from 'pages/Grow/GrowProfile/profile.svg';

export const growProfileUrl = 'grow_profile';
export const growBoardNavigationList = [
  {
    id: 0,
    title: 'Everything',
    icon: Everything,
    filter: 'board',
  },
  {
    id: 1,
    title: 'Notes',
    icon: Notes,
    filter: 'my-notes',
  },
  {
    id: 2,
    title: 'Meetings',
    icon: Meetings,
    filter: 'meetings',
  },
  {
    id: 3,
    title: 'Tasks',
    icon: Actions,
    filter: 'goals',
  },
];
export const growNavigationList = [
  {
    id: 0,
    title: 'Everything',
    icon: Everything,
    filter: 'grow',
  },
  {
    id: 1,
    title: 'Notes',
    icon: Notes,
    filter: 'my-notes',
  },
  {
    id: 2,
    title: 'Meetings',
    icon: Meetings,
    filter: 'meetings',
  },
  {
    id: 3,
    title: 'Actions',
    icon: Actions,
    filter: 'actions',
  },
  {
    id: 4,
    title: 'Achievements',
    icon: Achievements,
    filter: 'achievements',
  },
  {
    id: 5,
    title: 'Profile',
    icon: Profile,
    filter: 'profile',
  },
];
export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
