import React, { Component } from 'react';
import Sentiment from './Sentiment';
import { APP_URL, AWS_S3_URL } from '../../../constants';
import { CHECK_UNAUTHORIZED, HEADERS, LOCALSTORAGE_GET, SIGN_OUT } from '../../../helpers';
import glasses from '../../../images/vibe/glasses.png';
import HeelixModal from '../../shared/modals/HeelixModal';

export default class SelectSentiment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sentiment: props.sentiment,
      nextAvailable: this.props.sentiment !== null,
      sentimentChanged: false,
      image:
        localStorage !== null && LOCALSTORAGE_GET('user_image_uid') !== 'null'
          ? AWS_S3_URL + LOCALSTORAGE_GET('user_image_uid')
          : `${AWS_S3_URL}public/images/defaults/user.jpg`,
      first_name: localStorage != null ? LOCALSTORAGE_GET('user_first_name') : null,
    };
  }

  componentDidMount() {
    this.getImage();
  }

  getImage() {
    if (this.state.image === null) {
      const headers = HEADERS();
      fetch(`${APP_URL}/check_image`, { headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          if (response.status === 200 && response.ok === true) {
            response.json().then(jsonResponse => {
              this.setState({
                first_name: jsonResponse.first_name,
                image: jsonResponse.image_url,
              });
            });
          } else if (response.status === 401 || response.status === 403) {
            SIGN_OUT();
          }
        });
    }
  }

  render() {
    const sentiments = this._getSentiments();

    return (
      <div className="panel">
        <div className="panel-body">
          <div className="vibe-options-wrap">
            <div>
              <p className="bold vibe-question">{this.props.raw_text.question}</p>
            </div>
            <div className="pull-left stretch">
              <div className="vibe-sentiments-list">{sentiments}</div>
            </div>
            <div className="clear text-muted u-sizevr-1 vibe-anon">
              <span className="seen-by">{this.props.raw_text.seen_by_you}</span>
              <span className="anonymous-vibe" onClick={() => this.props.showAnonDescModal()}>
                <img src={glasses} className="icon" alt="" style={{ width: '24px' }} />
                {this.props.raw_text.your_vibe_is_anonymous}
              </span>
            </div>
          </div>
        </div>
        {this.props.tileRealignHandler !== undefined || this.props.hideCancelBar ? null : (
          <div className="panel-footer clear">
            <span
              onClick={() => this.props.redirectToFeed()}
              className="btn btn-previous"
              id="cancel-vibe-btn"
            >
              Cancel Vibe
            </span>
          </div>
        )}
        <HeelixModal
          title="Before your Vibe..."
          showModal={this.props.showOneTimeVibeModal}
          center
          hideModal={this.props.closeOneTimeVibeModal}
        >
          <h2>
            Just a reminder that you can only Vibe once each week. Once it's recorded you will need
            to wait until the next week to record a new one.
          </h2>
          <div onClick={() => this.props.closeOneTimeVibeModal()}>
            <button type="button" className="">
              Got it!
            </button>
          </div>
        </HeelixModal>
      </div>
    );
  }

  // on option click in Hows work going?
  selectSentiment(sentiment) {
    if (this.props.shouldSeeOneTimeVibeModal && !this.props.shownOneTimeVibeDescAlready) {
      this.props.openOneTimeVibeModal();
    } else {
      const changed = sentiment !== this.state.sentiment;
      this.setState({ sentiment, nextAvailable: true, sentimentChanged: changed });
      this.next(sentiment, changed);
    }
  }

  // onClick next button
  next(sentiment = null, sentimentChanged = null) {
    if (sentiment === null) {
      sentiment = this.state.sentiment;
    }
    if (!sentimentChanged) {
      sentimentChanged = this.state.sentimentChanged;
    }
    this.props.selectVibeFactors(sentiment);
    this.props.nextStep({ sentiment, sentimentChanged });
  }

  _getNextButton() {
    if (this.state.nextAvailable) {
      return (
        <a className="btn btn-md btn-primary btn-fixed btn-next" onClick={this.next.bind(this)}>
          Next
        </a>
      );
    }
    return <span className="btn btn-md btn-primary btn-fixed btn-next disabled">Next</span>;
  }

  _getSentiments() {
    return this.props.scale.map(sentiment => {
      const selected = sentiment.point === this.state.sentiment;

      return (
        <Sentiment
          sentiment={sentiment}
          selected={selected}
          selectChoice={this.selectSentiment.bind(this)}
          key={sentiment.point}
        />
      );
    });
  }
}
