import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CvvDetails from './CvvDetails';

class CvvModal extends Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        dialogClassName="win-modal"
        backdrop
        keyboard
      >
        <div className="modal-inner-wrap cvv-modal">
          <div className="close-cross" onClick={this.props.handleClose} />
          <div className="cvv-modal-title">What is CVV?</div>
          <div className="row cards">
            <CvvDetails
              body="For VISA and Mastercard, the Card Verification Value is on the back of the card."
              image="VisaLarge"
              title="3-digit Card Verification Code"
            />
            <CvvDetails
              body="For American Express, the Card Verification Value is on the front of the card."
              image="AMEXLarge"
              title="4-digit Card Verification Code"
            />
          </div>
        </div>
      </Modal>
    );
  }
}

CvvModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default CvvModal;
