import styled from '@emotion/styled';
import React from 'react';
import mapPlans from 'components/payments/PaymentChoose/shared/mapPlans';
import { connect } from 'react-redux';

const Container = styled.div`
  margin-bottom: 30px;
  border: 1px solid #d3efff;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  display: inline-flex;
`;

const Input = styled.input`
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;

  &:checked + label {
    background: #11a9ff;
    color: white;
  }

  &:disabled + label {
    color: #d5d5d5 !important;
  }
`;

const Label = styled.label`
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  text-align: center;
  line-height: 20px;
  padding: 12px 16px;
  min-height: 68px;
  color: #11a9ff;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    border-right: 1px solid #d3efff;
  }
`;

const DisabledLabel = styled.div`
  font-size: 9px;
  line-height: 11px;
`;

const MobilePricingPicker = ({ value, setValue, planSize, currentPlanSize }) => (
  <Container>
    {mapPlans({ planSize, currentPlanSize }, ({ plan: { key, name }, disabled }) => {
      const checked = key === value;
      if (disabled && checked) setValue('pro');
      return (
        <>
          <Input
            checked={checked}
            id={`plan${key}`}
            name="switch-plans"
            type="radio"
            value={key}
            onChange={e => setValue(e.target.value)}
            disabled={disabled}
          />
          <Label htmlFor={`plan${key}`}>
            {name instanceof Function ? name() : name}
            {Boolean(disabled) && <DisabledLabel>Not Available</DisabledLabel>}
          </Label>
        </>
      );
    })}
  </Container>
);

const mapState = state => ({
  currentPlanSize: state.plansDomain.planSize,
});

export default connect(mapState)(MobilePricingPicker);
