import React, { Component } from 'react';
import FactorsTooltipHorizontal from './FactorsTooltipHorizontal';

class FactorsBarHorizontal extends Component {
  state = {
    hovered: false,
    clicked: false,

    awesomeWidth: 0,
    goodWidth: 0,
    okayWidth: 0,
    badWidth: 0,
    terribleWidth: 0,
  };

  componentDidMount = () => {
    setTimeout(
      () =>
        this.setState({
          awesomeWidth: (this.props.data.awesome * this.props.chartWidth) / this.props.maxStep,
          goodWidth: (this.props.data.good * this.props.chartWidth) / this.props.maxStep,
          okayWidth: (this.props.data.okay * this.props.chartWidth) / this.props.maxStep,
          badWidth: (this.props.data.bad * this.props.chartWidth) / this.props.maxStep,
          terribleWidth: (this.props.data.terrible * this.props.chartWidth) / this.props.maxStep,
        }),
      0
    );
  };

  componentWillReceiveProps = nextProps => {
    if (
      JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data) ||
      nextProps.chartWidth !== this.props.chartWidth
    ) {
      this.setState({
        awesomeWidth: (nextProps.data.awesome * this.props.chartWidth) / nextProps.maxStep,
        goodWidth: (nextProps.data.good * this.props.chartWidth) / nextProps.maxStep,
        okayWidth: (nextProps.data.okay * this.props.chartWidth) / nextProps.maxStep,
        badWidth: (nextProps.data.bad * this.props.chartWidth) / nextProps.maxStep,
        terribleWidth: (nextProps.data.terrible * this.props.chartWidth) / nextProps.maxStep,
      });
    }
  };

  render() {
    return (
      <div
        className={`bar ${this.props.className}`}
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
        onClick={() => this.setState({ clicked: !this.state.clicked })}
        style={{
          width:
            this.state.awesomeWidth +
            this.state.goodWidth +
            this.state.okayWidth +
            this.state.badWidth +
            this.state.terribleWidth,
        }}
      >
        <div className="awesome-subbar subbar" style={{ width: this.state.awesomeWidth }} />
        <div className="good-subbar subbar" style={{ width: this.state.goodWidth }} />
        <div className="okay-subbar subbar" style={{ width: this.state.okayWidth }} />
        <div className="bad-subbar subbar" style={{ width: this.state.badWidth }} />
        <div className="terrible-subbar subbar" style={{ width: this.state.terribleWidth }} />
        <FactorsTooltipHorizontal
          data={this.props.data}
          isMounted={this.state.hovered || this.state.clicked}
          delayTime={300}
        />
      </div>
    );
  }
}

export default FactorsBarHorizontal;
