import React from 'react';
import styled from '@emotion/styled';
import SwitchButton from 'components/shared/buttons/SwitchButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 25px;
`;

const FilterName = styled.p`
  margin-bottom: 0;
  line-height: unset;
  font-size: 13px;
  color: ${props => (props.enabled ? '#6A6A6A' : '#A6A6A6')};
`;

const FilterSwitch = styled.div`
  line-height: 26px;
`;
const SharedNotesFilter = ({ label, checked, filter }) => (
  <Wrapper className="toggle_target">
    <FilterSwitch>
      <SwitchButton turnedOn={checked} onClick={filter} />
    </FilterSwitch>
    <FilterName enabled={checked}>{label}</FilterName>
  </Wrapper>
);

export default SharedNotesFilter;
