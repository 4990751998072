import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { POST_ACTIONS } from '../../redux/actions/postActions';
import menuDots from '../../images/feed/menu_dots_heelix.svg';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';
import { APP_URL } from '../../constants';

const mapStateToProps = state => ({
  user: state.usersDomain.user,
});

const mapDispatchToProps = dispatch => ({
  removeDismissedPost: id => dispatch(POST_ACTIONS.removeDeletedPost(id)),
});
class AppAlertsDropdown extends Component {
  state = {
    open: false,
  };

  outerClick = () => {
    this.setState({ open: !this.state.open });
    document.removeEventListener('click', this.outerClick);
  };

  toggleDropdown(e) {
    if (this.state.open) {
      e.nativeEvent.stopImmediatePropagation();
      document.removeEventListener('click', this.outerClick);
    } else document.addEventListener('click', this.outerClick);
    this.setState({ open: !this.state.open });
  }

  dismissPost = forOneDay => {
    const { id } = this.props.post;
    const headers = HEADERS();
    let url = `/post/dismiss/${id}`;
    if (forOneDay) {
      url += '?remind_tomorrow=true';
    }
    fetch(APP_URL + url, { method: 'put', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(() => {
          this.props.removeDismissedPost(id);
        });
      });
  };

  render() {
    const forOneDay = true;

    return (
      <div
        className="app-alerts-dropdown"
        custom-open={this.state.open.toString()}
        onClick={e => this.toggleDropdown(e)}
      >
        <img src={menuDots} />
        <div className="options-wrap">
          <div onClick={() => this.dismissPost(forOneDay)}>Remind me again tomorrow</div>
          <div onClick={() => this.dismissPost()}>Dismiss</div>
        </div>
      </div>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppAlertsDropdown));
