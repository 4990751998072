import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/core';
import { ReactComponent as BlueTriangle } from 'pages/Insights/OrganisationInsights/media/blue-triangle.svg';
import whiteCheckmark from 'pages/Insights/OrganisationInsights/media/white-checkmark.svg';
import getScoreColor from 'pages/Insights/OrganisationInsights/getScoreColor';

export const SCORE_MOBILE_BREAKPOINT = '1200px';

export const Table = styled.table`
  table-layout: fixed;

  th,
  td {
    vertical-align: middle;

    &:last-child {
      min-width: 140px;
      @media (min-width: ${SCORE_MOBILE_BREAKPOINT}) {
        min-width: 300px;
      }
    }
  }

  th:first-child {
    text-align: left;
    padding-left: 30px;
  }
`;

export const Th = styled.th`
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #9b9b9b;
  vertical-align: middle;
  padding: 6px 3px;
  text-align: center;

  ${({ hideOnMobile }) =>
    hideOnMobile &&
    css`
      display: none;
      @media (min-width: ${hideOnMobile}) {
        display: table-cell;
      }
    `};

  &:first-child {
    vertical-align: middle;
    border-left: none;
  }
`;

const Td = styled.td`
  padding: 6px 3px;
  vertical-align: top;
`;

const backgroundTransition = keyframes`
  from {
    background: #FBECB0;
  }
  to {
    background: #FFFFFF;
  }
`;

export const Tr = styled.tr`
  animation: ${({ highlight }) =>
    highlight &&
    css`
      ${backgroundTransition} 8s forwards
    `};

  td {
    padding-top: ${({ depth, isHierarchical, index }) => {
      if (!isHierarchical) return index <= 1 ? '18px' : '6px';
      return depth <= 1 ? '18px' : '6px';
    }};
  }
`;

export const TrTagged = styled.tr`
  animation: ${({ highlight }) =>
    highlight &&
    css`
      ${backgroundTransition} 8s forwards
    `};

  td {
    padding-top: 6px;
  }
`;

export const ScoreLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BoldColumn = styled(Td)`
  text-align: center;
  font-weight: ${({ isHierarchical, depth }) =>
    (!isHierarchical && depth === 0) || (isHierarchical && (depth === 0 || depth === 1))
      ? 'bold'
      : 'normal'};
`;

export const MembersColumn = styled(BoldColumn)`
  font-size: 12px;
  min-width: 87px;
  cursor: pointer;

  display: none;
  @media (min-width: 450px) {
    display: table-cell;
  }
`;

export const ScoreColumn = styled(BoldColumn)`
  color: ${({ score, isParticipationView }) =>
    getScoreColor(score, isParticipationView)?.backgroundColor};
  font-size: 13px;
  min-width: 67px;
  cursor: pointer;
  display: none;
  @media (min-width: ${SCORE_MOBILE_BREAKPOINT}) {
    display: table-cell;
  }
`;

export const NameColumnContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TeamTagSidebarContainer = styled(NameColumnContainer)`
  margin-bottom: 13px;
  font-size: 13px;
  cursor: pointer;
  color: ${({ checked }) => (checked ? '#11A9FF' : '#323232')};
`;

export const NameColumn = styled(BoldColumn)`
  width: 99%;
  text-align: left;
  position: relative;
  font-size: ${({ isHierarchical, depth }) => {
    if (!isHierarchical) {
      return depth === 0 ? '16px' : '13px';
    }
    switch (depth) {
      case 0:
        return '16px';
      case 1:
        return '14px';
      case 2:
        return '13px';
      default:
        return '11px';
    }
  }};

  @media (min-width: ${SCORE_MOBILE_BREAKPOINT}) {
    font-size: ${({ isHierarchical, depth }) => {
      if (!isHierarchical) {
        return depth === 0 ? '18px' : '14px';
      }
      switch (depth) {
        case 0:
          return '18px';
        case 1:
          return '16px';
        case 2:
          return '14px';
        default:
          return '13px';
      }
    }};
  }
`;
export const ScoreChartColumn = styled(Td)`
  border-left: #ebebeb 1px solid;
  border-right: #ebebeb 1px solid;
  position: relative;
  padding-left: 0;
  padding-right: 0;
`;

export const ChartLines = styled.div`
  position: absolute;
  border-left: #ebebeb 1px dashed;
  border-right: #ebebeb 1px dashed;
  content: '';
  width: 50%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  margin-left: auto;
  margin-right: auto;
`;

export const ChartCenterLine = styled.div`
  width: 1px;
  background: #d2d2d2;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
`;

export const calculateScoreWidth = (score, isParticipationView) => {
  return score === null ? null : `${isParticipationView ? score : (score + 100) / 2}%`;
};

export const calculateWidth = (score, isParticipationView, isMobile = false) => {
  const nullWidth = isMobile ? '15%' : '6.67%';
  return calculateScoreWidth(score, isParticipationView) ?? nullWidth;
};

export const BlueLine = styled.div`
  width: 1px;
  background: #88d4ff;
  height: 100%;
  position: absolute;
  top: ${({ depth }) => (depth === 0 ? '18px' : 0)};
  left: ${({ width }) => width};

  left: ${({ score, isParticipationView }) => calculateWidth(score, isParticipationView, true)};
  @media (min-width: ${SCORE_MOBILE_BREAKPOINT}) {
    left: ${({ score, isParticipationView }) => calculateWidth(score, isParticipationView)};
  }
`;

export const OrangeLine = styled(BlueLine)`
  background: #f1825a;
`;

export const BlueTriangleStyled = styled(BlueTriangle)`
  position: absolute;
  top: -10px;
  left: -3px;
`;

export const OrangeTriangle = styled(BlueTriangleStyled)`
  path {
    fill: #f1825a;
  }
`;

export const CheckBox = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 15px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  cursor: pointer;
  flex-shrink: 0;

  ${({ isChecked }) =>
    isChecked &&
    css`
      background: url(${whiteCheckmark}) center center no-repeat, #11a9ff;
      border: 1px solid #11a9ff;
    `};
`;

export const TagLabel = styled.span`
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  padding: 7px 16px;
  background: #11a9ff;
  border-radius: 25px;
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 5px;
`;
