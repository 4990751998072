import React from 'react';
import styled from '@emotion/styled';
import GrowProfileVibeButton from 'pages/Grow/GrowProfile/GrowProfileVibeButton';
import { getDayName } from 'pages/Grow/Utils/utils';
import { monthNames } from 'pages/Grow/GrowProfile/constants';

const TodayWrapper = styled.div`
  padding: 18px 25px 25px 18px;
  border-bottom: 1px solid #e7e7e7;
  border-left: 3px solid #11a9ff;
`;
const TodayTitle = styled.h3`
  font-size: 13px;
  color: #11a9ff;
  & strong {
    font-weight: bold;
  }
`;
const TodayDate = styled.strong`
  font-size: 24px;
  margin-right: 6px;
`;

const TodayDescription = styled.p`
  color: #a6a6a6;
  font-size: 14px;
  margin-bottom: 0;
`;
const AddVibeTodayButton = styled.button`
  width: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background: initial;
  margin-top: 26px;

  & svg {
    width: 32px;
    height: 32px;
    & path {
      fill: #11a9ff;
    }
    & rect {
      fill: white;
    }
  }
`;

const TodayEmptyAgenda = () => {
  const date = new Date();
  const day = date.getDate();
  const dayName = getDayName(date);
  const monthName = monthNames[date.getMonth()];

  return (
    <TodayWrapper>
      <TodayTitle>
        <TodayDate>{day}</TodayDate> {monthName}, {dayName} <strong>Today</strong>
      </TodayTitle>
      <TodayDescription>
        Have a new action, a meeting to run or a win you’ve just achieved?
      </TodayDescription>
      <TodayDescription>Hit the + button to create a new item now.</TodayDescription>
      <GrowProfileVibeButton VibeButtonContent={AddVibeTodayButton} customAction="create-note" />
    </TodayWrapper>
  );
};

export default TodayEmptyAgenda;
