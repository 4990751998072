import PropTypes from 'prop-types';
import OptionButton from 'components/vibes/createForms/partials/OptionButton';
import styled from '@emotion/styled';
import React from 'react';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 50%;
  > div {
    margin-right: ${props => (props.mode === noteTypes.goal ? '0' : '20px')};
    margin-left: ${props => (props.mode === noteTypes.goal ? '20px' : '0')};
  }
`;
const ValuesAndGoalsButtons = ({
  valuesToggle,
  goalsToggle,
  setValuesToggle,
  setGoalsToggle,
  mode,
  shouldSeeCompanyValues,
  converting,
}) => {
  return mode !== noteTypes.freeText ? (
    <ButtonWrap mode={mode} converting={converting}>
      {!valuesToggle && shouldSeeCompanyValues && (
        <OptionButton
          onClick={() => setValuesToggle(true)}
          label="Add company value"
          type="white"
        />
      )}
      {!goalsToggle && (
        <OptionButton onClick={() => setGoalsToggle(true)} label="Add KPI" type="white" />
      )}
    </ButtonWrap>
  ) : null;
};

ValuesAndGoalsButtons.propTypes = {
  goalsToggle: PropTypes.bool.isRequired,
  setGoalsToggle: PropTypes.func.isRequired,
  setValuesToggle: PropTypes.func.isRequired,
  valuesToggle: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  shouldSeeCompanyValues: PropTypes.bool.isRequired,
};

export default ValuesAndGoalsButtons;
