import React, { Component } from 'react';
import FactorsBarHorizontal from './FactorsChartHorizontal/FactorsBarHorizontal';

class FactorsChartHorizontal extends Component {
  state = {
    chartWidth: 1,
  };

  handleResize = () => {
    this.setState({
      chartWidth: parseInt(window.getComputedStyle(this.self).getPropertyValue('width'), 10),
    });
  };

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
  };

  render() {
    const maxValue = this.props.data.reduce((a, b) => {
      const sumCurrent =
        b.current.terrible + b.current.bad + b.current.okay + b.current.good + b.current.awesome;
      const sumPrevious = this.props.showPrevious
        ? b.previous.terrible +
          b.previous.bad +
          b.previous.okay +
          b.previous.good +
          b.previous.awesome
        : 0;
      const sum = sumCurrent > sumPrevious ? sumCurrent : sumPrevious;
      return a > sum ? a : sum;
    }, 0);
    const maxStep = maxValue + 5;
    return (
      <div className="factors-chart" ref={el => (this.self = el)}>
        {[...Array(6)].map((obj, index) => (
          <div
            className={`chart-step ${index === 0 ? 'start' : ''} ${index === 5 ? 'end' : ''}`}
            style={{ left: `${index * 20}%` }}
            key={index}
          >
            {!this.props.dashboard && index === 0 ? <div className="y-axis-legend">0</div> : ''}
            {!this.props.dashboard && index === 5 ? (
              <div className="y-axis-legend">{maxStep}</div>
            ) : (
              ''
            )}
          </div>
        ))}
        {this.props.data.map((data, index) => (
          <div
            className={`chart-bar-group ${this.props.showPrevious ? 'with-previous' : ''}`}
            key={index}
          >
            {this.props.showPrevious ? (
              <FactorsBarHorizontal
                data={data.previous}
                chartWidth={this.state.chartWidth}
                maxStep={maxStep}
                className="previous"
              />
            ) : (
              ''
            )}
            <FactorsBarHorizontal
              data={data.current}
              chartWidth={this.state.chartWidth}
              maxStep={maxStep}
              className="current"
            />
          </div>
        ))}
      </div>
    );
  }
}

export default FactorsChartHorizontal;
