import { withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import loginUser from 'components/signup/integration/loginUser';
import { APP_URL } from '../../../constants';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../../helpers';
import SetPassword from './SetPassword';
import steps from '../steps';
import SetTeamName from './SetTeamName';
import signInUser from './signInUser';
import ErrorPage from '../ErrorPage';

const FinalizeUser = ({ location, history }) => {
  const [response, setResponse] = useState({});
  const [step, setStep] = useState(steps.password);
  const [nameError, setNameError] = useState('');
  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    password: '',
    team_name: '',
    terms_and_privacy_accepted: '',
  });
  const [isFetching, setIsFetching] = useState(false);
  const [googleData, setGoogleData] = useState(undefined);
  const [integrationError, setIntegrationError] = useState('');

  useEffect(() => {
    if (query.appleToken) return;
    verifyToken();

    if (location.state && location.state.googleData) {
      setGoogleData(location.state.googleData);
    }
  }, []);

  const verifyToken = async () => {
    const url = location.pathname + location.search;
    const headers = HEADERS();
    const response = await fetch(`${APP_URL}${url}`, { method: 'get', headers });
    const json = await response.json();

    setResponse(json);
  };

  const handlePassword = response => {
    const { first_name, last_name, password } = response;
    setData({
      ...data,
      first_name,
      last_name,
      password,
    });

    if (!first_name) {
      history.push(`/confirm_email?step=${steps.password}`);
      return setStep(steps.password);
    }
    if (first_name && last_name) {
      history.push(
        `/confirm_email?${qs.stringify({
          ...query,
          step: steps.teamName,
        })}`
      );
      return setStep(steps.teamName);
    }
  };

  const handleTeam = response => {
    const { team_name, terms_and_privacy_accepted } = response;
    if (isIntegration()) {
      return signUpApple({ ...data, team_name, terms_and_privacy_accepted });
    }
    signUp({ ...data, team_name, terms_and_privacy_accepted });
  };

  const signUpApple = async data => {
    const onSuccess = () => {
      history.push('/');
    };

    const onFailure = () => {
      history.push(`/confirm_email?step=${steps.registerError}`);
    };

    const body = new FormData();
    body.append('first_name', data.first_name);
    body.append('last_name', data.last_name);
    body.append('name', data.team_name);
    body.append('terms', data.terms_and_privacy_accepted);
    body.append('appleToken', query.appleToken);
    body.append('refreshToken', query.refreshToken);
    const response = await fetch(`${APP_URL}/register_apple`, { method: 'post', body });
    const json = await response.json();
    if (response.status === 422) {
      const errors = Object.values(json);
      if (errors.length) {
        return setIntegrationError(errors[0]);
      }
    }
    await loginUser({ token: json.accessToken }, onFailure);
    onSuccess();
  };

  const signUp = async data => {
    setIsFetching(true);
    const body = new FormData();
    body.append('first_name', data.first_name);
    body.append('last_name', data.last_name);
    body.append('email', response.email);
    body.append('password', data.password);
    body.append('name', data.team_name);
    body.append('confirmation_token', response.token);

    const registerResponse = await fetch(`${APP_URL}/register`, { method: 'post', body });
    const authorized = CHECK_UNAUTHORIZED(registerResponse);
    const json = await authorized.json();
    if (json.errorType === 'name') {
      setNameError(json.error);
    } else if (json.error) {
      setResponse({ error: json.error });
    } else {
      const onSuccess = () => {
        history.push('/');
      };
      const onFailure = () => {
        setResponse({ error: 'Please try again.' });
      };
      signInUser(
        {
          username: response.email,
          password: data.password,
          accessToken: json.accessToken,
        },
        onSuccess,
        onFailure
      );
    }
    setIsFetching(false);
  };

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const queryStep = query.step;

  const isIntegration = () => googleData || query.appleToken;

  if (response.error && !isIntegration()) {
    return <ErrorPage body={response.error} title="Something went wrong" />;
  }
  if (response.message || isIntegration()) {
    switch (queryStep) {
      case undefined:
      case steps.password:
        return <SetPassword handlePassword={handlePassword} />;
      case steps.teamName:
        return (
          <SetTeamName
            handleTeam={handleTeam}
            loading={isFetching}
            googleData={googleData}
            nameError={nameError}
            integrationError={integrationError}
          />
        );
      case steps.registerError:
        return <ErrorPage title="Something went wrong" body="Please try signing up again." />;
      default:
        return null;
    }
  }
  return null;
};

export default withRouter(FinalizeUser);
