import mimeTypes from '../GooglePicker/mimeTypes';

const verifyAvatarImage = file => {
  if (file && 'type' in file && mimeTypes.AVATAR.includes(file.type)) {
    if ('size' in file && file.size >= 6 * 1024 * 1024) {
      alert('Maximum allowed size for an image is 6MB');
      return false;
    }
  } else {
    alert('Only jpeg, png, bmp, svg images are allowed');
    return false;
  }

  return true;
};

export default verifyAvatarImage;
