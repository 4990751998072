import React, { Component } from 'react';

class Flash extends Component {
  render() {
    let image;
    switch (this.props.flashType) {
      case 'warning':
        image = (
          <svg
            width="23"
            height="19"
            viewBox="0 0 23 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.8284 0.698169C11.2193 0.0664309 12.1382 0.0664303 12.5291 0.698169L22.4665 16.7576C22.8788 17.4238 22.3996 18.2838 21.6162 18.2838H1.74133C0.957905 18.2838 0.478733 17.4238 0.890969 16.7576L10.8284 0.698169ZM12.3688 7.16943C12.3688 6.80836 12.0599 6.51564 11.6787 6.51564C11.2976 6.51564 10.9886 6.80836 10.9886 7.16943V11.746C10.9886 12.107 11.2976 12.3997 11.6787 12.3997C12.0599 12.3997 12.3688 12.107 12.3688 11.746V7.16943ZM11.6787 13.7073C12.0599 13.7073 12.3688 14 12.3688 14.3611C12.3688 14.7222 12.0599 15.0149 11.6787 15.0149C11.2976 15.0149 10.9886 14.7222 10.9886 14.3611C10.9886 14 11.2976 13.7073 11.6787 13.7073Z"
              fill="#FFBC57"
            />
          </svg>
        );
        break;
      case 'success':
        image = (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9" cy="9" r="9" fill="#4DA60B" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.08962 10.4811L5.1384 7.98057L4 9.352L8.29671 12.992L8.30802 13L14 6.14971L12.6432 5L8.08962 10.4811Z"
              fill="white"
            />
          </svg>
        );
        break;
      case 'error':
        image = (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.7306 0.00264404L18 5.27582L17.9974 12.7306L12.7242 18L5.26943 17.9974L0 12.7242L0.0026442 5.26943L5.27582 0L12.7306 0.00264404Z"
              fill="#F5351B"
            />
            <path
              d="M10 4.66667C10 4.29848 9.55228 4 9 4C8.44772 4 8 4.29848 8 4.66667V9.33333C8 9.70152 8.44772 10 9 10C9.55228 10 10 9.70152 10 9.33333V4.66667Z"
              fill="white"
            />
            <path
              d="M10 13C10 12.4477 9.55228 12 9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14C9.55228 14 10 13.5523 10 13Z"
              fill="white"
            />
          </svg>
        );
        break;
      case 'notice':
        image = (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9" cy="9" r="9" fill="#11A9FF" />
            <path
              d="M8.0332 10.3809C8.0332 9.83398 8.09961 9.39844 8.23242 9.07422C8.36523 8.75 8.60742 8.43164 8.95898 8.11914C9.31445 7.80273 9.55078 7.54688 9.66797 7.35156C9.78516 7.15234 9.84375 6.94336 9.84375 6.72461C9.84375 6.06445 9.53906 5.73438 8.92969 5.73438C8.64062 5.73438 8.4082 5.82422 8.23242 6.00391C8.06055 6.17969 7.9707 6.42383 7.96289 6.73633H6.26367C6.27148 5.99023 6.51172 5.40625 6.98438 4.98438C7.46094 4.5625 8.10938 4.35156 8.92969 4.35156C9.75781 4.35156 10.4004 4.55273 10.8574 4.95508C11.3145 5.35352 11.543 5.91797 11.543 6.64844C11.543 6.98047 11.4688 7.29492 11.3203 7.5918C11.1719 7.88477 10.9121 8.21094 10.541 8.57031L10.0664 9.02148C9.76953 9.30664 9.59961 9.64062 9.55664 10.0234L9.5332 10.3809H8.0332ZM7.86328 12.1797C7.86328 11.918 7.95117 11.7031 8.12695 11.5352C8.30664 11.3633 8.53516 11.2773 8.8125 11.2773C9.08984 11.2773 9.31641 11.3633 9.49219 11.5352C9.67188 11.7031 9.76172 11.918 9.76172 12.1797C9.76172 12.4375 9.67383 12.6504 9.49805 12.8184C9.32617 12.9863 9.09766 13.0703 8.8125 13.0703C8.52734 13.0703 8.29688 12.9863 8.12109 12.8184C7.94922 12.6504 7.86328 12.4375 7.86328 12.1797Z"
              fill="white"
            />
          </svg>
        );
    }
    return (
      <div className={`flash ${this.props.flashType}`}>
        {image}
        {this.props.flashMessage}
        {this.props.dismissable ? (
          <div className="dismiss-cross" onClick={() => this.props.closeFlash()} />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default Flash;
