import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { APP_URL } from '../../../constants';
import { LOCALSTORAGE_GET } from '../../../helpers';

class AnalyticsShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      dateString: '',
      notificationFilterColumn: 'email_enqueued_at',
      days: [],
      stats: [],
      rows: [],
      distances: [],
      week: '',
      year: '',
    };
  }

  componentDidMount() {
    this.setState({ date: this.props.match.date });
    const url = this.props.location.pathname + this.props.location.search;
    this.fetchData(url);
  }

  prevDay(e) {
    e.preventDefault();
    const url = this.props.location.pathname + this.props.location.search;
    this.fetchData(url, 'prev');
  }

  nextDay(e) {
    e.preventDefault();
    const url = this.props.location.pathname + this.props.location.search;
    this.fetchData(url, 'next');
  }

  titleize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  fetchData(url, prevNext = null) {
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);
    const addToUrl = prevNext ? `&prev_next=${prevNext}` : '';
    fetch(APP_URL + url + addToUrl, { method: 'get', headers })
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({
            dateString: jsonResponse.dateString,
            stats: jsonResponse.stats,
            distances: JSON.parse(jsonResponse.distances),
            rows: JSON.parse(jsonResponse.rows),
            week: JSON.parse(jsonResponse.week),
            year: JSON.parse(jsonResponse.year),
          });
          if (jsonResponse.date !== this.props.match.params.date) {
            this.props.history.push(
              `/admin/analytics/${jsonResponse.date}/show?notifications_filter_column=${this.state.notificationFilterColumn}`
            );
          }
        });
      })
      .catch(() => {});
  }

  responseColour(percent) {
    if (percent >= 0 && percent <= 30) {
      return '#CD3C40';
    }
    if (percent >= 31 && percent <= 70) {
      return '#FBAA00';
    }
    if (percent >= 71 && percent <= 100) {
      return '#26C381';
    }
  }

  setFilterColumn(e, column) {
    e.preventDefault();
    const search = `?notifications_filter_column=${column}`;
    this.setState({ notificationFilterColumn: column });
    const url = this.props.location.pathname + search;
    this.fetchData(url);
    if (search !== this.props.location.search) {
      this.props.history.push(url);
    }
  }

  render() {
    return (
      <div>
        <Link
          to={`/admin/analytics/${this.state.year}/${this.state.week}?notifications_filter_column=${this.state.notificationFilterColumn}`}
        >
          &lt; &nbsp;View Week
        </Link>
        <h1>Time to Vibe</h1>
        <h2>{this.state.dateString}</h2>
        <a href="#" onClick={e => this.prevDay(e)}>
          Previous Day
        </a>
        &nbsp;-&nbsp;
        <a href="#" onClick={e => this.nextDay(e)}>
          Next Day
        </a>
        <div className="pull-right">
          <strong>Showing results for: </strong>
          <a
            className={
              this.state.notificationFilterColumn === 'email_delivered_at'
                ? 'invisible-link no-underline'
                : ''
            }
            onClick={e => this.setFilterColumn(e, 'email_delivered_at')}
          >
            Delivered
          </a>
          &nbsp;-&nbsp;
          <a
            className={
              this.state.notificationFilterColumn === 'email_enqueued_at'
                ? 'invisible-link no-underline'
                : ''
            }
            onClick={e => this.setFilterColumn(e, 'email_enqueued_at')}
          >
            Enqueued
          </a>
        </div>
        <table className="table u-margin-top-md">
          <tbody>
            <tr>
              <th />
              <th>Sent</th>
              {this.state.distances.map(
                (distance, key) => (
                  <th key={key}>{distance.period}</th>
                ),
                this
              )}
              <th>Overall</th>
            </tr>
            {this.state.rows.map((row, key) => (
              <tr key={key}>
                <td>
                  <strong>{row.time}</strong>
                </td>
                <td>{row.user_ids.length}</td>
                {row.distances.map((distance, key) => (
                  <td key={key}>
                    {distance.within_range}
                    &nbsp;
                    <span style={{ color: this.responseColour(row.percent) }}>
                      ({distance.percent}
                      %)
                    </span>
                  </td>
                ))}
                <td>
                  {row.overall}
                  &nbsp;
                  <span style={{ color: this.responseColour(row.percent) }}>
                    ({row.percent}
                    %)
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <h3>Stats</h3> */}
        {/* <table className="table"> */}
        {/* <tbody> */}
        {/* <tr> */}
        {/* {this.state.stats.map((stat, key) => */}
        {/* <td key={key}> */}
        {/* <strong>{this.titleize(stat.state)}: {stat.total}</strong> */}
        {/* </td> */}
        {/* )} */}
        {/* </tr> */}
        {/* </tbody> */}
        {/* </table> */}
      </div>
    );
  }
}

export default AnalyticsShow;
