import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class RedirectToHeelixModal extends Component {
  state = {
    redirect: false,
    showPopup: false,
  };

  componentDidMount() {
    if (
      window.location.hostname.localeCompare('app.heelix.com') != 0 &&
      window.location.hostname.localeCompare('beta.heelix.com') != 0
    ) {
      setTimeout(() => this.setState({ redirect: true }), 10000);
      this.setState({ showPopup: true });
    }
  }

  render() {
    return this.state.redirect ? (
      (window.top.location.href = 'https://app.heelix.com')
    ) : (
      <div>
        <Modal
          className="redirect-heelix-modal"
          show={this.state.showPopup}
          onHide={this.props.hideUpgradeModal}
        >
          <div className="modal-header no-border">
            <img src="https://s3-ap-southeast-2.amazonaws.com/jv-assets/public/Heelix-Logo-02.png" />
          </div>
          <div className="modal-body" style={{ minHeight: '182px' }}>
            <h2 className="redirect-modal-subtitle">
              We're excited to announce that Jobvibe is now Heelix.
            </h2>
            <p>
              Same company, different name. Why? Because DNA are the building blocks of life. People
              are the DNA of a company. We're re-directing you to the new site now.
              <a href="https://app.heelix.com"> Click here </a>
              if you don't automatically redirect in 10 seconds.
            </p>
          </div>
        </Modal>
      </div>
    );
  }
}
export default RedirectToHeelixModal;
