import React from 'react';
import { PropTypes } from 'prop-types';
import DiscussionTile from 'components/feed/DiscussionTile';
import PostTile from 'components/feed/PostTile';
import ShoutoutTile from 'components/feed/ShoutoutTile';
import PollAlertTile from 'components/feed/AppAlerts/PollAlertTile';
import PollTile from 'components/feed/PollTile';
import PostAlertTile from 'components/feed/AppAlerts/PostAlertTile';
import FeedbackAlertTile from 'components/feed/AppAlerts/FeedbackAlertTile';

const TeamContribution = ({
  post,
  exportModeOn,
  hasDeleteError,
  isPostSelected,
  handleSelectPost,
  tileRealignHandler,
  postId,
}) => (
  <>
    {post.type === 'Fix' && (
      <DiscussionTile
        key={post.id}
        showSpaceInfo
        post={post}
        type="growPost"
        tileRealignHandler={tileRealignHandler}
      />
    )}
    {post.type === 'Buzz' && (
      <PostTile
        key={post.id}
        showSpaceInfo
        post={post}
        type="growPost"
        tileRealignHandler={tileRealignHandler}
      />
    )}
    {post.type === 'Shoutout' &&
      post.shoutout &&
      (post.shoutout.recipient || post.shoutout.recipient_team) && (
        <ShoutoutTile
          postId={postId}
          key={post.id}
          showSpaceInfo
          post={post}
          type="growPost"
          exportModeOn={exportModeOn}
          hasDeleteError={hasDeleteError}
          handleSelectPost={handleSelectPost}
          isPostSelected={isPostSelected}
          tileRealignHandler={tileRealignHandler}
        />
      )}
    {post.type === 'Poll' && post.subtype === 'PollAlert' && (
      <PollAlertTile
        key={post.id}
        showSpaceInfo
        post={post}
        tileRealignHandler={tileRealignHandler}
      />
    )}
    {post.type === 'Poll' && post.subtype !== 'PollAlert' && (
      <PollTile
        key={post.id}
        showSpaceInfo
        post={post}
        type="growPost"
        tileRealignHandler={tileRealignHandler}
      />
    )}
    {(post.type === 'LeadPostAlert' || post.type === 'PostAlert') && (
      <PostAlertTile
        key={post.id}
        showSpaceInfo
        post={post}
        tileRealignHandler={tileRealignHandler}
      />
    )}
    {(post.type === 'AdminFeedbackAlert' || post.type === 'LeadFeedbackAlert') && (
      <FeedbackAlertTile
        key={post.id}
        showSpaceInfo
        post={post}
        tileRealignHandler={tileRealignHandler}
      />
    )}
  </>
);
TeamContribution.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    subtype: PropTypes.string,
    shoutout: PropTypes.shape(),
  }).isRequired,
};

export default TeamContribution;
