/** @jsx jsx */
import React, { useEffect } from 'react';
import ParticipantSelect from 'components/vibes/createForms/CreateMeeting/shared/ParticipantSelect';
import MeetingDateSelect from 'components/vibes/createForms/CreateMeeting/shared/MeetingDateSelect';
import PropTypes from 'prop-types';
import { MEETING_FREQUENCIES } from 'components/vibes/createForms/CreateMeeting/shared/shared';
import { Textarea, Title } from 'components/vibes/createForms/CreateNote/NoteTypes/layout';
import MeetingSteps from 'components/vibes/createForms/partials/MeetingSteps';
import { css, jsx } from '@emotion/core';
import moment from 'moment';

const OneOffMeeting = ({
  member,
  updateMember,
  members,
  nextMeeting,
  frequency,
  nextMeetingTime,
  setFrequencyField,
  setNextMeeting,
  setNextMeetingTime,
  setMeetingState,
  meetingState,
}) => {
  useEffect(() => {
    if (moment(nextMeeting).isAfter(moment(), 'day')) {
      setNextMeeting(null);
    }
  }, [frequency]);
  return (
    <>
      <ParticipantSelect
        memberValue={member?.value}
        updateMember={updateMember}
        members={members}
        question="Select a user or team you met with"
      />
      <MeetingDateSelect
        id="next_meeting"
        date={nextMeeting}
        setDate={setNextMeeting}
        time={nextMeetingTime}
        setTime={setNextMeetingTime}
        value={frequency}
        setValue={setFrequencyField}
        options={MEETING_FREQUENCIES}
        dateTitle="When was this meeting?"
        future={false}
      />
      <Title
        css={css`
          margin-top: 20px;
        `}
      >
        What was discussed
      </Title>
      <Textarea
        placeholder="Enter here..."
        value={meetingState.meetingContent}
        onChange={e => setMeetingState('meetingContent', e.target.value)}
        fromNote={false}
      />
      <Title>Next steps</Title>
      <MeetingSteps
        stepsCount={meetingState.stepsCount}
        steps={meetingState.steps}
        fromNote
        setMeetingState={setMeetingState}
      />
    </>
  );
};

OneOffMeeting.propTypes = {
  member: PropTypes.shape({}).isRequired,
  updateMember: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  nextMeeting: PropTypes.string.isRequired,
  setNextMeeting: PropTypes.func.isRequired,
  nextMeetingTime: PropTypes.string.isRequired,
  setNextMeetingTime: PropTypes.func.isRequired,
  frequency: PropTypes.string.isRequired,
  setFrequencyField: PropTypes.func.isRequired,
  setMeetingState: PropTypes.func.isRequired,
  meetingState: PropTypes.shape({
    meetingContent: PropTypes.string.isRequired,
    stepsCount: PropTypes.number.isRequired,
    steps: PropTypes.array.isRequired,
  }).isRequired,
};

export default OneOffMeeting;
