import React from 'react';

const OtherSection = ({
  emptyWhyFactors = false,
  handleMessageChange,
  disabled = false,
  value = '',
  title = 'If nothing above matches, use the text box below to specify',
}) => (
  <div className="panel-body u-padding-vertical-none message-input">
    <div className="centered ">
      <div className="vibe-factors">
        {!emptyWhyFactors && (
          <label
            htmlFor="message"
            className={`string optional control-label ${emptyWhyFactors ? 'empty-factors' : ''}`}
          >
            {title}
          </label>
        )}
        <textarea
          disabled={disabled}
          value={value}
          name="message"
          id="additional-message"
          placeholder="Comments are anonymous."
          className="string optional form-control"
          cols="30"
          rows="4"
          onChange={e => handleMessageChange(e)}
        />
      </div>
    </div>
  </div>
);

export default OtherSection;
