import React from 'react';
import { EVENTS, STATUS } from 'react-joyride';
import { setAction, setShowActionModal } from 'redux/app/actions';
import { connect } from 'react-redux';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import { withRouter } from 'react-router-dom';
import { USER_ACTIONS } from 'redux/actions/userActions';
import JoyrideWrapper from './JoyrideWrapper';
import { INSIGHTS_STEPS } from '../../onboardingSteps';

const adminLeadVibeStep = [
  {
    key: 'clickPlusBtnStep',
    target: '.vibe_btn_target',
    content: (
      <div>
        To get all the data in here, it takes 30 seconds for your team (and you) to submit your
        anonymous feedback each week.
        <b>&nbsp;Let’s give it a go to see how it works.</b>
      </div>
    ),
    placement: 'left',
    hideFooter: true,
    disableOverlay: true,
    disableBeacon: true,
  },
];

const vibeStep = [
  {
    key: 'clickPlusBtnStep',
    target: '.vibe_btn_target',
    content: (
      <div>
        To get all the data in here, it takes 30 seconds to submit your anonymous feedback each
        week.
        <b>&nbsp;Let’s give it a go to see how it works.</b>
      </div>
    ),
    placement: 'left',
    hideFooter: true,
    disableOverlay: true,
    disableBeacon: true,
  },
];

class InsightsTour extends React.Component {
  state = {
    run: false,
    steps: INSIGHTS_STEPS,
    additionalSteps: this.props.isLeadOrAdmin ? adminLeadVibeStep : vibeStep,
  };

  componentDidMount() {
    if (this.state.steps[this.props.insightsTourStep]?.key !== 'takeActionBtnStep') {
      this.finishInsightsStep(this.props.insightsTourStep);
    }
    this.setState({ run: true });
  }

  componentDidUpdate(prevProps) {
    const { steps } = this.state;
    const canVibe = !this.props.userAlreadyVibed && this.props.plan !== 'free';
    if (
      canVibe &&
      steps[this.props.insightsTourStep]?.key === 'clickPlusBtnStep' &&
      this.props.showActionModal !== prevProps.showActionModal &&
      this.props.showActionModal === true
    ) {
      this.props.setAction('create-vibe');
      this.setState({ run: false });
    }
    const skippedStep = this.props.isLeadOrAdmin ? 0 : 1;
    if (
      steps[this.props.insightsTourStep]?.key === 'clickPlusBtnStep' &&
      this.props.userAlreadyVibed !== prevProps.userAlreadyVibed &&
      this.props.userAlreadyVibed === true
    ) {
      this.props.setInsightsTourStep(this.props.insightsTourStep + 1 + skippedStep);
      this.setState({ run: true });
      if (this.props.isDemoAccount) {
        this.props.updateVibeStatus(false);
      }
    }
    if (
      steps[this.props.insightsTourStep]?.key === 'takeActionBtnStep' &&
      this.props.showActionModal !== prevProps.showActionModal &&
      this.props.showActionModal === true
    ) {
      this.setState({
        run: false,
      });
      this.props.setInsightsTourStep(this.props.insightsTourStep + 1);
      this.props.setAction('create-shoutout');
      setTimeout(() => {
        this.setState({
          run: true,
        });
      }, 500);
    }
    if (steps[this.props.insightsTourStep]?.key === 'giveGetFeedbackStep') {
      this.props.setShowActionModal(true);
    }

    if (
      this.props.insightsTourStep !== prevProps.insightsTourStep &&
      steps[this.props.insightsTourStep]?.key !== 'takeActionBtnStep'
    ) {
      this.finishInsightsStep(this.props.insightsTourStep);
    }

    if (
      ['togglePeriodStep', 'takeActionBtnStep', 'clickPlusBtnStep'].includes(
        steps[this.props.insightsTourStep]?.key
      ) &&
      window.location.pathname !== '/vibes/dashboard'
    ) {
      this.setState({ run: false });
      this.props.setInsightsTourStep(-1);
    }

    if (
      steps[this.props.insightsTourStep]?.key === 'takeActionBtnStep' &&
      !this.props.feedEnabled
    ) {
      this.props.setInsightsTourStep(-1);
      this.props.setFinishedTour('insights');
      this.props.setShowActionModal(false);
      this.props.toggleTourModal(true);
    }
  }

  finishInsightsStep = step => {
    const { steps } = this.state;
    const stepName = steps[step]?.key;
    switch (stepName) {
      case 'initialInsightsStep':
        this.props.finishStep('dashboard');
        break;
      case 'clickPlusBtnStep':
        this.props.finishStep('vibe');
        break;
      case 'keyPerformanceStep':
        this.props.finishStep('metrics');
        break;
      case 'takeActionBtnStep':
        this.props.finishStep('feedback');
        break;
    }
  };

  handleJoyrideCallback = data => {
    const { action, index, status, type } = data;
    const { steps } = this.state;
    let skippedSteps = 0;
    if (action !== 'close' && [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (steps[index]?.key === 'keyPerformanceStep' && this.props.userAlreadyVibed === true) {
        skippedSteps = this.props.isLeadOrAdmin ? 1 : 2;
        this.props.setInsightsTourStep(index + 1 + skippedSteps);
      } else {
        this.props.setInsightsTourStep(index + 1);
      }
    } else if (action === 'close' || [STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ run: false });
      this.props.setInsightsTourStep(-1);
      if (status === STATUS.FINISHED) {
        this.props.finishStep('feedback');
        this.props.setFinishedTour('insights');
        this.props.setShowActionModal(false);
        this.props.toggleTourModal(true);
      }
      if (this.props.hideSamplePosts) {
        this.props.removeSampleTeam();
      }
    }
  };

  render() {
    const { steps, run, additionalSteps } = this.state;

    return (
      <>
        <JoyrideWrapper
          run={run}
          stepIndex={this.props.insightsTourStep}
          steps={steps}
          callback={this.handleJoyrideCallback}
        />
        <JoyrideWrapper
          run={run && steps[this.props.insightsTourStep]?.key === 'clickPlusBtnStep'}
          stepIndex={0}
          steps={additionalSteps}
          callback={this.handleJoyrideCallback}
          disableScrolling={false}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  action: state.app.action,
  showActionModal: state.app.showActionModal,
  insightsTourStep: state.onboardingDomain.insightsTourStep,
  isLeadOrAdmin: Boolean(
    state.usersDomain.user.isAccountAdmin || state.usersDomain.user.formalLeadTeamsIds.length
  ),
  userAlreadyVibed: !!state.usersDomain.user && state.usersDomain.user.alreadyVibed,
  isDemoAccount: !!state.usersDomain.user && state.usersDomain.user.isDemoAccount,
  plan: state.plansDomain.plan,
  hideSamplePosts: state.usersDomain.user.hideSamplePosts,
  feedEnabled: state.usersDomain.feedEnabled,
});

const mapDispatch = {
  setAction,
  setShowActionModal,
  setInsightsTourStep: step => ONBOARDING_ACTIONS.setInsightsTourStep(step),
  toggleTourModal: toggle => ONBOARDING_ACTIONS.toggleTourModal(toggle),
  setFinishedTour: tour => ONBOARDING_ACTIONS.setFinishedTour(tour),
  finishStep: step => ONBOARDING_ACTIONS.finishStep(step),
  updateVibeStatus: vibed => USER_ACTIONS.vibeStatusUpdated(vibed),
  removeSampleTeam: () => ONBOARDING_ACTIONS.removeSampleTeam(),
};

export default withRouter(connect(mapStateToProps, mapDispatch)(InsightsTour));
