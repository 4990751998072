/** @jsx jsx */
import React from 'react';
import Select from 'react-select';
import moment from 'moment';
import { jsx, css } from '@emotion/core';

const checkIfIsEarlier = (current, end, last) => {
  const isEarlier = moment(current, 'h:mm A').diff(moment(end, 'h:mm A')) < 0;
  const isLaterThanLastValue =
    last === undefined ? true : moment(last, 'h:mm A').diff(moment(current, 'h:mm A')) < 0;
  return isEarlier && isLaterThanLastValue;
};

const options = () => {
  let current = '12:00 AM';
  let last;
  const end = '11:30 PM';
  const step = 30;
  const options = [];
  options.push({ value: current, label: current });
  while (checkIfIsEarlier(current, end, last)) {
    last = current;
    current = moment(current, 'h:mm A').add(step, 'minutes').format('h:mm A');
    options.push({ value: current, label: current });
  }
  return options;
};

const TimePicker = ({ setTime, time }) => (
  <Select
    options={options()}
    name="select-time"
    id="select-time"
    onChange={date => setTime(date.value)}
    value={time}
    searchable={false}
    clearable={false}
    css={css`
      cursor: pointer;
    `}
  />
);

export default TimePicker;
