import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CONVERT_NAME } from '../../../helpers';

const MoveModal = ({ toggled, setToggled, teams, moveTo }) => {
  const [team, setTeam] = useState(teams[0].id.toString());

  return (
    <div
      className="modal in"
      id="move-users-modal"
      style={{ display: toggled ? 'block' : 'none', background: 'rgba(0,0,0,0.75)' }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header no-border centered">
            <button
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setToggled(false)}
            >
              <i className="fa fa-times" aria-hidden="true" />
            </button>
            <h2 className="modal-title">Move users to</h2>
          </div>
          <div className="modal-body centered u-padding-horizontal-md">
            <select
              name="team_id"
              id="team_id"
              className="select required form-control select2"
              tabIndex={-1}
              aria-hidden="true"
              onChange={e => setTeam(e.target.value)}
            >
              {teams.map(team => (
                <option value={team.id} key={team.id}>
                  {CONVERT_NAME(team.name)}
                </option>
              ))}
            </select>
            <div className="u-padding-vertical-sm">
              <p>
                <strong>Note: </strong>
                Users' Vibe data will stay connected to their existing team up until this point.
                Once you click confirm, new Vibe data will feed into their new team's results.
              </p>
            </div>
            <input
              type="button"
              value="Confirm"
              className="btn btn-primary u-margin-bottom-sm u-padding-horizontal-md"
              data-dismiss="modal"
              data-name="move-to"
              onClick={e => {
                e.preventDefault();
                moveTo(team);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MoveModal.propTypes = {
  toggled: PropTypes.bool.isRequired,
  setToggled: PropTypes.func.isRequired,
  teams: PropTypes.array.isRequired,
  moveTo: PropTypes.func.isRequired,
};

export default MoveModal;
