import styled from '@emotion/styled';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AssignLeadModal from '../../spaces/AssignLeadModal';

const Wrap = styled.span`
  font-size: 13px;
  color: #323232;
  font-weight: normal;
`;
const Link = styled.span`
  color: #11a9ff;
`;

const AssignLead = ({ team, fetchTeams }) => {
  const [assignLeadModalToggled, setAssignLeadModalToggled] = useState(false);

  return (
    <>
      <Wrap>
        None. <Link onClick={() => setAssignLeadModalToggled(true)}>Assign lead</Link>
      </Wrap>
      <AssignLeadModal
        showAssignLeadModal={assignLeadModalToggled}
        closeAssignLeadModalHandler={() => setAssignLeadModalToggled(false)}
        assignTo={team}
        turnOnSpinner={() => {}}
        type="teams"
        dispatchAfterFetch={fetchTeams}
      />
    </>
  );
};

AssignLead.propTypes = {
  team: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  fetchTeams: PropTypes.func.isRequired,
};

export default AssignLead;
