import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { APP_URL, AWS_S3_URL } from '../../constants';
import { BODY_CLASSES, LOCALSTORAGE_BOOL, HEADERS, AVATAR_ERROR } from '../../helpers';
import LoadingScale from '../shared/LoadingScale';
import ShoutoutModal from '../grow/ShoutoutModal';
import Auxiliary from '../layouts/Auxiliary';
import BackButton from '../shared/buttons/BackButton';
import { USER_ACTIONS } from '../../redux/actions/userActions';
import { ReactComponent as Edit } from '../../images/grow/edit.svg';

class ShowUser extends Component {
  constructor(props) {
    super(props);

    this.id = props.match.params.id;

    this.state = {
      email: '',
      role: '',
      firstName: '',
      timezone: 'Australia/Sydney',
      disabled: false,
      state: '',
      localTime: '',
      relativeTimezone: '',
      team: [],
      teams: [],
      teamsLead: [],
      auth_user: false,
      image: null,
      parentTeams: [],
      memberTeams: [],
      loaded: false,
      isUnfollowed: true,
      user: {},
      showShoutoutModal: false,
      users: [],
      availableValues: [],
      shoutoutsRemaining: 0,
      isSampleUser: false,
    };
  }

  componentDidMount() {
    BODY_CLASSES(['users', 'show', 'grow', 'jobvibe-future']);
    this.show();
    this.props.closeFlash();
  }

  componentWillUnmount() {
    BODY_CLASSES();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.location.pathname !== this.props.location.pathname &&
      nextProps.location.pathname === '/view_profile'
    ) {
      this.id = null;
      this.show();
    }
  }

  show() {
    const headers = HEADERS();
    const url = this.id ? `/users/${this.id}` : '/view_profile';

    fetch(APP_URL + url, { headers })
      .then(response => {
        if (response.status !== 200) {
          this.props.setErrorPage(response.status);
        } else {
          response.json().then(jsonResponse => {
            this.setState({
              user: jsonResponse.user,
              email: jsonResponse.user.email,
              role: jsonResponse.user.roles[0].name,
              firstName: jsonResponse.user.first_name,
              timezone: jsonResponse.user.time_zone,
              state: jsonResponse.user.state,
              disabled: jsonResponse.disabled,
              localTime: jsonResponse.local_time,
              relativeTimezone: jsonResponse.relative_timezone,
              team: jsonResponse.team ? jsonResponse.team : [],
              teamsLead: jsonResponse.teams_lead,
              authUser: jsonResponse.auth_user,
              parentTeams: jsonResponse.parent_teams,
              memberTeams: jsonResponse.member_teams,
              loaded: true,
              isUnfollowed: jsonResponse.is_unfollowed,
              users: jsonResponse.users,
              teams: jsonResponse.teams,
              availableValues: jsonResponse.companyValues,
              shoutoutsRemaining: jsonResponse.remaining_shoutouts,
              isSampleUser: jsonResponse.is_sample_user,
            });
          });
        }
      })
      .catch(() => {});
  }

  unfollowUser() {
    this.props.unfollowUser(parseInt(this.id));
    this.setState({ isUnfollowed: !this.state.isUnfollowed });
  }

  EditLink = () => {
    if (this.state.authUser) {
      return (
        <div className="row">
          <Link id="edit-profile-btn" to="/edit_profile" className="primary-link">
            <Edit />
            Edit your profile
          </Link>
        </div>
      );
    }
    if (LOCALSTORAGE_BOOL('is_company_owner')) {
      return (
        <div className="row">
          <Link id="edit-profile-btn" to={`/user/${this.id}/edit`} className="primary-link">
            <Edit />
            Edit user's profile
          </Link>
        </div>
      );
    }
  };

  showRole = () =>
    this.state.user.role.length > 0 ? (
      <span id="user-profile-role">{this.state.user.role}, </span>
    ) : null;

  showUserTeam = () => (
    <Link
      to={`/team/${this.state.user.team_id}`}
      className="user-profile-link"
      id="user-profile-team"
    >
      {this.state.team.name}
    </Link>
  );

  showShoutoutModalHandler = () => {
    this.setState({ showShoutoutModal: true });
  };

  closeShoutoutModalHandler = () => {
    this.setState({ showShoutoutModal: false });
  };

  shoutoutAddedFromModal = () => {
    this.closeShoutoutModalHandler();
    this.props.history.push('/grow_profile');
  };

  render() {
    let teams = [this.state.team];
    teams = teams.concat(this.state.parentTeams);
    teams = teams.concat(this.state.memberTeams);
    if (this.state.loaded) {
      if (this.state.isSampleUser) {
        return (
          <Auxiliary>
            <div id="back-btn-wrapper">
              <BackButton />
            </div>
            <div id="user-header-wrapper">
              <div className="row" id="user-header">
                <div className="col-xs-4 visible-xs visible-sm" id="shoutout-wrapper" />
                <div className="col-xs-4 col-md-3" id="img-wrapper">
                  <img
                    src={this.state.user.image_url}
                    alt=""
                    className="img-circle"
                    onError={e => AVATAR_ERROR(e.target)}
                  />
                </div>
                <div className="col-xs-4 visible-xs visible-sm" id="contact-wrapper" />
              </div>
            </div>

            <div id="user-details-wrapper">
              <div className="row">
                <div className="col-xs-12" id="name-role-wrapper">
                  <h2 id="user-profile-name">
                    {this.state.user.first_name} {this.state.user.last_name}
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6" id="team-time-wrapper" />
                <div className="col-xs-12 col-md-6" id="desc-tags-wrapper">
                  <p>{this.state.user.description}</p>
                  <p>
                    {this.state.user.tags.map(tag => (
                      <Link
                        key={tag}
                        to={{
                          pathname: '/users/tags',
                          search: `?t=${tag}&page=1`,
                          state: { t: tag },
                        }}
                        className="user-profile-tag"
                      >
                        #{tag}
                      </Link>
                    ))}
                  </p>
                </div>
              </div>
            </div>
          </Auxiliary>
        );
      }
      return (
        <Auxiliary>
          <div id="back-btn-wrapper">
            <BackButton />
          </div>

          <div id="user-profile-wrap">
            <div id="user-header-wrapper">
              <div className="row" id="user-header">
                {!this.state.authUser ? (
                  <>
                    <div className="col-xs-4 visible-xs visible-sm" id="shoutout-wrapper">
                      <button
                        id="give-shoutout-btn"
                        onClick={this.showShoutoutModalHandler}
                        className="user-profile-btn btn"
                      >
                        <span className="hidden-xs">Give a shoutout</span>
                        <span className="visible-xs">Shoutout</span>
                      </button>
                    </div>
                    <div className="col-xs-4 col-md-3" id="img-wrapper">
                      <img
                        src={this.state.user.image_url}
                        alt=""
                        className="img-circle"
                        onError={e => AVATAR_ERROR(e.target)}
                      />
                    </div>
                    <div className="col-xs-4 visible-xs visible-sm" id="contact-wrapper">
                      <a
                        href={`mailto:${this.state.user.email}`}
                        id="email-user-btn"
                        className="user-profile-btn btn"
                      >
                        <span className="hidden-xs">
                          Contact
                          {this.state.user.first_name}
                        </span>
                        <span className="visible-xs">Contact</span>
                      </a>
                    </div>
                  </>
                ) : (
                  <div className="col-xs-12 col-md-12" id="img-wrapper">
                    <img
                      src={this.state.user.image_url}
                      alt=""
                      className="img-circle"
                      onError={e => AVATAR_ERROR(e.target)}
                    />
                  </div>
                )}
              </div>
            </div>

            <div id="user-details-wrapper">
              <div className="row">
                <div className="col-xs-12" id="name-role-wrapper">
                  <h2 id="user-profile-name">
                    {this.state.user.first_name} {this.state.user.last_name}
                  </h2>
                  <div className="user-description-with-time-info">
                    {this.showRole()}
                    {this.showUserTeam()}
                    <i className="fa fa-map-marker" aria-hidden="true" />
                    <span id="user-profile-timezone"> {this.state.user.time_zone}</span>
                    <i className="fa fa-clock-o" aria-hidden="true" />
                    <span id="user-profile-local-time">
                      Local time: <span>{this.state.localTime}</span>
                    </span>
                  </div>
                  {this.state.teamsLead.length > 0 && (
                    <div className="user-description-with-time-info">
                      Team Lead of:&nbsp;
                      {this.state.teamsLead.map((team, i) => (
                        <React.Fragment key={i}>
                          {!!i && ', '}
                          <Link
                            to={`/team/${team.id}`}
                            className="user-profile-link"
                            id="user-profile-team"
                          >
                            {team.name}
                          </Link>
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                  <div id="edit-profile-link">{this.EditLink()}</div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6" id="desc-tags-wrapper">
                  <p>{this.state.user.description}</p>
                  <p>
                    {this.state.user.tags.map(tag => (
                      <Link
                        key={tag}
                        to={{
                          pathname: '/users/tags',
                          search: `?t=${tag}&page=1`,
                          state: { t: tag },
                        }}
                        className="user-profile-tag"
                      >
                        #{tag}
                      </Link>
                    ))}
                  </p>
                </div>
              </div>
              {!this.state.authUser && (
                <div className="row">
                  <div className="col-xs-12" id="action-buttons-wrapper">
                    <div id="action-buttons">
                      <button
                        id="give-shoutout-btn"
                        onClick={this.showShoutoutModalHandler}
                        className="user-profile-btn btn"
                      >
                        Give a shoutout
                      </button>
                      <a
                        href={`mailto:${this.state.user.email}`}
                        id="email-user-btn"
                        className="user-profile-btn btn"
                      >
                        Contact {this.state.user.first_name}
                      </a>
                      <button
                        className="primary-link user-profile-btn btn"
                        onClick={e => this.unfollowUser(e)}
                      >
                        {this.state.isUnfollowed
                          ? `Follow ${this.state.user.first_name}`
                          : `Mute posts from ${this.state.user.first_name}`}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <ShoutoutModal
                shoutoutFor={this.state.user}
                teams={this.state.teams}
                showShoutoutModal={this.state.showShoutoutModal}
                shoutoutAddedFromModal={this.shoutoutAddedFromModal}
                closeShoutoutModalHandler={this.closeShoutoutModalHandler}
                recipients={this.state.users}
                values={this.state.availableValues}
                remainingShoutouts={this.state.shoutoutsRemaining}
              />
            </div>
          </div>
        </Auxiliary>
      );
    }
    return <LoadingScale />;
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  unfollowUser: userId => dispatch(USER_ACTIONS.unfollowUser(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowUser);
