import {
  ADMIN_ROLE,
  APP_URL,
  COMPANY_OWNER_ROLE,
  TEAM_MANAGER_ROLE,
  TEAM_MEMBER_ROLE,
  LOGIN_TIMEOUT_MILISECONDS,
} from 'constants.js';
import { CHECK_UNAUTHORIZED, LOCALSTORAGE_GET, SIGN_OUT } from 'helpers';
import loginUser from 'components/signup/integration/loginUser';

export default function loginWithIntegration(data, onFailure, type) {
  return fetch(`${APP_URL}/login_${type}`, { method: 'post', body: data })
    .then(response => CHECK_UNAUTHORIZED(response))
    .then(response => {
      if (response.status === 200 && response.ok === true) {
        return response
          .json()
          .then(async jsonResponse => {
            await loginUser(jsonResponse, onFailure);
          })
          .catch(error => {
            onFailure({ error: 'Authentication failed' });
          });
      }
      response.json().then(jsonResponse => {
        onFailure(jsonResponse);
      });
    })
    .catch(error => {
      onFailure(error);
    });
}
