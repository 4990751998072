import DOMAINS from '../domains';

export const USER_ACTION_TYPES = {
  REMOVE_PHOTO: 'REMOVE_PHOTO',
  UPDATE_PHOTO: 'UPDATE_PHOTO',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  FETCH_PROFILE: 'FETCH_PROFILE',
  REMOVE_IMAGE: 'REMOVE_IMAGE',
  PROFILE_UPDATED: 'PROFILE_UPDATED',
  CHANGE_USERS_CAPACITY: 'CHANGE_USERS_CAPACITY',
  UNFOLLOW_USER: 'UNFOLLOW_USER',
  RECEIVE_UNFOLLOW_USER_STATUS: 'RECEIVE_UNFOLLOW_USER_STATUS',
  UNFOLLOW_WEEKLY_DIGEST: 'UNFOLLOW_WEEKLY_DIGEST',
  RECEIVE_WEEKLY_DIGEST_STATUS: 'RECEIVE_WEEKLY_DIGEST_STATUS',
  UPDATE_VIBE_STATUS: 'UPDATE_VIBE_STATUS',
  REFRESH_PROFILE_IMAGE: 'REFRESH_PROFILE_IMAGE',
  ADD_LED_TEAM_ID: 'ADD_LED_TEAM_ID',
  REFRESH_RECENT_TEAMS: 'REFRESH_RECENT_TEAMS',
  ONE_TIME_VIBE_MODAL_SHOWN: 'ONE_TIME_VIBE_MODAL_SHOWN',
  UPDATE_ONE_TIME_VIBE_MODAL_STATUS: 'UPDATE_ONE_TIME_VIBE_MODAL_STATUS',
  UPDATE_USER_ALREADY_VIBED: 'UPDATE_USER_ALREADY_VIBED',
  CLOSE_FREE_PLAN_WARNING_MESSAGE: 'CLOSE_FREE_PLAN_WARNING_MESSAGE',
  UPDATE_FREE_PLAN_WARNING_MESSAGE: 'UPDATE_FREE_PLAN_WARNING_MESSAGE',
  HIDE_SAMPLE_POSTS: 'HIDE_SAMPLE_POSTS',
  UPDATE_HIDE_SAMPLE_POSTS: 'UPDATE_HIDE_SAMPLE_POSTS',
  UPDATE_VISITED: 'UPDATE_VISITED',
  TOGGLE_FEATURE: 'TOGGLE_FEATURE',
  RECEIVE_FEATURE_STATUS: 'RECEIVE_FEATURE_STATUS',
  UPDATE_USER_SHOULD_SEE_INSIGHTS_MODAL: 'UPDATE_USER_SHOULD_SEE_INSIGHTS_MODAL',
  WEEKLY_INSIGHTS_SHOWN: 'WEEKLY_INSIGHTS_SHOWN',
  UPDATE_WEEKLY_INSIGHTS_SHOWN_STATUS: 'UPDATE_WEEKLY_INSIGHTS_SHOWN_STATUS',
  UPDATE_ACCOUNT_DELETION_STATUS: 'UPDATE_ACCOUNT_DELETION_STATUS',
};
export const USER_ACTIONS = {
  changeUsersCapacity: isReached => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.CHANGE_USERS_CAPACITY,
    payload: isReached,
  }),

  updateProfile: (id, data, receiveErrors, generalError) => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.UPDATE_PROFILE,
    payload: {
      id,
      data,
      receiveErrors,
      generalError,
    },
  }),
  removePhoto: (id, data, receiveErrors, generalError) => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.REMOVE_PHOTO,
    payload: {
      id,
      data,
      receiveErrors,
      generalError,
    },
  }),
  updatePhoto: (id, data, receiveErrors, generalError, refreshPosts = true) => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.UPDATE_PHOTO,
    payload: {
      id,
      data,
      receiveErrors,
      generalError,
      refreshPosts,
    },
  }),
  removeImage: user => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.REMOVE_IMAGE,
    payload: {
      user,
    },
  }),
  profileUpdated: user => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.PROFILE_UPDATED,
    payload: {
      user,
    },
  }),
  unfollowUser: id => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.UNFOLLOW_USER,
    payload: id,
  }),
  receiveUnfollowUserStatus: (userId, json) => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.RECEIVE_UNFOLLOW_USER_STATUS,
    payload: {
      userId,
      json,
    },
  }),
  unfollowWeeklyDigest: () => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.UNFOLLOW_WEEKLY_DIGEST,
  }),
  receiveWeeklyDigestStatus: isUnfollowed => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.RECEIVE_WEEKLY_DIGEST_STATUS,
    payload: {
      isUnfollowed,
    },
  }),
  vibeStatusUpdated: vibeStatus => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.UPDATE_VIBE_STATUS,
    payload: vibeStatus,
  }),
  refreshProfileImage: imageUrl => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.REFRESH_PROFILE_IMAGE,
    payload: imageUrl,
  }),
  addLedTeamId: id => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.ADD_LED_TEAM_ID,
    payload: id,
  }),
  refreshRecentTeams: json => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.REFRESH_RECENT_TEAMS,
    payload: json,
  }),
  oneTimeVibeModalShown: () => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.ONE_TIME_VIBE_MODAL_SHOWN,
  }),
  updateOneTimeVibeModalStatus: shouldSeeOneTimeVibeModal => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.UPDATE_ONE_TIME_VIBE_MODAL_STATUS,
    payload: shouldSeeOneTimeVibeModal,
  }),
  updateUserAlreadyVibed: () => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.UPDATE_USER_ALREADY_VIBED,
  }),
  closeFreePlanWarningMessage: () => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.CLOSE_FREE_PLAN_WARNING_MESSAGE,
  }),
  updateFreePlanWarningMessage: () => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.UPDATE_FREE_PLAN_WARNING_MESSAGE,
  }),
  hideSamplePosts: () => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.HIDE_SAMPLE_POSTS,
  }),
  updateHideSamplePosts: () => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.UPDATE_HIDE_SAMPLE_POSTS,
  }),
  updateVisited: page => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.UPDATE_VISITED,
    payload: page,
  }),
  toggleFeature: type => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.TOGGLE_FEATURE,
    payload: type,
  }),
  receiveFeatureStatus: (type, enabled) => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.RECEIVE_FEATURE_STATUS,
    payload: {
      type,
      enabled,
    },
  }),
  updateUserShouldSeeInsightsModal: () => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.UPDATE_USER_SHOULD_SEE_INSIGHTS_MODAL,
  }),
  weeklyInsightsShown: () => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.WEEKLY_INSIGHTS_SHOWN,
  }),
  updateWeeklyInsightsShownStatus: weeklyInsightsShown => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.UPDATE_WEEKLY_INSIGHTS_SHOWN_STATUS,
    payload: weeklyInsightsShown,
  }),
  updateAccountDeletionStatus: accountDeletionStatus => ({
    domain: DOMAINS.USERS,
    type: USER_ACTION_TYPES.UPDATE_ACCOUNT_DELETION_STATUS,
    payload: accountDeletionStatus,
  }),
};
