/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import Dropzone from 'react-dropzone';
import Croppie from 'components/shared/croppie';
import { USER_ACTIONS } from 'redux/actions/userActions';
import { connect } from 'react-redux';
import OnboardingButton from './partials/OnboardingButton';
import PostponeButton from './partials/PostponeButton';
import CroppieRotate from '../shared/CroppieRotate';

const Container = styled.div`
  font-size: 16px;
  padding: 13px 29px 16px 29px;
  line-height: 150%;
  position: relative;
  margin-top: 27px;
  margin-bottom: 10px;
`;
const Wrapper = styled.div`
  display: block;
  margin-bottom: 20px;
  text-align: center;
  .btn {
    padding: 0 42px;
  }
`;

const PlaceholderContainer = styled.div`
  position: absolute;
  top: 13px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 130px;
`;

const PlaceholderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  z-index: 10;
  color: #11a9ff;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
`;

const CameraImage = () => (
  <svg
    width="19"
    height="16"
    viewBox="0 0 35 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="pull-left"
    css={css`
      margin-right: 10px;
    `}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.0345 5.52632H31.3793C33.3707 5.52632 35 7.18421 35 9.21053V25.7895C35 27.8158 33.3707 29.4737 31.3793 29.4737H3.62069C1.62931 29.4737 0 27.8158 0 25.7895V9.21053C0 7.18421 1.62931 5.52632 3.62069 5.52632H6.75862C7.35603 5.52632 7.99907 5.05474 8.18843 4.47816L9.31157 1.04853C9.50021 0.471579 10.144 0 10.7414 0H23.0517C23.6491 0 24.2922 0.471579 24.4815 1.04816L25.6047 4.47779C25.7933 5.05474 26.4371 5.52632 27.0345 5.52632ZM9.65531 16.5792C9.65531 12.8499 12.6269 9.82486 16.2932 9.82486C19.9587 9.82486 22.9312 12.8499 22.9312 16.5792C22.9312 20.3099 19.9587 23.3336 16.2932 23.3336C12.6269 23.3336 9.65531 20.3099 9.65531 16.5792ZM16.2933 25.7895C11.2942 25.7895 7.2416 21.6657 7.2416 16.5789C7.2416 11.4925 11.2942 7.36842 16.2933 7.36842C21.2917 7.36842 25.345 11.4925 25.345 16.5789C25.345 21.6657 21.2917 25.7895 16.2933 25.7895ZM28.9655 9.82426C28.9655 10.3271 29.5062 11.0523 30.1724 11.0523C30.8389 11.0523 31.3793 10.3271 31.3793 9.82426C31.3793 9.32197 30.8393 8.59619 30.1724 8.59619C29.5062 8.59619 28.9655 9.32197 28.9655 9.82426Z"
      fill="#11A9FF"
      opacity="0.45"
    />
  </svg>
);

const DropzoneDescription = () => (
  <div
    onClick={() => {
      dropzoneRef.current.open();
    }}
  >
    <CameraImage />
    Drop your photos here to upload or <u>choose a file</u>
    <br />
    <div
      css={css`
        font-size: 12px;
        line-height: 14px;
        color: #6a6a6a;
        margin-top: 16px;
      `}
    >
      PNG or JPG, no more than 5MB
    </div>
  </div>
);

const insertToCroppie = (files, setShowCroppie, setCroppieImage) => {
  const reader = new FileReader();
  if (files.length > 0) {
    const image = files[0];
    if (image.size > 5000000) {
      alert('File is too big!');
    } else if (image.type !== 'image/jpeg' && image.type !== 'image/png') {
      alert('Invalid file extension!');
    } else {
      setShowCroppie(true);
      reader.onloadend = () => {
        updateCroppie(reader.result, setCroppieImage);
      };
      reader.readAsDataURL(image);
    }
  } else {
    alert('Invalid file extension!');
  }
};

const updateCroppie = (imagePreviewCroppyUrl, setCroppieImage) => {
  const el = croppieRef;
  if (el) {
    const croppie = new Croppie(el.current, {
      url: imagePreviewCroppyUrl,
      viewport: {
        width: 200,
        height: 200,
        type: 'circle',
      },
      boundary: {
        width: 300,
        height: 300,
      },
      enableExif: true,
      enableOrientation: true,
    });
    setCroppieImage(croppie);
  }
};

const getCroppieResult = (croppieImage, setShowCroppie, setImagePreviewUrl) => {
  croppieImage.result('base64').then(base => {
    croppieImage.result('blob').then(blob => {
      if (blob.size > 2000000) {
        alert('File is too big!');
      } else {
        setShowCroppie(false);
        setImagePreviewUrl(base);
      }
    });
  });
};

const croppieRef = React.createRef();
const dropzoneRef = React.createRef();

const CircleImage = ({ showCroppie, imagePreviewUrl }) => (
  <div
    css={css`
      height: 100px;
      display: ${imagePreviewUrl ? 'block' : 'none'};
    `}
  >
    <img
      src={imagePreviewUrl}
      css={css`
        display: ${!showCroppie && imagePreviewUrl ? 'block' : 'none'};
        border-radius: 50%;
        width: 100px;
        height: 100px;
        margin: auto;
      `}
    />
  </div>
);

const CroppieBox = ({
  showCroppie,
  croppieRef,
  croppieImage,
  setShowCroppie,
  setImagePreviewUrl,
  rotateCroppie,
}) => (
  <div
    css={css`
      display: ${showCroppie ? 'block' : 'none'};
    `}
  >
    <p>Zoom the image to fit.</p>
    <div id="croppie" ref={croppieRef} />
    <CroppieRotate rotate={rotateCroppie} />
    <OnboardingButton
      label="Save"
      submit
      onClick={() => getCroppieResult(croppieImage, setShowCroppie, setImagePreviewUrl, croppieRef)}
    />
    <OnboardingButton
      label="Cancel"
      onClick={() => {
        setShowCroppie(false);
        setImagePreviewUrl('');
        document.getElementById('trigger-croppie').value = '';
        croppieImage && croppieImage.destroy();
      }}
    />
  </div>
);

const sendImage = (imagePreviewUrl, updatePhoto, nextStep) => {
  if (imagePreviewUrl) {
    const data = new FormData();
    data.append('update_image', true);
    data.append('userImageBase64', imagePreviewUrl);
    updatePhoto(null, data, receiveErrors, receiveErrors, false);
    nextStep();
  }
};

const receiveErrors = (errors, response) => {};

const UploadPhoto = ({ nextStep, updatePhoto, ...props }) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [showCroppie, setShowCroppie] = useState(false);
  const [croppieImage, setCroppieImage] = useState({});

  const rotateCroppie = deg => croppieImage.rotate(deg);

  return (
    <>
      <Container>
        <Dropzone
          style={{
            width: '100%',
            height: '130px',
            border: '1px dashed #C8EBFF',
            borderRadius: '3px',
            position: 'relative',
            zIndex: '2',
            background: '#F9FDFF',
            cursor: 'pointer',
          }}
          id="trigger-croppie"
          accept="image/*"
          inputProps={{ capture: false }}
          onDrop={e => insertToCroppie(e, setShowCroppie, setCroppieImage)}
          ref={dropzoneRef}
          css={css`
            display: ${!showCroppie && !imagePreviewUrl ? 'block' : 'none'};
          `}
        />
        <PlaceholderContainer
          css={css`
            display: ${!showCroppie && !imagePreviewUrl ? 'flex' : 'none'};
          `}
        >
          <PlaceholderWrapper>
            <DropzoneDescription />
          </PlaceholderWrapper>
        </PlaceholderContainer>
        <CircleImage showCroppie={showCroppie} imagePreviewUrl={imagePreviewUrl} />
        <CroppieBox
          showCroppie={showCroppie}
          croppieRef={croppieRef}
          croppieImage={croppieImage}
          setShowCroppie={setShowCroppie}
          setImagePreviewUrl={setImagePreviewUrl}
          rotateCroppie={rotateCroppie}
        />
      </Container>
      {!showCroppie && (
        <Wrapper>
          <OnboardingButton
            label="Submit"
            submit
            disabled={!imagePreviewUrl}
            onClick={() => sendImage(imagePreviewUrl, updatePhoto, nextStep)}
          />
        </Wrapper>
      )}
      <PostponeButton text="I’ll do it later" onClick={() => nextStep()} />
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  updatePhoto: (id, data, receiveErrors, generalError, refreshPosts) =>
    dispatch(USER_ACTIONS.updatePhoto(id, data, receiveErrors, generalError, refreshPosts)),
});

export default connect(null, mapDispatchToProps)(UploadPhoto);
