import styled from '@emotion/styled';
import { ReactComponent as SearchIcon } from 'pages/Insights/OrganisationInsights/media/search-icon.svg';
import { ReactComponent as CloseCross } from 'pages/Insights/OrganisationInsights/media/close-cross.svg';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

const Container = styled.div`
  position: relative;
`;

const Input = styled.input`
  height: 40px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 0 40px;
  max-width: 100%;
  &::placeholder {
    color: #c4c4c4;
    font-size: 14px;
  }
`;

const SearchButton = styled(SearchIcon)`
  position: absolute;
  left: 15px;
  cursor: pointer;
  top: 0;
  bottom: 0;
  margin: auto 0;
  path {
    fill: ${({ highlighted }) => (highlighted ? '#11A9FF' : '#C4C4C4')};
  }
`;

const ClearButton = styled(CloseCross)`
  position: absolute;
  right: 15px;
  cursor: pointer;
  top: 0;
  bottom: 0;
  margin: auto 0;
`;

const SearchInput = ({ placeholder, setSearch }) => {
  const [value, setValue] = useState('');

  const isInputEmpty = value.length === 0;
  const debouncedSearch = useCallback(debounce(setSearch, 300), [setSearch]);

  return (
    <Container>
      <SearchButton highlighted={!isInputEmpty} onClick={() => setSearch(value)} />
      <Input
        placeholder={placeholder}
        onChange={e => {
          setValue(e.target.value);
          debouncedSearch(e.target.value);
        }}
        value={value}
      />
      {!isInputEmpty && (
        <ClearButton
          onClick={() => {
            setValue('');
            setSearch('');
          }}
        />
      )}
    </Container>
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
};

SearchInput.defaultProps = {};

export default SearchInput;
