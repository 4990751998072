export default function chartLineHover(data) {
  const hoverData = data.filter(d => d.lineHover);
  const intervals = [];
  hoverData.forEach(({ data, _meta }) => {
    let a = null;
    let b = null;
    const meta = Object.values(_meta).find(Boolean);
    for (let i = 0; i < data.length; i++) {
      if (data[i] !== null) {
        if (a === null) a = meta.data[i];
        else if (b === null) b = meta.data[i];
        else break;
      }
    }
    const ax = a?._view.x;
    const ay = a?._view.y;
    const bx = b?._view.x;
    const by = b?._view.y;

    const hoverWidth = 6;
    const dotOffset = 5;
    intervals.push(
      (x, y) =>
        x > ax + dotOffset && x < bx - dotOffset && y > ay - hoverWidth && y < ay + hoverWidth
    );
  });
  return intervals;
}
