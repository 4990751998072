import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Modal from 'react-modal';

import Insights from 'pages/Insights';

import { APP_URL, INTERCOM_ID } from 'constants.js';
import { HEADERS, LOCALSTORAGE_GET, SIGN_OUT } from 'helpers.js';
import MyWinsIndex from 'pages/Grow/MyWins';
import ScheduleIndex from 'pages/Grow/Schedule';
import ProgressBoardIndex from 'pages/Grow/ProgressBoard';
import { INITIAL_ACTIONS } from 'redux/actions/initialActions';
import GrowContext from 'pages/Grow/GrowContext';
import refDebounce from 'refDebounce';
import SmartBanner from 'react-smartbanner';
import Navigation from './Navigation';
import InvitesNew from '../invites/New';
import UsersByTag from '../users/UsersByTag';
import ShowUser from '../users/ShowUser';
import TeamsNew from '../teams/TeamsNew';
import TeamsEdit from '../teams/TeamsEdit';
import EditUser from '../users/EditUser';
import SpaceFeed from '../feed/SpaceFeed';
import HomeFeed from '../feed/HomeFeed';
import FixesList from '../vibes/FixesList';
import SettingsEdit from '../settings/SettingsEdit';
import CompanySettingsEdit from '../settings/CompanySettingsEdit';
import Flash from '../shared/Flash';
import FreePlanWarningMessage from '../shared/FreePlanWarningMessage';
import Error404 from '../errors/Error404';
import ScrollToTop from './ScrollToTop';
import Exif from '../shared/Exif';
import PostShow from '../post/PostShow';
import NotificationSettings from '../settings/NotificationSettings';
import SpacesAcceptRequest from '../spaces/SpacesAcceptRequest';
import CompanyStructure from '../company_structure/CompanyStructure';
import UpgradePlanModal from '../free_plan/UpgradePlanModal';
import Error101 from '../errors/Error101';
import PaymentsNew from '../payments/PaymentsNew';
import PaymentChoose from '../payments/PaymentChoose';
import TopBar from './TopBar';
import Users from '../organisation/Users';
import Teams from '../organisation/Teams';
import Spaces from '../organisation/Spaces';
import 'react-smartbanner/dist/main.css';
import Intercom from 'react-intercom';
import OnboardingModal from '../onboarding/OnboardingModal';
import TourModal from '../onboarding/TourModal';
import Confetti from '../onboarding/Confetti';
import GrowTour from '../onboarding/GrowTour';
import InsightsTour from '../onboarding/InsightsTour';
import FeedTour from '../onboarding/FeedTour';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import AnnualBillingPrompt from 'components/payments/prompts/AnnualBillingPrompt';
import SetupTour from 'components/onboarding/SetupTour';
import QuestionnairesIndex from 'components/questionnaires/QuestionnairesIndex';
import QuestionnairesDetails from 'components/questionnaires/QuestionnairesDetails';
import PromptContainer from 'components/payments/prompts/PromptContainer';
import OrganisationInsights from 'pages/Insights/OrganisationInsights';
import ScoreComparison from 'pages/Insights/OrganisationInsights/ScoreComparison/ScoreComparison';
import Champions from 'pages/Grow/Champions';
import ShoutoutFeed from '../feed/ShoutoutFeed';
import GrowProfileNew from 'pages/Grow/GrowProfile/GrowProfileNew';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import qs from 'qs';
import { clearActionBody, setAction, setShowActionModal } from 'redux/app/actions';

class Application extends Component {
  constructor(props) {
    super(props);

    this.callback = {
      func: () => {},
    };

    this.debouncedFetch = refDebounce(this.callback, 1000, { leading: true });

    this.toggleContextCallback = callback => {
      this.setState(
        {
          callback,
        },
        () => {
          this.callback.func = callback;
          this.debouncedFetch();
        }
      );
    };

    this.state = {
      title: '',
      path: '',
      author: '',
      className: '',
      previousPage: '',
      subheader: '',
      infoMessage: '',
      imgPath: '',
      imgAlt: '',
      flashMessage: '',
      flashDismissable: true,
      flashType: '',
      error: null,
      hideNavbar: false,
      scroll: 0,
      page: 1,
      navigationCollapsed: false,
      // context:
      callback: () => {},
      setCallback: this.toggleContextCallback,
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    if (this.props.location.search === '?from=impersonate') {
      this.props.history.push('/');
      window.location.reload();
    }
    this.checkTokenExpires();
    // IS_AUTHENTICATED();
    this.updateLocalStorage();

    if (!this.getParameterByName('uta')) {
      this.setState({ hideNavbar: true });
    }
    this.props.initialFetchData();
    Modal.setAppElement(this.ref.current);
  }

  componentDidUpdate(props) {
    const flash = this.props.location.state?.flash;
    if (flash && flash !== this.state.flashMessage) {
      this.updateFlash('success', flash);
    }
    if (
      props.match.path !== this.props.match.path &&
      !props.location.pathname.includes('accept_request') &&
      !/(team\/)\d*(\/edit)/.test(props.location.pathname)
    ) {
      this.closeFlash();
    }
  }

  setErrorPage = error => {
    this.setState({ error });
  };

  setInfo = message => {
    this.setState({ infoMessage: message });
  };

  setSubheader = text => {
    this.setState({ subheader: text });
  };

  setHeader = (title, path, previousPage, className, scroll = 0, page = 1, filters = '') => {
    this.setState({
      title,
      path,
      previousPage,
      className,
      scroll,
      page,
      filters,
    });
    this.setInfo('');
    this.setSubheader('');
    this.setImage('');
  };

  setImage = (imgPath, imgAlt) => {
    this.setState({
      imgPath,
      imgAlt,
    });
  };

  // componentWillReceiveProps() {
  //    this.checkTokenExpires();
  // }
  checkTokenExpires() {
    if (LOCALSTORAGE_GET('token_expires_at') !== null) {
      const now = new Date();
      const date = new Date(LOCALSTORAGE_GET('token_expires_at'));
      if (now.getTime() >= date.getTime()) {
        SIGN_OUT();
      }
    }
  }

  getParameterByName(name, url) {
    if (!url) url = document.location;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  updateLocalStorage() {
    let accessToken = '';
    let mobileParam = '';
    if (this.getParameterByName('uta')) {
      mobileParam = '?mobile_app=1';
      accessToken = this.getParameterByName('uta');
    } else if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    const headers = HEADERS(accessToken);
    fetch(`${APP_URL}/check_image${mobileParam}`, { headers }).then(response => {
      if (response.status === 200 && response.ok === true) {
        response.json().then(jsonResponse => {
          if (localStorage != null) {
            if (LOCALSTORAGE_GET('user_first_name') !== jsonResponse.first_name) {
              localStorage.setItem('user_first_name', jsonResponse.first_name);
            }
            if (LOCALSTORAGE_GET('user_image_uid') !== jsonResponse.image_uid) {
              localStorage.setItem('user_image_uid', jsonResponse.image_uid);
            }
            if (LOCALSTORAGE_GET('user_last_name') !== jsonResponse.last_name) {
              localStorage.setItem('user_last_name', jsonResponse.last_name);
            }
          } else {
            this.setImage(jsonResponse.image_uid, jsonResponse.first_name);
          }
        });
      } else if (response.status === 401 || response.status === 403) {
        SIGN_OUT();
      }
    });
  }

  closeFlash = () => {
    this.setState({ flashMessage: '' });
    if (this.props.location.state?.flash) {
      this.props.history.replace(this.props.location.pathname, null);
    }
  };

  updateFlash = (flashType, flashMessage, flashDismissable = true) => {
    this.setState({
      flashType,
      flashMessage,
      flashDismissable,
    });
    window.scrollTo(0, 0);
  };

  render() {
    const {
      user,
      displayMeetingModal,
      setAction,
      clearActionBody,
      setShowActionModal,
    } = this.props;
    const { userImg, hideNavbar, navigationCollapsed } = this.state;
    const customHistory = createBrowserHistory();

    const intercomUser = {
      user_id: LOCALSTORAGE_GET('user_id'),
      email: LOCALSTORAGE_GET('user_email'),
      name: `${LOCALSTORAGE_GET('user_first_name')} ${LOCALSTORAGE_GET('user_last_name')}`,
      created_at: LOCALSTORAGE_GET('user_created_at'),
      account_name: LOCALSTORAGE_GET('account_name'),
      user_role: LOCALSTORAGE_GET('user_type'),
    };

    if (this.state.error === 404) {
      return <Error404 />;
    }
    if (this.state.error === 101) {
      return <Error101 />;
    }

    if (user && displayMeetingModal) {
      setShowActionModal(true);
      clearActionBody();
      setAction('create-note-meeting-schedule');
    }

    return (
      <ScrollToTop>
        <div
          className={[
            'frame-wrapper',
            this.state.navigationCollapsed ? 'frame-wrapper--navigation-collapsed' : '',
          ].join(' ')}
          ref={this.ref}
        >
          <SmartBanner title="Heelix" />

          <Intercom appID={INTERCOM_ID} {...intercomUser} />

          <Exif />
          {hideNavbar && (
            <Navigation
              collapsed={navigationCollapsed}
              onCollapseChange={collapsed => {
                this.setState({
                  navigationCollapsed: collapsed,
                });
              }}
              updateFlash={this.updateFlash}
            />
          )}
          {this.state.flashMessage ? (
            <Flash
              closeFlash={this.closeFlash}
              flashMessage={this.state.flashMessage}
              flashType={this.state.flashType}
              dismissable={this.state.flashDismissable}
            />
          ) : (
            ''
          )}
          <FreePlanWarningMessage />
          <div className="page-wrapper">
            <TopBar isFreePlan={this.props.isFreePlan} resetTours={this.props.resetTours} />
            {/* Switch will be generated after initial data fetch */}
            {user && (
              <GrowContext.Provider
                value={{
                  callback: this.state.callback,
                  setCallback: this.state.setCallback,
                }}
              >
                <Switch>
                  <Route
                    exact
                    path="/grow_profile"
                    render={routeProps => (
                      <GrowProfileNew
                        {...routeProps}
                        userImg={userImg}
                        setInfo={this.setInfo}
                        updateFlash={this.updateFlash}
                        closeFlash={this.closeFlash}
                        setErrorPage={this.setErrorPage}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/champions"
                    render={routeProps => (
                      <Champions {...routeProps} setErrorPage={this.setErrorPage} />
                    )}
                  />
                  <Route
                    exact
                    path="/users"
                    render={routeProps => (
                      <Users
                        {...routeProps}
                        setHeader={this.setHeader}
                        setSubheader={this.setSubheader}
                        setInfo={this.setInfo}
                        updateFlash={this.updateFlash}
                        closeFlash={this.closeFlash}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/users/tags"
                    render={routeProps => (
                      <UsersByTag
                        {...routeProps}
                        setHeader={this.setHeader}
                        setSubheader={this.setSubheader}
                        setInfo={this.setInfo}
                        updateFlash={this.updateFlash}
                        closeFlash={this.closeFlash}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/invitation/new"
                    render={routeProps => (
                      <InvitesNew
                        {...routeProps}
                        setHeader={this.setHeader}
                        setSubheader={this.setSubheader}
                        setInfo={this.setInfo}
                        updateFlash={this.updateFlash}
                        closeFlash={this.closeFlash}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/user/:id"
                    render={routeProps => (
                      <ShowUser
                        {...routeProps}
                        setHeader={this.setHeader}
                        setErrorPage={this.setErrorPage}
                        closeFlash={this.closeFlash}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/user/:id/edit"
                    render={routeProps => (
                      <EditUser
                        {...routeProps}
                        setHeader={this.setHeader}
                        updateFlash={this.updateFlash}
                        setErrorPage={this.setErrorPage}
                        closeFlash={this.closeFlash}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/edit_profile"
                    render={routeProps => (
                      <EditUser
                        {...routeProps}
                        setHeader={this.setHeader}
                        updateFlash={this.updateFlash}
                        setErrorPage={this.setErrorPage}
                        closeFlash={this.closeFlash}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/notification_settings"
                    render={routeProps => (
                      <NotificationSettings
                        {...routeProps}
                        setHeader={this.setHeader}
                        closeFlash={this.closeFlash}
                      />
                    )}
                  />
                  <Route
                    path="/spaces/accept_request/:userId/:spaceId"
                    render={routeProps => (
                      <SpacesAcceptRequest
                        {...routeProps}
                        setHeader={this.setHeader}
                        setSubheader={this.setSubheader}
                        setInfo={this.setInfo}
                        closeFlash={this.closeFlash}
                      />
                    )}
                  />
                  <Route
                    path="/spaces"
                    render={routeProps => (
                      <Spaces
                        {...routeProps}
                        setHeader={this.setHeader}
                        setSubheader={this.setSubheader}
                        setInfo={this.setInfo}
                        updateFlash={this.updateFlash}
                        closeFlash={this.closeFlash}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/teams/new"
                    render={routeProps => (
                      <TeamsNew
                        {...routeProps}
                        setHeader={this.setHeader}
                        closeFlash={this.closeFlash}
                      />
                    )}
                  />
                  <Route
                    path="/teams"
                    render={routeProps => (
                      <Teams
                        {...routeProps}
                        setHeader={this.setHeader}
                        setSubheader={this.setSubheader}
                        setInfo={this.setInfo}
                        updateFlash={this.updateFlash}
                        closeFlash={this.closeFlash}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/team/:id/edit"
                    render={routeProps => (
                      <TeamsEdit
                        {...routeProps}
                        updateFlash={this.updateFlash}
                        setErrorPage={this.setErrorPage}
                        closeFlash={this.closeFlash}
                      />
                    )}
                  />

                  <Route
                    path="/vibes"
                    render={routeProps => (
                      <Insights
                        {...routeProps}
                        setHeader={this.setHeader}
                        setInfo={this.setInfo}
                        updateFlash={this.updateFlash}
                        closeFlash={this.closeFlash}
                        setErrorPage={this.setErrorPage}
                      />
                    )}
                  />
                  <Route
                    path="/organisation_insights/score/comparison"
                    render={routeProps => (
                      <ScoreComparison
                        {...routeProps}
                        setHeader={this.setHeader}
                        setInfo={this.setInfo}
                        closeFlash={this.closeFlash}
                        setErrorPage={this.setErrorPage}
                      />
                    )}
                  />
                  <Route
                    path="/organisation_insights"
                    render={routeProps => (
                      <OrganisationInsights
                        {...routeProps}
                        setHeader={this.setHeader}
                        setInfo={this.setInfo}
                        closeFlash={this.closeFlash}
                        setErrorPage={this.setErrorPage}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/"
                    render={routeProps => (
                      <HomeFeed
                        {...routeProps}
                        setHeader={this.setHeader}
                        setImage={this.setImage}
                        closeFlash={this.closeFlash}
                        updateFlash={this.updateFlash}
                        setErrorPage={this.setErrorPage}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/feed"
                    render={routeProps => (
                      <SpaceFeed
                        {...routeProps}
                        setHeader={this.setHeader}
                        setImage={this.setImage}
                        closeFlash={this.closeFlash}
                        updateFlash={this.updateFlash}
                        setErrorPage={this.setErrorPage}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/posts/list"
                    render={routeProps => (
                      <FixesList
                        {...routeProps}
                        setInfo={this.setInfo}
                        closeFlash={this.closeFlash}
                        updateFlash={this.updateFlash}
                        fixesIds={this.state.fixesListIds}
                        subtitle={this.state.subtitle}
                      />
                    )}
                  />
                  <Redirect from="/advanced_search" to="/" />
                  <Redirect from="/fixes/list" to="/posts/list" />
                  <Redirect from="/fixes/:id" to="/post/:id" />
                  <Redirect from="/buzz/:id" to="/post/:id" />
                  <Redirect from="/fixes" to="/" />
                  <Redirect from="/buzz" to="/" />
                  <Redirect from="/show/vibes" to="/" />
                  <Redirect
                    from="/view_profile"
                    to={`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`}
                  />
                  <Route
                    exact
                    path="/shoutouts"
                    render={routeProps => (
                      <ShoutoutFeed
                        {...routeProps}
                        setHeader={this.setHeader}
                        setImage={this.setImage}
                        closeFlash={this.closeFlash}
                        updateFlash={this.updateFlash}
                        setErrorPage={this.setErrorPage}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/your_schedule"
                    render={routeProps => (
                      <ScheduleIndex
                        {...routeProps}
                        setInfo={this.setInfo}
                        updateFlash={this.updateFlash}
                        setErrorPage={this.setErrorPage}
                        setHeader={this.setHeader}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/wins"
                    render={routeProps => (
                      <MyWinsIndex
                        {...routeProps}
                        setInfo={this.setInfo}
                        updateFlash={this.updateFlash}
                        setErrorPage={this.setErrorPage}
                        setHeader={this.setHeader}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/grow_board"
                    render={routeProps => (
                      <ProgressBoardIndex
                        {...routeProps}
                        setInfo={this.setInfo}
                        updateFlash={this.updateFlash}
                        setErrorPage={this.setErrorPage}
                        setHeader={this.setHeader}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/company-settings"
                    render={routeProps => (
                      <CompanySettingsEdit
                        {...routeProps}
                        setHeader={this.setHeader}
                        updateFlash={this.updateFlash}
                        closeFlash={this.closeFlash}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/settings"
                    render={routeProps => (
                      <SettingsEdit
                        {...routeProps}
                        setHeader={this.setHeader}
                        updateFlash={this.updateFlash}
                        closeFlash={this.closeFlash}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/company_structure"
                    render={routeProps => (
                      <CompanyStructure
                        {...routeProps}
                        setHeader={this.setHeader}
                        updateFlash={this.updateFlash}
                        closeFlash={this.closeFlash}
                        setErrorPage={this.setErrorPage}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/post/:id"
                    render={routeProps => (
                      <PostShow
                        {...routeProps}
                        setErrorPage={this.setErrorPage}
                        closeFlash={this.closeFlash}
                        updateFlash={this.updateFlash}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/payments/new"
                    render={routeProps => (
                      <PaymentsNew
                        {...routeProps}
                        setErrorPage={this.setErrorPage}
                        closeFlash={this.closeFlash}
                        updateFlash={this.updateFlash}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/payments/choose_plan"
                    render={routeProps => (
                      <PaymentChoose
                        {...routeProps}
                        setErrorPage={this.setErrorPage}
                        closeFlash={this.closeFlash}
                        updateFlash={this.updateFlash}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/questionnaires"
                    render={routeProps => (
                      <QuestionnairesIndex
                        {...routeProps}
                        setErrorPage={this.setErrorPage}
                        closeFlash={this.closeFlash}
                        updateFlash={this.updateFlash}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/questionnaires/:id"
                    render={routeProps => (
                      <QuestionnairesDetails
                        {...routeProps}
                        setErrorPage={this.setErrorPage}
                        closeFlash={this.closeFlash}
                        updateFlash={this.updateFlash}
                      />
                    )}
                  />
                </Switch>
              </GrowContext.Provider>
            )}
          </div>
          <UpgradePlanModal />
          <AnnualBillingPrompt />
          <PromptContainer />
          {user && <OnboardingModal />}
          <TourModal />
          <Confetti />
          {this.props.growTourStep >= 0 && !this.props.isFreePlan && <GrowTour />}
          {this.props.insightsTourStep >= 0 &&
            !this.props.isFreePlan &&
            !this.props.displayOnboardingModal &&
            !this.props.insightsTourFinished && <InsightsTour />}
          {this.props.feedTourStep >= 0 && !this.props.isFreePlan && <FeedTour />}
          {this.props.setupTourStep >= 0 && !this.props.isFreePlan && <SetupTour />}
        </div>
      </ScrollToTop>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  initialFetchData: () => dispatch(INITIAL_ACTIONS.fetchData()),
  resetTours: () => dispatch(ONBOARDING_ACTIONS.resetTours()),
  clearActionBody: () => dispatch(clearActionBody()),
  setAction: action => dispatch(setAction(action)),
  setShowActionModal: action => dispatch(setShowActionModal(action)),
});
const mapStateToProps = state => ({
  user: state.usersDomain.user,
  spaceId: state.usersDomain.spaceId,
  isFreePlan: !!state.plansDomain.plan && state.plansDomain.plan === 'free',
  growTourStep: state.onboardingDomain.growTourStep,
  insightsTourStep: state.onboardingDomain.insightsTourStep,
  feedTourStep: state.onboardingDomain.feedTourStep,
  displayOnboardingModal: state.onboardingDomain.displayOnboardingModal,
  displayMeetingModal: state.onboardingDomain.displayMeetingModal,
  insightsTourFinished: state.onboardingDomain.insightsTourFinished,
  setupTourStep: state.onboardingDomain.setupTourStep,
});

export default connect(mapStateToProps, mapDispatchToProps)(Application);
