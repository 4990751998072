import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { INITIAL_ACTIONS } from 'redux/actions/initialActions';
import { connect } from 'react-redux';
import { APP_URL } from '../../constants';
import { HEADERS } from '../../helpers';

class PaymentsSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: '',
      email: '',
      ctaText: '',
      ctaPath: '',
    };
  }

  componentDidMount() {
    const headers = HEADERS();
    fetch(`${APP_URL}/payments/success`, { method: 'get', headers })
      .then(response => {
        response.json().then(jsonResponse => {
          if (jsonResponse.plan) {
            this.setState({ plan: jsonResponse.plan });
            if (!jsonResponse.plan.free) {
              localStorage.setItem('upgrade_account', false);
            }
          }
          this.setState({
            email: jsonResponse.billingEmail,
            ctaText: jsonResponse.ctaText,
            ctaPath: jsonResponse.ctaPath,
          });
          this.props.initialFetchData();
        });
      })
      .catch(() => {});
  }

  render() {
    return (
      <div className="payment_success">
        <div className="panel panel-fixed-md">
          <div className="panel-body centered">
            <h2 className="u-margin-bottom-sm u-margin-top-none">Success</h2>
            <i
              className="fa fa-check-circle text-okay u-margin-bottom-sm"
              style={{ fontSize: '140px', lineHeight: '140px' }}
            />
            <p>
              Your account has been upgraded to <strong>{this.state.plan.name}</strong>.
            </p>
            <p>
              We've sent an invoice to <strong>{this.state.email}</strong>
            </p>
            <div className="u-margin-top-sm">
              <Link to="/settings" className="btn u-margin-right-xxs btn-secondary">
                Billing Details
              </Link>
              <Link to={this.state.ctaPath} className="btn u-margin-left-xxs btn-primary">
                {this.state.ctaText}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  initialFetchData: () => dispatch(INITIAL_ACTIONS.fetchData()),
});

export default connect(null, mapDispatchToProps)(PaymentsSuccess);
