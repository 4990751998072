import React from 'react';
import PropTypes from 'prop-types';
import WelcomeContainer from '../WelcomeContainer';
import LoadingOverlay from '../LoadingOverlay';

const InviteTerms = ({ handleTerms, loading }) => {
  const handleSubmit = e => {
    e.preventDefault();

    handleTerms(!!e.target.terms_and_privacy_accepted.checked);
  };

  return (
    <WelcomeContainer
      className="text-center"
      title="Final step..."
      body={
        <>
          <LoadingOverlay toggled={loading} message="Processing..." height="50%" />
          <div className="password-set">
            <form
              onSubmit={handleSubmit}
              action=""
              className="simple_form form-compact invite-terms"
              id="new_user"
            >
              <div className="checkbox privacy-terms-checkbox">
                <input
                  required
                  id="terms_and_privacy_accepted"
                  name="terms_and_privacy_accepted"
                  type="checkbox"
                />
                <label htmlFor="terms_and_privacy_accepted">
                  By signing up, you agree to the collection, use and storage of personal
                  information in accordance with the terms of our&nbsp;
                  <a className="" href="https://heelix.com/terms.html" target="blank">
                    Terms
                  </a>{' '}
                  and{' '}
                  <a className="" href="https://heelix.com/privacy.html" target="blank">
                    Privacy Policy
                  </a>
                  .
                </label>
              </div>
              <button
                disabled={loading}
                type="submit"
                className="btn btn-primary btn-full submit-button"
                id="sign-in-btn"
              >
                Let's go
              </button>
            </form>
          </div>
        </>
      }
    />
  );
};

InviteTerms.propTypes = {
  handleTerms: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default InviteTerms;
