import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomTooltip from '../CustomTooltip';

class SwitchButton extends Component {
  state = {
    showFullyDisabledTooltip: false,
  };

  // --temporarilyDisabled and fullyDisabled props serve only styling purposes, as it's much more convenient to deal with
  render() {
    const {
      showLabel,
      onClick,
      temporarilyDisabled,
      turnedOn,
      fullyDisabledTooltipContent,
      fullyDisabled,
    } = this.props;

    return (
      <span
        className={`switch ${turnedOn ? 'turned-on' : 'turned-off'} ${
          temporarilyDisabled ? 'temp-disabled' : ''
        } ${fullyDisabled ? 'full-disabled' : ''} ${showLabel ? 'show-label' : ''}`}
        onClick={e => (onClick ? onClick(e) : null)}
        onMouseEnter={e =>
          this.setState({
            showFullyDisabledTooltip: fullyDisabled,
          })
        }
        onMouseLeave={e =>
          this.setState({
            showFullyDisabledTooltip: false,
          })
        }
        {...this.props}
      >
        {fullyDisabled ? (
          <CustomTooltip isMounted={this.state.showFullyDisabledTooltip}>
            {fullyDisabledTooltipContent}
          </CustomTooltip>
        ) : (
          ''
        )}
        {showLabel ? (
          <span className={`${turnedOn ? 'turned-on' : 'turned-off'}`}>
            {turnedOn ? 'ON' : 'OFF'}
          </span>
        ) : (
          ''
        )}
      </span>
    );
  }
}

SwitchButton.propTypes = {
  turnedOn: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SwitchButton;
