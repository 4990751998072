import { PromptButton, promptColors } from 'components/payments/prompts/Prompt';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const UpgradeButton = ({ hide, history, isAccountAdmin, accountAdmin }) => {
  return (
    <PromptButton
      color={promptColors.yellow}
      onClick={() => {
        hide && hide();
        if (isAccountAdmin) {
          history.push('/payments/choose_plan');
        } else {
          window.location = `mailto:${accountAdmin.email}`;
        }
      }}
    >
      {isAccountAdmin ? 'UPGRADE' : 'SEND A NOTE'}
    </PromptButton>
  );
};

const mapState = state => ({
  isAccountAdmin: state.usersDomain.user.isAccountAdmin,
  accountAdmin: state.plansDomain.accountAdmin,
});

export default connect(mapState)(withRouter(UpgradeButton));
