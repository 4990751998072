import React, { Component } from 'react';
import { APP_URL } from '../../constants';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';

class PaymentsUnsubscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      reason: '',
    };
  }

  componentDidMount() {
    this.setState({ show: this.props.show });
  }

  handleErrors = response => {
    if (!response.ok) {
      response.json().then(json => {
        this.props.closeModal();
        this.props.updateFlash('error', json.message);
      });
      throw Error('Could not cancel a subscription');
    }
    return response;
  };

  unsubscribe(e) {
    e.preventDefault();
    const data = new FormData();
    data.append('account[reason]', this.state.reason);
    const headers = HEADERS();
    fetch(`${APP_URL}/payments/unsubscribe`, { method: 'post', headers, body: data })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => this.handleErrors(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.props.closeModal();
          this.props.getData();
          this.props.addMessage(jsonResponse.message);
        });
      })
      .catch(() => {});
  }

  render() {
    const classes = this.state.show ? 'js-unsub-modal modal fade in' : 'js-unsub-modal modal fade';
    const style = this.state.show
      ? { display: 'block', background: 'rgba(0,0,0,0.75)' }
      : { display: 'none' };
    return (
      <div className={classes} style={style}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header no-border centered">
              <button className="close" onClick={e => this.props.closeModal()}>
                <i className="fa fa-close" />
              </button>
              <h2 className="modal-title">Sorry to see you go</h2>
            </div>
            <div className="modal-body modal-body-no-padding">
              <div className="u-padding-horizontal-md centered">
                <p>
                  We hope you’ve enjoyed the journey. Thanks for being with us. We’re always working
                  to improve on what we do. So if you have 2 more minutes to spare please let us
                  know what didn’t work out for your team or what we could do better.
                </p>
              </div>
              <div className="intro-modal-footer u-padding-vertical-sm">
                <div className="u-padding-horizontal-md">
                  <form className="simple_form account">
                    <div className="form-group text required account_reason">
                      <label htmlFor="account_reason" className="text required control-label">
                        Your message
                      </label>
                      <textarea
                        className="text required form-control"
                        id="account_reason"
                        name="account[reason]"
                        placeholder="Do you have some time to let us know why?"
                        onChange={e => this.setState({ reason: e.target.value })}
                        defaultValue={this.state.reason}
                      />
                    </div>
                    <div className="clearfix">
                      <div className="pull-right">
                        <a className="btn btn-sm" onClick={e => this.props.closeModal()}>
                          Cancel
                        </a>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={e => {
                            this.unsubscribe(e);
                          }}
                        >
                          Unsubscribe
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PaymentsUnsubscribe;
