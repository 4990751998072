import React, { Component } from 'react';
import styled from '@emotion/styled';
import SpaceLogo from '../../spaces/SpaceLogo';
import { AVATAR_ERROR } from '../../../helpers';

const UserImage = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 50%;
`;

const RecipientsImagesWrap = styled.div`
  position: relative;
  margin: 22px auto 28px;
  width: ${props => 40 + props.length * 60 + 'px'};
  height: 100px;
  display: block;
  text-align: center;
  div {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    background-color: #fff;
    border: 5px solid #fff;
    border-radius: 50%;
    &:nth-of-type(2) {
      left: 60px;
      z-index: 3;
    }
    &:nth-of-type(3) {
      left: 120px;
      z-index: 2;
    }
    &:nth-of-type(4) {
      left: 180px;
      z-index: 1;
    }
    .shoutouts-consolidation {
      display: block;
      border-radius: 50%;
      width: 90px !important;
      height: 90px !important;
      font-size: 35px;
      font-weight: bold;
    }
    span.shoutouts-consolidation {
      padding-top: 20px;
    }
  }
`;

class ShoutoutRecipientsImage extends Component {
  render() {
    const { recipients } = this.props;
    const trimmedRecipients = recipients.slice(0, 4);
    return (
      <div className="media-multiple">
        <RecipientsImagesWrap length={trimmedRecipients.length}>
          {trimmedRecipients.map(recipient => (
            <div>
              {' '}
              {recipient.recipient ? (
                <UserImage
                  key={recipient.recipient.id}
                  alt={`avatar`}
                  src={recipient.recipient.image_url}
                  onError={e => AVATAR_ERROR(e.target)}
                />
              ) : (
                <SpaceLogo space={recipient.recipient_team} className={'shoutouts-consolidation'} />
              )}
            </div>
          ))}
        </RecipientsImagesWrap>
      </div>
    );
  }
}

export default ShoutoutRecipientsImage;
