import React from 'react';
import styled from '@emotion/styled';
import planTypes from 'components/payments/PaymentChoose/planTypes';
import { connect } from 'react-redux';
import DropdownArrow from './dropdown-arrow.svg';
import StartHereArrow from './desktop/StartHereArrow.svg';

const MobileContainer = styled.div`
  margin-bottom: 20px;
  @media (min-width: 768px) {
    display: none;
  }
`;
const Select = styled.select`
  display: block;
  font-size: 16px;
  color: #11a9ff;
  line-height: 1.3;
  height: 48px;
  padding: 0 30px;
  width: 100%;
  max-width: 100%;
  margin: 0;
  border: 1px solid #d3efff;
  box-sizing: border-box;
  border-radius: 32px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url(${DropdownArrow});
  background-repeat: no-repeat;
  background-position: right 25px top 55%;
`;

const DesktopContainer = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

const InputContainer = styled.div`
  display: inline-flex;
  overflow: hidden;
  margin-bottom: 30px;
  border: 1px solid #dfebf0;
  box-sizing: border-box;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 45px;
`;

const Label = styled.label`
  font-size: 14px;
  text-align: center;
  line-height: 17px;
  padding: 14px 16px;
  min-height: 46px;
  margin: 5px;
  color: #11a9ff;
  border-radius: 35px;
  cursor: pointer;
`;

const Input = styled.input`
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;

  :checked + label {
    background: #11a9ff;
    color: white;
  }

  &:disabled + label {
    color: #a6a6a6 !important;
  }
`;

const StartHere = styled.div`
  display: none;
  @media (min-width: 1350px) {
    display: block;
  }

  font-family: 'Kalam', serif;
  font-size: 20px;
  line-height: 150%;
  text-transform: uppercase;
  color: #fe483c;
  img {
    margin-left: 19px;
    margin-right: 35px;
  }
`;

const planKeys = Object.keys(planTypes).map(key => key?.toString());

const PlanSizeSelect = ({ value, setValue, accountPlanSize, usersCount }) => {
  const mapPlanTypes = cb =>
    planKeys.map(key => {
      let disabled = false;
      const planSize = accountPlanSize?.toString();
      switch (key.toString()) {
        case 'perUser':
          if (['25', '50', '100', 'custom'].includes(planSize)) {
            disabled = true;
          }
          break;
        case '25':
          if (['50', '100', 'custom'].includes(planSize) || usersCount > 25) {
            disabled = true;
          }
          break;
        case '50':
          if (['100', 'custom'].includes(planSize) || usersCount > 50) {
            disabled = true;
          }
          break;
        case '100':
          if (['custom'].includes(planSize) || usersCount > 100) {
            disabled = true;
          }
          break;
      }

      return cb({ key, disabled });
    });

  return [
    <MobileContainer>
      <Select value={value} onChange={e => setValue(e.target.value)}>
        {mapPlanTypes(({ key, disabled }) => (
          <option value={key} disabled={disabled}>
            {planTypes[key].name}
          </option>
        ))}
      </Select>
    </MobileContainer>,
    <DesktopContainer>
      <StartHere>
        Start here
        <img src={StartHereArrow} alt="" />
      </StartHere>
      <InputContainer>
        {mapPlanTypes(({ key, disabled }) => {
          const checked = key === value;
          if (disabled && checked)
            setValue(planKeys[(planKeys.indexOf(key) + 1) % planKeys.length]);
          return (
            <>
              <Input
                type="radio"
                name="switch-two"
                value={key}
                checked={checked}
                onChange={e => setValue(e.target.value)}
                id={`planButton${key}`}
                disabled={disabled}
              />
              <Label htmlFor={`planButton${key}`}>{planTypes[key].name}</Label>
            </>
          );
        })}
      </InputContainer>
    </DesktopContainer>,
  ];
};

const mapState = state => ({
  accountPlanSize: state.plansDomain.planSize,
  usersCount: state.plansDomain.usersCount,
});

export default connect(mapState)(PlanSizeSelect);
