import React from 'react';
import PropTypes from 'prop-types';
import { toggleNoteModal } from 'redux/app/actions';
import { connect } from 'react-redux';
import { GROW_ACTIONS } from 'redux/actions/growActions';

const Resolve = ({ resolveNote, toggleNoteModal, id }) => {
  const handleClick = () => {
    resolveNote(id);
    toggleNoteModal(false);
  };

  return <div onClick={handleClick}>Resolve</div>;
};

Resolve.propTypes = {
  id: PropTypes.number.isRequired,
};

const mapDispatch = dispatch => ({
  resolveNote: id => dispatch(GROW_ACTIONS.resolveNote(id)),
  toggleNoteModal: toggled => dispatch(toggleNoteModal(toggled)),
});

export default connect(null, mapDispatch)(Resolve);
