import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { AVATAR_ERROR } from 'helpers';
import { css } from '@emotion/core';

const AuthorWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: 11px;
  line-height: 13px;
`;
const Avatar = styled.img`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 6px;
`;
const AuthorInfoName = styled.a`
  color: #323232;
  text-decoration: none !important;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
`;
const SharedInfo = styled.span`
  margin-right: 5px;
  color: ${props =>
    props.isResolved && !props.inHeader ? '#52BA05' : props.assigned ? '#323232' : '#11A9FF'};
  ${({ isCancelled }) =>
    isCancelled &&
    css`
      color: #979797;
    `};
  white-space: nowrap;
`;
const getUserName = user => `${user.first_name} ${user.last_name}`;

const Author = ({
  user,
  bold = false,
  shared = false,
  assigned = false,
  assignedTo = false,
  isResolved = false,
  completed = false,
  inHeader,
  isCancelled,
}) => {
  if (!user) {
    return '';
  }
  const username = getUserName(user);
  return (
    <AuthorWrapper>
      {(shared || assigned || assignedTo || completed) && (
        <SharedInfo
          isResolved={isResolved}
          assigned={assigned}
          inHeader={inHeader}
          isCancelled={isCancelled}
        >
          {shared ? 'Shared from' : null}
          {assigned ? 'Assigned by' : null}
          {assignedTo ? 'Assigned to' : null}
          {completed ? 'Completed by' : null}
        </SharedInfo>
      )}
      <Avatar src={user.image_url} alt={username} onError={e => AVATAR_ERROR(e.target)} />
      <AuthorInfoName bold={bold}>{username}</AuthorInfoName>
    </AuthorWrapper>
  );
};

Author.propTypes = {
  user: PropTypes.object.isRequired,
  shared: PropTypes.bool,
};

export default Author;
