import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import React from 'react';
import { SCORE_MOBILE_BREAKPOINT } from 'pages/Insights/OrganisationInsights/Score/layout';

const LineContainer = styled.div`
  position: relative;
  width: 30px;
  align-self: stretch;
  flex-shrink: 0;
  display: none;
  @media (min-width: ${SCORE_MOBILE_BREAKPOINT}) {
    display: block;
  }
`;

const Line = styled.div`
  position: absolute;
  border-left: #d6d6d6 1px dashed;
  content: '';
  width: 50%;
  height: ${({ trimmed }) => (trimmed ? '100%' : 'calc(100% + 12px)')};
  left: 0;
  right: 0;
  top: 0;
  margin-left: auto;
  margin-right: auto;
`;

const DottedLine = ({ depth, nextDepth }) =>
  depth < 2 ? null : (
    <>
      <DottedLine depth={depth - 1} nextDepth={nextDepth} />
      <LineContainer>
        <Line trimmed={!nextDepth ? true : nextDepth < depth} />
      </LineContainer>
    </>
  );

DottedLine.propTypes = {
  depth: PropTypes.number.isRequired,
  nextDepth: PropTypes.number,
};

DottedLine.defaultProps = {
  nextDepth: null,
};

export default DottedLine;
