import styled from '@emotion/styled';
import { BlueButton } from 'components/payments/PaymentChoose/shared/PlanButton';

export const Container = styled.div`
  padding: 25px 25px 30px;
  text-align: center;
`;
export const Content = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  margin-top: 15px;
`;
export const Picture = styled.div`
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const BlueButtonStyled = styled(BlueButton)`
  font-size: 14px;
  display: inline-block;
  padding: 3px 20px;
  width: auto;
  margin-top: 20px;
`;

export const GrayButton = styled.div`
  font-size: 13px;
  line-height: 15px;
  text-decoration: underline;
  color: #6a6a6a;
  cursor: pointer;
  margin-top: 10px;
`;
