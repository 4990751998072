import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Layout from 'pages/Grow/NoteLayout';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { connect } from 'react-redux';

import { ReactComponent as Comments } from 'pages/Grow/GrowPost/assets/comments.svg';
import { ReactComponent as Media } from 'pages/Grow/GrowPost/assets/media.svg';
import { getFollowUp } from 'helpers.js';
import striptags from 'striptags';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';
import Author from './Author';
import SharedMultiple from './SharedMultiple';
import SharedWith from './SharedWith';

import NoteDropdown from '../GrowPost/NoteDropdown/NoteDropdown';
import SharedTeam from '../SharedTeam';

const ConfirmButton = styled.div`
  background: #46b700;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 39px;
  cursor: pointer;
`;

const CommentsStyled = styled(Comments)`
  path {
    ${({ isResolved }) =>
      isResolved &&
      css`
        fill: #52ba05;
      `}
    ${({ isCancelled }) =>
      isCancelled &&
      css`
        fill: #979797;
      `};
  }
`;

const MediaStyled = styled(Media)`
  path:not([fill='white']) {
    ${({ isResolved }) =>
      isResolved &&
      css`
        fill: #52ba05;
      `}
    ${({ isCancelled }) =>
      isCancelled &&
      css`
        fill: #979797;
      `};
  }
`;

const FlagStyled = styled(Layout.FlagStyled)`
  line-height: 28px;
`;

const GlassesStyled = styled(Layout.GlassesStyled)`
  line-height: 28px;
`;

const SharedStyled = styled(Layout.Shared)`
  line-height: 28px;
`;

const Marker = ({ values }) => (
  <Layout.MarkerWrapper>
    {values.map(value => (
      <Layout.Value>{value}</Layout.Value>
    ))}
  </Layout.MarkerWrapper>
);

const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 25px 25px;
`;

const FooterComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
`;

const FooterComponentNumber = styled.div`
  margin-left: 9px;
  font-size: 13px;
  font-weight: normal;
  color: #323232;
`;

const GoalTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 152.34%;
  ${({ isResolved }) =>
    isResolved &&
    css`
      color: #52ba05;
    `};
  ${({ isCancelled }) =>
    isCancelled &&
    css`
      color: #979797;
    `};
  padding: 0 25px 25px;
  word-break: break-word;
`;

const SimpleWrapper = styled.div`
  padding: 0 25px 25px;
`;

Marker.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const ProgressBoardPost = ({
  postId,
  note,
  win,
  type,
  removeNoteClickHandle,
  progressStatus,
  addToProgressBoardEnabled,
  openProgressBoardPost,
  user,
  confirmNote,
  preparationNote,
}) => {
  note = note || win;
  const followUp = getFollowUp(note.due_date, note.created_at, note.type, user.timeZone);
  const isResolved = note.status === 'resolved';
  const mediaLength = note.media.length > 0 && note.media[0].type === 'v' ? 1 : note.media.length;
  return (
    <>
      {!isResolved &&
        ['SharedNote', 'SharedWin', 'SharedManagerPrompt', 'SharedMeetingStep'].includes(type) && (
          <Layout.Header variant={note.assignee_id ? 'yellow' : 'azure'} progressBoard>
            <Author
              user={note.owner || win.author}
              bold
              shared={Boolean(note.shared_from)}
              assigned={Boolean(note.assignee_id)}
              isResolved={isResolved}
              inHeader
              isCancelled={progressStatus === 'not_complete'}
            />
            {(note.type === noteTypes.goal || note.type === noteTypes.meetingStep) &&
              !isResolved && (
                <NoteDropdown
                  postId={postId}
                  object={note}
                  type={type}
                  progressStatus={progressStatus}
                  addToProgressBoardEnabled={addToProgressBoardEnabled}
                />
              )}
          </Layout.Header>
        )}
      <Layout.Header progressBoard>
        <Layout.HeaderDate progressBoard onClick={() => openProgressBoardPost(postId)}>
          {moment.utc(note.created_at).tz(user.timeZone).format('DD MMMM YYYY')}
        </Layout.HeaderDate>
        <Layout.HeaderType onClick={() => openProgressBoardPost(postId)}>
          {type !== 'SharedNote' &&
            type !== 'SharedWin' &&
            type !== 'SharedManagerPrompt' &&
            (note.shared_with.length || note.shared_with_team.length || note.assignee ? (
              <>
                {note.shared_with.length || note.shared_with_team.length ? (
                  <Layout.MegaphoneStyled
                    isResolved={isResolved}
                    isCancelled={progressStatus === 'not_complete'}
                    progressBoard
                  />
                ) : (
                  <FlagStyled
                    isCancelled={progressStatus === 'not_complete'}
                    isResolved={isResolved}
                  />
                )}
                <SharedStyled isResolved={isResolved} />
              </>
            ) : (
              <GlassesStyled isResolved={isResolved} />
            ))}
        </Layout.HeaderType>

        {(!type.includes('Shared') || (note.type === noteTypes.want && !note.status)) && (
          <NoteDropdown
            postId={postId}
            object={note}
            type={type}
            removeNote
            progressStatus={progressStatus}
            addToProgressBoardEnabled={addToProgressBoardEnabled}
            removeNoteClickHandle={removeNoteClickHandle}
            preparationNote={preparationNote}
          />
        )}
      </Layout.Header>
      {note.type === noteTypes.private && (
        <Layout.PrivateHeader progressBoard onClick={() => openProgressBoardPost(postId)}>
          <Layout.PrivateStyled />
          Private note: <strong>{Layout.getFeedbackRecipientName(note)}</strong>
        </Layout.PrivateHeader>
      )}
      {Boolean(note.title) && (
        <GoalTitle
          isResolved={isResolved}
          isCancelled={progressStatus === 'not_complete'}
          onClick={() => openProgressBoardPost(postId)}
        >
          {note.type === noteTypes.managerPrompt && <div>Action plan:</div>}
          {note.title}{' '}
          {note.type === noteTypes.meetingPreparation &&
            (note.member
              ? `${note.member.first_name} ${note.member.last_name}`
              : `${note.team.name}`)}
        </GoalTitle>
      )}
      {Boolean(note.content) && note.type !== noteTypes.managerPrompt && (
        <Layout.Content
          progressBoard
          onClick={() => openProgressBoardPost(postId)}
          dangerouslySetInnerHTML={{
            __html: striptags(note.content, ['a', 'br']),
          }}
        />
      )}
      {Boolean(note.category) && <Marker values={[note.category]} />}
      {note.assignee_id && note.assignee_id !== user.id && (
        <SimpleWrapper onClick={() => openProgressBoardPost(postId)}>
          <Author
            user={note.assignee}
            bold
            assignedTo
            isResolved={isResolved}
            isCancelled={progressStatus === 'not_complete'}
          />
        </SimpleWrapper>
      )}
      {(note.shared_with.length > 0 || note.shared_with_team.length > 0) &&
        note.owner_id === user.id && (
          <SharedWith
            onClick={() => openProgressBoardPost(postId)}
            isResolved={isResolved}
            isCancelled={progressStatus === 'not_complete'}
          >
            {note.shared_with.length + note.shared_with_team.length === 1 ? (
              note.shared_with.length ? (
                <Author user={note.shared_with[0]} bold isResolved={isResolved} />
              ) : (
                <SharedTeam team={note.shared_with_team[0]} className="progress-board" />
              )
            ) : (
              <SharedMultiple />
            )}
          </SharedWith>
        )}
      {isResolved &&
        note.resolved_by &&
        ((note.assignee && note.assignee.id !== note.owner.id) ||
          note.shared_with.length > 0 ||
          note.shared_with_team.length > 0) && (
          <>
            <SimpleWrapper onClick={() => openProgressBoardPost(postId)}>
              <Author
                user={note.resolved_by}
                bold={isResolved}
                completed={isResolved}
                isResolved={isResolved}
                isCancelled={progressStatus === 'not_complete'}
              />
            </SimpleWrapper>
            {!note.confirmed_at && note.type === noteTypes.goal && note.owner_id === user.id && (
              <SimpleWrapper>
                <ConfirmButton onClick={() => confirmNote(postId, note.id)}>Confirm?</ConfirmButton>
              </SimpleWrapper>
            )}
          </>
        )}
      {Boolean(note.resolved_at) && Boolean(note.confirmed_at) && (
        <Layout.Resolved onClick={() => openProgressBoardPost(postId)}>
          <Layout.TickStyled />
          Resolved
        </Layout.Resolved>
      )}
      {Boolean(
        note.type === noteTypes.goal ||
          note.type === noteTypes.meetingPreparation ||
          note.type === noteTypes.managerPrompt ||
          note.type === noteTypes.managerPromptPreparation ||
          note.type === noteTypes.meetingStep
      ) &&
        note.status !== 'resolved' &&
        progressStatus !== 'not_complete' &&
        (note.owner_id === user.id || note.due_date) && (
          <Layout.FollowUp color={followUp.color} onClick={() => openProgressBoardPost(postId)}>
            <Layout.ClockStyled
              color={progressStatus === 'not_complete' ? '#979797' : followUp.color}
            />
            {followUp.date}
          </Layout.FollowUp>
        )}
      {(Boolean(note.media.length) || note.total_comments_count > 0) && (
        <FooterWrapper onClick={() => openProgressBoardPost(postId)}>
          {Boolean(note.media.length) && (
            <FooterComponentWrapper>
              <MediaStyled
                isResolved={isResolved}
                isCancelled={progressStatus === 'not_complete'}
              />
              <FooterComponentNumber>{mediaLength}</FooterComponentNumber>
            </FooterComponentWrapper>
          )}
          {note.total_comments_count > 0 && (
            <FooterComponentWrapper>
              <CommentsStyled
                isResolved={isResolved}
                isCancelled={progressStatus === 'not_complete'}
              />
              <FooterComponentNumber>{note.total_comments_count}</FooterComponentNumber>
            </FooterComponentWrapper>
          )}
        </FooterWrapper>
      )}
    </>
  );
};
ProgressBoardPost.propTypes = {
  postId: PropTypes.number,
  note: PropTypes.shape(),
  win: PropTypes.object,
  type: PropTypes.string,
};

const mapStateToProps = state => ({
  user: state.usersDomain.user,
});

export default connect(mapStateToProps, null)(ProgressBoardPost);
