import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import {
  ADMIN_ROLE,
  APP_URL,
  COMPANY_OWNER_ROLE,
  TEAM_MANAGER_ROLE,
  TEAM_MEMBER_ROLE,
} from '../../constants';
import { HEADERS, LOCALSTORAGE_GET } from '../../helpers';

class ImpersonateIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      error: false,
    };
  }

  componentDidMount() {
    const cookies = new Cookies();
    const impersonateCookie = cookies.get('impersonator');
    const { url } = this.props.match;
    let accessToken = '';
    if (impersonateCookie) {
      accessToken = impersonateCookie;
      localStorage.clear();
      localStorage.setItem('access_token', accessToken);
    } else if (localStorage != null) {
      if (LOCALSTORAGE_GET('admin_access_token')) {
        accessToken = LOCALSTORAGE_GET('admin_access_token');
        localStorage.clear();
        localStorage.setItem('access_token', accessToken);
      } else {
        accessToken = LOCALSTORAGE_GET('access_token');
      }
    }

    const headers = HEADERS(accessToken);
    fetch(APP_URL + url, { method: 'get', headers })
      .then(response => {
        if (response.status === 401) {
          this.setState({
            error: true,
            redirect: true,
          });
        }
        return response;
      })
      .then(response => {
        response.json().then(jsonResponse => {
          if (jsonResponse.success) {
            const adminAccessToken = LOCALSTORAGE_GET('access_token');
            localStorage.clear();
            localStorage.setItem('admin_access_token', adminAccessToken);
            localStorage.setItem('impersonate', true);
            localStorage.setItem('access_token', jsonResponse.accessToken);
            localStorage.setItem('is_authenticated', true);

            sessionStorage.setItem(
              'token_expires_at',
              new Date(new Date().getTime() + 24 * 3600 * 1000)
            );
            localStorage.setItem(
              'token_expires_at',
              new Date(new Date().getTime() + 24 * 3600 * 1000)
            );

            localStorage.setItem('user_first_name', jsonResponse.first_name);
            localStorage.setItem('user_last_name', jsonResponse.last_name);
            localStorage.setItem('user_image_uid', jsonResponse.image_uid);
            localStorage.setItem('user_id', jsonResponse.id);
            localStorage.setItem('user_type', jsonResponse.type);
            localStorage.setItem('account_id', jsonResponse.account_id);
            localStorage.setItem('upgrade_account', jsonResponse.upgrade_account);
            jsonResponse.roles.forEach(role => {
              switch (role.name) {
                case ADMIN_ROLE:
                  localStorage.setItem('is_admin', true);
                  break;
                case COMPANY_OWNER_ROLE:
                  localStorage.setItem('is_company_owner', true);
                  break;
                case TEAM_MANAGER_ROLE:
                  localStorage.setItem('is_team_manager', true);
                  break;
                case TEAM_MEMBER_ROLE:
                  localStorage.setItem('is_team_member', true);
                  break;
                default:
              }
            });
            this.setState({ redirect: true });
          } else {
            this.setState({
              error: true,
              redirect: true,
            });
          }
        });
      })
      .catch(() => {});
  }

  render() {
    if (this.state.redirect && this.state.error) {
      return (
        <Redirect
          push
          to={{
            pathname: '/',
          }}
        />
      );
    }
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: '/',
            search: '?from=impersonate',
          }}
        />
      );
    }
    return <div />;
  }
}

export default ImpersonateIndex;
