import styled from '@emotion/styled';

export const Wrap = styled.div`
  padding: 28px 18px 28px 23px;
  float: right;
  width: 30%;
  max-height: 558px;
  @media (max-width: 600px) {
    max-height: 707px;
  }
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f2f2f2;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
  }
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 16px;
`;
export const NotesWrap = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  word-break: break-word;
  white-space: pre-wrap;
`;
