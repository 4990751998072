import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { AWS_S3_URL } from '../../constants';

class InvitesIntroModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  componentDidMount() {
    this.setState({ display: this.props.display });
  }

  closePopup(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({ display: false });
    localStorage.removeItem('onboarding_flow_invite');
  }

  render() {
    return (
      <Modal
        className="intro-modal js-intro-modal"
        show={this.state.display}
        onHide={e => this.closePopup(e)}
      >
        <Modal.Header className="no-border centered">
          <button
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={e => this.closePopup(e)}
          >
            <i className="icon fa fa-close" onClick={() => this.closePopup(null)} />
          </button>
          <Modal.Title>
            <img
              src={`${AWS_S3_URL}public/images/jv-logo-rings.png`}
              alt="logo"
              className="u-margin-right-xxs u-height-md"
            />
            Welcome to Heelix, {localStorage.getItem('user_first_name')}!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="centered modal-body-no-padding">
          <p className="u-padding-horizontal-md u-margin-bottom-sm">
            We’re here to help your team work better together so let’s start by getting them on
            board. It's a good idea to invite your whole team in one go so everyone starts off
            together.
          </p>
          <a
            href=""
            className="btn btn-primary btn-lg u-margin-bottom-md"
            onClick={e => this.closePopup(e)}
          >
            Invite your team
          </a>
          <div className="intro-modal-footer u-padding-vertical-sm">
            <h3 className="u-margin-top-none">Team Tips</h3>
            <div className="u-padding-horizontal-sm u-margin-top-xs text-left u-size0">
              <ul>
                <li> Invite at least five people to make sure their feedback stays anonymous.</li>
                <li>
                  Everyone you invite will be sent an email introducing Heelix and letting them know
                  what to do next.
                </li>
                <li> You can split users into smaller teams at any time.</li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default InvitesIntroModal;
