import React, { useEffect, useRef, useState } from 'react';
import LoadingOverlay from 'components/signup/LoadingOverlay';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import fetchApi from 'fetchApi';
import ErrorPage from 'components/signup/ErrorPage';
import { useInterval } from 'helpers';

export const useGoogleCalendarSync = () => {
  const [enabled, setEnabled] = useState(undefined);
  const [syncMeeting, setSyncMeeting] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(null);
  const windowRef = useRef(window);

  useEffect(() => {
    if (!syncMeeting) return;
    if (enabled === false) {
      windowRef.current = window.open('/google_calendar_sync?ok=true');
      setPopupOpen(true);
      setLoadingOverlay('Syncing with Google Calendar');
    }
  }, [syncMeeting]);

  const fetchCalendarStatus = async () => {
    setTimeout(async () => {
      const response = await fetchApi('/meeting_google_calendar_status');
      setSyncMeeting(Boolean(response?.isGoogleCalendarEnabled));
      setLoadingOverlay(null);
    }, 2000);
  };

  useInterval(
    () => {
      if (windowRef.current.closed) {
        setPopupOpen(false);
        setLoadingOverlay('Please wait...');
        fetchCalendarStatus();
      }
    },
    popupOpen ? 2000 : null
  );

  return [setEnabled, syncMeeting, setSyncMeeting, loadingOverlay];
};

const GoogleCalendarPopup = ({ location }) => {
  const [error, setError] = useState(null);
  const handleSync = async () => {
    const response = await fetchApi('/meeting_google_calendar', { method: 'post' });
    if (response.redirect) {
      window.location.href = response.url;
      return;
    }
    if (!response.ok) {
      setError(response.error ?? 'Something went wrong. Please try again later.');
    } else {
      window.close();
    }
  };

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (params.ok) {
      handleSync();
    } else {
      setError(params.error ?? 'Something went wrong. Please try again later.');
    }
  }, []);

  return error ? (
    <ErrorPage title="Something went wrong" body={error} />
  ) : (
    <div>
      <LoadingOverlay toggled height="100%" />
    </div>
  );
};

export default withRouter(GoogleCalendarPopup);
