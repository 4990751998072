import React from 'react';
import styled from '@emotion/styled';

const AddNextStepWrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 13px;
  background: ${props => (props.fromNote ? '#FFFFFF' : '#F3F3F3')};
  border-radius: 25px;
  width: ${props => (props.label === 'step' ? 127 : 100)}px;
  height: 30px;
  color: #6a6a6a;
  font-size: 12px;
  line-height: 16px;
  padding: 5px 13px;
  cursor: pointer;
`;

const PlusSize = styled.span`
  font-size: 19px;
  margin-right: 9px;
  display: inline-block;
  vertical-align: text-bottom;
  margin-top: 2px;
`;

const AddNextStepButton = ({ handleClick, label, fromNote = false }) => (
  <AddNextStepWrapper onClick={handleClick} label={label} fromNote={fromNote}>
    <PlusSize>+ </PlusSize>
    Add {label === 'step' ? 'next ' : ''} {label}
  </AddNextStepWrapper>
);

export default AddNextStepButton;
