/** @jsx jsx */
import React, { useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const CustomTooltip = ({ children, id, x = null, y = null, toggled = false }) => {
  let ref = null;

  useEffect(() => {
    if (toggled) {
      ReactTooltip.show(ReactDOM.findDOMNode(ref));
    } else {
      ReactTooltip.hide(ReactDOM.findDOMNode(ref));
    }
  }, [toggled]);

  return (
    <>
      <ReactTooltip
        id={id}
        css={css`
          && {
            background: #e1f2fa;
            margin-top: -10px !important;
            max-width: 224px;
            :after {
              border-top: solid 8px #e1f2fa !important;
              bottom: -8px;
              border-left-width: 8px !important;
              border-right-width: 8px !important;
              width: 0;
            }
          }
        `}
        place="top"
        type="light"
        effect="solid"
        overridePosition={({ left, top }) => {
          return { left: left + x, top: top + y };
        }}
      >
        <span>{children}</span>
      </ReactTooltip>
      <span
        data-for={id}
        data-tip={children}
        ref={inst => (ref = inst)}
        css={css`
          position: absolute;
          top: 0;
          left: 0;
        `}
      />
    </>
  );
};

CustomTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  toggled: PropTypes.bool,
  x: PropTypes.number,
  y: PropTypes.number,
};

export default CustomTooltip;
