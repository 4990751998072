import React, { Component } from 'react';
import Select from 'react-select';
import Form from './Form';
import { APP_URL } from '../../constants';
import { BODY_CLASSES, HEADERS, LOCALSTORAGE_BOOL } from '../../helpers';
import InvitesIntroModal from './InvitesIntroModal';
import 'react-select/dist/react-select.css';
import LoadingScale from '../shared/LoadingScale';
import { connect } from 'react-redux';

const qs = require('query-string');

const mapStateToProps = state => ({
  user: state.usersDomain.user,
});

class New extends Component {
  constructor(props) {
    super(props);

    this.state = {
      teams: [],
      showModal: 'none',
      inputs: 1,
      inputsCount: 0,
      formList: {},
      formInputs: [],
      teamId: '',
      emails: '',
      showIntroModal: false,
      loaded: false,
      teamsForSelect: [],
      selectedTeamId: null,
      loading: false,
      planCapacityReached: false,
    };
  }

  componentDidMount() {
    this.props.closeFlash();
    const capacityReached = this.props.user.planCapacityReached;
    this.setState({
      teamsForSelect: this.teamOptions(),
      planCapacityReached: capacityReached,
    });
    if (capacityReached) {
      this.props.updateFlash(
        'error',
        'You’ve reached the maximum number of users for your plan. Please delete some users from your invited list or contact hello@heelix.com / your Account Manager to increase your user allocation.'
      );
    }

    let teamData = null;
    if (this.props.location.search) {
      teamData = qs.parse(this.props.location.search).team;
      if (teamData > 0) {
        this.setState({
          selectedTeamId: teamData,
          teamId: teamData,
        });
      }
    }
  }

  componentWillMount() {
    BODY_CLASSES(['invites', 'new']);
    const headers = HEADERS();
    if (localStorage != null) {
      if (LOCALSTORAGE_BOOL('onboarding_flow_invite')) {
        this.setState({ showIntroModal: true });
      }
    }
    fetch(`${APP_URL}/teamsList`, { method: 'get', headers })
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({ teams: jsonResponse.teams });
          this.setState({ loaded: true });
          this.props.setHeader('Add users');
          this.props.setSubheader('');
          this.props.setInfo('');
        });
      })
      .catch(() => {});
  }

  addAnotherBox() {
    this.setState({ inputsCount: this.state.inputsCount + 1 });
    this.setState({ inputs: this.state.inputs + 1 });
    const count = this.state.inputsCount + 1;
    const el = this.state.formList;
    el[count] = <Form inputsCount={count} inputs={this.state.inputs + 1} />;
    this.setState({ formList: el });
  }

  close() {
    this.setState({ showModal: 'none' });
  }

  open() {
    this.setState({ showModal: 'block' });
  }

  setTeam = e => {
    this.setState({ teamId: e.value });
    this.setState({ selectedTeamId: e.value });
  };

  sendInvites(e) {
    e.preventDefault();
    if (!this.props.user.planCapacityReached) {
      this.setState({ loading: true });
      const data = new FormData();
      const inputs = document.getElementsByTagName('input');
      for (let i = 0; i < inputs.length; i++) {
        const { name } = inputs[i];
        const { value } = inputs[i];
        const element = { name, value };
        data.append(element.name, element.value);
      }
      let { teamId } = this.state;
      if (teamId === '') {
        teamId = this.state.teams[0].id;
      }
      data.append('team', teamId);
      const headers = HEADERS();
      fetch(`${APP_URL}/invitation/send`, { method: 'post', headers, body: data })
        .then(response => {
          response.json().then(jsonResponse => {
            this.props.updateFlash(jsonResponse.flashName, jsonResponse.message);
            if (jsonResponse.flashName === 'success') {
              this.setState({
                inputs: 1,
                inputsCount: 0,
                formList: {},
                formInputs: [],
                emails: '',
              });
              const inputs = document.getElementsByTagName('input');
              for (let i = 0; i < inputs.length; i++) {
                inputs[i].value = '';
              }
            }
            this.setState({ loading: false });
          });
        })
        .catch(() => {});
    }
  }

  addBoxWithValue(value, i) {
    this.setState({ inputsCount: this.state.inputsCount + i });
    this.setState({ inputs: this.state.inputs + i });
    const count = this.state.inputsCount + i;
    const el = this.state.formList;
    el[count] = <Form inputsCount={count} inputs={this.state.inputs + i} value={value} />;
    this.setState({ formList: el });
    this.setState({ formList: this.state.formList });
  }

  addEmails() {
    const { emails } = this.state;
    const array = emails.replace(' ', '').split(',');
    for (let i = 0; i < array.length; i++) {
      const email = document.getElementById('invites[user][0][email]').value;
      const first_name = document.getElementById('invites[user][0][first_name]').value;
      const last_name = document.getElementById('invites[user][0][last_name]').value;
      const role = document.getElementById('invites[user][0][role]').value;
      if (!email && !first_name && !last_name && !role) {
        document.getElementById('invites[user][0][email]').value = array[i];
        document.getElementById('invites[user][0][first_name]').value = this.setFirstName(array[i]);
      } else {
        this.addBoxWithValue(array[i], i + 1);
      }
    }
    this.setState({ showModal: 'none' });
  }

  setFirstName(email) {
    return email.split('@')[0].replace(/\b\w/g, l => l.toUpperCase());
  }

  teamOptions() {
    const opts = [];
    this.state.teams.forEach(team => {
      opts.push({ value: team.id, label: team.name });
    });
    return opts;
  }

  render() {
    const display = this.state.showModal;
    const email = 'invites[user][0][email]';
    const first_name = 'invites[user][0][first_name]';
    const last_name = 'invites[user][0][last_name]';
    const role = 'invites[user][0][role]';
    if (this.state.loaded) {
      return (
        <div className="new-invites-container">
          {this.state.loading && <LoadingScale />}
          <InvitesIntroModal display={this.state.showIntroModal} />
          <div className="contrain">
            <div className="header clear" />
            <div className="form-group email optional account_users_email has-error">
              <span className="help-block">{this.state.error}</span>
            </div>
            <div className="row first-row">
              <div className="col-sm-4">
                <label className="hidden-for-mobile">Email</label>
                <div className="form-group string optional">
                  <input
                    type="email"
                    name={email}
                    id={email}
                    placeholder="Email"
                    className="string optional stretch form-control"
                  />
                </div>
              </div>
              <div className="col-sm-2">
                <label className="hidden-for-mobile">First Name</label>
                <div className="form-group string optional">
                  <input
                    type="text"
                    name={first_name}
                    id={first_name}
                    placeholder="First Name"
                    className="string optional stretch form-control"
                  />
                </div>
              </div>
              <div className="col-sm-2">
                <label className="hidden-for-mobile">Last Name</label>
                <div className="form-group string optional">
                  <input
                    type="text"
                    name={last_name}
                    id={last_name}
                    placeholder="Last Name"
                    className="string optional stretch form-control"
                  />
                </div>
              </div>
              <div className="col-sm-2">
                <label className="hidden-for-mobile">Role/Position</label>
                <div className="form-group string optional">
                  <input
                    type="text"
                    name={role}
                    id={role}
                    placeholder="Role/Position"
                    className="string optional stretch form-control"
                  />
                </div>
              </div>
              <div className="col-sm-2" />
            </div>
            <form className="simple_form" id="form" ref="form">
              <div className="js-users-list" ref="usersList">
                {Object.keys(this.state.formList).map(key => (
                  <div key={key}>{this.state.formList[key]}</div>
                ))}
              </div>
              <div className="add-another-users-box">
                <a
                  id="add-another-user-btn"
                  className="ul-link js-add-user-box"
                  onClick={() => this.addAnotherBox()}
                >
                  Add another user
                </a>
                <span> or </span>
                <a
                  id="invite-people-btn"
                  data-toggle="modal"
                  data-backdrop="static"
                  data-injected-class="cover"
                  data-target="#fullscreen-invite-users"
                  className="ul-link"
                  onClick={() => this.open()}
                >
                  Invite several people at once
                </a>
              </div>
              <div className="row u-margin-top-sm">
                <div className="col-sm-4">
                  <div className="select required user_team">
                    <label htmlFor="team-select" className="select required control-label">
                      Add all users to the following team
                    </label>
                    <Select
                      name="invites[team]"
                      id="team-select"
                      required
                      onChange={e => this.setTeam(e)}
                      value={this.state.selectedTeamId || ''}
                      options={this.teamOptions()}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-10">
                  <button
                    id="send-invites-btn"
                    className="btn btn-primary pull-right pull-xs-none"
                    onClick={e => this.sendInvites(e)}
                    disabled={this.state.planCapacityReached}
                  >
                    Send Invites
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            id="fullscreen-invite-users"
            ref="dialog"
            className="modal modal-fit-height cover"
            tabIndex="1"
            role="dialog"
            style={{ backgroundColor: '#EFEFEF', display }}
          >
            <span className="close" data-dismiss="modal">
              <span className="fa fa-close" onClick={() => this.close()} />
            </span>
            <div className="modal-dialog">
              <header>
                <h1>Add Multiple Users</h1>
              </header>
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="pull-left"> Enter multiple email addresses </label>
                      <textarea
                        name="bulk_invites"
                        id="bulk_invites"
                        rows={3}
                        className="text textarea-expanding"
                        placeholder="example@email.com, another.example@email.com"
                        onChange={e => this.setState({ emails: e.target.value })}
                      />
                      <p className="help-block greyed pull-left text-left u-sizevr-1">
                        <strong>Tip: </strong>
                        You can copy and paste a list of contacts from your email. Make sure to
                        separate email addresses with commas.
                      </p>
                    </div>
                  </div>
                  <div className="row u-margin-top-sm">
                    <div className="col-md-12">
                      <a
                        id="add-emails-btn"
                        className="btn btn-primary pull-right js-invite-prepopulate"
                        data-dismiss="modal"
                        onClick={() => this.addEmails()}
                      >
                        Add
                      </a>
                      <a
                        id="cancel-invite-btn"
                        className="cancel-modal pull-right greyed space-to-right"
                        data-dismiss="modal"
                        onClick={() => this.close()}
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }
}

export default connect(mapStateToProps)(New);
