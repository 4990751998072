import store from 'redux/store';
import { useEffect, useState } from 'react';
import check from 'rbac/check';
import plans from 'rbac/plans';

export default function useCan(perform, data) {
  // const state = store.getState();
  const [can, setCan] = useState(false);

  useEffect(() => {
    let currentValue;

    function handleChange() {
      const previousValue = currentValue;
      currentValue = store.getState()?.plansDomain?.rbacPlan;

      if (previousValue !== currentValue) {
        setCan(check(plans, currentValue, perform, data));
      }
    }

    handleChange();

    const unsubscribe = store.subscribe(handleChange);

    return () => {
      unsubscribe();
    };
  }, []);

  return can;
}
