import React from 'react';
import { NotesWrap, Title } from 'components/vibes/team-vibe-show/partials/RightPanel/layout';
import ListWrap from 'components/vibes/team-vibe-show/partials/ListWrap';
import striptags from 'striptags';
import styled from '@emotion/styled';
import { AVATAR_ERROR } from 'helpers';

const Container = styled.div`
  margin-bottom: 20px;
`;

const StyledNotesWrap = styled(NotesWrap)`
  margin-bottom: 14px;
`;

const UserHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 9px;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
`;

const AttendeesPanel = ({ notes }) => {
  return (
    <Container>
      <Title>Meeting agenda</Title>
      {notes.map(({ additional_notes, steps, owner: { image_url, first_name, last_name } }) => (
        <>
          <UserHeader>
            <Avatar
              src={image_url}
              alt={`${first_name} ${last_name}`}
              onError={e => AVATAR_ERROR(e.target)}
            />
            <Name>
              {first_name} {last_name}
            </Name>
          </UserHeader>
          <StyledNotesWrap
            dangerouslySetInnerHTML={{
              __html: striptags(additional_notes, ['a', 'br']),
            }}
          />
          <ListWrap steps={steps} />
        </>
      ))}
    </Container>
  );
};

export default AttendeesPanel;
