import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { BODY_CLASSES, CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';
import { APP_URL } from '../../constants';

class SpacesAcceptRequest extends Component {
  constructor() {
    super();
    this.state = {
      redirect: false,
      message: '',
      flashName: '',
      spaceId: null,
    };
  }

  componentDidMount() {
    BODY_CLASSES(['jobvibe-future']);
    const { userId } = this.props.match.params;
    const { spaceId } = this.props.match.params;
    if (userId && spaceId) {
      const url = this.props.location.pathname;
      const headers = HEADERS();
      fetch(APP_URL + url, { method: 'get', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            this.setState({
              spaceId: jsonResponse.spaceId,
              message: jsonResponse.message,
              flashName: jsonResponse.type,
              redirect: jsonResponse.redirect,
            });
          });
        });
    }
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: '/spaces',
            state: {
              message: this.state.message,
              flashName: this.state.flashName,
              spaceId: this.state.spaceId,
            },
          }}
        />
      );
    }
    return <div />;
  }
}

export default SpacesAcceptRequest;
