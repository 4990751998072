import DOMAINS from '../domains';

export const TAG_ACTION_TYPES = {
  FETCH_DISCUSSION_TAGS: 'FETCH_DISCUSSION_TAGS',
  RECEIVE_DISCUSSION_TAGS: 'RECEIVE_DISCUSSION_TAGS',
  FETCH_HASHTAGS: 'FETCH_HASHTAGS',
  RECEIVE_HASHTAGS: 'RECEIVE_HASHTAGS',
  FETCH_GOALS: 'FETCH_GOALS',
  RECEIVE_GOALS: 'RECEIVE_GOALS',
  FETCH_COMPANY_VALUES: 'FETCH_COMPANY_VALUES',
  RECEIVE_COMPANY_VALUES: 'RECEIVE_COMPANY_VALUES',
};
export const TAG_ACTIONS = {
  // --Used mostly in React components during dispatch
  fetchDiscussionTags: () => ({
    domain: DOMAINS.TAGS,
    type: TAG_ACTION_TYPES.FETCH_DISCUSSION_TAGS,
  }),
  receiveDiscussionTags: json => ({
    domain: DOMAINS.TAGS,
    type: TAG_ACTION_TYPES.RECEIVE_DISCUSSION_TAGS,
    payload: json,
  }),
  fetchHashtags: () => ({ domain: DOMAINS.TAGS, type: TAG_ACTION_TYPES.FETCH_HASHTAGS }),
  receiveHashtags: json => ({
    domain: DOMAINS.TAGS,
    type: TAG_ACTION_TYPES.RECEIVE_HASHTAGS,
    payload: json,
  }),
  fetchGoals: () => ({ domain: DOMAINS.TAGS, type: TAG_ACTION_TYPES.FETCH_GOALS }),
  receiveGoals: json => ({
    domain: DOMAINS.TAGS,
    type: TAG_ACTION_TYPES.RECEIVE_GOALS,
    payload: json,
  }),
  fetchCompanyValues: () => ({ domain: DOMAINS.TAGS, type: TAG_ACTION_TYPES.FETCH_COMPANY_VALUES }),
  receiveCompanyValues: json => ({
    domain: DOMAINS.TAGS,
    type: TAG_ACTION_TYPES.RECEIVE_COMPANY_VALUES,
    payload: json,
  }),
};
