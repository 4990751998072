import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import qs from 'qs';
import styled from '@emotion/styled';
import { BODY_CLASSES, CHECK_UNAUTHORIZED, HEADERS } from 'helpers';
import { APP_URL, AWS_S3_URL } from 'constants.js';
import StripeForm from './StripeForm';

const StyledLink = styled.a`
  color: #323232;
  font-weight: bold;
  text-decoration: underline;
  :hover {
    color: #323232;
  }
`;

class PaymentsNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeUsers: '',
      amountInclGst: '',
      totalInclGst: '',
      amount: '',
      stripeUpgradePlan: '',
      countries: [],
      includeGst: false,
      country: '',
    };
  }

  UNSAFE_componentWillMount() {
    BODY_CLASSES(['payments', 'heelix', 'new-payments-page']);
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.state) {
      if (location.state.stripeUpgradePlan) {
        this.setState({ stripeUpgradePlan: location.state.stripeUpgradePlan });
      }
    }

    this.fetchData();
  }

  getPlan = () => {
    const { location } = this.props;
    const plan = qs.parse(location.search, { ignoreQueryPrefix: true }).plan || 'pro';
    if (plan !== 'pro' && plan !== 'plus') {
      return 'pro';
    }
    return plan;
  };

  includeGst = async nextCountry => {
    const { country } = this.state;
    if (nextCountry !== country) {
      await this.fetchData(nextCountry);
      this.setState({ country: nextCountry });
    }
    this.setState({ includeGst: nextCountry === 'AU' });
  };

  fetchData = async (country = '') => {
    const headers = HEADERS();
    const { location } = this.props;
    const params = {
      ...qs.parse(location.search, { ignoreQueryPrefix: true }),
      country,
    };
    return fetch(`${APP_URL}/payments/new?${qs.stringify(params)}`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({
            activeUsers: jsonResponse.activeUsers,
            activeUsersLimit: jsonResponse.activeUsersLimit,
            amountInclGst: jsonResponse.amountInclGst,
            totalInclGst: jsonResponse.totalInclGst,
            gst: jsonResponse.gst,
            amount: jsonResponse.amount,
            total: jsonResponse.total,
            stripeUpgradePlan: jsonResponse.stripeUpgradePlan,
            countries: jsonResponse.countries,
          });
        });
      })
      .catch(() => {});
  };

  trimDecimalZeros = value => (!value ? value : value.replace(/\.0+$/, ''));

  render() {
    const {
      amount,
      includeGst,
      stripeUpgradePlan,
      activeUsers,
      activeUsersLimit,
      totalInclGst,
      total,
      gst,
    } = this.state;
    const isMonthly = stripeUpgradePlan.interval === 'month';
    const displayAmount = !amount ? '' : this.trimDecimalZeros(amount);
    const { location } = this.props;
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { amountInclGst } = this.state;
    const { amount: amount1 } = this.state;
    const { stripeUpgradePlan: stripeUpgradePlan1 } = this.state;
    const { stripeUpgradePlan: stripeUpgradePlan2 } = this.state;
    const { countries } = this.state;
    const { amountInclGst: amountInclGst1 } = this.state;
    return (
      <div className="new_payment new-payment-wrapper">
        <div className="row">
          <div className="col-xs-12">
            <h2 className="text-center" id="new-payment-page-title">
              {params.upgrade === 'true' ? 'Upgrade' : 'Downgrade'} to {stripeUpgradePlan.name}
            </h2>
          </div>
        </div>
        <div className="row">
          <div id="subscription-details-wrapper">
            <h3>Subscription details</h3>
            <div id="subscription-details-amount-wrapper">
              <div id="subscription-details-amount">{displayAmount}</div>
              <div>
                {stripeUpgradePlan.currency?.toUpperCase()}
                {isMonthly ? ' per user per month' : ' per month, billed annually'}

                {includeGst && ` plus GST ($${amountInclGst1}${isMonthly ? '/user' : '/account'})`}
              </div>
            </div>
            <p id="subscription-details-description">
              Based on {isMonthly ? activeUsers : `1-${activeUsersLimit}`} users, we&apos;ll charge
              you today
              <strong>
                {' '}
                ${this.trimDecimalZeros(includeGst ? totalInclGst : total)}
                {isMonthly && '/m'}
              </strong>
              {includeGst && ` (Including GST of $${gst})`}.{' '}
              {isMonthly ? (
                <>
                  Any new users you add will be charged at ${includeGst ? amountInclGst : amount1}/
                  {stripeUpgradePlan1 ? stripeUpgradePlan2.interval.charAt(0) : 'm'}
                  {includeGst && ' inc GST'}.
                </>
              ) : (
                <>
                  More on billing{' '}
                  <StyledLink href="https://help.heelix.com/en/articles/4253669-billing-faqs">
                    here
                  </StyledLink>
                  .
                </>
              )}
            </p>
          </div>
          <div className="col-lg-7" id="stripe-form-wrapper">
            <h3 id="stripe-form-title">
              Credit card details
              <span>
                <img
                  className="lock-icon"
                  src={`${AWS_S3_URL}public/images/icons/lock.svg`}
                  alt=""
                />
                &nbsp;Secure form
              </span>
            </h3>
            <StripeForm
              path="/payments/create"
              countries={countries}
              selectCountry={this.includeGst}
              ctaText="Complete payment"
              cancelPath="/"
              plan={this.getPlan()}
              planId={stripeUpgradePlan.id}
            />
          </div>
        </div>
        <div className="row" id="faq-section-wrapper">
          <div className="col-xs-12">
            <h3>FAQ</h3>
          </div>
          <div className="col-md-6">
            <h4>How do you count active users?</h4>
            <p>
              You only pay for a user once they have accepted their invite to Heelix. You can
              deactivate users at any time and your bill will be adjusted accordingly. We also offer
              the ability to pay by user bracket to give you more flexibility.
            </p>
          </div>
          <div className="col-md-6">
            <h4>Can I add or remove users at any time?</h4>
            <p>
              If paying monthly you can easily add or remove users at any time. If you’re paying
              annually you will need to upgrade your user bracket and pay the difference, which is
              adjusted for the remaining months of your subscription.
            </p>
          </div>
          <div className="col-md-6">
            <h4>Do I have to sign a contract?</h4>
            <p>No. You upgrade or cancel your subscription as you need.</p>
          </div>
          <div className="col-md-6">
            <h4>What if I decide to cancel my subscription?</h4>
            <p>Just go to the billing settings under your account and click the cancel link.</p>
          </div>
          <div className="col-md-6">
            <h4>Can I get a discount if I pay annually?</h4>
            <p>
              Absolutely. Check out our <Link to="/payments/choose_plan">pricing page</Link> to
              learn more.
            </p>
          </div>
          <div className="col-md-6">
            <h4>Have more questions?</h4>
            <p>
              We&apos;re here to help. You can get in touch with us anytime at&nbsp;
              <a
                className="more-question"
                href="mailto:support@heelix.com?subject=Question about Heelix"
              >
                support@heelix.com
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PaymentsNew);
