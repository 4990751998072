import React from 'react';
import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';
import { ReactComponent as FeedIcon } from './feed.svg';

const Wrapper = styled.div`
  padding: 8px 0;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
`;
const FeedIconStyled = styled(FeedIcon)`
  width: 22px;
  height: 22px;
  margin-right: 12px;
  vertical-align: bottom;
`;

const GrowHeader = ({ title }) => {
  return (
    <Wrapper>
      <FeedIconStyled />
      Grow: {title}
    </Wrapper>
  );
};

export default withRouter(GrowHeader);
