import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { APP_URL, SHOUTOUT_LIMIT } from '../../constants';
import { AUTOCOMPLETE_NATIVE_VALUE_RESET, CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';
import VibeDoughnut from '../vibes/VibeDoughnut';
import { POST_ACTIONS } from '../../redux/actions/postActions';
import { TAG_ACTIONS } from '../../redux/actions/tagActions';

const mapDispatchToProps = dispatch => ({
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  fetchPosts: () => dispatch(POST_ACTIONS.fetchPosts()),
  addShoutout: () => dispatch(POST_ACTIONS.addShoutout()),
  fetchCompanyValues: () => dispatch(TAG_ACTIONS.fetchCompanyValues()),
});

const mapStateToProps = state => ({
  companyValues: state.tagsDomain.companyValues,
  user: state.usersDomain.user,
  usersForShoutoutModal: state.usersDomain.usersForShoutoutModal,
});

class ShoutoutModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recipientId: null,
      content: '',
      team: '',
      mediaUrl: [],
      companyValues: [],
      companyValue: '',
      loadedFix: null,
      errorState: false,
      posting: false,
      charsLeft: 1000,
      teams: [],
    };
  }

  componentWillReceiveProps() {
    if (this.props.shoutoutFor && this.props.shoutoutFor.id !== this.state.recipientId) {
      this.props.shoutoutFor.value = this.props.shoutoutFor.id;
      this.changeRecipient(this.props.shoutoutFor);
    }
  }

  removeCompanyValueHandler(companyValue) {
    const companyValueIndex = this.state.companyValues.indexOf(companyValue);
    const chosenHelperArray = this.state.companyValues;
    chosenHelperArray.splice(companyValueIndex, 1);
    this.setState({
      companyValues: chosenHelperArray || [],
    });
  }

  contentChangedHandler = event => {
    this.setState({
      content: event.target.value,
      charsLeft: 1000 - event.target.value.length,
    });
  };

  submitForm() {
    if (!(this.state.content && this.state.recipientId && this.state.team))
      this.setState({ errorState: 'Form contains empty fields that are required' });
    else if (this.state.content.length > 1000)
      this.setState({ errorState: 'Your post exceeds the maximum length of 1000 characters' });
    else {
      this.setState({ posting: true });
      const data = new FormData();
      this.setState({
        errorState: false,
      });
      data.append('shoutout[type]', 'Shoutout');
      data.append('shoutout[audience_id]', this.state.team);
      data.append('shoutout[content]', this.state.content);
      data.append('shoutout[recipient_id]', this.state.recipientId);
      for (let i = 0; i < this.state.companyValues.length; i++)
        data.append('shoutout_values[]', this.state.companyValues[i]);

      const headers = HEADERS();
      fetch(`${APP_URL}/shoutout`, { method: 'post', headers, body: data })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(() => {
            this.setState({
              content: '',
              team: '',
              recipientId: null,
              companyValues: [],
              companyValue: '',
            });
            this.setState({
              posting: false,
            });
            this.props.shoutoutAddedFromModal();
            this.props.addShoutout();
            this.props.resetPosts();
            this.props.fetchPosts();
            this.props.fetchCompanyValues();
          });
        })
        .catch(function () {
          this.setState({
            posting: false,
          });
        });
    }
  }

  getSelectRecipientsOptions() {
    let recipients = this.props.usersForShoutoutModal;
    if (recipients.length > 0) {
      recipients = recipients.map(recipient => ({
        value: recipient.id,
        label: `${recipient.name} (${recipient.slug})`,
      }));
    }
    return recipients;
  }

  changeRecipient = recipient => {
    if (recipient) {
      this.setState({ recipientId: recipient.value });
      const headers = HEADERS();
      fetch(`${APP_URL}/shoutout/get_teams/${recipient.value}`, { method: 'get', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            this.setState({
              teams: json.sharedTeams,
            });
          });
        })
        .catch(() => {});
    }
  };

  getSelectTeamsOptions() {
    let { teams } = this.state;
    if (teams.length > 0) {
      teams = teams.map(team => ({ value: team.id, label: team.name }));
    }
    return teams;
  }

  changeTeam = team => {
    this.setState({ team: team.value });
  };

  render() {
    return (
      <Modal
        show={this.props.showShoutoutModal}
        onHide={this.props.closeShoutoutModalHandler}
        dialogClassName="shoutout-modal"
        backdrop
        keyboard
      >
        <form className="modal-inner-wrap">
          <div className="close-cross" onClick={this.props.closeShoutoutModalHandler} />
          <h1>Give a shoutout</h1>
          <div className="select-recipient-box">
            <h2>
              Who is the shoutout for?
              <span>*</span>
            </h2>
            <Select
              options={this.getSelectRecipientsOptions()}
              inputProps={{ id: 'shoutout-for-input' }}
              value={this.state.recipientId}
              isSearchable
              onChange={this.changeRecipient}
              clearable={false}
              className="select-component recipients orange"
              placeholder="Type here ..."
            />
          </div>
          <VibeDoughnut
            score={(this.props.user.remainingShoutouts / SHOUTOUT_LIMIT) * 100}
            text={this.props.user.remainingShoutouts}
            primary_color="#FE873C"
            width={52}
            height={52}
            remaining
          />
          <span className="clearfix" />
          <h2>
            What was the impact (in a few sentences)?
            <span>*</span>
          </h2>
          <textarea
            id="impact-input"
            onChange={this.contentChangedHandler}
            value={this.state.content}
            placeholder="Type here ..."
          />
          {this.state.charsLeft < 0 && (
            <p id="impact-input-error" className="text-danger">
              Your post exceeds the maximum length of 1000 characters
            </p>
          )}
          <h2>
            Post to
            <span>*</span>
          </h2>
          {this.state.teams.length == 0 && !this.state.recipientId && (
            <ReactTooltip
              id="select-team-tooltip"
              class="react-tooltip"
              place="top"
              type="dark"
              effect="solid"
            >
              <span>You need to first select a user</span>
            </ReactTooltip>
          )}
          <div data-tip data-for="select-team-tooltip">
            <Select
              options={this.getSelectTeamsOptions()}
              inputProps={{ id: 'post-to-input' }}
              value={this.state.team}
              disabled={this.state.teams.length == 0 && !this.state.recipientId}
              searchable={false}
              onChange={this.changeTeam}
              clearable={false}
              className="select-component teams orange"
              placeholder="Select team"
            />
          </div>
          <h2>Tag a related company value (optional)</h2>
          <TextInput
            trigger=""
            id="company-value-input"
            Component="input"
            placeholder="Type here ..."
            options={this.props.companyValues.filter(
              value => this.state.companyValues.indexOf(value) < 0
            )}
            onChange={e => this.setState({ companyValue: e })}
            value={this.state.companyValue}
            onBlur={e => {
              const element = e.target;
              setTimeout(() => {
                const val = element.value.trim();
                AUTOCOMPLETE_NATIVE_VALUE_RESET(element);
                if (val !== '' && this.state.companyValues.indexOf(val) === -1) {
                  this.setState({
                    companyValues: [...this.state.companyValues, val],
                    companyValue: '',
                  });
                }
              }, 200);
            }}
            onKeyDown={e => {
              const val = e.target.value.trim();
              if (e.key === 'Enter' && val !== '' && this.state.companyValues.indexOf(val) === -1) {
                e.preventDefault();
                this.setState({
                  companyValues: [...this.state.companyValues, val],
                  companyValue: '',
                });
                AUTOCOMPLETE_NATIVE_VALUE_RESET('company-value-input');
              }
            }}
          />
          {this.state.companyValues.map((companyValue, index) => (
            <span id={`company-value-${index}`} className="value box" key={index}>
              {companyValue}
              <div onClick={() => this.removeCompanyValueHandler(companyValue)} className="cross" />
            </span>
          ))}
          <input
            id="create-shoutout-btn"
            type="button"
            onClick={() => this.submitForm()}
            value={this.state.posting ? 'Processing...' : 'Give shoutout'}
            disabled={this.props.user.remainingShoutouts <= 0 || this.state.charsLeft < 0}
          />
          <span id="error-message" className={`error-message ${this.state.errorState && 'shown'}`}>
            {this.state.errorState}
          </span>
        </form>
      </Modal>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShoutoutModal));
