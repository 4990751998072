import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styled from '@emotion/styled';
import { CloseButton } from 'components/shared/Modal/index';
import { Button } from 'components/shared/Layout/Button';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { getSlackUrl } from 'components/layouts/Navigation/SlackButton';

const Container = styled.div`
  padding: 36px 50px 0;
  font-size: 14px;
  color: #323232;

  ul {
    list-style: none;
    padding-left: 31px;
    padding-bottom: 10px;

    li {
      margin-bottom: 12px;

      ::before {
        content: '';
        background-color: #11a9ff;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        margin-right: 12px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
`;

const Header = styled.div`
  font-size: 22px;
  color: #323232;
  font-weight: bold;
  text-align: center;
`;

const HR = styled.hr`
  border-top: 1px solid #f3f3f3;
`;
const Footer = styled.div`
  font-size: 13px;
  color: #979797;
  text-align: center;
`;

const Title = styled.div`
  text-align: center;
  margin: 21px 0 52px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding: 25px 0 35px;
  button {
    margin: 0 10px;
  }
`;

const SlackModal = ({ location }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const connectSlack = qs.parse(location.search, { ignoreQueryPrefix: true }).connect_slack;
    if (!connectSlack) return;
    setShow(true);
  }, []);

  const onHide = () => {
    window.location.replace('/');
    setShow(false);
  };

  const grantAccess = () => {
    window.location.href = getSlackUrl();
  };

  return (
    <Modal show={show} onHide={onHide} dialogClassName="win-modal" backdrop keyboard>
      <Container>
        <CloseButton onClick={onHide} />
        <Header>Confirm access to your Slack account</Header>
        <Title>Heelix is requesting access to the following:</Title>
        <ul>
          <li>Read your account information (name, email)</li>
          <li>Read all team / account data</li>
          <li>Posting on your account</li>
          <li>Recording feedback on your account</li>
        </ul>
        <HR />
        <Footer>
          By giving access to the App you agree to the{' '}
          <a href="https://heelix.com/privacy" target="blank">
            privacy policy
          </a>
          .
        </Footer>
        <Buttons>
          <Button type="primary" onClick={grantAccess}>
            Grant access
          </Button>
          <Button type="secondary" onClick={onHide}>
            Cancel
          </Button>
        </Buttons>
      </Container>
    </Modal>
  );
};

export default withRouter(SlackModal);
