import DOMAINS from '../domains';
import { APP_TOGGLE_ADD_USER_MODAL } from 'redux/app/actions';

export const ONBOARDING_ACTION_TYPES = {
  START_FEED_TOUR: 'START_FEED_TOUR',
  START_GROW_TOUR: 'START_GROW_TOUR',
  START_INSIGHTS_TOUR: 'START_INSIGHTS_TOUR',
  START_SETUP_TOUR: 'START_SETUP_TOUR',
  RESET_TOURS: 'RESET_TOURS',
  RECEIVE_ONBOARDING_SPACE: 'RECEIVE_ONBOARDING_SPACE',
  RECEIVE_ONBOARDING_USER: 'RECEIVE_ONBOARDING_USER',
  SET_FEED_TOUR_STEP: 'SET_FEED_TOUR_STEP',
  SET_INSIGHTS_TOUR_STEP: 'SET_INSIGHTS_TOUR_STEP',
  SET_GROW_TOUR_STEP: 'SET_GROW_TOUR_STEP',
  SET_SETUP_TOUR_STEP: 'SET_SETUP_TOUR_STEP',
  TOGGLE_TOUR_MODAL: 'TOGGLE_TOUR_MODAL',
  SET_FINISHED_TOUR: 'SET_FINISHED_TOUR',
  FINISH_STEP: 'FINISH_STEP',
  RECEIVE_FINISHED_TOURS: 'RECEIVE_FINISHED_TOURS',
  POSTPONE_TOUR: 'POSTPONE_TOUR',
  RECEIVE_POSTPONE_TOUR: 'RECEIVE_POSTPONE_TOUR',
  REMOVE_SAMPLE_TEAM: 'REMOVE_SAMPLE_TEAM',
  SET_REMOVE_SAMPLE_TEAM: 'SET_REMOVE_SAMPLE_TEAM',
  SHOW_MEETING_MODAL: 'SHOW_MEETING_MODAL',
  CLOSE_MEETING_MODAL: 'CLOSE_MEETING_MODAL',
  HANDLE_CLOSE_MEETING_MODAL: 'HANDLE_CLOSE_MEETING_MODAL',
  RESET_SETTINGS: 'RESET_SETTINGS',
  SET_WAS_USER_ADDED: 'SET_WAS_USER_ADDED',
  SET_WAS_TEAM_ADDED: 'SET_WAS_TEAM_ADDED',
  SET_WAS_USER_MOVED: 'SET_WAS_USER_MOVED',
  SET_WAS_COMPANY_STRUCTURE_FETCHED: 'SET_WAS_COMPANY_STRUCTURE_FETCHED',
  SET_WAS_ADD_TEAM_BUTTON_CLICKED: 'SET_WAS_ADD_TEAM_BUTTON_CLICKED',
  TOGGLE_ADD_CHILD_TEAM: 'TOGGLE_ADD_CHILD_TEAM',
  SET_MEETING_SETTINGS: 'SET_MEETING_SETTINGS',
};
export const ONBOARDING_ACTIONS = {
  startFeedTour: (step = 0) => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.START_FEED_TOUR,
    payload: step,
  }),
  startGrowTour: (step = 0) => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.START_GROW_TOUR,
    payload: step,
  }),
  startInsightsTour: (step = 0) => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.START_INSIGHTS_TOUR,
    payload: step,
  }),
  startSetupTour: (step = 0) => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.START_SETUP_TOUR,
    payload: step,
  }),
  resetTours: () => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.RESET_TOURS,
  }),
  receiveOnboardingUser: json => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.RECEIVE_ONBOARDING_USER,
    payload: json,
  }),
  receiveOnboardingSpace: json => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.RECEIVE_ONBOARDING_SPACE,
    payload: json,
  }),
  setFeedTourStep: step => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.SET_FEED_TOUR_STEP,
    payload: step,
  }),
  setInsightsTourStep: step => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.SET_INSIGHTS_TOUR_STEP,
    payload: step,
  }),
  setGrowTourStep: step => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.SET_GROW_TOUR_STEP,
    payload: step,
  }),
  setSetupTourStep: step => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.SET_SETUP_TOUR_STEP,
    payload: step,
  }),
  toggleTourModal: (toggle = true) => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.TOGGLE_TOUR_MODAL,
    payload: toggle,
  }),
  setFinishedTour: tour => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.SET_FINISHED_TOUR,
    payload: tour,
  }),
  finishStep: type => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.FINISH_STEP,
    payload: type,
  }),
  receiveFinishedTours: json => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.RECEIVE_FINISHED_TOURS,
    payload: json,
  }),
  postponeTour: tour => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.POSTPONE_TOUR,
    payload: tour,
  }),
  receivePostponeTour: () => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.RECEIVE_POSTPONE_TOUR,
  }),
  removeSampleTeam: tour => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.REMOVE_SAMPLE_TEAM,
    payload: tour,
  }),
  setRemoveSampleTeam: teamId => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.SET_REMOVE_SAMPLE_TEAM,
    payload: teamId,
  }),
  showMeetingModal: (stopShowingButton = true, settings) => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.SHOW_MEETING_MODAL,
    payload: {
      stopShowingButton,
      settings,
    },
  }),
  closeMeetingModal: (permanently = false) => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.CLOSE_MEETING_MODAL,
    payload: permanently,
  }),
  handleCloseMeetingModal: () => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.HANDLE_CLOSE_MEETING_MODAL,
  }),
  resetSettings: () => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.RESET_SETTINGS,
  }),
  setWasUserAdded: wasUserAdded => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.SET_WAS_USER_ADDED,
    payload: wasUserAdded,
  }),
  setWasAddTeamButtonClicked: wasAddTeamButtonClicked => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.SET_WAS_ADD_TEAM_BUTTON_CLICKED,
    payload: wasAddTeamButtonClicked,
  }),
  setWasTeamAdded: wasTeamAdded => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.SET_WAS_TEAM_ADDED,
    payload: wasTeamAdded,
  }),
  setWasUserMoved: wasUserMoved => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.SET_WAS_USER_MOVED,
    payload: wasUserMoved,
  }),
  setWasCompanyStructureFetched: wasCompanyStructureFetched => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.SET_WAS_COMPANY_STRUCTURE_FETCHED,
    payload: wasCompanyStructureFetched,
  }),
  toggleAddChildTeam: addChildTeamState => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.TOGGLE_ADD_CHILD_TEAM,
    payload: addChildTeamState,
  }),
  setMeetingSettings: meetingSettings => ({
    domain: DOMAINS.ONBOARDING,
    type: ONBOARDING_ACTION_TYPES.SET_MEETING_SETTINGS,
    payload: meetingSettings,
  }),
};
