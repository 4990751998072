import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleNoteModal } from 'redux/app/actions';
import { GROW_ACTIONS } from 'redux/actions/growActions';

const Delete = ({ deletePost, userId, object, type, toggleNoteModal }) => {
  const handleClick = () => {
    deletePost(object.id, type);
    toggleNoteModal(false);
  };

  if (
    (object.owner && object.owner.id !== userId) ||
    (object.author && object.author.id !== userId)
  )
    return null;

  return <div onClick={handleClick}>Delete</div>;
};

Delete.propTypes = {
  object: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

const mapDispatch = dispatch => ({
  deletePost: (id, type) => dispatch(GROW_ACTIONS.deletePost(id, type)),
  toggleNoteModal: toggled => dispatch(toggleNoteModal(toggled)),
});

const mapState = state => ({
  userId: state.usersDomain.user.id,
});

export default connect(mapState, mapDispatch)(Delete);
