import fetchApi from 'fetchApi';

export const fetchNextUpData = async () => {
  const response = await fetchApi(`/grow/up_next`);
  return response;
};
export const getMonthName = date => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return monthNames[date.getMonth()];
};
