import styled from '@emotion/styled';

export const Wrap = styled.div`
  margin-top: 25px;
  .Select-control {
    height: 42px;
    border: 1px solid #ededed;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 14px;
  }
  .Select-placeholder,
  .Select-value {
    padding-top: 4px;
    margin-left: 5px;
  }
  .Select-value-label {
    padding-left: 5px;
  }
  .Select-input {
    padding-left: 0;
    padding-top: 4px;
  }
`;
export const DataTimePickerWrapper = styled.div`
  width: 100%;
  margin-bottom: ${props => (props.fromNote ? '3px' : '10px')};
  margin-top: 3px;
  display: table;
  div.react-datepicker-wrapper {
    width: 50%;
  }
`;
export const DataWrapper = styled.div`
  width: 80%;
  display: table-cell;
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  padding-right: 25px;
  cursor: pointer;
  @media (max-width: 400px) {
    display: table-row;
  }
  @media (max-width: 620px) {
    width: 70%;
  }
`;
export const TimeWrapper = styled.div`
  width: 20%;
  display: table-cell;
  cursor: pointer;
  vertical-align: top;
  @media (max-width: 400px) {
    display: table-row;
    .Select {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 620px) {
    width: 30%;
  }
`;
export const Textarea = styled.textarea`
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  padding: 9px 14px;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: ${props => (props.fromNote ? '0' : '20px')};
  height: ${props => (props.fromNote ? '105px' : '130px')};
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  margin-bottom: 12px;
`;
