import React, { Component } from 'react';

class BlankPost extends Component {
  state = {
    authorName: '',
    authorRole: '',
    content: '',
  };

  componentDidMount() {
    this.setState({
      authorName: [...Array(Math.floor(Math.random() * 10) + 12).fill(0)].reduce(
        (a, b) => a + b,
        ''
      ), // 12-22 chars
      authorRole: [...Array(Math.floor(Math.random() * 8) + 8).fill(0)].reduce((a, b) => a + b, ''), // 8-16 chars
      content: [...Array(Math.floor(Math.random() * 180) + 50).fill(0)].reduce((a, b) => a + b, ''), // 50-230 chars
    });
  }

  render() {
    return (
      <div className="col-lg-6 col-xs-12 post-tile-wrap initial-hide">
        <div className={`post-tile blank-post ${this.props.animated ? 'animated' : ''}`}>
          <div className="post-tile-header">
            <div className="author-avatar">
              <div className="blank-post-avatar" />
            </div>
            <div className="author-info">
              <div className="author-name">{this.state.authorName}</div>
              <span className="author-role-position">{this.state.authorRole}</span>
            </div>
          </div>
          <div className="post-tile-body">
            <span>{this.state.content}</span>
          </div>
          <div className="post-tile-comments">
            <div className="post-tile-comment-input">
              <div className="blank-post-likes-placeholder" />
              <div className="blank-post-comment-placeholder" />
            </div>
          </div>
          <div className="post-tile-comments-section" />
        </div>
      </div>
    );
  }
}

export default BlankPost;
