import React, { Component } from 'react';
import delayUnmounting from '../../HOC/delayUnmounting';

class Issue extends Component {
  state = {
    message: '',
    mounted: false,
  };

  componentDidMount() {
    this.height = window.getComputedStyle(this.self).getPropertyValue('height');
    this.self.style.height = 0;
    setTimeout(() => {
      this.self.style.height = this.height;
      this.setState({
        message: this.props.message,
        mounted: true,
      });
    }, 0);
  }

  componentWillReceiveProps(props) {
    if (!props.isMounted) this.self.style.height = 0;
    else this.self.style.height = this.height;
    if (this.state.mounted !== props.isMounted) this.setState({ mounted: !this.state.mounted });
  }

  render() {
    return (
      <div className={`issue ${this.state.mounted ? 'mounted' : ''}`} ref={el => (this.self = el)}>
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.63386 0.530089C8.01868 -0.136981 8.98144 -0.136984 9.36626 0.530087L16.8648 13.5285C17.2494 14.1952 16.7683 15.0282 15.9986 15.0282H1.00149C0.231846 15.0282 -0.249299 14.1952 0.13529 13.5285L7.63386 0.530089ZM9.04321 5.64914C9.04321 5.34444 8.80003 5.09743 8.50004 5.09743C8.20005 5.09743 7.95687 5.34444 7.95687 5.64914V9.51112C7.95687 9.81582 8.20005 10.0628 8.50004 10.0628C8.80003 10.0628 9.04321 9.81582 9.04321 9.51112V5.64914ZM8.50004 11.1662C8.80003 11.1662 9.04321 11.4132 9.04321 11.7179C9.04321 12.0226 8.80003 12.2696 8.50004 12.2696C8.20005 12.2696 7.95687 12.0226 7.95687 11.7179C7.95687 11.4132 8.20005 11.1662 8.50004 11.1662Z"
            fill="#FFBC57"
          />
        </svg>
        {this.state.message}
      </div>
    );
  }
}

export default delayUnmounting(Issue);
