import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import WelcomeContainer from '../WelcomeContainer';
import LoadingOverlay from '../LoadingOverlay';
import registerWithGoogle from '../registerWithGoogle';
import steps from '../steps';

const SetTeamName = ({ handleTeam, loading, googleData, history, nameError, integrationError }) => {
  const handleSubmit = async e => {
    e.preventDefault();

    if (!googleData) {
      return handleTeam({
        team_name: e.target.team_name.value,
        terms_and_privacy_accepted: e.target.terms_and_privacy_accepted.checked,
      });
    }

    const onSuccess = () => {
      history.push('/');
    };

    const onFailure = () => {
      history.push(`/confirm_email?step=${steps.registerError}`);
    };

    registerWithGoogle(
      googleData,
      e.target.team_name.value,
      e.target.terms_and_privacy_accepted.checked,
      onSuccess,
      onFailure
    );
  };

  return (
    <WelcomeContainer
      className="text-center"
      title="Finally, set up a team name"
      body={
        <>
          <LoadingOverlay toggled={loading} message="Processing..." height="77%" />
          <div className="password-set">
            <div>You can always change this later</div>
            <form
              onSubmit={handleSubmit}
              action=""
              className="simple_form form-compact password-form"
              id="new_user"
            >
              <div className="form-group">
                <input
                  required
                  name="team_name"
                  type="text"
                  className="string email required form-control"
                  placeholder="Type here"
                />
                <div className="name-error">{nameError || integrationError}</div>
              </div>
              <div className="checkbox privacy-terms-checkbox">
                <input
                  required
                  id="terms_and_privacy_accepted"
                  name="terms_and_privacy_accepted"
                  type="checkbox"
                />
                <label htmlFor="terms_and_privacy_accepted">
                  By signing up, you agree to the collection, use and storage of personal
                  information in accordance with the terms of our&nbsp;
                  <a className="" href="https://heelix.com/terms.html" target="blank">
                    Terms
                  </a>
                  &nbsp;and&nbsp;
                  <a className="" href="https://heelix.com/privacy.html" target="blank">
                    Privacy Policy
                  </a>
                  .
                </label>
              </div>
              <button type="submit" className="btn btn-primary btn-full" id="sign-in-btn">
                Let's go
              </button>
            </form>
          </div>
          <div className="business-description">
            If you are a business and you enter personal information about your employees or any
            third party you acknowledge you have consent from those persons and can direct those
            persons to the terms of our&nbsp;
            <a className="" href="https://heelix.com/privacy.html" target="blank">
              Privacy Policy
            </a>
            &nbsp;and&nbsp;
            <a className="" href="https://heelix.com/terms.html" target="blank">
              Terms
            </a>
            .
          </div>
        </>
      }
    />
  );
};

SetTeamName.propTypes = {
  handleTeam: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  googleData: PropTypes.object,
  nameError: PropTypes.string,
  integrationError: PropTypes.string,
};

export default withRouter(SetTeamName);
