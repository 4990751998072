/** @jsx jsx */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import striptags from 'striptags';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import loadImage from 'blueimp-load-image';
import PostMedia from 'pages/Grow/GrowPost/PostMedia';
import { toggleUserModal } from 'redux/app/actions';
import { css, jsx } from '@emotion/core';
import PostDetails from 'components/shared/posts/PostDetails';
import AuthorInfo from 'components/shared/posts/AuthorInfo';
import DropzoneWrap from 'components/shared/posts/DropzoneWrap';
import PinnedPost from 'components/post/shared/PinnedPost';
import Auxiliary from '../../layouts/Auxiliary';
import { POST_ACTIONS } from '../../../redux/actions/postActions';
import PollOptionsExpired from '../../feed/PollTile/PollOptionsExpired';
import PollOptions from '../../feed/PollTile/PollOptions';
import {
  AVATAR_ERROR,
  FORMAT_CONTENT_WITH_MARKUPS,
  FORMAT_FILTERS,
  GET_MENTIONS_FROM_CONTENT,
  ROTATE_IE_IMAGE,
  VALIDATE_FILE,
} from '../../../helpers';
import PostComments from './PostComments';
import PostFile from '../../feed/PostFile';
import favActiveBtn from '../../../images/feed/star_active.png';
import favBtn from '../../../images/feed/star.png';
import FavPostTooltip from '../../grow/tooltips/FavPostTooltip';
import MentionsInputWrapper from '../../shared/MentionsInputWrapper';
import CommentInput from '../shared/CommentInput';
import EditMediaWrap from '../shared/EditMediaWrap';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';

const mapStateToProps = state => ({
  user: state.usersDomain.user,

  discussionTags: state.tagsDomain.discussionTags,
  hashtags: state.tagsDomain.hashtags,

  post: state.postsDomain.singlePost,
  filters: state.postsDomain.filters,
  submittingComment: state.postsDomain.submittingComment,
  reachedDataLimit: state.plansDomain.reachedDataLimit,
});

const mapDispatchToProps = dispatch => ({
  updatePost: (postId, data) => dispatch(POST_ACTIONS.updatePost(postId, data)),
  favPost: postId => dispatch(POST_ACTIONS.favPost(postId)),
  votePoll: (id, data) => dispatch(POST_ACTIONS.votePoll(id, data)),
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
});

class Poll extends Component {
  constructor(props) {
    super(props);
    this.allFilesSize = 0;
  }

  state = {
    errorAction: null,
    lightboxIsOpen: false,
    currentImage: 0,

    // ---For editing purposes
    commentEditing: false,
    commentEditingId: null,
    options: [],
    content: '',
    team: '',
    media: [],
    mediaUrl: [],
    commentMediaUrl: [],
    selectedTeam: {},
    message: null,
    errors: null,
    processing: false,
    commentCharsLeft: 250,
    errorContent: '',
    vote: false,
  };

  componentDidMount() {
    if (this.props.editing) {
      this.setState({
        options: JSON.parse(JSON.stringify(this.props.post.poll_options)),
        content: FORMAT_CONTENT_WITH_MARKUPS(this.props.post.content),
        media: this.props.post.media,
        mediaUrl: [],
      });
    }

    const renderedHashtags = document.getElementsByClassName('inline-hashtag');
    Array.from(renderedHashtags).forEach(hashtag => {
      hashtag.addEventListener('click', this.hashtagClickedHandler, false);
    });

    const renderedMentions = document.getElementsByClassName('inline-mention');
    Array.from(renderedMentions).forEach(mention => {
      mention.addEventListener('click', this.mentionClickedHandler, false);
    });
  }

  // ---Hashtags listener and filter apply
  hashtagClickedHandler = e => {
    this.props.history.push(
      `/feed?${qs.stringify(
        FORMAT_FILTERS(e.currentTarget, {
          space: this.props.filters.space,
          types: [],
          hashtags: [],
          string: '',
        })
      )}`
    );
  };
  //--------------------

  mentionClickedHandler = e => {
    e.preventDefault();
    const mentionedId = parseInt(e.currentTarget.getAttribute('user-id'));
    if (mentionedId === this.props.user.id) {
      this.props.history.push(`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`);
    } else {
      return this.props.toggleUserModal(true, mentionedId);
    }
  };

  componentWillReceiveProps(props) {
    if (props.post.error !== this.props.post.error && props.post.error) {
      alert(props.post.error);
      return;
    }
    if (props.editing && !props.post.isFetching) {
      this.setState({
        options: JSON.parse(JSON.stringify(this.props.post.poll_options)),
        content: FORMAT_CONTENT_WITH_MARKUPS(this.props.post.content),
        media: this.props.post.media,
      });
    }
    if (
      this.props.post &&
      this.props.post.isFetching === true &&
      this.props.post.isFetching !== props.post.isFetching
    ) {
      this.setState({
        mediaUrl: [],
      });
      this.props.changeEditingState();
    }
  }

  fileUploadHandler = files => {
    let mediaUrl = [];

    for (let i = 0; i < files.length; i++) {
      if (!VALIDATE_FILE(files[i], this.state.media, this.allFilesSize)) {
        break;
      }

      const file = files[i];
      const index = i;
      const type = file.type.split('/')[0];

      this.allFilesSize += file.size;

      if (
        (type === 'image' && !(this.state.mediaUrl[0] && this.state.mediaUrl[0].type === 'v')) ||
        (type === 'video' && this.state.mediaUrl.length === 0)
      ) {
        if (type === 'video' && files.length > 1) {
          // Breaks loop if there are images and videos simultaneously
          this.setState({
            mediaUrl: [],
            disabledButton: false,
          });
          alert('You may attach either one video or few images.');
          break;
        }

        const reader = new FileReader();
        reader.onload = ((index, type) => e => {
          if (type === 'image') {
            const loadImageOptions = {
              orientation: true,
              noRevoke: true,
            };
            const img = ROTATE_IE_IMAGE(e.target.result);
            loadImage(
              img,
              canvas => {
                mediaUrl[index] = {
                  url: img,
                  type: type === 'image' ? 'i' : 'v',
                  size: file.size,
                };
                if (mediaUrl.filter(Boolean).length === files.length) {
                  this.setState({
                    mediaUrl: [...this.state.mediaUrl, ...mediaUrl],
                    disabledButton: false,
                  });
                }
              },
              loadImageOptions
            );
          } else {
            mediaUrl[index] = {
              url: reader.result,
              type: type === 'image' ? 'i' : 'v',
              size: file.size,
            };
            if (mediaUrl.filter(Boolean).length === files.length) {
              this.setState({
                mediaUrl: [...this.state.mediaUrl, ...mediaUrl],
                disabledButton: false,
              });
            }
          }
        })(index, type);
        reader.readAsDataURL(file);
      } else {
        mediaUrl = [];
        alert('You may attach either one video or a few images.');
        return;
      }
    }
  };

  removeMediaHandler = index => {
    const updatedMedia = [...this.state.media];

    const mediaIndex = updatedMedia.findIndex(m => m.id === index);

    updatedMedia.splice(mediaIndex, 1);
    this.setState({
      media: updatedMedia,
    });
  };

  removeNewMediaHandler = index => {
    this.allFilesSize -= this.state.mediaUrl[index].size;
    const updatedMediaUrl = [...this.state.mediaUrl];
    updatedMediaUrl.splice(index, 1);
    this.setState({
      mediaUrl: updatedMediaUrl,
    });
  };

  updatePostHandler = () => {
    if (this.props.post.isFetching) return;
    if (this.state.errorContent) {
      alert('Please reduce the number of characters');
      return;
    }

    if (this.state.options.some(option => option.content.length > 250)) {
      alert('Maximum length of poll option is 250 characters');
      return;
    }

    if (this.state.options.some(option => option.content.length <= 0)) {
      alert('Please include content of the poll option');
      return;
    }

    if (this.state.content.length > 0) {
      const data = new FormData();
      data.append('_method', 'PUT');
      data.append('content', this.state.content);
      data.append('type', 'Poll');
      for (let i = 0; i < this.state.options.length; i++) {
        data.append(`options[${this.state.options[i].id}]`, this.state.options[i].content);
      }
      /*
            for (let i = 0; i < this.state.subjects.length; i++)
                data.append('post_subjects[]', this.state.subjects[i]);
            */
      for (let i = 0; i < this.state.mediaUrl.length; i++)
        data.append('post_media[]', JSON.stringify(this.state.mediaUrl[i]));
      for (let i = 0; i < this.state.media.length; i++)
        data.append('post_old_media[]', this.state.media[i].id);
      this.props.updatePost(this.props.post.id, data);
    }
  };

  contentChangedHandler = (event, unformattedContent) => {
    const input = event.target.value;
    this.setState({
      content: input,
      errorContent:
        unformattedContent.length > 1000
          ? 'Your post exceeds the maximum length of 1000 characters'
          : '',
      charsLeft: 1000 - unformattedContent.length,
    });
  };

  updatePoll = (e, id) => {
    e.preventDefault();
    const data = new FormData();
    data.append('poll[selectedOption]', id);
    this.props.votePoll(this.props.post.id, data);
  };

  changeFieldContent = (e, i) => {
    // let options = this.props.post.poll_options.slice(); //---Options require non-shallow copy
    const options = JSON.parse(JSON.stringify(this.state.options));
    options[i].content = e.target.value;
    this.setState({ options });
  };

  render() {
    const rolePosition = [];
    if (this.props.post.user.role !== '') {
      rolePosition.push(this.props.post.user.role);
    }
    rolePosition.push(this.props.post.user.team.name);
    if (!this.props.post) {
      return null;
    }

    // todo: potentially block of dead code
    let nameError = null;
    let descriptionError = null;
    if (this.state.errors) {
      if (this.state.errors['post.name']) {
        nameError = (
          <span id="helpBlock" className="help-block text-danger">
            {this.state.errors['post.name'][0]}
          </span>
        );
      }
      if (this.state.errors['post.content']) {
        descriptionError = (
          <span id="helpBlock" className="help-block text-danger">
            {this.state.errors['post.content'][0]}
          </span>
        );
      }
    }

    if (this.props.post) {
      return (
        <div className="post-wrapper poll-wrapper">
          <div className="post-header">
            <div className="author-avatar">
              <img
                alt={`${this.props.post.user.first_name} ${this.props.post.user.last_name}`}
                src={this.props.post.user.image_url}
                onError={e => AVATAR_ERROR(e.target)}
              />
            </div>
            <AuthorInfo post={this.props.post} user={this.props.user} />
            <PinnedPost post={this.props.post} />
            <img
              src={this.props.post.favourite ? favActiveBtn : favBtn}
              data-tip
              data-for={`post_fav_status${this.props.post.id}`}
              className={`heart ${this.props.post.favourite ? 'marked' : ''}`}
              onClick={() => this.props.favPost(this.props.post.id)}
              alt=""
            />
          </div>
          <div className="post-body">
            {this.props.editing ? (
              <>
                <DropzoneWrap fileUploadHandler={this.fileUploadHandler} />
                <EditMediaWrap
                  mediaUrl={this.state.mediaUrl}
                  media={this.state.media}
                  removeNewMediaHandler={this.removeNewMediaHandler}
                  removeMediaHandler={this.removeMediaHandler}
                />
              </>
            ) : (
              <div className="post-show-media">
                <PostMedia
                  media={this.props.post.media}
                  embed={{
                    id: this.props.post.integration_video_id,
                    type: this.props.post.integration_video_type,
                  }}
                />
              </div>
            )}
            <div className={`post-content ${this.props.editing ? 'edit-poll' : ''}`}>
              {this.props.editing ? (
                <Auxiliary>
                  <MentionsInputWrapper
                    value={this.state.content}
                    contentChangedHandler={this.contentChangedHandler}
                    placeholder="Type poll title, eg Where should we go for our team lunch today?"
                    mentions={GET_MENTIONS_FROM_CONTENT(this.props.post.content)}
                  />
                  {this.state.errorContent ? (
                    <div className="fix-tile-error">
                      <span className="text-danger">{this.state.errorContent}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="poll-options">
                    {this.state.options.map((option, index) => (
                      <input
                        className="poll-option"
                        type="text"
                        value={option.content}
                        onChange={e => this.changeFieldContent(e, index)}
                        placeholder={`Option ${index + 1}`}
                      />
                    ))}
                  </div>
                  {this.props.editing && this.state.content.length === 0 ? (
                    <div className="help-block text-danger">Post content cannot be empty</div>
                  ) : (
                    ''
                  )}
                  <span
                    id="save-edit-btn"
                    className={`edit-save btn-sm btn btn-fixed btn-primary ${
                      this.props.post.isFetching ? 'disabled' : ''
                    }`}
                    onClick={this.updatePostHandler}
                  >
                    Save
                  </span>
                </Auxiliary>
              ) : (
                <div>
                  <div className="post-tile-title">
                    <h4 className="poll-content">
                      <span>Poll: </span>
                      <span
                        css={css`
                          word-wrap: break-word;
                        `}
                        dangerouslySetInnerHTML={{
                          __html: striptags(this.props.post.content, ['a', 'br']),
                        }}
                      />
                    </h4>
                  </div>
                  {this.props.post.expired ||
                  this.props.post.is_poll_selected ||
                  (this.props.post.is_author && !this.state.vote) ? (
                    <PollOptionsExpired poll={this.props.post} />
                  ) : (
                    <PollOptions poll={this.props.post} updatePoll={this.updatePoll} />
                  )}
                </div>
              )}
              {this.props.post.files.map((file, index) => (
                <PostFile file={file} key={index} />
              ))}
              <div className="poll-info">
                {this.props.post.is_author && !this.props.post.is_poll_selected && (
                  <span className="vote-btn">
                    {!this.state.vote ? (
                      <a onClick={() => this.setState({ vote: true })}>Vote</a>
                    ) : (
                      <a onClick={() => this.setState({ vote: false })}>Cancel</a>
                    )}
                  </span>
                )}
              </div>
              <PostDetails post={this.props.post} showSpaceInfo={this.props.showSpaceInfo} large />
            </div>
            <CommentInput post={this.props.post} />
          </div>
          <div className="post-comments-section">
            <div className="post-comments">
              <PostComments />
            </div>
          </div>
          <FavPostTooltip postId={this.props.post.id} isFavourite={this.props.post.favourite} />
        </div>
      );
    }
    return <div />;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Poll));
