import React from 'react';
import styled from '@emotion/styled';

const AssigneeOptionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 14px;
  cursor: pointer;
  &:hover {
    background-color: #d8d8d8;
  }
`;

const AssigneeOptionImage = styled.img`
  width: 26px;
  height: 26px;
  border: 1px solid #d8d8d8;
  border-radius: 50%;
  margin-right: 11px;
`;

const AssigneeOptionTitle = styled.span`
  font-size: 14px;
  color: #323232;
  line-height: 16px;
`;

const AssigneeOptionTeamDefault = styled.span`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 11px;
  display: inline-block;
  font-weight: bold;
  line-height: 180%;
  text-align: center;
  text-shadow: none;
  position: relative;
`;
const getStyles = colors =>
  colors
    ? {
        backgroundColor: colors.background,
        color: colors.letters,
        borderColor: colors.border,
        borderWidth: '1px',
        borderStyle: 'solid',
      }
    : {};

const AssigneeOption = props => {
  const handleMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
    props.onSelect(props.option, event);
  };

  const handleMouseEnter = event => {
    props.onFocus(props.option, event);
  };

  const handleMouseMove = event => {
    if (props.isFocused) return;
    props.onFocus(props.option, event);
  };

  return (
    <div
      className="Select-value"
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
    >
      <AssigneeOptionWrapper className="Select-value-label">
        {props.option.image ? (
          <AssigneeOptionImage src={props.option.image} alt="" />
        ) : (
          <AssigneeOptionTeamDefault
            className={`space-logo`}
            style={getStyles(props.option.thumbnail_details.colors)}
          >
            {props.option.thumbnail_details.letters}
          </AssigneeOptionTeamDefault>
        )}
        <AssigneeOptionTitle>{props.option.label}</AssigneeOptionTitle>
      </AssigneeOptionWrapper>
    </div>
  );
};

export default AssigneeOption;
