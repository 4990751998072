import {
  APP_CLEAR_ACTION_BODY,
  APP_SET_ACTION,
  APP_SET_RECENT_NOTE,
  APP_RESET_RECENT_NOTE,
  APP_TOGGLE_ACTION_MODAL,
  APP_TOGGLE_NOTE_MODAL,
  APP_TOGGLE_USER_MODAL,
  APP_TOGGLE_ADD_USER_MODAL,
  APP_TOGGLE_ADD_CSV_USER_MODAL,
  APP_MODAL_NOTE,
  APP_RESET_MODAL_NOTE,
  APP_SET_CSV_USERS,
  APP_RESET_CSV_USERS,
} from './actions';

const initialState = {
  action: 'create-post',
  userModal: false,
  showActionModal: false,
  showAddUserModal: false,
  showAddCSVUserModal: false,
  showNoteModal: false,
  body: {
    userId: -1,
    converting: false,
  },
  recentNotes: {},
  currentNoteModal: {},
  csvUsers: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case APP_SET_ACTION:
      return {
        ...state,
        action: payload.action,
        body: {
          ...state.body,
          ...payload.body,
        },
      };
    case APP_CLEAR_ACTION_BODY:
      return {
        ...state,
        body: initialState.body,
      };
    case APP_TOGGLE_USER_MODAL:
      return {
        ...state,
        userModal: payload.userModal,
        userId: payload.userId,
      };
    case APP_TOGGLE_ACTION_MODAL:
      return {
        ...state,
        showActionModal: payload.showActionModal,
      };
    case APP_TOGGLE_ADD_USER_MODAL:
      return {
        ...state,
        showAddUserModal: payload.showModal,
        showAddCSVUserModal: false,
      };

    case APP_TOGGLE_ADD_CSV_USER_MODAL:
      return {
        ...state,
        showAddCSVUserModal: payload.showModal,
        showAddUserModal: false,
      };
    case APP_SET_CSV_USERS:
      return {
        ...state,
        csvUsers: payload,
      };
    case APP_RESET_CSV_USERS:
      return {
        ...state,
        csvUsers: [],
      };
    case APP_SET_RECENT_NOTE:
      return {
        ...state,
        recentNotes: {
          ...state.recentNotes,
          [payload.id]: {
            ...state.recentNotes[payload.id],
            ...payload.note,
          },
        },
      };
    case APP_RESET_RECENT_NOTE:
      return {
        ...state,
        recentNotes: {},
      };
    case APP_TOGGLE_NOTE_MODAL:
      return {
        ...state,
        showNoteModal: payload.showNoteModal,
      };
    case APP_MODAL_NOTE:
      return {
        ...state,
        currentNoteModal: payload,
      };
    case APP_RESET_MODAL_NOTE:
      return {
        ...state,
        currentNoteModal: {},
      };
    default:
      return state;
  }
};
