import PropTypes from 'prop-types';

const teamShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  parent_id: PropTypes.number,
  depth: PropTypes.number.isRequired,
  members_count: PropTypes.number.isRequired,
  score: PropTypes.number,
  previous_score: PropTypes.number,
  has_enough_members: PropTypes.bool.isRequired,
});

export default teamShape;
