import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import NoteDueDate from 'components/vibes/createForms/CreateNote/NoteDueDate';

const AssignPopupWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 353px;
  background-color: white;
  padding: 24px;
  border: 1px solid #efefef;
  box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const AssignButtonSubmit = styled.button`
  background-color: #11a9ff;
  color: white;
  padding: 9px 20px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #11a9ff;
  outline: none;
`;

const CancelButton = styled.button`
  border: 1px solid #11a9ff;
  margin-left: 18px;
  background-color: white;
  color: #11a9ff;
  padding: 9px 20px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  outline: none;
`;
const SelectTitle = styled.h3`
  padding: 0;
  font-weight: bold;
  margin-bottom: 12px;
  font-style: normal;
  font-size: 13px;
  line-height: 15px;
  color: #323232;
`;

const UserSelect = styled(Select)`
  width: 305px !important;
  margin-bottom: 24px !important;
`;
const DatePickerWrapper = styled.div`
  margin-bottom: 24px;
  & .react-datepicker-wrapper {
    width: 100%;
  }
  & > div {
    width: 100%;
  }
`;

const AssignPopupContent = ({ options, cancelHandling, submitAssignHandler, step }) => {
  const [assignedUser, setAssignedUser] = useState(null);
  const [date, setDate] = useState(null);

  const handleAssignUser = value => {
    setAssignedUser(value);
  };
  useEffect(() => {
    if (options.length) {
      setAssignedUser(options[0]);
    }
  }, []);
  useEffect(() => {
    if (step.assign) {
      setAssignedUser(step.assign.user);
      setDate(step.assign.date);
    }
  }, [step]);
  return (
    <AssignPopupWrapper>
      <SelectTitle>Assign to</SelectTitle>
      <UserSelect
        options={options}
        inputProps={{ id: 'shoutout-for-input' }}
        value={assignedUser}
        isSearchable
        onChange={handleAssignUser}
        clearable={false}
        className="select-component recipients orange"
        placeholder="Search for users here"
        disabled={false}
      />
      <SelectTitle>Due Date</SelectTitle>
      <DatePickerWrapper>
        <NoteDueDate
          mode="Goal"
          setDate={item => {
            setDate(item);
          }}
          date={date}
          initialToggled={false}
        />
      </DatePickerWrapper>
      <ButtonsWrapper>
        <AssignButtonSubmit
          onClick={() => {
            submitAssignHandler(assignedUser, date);
          }}
        >
          Assign
        </AssignButtonSubmit>
        <CancelButton onClick={cancelHandling}>Cancel</CancelButton>
      </ButtonsWrapper>
    </AssignPopupWrapper>
  );
};

export default AssignPopupContent;
