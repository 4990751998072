import React, { Component } from 'react';
import { APP_URL } from '../../../constants';
import { LOCALSTORAGE_GET } from '../../../helpers';

class AccountEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: '',
      name: '',
      planId: '',
      suspended: false,
      scheduleVibePrompts: false,
      plans: [],
      loaded: false,
    };
  }

  componentDidMount() {
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);
    fetch(APP_URL + this.props.location.pathname, { method: 'get', headers })
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({
            account: jsonResponse.account,
            name: jsonResponse.account.name,
            planId: jsonResponse.account.plan_id,
            suspended: jsonResponse.account.suspended,
            scheduleVibePrompts: jsonResponse.account.schedule_vibe_prompts,
            plans: jsonResponse.plans,
          });
        });

        this.setState({ loaded: true });
      })
      .catch(() => {});
  }

  setSuspended() {
    const { suspended } = this.state;
    this.setState({ suspended: !suspended });
  }

  setSchedule() {
    const { scheduleVibePrompts } = this.state;
    this.setState({ scheduleVibePrompts: !scheduleVibePrompts });
  }

  sendData(e) {
    e.preventDefault();
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);
    const data = new FormData();
    data.append('account[name]', this.state.name);
    data.append('account[plan_id]', this.state.planId);
    data.append('account[suspended]', this.state.suspended);
    data.append('account[schedule_vibe_prompts]', this.state.scheduleVibePrompts);
    fetch(`${APP_URL}/admin/accounts/${this.state.account.id}/update`, {
      headers,
      body: data,
      method: 'POST',
    })
      .then(response => {
        response.json().then(jsonResponse => {
          this.props.updateFlash(jsonResponse.flashType, jsonResponse.flashMessage);
        });
      })
      .catch(() => {});
  }

  render() {
    if (this.state.loaded) {
      return (
        <div>
          <h1>{this.state.account.name}</h1>
          <form className="simple_form edit_account">
            <div className="form-group string required account_name">
              <label className="string required control-label">Account name</label>
              <input
                type="text"
                className="string required form-control"
                name="account[name]"
                value={this.state.name}
                onChange={e => {
                  e.preventDefault();
                  this.setState({ name: e.target.value });
                }}
              />
              <p className="help-block">
                The name of this account, usually the company or division name.
              </p>
            </div>
            <div className="form-group select optional account_plan_id">
              <label className="select optional control-label">Plan</label>
              <select
                name="account[plan_id]"
                id="account_plan_id"
                className="select optional form-control"
                onChange={e => this.setState({ planId: e.target.value })}
                value={this.state.planId}
              >
                {this.state.plans.map(plan => (
                  <option value={plan.id} key={plan.id}>
                    {plan.tagged_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group boolean optional account_suspended">
              <div className="checkbox">
                <input
                  className="boolean optional"
                  id="account_suspended"
                  name="account[suspended]"
                  type="checkbox"
                  checked={this.state.suspended}
                  onClick={() => this.setSuspended()}
                />
                <label className="boolean optional">Suspended</label>
              </div>
            </div>

            <div className="form-group boolean optional account_schedule_vibe_prompts">
              <div className="checkbox">
                <input
                  className="boolean optional"
                  id="account_schedule_vibe_prompts"
                  name="account[schedule_vibe_prompts]"
                  type="checkbox"
                  checked={this.state.scheduleVibePrompts}
                  onClick={() => this.setSchedule()}
                />
                <label className="boolean optional">Schedule vibe prompts</label>
              </div>
            </div>
            <hr />
            <input
              type="submit"
              className="btn btn-lg btn-fixed btn-wide btn-primary"
              value="save"
              onClick={e => this.sendData(e)}
            />
          </form>
        </div>
      );
    }
    return <div />;
  }
}
export default AccountEdit;
