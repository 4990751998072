import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';
import { LOCALSTORAGE_GET, LOCALSTORAGE_BOOL } from '../../../helpers';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import qs from 'qs';

class ProfileMenu extends Component {
  signOut(e) {
    localStorage.clear();
    localStorage.setItem('is_authenticated', false);
    e.preventDefault();
    this.renderEvent();

    window.location.reload();
  }

  renderEvent() {
    let event;
    if (typeof MouseEvent !== 'function') {
      event = document.createEvent('MouseEvent');
      event.initMouseEvent(
        'click',
        true,
        true,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
    } else {
      event = new MouseEvent('click');
    }
    document.dispatchEvent(event);
  }

  render() {
    const { open, onClose, rootCloseEvent } = this.props;
    const AdminSettings = () =>
      LOCALSTORAGE_GET('is_authenticated') === 'true' && LOCALSTORAGE_BOOL('is_admin') ? (
        <li id="menu-admin-panel-btn">
          <Link to="admin">
            <i className="fa fa-gear" /> Admin
          </Link>
        </li>
      ) : (
        false
      );
    return (
      <RootCloseWrapper disabled={!open} onRootClose={onClose} event={rootCloseEvent}>
        <ul className="dropdown-menu u-sizevr-1 profile-nav">
          <li id="menu-view-profile-btn">
            <Link
              to={`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`}
              onClick={() => this.renderEvent()}
            >
              View your profile
            </Link>
          </li>
          <li id="menu-edit-profile-btn">
            <Link to="/edit_profile" onClick={() => this.renderEvent()}>
              Edit your profile
            </Link>
          </li>
          <li id="menu-notification-settings-btn">
            <Link to="/notification_settings" onClick={() => this.renderEvent()}>
              Notification settings
            </Link>
          </li>
          {LOCALSTORAGE_GET('is_company_owner') ? (
            <li id="menu-account-settings-btn">
              <Link to="/settings" onClick={() => this.renderEvent()}>
                Account settings
              </Link>
            </li>
          ) : (
            ''
          )}
          <li id="menu-sign-out-btn">
            <a href="#" onClick={e => this.signOut(e)}>
              Sign Out
            </a>
          </li>
          <li role="separator" className="divider" />
          <AdminSettings />
        </ul>
      </RootCloseWrapper>
    );
  }
}

export default ProfileMenu;
