/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { AVATAR_ERROR } from '../../helpers';
import SpaceLogo from '../spaces/SpaceLogo';

const PreviewWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 50%;
`;

const SpaceLogoStyled = styled(SpaceLogo)`
  font-size: 20px;
  line-height: 42px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-weight: bold;
`;

const Description = styled.div`
  margin-left: 17px;
`;

const Name = styled.div`
  font-size: 13px;
  color: #11a9ff;
  margin-bottom: 1px;
`;

const TeamName = styled.div`
  font-size: 13px;
  color: #11a9ff;
  margin-bottom: 1px;
  font-weight: bold;
`;

const Title = styled.div`
  font-size: 10px;
  color: #6a6a6a;
`;

const getDescription = user => {
  let desc = '';
  if (user.role) {
    desc = `${user.role}, `;
  }
  desc += user.team.name;
  return desc;
};

const MemberPreview = ({ member, members }) => {
  const user = members.find(m => m.value === member);
  if (user) {
    if (user.type === 'user') {
      return (
        <PreviewWrapper>
          <Image src={user.image_url} alt="" onError={e => AVATAR_ERROR(e.target)} />
          <Description>
            <Name>
              <strong>
                {user.first_name} {user.last_name}
              </strong>
            </Name>
            <Title>{getDescription(user)}</Title>
          </Description>
        </PreviewWrapper>
      );
    } else {
      return (
        <PreviewWrapper>
          <SpaceLogoStyled space={user} />
          <Description>
            <TeamName>{user.name}</TeamName>
          </Description>
        </PreviewWrapper>
      );
    }
    return null;
  }
};
export default MemberPreview;
