import React from 'react';
import styled from '@emotion/styled';
import SwitchButton from '../../components/shared/buttons/SwitchButton';

const Wrapper = styled.div`
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FilterName = styled.p`
  margin-bottom: 0;
  font-size: 13px;
  color: ${props => (props.enabled ? '#6A6A6A' : '#A6A6A6')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FilterSwitch = styled.div`
  margin-top: 5px;
  margin-left: 5px;
`;
const Filter = ({ label, type, checked, filter, name = 'grow-type' }) => (
  <Wrapper>
    <FilterName enabled={checked}>{label}</FilterName>
    <FilterSwitch>
      <SwitchButton turnedOn={checked} onClick={filter} filter-type={name} type-filter={type} />
    </FilterSwitch>
  </Wrapper>
);

export default Filter;
