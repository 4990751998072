import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { APP_URL } from '../../../constants';
import { LOCALSTORAGE_GET, CONVERT_NAME } from '../../../helpers';
import OfflineInvoices from '../offline_invoices/OfflineInvoices';

class AccountShow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: '',
      users: [],
      usersLast7DaysCount: 0,
      userTotal: 0,
      userActiveTotal: 0,
      userInvitedTotal: 0,
      accountOwner: '',
      teams: [],
      teamTotal: 0,
      defaultTeam: [],
      invoices: [],
      loaded: false,
      user: {},
    };
  }

  componentDidMount() {
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);
    fetch(APP_URL + this.props.location.pathname, { method: 'get', headers })
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({
            account: jsonResponse.account,
            users: jsonResponse.users,
            usersLast7DaysCount: jsonResponse.users_last_7_days_count,
            userTotal: jsonResponse.user_total,
            userActiveTotal: jsonResponse.user_active_total,
            userInvitedTotal: jsonResponse.user_invited_total,
            accountOwner: jsonResponse.account_owner,
            teams: jsonResponse.teams,
            teamTotal: jsonResponse.team_total,
            defaultTeam: jsonResponse.default_team,
            invoices: jsonResponse.invoices,
            user: jsonResponse.user,
          });

          this.setState({ loaded: true });
        });
      })
      .catch(() => {});
  }

  displayUserStat(userCount, text, percentageBase = null, percentageText = null) {
    let textFinal = `${userCount} ${text}`;

    if (percentageBase) {
      textFinal += ` (${Math.round((parseFloat(userCount) / parseFloat(percentageBase)) * 100)}%`;
      textFinal += percentageText ? `${percentageText})` : ')';
    }

    return textFinal;
  }

  displayCascadeTeam(teams) {
    let content = '';
    let children = null;
    teams.map(team => {
      content = `${CONVERT_NAME(team.team.name)} (${team.team.users_active_count} active, ${
        team.team.users_invited_count
      } invited, ${team.team.users_last_7_days_count} seen, ${team.team.team_leads_count} leads)`;

      if (team.team.children && team.team.children.length > 0) {
        children = team.team.children;
      }
    });

    return (
      <dd>
        {content}
        <dl>{children && this.displayCascadeTeam(children)}</dl>
      </dd>
    );
  }

  render() {
    if (this.state.loaded) {
      return (
        <div className="account-show">
          <p className="actions">
            <Link
              to={`/admin/impersonate/${this.state.accountOwner.id}`}
              className="btn btn-primary pull-right margin-below"
            >
              <i className="fa fa-home" /> Impersonate
            </Link>
            <Link
              to={`/admin/accounts/${this.state.account.id}/edit`}
              className="btn btn-primary pull-right margin-below"
            >
              <i className="fa fa-pencil" /> Edit this account
            </Link>
          </p>

          <h1>{this.state.account.name}</h1>
          <dl>
            <dt>Users:</dt>
            <dd>{this.displayUserStat(this.state.userTotal, 'total users')}</dd>
            <dd>
              {this.displayUserStat(
                this.state.userActiveTotal,
                'active users',
                this.state.userTotal
              )}
            </dd>
            <dd>
              {this.displayUserStat(this.state.userInvitedTotal, 'invited', this.state.userTotal)}
            </dd>
            <dd>
              {this.displayUserStat(
                this.state.usersLast7DaysCount,
                'seen in last 7 days',
                this.state.userActiveTotal,
                ' of active users'
              )}
            </dd>
          </dl>
          <dl>
            <dt>Teams</dt>
            <dd>{this.state.teamTotal} teams</dd>
            {this.displayCascadeTeam([this.state.defaultTeam])}
          </dl>
          <dl>
            <dt>Account Owner:</dt>
            <dd>
              {this.state.accountOwner &&
                `${this.state.accountOwner.first_name} ${this.state.accountOwner.last_name}`}
            </dd>

            <dd>
              <a href={`mailto:${this.state.accountOwner}` && this.state.accountOwner.email}>
                {this.state.accountOwner && this.state.accountOwner.email}
              </a>
            </dd>
          </dl>
          <p>
            <Link
              to={`/admin/offline_invoices/new?account_id=${this.state.account.id}`}
              className="btn btn-primary pull-right margin-below"
            >
              create new invoice
            </Link>
          </p>
          <h2>Past Offline Invoices</h2>
          <table className="table">
            <tbody>
              <tr>
                <th />
                <th>Name</th>
                <th>Code</th>
                <th>Sent</th>
                <th>Paid</th>
                <th className="hidden-sm hidden-xs">Created</th>
                <th className="hidden-sm hidden-xs">Invoice Due</th>
                <th />
              </tr>
              {this.state.invoices.map(invoice => (
                <OfflineInvoices key={invoice.id} invoice={invoice} user={this.state.user} />
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return <div />;
  }
}
export default AccountShow;
