import React, { Component } from 'react';

class UserTile extends Component {
  state = {
    mounted: false,
  };

  dragStart = e => {
    if (
      this.props.setupStep === 1 &&
      !this.props.dragHelperVariables.draggedUsersDOM &&
      !this.props.dragHelperVariables.draggedTeamDOM
    ) {
      e.stopPropagation();
      document.body.classList.add('dragging');
      Array.from(document.querySelectorAll('.drag-capture-area')).forEach(node => {
        node.classList.add('active');
      });
      const mainElement = e.currentTarget;
      const mainElementX = mainElement.getBoundingClientRect().left;
      const mainElementY = mainElement.getBoundingClientRect().top;
      const selectedElementsDOM = Array.from(
        mainElement.parentNode.parentNode.querySelectorAll('.user-tile.selected')
      );
      if (selectedElementsDOM.includes(mainElement)) {
        selectedElementsDOM.splice(selectedElementsDOM.indexOf(mainElement), 1);
      }
      selectedElementsDOM.unshift(mainElement);
      const selectedElementsData = [
        ...this.props.team.users.filter(user => user.is_selected && user.id !== this.props.user.id),
        this.props.user,
      ];
      this.props.setDragHelperVariables({
        dragHelperVariables: {
          draggedUsersDOM: selectedElementsDOM,
          draggedUsersData: selectedElementsData,
          dragStartX: e.clientX,
          dragStartY: e.clientY,
          draggedElementStartX: mainElementX,
          draggedElementStartY: mainElementY,
        },
      });
      selectedElementsDOM.forEach((element, index) => {
        element.classList.remove('stop-position-transition');
        element.style.width = `${element.getBoundingClientRect().width}px`;
        element.parentNode.style.height = window
          .getComputedStyle(element.parentNode)
          .getPropertyValue('height');
        element.style.top = `${element.getBoundingClientRect().top}px`;
        element.style.left = `${element.getBoundingClientRect().left}px`;
        element.classList.add('stop-position-transition', 'dragged');
        setTimeout(() => {
          element.classList.remove('stop-position-transition');
          element.style.transform = 'rotateZ(8deg)';
          element.style.top = `${mainElementY + index * 52}px`;
          element.style.left = `${mainElementX}px`;
        }, 0);
      });
    }
  };

  componentDidMount() {
    setTimeout(() => this.setState({ mounted: true }), 5);
  }

  render() {
    return (
      <div
        id={this.props.id}
        className={`user-tile-background ${this.state.mounted ? 'mounted' : ''}`}
      >
        <div
          className={`user-tile ${this.props.setupStep > 1 ? 'remove-grab-cursor' : ''} ${
            this.props.user.is_selected ? 'selected' : ''
          }`}
          onMouseDown={this.dragStart}
        >
          <span className="user-name">
            {this.props.user.first_name} {this.props.user.last_name}
          </span>
          {this.props.setupStep === 1 ? (
            <span
              className={`custom-checkbox ${this.props.user.is_selected ? 'selected' : ''}`}
              onClick={() => this.props.selectUser(this.props.user)}
              onMouseDown={e => e.stopPropagation()}
            />
          ) : (
            ''
          )}
          {this.props.setupStep > 1 ? (
            <span
              className={`invite-checkbox ${this.props.sendingInvitations ? 'disabled' : ''} ${
                !this.props.user.marked_to_invite ? '' : 'selected'
              } ${this.props.user.state === 'active' ? 'already-invited' : ''}`}
              onMouseDown={e => e.stopPropagation()}
              onClick={() =>
                !this.props.sendingInvitations && this.props.user.state !== 'active'
                  ? this.props.markInvitedUsers(true, null, this.props.user)
                  : null
              }
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

export default UserTile;
