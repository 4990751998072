const steps = {
  email: 'email',
  existingUser: 'existingUser',
  resendInvite1: 'resendInvite1',
  resendInvite2: 'resendInvite2',
  newUser1: 'newUser1',
  newUser2: 'newUser2',
  welcomeError: 'welcomeError',
  password: 'password',
  teamName: 'teamName',
  registerError: 'registerError',
  suspended: 'suspended',
  inviteName: 'inviteName',
  invitePassword: 'invitePassword',
  inviteTerms: 'inviteTerms',
  inviteError: 'inviteError',
  redirect: 'redirect',
  both: 'both',
  demo: 'demo',
};

export default steps;
