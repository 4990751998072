import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

import { BODY_CLASSES, HEADERS } from 'helpers';

import debounce from 'lodash/debounce';
import Header from '../partials/Header';
import OrganisationTable from '../partials/OrganisationTable';
import { APP_URL } from '../../../constants';
import categories from '../categories';
import Page from '../../layouts/Page';
import fields from './fields';
import TeamsSidebar from './TeamsSidebar';

const Teams = ({ location, history, updateFlash, isMember }) => {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [page, setPage] = useState(queryParams.page || 1);
  const [filter, setFilter] = useState(queryParams.type || 'flat');
  const [teams, setTeams] = useState({});
  const [searchTerm, setSearchTerm] = useState(queryParams.search_term || '');
  const [sort, setSort] = useState({
    order: queryParams.sort_order || 'asc',
    column: queryParams.sort_column || 'name',
  });
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    BODY_CLASSES();
  }, []);

  const isHierarchical = filter === 'hierarchical';

  const handleSelectClick = id => {
    if (!isMember) {
      setSelectedIndex(id);
    }
  };

  const fetchTeams = async () => {
    const headers = HEADERS();
    const params = {
      page,
      search: searchTerm,
      sort_order: sort.order,
      sort_column: sort.column,
    };

    if (searchTerm !== teams.searchTerm || isHierarchical) {
      setPage(1);
      params.page = 1;
    }

    if (isHierarchical) {
      params.type = 'hierarchical';
    }

    history.replace(`/teams?${qs.stringify(params)}`);
    const response = await fetch(`${APP_URL}/teams?${qs.stringify(params)}`, {
      method: 'get',
      headers,
    });
    const json = await response.json();
    const rows = json.teams.data.map(team => fields.rows[filter](team, fetchTeams, isMember));
    setTeams({
      ...json.teams,
      filter,
      rows,
      searchTerm,
      page,
    });
    setSelectedIndex(-1);
    setLoading(false);
  };

  useEffect(() => {
    fetchTeams();
  }, [page, searchTerm, sort.order, sort.column, filter]);

  const handleSort = column => {
    switch (sort.order) {
      case 'desc':
        return setSort({
          column,
          order: 'asc',
        });
      case 'asc':
        return setSort({
          column: '',
          order: '',
        });
      case '':
      default:
        return setSort({
          column,
          order: 'desc',
        });
    }
  };

  const getSelectedTeam = () => {
    const team = teams.data.find((team, index) => index === selectedIndex);
    if (!team) return null;
    return team;
  };

  const handleHierarchicalSwitch = () => {
    setFilter(filter === 'flat' ? 'hierarchical' : 'flat');
    setSearchTerm('');
  };
  const handleFilter = () => debounce(setSearchTerm, 300);

  return !loading ? (
    <>
      <Page.Wrapper>
        <Page.Content>
          <Header
            category={categories.TEAMS}
            count={teams.total}
            switchButton={{
              handleChange: handleHierarchicalSwitch,
              toggled: isHierarchical,
            }}
            handleFilter={!isHierarchical && handleFilter()}
          />
        </Page.Content>
        <Page.SidebarPlaceholder />
      </Page.Wrapper>
      <Page.Wrapper>
        <Page.Content>
          {isHierarchical ? (
            <OrganisationTable
              columns={fields.columns[teams.filter]}
              rows={teams.rows}
              select={{
                multi: false,
                handleChange: handleSelectClick,
                index: selectedIndex,
                allowSelect: !isMember,
              }}
              hierarchical
            />
          ) : (
            <OrganisationTable
              columns={fields.columns[teams.filter]}
              rows={teams.rows}
              select={{
                multi: false,
                handleChange: handleSelectClick,
                index: selectedIndex,
                allowSelect: !isMember,
              }}
              pagination={{
                index: teams.page,
                total: teams.last_page,
                handleChange: setPage,
              }}
              sort={{
                order: sort.order,
                handleChange: handleSort,
                column: sort.column,
              }}
            />
          )}
        </Page.Content>
        <Page.Sidebar>
          <TeamsSidebar
            teams={teams.data}
            selected={getSelectedTeam()}
            updateFlash={updateFlash}
            reloadData={fetchTeams}
          />
        </Page.Sidebar>
      </Page.Wrapper>
    </>
  ) : null;
};

const mapStateToProps = state => ({
  isMember:
    !!state.usersDomain.user &&
    !state.usersDomain.user.isAccountAdmin &&
    !state.usersDomain.user.formalLeadTeamsIds.length > 0,
});
export default withRouter(connect(mapStateToProps, null)(Teams));
