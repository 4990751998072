import { applyMiddleware, compose, createStore } from 'redux';
import { ENV } from 'constants.js';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
      })
    : compose;

const middleware =
  ENV === 'development' ? [require('redux-immutable-state-invariant').default(), thunk] : [thunk];

const enhancer = composeEnhancers(applyMiddleware(...middleware));

export default createStore(rootReducer, enhancer);
