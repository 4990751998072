import { APP_URL } from 'constants.js';
import { HEADERS } from 'helpers';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';

export const validateNoteCreation = (
  state,
  toggledMode,
  preparationNote,
  update,
  maxCharsContent
) => {
  const isMeetingOrManagerPrompt =
    toggledMode === noteTypes.meeting ||
    toggledMode === noteTypes.quickMeeting ||
    toggledMode === noteTypes.managerPrompt ||
    toggledMode === noteTypes.managerPromptPreparation;
  if (
    (!state.content.length &&
      toggledMode !== noteTypes.meetingPreparation &&
      !isMeetingOrManagerPrompt) ||
    (!state.meetingContent.length && isMeetingOrManagerPrompt)
  ) {
    return 'Form contains empty fields that are required';
  }
  if (state.content.length > maxCharsContent) {
    return 'Your post exceeds the maximum length of ' + maxCharsContent + ' characters';
  }
  if (isMeetingOrManagerPrompt && !preparationNote) {
    if (
      (!state.meetingMember && !state.fromNote && !update) ||
      (!state.noteRecipient && state.fromNote)
    ) {
      return 'Please select a user or team you met with';
    }
    if (!state.meetingDate && !update) {
      return 'Please select a meeting date';
    }
  }
  return null;
};

export const recordNote = async (state, props, data, type, update = false) => {
  if (type === noteTypes.quickMeeting) {
    type = noteTypes.meeting;
  }
  const shareWith = type === noteTypes.meeting ? state.meetingShareWith : state.shareWith;
  if (shareWith.length) {
    shareWith.forEach(user => {
      if (user.type === 'user') {
        data.append('note[shared_ids][]', user.id);
      } else {
        data.append('note[shared_team_ids][]', user.id);
      }
    });
  }
  data.append(
    'note[content]',
    type === noteTypes.meeting || type === noteTypes.managerPrompt
      ? state.meetingContent
      : state.content
  );
  data.append('note[type]', type);
  if (type === noteTypes.want) {
    data.append('note[category]', state.wantCategory);
  }

  if (type === noteTypes.private) {
    if (state.noteRecipient?.type === 'user') {
      data.append('note[member_id]', state.noteRecipient.id);
    } else {
      data.append('note[member_team_id]', state.noteRecipient.id);
    }
    data.append('note[category]', state.category);
  }

  if (type === noteTypes.goal || type === noteTypes.managerPrompt) {
    const assigneeId = state.assignTo?.value;
    if (assigneeId) {
      data.append('note[assignee_id]', assigneeId);
    }
    data.append('note[title]', state.title || '');
    if (state.dueDate) {
      data.append('note[due_date]', state.dueDate);
    }
  }
  if (
    (type === noteTypes.meetingPreparation ||
      type === noteTypes.meeting ||
      type === noteTypes.managerPrompt) &&
    state.steps
  ) {
    for (let i = 0; i < state.steps.length; i++) {
      if (state.steps.length > 0) {
        data.append(`steps[${i}][content]`, state.steps[i].value);
        data.append(`steps[${i}][id]`, state.steps[i].dbId);
        if (state.steps[i].assign) {
          data.append(`steps[${i}][assignee_id]`, state.steps[i].assign.user.value);
          if (state.steps[i].assign.date) {
            data.append(`steps[${i}][due_date]`, state.steps[i].assign.date);
          }
        }
      }
    }
    if (
      (type === noteTypes.meeting || type === noteTypes.managerPrompt) &&
      state.preparationNote &&
      props.action !== 'create-note-quick-meeting'
    ) {
      data.append('note[preparation_note_id]', state.preparationNote);
    }
    data.append('note[additional_notes]', state.additionalNotes);
    if (
      (state.meetingMember && state.fromNote && state.toggledMode === 'Meeting') ||
      state.toggledMode === 'QuickMeeting'
    ) {
      if (state.meetingMember.type === 'user') {
        data.append('note[member_id]', state.meetingMember.id);
      } else {
        data.append('note[team_id]', state.meetingMember.id);
      }
    } else if (state.meetingMemberId && type === noteTypes.meeting) {
      data.append('note[member_id]', state.meetingMemberId);
    } else if (state.meetingTeamId && type === noteTypes.meeting) {
      data.append('note[team_id]', state.meetingTeamId);
    }
    data.append('note[date]', state.meetingDate);
    data.append('note[time]', state.meetingTime);
  }

  if (props.progressStatus) {
    data.append('progressStatus', props.progressStatus);
  }

  if (type === noteTypes.private || type === noteTypes.goal) {
    for (let i = 0; i < state.goals.length; i++) {
      data.append('relatedGoals[]', state.goals[i]);
    }
    for (let i = 0; i < state.companyValues.length; i++) {
      data.append('relatedCompanyValues[]', state.companyValues[i]);
    }
  }
  let response;
  const headers = HEADERS();
  if (update) {
    data.append('_method', 'PUT');
    response = await fetch(`${APP_URL}/member_notes/${state.id}`, {
      method: 'post',
      headers,
      body: data,
    });
  } else {
    response = await fetch(`${APP_URL}/member_notes`, { method: 'post', headers, body: data });
  }
  return response;
};

export const recordWin = async (state, data, update) => {
  if (state.shareWith.length) {
    state.shareWith.forEach(user => {
      if (user.type === 'user') {
        data.append('win[shared_ids][]', user.id);
      } else {
        data.append('win[shared_team_ids][]', user.id);
      }
    });
  }

  data.append('win[title]', state.title ?? '');
  data.append('win[content]', state.content);
  for (let i = 0; i < state.goals.length; i++) {
    data.append('win_goals[]', state.goals[i]);
  }
  for (let i = 0; i < state.companyValues.length; i++) {
    data.append('win_values[]', state.companyValues[i]);
  }
  let response;
  const headers = HEADERS();
  if (update === 'edit') {
    data.append('_method', 'PUT');
    response = await fetch(`${APP_URL}/win/${state.id}`, { method: 'post', headers, body: data });
  } else if (update) {
    response = await fetch(`${APP_URL}/member_notes/convert/${state.id}`, {
      method: 'post',
      headers,
      body: data,
    });
  } else {
    response = await fetch(`${APP_URL}/win`, { method: 'post', headers, body: data });
  }
  return response;
};

export const getNoteType = type => {
  switch (type) {
    case 'create-note-private':
      return noteTypes.private;
    case 'create-note-win':
      return noteTypes.win;
    case 'create-note-want-to':
      return noteTypes.want;
    case 'create-note-goal':
      return noteTypes.goal;
    case 'create-note-meeting-preparation':
      return noteTypes.meetingPreparation;
    case 'create-note-meeting':
      return noteTypes.meeting;
    case 'create-note-quick-meeting':
      return noteTypes.quickMeeting;
    case 'create-note-manager-prompt':
      return noteTypes.managerPrompt;
    case 'create-note-manager-prompt-preparation':
      return noteTypes.managerPromptPreparation;
    case 'create-note-meeting-schedule':
      return noteTypes.meetingSchedule;
    case 'create-note-meeting-oneoff':
      return noteTypes.meetingOneOff;
    default:
      return noteTypes.freeText;
  }
};

export const getPlaceholder = (mode, wantCategory) => {
  switch (mode) {
    case noteTypes.freeText:
      return 'Start with a note or choose one of the options above to create a goal, to do, meeting and more';
    case noteTypes.goal:
      return 'What do you need to action / do?';
    case noteTypes.win:
      return 'Describe your achievement here...';
    case noteTypes.private:
      return 'Start typing your feedback here...';
    case noteTypes.want:
      switch (wantCategory) {
        case 'Start':
          return 'Start typing here e.g. Sharing more of our wins with the rest of the team';
        case 'Stop':
          return 'Start typing here e.g. Having so many meetings as it gets in the way of work';
        case 'Continue':
          return 'Start typing here e.g. Having a weekly check-in to see where the team is up to';
      }
    default:
      return 'Type your note here...';
  }
};

export const initialNoteState = {
  showMediaDropzone: false,
  showFileDropzone: false,
  isHashtag: false,
  isMention: false,
  content: '',
  meetingContent: '',
  team: '',
  mediaUrl: [],
  media: [],
  mediaSize: [],
  subjects: [],
  subject: '',
  files: [],
  oldFiles: [],
  relatedPostsCount: 0,
  errorState: false,
  charsLeftContent: 1000,
  showCharLimitInfo: false,
  selection: { start: 0, end: 0 },
  inputElement: null,
  markup: '[-/-markupStart-/-]__type__:__id__[--__display__--]',
  postErrors: [],
  generalError: null,
  subtype: 'idea',
  toggledMode: noteTypes.freeText,
  isSharing: false,
  meetingIsSharing: false,
  shareWith: [],
  meetingShareWith: [],
  companyValues: [],
  goals: [],
  title: '',
  noteRecipient: '',
  category: '',
  wantCategory: '',
  data: {
    categories: [],
    leads: [],
    users: [],
    feedbackUsers: [],
    wantCategories: [],
  },
  addAnother: false,
  flash: {
    toggled: false,
    message: '',
    type: 'success',
  },
  assignTo: '',
  dueDate: '',
  goalsToggle: false,
  valuesToggle: false,
  toggleDatePicker: false,
  disabledButton: false,
  stepsCount: 2,
  additionalNotes: '',
  steps: [
    {
      label: 'Next step 1',
      value: '',
      id: 1,
      dbId: null,
    },
    {
      label: 'Next step 2',
      value: '',
      id: 2,
      dbId: null,
    },
  ],
  meetingTime: '12:00 PM',
  meetingDate: '',
  meetingMember: null,
  meetingMemberId: null,
  meetingTeamId: null,
  members: [],
  fromNote: false,
  loading: false,
};
