import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import WelcomeContainer from './WelcomeContainer';
import LoadingOverlay from './LoadingOverlay';
import steps from './steps';
import { HEADERS } from '../../helpers';
import { APP_URL } from '../../constants';
import ErrorPage from './ErrorPage';

const ResendInvite = ({ email, step, selectStep, history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!email) history.push('/login');
  }, []);

  const setTitle = () => {
    switch (step) {
      case steps.resendInvite1:
        return 'Missing your invitation?';
      case steps.resendInvite2:
        return 'Invite sent';
    }
  };

  const handleResend = async e => {
    e.preventDefault();
    setIsLoading(true);

    const email = e.target.email.value;
    const data = new FormData();
    data.append('email', email);

    const headers = HEADERS();
    const response = await fetch(`${APP_URL}/invitation/resend`, {
      method: 'post',
      body: data,
      headers,
    });
    const json = await response.json();
    if (json.error) {
      setError(json.error);
    }

    selectStep();
    setIsLoading(false);
  };

  return error ? (
    <ErrorPage title="Something went wrong" body={error} />
  ) : (
    <WelcomeContainer
      title={setTitle()}
      body={
        <>
          <LoadingOverlay toggled={isLoading} message="Processing..." height="65%" />
          {step === steps.resendInvite1 ? (
            <>
              <div className="description-invite mx-auto">
                Looks like you're already on our system. If you're missing your invite enter your
                email below to request another one.
              </div>
              <form
                onSubmit={handleResend}
                action=""
                className="simple_form form-compact "
                id="new_user"
              >
                <div className="form-group email required user_email email-confirmation">
                  <input
                    name="email"
                    type="email"
                    className="string email required form-control"
                    id="user_email"
                    placeholder="Start with your email"
                    defaultValue={email}
                    required
                  />
                </div>
                <button
                  disabled={isLoading}
                  type="submit"
                  className="btn btn-primary btn-full"
                  id="sign-in-btn"
                >
                  Request invite
                </button>
              </form>
            </>
          ) : step === steps.resendInvite2 ? (
            <div className="verify-email invite-sent">
              <div>We've just resent an invite to the email linked with your account.</div>
              <div>Please follow the instructions to get started.</div>
              <div className="verify-didnt-receive">
                Didn't receive an email? <a onClick={selectStep}>Click here</a>.
              </div>
            </div>
          ) : null}
        </>
      }
    />
  );
};

ResendInvite.propTypes = {
  email: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
  selectStep: PropTypes.func.isRequired,
};

export default withRouter(ResendInvite);
