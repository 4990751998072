/** @jsx jsx */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AVATAR_ERROR, LOCALSTORAGE_BOOL } from 'helpers';
import Star from 'images/grow/star.svg';
import Edit from 'images/grow/edit.svg';
import { css, jsx } from '@emotion/core';

class UserProfile extends Component {
  showRole = () =>
    this.props.user.role.length > 0 ? (
      <span id="user-profile-role">
        {this.props.user.role}
        ,&nbsp;
      </span>
    ) : null;

  showUserTeam = () => (
    <Link
      to={`/team/${this.props.user.team_id}`}
      className="user-profile-link"
      id="user-profile-team"
    >
      {this.props.teamName}
    </Link>
  );

  EditLink = () => {
    if (this.props.user) {
      return (
        <div className="row">
          <Link to="/edit_profile" className="primary-link">
            <img
              css={css`
                margin-right: 13px;
              `}
              height="25px"
              width="25px"
              src={Edit}
              alt=""
            />
            <span>Edit your profile</span>
          </Link>
        </div>
      );
    }
    if (LOCALSTORAGE_BOOL('is_company_owner')) {
      return (
        <div className="row">
          <Link to={`/user/${this.id}/edit`} className="primary-link">
            <img
              css={css`
                margin-right: 13px;
              `}
              height="25px"
              width="25px"
              src={Edit}
              alt=""
            />
            <span>Edit user's profile</span>
          </Link>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="full-width">
        <div id="user-profile-wrapper">
          <div className="row grow-row">
            <div className="col-xs-12 col-lg-2" id="img-wrapper">
              <img
                src={this.props.user.image_url}
                alt=""
                className="img-circle"
                id="grow-user-profile-img"
                onError={e => AVATAR_ERROR(e.target)}
              />
            </div>
            <div className="col-xs-12 col-lg-8" id="description-wrapper">
              <div className="row">
                <div className="col-xs-12" id="name-role-wrapper">
                  <div>
                    <h2 id="user-profile-name">
                      {this.props.user.first_name} {this.props.user.last_name}
                      {this.props.userAlreadyVibed && (
                        <img
                          css={css`
                            margin-left: 10px;
                          `}
                          height="20px"
                          width="20px"
                          src={Star}
                          alt=""
                        />
                      )}
                    </h2>
                    <div id="user-description-with-time-info">
                      {this.showRole()}
                      {this.showUserTeam()}
                      <div>
                        <i className="fa fa-map-marker" aria-hidden="true" />
                        <span id="user-profile-timezone"> {this.props.user.time_zone}</span>
                      </div>
                      <div>
                        <i className="fa fa-clock-o" aria-hidden="true" />
                        <span id="user-profile-local-time">
                          Local time: <span>{this.props.localTime}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12" id="desc-tags-wrapper">
                  <p className="description">
                    {this.props.user.description
                      ? this.props.user.description
                      : "Add your own bio to this space by clicking 'Edit profile' above. \nYou can tell your team mates a bit more about yourself and your interests."}
                  </p>
                  <p>
                    {this.props.user.tags.map(tag => (
                      <Link
                        key={tag}
                        to={{
                          pathname: '/users/tags',
                          search: `?t=${tag}&page=1`,
                          state: { t: tag },
                        }}
                        className="user-profile-tag"
                      >
                        #{tag}
                      </Link>
                    ))}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-lg-2 buttons" id="description-wrapper">
              <div className="row float-right">
                <div className="col-xs-12" id="name-role-wrapper">
                  <div>
                    <div id="edit-profile-link">{this.EditLink()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserProfile;
