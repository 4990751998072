import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import 'react-autocomplete-input/dist/bundle.css';
import Select from 'react-select';
import { connect } from 'react-redux';
import fetchApi from 'fetchApi';
import { SPACE_ACTIONS } from '../../redux/actions/spaceActions';
import { POST_ACTIONS } from '../../redux/actions/postActions';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';
import { APP_URL } from '../../constants';

class AssignLeadModal extends Component {
  state = {
    users: [],
    selectedUser: '',
    spaceMembers: [],
  };

  fetchMembers = async () => {
    const response = await fetchApi(`/assign_lead/${this.props.type}/${this.props.assignTo.id}`);
    this.setState({ spaceMembers: response.members });
  };

  componentDidMount() {
    this.props.assignTo && this.props.assignTo.id && this.fetchMembers();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.type === 'spaces' &&
      this.props.showAssignLeadModal &&
      this.props.assignTo.id !== prevProps.assignTo.id
    ) {
      this.fetchMembers();
    }
  }

  submitForm() {
    const { type, dispatchAfterFetch } = this.props;
    this.props.turnOnSpinner && this.props.turnOnSpinner(this.props.assignTo.id);
    const headers = HEADERS();
    fetch(
      `${APP_URL}/${type}/assign_lead/${this.state.selectedUser.value}/${this.props.assignTo.id}`,
      { method: 'get', headers }
    )
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          if (type === 'teams') {
            return dispatchAfterFetch && dispatchAfterFetch();
          }
          this.props.fetchSpaceDetails(this.props.assignTo.id);
          if (this.props.resetList) {
            this.props.resetList();
          } else {
            this.props.resetPosts();
            this.props.fetchPosts();
          }
          if (this.props.updateFlash) {
            this.props.updateFlash(jsonResponse.flashName, jsonResponse.message);
          }
        });
      })
      .catch(error => {});
    this.setState({ selectedUser: '' });
    this.props.closeAssignLeadModalHandler();
  }

  getSelectUserOptions() {
    const leadIds = this.props.leads && this.props.leads.map(a => a.id);
    let users = this.state.spaceMembers;
    if (leadIds) {
      users = users.filter(user => !leadIds.includes(user.id));
    }
    if (users && users.length > 0) {
      users = users.map(user => ({ value: user.id, label: `${user.name} (${user.slug})` }));
    }
    return users;
  }

  render() {
    return (
      <Modal
        show={this.props.showAssignLeadModal}
        onHide={this.props.closeAssignLeadModalHandler}
        dialogClassName="informal-space-modal"
        backdrop
        keyboard
      >
        <div className="close-cross" onClick={this.props.closeAssignLeadModalHandler} />
        <div className="row table nopadding">
          <div className="col-md-8 create-space nopadding">
            <form className="modal-inner-wrap">
              <h1>Assign lead</h1>
              <h2>
                Assign lead to {this.props.assignTo && this.props.assignTo.name}{' '}
                {this.props.type === 'spaces' ? 'space' : 'team'}{' '}
              </h2>
              <Select
                options={this.getSelectUserOptions()}
                value={this.state.selectedUser}
                isSearchable
                onChange={e => this.setState({ selectedUser: e })}
                clearable={false}
                inputProps={{ id: 'assign-lead-input' }}
                className="select-component assign-lead orange"
                isMulti
                placeholder=""
              />
              <div>
                <input
                  id="create-space-btn"
                  type="button"
                  onClick={() => this.submitForm()}
                  value="Assign lead"
                />
                <span
                  id="error-message"
                  className={`error-message ${this.state.errorState && 'shown'}`}
                >
                  {this.state.errorState}
                </span>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}
AssignLeadModal.defaultProps = {
  type: 'spaces',
};

const mapDispatchToProps = dispatch => ({
  fetchPosts: () => dispatch(POST_ACTIONS.fetchPosts()),
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  fetchSpaceDetails: spaceId => dispatch(SPACE_ACTIONS.fetchSpaceDetails(spaceId)),
});

export default connect(null, mapDispatchToProps)(AssignLeadModal);
