import DOMAINS from '../domains';

export const SPACE_ACTION_TYPES = {
  FAV_SPACE: 'FAV_SPACE', // --Performs both fav and unfav actions
  RECEIVE_FAV_STATUS: 'RECEIVE_FAV_STATUS',

  FETCH_FORMAL_SPACE: 'FETCH_FORMAL_SPACE',
  RECEIVE_FORMAL_SPACE: 'RECEIVE_FORMAL_SPACE',

  FETCH_SPACE_NOTIFICATION_SETTINGS: 'FETCH_SPACE_NOTIFICATION_SETTINGS',
  RECEIVE_SPACE_NOTIFICATION_SETTINGS: 'RECEIVE_SPACE_NOTIFICATION_SETTINGS',

  FETCH_SPACE_DETAILS: 'FETCH_SPACE_DETAILS',
  RECEIVE_SPACE_DETAILS: 'RECEIVE_SPACE_DETAILS',

  UNFOLLOW_SPACE: 'UNFOLLOW_SPACE',
  RECEIVE_UNFOLLOW_SPACE_STATUS: 'RECEIVE_UNFOLLOW_SPACE_STATUS',

  GET_EDITED_SPACE: 'GET_EDITED_SPACE',
  RECEIVE_EDITED_SPACE: 'RECEIVE_EDITED_SPACE',
  RESET_EDITED_SPACE: 'RESET_EDITED_SPACE',
  FETCH_SPACES: 'FETCH_SPACES',
  RECEIVE_SPACES: 'RECEIVE_SPACES',
  RECEIVE_SPACE_MEMBERS: 'RECEIVE_SPACE_MEMBERS',

  UPDATE_SPACE_NAME: 'UPDATE_SPACE_NAME',
};
export const SPACE_ACTIONS = {
  favSpace: (spaceId, callback) => ({
    domain: DOMAINS.SPACES,
    type: SPACE_ACTION_TYPES.FAV_SPACE,
    payload: {
      spaceId,
      callback,
    },
  }),
  receiveFavStatus: (spaceId, json) => ({
    domain: DOMAINS.SPACES,
    type: SPACE_ACTION_TYPES.RECEIVE_FAV_STATUS,
    payload: {
      spaceId,
      json,
    },
  }),

  fetchFormalSpace: spaceId => ({
    domain: DOMAINS.SPACES,
    type: SPACE_ACTION_TYPES.FETCH_FORMAL_SPACE,
    payload: spaceId,
  }),
  receiveFormalSpace: json => ({
    domain: DOMAINS.SPACES,
    type: SPACE_ACTION_TYPES.RECEIVE_FORMAL_SPACE,
    payload: json,
  }),

  fetchSpaceNotificationSettings: () => ({
    domain: DOMAINS.SPACES,
    type: SPACE_ACTION_TYPES.FETCH_SPACE_NOTIFICATION_SETTINGS,
  }),

  receiveSpaceNotificationSettings: json => ({
    domain: DOMAINS.SPACES,
    type: SPACE_ACTION_TYPES.RECEIVE_SPACE_NOTIFICATION_SETTINGS,
    payload: json,
  }),

  fetchSpaceDetails: spaceId => ({
    domain: DOMAINS.SPACES,
    type: SPACE_ACTION_TYPES.FETCH_SPACE_DETAILS,
    payload: spaceId,
  }),

  receiveSpaceDetails: json => ({
    domain: DOMAINS.SPACES,
    type: SPACE_ACTION_TYPES.RECEIVE_SPACE_DETAILS,
    payload: json,
  }),

  unfollowSpace: (id, notificationType) => ({
    domain: DOMAINS.SPACES,
    type: SPACE_ACTION_TYPES.UNFOLLOW_SPACE,
    payload: {
      id,
      notificationType,
    },
  }),
  receiveUnfollowSpaceStatus: (spaceId, json) => ({
    domain: DOMAINS.SPACES,
    type: SPACE_ACTION_TYPES.RECEIVE_UNFOLLOW_SPACE_STATUS,
    payload: {
      spaceId,
      json,
    },
  }),
  fetchSpaces: (successCallback = () => {}) => ({
    domain: DOMAINS.SPACES,
    type: SPACE_ACTION_TYPES.FETCH_SPACES,
    payload: { successCallback },
  }),
  receiveSpaces: json => ({
    domain: DOMAINS.SPACES,
    type: SPACE_ACTION_TYPES.RECEIVE_SPACES,
    payload: json,
  }),
  receiveSpaceMembers: json => ({
    domain: DOMAINS.SPACES,
    type: SPACE_ACTION_TYPES.RECEIVE_SPACE_MEMBERS,
    payload: json,
  }),

  updateSpaceName: (spaceIndex, newName) => ({
    domain: DOMAINS.SPACES,
    type: SPACE_ACTION_TYPES.UPDATE_SPACE_NAME,
    payload: {
      spaceIndex,
      newName,
    },
  }),
};
