import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as TrendDown } from 'pages/Grow/GrowPost/assets/trend_down.svg';
import { connect } from 'react-redux';
import { clearActionBody, setAction, setShowActionModal, toggleNoteModal } from 'redux/app/actions';
import {
  BlueButtonStyled,
  Container,
  Content,
  Picture,
} from 'pages/Grow/GrowPost/ManagerPrompts/layout';
import ManagerPromptDropdown from 'pages/Grow/GrowPost/ManagerPrompts/ManagerPromptDropdown';
import { noteShape } from 'pages/Grow/GrowPost/ManagerPrompts/common';

const ManagerPrompt = ({ note, clearActionBody, setAction, setShowActionModal }) => {
  const handleStart = e => {
    e.preventDefault();
    clearActionBody();
    setAction('create-note-manager-prompt', {
      preparationNote: note,
    });
    setShowActionModal(true);
  };

  return (
    <Container>
      <ManagerPromptDropdown note={note} />
      <Picture>
        <TrendDown />
      </Picture>
      <Content>{note.content}</Content>
      <BlueButtonStyled onClick={handleStart}>Get started</BlueButtonStyled>
    </Container>
  );
};

ManagerPrompt.propTypes = {
  note: PropTypes.shape(noteShape).isRequired,
};

const mapStateToProps = state => ({
  user: !!state.usersDomain.user && state.usersDomain.user,
});

const mapDispatch = dispatch => ({
  setAction: (tab, body) => dispatch(setAction(tab, body)),
  setShowActionModal: open => dispatch(setShowActionModal(open)),
  clearActionBody: () => dispatch(clearActionBody()),
  toggleNoteModal: toggled => dispatch(toggleNoteModal(toggled)),
});
export default connect(mapStateToProps, mapDispatch)(ManagerPrompt);
