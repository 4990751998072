import React from 'react';

export default class Factor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.factor.selected,
    };
  }

  render() {
    return (
      <div className="col-sm-6">
        <div
          id={`vibe-factor-${this.props.factor.id}`}
          className={`vibe-factor ${this._getCssClass()}`}
          onClick={() => this._toggle()}
        >
          {this.props.factor.name}
        </div>
      </div>
    );
  }

  _getCssClass() {
    if (this.state.selected) return 'selected';
    if (this.props.disabled) return 'disabled';
    return '';
  }

  _toggle() {
    if (this.state.selected) {
      this.props.removeFactor(this.props.factor);
      this.setState({ selected: false });
    } else if (!this.props.disabled) {
      this.props.addFactor(this.props.factor);
      this.setState({ selected: true });
    }
  }
}
