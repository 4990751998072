import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Modal } from 'react-bootstrap';

const ProgressModal = styled(Modal)`
  .modal-content {
    margin-top: 104px;
    max-width: 672px;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
    @media (max-width: 479px) {
      margin-top: 0;
    }
  }

  .modal-body {
    padding: 0;
  }
`;

const ProgressBoardModal = ({ showModal, hideModal, children }) => (
  <ProgressModal show={showModal} onHide={hideModal} backdrop keyboard>
    <div className="modal-content">
      <div className="modal-body">{children}</div>
    </div>
  </ProgressModal>
);

ProgressBoardModal.propTypes = {
  showModal: PropTypes.bool,
  hideModal: PropTypes.func,
};

export default ProgressBoardModal;
