import { Component } from 'react';

class Exif extends Component {
  componentDidMount() {
    // ---Global variable required for non-react croppie to work properly with images containing EXIF metadata
    window.EXIF = require('exif-js');
  }

  render() {
    return '';
  }
}

export default Exif;
