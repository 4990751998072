import qs from 'qs';
import { appendVideoThumbnail } from 'components/shared/fileUpload/generateFileFormData';
import { COMMENT_ACTION_TYPES, COMMENT_ACTIONS } from '../actions/commentActions';
import { APP_URL } from '../../constants';
import store from '../store';
import { CHECK_UNAUTHORIZED, HEADERS, OBJECT_TO_FORMDATA } from '../../helpers';
import { TAG_ACTIONS } from '../actions/tagActions';
import { GROW_ACTIONS } from '../actions/growActions';
import striptags from 'striptags';

const commentReducer = (state, action) => {
  const headers = HEADERS();
  let endpoint;
  let type;
  let postIndex;
  let newState; // ---Variables need to be declared before switch to avoid duplicate error
  switch (action.type) {
    case COMMENT_ACTION_TYPES.ADD_COMMENT:
      const commentType = action.payload.type === 'growPost' ? 'post' : action.payload.type;
      let data;
      switch (commentType) {
        case 'post':
          data = OBJECT_TO_FORMDATA({
            postId: action.payload.postId,
            content: action.payload.data.content,
          });
          break;
        case 'note':
          data = OBJECT_TO_FORMDATA({
            noteId: action.payload.postId,
            content: action.payload.data.content,
          });
          break;
        case 'win':
          data = OBJECT_TO_FORMDATA({
            winId: action.payload.postId,
            content: action.payload.data.content,
          });
          break;
        default:
          break;
      }

      endpoint = commentType === 'post' ? '' : `/${commentType}`;

      if (commentType === 'post') {
        for (let i = 0; i < action.payload.data.media.length; i++) {
          data.append('media[]', action.payload.data.media[i]);
        }
        const video = action.payload.data.media.length
          ? JSON.parse(action.payload.data.media[0])
          : null;
        if (video) {
          appendVideoThumbnail([video], data, 'media');
        }
      }

      fetch(`${APP_URL}/comment${endpoint}`, { method: 'post', headers, body: data })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            store.dispatch(
              COMMENT_ACTIONS.appendAddedComment(
                action.payload.postId,
                jsonResponse,
                action.payload.type,
                action.payload.commentAddedHandler
              )
            );
            if (commentType === 'post') {
              store.dispatch(TAG_ACTIONS.fetchHashtags());
            }
          });
        });

      return state;
    case COMMENT_ACTION_TYPES.APPEND_ADDED_COMMENT:
      newState = JSON.parse(JSON.stringify(state)); // ---Required for deep object cloning without references on lower levels - spread operator and Object.assign work only on top level
      if (action.payload.commentAddedHandler) {
        action.payload.commentAddedHandler(action.payload.json);
      }
      type = action.payload.type;
      switch (type) {
        case 'post':
          postIndex = state.postsDomain.posts.map(post => post.id).indexOf(action.payload.postId);
          if (postIndex > -1) {
            newState.postsDomain.posts[postIndex].comments.push(action.payload.json);
            newState.postsDomain.posts[postIndex].total_comments_count += 1;
          }
          if (newState.postsDomain.singlePost) {
            newState.postsDomain.singlePost.comments.push(action.payload.json);
            newState.postsDomain.singlePost.total_comments_count += 1;
          }
          return newState;
        case 'growPost':
          postIndex = state.growDomain.posts
            .map(post => (post.post ? post.post.id : null))
            .indexOf(action.payload.postId);
          if (postIndex >= 0) {
            newState.growDomain.posts[postIndex].post.comments.push(action.payload.json);
          }
          if (newState.growDomain.post && newState.growDomain.post.comments) {
            newState.growDomain.post.comments.push(action.payload.json);
            newState.growDomain.post.total_comments_count += 1;
          }
          return newState;
        case 'note':
        case 'win':
          postIndex = state.growDomain.posts
            .map(post => (post[type] ? post[type].id : null))
            .indexOf(action.payload.postId);
          if (postIndex >= 0) {
            newState.growDomain.posts[postIndex][type].comments.push(action.payload.json);
            newState.growDomain.posts[postIndex][type].total_comments_count += 1;
          }
          if (newState.growDomain.post && newState.growDomain.post.comments) {
            newState.growDomain.post.comments.push(action.payload.json);
            newState.growDomain.post.total_comments_count += 1;
          }
          return newState;
        default:
          return state;
      }
    case COMMENT_ACTION_TYPES.DELETE_COMMENT:
      fetch(`${APP_URL}/comment/${action.payload.commentId}`, { method: 'delete', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(() => {
          store.dispatch(
            COMMENT_ACTIONS.removeDeletedComment(
              action.payload.postId,
              action.payload.commentId,
              action.payload.type
            )
          );
          store.dispatch(TAG_ACTIONS.fetchHashtags());
        });
      return state;
    case COMMENT_ACTION_TYPES.REMOVE_DELETED_COMMENT:
      newState = JSON.parse(JSON.stringify(state));
      type = action.payload.type;
      switch (type) {
        case 'post':
          postIndex = state.postsDomain.posts.map(post => post.id).indexOf(action.payload.postId);
          if (postIndex > -1) {
            newState.postsDomain.posts[postIndex].comments = newState.postsDomain.posts[
              postIndex
            ].comments.filter(comment => comment.id !== action.payload.commentId);
            newState.postsDomain.posts[postIndex].total_comments_count -= 1;
          }
          if (newState.postsDomain.singlePost) {
            newState.postsDomain.singlePost.comments = newState.postsDomain.singlePost.comments.filter(
              comment => comment.id !== action.payload.commentId
            );
            newState.postsDomain.singlePost.total_comments_count -= 1;
          }
          return newState;
        case 'growPost':
          postIndex = state.growDomain.posts
            .map(post => (post.post ? post.post.id : null))
            .indexOf(action.payload.postId);
          if (postIndex > -1) {
            newState.growDomain.posts[postIndex].post.comments = newState.growDomain.posts[
              postIndex
            ].post.comments.filter(comment => comment.id !== action.payload.commentId);
            newState.growDomain.posts[postIndex].post.total_comments_count -= 1;
          }
          if (newState.growDomain.post && newState.growDomain.post.comments) {
            newState.growDomain.post.comments = newState.growDomain.post.comments.filter(
              comment => comment.id !== action.payload.commentId
            );
            newState.growDomain.post.total_comments_count -= 1;
          }
          return newState;
        case 'win':
        case 'note':
          postIndex = state.growDomain.posts
            .map(post => (post[type] ? post[type].id : null))
            .indexOf(action.payload.postId);
          if (postIndex > -1) {
            newState.growDomain.posts[postIndex][type].comments = newState.growDomain.posts[
              postIndex
            ][type].comments.filter(comment => comment.id !== action.payload.commentId);
            newState.growDomain.posts[postIndex][type].total_comments_count -= 1;
          }
          if (newState.growDomain.post && newState.growDomain.post.comments) {
            newState.growDomain.post.comments = newState.growDomain.post.comments.filter(
              comment => comment.id !== action.payload.commentId
            );
            newState.growDomain.post.total_comments_count -= 1;
          }
          return newState;
        default:
          return state;
      }
    case COMMENT_ACTION_TYPES.FETCH_COMMENTS:
      const queryString = qs.stringify({
        postId: action.payload.postId,
        loadedCount: action.payload.loadedCount,
      });
      type = action.payload.type;
      endpoint = type === 'post' || type === 'growPost' ? '' : `/${type}`;
      fetch(`${APP_URL}/comment${endpoint}?${queryString}`, { method: 'get', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            store.dispatch(
              COMMENT_ACTIONS.receiveComments(
                action.payload.postId,
                jsonResponse,
                action.payload.singlePost,
                type
              )
            );
          });
        });
      if (type === 'post') {
        return {
          ...state,
          postsDomain: {
            ...state.postsDomain,
            fetchingComments: true,
          },
        };
      }
      return {
        ...state,
        growDomain: {
          ...state.growDomain,
          fetchingComments: true,
        },
      };
    case COMMENT_ACTION_TYPES.RECEIVE_COMMENTS:
      newState = JSON.parse(JSON.stringify(state));
      type = action.payload.type;
      switch (type) {
        case 'post':
          if (action.payload.singlePost)
            newState.postsDomain.singlePost.comments = [
              ...action.payload.json,
              ...newState.postsDomain.singlePost.comments,
            ];
          else {
            postIndex = state.postsDomain.posts.map(post => post.id).indexOf(action.payload.postId);
            newState.postsDomain.posts[postIndex].comments = [
              ...action.payload.json,
              ...newState.postsDomain.posts[postIndex].comments,
            ];
          }
          newState.postsDomain.fetchingComments = false;
          return newState;
        case 'growPost':
          postIndex = state.growDomain.posts
            .map(post => (!post.post ? null : post.post.id))
            .indexOf(action.payload.postId);
          newState.growDomain.posts[postIndex].post.comments = [
            ...action.payload.json,
            ...newState.growDomain.posts[postIndex].post.comments,
          ];
          newState.growDomain.fetchingComments = false;
          return newState;
        case 'win':
        case 'note':
          postIndex = state.growDomain.posts
            .map(post => (!post[type] ? null : post[type].id))
            .indexOf(action.payload.postId);
          newState.growDomain.posts[postIndex][type].comments = [
            ...action.payload.json,
            ...newState.growDomain.posts[postIndex][type].comments,
          ];
          newState.growDomain.fetchingComments = false;
          return newState;
        default:
          return state;
      }
    case COMMENT_ACTION_TYPES.UPDATE_COMMENT:
      fetch(`${APP_URL}/comment/${action.payload.commentId}/save`, {
        method: 'post',
        headers,
        body: action.payload.data,
      })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            if (!action.payload.grow) {
              store.dispatch(COMMENT_ACTIONS.refreshUpdatedComment(jsonResponse));
            } else {
              jsonResponse.comment.content = striptags(jsonResponse.comment.content, ['br']);
              store.dispatch(COMMENT_ACTIONS.refreshGrowComment(jsonResponse));
              action.payload.receiveComment && action.payload.receiveComment(jsonResponse.comment);
            }
          });
        });
      return state;
    case COMMENT_ACTION_TYPES.REFRESH_UPDATED_COMMENT:
      const { content } = action.payload.json.comment;
      const { singlePost } = state.postsDomain;
      const { posts } = state.postsDomain;
      newState = JSON.parse(JSON.stringify(state));
      if (singlePost) {
        const commentIndex = singlePost.comments
          .map(comment => comment.id)
          .indexOf(action.payload.json.comment.id);
        if (commentIndex > -1) {
          newState.postsDomain.singlePost.comments[commentIndex].content = content;
        }
      }
      if (posts) {
        const postIndex = posts
          .map(post => post.id)
          .indexOf(action.payload.json.comment.postable_id);
        if (postIndex > -1) {
          const commentIndex = newState.postsDomain.posts[postIndex].comments
            .map(comment => comment.id)
            .indexOf(action.payload.json.comment.id);
          newState.postsDomain.posts[postIndex].comments[commentIndex].content = content;
        }
      }

      return newState;
    case COMMENT_ACTION_TYPES.REFRESH_GROW_COMMENT:
      newState = JSON.parse(JSON.stringify(state));
      if (state.growDomain.posts) {
        const { comment } = action.payload.json;
        const noteId = comment.note_id;
        const winId = comment.win_id;
        if (noteId) {
          const postIndex = state.growDomain.posts
            .map(post => (post.note ? post.note.id : null))
            .indexOf(noteId);
          if (postIndex > -1) {
            const commentIndex = newState.growDomain.posts[postIndex].note.comments
              .map(comment => comment.id)
              .indexOf(comment.id);
            newState.growDomain.posts[postIndex].note.comments[commentIndex].content =
              comment.content;
            newState.growDomain.posts[postIndex].note.comments[commentIndex].not_formatted_content =
              comment.not_formatted_content;
          }
        } else if (winId) {
          const postIndex = state.growDomain.posts
            .map(post => (post.win ? post.win.id : null))
            .indexOf(winId);
          if (postIndex > -1) {
            const commentIndex = newState.growDomain.posts[postIndex].win.comments
              .map(comment => comment.id)
              .indexOf(comment.id);
            newState.growDomain.posts[postIndex].win.comments[commentIndex].content =
              comment.content;
            newState.growDomain.posts[postIndex].win.comments[commentIndex].not_formatted_content =
              comment.not_formatted_content;
          }
        }
      }

      return newState;
    default:
      return state;
  }
};
export default commentReducer;
