import React, { Component } from 'react';
import VibeFlowContainer from '../VibeFlowContainer';

class CreateVibe extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="create-vibe-form">
        <div className="vibe_new">
          <VibeFlowContainer
            vibe_factors={this.props.vibe_factors}
            feel_factors={this.props.feel_factors}
            links={this.props.links}
            raw_text={this.props.raw_text}
            scale={this.props.scale}
            sentimentChanged=""
            images={this.props.images}
            enable_vibe_comments={this.props.enable_vibe_comments}
            hideCancelBar
            fetchPostsOnClose={this.props.fetchPostsOnClose}
            closePopup={this.props.closePopup}
          />
        </div>
      </div>
    );
  }
}

export default CreateVibe;
