import Dropdown, { DropdownWrap } from 'components/shared/Dropdown';
import React from 'react';
import { connect } from 'react-redux';
import { GROW_ACTIONS } from 'redux/actions/growActions';
import PropTypes from 'prop-types';
import { noteShape } from 'pages/Grow/GrowPost/ManagerPrompts/common';

const ManagerPromptDropdown = ({ note, dismissManagerPrompt }) => {
  return (
    <DropdownWrap>
      <Dropdown stopPropagation={false}>
        <div onClick={() => dismissManagerPrompt(note.id, note.action_plan.id)}>Dismiss</div>
      </Dropdown>
    </DropdownWrap>
  );
};

ManagerPromptDropdown.propTypes = {
  note: PropTypes.shape(noteShape).isRequired,
  dismissManagerPrompt: PropTypes.func.isRequired,
};

const mapDispatch = {
  dismissManagerPrompt: GROW_ACTIONS.dismissManagerPrompt,
};

export default connect(null, mapDispatch)(ManagerPromptDropdown);
