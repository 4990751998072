import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

class MentionNotificationsTooltip extends Component {
  render() {
    return (
      <ReactTooltip id={this.props.id} class="react-tooltip" place="top" type="dark" effect="solid">
        <span>
          If you’re mentioned in a post,
          <br />
          you will get a notification
          <br />
          on email and mobile.
        </span>
      </ReactTooltip>
    );
  }
}
export default MentionNotificationsTooltip;
