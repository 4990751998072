import React, { Component } from 'react';
import UserTile from './IssuesUserTile';
import Auxiliary from '../../layouts/Auxiliary';
import UserPlaceholder from '../setup_mode/UserPlaceholder';

class IssuesUserList extends Component {
  defaultDelayTime = 400;

  immediateDelayTime = 0;

  state = {
    dragOver: false,
    delayTime: this.defaultDelayTime,
    placeholderHeight: 0,
    placeholderWidth: 0,

    activeUnfolded: true,
    invitedUnfolded: true,
    pendingUnfolded: true,
  };

  render() {
    let userPlaceholder = null;
    if (this.props.dragHelperVariables.draggedUserDOM) {
      userPlaceholder = (
        <UserPlaceholder
          userNode={this.props.dragHelperVariables.draggedUserDOM}
          key={1}
          isMounted={this.state.dragOver}
          delayTime={this.state.delayTime}
        />
      );
    }

    return (
      <div className="team-members-list">
        <div
          className="drag-capture-area"
          onMouseEnter={() => {
            if (
              this.props.dragHelperVariables.draggedUserDOM &&
              this.props.team.users
                .map(user => user.id)
                .indexOf(this.props.dragHelperVariables.draggedUserData.id) === -1
            ) {
              this.setState({
                dragOver: true,
              });
              setTimeout(
                () =>
                  this.setState({
                    placeholderHeight: window
                      .getComputedStyle(this.props.dragHelperVariables.draggedUserDOM)
                      .getPropertyValue('height'),
                    placeholderWidth: window
                      .getComputedStyle(this.props.dragHelperVariables.draggedUserDOM)
                      .getPropertyValue('width'),
                  }),
                0
              );
            }
          }}
          onMouseLeave={() => {
            if (this.props.dragHelperVariables.draggedUserDOM) {
              this.setState({
                dragOver: false,
                delayTime: this.defaultDelayTime,
                placeholderHeight: 0,
              });
            }
          }}
          onMouseUp={() => {
            if (
              this.props.dragHelperVariables.draggedUserData &&
              this.props.team.users
                .map(user => user.id)
                .indexOf(this.props.dragHelperVariables.draggedUserData.id) === -1
            )
              this.props.moveUser(this.props.team, this.props.dragHelperVariables);
            this.setState({
              dragOver: false,
              delayTime: this.immediateDelayTime,
              placeholderHeight: 0,
            });
          }}
        />
        {this.props.team.users.length > 0 ? (
          <Auxiliary>
            {this.props.team.users.some(user => user.state === 'invited') ? (
              <div
                onClick={() => this.setState({ activeUnfolded: !this.state.activeUnfolded })}
                className={`user-state ${this.state.activeUnfolded ? 'unfolded' : ''}`}
              >
                Invited and active
              </div>
            ) : (
              ''
            )}
            {this.state.activeUnfolded
              ? this.props.team.users
                  .filter(user => user.state === 'active')
                  .sort((a, b) => (a.last_name.toLowerCase() > b.last_name.toLowerCase() ? 1 : -1))
                  .map(user => (
                    <UserTile
                      team={this.props.team}
                      key={user.id}
                      user={user}
                      markInvitedUsers={this.props.markInvitedUsers}
                      setDragHelperVariables={this.props.setDragHelperVariables}
                      dragHelperVariables={this.props.dragHelperVariables}
                      sendingInvitations={this.props.sendingInvitations}
                    />
                  ))
              : ''}
            {this.props.team.users.some(user => user.state === 'invited') ? (
              <div
                onClick={() => this.setState({ invitedUnfolded: !this.state.invitedUnfolded })}
                className={`user-state ${this.state.invitedUnfolded ? 'unfolded' : ''}`}
              >
                Invited, but not activated
              </div>
            ) : (
              ''
            )}
            {this.state.invitedUnfolded
              ? this.props.team.users
                  .filter(user => user.state === 'invited')
                  .sort((a, b) => (a.last_name.toLowerCase() > b.last_name.toLowerCase() ? 1 : -1))
                  .map(user => (
                    <UserTile
                      team={this.props.team}
                      key={user.id}
                      user={user}
                      markInvitedUsers={this.props.markInvitedUsers}
                      setDragHelperVariables={this.props.setDragHelperVariables}
                      dragHelperVariables={this.props.dragHelperVariables}
                      sendingInvitations={this.props.sendingInvitations}
                    />
                  ))
              : ''}
            {this.props.team.users.some(user => user.state === 'pending') ? (
              <div
                onClick={() => this.setState({ pendingUnfolded: !this.state.pendingUnfolded })}
                className={`user-state ${this.state.pendingUnfolded ? 'unfolded' : ''}`}
              >
                Not invited
              </div>
            ) : (
              ''
            )}
            {this.state.pendingUnfolded
              ? this.props.team.users
                  .filter(user => user.state === 'pending')
                  .sort((a, b) => (a.last_name.toLowerCase() > b.last_name.toLowerCase() ? 1 : -1))
                  .map(user => (
                    <UserTile
                      team={this.props.team}
                      key={user.id}
                      user={user}
                      markInvitedUsers={this.props.markInvitedUsers}
                      setDragHelperVariables={this.props.setDragHelperVariables}
                      dragHelperVariables={this.props.dragHelperVariables}
                      sendingInvitations={this.props.sendingInvitations}
                    />
                  ))
              : ''}
            {userPlaceholder}
          </Auxiliary>
        ) : (
          <div className="no-users-placeholder-wrap">
            {this.state.dragOver ? (
              <Auxiliary>
                {this.props.dragHelperVariables.draggedUsersDOM
                  ? this.props.dragHelperVariables.draggedUsersDOM.map((element, index) => (
                      <UserPlaceholder
                        userNode={element}
                        key={index}
                        isMounted={this.state.dragOver}
                        delayTime={this.state.delayTime}
                      />
                    ))
                  : ''}
              </Auxiliary>
            ) : (
              <div className="no-users-placeholder">
                <svg width="28" height="30" viewBox="0 0 28 30" fill="none">
                  <path
                    d="M13.2771 0.841835C13.2771 1.30951 13.6512 1.68365 14.1189 1.68365L21.7763 1.68365C23.859 1.68365 25.5489 3.37976 25.5489 5.45624L25.5489 23.9263C25.5489 26.0091 23.8528 27.6989 21.7763 27.6989L13.9942 27.6989C13.5265 27.6989 13.1523 28.0731 13.1523 28.5408C13.1523 29.0084 13.5265 29.3826 13.9942 29.3826L21.7763 29.3826C24.7881 29.3826 27.2325 26.9319 27.2325 23.9263L27.2325 5.45624C27.2325 2.44441 24.7819 1.66578e-05 21.7763 1.67892e-05L14.1189 1.71239e-05C13.6574 1.71441e-05 13.2771 0.374159 13.2771 0.841835Z"
                    fill="#11A9FF"
                  />
                  <path
                    d="M14.0062 19.4488L17.9222 15.5328L0.842684 15.5328C0.375007 15.5328 0.000865686 15.1587 0.000865666 14.691C0.000865645 14.2233 0.375007 13.8492 0.842683 13.8492L17.9222 13.8492L14.0062 9.93317C13.8441 9.77104 13.7568 9.55279 13.7568 9.34078C13.7568 9.12253 13.8379 8.91051 14.0062 8.74839C14.3367 8.41789 14.8667 8.41789 15.1972 8.74839L20.5474 14.0986C20.7033 14.2545 20.7969 14.4727 20.7969 14.691C20.7969 14.9155 20.7096 15.1275 20.5474 15.2834L15.1972 20.6336C14.8667 20.9641 14.3367 20.9641 14.0062 20.6336C13.682 20.3093 13.682 19.7731 14.0062 19.4488Z"
                    fill="#11A9FF"
                  />
                </svg>
                <span>Drag & drop your team members here</span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default IssuesUserList;
