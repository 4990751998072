/** @jsx jsx */
import React, { Component } from 'react';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  clearActionBody,
  setAction,
  setCurrentNoteModal,
  setShowActionModal,
  toggleNoteModal,
} from 'redux/app/actions';
import { DragDropContext } from 'react-beautiful-dnd';
import MyNote from 'pages/Grow/GrowPost/MyNote';
import { Wrapper } from 'pages/Grow/NoteLayout';
import { generateTitle } from 'components/vibes/createForms/CreateNote';
import { ReactComponent as BackButton } from 'pages/Grow/GrowPost/assets/back.svg';
import Loader from 'components/shared/Loader';
import { noScrollBar } from 'styles';
import { BODY_CLASSES, HEADERS, htmlspecialchars_decode } from 'helpers';
import { APP_URL } from 'constants.js';
import { shouldRenderBlankState } from 'pages/Grow/Utils/utils';
import BlankState from 'components/grow/blank_state/BlankState';
import GrowBoardBlankSpaceContent from 'pages/Grow/BlankSpaceContents/GrowBoardBlankSpaceContent';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';
import { convertManagerPromptToWin } from 'pages/Grow/GrowPost/ManagerPrompts/common';
import Column from './Column';
import initialData from './initial-data';
import ProgressBoardModal from './ProgressBoardModal';
import SharedNotesFilter from './SharedNotesFilter';
import TeamMemberSelect from './TeamMemberSelect';
import { handleEdit } from '../GrowPost/NoteDropdown/Convert';
import { ReactComponent as BoardView } from '../GrowProfile/boardView.svg';
import { growBoardNavigationList, growProfileUrl } from '../GrowProfile/constants';

const LoaderWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`;

const ViewTimelineButton = styled.button`
  display: flex;
  align-items: center;
  background: white;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 14px;
  box-shadow: 1px 4px 8px rgba(106, 106, 106, 0.12);
  padding: 12px 16px;
  margin-left: auto;
  margin-right: 40px;

  & svg {
    margin-right: 12px;
  }

  > span {
    display: none;
    @media (min-width: 680px) and (max-width: 767px) {
      display: block;
    }
    @media (min-width: 1390px) {
      display: block;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  overflow-x: visible;
  margin-right: 8px;
  @media (max-width: 768px) {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
`;

const CloseModalButton = styled.div`
  top: -14px;
  right: 8px;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  cursor: pointer;
  transform: translate(50%, -50%);

  &:before,
  &:after {
    display: block;
    content: '';
    height: 3px;
    width: 18px;
    position: absolute;
    left: 50%;
    top: 50%;
    background: #d5d5d5;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

const BackModalButton = styled.div`
  display: block;
  @media (min-width: 480px) {
    display: none;
  }
`;

const BackButtonStyled = styled(BackButton)`
  margin: 18px 25px;
`;

const ProgressBoardTitle = styled.h2`
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  margin: 25px 12px 15px 8px;
`;

const NotesCounter = styled.span`
  padding: 3px 7px 3px 6px;
  background: #ffffff;
  border-radius: 3px;
  font-size: 11px;
  line-height: 13px;
  font-weight: bold;
  color: #6a6a6a;
  position: relative;
  bottom: 4px;
  height: fit-content;
`;

const ProgressBoardHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ProgressBoardTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

const ProgressBoardFiltersWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 10px;
  @media (min-width: 768px) {
    padding: 0;
  }
`;

const StackedColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 0 8px;
  width: 320px !important;

  > div > div {
    min-width: 100%;
    min-height: 49vh;
    max-height: 49vh;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 8px 0;

    ${noScrollBar}
    :first-child {
      flex: 0 1 auto;
    }

    > div {
      margin: 0;
      min-height: 49vh;
      width: auto !important;
    }
  }

  @media (max-width: 768px) {
    flex: 0 0 auto;
  }
`;

const GrowBoardTypesFiltersList = styled.div`
  display: flex;
`;

const GrowBoardTypesFilter = styled.div``;
const FilterButton = styled.div`
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  outline: none;
  background: initial;
  padding: 0;
  cursor: pointer;
  color: ${props => (props.isSelected ? '#11A9FF' : '#6A6A6A')};
  font-weight: ${props => (props.isSelected ? 'bold' : 'normal')};
  @media (min-width: 768px) {
    margin-right: 30px;
  }
`;
const FilterIcon = styled.span`
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: ${props => (props.isSelected ? '#11a9ff' : 'white')};

  & svg {
    & rect,
    & path {
      fill: ${props => (props.isSelected ? 'white' : '#6A6A6A')};
    }
  }
`;
const FilterTitle = styled.div`
  font-size: 14px;
  display: none;
  @media (min-width: 680px) and (max-width: 767px) {
    display: block;
  }
  @media (min-width: 1390px) {
    display: block;
  }
`;

async function getProgressBoardData() {
  const headers = HEADERS();
  const response = await fetch(`${APP_URL}/progress_board`, { method: 'GET', headers });
  const data = await response.json();
  return data;
}

async function updateProgressBoardNote(id, status, postIds = []) {
  const headers = HEADERS();
  headers.append('Content-Type', 'application/json');
  const response = await fetch(`${APP_URL}/progress_board/post/${id}/${status}`, {
    body: JSON.stringify({ postIds }),
    method: 'PUT',
    headers,
  });
  if (response.ok) {
    const data = await response.json();
    return data;
  }
  return false;
}

async function removeProgressBoardNote(id) {
  const headers = HEADERS();
  const response = await fetch(`${APP_URL}/progress_board/post/${id}`, {
    method: 'DELETE',
    headers,
  });
  const data = await response.json();
  return data;
}

async function loadProgressBoardNote(id) {
  const headers = HEADERS();
  const response = await fetch(`${APP_URL}/progress_board/post/${id}`, { method: 'GET', headers });
  const data = await response.json();
  return data;
}

async function confirmProgressBoardNote(id) {
  const headers = HEADERS();
  const response = await fetch(`${APP_URL}/member_notes/confirm/${id}`, { method: 'PUT', headers });
  if (response.ok) {
    const data = await response.json();
    return data;
  }
  return false;
}

class ProgressBoardIndex extends Component {
  state = initialData;

  closeBlankState = () => this.setState({ showBlankState: false });

  closeNoteModal = () => {
    this.setState({ showNote: false });
  };

  componentDidMount() {
    BODY_CLASSES(['grow', 'index', 'jobvibe-future']);
    this.setState({ loading: true });
    getProgressBoardData().then(data => this.setState({ ...data, loading: false }));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.reloadProgressBoard !== this.props.reloadProgressBoard ||
      prevProps.hiddenSamplePosts !== this.props.hiddenSamplePosts
    ) {
      this.setState({ loading: true });
      getProgressBoardData().then(data =>
        this.setState({
          ...data,
          loading: false,
          showNote: false,
          post: null,
        })
      );
    }
  }

  onDragEnd = result => {
    const oldState = this.state;
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = this.state.columns[source.droppableId];
    const finish = this.state.columns[destination.droppableId];

    if (start === finish) {
      const newPostIds = Array.from(start.postIds);
      const draggableIdIndex = newPostIds.indexOf(draggableId);
      newPostIds.splice(draggableIdIndex, 1);
      newPostIds.splice(destination.index, 0, draggableId);
      const newColumn = {
        ...start,
        postIds: newPostIds,
      };

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn,
        },
      };

      this.setState(newState);

      updateProgressBoardNote(draggableId, destination.droppableId, newPostIds).then(data => {
        if (data) {
          this.setState(newState);
        } else {
          this.setState(oldState);
        }
      });
      return;
    }

    const startPostIds = Array.from(start.postIds);
    const draggableIdIndex = startPostIds.indexOf(draggableId);
    startPostIds.splice(draggableIdIndex, 1);
    const newStart = {
      ...start,
      postIds: startPostIds,
    };

    const finishPostIds = Array.from(finish.postIds);
    finishPostIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      postIds: finishPostIds,
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    this.setState(newState);

    updateProgressBoardNote(draggableId, destination.droppableId, finishPostIds).then(data => {
      if (data) {
        const notesCopy = JSON.parse(JSON.stringify(this.state.posts));
        const draggableIdIndex = notesCopy.findIndex(post => post.id === draggableId);
        notesCopy[draggableIdIndex] = data.post;
        const newState = {
          ...this.state,
          posts: notesCopy,
        };

        this.setState(newState, () => {
          if (
            data.post.note.status === 'resolved' &&
            data.post.note.owner_id === this.props.user.id &&
            data.post.type !== 'MeetingPreparationNote' &&
            data.post.type !== 'MeetingStepNote'
          ) {
            if (data.post.type.includes(noteTypes.managerPrompt)) {
              convertManagerPromptToWin(
                this.props.setAction,
                notesCopy[draggableIdIndex].note,
                notesCopy[draggableIdIndex].note.id
              );
            } else {
              const {
                content,
                goals,
                company_values,
                title,
                assignee_id,
                shared_with,
                shared_with_team,
                due_date,
                assignee,
                id,
                media,
                member_id,
                category,
                files,
                action_plan,
              } = notesCopy[draggableIdIndex].note;
              const formattedContent = content
                ? htmlspecialchars_decode(content).replace(/<br\s*[\/]?>/gi, '\n')
                : '';

              const isConvertingAssignedGoalToWin =
                data.post.note.type === noteTypes.goal && assignee_id;

              const sharedTeams = shared_with_team.map(team => ({
                value: `${team.id}_team`,
                id: team.id,
                label: team.name,
                image_url: team.image_url,
                title: '',
                type: 'team',
                thumbnail_details: team.thumbnail_details,
              }));

              const sharedUsers = shared_with.length
                ? shared_with.map(shared => ({
                    image: shared.image_url,
                    label: `${shared.first_name} ${shared.last_name}`,
                    title: generateTitle(shared.role, shared.team.name),
                    value: shared.id,
                    type: 'user',
                    id: shared.id,
                  }))
                : [];

              const assignedUsers = isConvertingAssignedGoalToWin
                ? [
                    {
                      value: `${assignee_id}_user`,
                      id: assignee_id,
                      label: `${assignee.first_name} ${assignee.last_name}`,
                      image: assignee.image_url,
                      title: generateTitle(assignee.role, assignee.team.name),
                      type: 'user',
                    },
                  ]
                : [];

              const sharedUsersTeams = isConvertingAssignedGoalToWin
                ? assignedUsers
                : sharedUsers.concat(sharedTeams);

              this.props.setAction('create-note-win', {
                id,
                title,
                media,
                oldFiles: files,
                noteRecipient: member_id,
                category,
                dueDate: due_date,
                content: formattedContent,
                companyValues: company_values.map(value => value.name),
                goals: goals.map(value => value.name),
                shareWith: sharedUsersTeams,
                assignTo:
                  assignee_id && !isConvertingAssignedGoalToWin
                    ? {
                        image: assignee.image_url,
                        label: `${assignee.first_name} ${assignee.last_name}`,
                        title: generateTitle(assignee.role, assignee.team.name),
                        value: assignee.id,
                      }
                    : '',
                isSharing: Boolean(shared_with.length || assignee_id),
                converting: 'win',
                withFollowupPost: action_plan?.factor,
              });
            }
            this.props.setShowActionModal(true);
          } else if (
            data.post.note.status === 'resolved' &&
            data.post.type === 'MeetingPreparationNote'
          ) {
            this.openProgressBoardPost(data.post.id);
          }
        });
      } else {
        this.setState(oldState);
      }
    });
  };

  addNoteClickHandle = progressStatus => {
    const { clearActionBody, setAction, setShowActionModal } = this.props;
    clearActionBody();
    setAction(`create-note-${progressStatus}`, { progressStatus });
    setShowActionModal(true);
  };

  removeNoteClickHandle = id => {
    this.setState({ loading: true });
    removeProgressBoardNote(id).then(() =>
      getProgressBoardData().then(data =>
        this.setState({
          ...data,
          loading: false,
          showNote: false,
          post: null,
        })
      )
    );
  };

  openProgressBoardPost = id => {
    loadProgressBoardNote(id).then(data => {
      const { note } = data.post;
      const {
        setAction,
        setShowActionModal,
        toggleNoteModal,
        user,
        setCurrentNoteModal,
      } = this.props;
      setCurrentNoteModal(note);

      if (note.type === noteTypes.meetingPreparation) {
        handleEdit(
          note,
          'edit',
          setAction,
          setShowActionModal,
          toggleNoteModal,
          null,
          false,
          null,
          user
        );
      } else if (note.type === noteTypes.managerPromptPreparation) {
        setAction('create-note-manager-prompt', {
          preparationNote: note,
        });
        setShowActionModal(true);
      } else {
        this.setState({ showNote: true, post: data.post, noteType: data.type });
      }
    });
  };

  confirmNote = (postId, noteId) => {
    confirmProgressBoardNote(noteId).then(data => {
      if (data && data.post !== null) {
        const notesCopy = JSON.parse(JSON.stringify(this.state.posts));
        const postIndex = notesCopy.findIndex(post => post.id === postId);
        if (postIndex >= 0) {
          notesCopy[postIndex] = data.post;
          this.setState({ posts: notesCopy });
        }
      }
    });
  };

  commentAddedHandler = comment => {
    const { post } = this.state;
    post.note.comments.push(comment);
    const postsCopy = JSON.parse(JSON.stringify(this.state.posts));
    postsCopy[postsCopy.findIndex(p => p.id === post.id)].note.total_comments_count++;
    this.setState({ post, posts: postsCopy });
  };

  commentDeletedHandler = commentId => {
    const { post } = this.state;
    const comments = post.note.comments.filter(comment => comment.id !== commentId);
    const postsCopy = JSON.parse(JSON.stringify(this.state.posts));
    postsCopy[postsCopy.findIndex(p => p.id === post.id)].note.total_comments_count--;
    postsCopy[postsCopy.findIndex(p => p.id === post.id)].note.comments = comments;
    post.note.comments = comments;
    post.note.total_comments_count -= 1;
    this.setState({ post, posts: postsCopy });
  };

  handleMemberFilterSelect = member => {
    this.setState({
      filteredUser: member ? { id: member.id, type: member.type, value: member.value } : null,
    });
  };

  openCreateModal = action => {
    this.props.clearActionBody();
    this.props.setAction(action);
    this.props.setShowActionModal(true);
  };

  changeTypeHandler = type => {
    if (this.state.filteredType === type) {
      return;
    }

    this.setState({ filteredType: type });
  };

  render() {
    const {
      loading,
      columnOrder,
      columns,
      showNote,
      post,
      hideSharedNotes,
      filteredUser,
      filteredType,
      teamMembers,
      showBlankState,
    } = this.state;
    const { showActionModal, history } = this.props;
    let { posts } = this.state;
    if (hideSharedNotes && posts.length > 0) {
      posts = posts.filter(
        p =>
          p.note.shared_with.length === 0 &&
          p.note.shared_with_team.length === 0 &&
          (!p.type.includes('Shared') ||
            (p.type.includes('Shared') &&
              p.note.type === 'Goal' &&
              p.note.assignee_id === this.props.user.id))
      );
    }
    if (filteredUser && posts.length > 0) {
      posts = posts.filter(p =>
        filteredUser.type == 'user'
          ? (p.note.assignee && p.note.assignee.id === filteredUser.id) ||
            (p.note.shared_with.length &&
              p.note.shared_with.filter(sharedUser => sharedUser.id === filteredUser.id).length >
                0) ||
            p.note.owner.id === filteredUser.id ||
            p.note.member_id === filteredUser.id
          : (p.note.shared_with_team.length &&
              p.note.shared_with_team.filter(sharedTeam => sharedTeam.id === filteredUser.id)
                .length > 0) ||
            p.note.team_id === filteredUser.id
      );
    }

    if (filteredType !== initialData.filteredType && posts.length > 0) {
      switch (filteredType) {
        case 'my-notes':
          posts = posts.filter(
            p => (p.type === 'MyNote' || p.type === 'SharedNote') && p.note.type !== 'Goal'
          );
          break;
        case 'meetings':
          posts = posts.filter(p => p.type === 'MeetingPreparationNote');
          break;
        case 'goals':
          posts = posts.filter(p => p.note.type === 'Goal');
          break;
      }
    }

    const stackedColumns = ['complete', 'not_complete'];

    const renderColumn = (columnId, isStacked = false) => {
      const column = columns[columnId];
      const p = [];
      column.postIds.map(postId => {
        const note = posts.find(pid => pid.id === postId);
        if (note) {
          p.push(note);
        }
      });

      const withWrap = node =>
        isStacked ? (
          <div
            css={css`
              position: relative;
            `}
          >
            {node}
          </div>
        ) : (
          node
        );

      return withWrap(
        <Column
          key={column.id}
          column={column}
          posts={p}
          openProgressBoardPost={this.openProgressBoardPost}
          addNoteClickHandle={this.addNoteClickHandle}
          removeNoteClickHandle={this.removeNoteClickHandle}
          confirmNote={this.confirmNote}
        />
      );
    };

    return (
      <>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <ProgressBoardHeaderWrapper
            css={css`
              @media (max-width: 500px) {
                display: block;
              }
            `}
          >
            {loading && (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            )}
            <ProgressBoardTitleWrapper>
              <ViewTimelineButton onClick={() => history.push(`/${growProfileUrl}`)}>
                <BoardView /> <span>Timeline view</span>
              </ViewTimelineButton>
              <GrowBoardTypesFiltersList>
                {growBoardNavigationList.map(type => {
                  return (
                    <GrowBoardTypesFilter
                      key={type.id}
                      onClick={() => this.changeTypeHandler(type.filter)}
                    >
                      <FilterButton isSelected={type.filter === filteredType}>
                        <FilterIcon isSelected={type.filter === filteredType}>
                          <type.icon />
                        </FilterIcon>
                        <FilterTitle>{type.title}</FilterTitle>
                      </FilterButton>
                    </GrowBoardTypesFilter>
                  );
                })}
              </GrowBoardTypesFiltersList>
            </ProgressBoardTitleWrapper>
            <ProgressBoardFiltersWrapper
              css={css`
                @media (max-width: 500px) {
                  margin-left: 9px;
                }
              `}
            >
              <SharedNotesFilter
                label="Hide shared notes"
                checked={hideSharedNotes}
                filter={() => this.setState({ hideSharedNotes: !hideSharedNotes })}
              />
              <TeamMemberSelect
                onSelect={this.handleMemberFilterSelect}
                selectedMember={filteredUser?.value}
                teamMembers={teamMembers}
              />
            </ProgressBoardFiltersWrapper>
          </ProgressBoardHeaderWrapper>
          {shouldRenderBlankState(showBlankState, this.state.posts, this.state.loading) && (
            <BlankState
              closeBlankState={this.closeBlankState}
              Component={GrowBoardBlankSpaceContent}
              openModal={this.openCreateModal}
              shouldDisplaySidePlaceholder={false}
              blankStateProps={{ width: 860 }}
            />
          )}
          <Container>
            {columnOrder.filter(c => !stackedColumns.includes(c)).map(c => renderColumn(c, false))}
            <StackedColumn>
              <div
                css={css`
                  position: sticky;
                  top: 0;
                `}
              >
                {stackedColumns.map(c => renderColumn(c, true))}
              </div>
            </StackedColumn>
          </Container>
        </DragDropContext>
        <ProgressBoardModal
          showModal={showNote && !showActionModal}
          hideModal={this.closeNoteModal}
        >
          {post && (
            <Wrapper style={{ marginBottom: 0, border: 'none' }}>
              <CloseModalButton onClick={this.closeNoteModal} />
              <BackModalButton onClick={this.closeNoteModal}>
                <BackButtonStyled />
              </BackModalButton>
              <MyNote
                note={post.note}
                type={post.type}
                postId={post.id}
                exportModeOn={false}
                isPostSelected={false}
                addToProgressBoardEnabled={false}
                commentAddedHandler={this.commentAddedHandler}
                commentDeletedHandler={this.commentDeletedHandler}
                progressStatus={post.progress_status}
                preparationNote={post.preparation_note}
              />
            </Wrapper>
          )}
        </ProgressBoardModal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  showActionModal: state.app.showActionModal,
  reloadProgressBoard: state.growDomain.reloadProgressBoard,
  hiddenSamplePosts: state.usersDomain.user.hideSamplePosts,
});

const mapDispatch = {
  clearActionBody,
  setAction,
  setShowActionModal,
  toggleNoteModal,
  setCurrentNoteModal,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(ProgressBoardIndex));
