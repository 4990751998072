import React, { useState } from 'react';
import { ReactComponent as Assign } from 'components/vibes/createForms/partials/assign.svg';
import { ReactComponent as Edit } from 'components/vibes/createForms/partials/edit.svg';
import { ReactComponent as Close } from 'components/vibes/createForms/partials/close.svg';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import AssignPopupContent from 'components/vibes/createForms/partials/AssignPopupContent';
import moment from 'moment';

const AssignContainer = styled.div`
  position: relative;
`;

const AssignButton = styled.button`
  background-color: initial;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  color: #11a9ff;
  outline: none;
`;

const EditButton = styled.button`
  white-space: nowrap;
  background-color: initial;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  color: #a6a6a6;
  outline: none;
`;

const ClearButton = styled.button`
  display: flex;
  white-space: nowrap;
  background-color: initial;
  border: none;
  color: #a6a6a6;
  outline: none;
`;

const TextWrapper = styled.span`
  margin-left: 12px;
`;

const EditTextWrapper = styled.span`
  margin-right: 12px;
`;

const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AssignPopup = ({ spaceMembers, allTeams, userProps, step, submitAssign, clearAssign }) => {
  const [isAssignPopupOpen, enableAssignPopup] = useState(false);

  const getSelectRecipientsOptions = () => {
    let recipients = spaceMembers.filter(user => user.id !== userProps.id);
    if (recipients.length > 0) {
      recipients.sort((recipientA, recipientB) => {
        const nameA = recipientA.name ? recipientA.name.toLowerCase() : '';
        const nameB = recipientB.name ? recipientB.name.toLowerCase() : '';
        if (nameA < nameB) {
          return -1;
        }
        if (nameA >= nameB) {
          return 1;
        }
        return 0;
      });

      recipients = recipients.map(recipient => ({
        value: recipient.id,
        name: recipient.name,
        label: `${recipient.name} ${
          typeof recipient.role !== 'undefined' ? `(${recipient.slug})` : ''
        }`,
      }));
    }
    return recipients;
  };

  const recipients = [
    { label: 'Assign to myself', name: userProps.name, value: userProps.id },
    ...getSelectRecipientsOptions(),
  ];
  const cancelHandling = () => {
    enableAssignPopup(false);
  };

  const submitAssignHandler = (assignedUser, date) => {
    submitAssign(step, assignedUser, date);
    enableAssignPopup(false);
  };

  return (
    <div>
      <AssignContainer>
        {!step.assign ? (
          <AssignButton type="button" onClick={() => enableAssignPopup(true)}>
            <Assign /> <TextWrapper>Assign</TextWrapper>
          </AssignButton>
        ) : (
          <EditButtonWrapper>
            <EditButton onClick={() => enableAssignPopup(true)}>
              <EditTextWrapper>
                {step.assign.user.name}
                {step.assign.date && <span>, {moment(step.assign.date).format('MMMM DD')}</span>}
              </EditTextWrapper>
              <Edit />
            </EditButton>
            <ClearButton onClick={() => clearAssign(step.id)}>
              <Close />
            </ClearButton>
          </EditButtonWrapper>
        )}
        {isAssignPopupOpen && (
          <AssignPopupContent
            options={recipients}
            cancelHandling={cancelHandling}
            step={step}
            submitAssignHandler={submitAssignHandler}
          />
        )}
      </AssignContainer>
    </div>
  );
};

const mapStateToProps = state => ({
  userProps: state.usersDomain.user,
  allTeams: state.teamsDomain.allTeams,
  spaceMembers: state.usersDomain.usersForShoutoutModal,
});

export default connect(mapStateToProps)(AssignPopup);
