import React from 'react';
import styled from '@emotion/styled';
import { AWS_S3_URL } from 'constants.js';

export const icons = {
  clipIconToggled: 'clip-icon-blue.svg',
  clipIcon: 'clip-icon-v2.svg',
  mentionIconToggled: 'mention-icon-blue.svg',
  mentionIcon: 'mention-icon-v2.svg',
  mediaIconToggled: 'media-icon-blue.svg',
  mediaIcon: 'media-icon-v2.svg',
  driveIcon: 'drive-icon-v2.svg',
  pollIcon: 'Poll.svg',
  pollIconToggled: 'PollSelected.svg',
  megaphone: 'megaphone-v2.svg',
  sunglasses: 'sunglasses-v2.svg',
};

const HiddenImage = styled.img`
  display: none;
`;
// component can return array of elements
const PreloadIcons = () =>
  Object.values(icons).map(src => (
    <HiddenImage key={src} src={`${AWS_S3_URL}public/images/icons/${src}`} alt="" />
  ));

export default PreloadIcons;
