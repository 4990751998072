import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { APP_URL } from '../../../constants';
import { BODY_CLASSES, CHECK_UNAUTHORIZED, LOCALSTORAGE_GET } from '../../../helpers';
import InvoicesForm from './InvoicesForm';

class InvoicesEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      invoice: {},
    };
  }

  componentDidMount() {
    BODY_CLASSES(['offline_invoices', 'edit']);
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);
    fetch(APP_URL + this.props.location.pathname, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({
            accounts: jsonResponse.accounts,
            invoice: jsonResponse.invoice,
          });
        });
      })
      .catch(() => {});
  }

  sendForm(data) {
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);
    fetch(APP_URL + this.props.location.pathname, { method: 'post', headers, body: data })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({ redirect: jsonResponse.redirect });
        });
      })
      .catch(() => {});
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to="/admin/offline_invoices" />;
    }
    return (
      <div>
        <h1>New Offline Invoice</h1>
        {this.state.accounts.length > 0 ? (
          <InvoicesForm
            accounts={this.state.accounts}
            sendForm={this.sendForm.bind(this)}
            invoice={this.state.invoice}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default InvoicesEdit;
