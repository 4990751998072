import { connect } from 'react-redux';
import React from 'react';
import TrialUpgradePrompt from 'components/payments/prompts/objects/TrialUpgradePrompt';
import PlanUpgradedPrompt from 'components/payments/prompts/objects/PlanUpgradedPrompt';
import PlanDowngradedPrompt from 'components/payments/prompts/objects/PlanDowngradedPrompt';
import GrowLimitPrompt from 'components/payments/prompts/objects/GrowLimitPrompt';
import FeaturePrompt from 'components/payments/prompts/objects/FeaturePrompt';
import ShoutoutPrompt from 'components/payments/prompts/objects/ShoutoutPrompt';
import InsightsPrompt from 'components/payments/prompts/objects/InsightsPrompt';

const getComponent = type => {
  switch (type) {
    case 'TrialUpgradePrompt':
      return TrialUpgradePrompt;
    case 'PlanUpgradedPrompt':
      return PlanUpgradedPrompt;
    case 'PlanDowngradedPrompt':
      return PlanDowngradedPrompt;
    case 'GrowLimitPrompt':
      return GrowLimitPrompt;
    case 'ShoutoutPrompt':
      return ShoutoutPrompt;
    case 'FeaturePrompt':
      return FeaturePrompt;
    case 'InsightsPrompt':
      return InsightsPrompt;
    default:
      return null;
  }
};

const PromptContainer = ({ prompt }) => {
  if (!prompt) return null;
  const Component = getComponent(prompt.type);

  return !Component ? null : <Component prompt={prompt} />;
};

const mapState = state => ({
  prompt: state.plansDomain.prompt,
});

export default connect(mapState)(PromptContainer);
