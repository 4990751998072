/** @jsx jsx */
import Prompt, { promptColors } from 'components/payments/prompts/Prompt';
import { jsx } from '@emotion/core';
import React from 'react';
import UpgradeButton from 'components/payments/prompts/UpgradeButton';

const ShoutoutPrompt = ({ prompt }) => {
  // TODO: add to backend notifications, add format when out of shoutouts
  return (
    <Prompt
      color={promptColors.yellow}
      title={"You're almost out of shoutouts for this month"}
      content={
        "When you reach your limit you won't be able to give any more shoutouts until next month. Upgrade to Connect & Perform Pro to get additional shoutouts every month (1 per every team member you manager)."
      }
      button={hide => <UpgradeButton hide={hide} />}
    />
  );
};

export default ShoutoutPrompt;
