import { APP_URL } from 'constants.js';
import { CHECK_UNAUTHORIZED, HEADERS } from 'helpers';
import { setAction, setShowActionModal } from 'redux/app/actions';
import { FEED_STEPS } from 'onboardingSteps';
import store from '../store';
import { ONBOARDING_ACTIONS, ONBOARDING_ACTION_TYPES } from '../actions/onboardingActions';

const onboardingReducer = (state, action) => {
  const headers = HEADERS();

  switch (action.type) {
    case ONBOARDING_ACTION_TYPES.START_FEED_TOUR:
      if (state.usersDomain.feedEnabled) {
        fetch(`${APP_URL}/feed_tour`, {
          method: 'get',
          headers,
        })
          .then(response => CHECK_UNAUTHORIZED(response))
          .then(response => {
            response.json().then(json => {
              if (
                ['giveGetFeedbackStep', 'createDiscussionStep'].includes(
                  FEED_STEPS[action.payload]?.key
                )
              ) {
                store.dispatch(setAction('create-discussion'));
                store.dispatch(setShowActionModal(true));
              }
              store.dispatch(ONBOARDING_ACTIONS.toggleTourModal(false));
              store.dispatch(ONBOARDING_ACTIONS.setGrowTourStep(-1));
              store.dispatch(ONBOARDING_ACTIONS.setInsightsTourStep(-1));
              store.dispatch(ONBOARDING_ACTIONS.setFeedTourStep(action.payload));
              store.dispatch(ONBOARDING_ACTIONS.receiveOnboardingSpace(json));
            });
          });
      }
      return state;
    case ONBOARDING_ACTION_TYPES.START_GROW_TOUR:
      if (state.usersDomain.growEnabled) {
        fetch(`${APP_URL}/grow_tour`, {
          method: 'get',
          headers,
        })
          .then(response => CHECK_UNAUTHORIZED(response))
          .then(response => {
            response.json().then(json => {
              store.dispatch(ONBOARDING_ACTIONS.toggleTourModal(false));
              store.dispatch(ONBOARDING_ACTIONS.setInsightsTourStep(-1));
              store.dispatch(ONBOARDING_ACTIONS.setFeedTourStep(-1));
              store.dispatch(ONBOARDING_ACTIONS.setGrowTourStep(action.payload));
              store.dispatch(ONBOARDING_ACTIONS.receiveOnboardingUser(json));
            });
          });
      }
      return state;
    case ONBOARDING_ACTION_TYPES.START_INSIGHTS_TOUR:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          showTourModal: false,
          displayOnboardingModal: false,
          insightsTourFinished: false,
          growTourStep: -1,
          feedTourStep: -1,
          insightsTourStep: action.payload,
        },
      };
    case ONBOARDING_ACTION_TYPES.START_SETUP_TOUR:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          showTourModal: false,
          growTourStep: -1,
          feedTourStep: -1,
          insightsTourStep: -1,
          setupTourStep: action.payload,
        },
      };
    case ONBOARDING_ACTION_TYPES.RESET_TOURS:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          growTourStep: -1,
          feedTourStep: -1,
          insightsTourStep: -1,
          setupTourStep: -1,
        },
      };
    case ONBOARDING_ACTION_TYPES.RECEIVE_ONBOARDING_USER:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          onboardingUser: action.payload.user,
        },
      };
    case ONBOARDING_ACTION_TYPES.RECEIVE_ONBOARDING_SPACE:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          onboardingSpace: action.payload.space,
        },
      };
    case ONBOARDING_ACTION_TYPES.SET_FEED_TOUR_STEP:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          feedTourStep: action.payload,
        },
      };
    case ONBOARDING_ACTION_TYPES.SET_INSIGHTS_TOUR_STEP:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          insightsTourStep: action.payload,
        },
      };
    case ONBOARDING_ACTION_TYPES.SET_GROW_TOUR_STEP:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          growTourStep: action.payload,
        },
      };
    case ONBOARDING_ACTION_TYPES.SET_SETUP_TOUR_STEP:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          setupTourStep: action.payload,
        },
      };
    case ONBOARDING_ACTION_TYPES.TOGGLE_TOUR_MODAL:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          showTourModal: action.payload,
        },
      };
    case ONBOARDING_ACTION_TYPES.SET_FINISHED_TOUR:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          finishedTour: action.payload,
        },
      };
    case ONBOARDING_ACTION_TYPES.FINISH_STEP:
      fetch(`${APP_URL}/onboarding/${action.payload}`, {
        method: 'post',
        headers,
      })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            store.dispatch(ONBOARDING_ACTIONS.receiveFinishedTours(json.onboardingData));
          });
        });
      return state;
    case ONBOARDING_ACTION_TYPES.RECEIVE_FINISHED_TOURS:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          feedTourFinished: action.payload.spaces,
          insightsTourFinished: action.payload.feedback,
          growTourFinished: action.payload.meetings,
          displayOnboardingModal: false,
        },
      };
    case ONBOARDING_ACTION_TYPES.POSTPONE_TOUR:
      fetch(`${APP_URL}/onboarding/postpone`, {
        method: 'get',
        headers,
      })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(() => {
            store.dispatch(ONBOARDING_ACTIONS.receivePostponeTour());
          });
        });
      return state;
    case ONBOARDING_ACTION_TYPES.RECEIVE_POSTPONE_TOUR:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          displayOnboardingModal: false,
        },
      };
    case ONBOARDING_ACTION_TYPES.REMOVE_SAMPLE_TEAM:
      fetch(`${APP_URL}/onboarding/sample_team`, {
        method: 'post',
        headers,
      })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            store.dispatch(ONBOARDING_ACTIONS.setRemoveSampleTeam(json));
          });
        });
      return state;
    case ONBOARDING_ACTION_TYPES.SET_REMOVE_SAMPLE_TEAM:
      let teams = state.teamsDomain.teams.map(value => ({ ...value }));
      if (action.payload.hide) {
        teams = teams.filter(team => team.id !== action.payload.team.id);
      } else {
        teams.push(action.payload.team);
      }
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user: {
            ...state.usersDomain.user,
            hideSampleTeam: action.payload.hide,
            recentSpaces: action.payload.recentTeams,
          },
        },
        teamsDomain: {
          ...state.teamsDomain,
          teams,
        },
      };
    case ONBOARDING_ACTION_TYPES.SHOW_MEETING_MODAL:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          displayMeetingModal: true,
          settings: action.payload.settings,
          stopShowingButton: action.payload.stopShowingButton,
        },
      };
    case ONBOARDING_ACTION_TYPES.CLOSE_MEETING_MODAL:
      fetch(`${APP_URL}/onboarding/meeting?permanently=${action.payload}`, {
        method: 'post',
        headers,
      })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(() => {
            store.dispatch(ONBOARDING_ACTIONS.handleCloseMeetingModal());
          });
        });
      return state;
    case ONBOARDING_ACTION_TYPES.HANDLE_CLOSE_MEETING_MODAL:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          displayMeetingModal: false,
          settings: [],
        },
      };
    case ONBOARDING_ACTION_TYPES.RESET_SETTINGS:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          settings: undefined,
        },
      };
    case ONBOARDING_ACTION_TYPES.SET_WAS_USER_ADDED:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          wasUserAdded: action.payload,
        },
      };
    case ONBOARDING_ACTION_TYPES.SET_WAS_TEAM_ADDED:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          wasTeamAdded: action.payload,
        },
      };
    case ONBOARDING_ACTION_TYPES.SET_WAS_USER_MOVED:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          wasUserMoved: action.payload,
        },
      };
    case ONBOARDING_ACTION_TYPES.SET_WAS_COMPANY_STRUCTURE_FETCHED:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          wasCompanyStructureFetched: action.payload,
        },
      };
    case ONBOARDING_ACTION_TYPES.SET_WAS_ADD_TEAM_BUTTON_CLICKED:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          wasAddTeamButtonClicked: action.payload,
        },
      };
    case ONBOARDING_ACTION_TYPES.TOGGLE_ADD_CHILD_TEAM:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          addChildTeamState: action.payload,
        },
      };
    case ONBOARDING_ACTION_TYPES.SET_MEETING_SETTINGS:
      return {
        ...state,
        onboardingDomain: {
          ...state.onboardingDomain,
          settings: action.payload,
        },
      };
    default:
      return state;
  }
};
export default onboardingReducer;
