import axios from 'axios';
import { APP_URL } from './constants';
import { LOCALSTORAGE_GET } from './helpers';

const instance = axios.create({
  baseURL: APP_URL,
  headers: { Accept: 'application/json' },
});
instance.interceptors.request.use(
  config => {
    const token = LOCALSTORAGE_GET('access_token');
    if (token) config.headers.Authorization = `Bearer ${token}`;

    const impersonateToken = LOCALSTORAGE_GET('admin_access_token');
    if (impersonateToken)
      config.headers['Impersonator-Authorization'] = `Bearer ${impersonateToken}`;

    return config;
  },
  error => Promise.reject(error)
);
export default instance;
