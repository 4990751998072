import React from 'react';
import { EVENTS, STATUS } from 'react-joyride';
import { setAction, setShowActionModal } from 'redux/app/actions';
import { connect } from 'react-redux';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import { FEED_STEPS } from 'onboardingSteps';
import { POST_ACTIONS } from 'redux/actions/postActions';
import JoyrideWrapper from './JoyrideWrapper';

class FeedTour extends React.Component {
  state = {
    run: true,
    steps: FEED_STEPS,
  };

  componentDidMount() {
    const { steps } = this.state;
    this.finishFeedStep(this.props.feedTourStep);
    if (steps[this.props.feedTourStep]?.key === 'giveGetFeedbackStep') {
      this.setState(
        {
          run: false,
        },
        () => {
          setTimeout(() => {
            this.setState({
              run: true,
            });
          }, 500);
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { run, steps } = this.state;
    const { isMember } = this.props;

    if (
      steps[this.props.feedTourStep]?.key === 'clickPlusBtnStep' &&
      this.props.showActionModal !== prevProps.showActionModal &&
      this.props.showActionModal === true
    ) {
      this.props.setAction('create-post');
      this.setState({
        run: false,
      });
      this.props.setFeedTourStep(this.props.feedTourStep + 1);
      setTimeout(() => {
        this.setState({
          run: true,
        });
      }, 500);
    }
    if (
      this.props.feedTourStep !== prevProps.feedTourStep &&
      ['sharePostStep', 'giveGetFeedbackStep', 'createDiscussionStep'].includes(
        steps[this.props.feedTourStep]?.key
      )
    ) {
      this.props.setShowActionModal(true);
    }
    if (
      steps[this.props.feedTourStep]?.key === 'createDiscussionStep' &&
      this.props.showActionModal === false
    ) {
      this.setState({ run: false });
      this.props.setFeedTourStep(-1);
    }
    if (
      steps[this.props.feedTourStep]?.key === 'giveGetFeedbackStep' &&
      this.props.feedTourStep !== prevProps.feedTourStep &&
      this.props.action !== prevProps.action &&
      this.props.action === 'create-shoutout'
    ) {
      setTimeout(() => {
        this.props.setAction('create-poll');
      }, 1000);
      !isMember &&
        setTimeout(() => {
          this.props.setAction('create-questionnaire');
        }, 2000);
      setTimeout(
        () => {
          this.props.setAction('create-discussion');
        },
        !isMember ? 3000 : 2500
      );
    }
    if (steps[this.props.feedTourStep]?.key === 'createSpaceBtnStep') {
      this.props.setShowActionModal(false);
    }
    if (this.props.feedTourStep !== prevProps.feedTourStep) {
      this.finishFeedStep(this.props.feedTourStep);
    }
  }

  finishFeedStep = step => {
    const { steps } = this.state;
    const stepName = steps[step]?.key;
    switch (stepName) {
      case 'initialFeedStep':
        this.props.finishStep('home_feed');
        break;
      case 'clickPlusBtnStep':
        this.props.finishStep('new_content');
        break;
      case 'giveGetFeedbackStep':
        this.props.finishStep('post');
        break;
      case 'createSpaceBtnStep':
        this.props.finishStep('spaces');
        break;
    }
  };

  handleJoyrideCallback = data => {
    const { action, index, status, type } = data;
    const { steps } = this.state;
    if (action !== 'close' && [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (steps[index].key === 'sharePostStep') {
        this.props.setAction('create-shoutout');
        this.props.setFeedTourStep(index + 1);
      } else if (steps[index]?.key === 'giveGetFeedbackStep') {
        this.props.setAction('create-discussion');
        this.props.setFeedTourStep(index + 1);
      } else {
        this.props.setFeedTourStep(index + 1);
      }
    } else if (action === 'close' || [STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ run: false });
      this.props.setFeedTourStep(-1);
      if (status === STATUS.FINISHED) {
        this.props.setFinishedTour('feed');
        this.props.toggleTourModal(true);
      }
      if (this.props.hideSamplePosts) {
        this.props.resetPosts();
        this.props.fetchPosts();
      }
    }
  };

  render() {
    const { steps, run } = this.state;

    return (
      <JoyrideWrapper
        run={run}
        stepIndex={this.props.feedTourStep}
        steps={steps}
        callback={this.handleJoyrideCallback}
      />
    );
  }
}

const mapStateToProps = state => ({
  action: state.app.action,
  showActionModal: state.app.showActionModal,
  feedTourStep: state.onboardingDomain.feedTourStep,
  hideSamplePosts: state.usersDomain.user.hideSamplePosts,
  isMember:
    !!state.usersDomain.user &&
    !state.usersDomain.user.isAccountAdmin &&
    !state.usersDomain.user.formalLeadTeamsIds.length > 0,
});

const mapDispatch = {
  setAction,
  setShowActionModal,
  setFeedTourStep: step => ONBOARDING_ACTIONS.setFeedTourStep(step),
  toggleTourModal: toggle => ONBOARDING_ACTIONS.toggleTourModal(toggle),
  setFinishedTour: tour => ONBOARDING_ACTIONS.setFinishedTour(tour),
  finishStep: step => ONBOARDING_ACTIONS.finishStep(step),
  resetPosts: () => POST_ACTIONS.resetPosts(),
  fetchPosts: () => POST_ACTIONS.fetchPosts(),
};

export default connect(mapStateToProps, mapDispatch)(FeedTour);
