/** @jsx jsx */
import ShareProfile from 'components/vibes/createForms/CreateNote/ShareProfile';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import OptionButton from 'components/vibes/createForms/partials/OptionButton';
import FlipSelect from 'components/shared/FlipSelect';

const Body = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  @media (max-width: 600px) {
    flex-direction: column-reverse;
    > div {
      margin-bottom: 10px;
    }
  }
`;

const SelectWrap = styled.div`
  width: 247px;
  .Select {
    width: 100% !important;
    margin-bottom: 0 !important;
    .Select-control {
      background: #f3f3f3 !important;
    }
    .Select-option {
      background-color: #f3f3f3;
    }
  }
  .Select-placeholder {
    color: #7b7b7b !important;
  }
`;

const Line = styled.div`
  height: 1px;
  background: #f3f3f3;
  margin: 0 20px;
`;

const ShareBottomBar = props => {
  const { isSharing, shareWith, setShareWith, mode, leads, assignTo, index = 0 } = props;
  const [isAnother, setIsAnother] = useState(false);

  useEffect(() => {
    if (shareWith.length > index + 1) setIsAnother(true);
    else setIsAnother(false);
  }, [shareWith]);

  const sharedValue = shareWith.length > index && shareWith[index].value;
  if (assignTo) {
    return <Body />;
  }
  return (
    <>
      <Line />
      <Body>
        {isSharing &&
          !assignTo &&
          (!sharedValue ? (
            <SelectWrap>
              <FlipSelect
                options={leads}
                inputProps={{ id: 'shoutout-for-input' }}
                value={sharedValue}
                isSearchable
                onChange={user => setShareWith([...shareWith, user])}
                clearable={false}
                className="select-component recipients orange"
                placeholder="Share with..."
                disabled={false}
              />
            </SelectWrap>
          ) : (
            <>
              {sharedValue && (
                <ShareProfile
                  user={shareWith[index]}
                  isAssignee={!sharedValue}
                  mode={mode}
                  css={css`
                    margin-left: 5px;
                  `}
                />
              )}
              {!isAnother && (
                <OptionButton
                  onClick={() => setIsAnother(true)}
                  label="Add another person"
                  type="gray2"
                  css={css`
                    margin-left: 25px;
                  `}
                />
              )}
            </>
          ))}
      </Body>
      {isAnother && !assignTo && (
        <ShareBottomBar
          {...props}
          index={index + 1}
          leads={leads.filter(lead => lead.value !== sharedValue)}
        />
      )}
    </>
  );
};

export default ShareBottomBar;
