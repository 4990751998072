import { Link } from 'react-router-dom';
import React from 'react';
import styled from '@emotion/styled';
import { AWS_S3_URL } from 'constants.js';
import { ReactComponent as Visa } from './visa.svg';
import { ReactComponent as MasterCard } from './mastercard.svg';
import { ReactComponent as AMEX } from './amex.svg';

const CardSummary = styled.div`
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.12);
  padding: 21px;
  text-align: left;
`;

const Name = styled.div`
  font-size: 14px;
  line-height: 16px;
`;

const CardNumber = styled.div`
  font-size: 13px;
  margin-bottom: 14px;
`;

const Dot = styled.span`
  width: 6px;
  height: 6px;
  margin-right: 5px;
  background-color: #d5d5d5;
  display: inline-block;
  border-radius: 50%;
  &:nth-of-type(4n) {
    margin-right: 16px;
  }
`;

const BottomRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 21px;
`;

const StyledLink = styled(Link)`
  color: #11a9ff;
  font-size: 13px;
  line-height: 15px;
  margin-left: auto;
`;

const cards = {
  Visa,
  MasterCard,
  'American Express': AMEX,
};

const CreditCard = ({
  lastFourDigits,
  name,
  brand = '',
  editLinkTo = '/',
  editOnClick = () => null,
  editText = 'Edit this card',
  ...props
}) => {
  const Brand = cards[brand];
  return (
    <CardSummary className=" u-size0" {...props}>
      <CardNumber>
        {[...Array(12)].map(() => (
          <Dot />
        ))}
        {lastFourDigits}
      </CardNumber>
      <Name>{name}</Name>
      <BottomRow>
        {Brand && <Brand />}
        <StyledLink to={editLinkTo} onClick={editOnClick}>
          {editText}
        </StyledLink>
      </BottomRow>
    </CardSummary>
  );
};

export default CreditCard;
