import React, { Component } from 'react';
import IssuesUserList from './IssuesUserList';
import SpaceLogo from '../../spaces/SpaceLogo';

class IssuesTeamTile extends Component {
  render() {
    const membersCount =
      this.props.teams
        .filter(stateTeam => stateTeam.parent_ids.indexOf(this.props.team.id) > 0)
        .map(stateTeam => stateTeam.users.length)
        .reduce((a, b) => a + b, 0) + this.props.team.users.length;
    const subsCount = this.props.teams.filter(
      stateTeam => stateTeam.parent_id === this.props.team.id
    ).length;
    return (
      <div
        className={`team-tile-background ${
          !this.props.teams.some(team => team.parent_id === this.props.team.id) ||
          (!this.props.team.childrenUnfolded &&
            this.props.teams.some(team => team.parent_id === this.props.team.id))
            ? 'hide-horizontal-branch'
            : ''
        }`}
      >
        <div className="team-tile">
          {this.props.teams.some(team => team.parent_id === this.props.team.id) ? (
            <div
              className={`unfold-button ${this.props.team.childrenUnfolded ? 'unfolded' : ''}`}
              onClick={e => {
                e.stopPropagation();
                this.props.teamFolding(this.props.team);
              }}
              onMouseDown={e => e.stopPropagation()}
            >
              <span>{subsCount}</span>
            </div>
          ) : (
            ''
          )}
          <div className="team-tile-header">
            <SpaceLogo space={this.props.team} className="team-letters" />
            <span>{this.props.team.name}</span>
          </div>
          <div className="team-info-wrap">
            {`${membersCount} member${membersCount > 1 || membersCount === 0 ? 's' : ''}`} <span />{' '}
            {`${subsCount} sub${subsCount > 1 || subsCount === 0 ? 's' : ''}`}
          </div>
          <IssuesUserList
            makeUsersLead={this.props.makeUsersLead}
            moveUser={this.props.moveUser}
            moveSelectedUsers={this.props.moveSelectedUsers}
            markInvitedUsers={this.props.markInvitedUsers}
            team={this.props.team}
            dragHelperVariables={this.props.dragHelperVariables}
            setDragHelperVariables={this.props.setDragHelperVariables}
            sendingInvitations={this.props.sendingInvitations}
          />
        </div>
      </div>
    );
  }
}

export default IssuesTeamTile;
