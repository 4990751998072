/** @jsx jsx */
import React, { Component } from 'react';
import { css, jsx } from '@emotion/core';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import CustomTooltip from 'components/vibes/team-vibe-show/CustomTooltip';
import debounce from 'lodash/debounce';
import chartLineHover from 'components/vibes/team-vibe-show/chartLineHover';

const isCurrentWeek = date => {
  var now = moment();
  var input = moment(date);
  return now.isoWeek() == input.isoWeek();
};
class PerformanceOverTimeChart extends Component {
  hoverIntervals = [];

  categoryNames = [
    'Excitement & Energy',
    'Learning & Growth',
    'Autonomy & Independence',
    'Valued',
    'Clarity & Certainty',
    'Being Connected',
    'Safety & Comfort',
    'Purpose',
    'Productivity',
    'External',
  ];

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      dottedHover: {
        toggled: false,
        x: null,
        y: null,
      },
    };
  }

  _customTitleTooltip(tooltipItem, data) {
    const value = data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index];
    return `${value}`;
  }

  _customLabelTooltip(tooltipItem, data) {}

  onChartRendered = () => {
    this.hoverIntervals = chartLineHover(
      this.chartRef.current?.chartInstance.chart.config.data.datasets
    );
  };

  onChartHover = ({ offsetX, offsetY }) => {
    const showTooltip = this.hoverIntervals.some(f => f(offsetX, offsetY));
    if (this.state.dottedHover.toggled !== showTooltip) {
      this.setState({
        dottedHover: {
          toggled: showTooltip,
          x: offsetX,
          y: offsetY,
        },
      });
    }
  };

  debouncedOnChartHover = () => debounce(this.onChartHover, 50, { maxWait: 150 });

  render() {
    const { dashboard, dates, vibeStats, chartData, showFactor } = this.props;
    const options = {
      maintainAspectRatio: dashboard,
      responsive: true,
      layout: {
        padding: {
          top: 45,
          left: 5,
        },
      },
      spanGaps: true,
      scales: {
        xAxes: [
          {
            display: !dashboard,
            position: 'bottom',
            stacked: true,
            gridLines: {
              display: false,
              drawBorder: true,
            },
            ticks: {
              display: !dashboard,
              fontColor: '#C4C4C4',
              fontFamily: 'Roboto',
              padding: 27,
            },
          },
        ],
        yAxes: [
          {
            position: 'right',
            scaleLabel: {
              display: !dashboard,
              labelString: 'Score',
              fontColor: '#C4C4C4',
            },
            gridLines: {
              drawBorder: false,
              color: 'rgba(243,243,243,1)',
              zeroLineColor: 'rgba(182,215,229,1)',
            },
            ticks: {
              display: !dashboard,
              max: 103,
              stepSize: 20,
              min: -103,
              fontSize: 12,
              fontColor: '#706E84',
              callback(value) {
                const val = parseInt(value, 10);
                if (val === 100 || val === -100 || val === 0) return val;
                if (val === 103 || val === -103) return null;
                return '';
              },
            },
          },
        ],
      },
      legend: false,
      tooltips: {
        backgroundColor: 'white',
        titleFontFamily: 'Roboto, sans-serif',
        titleFontSize: 14,
        titleFontStyle: 'Bold',
        titleFontColor: '#575563',
        borderColor: 'rgba(0, 0, 0, 0.15)',
        borderWidth: 1,
        cornerRadius: 3,
        xPadding: 16,
        yPadding: 7,
        titleMarginBottom: 0,
        caretPadding: 10,
        yAlign: 'bottom',
        xAlign: 'center',
        callbacks: {
          title: this._customTitleTooltip,
          label: this._customLabelTooltip,
        },
      },
      hover: {
        mode: 'nearest',
        onHover: this.debouncedOnChartHover(),
      },
      animation: {
        onComplete: this.onChartRendered,
      },
      plugins: {
        datalabels: {
          display: false,
        },
      },
    };

    const dataset = [
      { label: 'Excitement & Energy', color: '#C0BCEE' },
      { label: 'Learning & Growth', color: '#B4D79B' },
      { label: 'Autonomy & Independence', color: '#F6C29D' },
      { label: 'Valued', color: '#6d97f0' },
      { label: 'Clarity & Certainty', color: '#e26cf2' },
      { label: 'Being Connected', color: '#fff16f' },
      { label: 'Safety & Comfort', color: '#f57d63' },
      { label: 'Purpose', color: '#6df5ce' },
      { label: 'Productivity', color: '#4ab7f6' },
      { label: 'External', color: '#c3f655' },
    ];

    const completeData = dataset.map(({ label, color }, i) => {
      const chartDataRefactored = Object.values(
        (!showFactor || showFactor[this.categoryNames[i]]) && chartData
          ? chartData[this.categoryNames[i]]
          : []
      );
      if (
        typeof chartDataRefactored[chartDataRefactored.length - 1] === 'number' &&
        chartDataRefactored.length > 1 &&
        dates &&
        isCurrentWeek(dates[dates.length - 1])
      ) {
        chartDataRefactored.pop();
      }
      return {
        label,
        data: chartDataRefactored,
        backgroundColor: '#FFFFFF',
        borderColor: color,
        lineTension: 0.1,
        fill: false,
        pointBorderWidth: 2,
      };
    });
    const predictionData = [];
    completeData.forEach(({ data, ...obj }) => {
      const leadingLine = Array(data.length).fill(null);
      const trailingLine = Array(data.length).fill(null);
      const boolMap = data.map(n => typeof n === 'number');
      const firstNumberIndex = boolMap.indexOf(true);
      if (firstNumberIndex > 0) {
        leadingLine[0] = data[firstNumberIndex];
        leadingLine[firstNumberIndex] = data[firstNumberIndex];
        predictionData.push({
          ...obj,
          data: leadingLine,
          label: `${obj.label} Prediction leading`,
          borderDash: [0, 8],
          borderCapStyle: 'square',
          pointRadius: 0,
          hoverBackgroundColor: '#fff',
          lineHover: true,
        });
      }

      const lastNumberIndex = boolMap.lastIndexOf(true);
      if (dates && data.length < dates.length) {
        if (!showFactor || showFactor[obj.label]) {
          const dottedChart = Array(dates.length).fill(null);
          const latestDate = dates[dates.length - 1];
          const beforeLatestDate = dates[dates.length - 2];
          dottedChart[dottedChart.length - 2] = chartData[obj.label][beforeLatestDate];
          dottedChart[dottedChart.length - 1] = chartData[obj.label][latestDate];
          predictionData.push({
            ...obj,
            data: dottedChart,
            label: `${obj.label} Prediction trailing`,
            borderDash: [0, 8],
            borderCapStyle: 'square',
            pointRadius: 3,
            hoverBackgroundColor: '#fff',
            lineHover: true,
          });
        }
      } else {
        trailingLine[lastNumberIndex] = data[lastNumberIndex];
        trailingLine[data.length - 1] = data[lastNumberIndex];

        predictionData.push({
          ...obj,
          data: trailingLine,
          label: `${obj.label} Prediction trailing`,
          borderDash: [0, 8],
          borderCapStyle: 'square',
          pointRadius: 0,
          hoverBackgroundColor: '#fff',
          lineHover: true,
        });
      }
    });
    const vibeStatsData = Object.values(vibeStats);

    const vibeDottedChartData = Array(vibeStatsData.length).fill(null);
    vibeDottedChartData[vibeDottedChartData.length - 2] = vibeStatsData[vibeStatsData.length - 2];
    vibeDottedChartData[vibeDottedChartData.length - 1] = vibeStatsData[vibeStatsData.length - 1];
    const getVibeScoreLine = vsData => {
      const vibeScoreLineData = [
        {
          label: 'Vibe score',
          data: vsData.slice(0, vsData.length - 1),
          backgroundColor: '#FFFFFF',
          borderColor: '#F5351B',
          fill: false,
          lineTension: 0.1,
          pointBorderWidth: 3,
          borderWidth: 3,
          pointRadius: 4,
        },
        {
          label: `Vibe score Prediction trailing`,
          data: vibeDottedChartData,
          backgroundColor: '#FFFFFF',
          borderColor: '#F5351B',
          fill: false,
          lineTension: 0.1,
          pointBorderWidth: 2,
          pointRadius: 4,
          borderDash: [0, 8],
          borderCapStyle: 'square',
          hoverBackgroundColor: '#fff',
          lineHover: true,
        },
      ];
      if (dates && !isCurrentWeek(dates[dates.length - 1])) {
        return [{ ...vibeScoreLineData[0], data: vsData }];
      }
      if (vsData[vsData.length - 1] !== vsData[vsData.length - 2]) {
        return vibeScoreLineData;
      }
      return [vibeScoreLineData[0], { ...vibeScoreLineData[1], pointRadius: 0 }];
    };

    const data = [...getVibeScoreLine(vibeStatsData), ...completeData, ...predictionData];

    return (
      <>
        <div
          id="stats-wrapper"
          css={css`
            ${dashboard && 'margin-top: 18px; margin-bottom: 25px;'}
          `}
        >
          <div id="timeline-chart" className={`${dashboard && 'dashboard'}`}>
            <Line
              data={{
                labels: dates,
                datasets: vibeStats ? data : [],
              }}
              height={170}
              options={options}
              ref={this.chartRef}
            />
          </div>
          <CustomTooltip id="chart-tooltip" {...this.state.dottedHover}>
            No data recorded for this period. You are predicted to remain at this level unless a new
            response is recorded.
          </CustomTooltip>
        </div>
        <div
          className="legend-wrap"
          css={css`
            ${dashboard && 'margin-bottom: 20px;'}
          `}
        >
          <svg width="90" height="20" xmlns="http://www.w3.org/2000/svg">
            <g>
              <line
                stroke="#f5351b"
                strokeLinecap="undefined"
                strokeLinejoin="undefined"
                id="svg_1"
                y2="10"
                x2="60"
                y1="10"
                x1={`${dashboard ? 14 : 16}`}
                strokeWidth="4"
                fill="none"
              />
              <ellipse
                stroke="#f5351b"
                ry={`${dashboard ? 4 : 6}`}
                rx={`${dashboard ? 4 : 6}`}
                id="svg_2"
                cy="10"
                cx="65"
                strokeWidth={`${dashboard ? 3 : 4}`}
                fill="#fff"
              />
              <ellipse
                stroke="#f5351b"
                ry={`${dashboard ? 4 : 6}`}
                rx={`${dashboard ? 4 : 6}`}
                id="svg_3"
                cy="10"
                cx="10"
                strokeWidth={`${dashboard ? 3 : 4}`}
                fill="#fff"
              />
            </g>
          </svg>
          <span id="legend-text">Vibe score</span>
        </div>
      </>
    );
  }
}
export default PerformanceOverTimeChart;
