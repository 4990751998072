import React from 'react';
import HeelixModal from '../../shared/modals/HeelixModal';

const VibeLimitModal = props => (
  <HeelixModal
    hideModal={props.onHide}
    showModal={props.open}
    center
    title={"You've already recorded your Vibe this week"}
  >
    <h2>You can record a new Vibe once we have started a new week</h2>
    <div onClick={() => props.onHide()}>
      <button type="button">Got it!</button>
    </div>
  </HeelixModal>
);

export default VibeLimitModal;
