import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { APP_URL, AWS_S3_URL } from '../../../constants';
import { LOCALSTORAGE_GET } from '../../../helpers';

class Stripe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: [],
      search: this.props.search,
      column: '',
      order: '',
      user: {},
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.search !== this.props.search) {
      this.setState({ search: nextProps.search }, () => {
        this.fetchData();
      });
    }
  }

  fetchData(order) {
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);
    let url = `/admin/accounts?tab=stripe&search=${this.state.search}`;
    if (order) {
      url += order;
    }
    fetch(APP_URL + url, { method: 'get', headers })
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({
            accounts: jsonResponse.accounts,
            column: jsonResponse.column,
            order: jsonResponse.order,
            user: jsonResponse.user,
          });
        });
      })
      .catch(() => {});
    if (url !== this.props.location.pathname + this.props.location.search) {
      this.props.history.push(url);
    }
  }

  imgSrc(account) {
    const defaultTeam = account.teams[0];

    if (defaultTeam) {
      return defaultTeam.image_uid
        ? defaultTeam.image_url
        : `${AWS_S3_URL}public/images/defaults/team.jpg`;
    }
  }

  orderBy(e, column, order = 'desc') {
    e.preventDefault();
    if (column === this.state.column) {
      order = this.state.order === 'desc' ? 'asc' : 'desc';
    }
    this.fetchData(`&order[${column}]=${order}`);
  }

  setIcon(name) {
    const caret = this.state.column !== name ? 'left' : this.state.order === 'asc' ? 'up' : 'down';
    return <i className={`fa fa-caret-${caret}`} />;
  }

  render() {
    return (
      <table className="table">
        <tbody>
          <tr>
            <th />
            <th onClick={e => this.orderBy(e, 'name')}>
              <a href="#">
                Name
                {this.setIcon('name')}
              </a>
            </th>
            <th onClick={e => this.orderBy(e, 'created_at')}>
              <a href="#">
                Created
                {this.setIcon('created_at')}
              </a>
            </th>
            <th onClick={e => this.orderBy(e, 'paid_until')}>
              <a href="#">
                Paid Until
                {this.setIcon('paid_until')}
              </a>
            </th>
          </tr>
          {this.state.accounts.map(account => (
            <tr key={account.id}>
              <td style={{ width: '40px' }}>
                <img
                  src={this.imgSrc(account)}
                  alt={account.name}
                  className="shadow"
                  style={{ height: '20px' }}
                />
              </td>
              <td>
                <Link to={`/admin/accounts/${account.id}`} className="primary-link">
                  {account.name}
                </Link>
              </td>
              <td className="hidden-sm hidden-xs">
                <TimeAgo date={`${account.created_at} ${this.state.user.time_zone}`} />
              </td>
              <td className="hidden-sm hidden-xs">
                {account.paid_until ? account.paid_until : 'Not paid'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
export default Stripe;
