import styled from '@emotion/styled';
import React from 'react';
import { connect } from 'react-redux';
import planTypes from 'components/payments/PaymentChoose/planTypes';
import qs from 'qs';
import { withRouter } from 'react-router-dom';

export const BlueButton = styled.button`
  line-height: 30px;
  background: #11a9ff;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  border-radius: 4px;
  -webkit-appearance: none;
  font-weight: bold;
  cursor: pointer;
  box-shadow: none;
  border: none;
  width: calc(100% - 40px);
  max-width: 400px;
  padding: 9px 0;
  margin: 0 20px;

  :disabled {
    background: #f3f3f3;
    color: #a6a6a6;
  }
`;

/**
 *
 * @param {{type: string, size}} current
 * @param {{type: string, size}} upgrade
 * @returns {boolean}
 */
const comparePlans = (current, upgrade) => {
  const types = ['free', 'pro', 'plus'];
  const sizes = Object.keys(planTypes);
  if (upgrade.type === 'free') return false;
  if (current.size === 'perUser' && upgrade.size !== 'perUser') return true;
  if (sizes.indexOf(current.size.toString()) < sizes.indexOf(upgrade.size.toString())) return true;
  return types.indexOf(current.type) <= types.indexOf(upgrade.type);
};

const PlanButton = ({
  plan,
  currentPlan,
  planSize,
  currentPlanSize,
  openContactModal,
  openDowngradeModal,
  history,
  openUpgradeModal,
  ...props
}) => {
  currentPlan =
    currentPlan === 'connectPerform'
      ? 'pro'
      : currentPlan === 'connectPerformPro'
      ? 'plus'
      : 'free';

  if (
    plan === currentPlan &&
    (plan === 'free' || planSize?.toString() === currentPlanSize?.toString())
  ) {
    return (
      <BlueButton {...props} disabled>
        Your current plan
      </BlueButton>
    );
  }

  if (props.disabled) {
    return <BlueButton {...props}>Not Available</BlueButton>;
  }

  if (planSize === 'custom') {
    return (
      <BlueButton onClick={openContactModal} {...props}>
        Contact Us
      </BlueButton>
    );
  }

  const redirectToPaymentScreen = isUpgrade => {
    const query = {
      upgrade: isUpgrade,
      plan,
      planSize,
    };
    const queryString = qs.stringify(query);

    return history.push(`/payments/new?${queryString}`);
  };

  if (comparePlans({ type: currentPlan, size: currentPlanSize }, { type: plan, size: planSize })) {
    const showUpgradeModal = currentPlan !== 'free' && currentPlanSize !== 'trial';
    return (
      <BlueButton
        {...props}
        onClick={
          showUpgradeModal
            ? () => openUpgradeModal(plan, planSize)
            : () => redirectToPaymentScreen(true)
        }
      >
        Upgrade
      </BlueButton>
    );
  }
  return (
    <BlueButton
      {...props}
      onClick={plan === 'free' ? openDowngradeModal : () => redirectToPaymentScreen(false)}
    >
      Downgrade
    </BlueButton>
  );
};

const mapState = state => ({
  currentPlan: state.plansDomain.rbacPlan,
  currentPlanSize: state.plansDomain.planSize,
});

export default connect(mapState)(withRouter(PlanButton));
