import React, { Component } from 'react';

class LoadingScale extends Component {
  render() {
    return (
      <div className="loading-scale">
        <div className="loader text-center">
          <div className="la-line-scale-pulse-out centered-la-2x la-2x jobvibe-blue">
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    );
  }
}
export default LoadingScale;
