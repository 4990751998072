import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

const SpacesTooltip = ({ id }) => (
  <ReactTooltip id={id} class="react-tooltip" place="top" type="dark" effect="solid">
    <span>
      Create cross-functional Spaces to bring together users from different teams. Users can also
      create their own Spaces, whether it be for the team’s social club or a particular interest
      group.
    </span>
  </ReactTooltip>
);

SpacesTooltip.propTypes = {
  id: PropTypes.string.isRequired,
};
export default SpacesTooltip;
