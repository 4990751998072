import { Title, Wrap } from 'components/vibes/team-vibe-show/partials/Headers/layout';
import * as Layout from 'pages/Grow/NoteLayout';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const ManagerPromptHeader = ({ title, createdAt, userTimeZone }) => {
  return (
    <Wrap className="menu-outer-wrap">
      <Title>Action plan: {title}</Title>
      <Layout.HeaderDate>
        {moment.utc(createdAt).tz(userTimeZone).format('DD MMMM YYYY')}
      </Layout.HeaderDate>
      <Layout.HeaderType>
        <Layout.GlassesStyled isResolved={false} />
      </Layout.HeaderType>
    </Wrap>
  );
};

ManagerPromptHeader.propTypes = {
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  userTimeZone: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  title: state.app.body.title ?? state.app.body.preparationNote.action_plan.factor,
  userTimeZone: state.usersDomain.user.timeZone,
});

export default connect(mapStateToProps, null)(ManagerPromptHeader);
