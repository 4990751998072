/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';

const Container = styled.a`
  display: block;
  margin: 20px -26px 25px;
  ${({ large }) =>
    large &&
    css`
      margin: 20px -29px 25px;
    `}
  :hover {
    text-decoration: none;
  }
`;

const BottomBar = styled.div`
  background: #f6f6f6;
  padding: 17px 26px 12px;
`;

const Page = styled.div`
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #11a9ff;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  color: #323232;
  margin-top: 7px;
`;

const Picture = styled.img`
  max-width: 100%;
`;

const MetaInfo = ({ data, large = false }) => {
  return !data ? null : (
    <Container href={data.url} target="_blank" large={large}>
      {data.image && <Picture src={data.image} alt="" />}
      <BottomBar>
        <Page>{data.base_url}</Page>
        <Title>{data.title}</Title>
      </BottomBar>
    </Container>
  );
};

export default MetaInfo;
