/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import MobilePricingPicker from 'components/payments/PaymentChoose/mobile/MobilePricingPicker';
import styled from '@emotion/styled';
import plans from 'components/payments/PaymentChoose/plans';
import {
  DropdownTabs,
  Assistance,
  AssistanceDescription,
  AssistanceLetsTalk,
  CheckmarkList,
  ShortDescription,
} from 'components/payments/PaymentChoose/shared/layout';
import { css, jsx } from '@emotion/core';
import FeatureDropdown from 'components/payments/PaymentChoose/mobile/FeatureDropdown';
import planTypes from 'components/payments/PaymentChoose/planTypes';
import PriceSection from 'components/payments/PaymentChoose/shared/PriceSection';
import PlanButton from 'components/payments/PaymentChoose/shared/PlanButton';
import AssistanceSvg from './shared/Assistance.svg';

const tabs = {
  free: 'free',
  pro: 'pro',
  plus: 'plus',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    display: none;
  }
`;

const SingleView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MobilePlanSelector = ({
  openContactModal,
  planSize,
  currency,
  openDowngradeModal,
  openUpgradeModal,
}) => {
  const { prices } = planTypes[planSize];

  const [tab, setTab] = useState(tabs.free);
  const [plan, setPlan] = useState(plans.find(plan => plan.key === tabs.pro));

  useEffect(() => {
    setPlan(plans.find(plan => plan.key === tab));
  }, [tab]);

  return (
    <Container>
      <MobilePricingPicker value={tab} setValue={setTab} planSize={planSize} />
      <SingleView>
        <ShortDescription>{plan.shortDescription}</ShortDescription>

        <PriceSection
          price={plan.price ?? prices[plan.key][currency]}
          isPriceString={Boolean(plan.priceString)}
          description={plan.priceDescription}
          image={prices[plan.key]?.image}
          currency={currency}
        />

        <PlanButton
          planSize={planSize}
          plan={plan.key}
          openContactModal={openContactModal}
          openDowngradeModal={openDowngradeModal}
          openUpgradeModal={openUpgradeModal}
        />

        <CheckmarkList icon={plan.pointsIcon}>
          {plan.points.map(point => (
            <li>{point}</li>
          ))}
        </CheckmarkList>

        <DropdownTabs>
          {plan.features.map(feature => (
            <FeatureDropdown feature={feature} />
          ))}
        </DropdownTabs>

        <PriceSection
          price={plan.price ?? prices[plan.key][currency]}
          isPriceString={Boolean(plan.priceString)}
          description={plan.priceDescription}
          image={prices[plan.key]?.image}
          currency={currency}
        />

        <PlanButton
          planSize={planSize}
          plan={plan.key}
          openContactModal={openContactModal}
          openDowngradeModal={openDowngradeModal}
          openUpgradeModal={openUpgradeModal}
        />

        <Assistance>
          <img src={AssistanceSvg} alt="" />
          <AssistanceDescription
            css={css`
              margin-top: 18px;
            `}
          >
            Need assistance?
          </AssistanceDescription>
          <AssistanceLetsTalk onClick={openContactModal}>Let's talk!</AssistanceLetsTalk>
        </Assistance>
      </SingleView>
    </Container>
  );
};

export default MobilePlanSelector;
