import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { PLAN_ACTIONS } from 'redux/actions/planActions';
import VibeFlowContainer from '../vibes/VibeFlowContainer';
import Auxiliary from '../layouts/Auxiliary';
import HeelixLogoSmall from '../../images/vibe/heelix_logo_small.png';

const mapStateToProps = state => ({
  plan: state.plansDomain.plan,
  user: state.usersDomain.user,
});
const mapDispatchToProps = dispatch => ({
  showUpgradeModal: () => dispatch(PLAN_ACTIONS.showUpgradeModal()),
  hideUpgradeModal: () => dispatch(PLAN_ACTIONS.hideUpgradeModal()),
});

class VibeTile extends Component {
  state = {
    loaded: false,
  };

  render() {
    let content = (
      <div className="text-center">
        <img src={HeelixLogoSmall} alt="" className="shadowfilter waiting-for-vibe" />
      </div>
    );

    if (this.props.vibeTileLoaded) {
      content = (
        <Auxiliary>
          <VibeFlowContainer
            vibe_factors={this.props.vibe_factors}
            feel_factors={this.props.feel_factors}
            links={this.props.links}
            raw_text={this.props.raw_text}
            scale={this.props.scale}
            sentimentChanged=""
            images={this.props.images}
            enable_vibe_comments={this.props.enable_vibe_comments}
            tileRealignHandler={this.props.tileRealignHandler}
            fetchPostsOnClose={this.props.fetchPostsOnClose}
          />
        </Auxiliary>
      );
    }

    return (
      <div className="col-lg-6 col-xs-12 post-tile-wrap initial-hide">
        <div className="post-tile vibe-tile">
          <div className="post-tile-body vibe_new">{content}</div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VibeTile));
