import styled from '@emotion/styled';

export const Wrap = styled.div`
  padding-top: 30px;
  padding-left: 30px;
  height: 80px !important;
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 152.34%;
`;
export const QuickMeetingTitle = styled.div`
  color: #11a9ff;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
`;
