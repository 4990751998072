import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

class EmailNotificationsTooltip extends Component {
  render() {
    return (
      <ReactTooltip id={this.props.id} class="react-tooltip" place="top" type="dark" effect="solid">
        <span>
          Email rollups are a less noisy way
          <br />
          of keeping you in touch with all
          <br />
          the new posts that have been made.
        </span>
      </ReactTooltip>
    );
  }
}
export default EmailNotificationsTooltip;
