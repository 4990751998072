import React, { Component } from 'react';
import delayUnmounting from '../../HOC/delayUnmounting';

class UserPlaceholder extends Component {
  state = {
    mounted: false,
  };

  componentDidMount() {
    // --Required for dragEnter opacity animation
    setTimeout(() => this.setState({ mounted: true }), 0);
  }

  componentWillReceiveProps(props) {
    this.setState({ mounted: props.isMounted });
  }

  render() {
    return (
      <span
        className={`user-placeholder ${
          this.props.additionalClasses ? this.props.additionalClasses : ''
        } ${this.state.mounted ? 'mounted' : ''}`}
        style={{
          height: this.state.mounted ? window.getComputedStyle(this.props.userNode).height : 0,
        }}
      />
    );
  }
}

export default delayUnmounting(UserPlaceholder);
