import React from 'react';
import PropTypes from 'prop-types';
import { Textarea, Title, Wrap } from 'components/vibes/createForms/CreateNote/NoteTypes/layout';
import MeetingSteps from 'components/vibes/createForms/partials/MeetingSteps';

const ManagerPromptForm = ({ stepsCount, steps, setMeetingState, notes, setNotes }) => {
  return (
    <Wrap>
      <Title>Notes</Title>
      <Textarea
        placeholder="Enter here..."
        value={notes}
        onChange={e => setNotes(e.target.value)}
      />
      <Title>Actions you want to take</Title>
      <MeetingSteps
        disableAssignUser
        stepsCount={stepsCount}
        steps={steps}
        setMeetingState={setMeetingState}
      />
    </Wrap>
  );
};

ManagerPromptForm.propTypes = {
  stepsCount: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
  setMeetingState: PropTypes.func.isRequired,
  notes: PropTypes.string.isRequired,
  setNotes: PropTypes.func.isRequired,
};

export default ManagerPromptForm;
