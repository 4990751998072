import React from 'react';
import GoogleLogin from 'react-google-login';
import PropTypes from 'prop-types';
import AppleLogin from 'components/signup/AppleLogin';
import { AWS_S3_URL, GOOGLE_KEY } from '../../constants';
import LoadingOverlay from './LoadingOverlay';
import WelcomeContainer from './WelcomeContainer';

const WelcomeEmail = ({ lookupMail, isFetching, googleResponse, googleFailure }) => (
  <WelcomeContainer
    title="Welcome to Heelix!"
    body={
      <>
        <LoadingOverlay toggled={isFetching} message="Verifying email" height="80%" />
        <div className="google-button text-center">
          <GoogleLogin
            clientId={GOOGLE_KEY}
            prompt="select_account"
            onSuccess={googleResponse}
            onFailure={googleFailure}
            render={renderProps => (
              <button
                onClick={renderProps.onClick}
                type="button"
                className="btn text-center mx-auto"
              >
                <img src={`${AWS_S3_URL}public/images/icons/google-button-logo.png`} alt="" />
                <span>Use Google account</span>
              </button>
            )}
          />
        </div>
        <AppleLogin />
        <div className=" horizontal-line mx-auto">
          <span>Or</span>
        </div>
        <form onSubmit={lookupMail} action="" className="simple_form form-compact" id="new_user">
          <div className="form-group email required user_email">
            <input
              name="email"
              type="email"
              className="string email required form-control"
              id="user_email"
              placeholder="Start with your email"
              required
            />
          </div>
          <button
            disabled={isFetching}
            type="submit"
            className="btn btn-primary btn-full"
            id="sign-in-btn"
          >
            Go
          </button>
        </form>
      </>
    }
  />
);

WelcomeEmail.propTypes = {
  lookupMail: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  googleResponse: PropTypes.func.isRequired,
  googleFailure: PropTypes.func.isRequired,
  verifyCallback: PropTypes.func.isRequired,
  captchaToken: PropTypes.string,
};

export default WelcomeEmail;
