/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import pin from 'images/feed/pin.svg';
import { connect } from 'react-redux';
import { POST_ACTIONS } from 'redux/actions/postActions';

const PinnedPost = ({ post, user, pinPost }) =>
  post.pinned ? (
    <div
      className="pinned-post"
      onClick={user.leadTeamsIds.includes(post.audience_id) ? () => pinPost(post.id) : null}
    >
      <img src={pin} />
    </div>
  ) : (
    ''
  );

const mapDispatchToProps = dispatch => ({
  pinPost: id => dispatch(POST_ACTIONS.pinPost(id)),
});
const mapStateToProps = state => ({
  user: state.usersDomain.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(PinnedPost);
