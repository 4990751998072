import React, { createRef, useState } from 'react';
import styled from '@emotion/styled';
import FilterButton from 'components/feed/QuestionnaireTile/TeamFilter/FilterButton';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PortalDropdown from 'components/feed/QuestionnaireTile/TeamFilter/PortalDropdown';

const Container = styled.div`
  margin-bottom: 23px;
  border: 1px solid #e6e6e6;
  ${({ open }) => open && 'border-bottom: none'};
  border-radius: ${({ open }) => (!open ? '4px' : '4px 4px 0 0')};
  background: #ffffff;

  h1 {
    display: none;
  }

  input {
    background: #ffffff;
    border: 1px solid #11a9ff;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    padding: 10px 13px;
    height: 42px;
    outline: none;

    &::placeholder {
      color: #c4c4c4;
      font-style: italic;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 15px;
    margin-top: 15px;
    margin-left: 3px;
    margin-bottom: 11px;
  }

  a {
    text-decoration: none;
    color: inherit;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .space-logo,
    img {
      display: inline-block;
      font-weight: 900;
      width: 38px;
      height: 38px;
      border: 1px solid white;
      border-radius: 100%;
      margin: 0;
      line-height: 38px;
      font-size: 15px;
      text-align: center;
    }

    .team-name {
      font-size: 16px;
      display: inline-block;
      position: relative;
      left: 11px;
      top: 1px;
      flex-grow: 1;
    }
  }
`;

const TeamFilter = ({ location, teams, user, setTeam }) => {
  const [selectedTeam, setSelectedTeam] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const filterButtonRef = createRef();

  return (
    <Container open={dropdownOpen}>
      <FilterButton
        ref={filterButtonRef}
        team={selectedTeam}
        onClick={e => {
          e.stopPropagation();
          setDropdownOpen(isOpen => !isOpen);
        }}
      />
      {dropdownOpen && (
        <PortalDropdown
          withRedirect={false}
          tabLink={location.pathname}
          queryString={selectedTeam}
          isMounted={dropdownOpen}
          teams={teams}
          toggle={teamId => {
            setDropdownOpen(isOpen => !isOpen);
            if (teamId) {
              const team = teams.find(team => team.id === teamId);
              setSelectedTeam(team);
              setTeam(team);
            }
          }}
          user={user}
          teamString="id"
          filterButtonRef={filterButtonRef}
        />
      )}
    </Container>
  );
};

const mapState = state => ({
  teams: state.teamsDomain.teams,
  user: state.usersDomain.user,
  onboardingTeam: state.onboardingDomain.onboardingTeam,
  insightsTourStep: state.onboardingDomain.insightsTourStep,
});

export default withRouter(connect(mapState)(TeamFilter));
