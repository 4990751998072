import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { AVATAR_ERROR } from 'helpers';

const SharedMultipleWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: 11px;
  line-height: 28px;
  margin-left: -4px;
`;
const SharedInfo = styled.span`
  margin-right: 6px;
  color: ${props => (props.isResolved ? '#52BA05' : '#323232')};
`;

const SharedMultiple = ({ isResolved = false }) => {
  return (
    <SharedMultipleWrapper>
      <SharedInfo isResolved={isResolved}>&nbsp;multiple people</SharedInfo>
    </SharedMultipleWrapper>
  );
};

SharedMultiple.propTypes = {
  isResolved: PropTypes.bool,
};

export default SharedMultiple;
