import React, { Component } from 'react';
import { connect } from 'react-redux';
import striptags from 'striptags';
import { withRouter, Link } from 'react-router-dom';

class FeedbackAlert extends Component {
  render() {
    if (this.props.post && this.props.post.space) {
      const content = JSON.parse(this.props.post.content);
      let redirectUrl = `/vibes/dashboard?period=-7`;
      if (this.props.post.direct_team) {
        redirectUrl = `/vibes/dashboard?team=${this.props.post.direct_team.id}&period=-7`;
      }
      return (
        <div className="post-wrapper poll-wrapper">
          <div className="post-body">
            <div className="post-content">
              <h3 className="app-alert-header">Here's what you need to know this week:</h3>
              <div className="feedback-wrapper">
                {content.map((feedback, index) => (
                  <div className="app-alert-feedback">
                    <span className="feedback-counter">{index + 1}</span>
                    <span
                      className="feedback-paragraph"
                      dangerouslySetInnerHTML={{
                        __html: striptags(feedback, ['a', 'br']),
                      }}
                    />
                  </div>
                ))}
                <div className="feedback-info">
                  Check out the other feedback that's been received and some suggested actions by
                  visiting your&nbsp;
                  <Link to={redirectUrl}>insights page on web here</Link>.
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }
}

const mapStateToProps = state => ({
  post: state.postsDomain.singlePost,
});

export default withRouter(connect(mapStateToProps)(FeedbackAlert));
