import styled from '@emotion/styled';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const LeadWrap = styled.div`
  font-size: 14px;
  color: #6a6a6a;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 21px 0 16px;
  @media (min-width: 768px) and (max-width: 1200px) {
    text-align: center;
  }
`;

const Picture = styled.img`
  width: 32px;
  margin-left: 65px;
  margin-right: 12px;
  border-radius: 50%;
  @media (min-width: 768px) and (max-width: 1200px) {
    margin-left: 0;
  }
`;

const TeamLead = ({ leads }) => {
  const leadName = useRef(null);
  const lead = leads[0];
  const length = leads.length - 1;
  return (
    <LeadWrap ref={leadName}>
      <Picture src={lead.image_url} alt="" />
      {length > 0 ? (
        <span>
          + {length} other{length > 1 && 's'}
        </span>
      ) : (
        <span title={`${lead.first_name} ${lead.last_name}`}>
          {`${lead.first_name} ${lead.last_name}`}
        </span>
      )}
    </LeadWrap>
  );
};

TeamLead.propTypes = {
  leads: PropTypes.array.isRequired,
};

export default TeamLead;
