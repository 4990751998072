/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import Select from 'react-select';
import MemberPreview from './MemberPreview';

const Title = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  padding-bottom: 13px;
  padding-top: 25px;
`;

const SelectWrapper = styled.div`
  width: ${props => (props.fullwidth ? '100%' : '47%')};
  ${props => props.css}
`;

const SelectQuestion = ({
  question,
  id,
  value,
  setValue,
  options,
  searchable,
  disabled = false,
  fullwidth = true,
  placeholder = 'Select ...',
  ...props
}) => {
  return (
    <SelectWrapper fullwidth={fullwidth} className="select-question" {...props}>
      <Title> {question} </Title>
      {id === 'member' && value ? (
        <MemberPreview member={value} members={options} />
      ) : (
        <Select
          name={`select-${id}`}
          id={`select-${id}`}
          onChange={el => setValue(el.value)}
          value={value}
          options={options}
          searchable={searchable}
          disabled={disabled}
          clearable={false}
          placeholder={placeholder}
          css={css`
            .Select-control {
              border: 1px solid ${disabled ? '#d9d9d9' : '#EDEDED'};
            }
          `}
        />
      )}
    </SelectWrapper>
  );
};
export default SelectQuestion;
