import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'qs';
import PropTypes from 'prop-types';
import DateDropdownList from './DateRange/DateDropdownList';
import CustomDateRangeWrap from './DateRange/CustomDateRangeWrap';

class DateRange extends Component {
  state = {
    unfolded: false,
    showCustomRange: false,
  };
  // ---Number periods are strings here to avoid parsing query string parameters
  options =
    this.props.isFeed && !this.props.isSchedule
      ? [
          {
            period: '',
            description: 'All time',
          },
          {
            period: '7',
            description: 'This week',
          },
          {
            period: '-7',
            description: 'Last week',
          },
          {
            period: '30',
            description: 'Last month',
          },
          {
            period: 'custom',
            description: 'Custom date range',
          },
        ]
      : this.props.hideThisLastWeek
      ? [
          {
            period: '30',
            description: 'Last 30 days',
          },
          {
            period: '60',
            description: 'Last 60 days',
          },
          {
            period: '90',
            description: 'Last 90 days',
          },
          {
            period: 'custom',
            description: 'Custom date range',
          },
        ]
      : this.props.hideThisWeek
      ? [
          {
            period: '-7',
            description: 'Last week',
          },
          {
            period: '30',
            description: 'Last 30 days',
          },
          {
            period: '60',
            description: 'Last 60 days',
          },
          {
            period: '90',
            description: 'Last 90 days',
          },
          {
            period: 'custom',
            description: 'Custom date range',
          },
        ]
      : this.props.isSchedule
      ? [
          {
            period: '',
            description: 'All time',
          },
          {
            period: '7',
            description: 'This week',
          },
          {
            period: '14',
            description: 'Next week',
          },
          {
            period: '30',
            description: 'This month',
          },
        ]
      : [
          {
            period: '7',
            description: 'This week',
          },
          {
            period: '-7',
            description: 'Last week',
          },
          {
            period: '30',
            description: 'Last 30 days',
          },
          {
            period: '60',
            description: 'Last 60 days',
          },
          {
            period: '90',
            description: 'Last 90 days',
          },
          {
            period: 'custom',
            description: 'Custom date range',
          },
        ];

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  closeCustomRangeHandler = () => this.setState({ showCustomRange: false });

  openCustomRangeHandler = () => this.setState({ showCustomRange: true });

  toggle = () => this.setState({ unfolded: !this.state.unfolded });

  render() {
    const { queryString } = this.props;
    const chosenOption = queryString.period
      ? this.options.find(option => option.period === queryString.period)
      : this.options[0];
    if (!chosenOption && chosenOption !== '') {
      return (
        <Redirect
          to={`?${qs.stringify({
            ...queryString,
            period: this.options[0].period,
          })}`}
        />
      );
    }
    const filteredOptions = this.options.filter(
      option => option.period !== +queryString.period || option.period === 'custom'
    );
    return (
      <div ref={this.ref} className={`date-range-wrap ${this.props.className}`}>
        {!this.props.isFeed && !this.props.hideLabel && (
          <span className="range-caption">Date range</span>
        )}
        <div
          role="select"
          className="date-range"
          onClick={() => {
            this.setState({
              unfolded: !this.state.unfolded,
            });
          }}
        >
          {chosenOption.description}
          <DateDropdownList
            options={filteredOptions}
            queryString={queryString}
            isMounted={this.state.unfolded}
            tabLink={this.props.tabLink}
            openCustomRangeHandler={this.openCustomRangeHandler}
            toggle={this.toggle}
          />
          {this.state.showCustomRange ? (
            <CustomDateRangeWrap
              queryString={queryString}
              tabLink={this.props.tabLink}
              closeCustomRangeHandler={this.closeCustomRangeHandler}
              portal={this.props.portal}
              dateRangeRef={this.ref}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

DateRange.propTypes = {
  portal: PropTypes.bool,
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
};

DateRange.defaultProps = {
  hideLabel: false,
  className: '',
};

export default DateRange;
