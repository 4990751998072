import React, { Component } from 'react';
import delayUnmounting from '../HOC/delayUnmounting';

class CustomTooltip extends Component {
  state = {
    mounted: false,
  };

  componentDidMount() {
    setTimeout(() => this.setState({ mounted: true }), 0);
  }

  componentWillReceiveProps(props) {
    if (this.state.mounted !== props.isMounted) this.setState({ mounted: props.isMounted });
  }

  render() {
    return (
      <div className={`custom-tooltip ${this.state.mounted ? 'mounted' : ''}`}>
        {this.props.children}
      </div>
    );
  }
}

export default delayUnmounting(CustomTooltip);
