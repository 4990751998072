import React from 'react';
import styled from '@emotion/styled';
import { PropTypes } from 'prop-types';
import GrowPost from 'pages/Grow/GrowPost';
import { getDayName, filterSelectedPosts } from 'pages/Grow/Utils/utils';
import Masonry from 'react-masonry-component';
import oldPostStyles from 'pages/Grow/GrowPost/style.sass';
import { monthNames } from 'pages/Grow/GrowProfile/constants';

const MasonryStyled = styled(Masonry)`
  margin-left: -10px;
  margin-right: -10px;
  ${oldPostStyles}
`;

const DayWrapper = styled.div`
  padding: 18px 25px 25px 18px;
  border-left: ${({ today }) => (today ? '3px solid #11a9ff' : 'none')};
  border-bottom: 1px solid #e7e7e7;
`;

const DayTitle = styled.h3`
  font-size: 13px;
  color: ${({ today }) => (today ? '#11a9ff' : '#a6a6a6')};
  & strong {
    font-weight: bold;
  }
`;

const DayDate = styled.strong`
  font-size: 24px;
  margin-right: 6px;
`;
const PostsWrapper = styled.div`
  margin-top: 6px;
`;

const DayAgenda = ({
  dayData,
  dayData: { today },
  tileRealignHandler,
  openPostModal,
  handleListeners,
  exportModeOn,
  hasDeleteError,
  handleSelectPost,
  selectedPosts,
}) => {
  const date = new Date(dayData.date);
  const day = date.getDate();
  const dayName = getDayName(date);

  const monthName = monthNames[date.getMonth()];

  return (
    <DayWrapper today={today}>
      <DayTitle today={today}>
        <DayDate>{day}</DayDate> {monthName}, {dayName}
        {today && <strong>&nbsp;Today</strong>}
      </DayTitle>
      <PostsWrapper>
        <MasonryStyled onLayoutComplete={handleListeners}>
          {dayData.posts.map(post => {
            const isPostSelected = selectedPosts.includes(post.id);
            return (
              <GrowPost
                isExportAvailable={filterSelectedPosts(post)}
                key={post.id}
                post={post}
                openPostModal={openPostModal}
                tileRealignHandler={tileRealignHandler}
                exportModeOn={exportModeOn}
                hasDeleteError={hasDeleteError}
                handleSelectPost={handleSelectPost}
                isPostSelected={isPostSelected}
              />
            );
          })}
        </MasonryStyled>
      </PostsWrapper>
    </DayWrapper>
  );
};

DayAgenda.propTypes = {
  dayData: PropTypes.string.isRequired,
  today: PropTypes.bool.isRequired,
  tileRealignHandler: PropTypes.func.isRequired,
  openPostModal: PropTypes.func.isRequired,
  handleListeners: PropTypes.func.isRequired,
};

export default DayAgenda;
