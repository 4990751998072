import React, { Component } from 'react';
import styled from '@emotion/styled';

const FeedbackWrapper = styled.div`
  margin-top: 38px;
  padding: 0 15px;
  h1 {
    font-weight: bold;
    line-height: initial;
    font-size: 22px;
    color: #323232;
    margin-bottom: 21px;
    @media (max-width: 1080px) {
      font-size: 16px;
    }
    svg {
      position: relative;
      left: 18px;
      top: -2px;
      @media (max-width: 1080px) {
        left: 14px;
        top: 0;
        width: 24px;
      }
    }
  }

  h2 {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 8px;
    &.positive {
      color: #52ba05;
    }
    &.neutral {
      color: #beb49e;
    }
    &.negative {
      color: #f5351b;
    }
  }
  .single-factor {
    font-size: 13px;
    line-height: 27px;
    color: #323232;
  }
`;

const Column = styled.div`
  ${props => props.dashboard && 'min-height: 40px;'}
  ${props => props.dashboard && props.negative && 'float: none;'}
`;

class DetailedFeedback extends Component {
  state = {
    unfolded: false,
  };

  buildDetailedFeedback = type => {
    let feedback = this.props.detailedFeedbackData;
    if (!feedback) return;
    const limit = this.props.dashboard ? 5 : this.props.limit;
    if (this.props.factor) {
      feedback = feedback.filter(object => object.position === parseInt(this.props.factor));
    }
    feedback = feedback.filter(object => object.aggregation === type);
    feedback = feedback.sort((a, b) => (a.count < b.count ? 1 : -1));
    return feedback.map((object, index) => {
      const i = index + 1;
      if (limit && i === limit + 1) {
        return (
          <div key={index} className="single-factor">
            ...
          </div>
        );
      }
      if (limit && i > limit + 1) {
        return;
      }
      return (
        <div key={index} className="single-factor">
          {index + 1}. {object.factor} <strong>({object.count})</strong>
        </div>
      );
    });
  };

  render() {
    const { teamName, dashboard } = this.props;
    const classes = dashboard ? 'col-md-6 col-sm-12' : 'col-md-4 col-sm-12' + ' feedback-column';
    return (
      <FeedbackWrapper className="detailed-feedback-section">
        {teamName && (
          <h1>
            Triggers from&nbsp;
            {teamName}
            <svg width="30" height="10" viewBox="0 0 30 10" fill="none">
              <path
                d="M29.6978 0.394011C26.4881 -0.166582 20.3678 -0.277533 15.9571 1.06555C15.6582 1.06555 14.3523 1.06555 14.0534 1.06555C9.64274 -0.277533 3.52238 -0.160742 0.312733 0.394011C-0.0858511 0.504961 -0.0858511 2.4028 0.213087 2.51375C0.816207 2.8466 1.11514 3.51815 1.11514 3.51815C1.11514 3.51815 1.31444 4.18969 1.81791 6.64228C2.31614 9.10656 3.62203 9.5562 3.62203 9.5562C3.62203 9.5562 5.22685 10 7.03096 10C8.83508 10 9.94167 9.88911 11.1427 9.21757C12.3437 8.54602 12.8471 6.75907 12.8471 6.75907C12.8471 6.75907 13.4503 5.19409 13.8488 3.74589C13.9485 3.29625 15.9571 3.29625 16.0568 3.74589C16.4554 5.19992 17.0585 6.75907 17.0585 6.75907C17.0585 6.75907 17.562 8.54602 18.763 9.21757C19.9693 9.88911 21.0706 10 22.8747 10C24.6788 10 26.2836 9.5562 26.2836 9.5562C26.2836 9.5562 27.5895 9.22335 28.0877 6.65396C28.5912 4.08458 28.7905 3.52983 28.7905 3.52983C28.7905 3.52983 29.0894 2.85828 29.6925 2.52543C30.1016 2.40864 30.1016 0.394011 29.6978 0.394011Z"
                fill="#C4C4C4"
              />
            </svg>
          </h1>
        )}
        <div className="row">
          <Column className={classes} dashboard={dashboard}>
            <h2 className="positive">Positive</h2>
            {this.buildDetailedFeedback('positive')}
          </Column>
          <Column className={classes} dashboard={dashboard}>
            <h2 className="neutral">Neutral</h2>
            {this.buildDetailedFeedback('neutral')}
          </Column>
          <Column className={classes} dashboard={dashboard} negative>
            <h2 className="negative">Negative</h2>
            {this.buildDetailedFeedback('negative')}
          </Column>
        </div>
      </FeedbackWrapper>
    );
  }
}

DetailedFeedback.defaultProps = {
  factor: null,
  limit: false,
};

export default DetailedFeedback;
