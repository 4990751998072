import React, { Component } from 'react';
import striptags from 'striptags';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppAlertsDropdown from '../../vibes/AppAlertsDropdown';
import { POST_ACTIONS } from '../../../redux/actions/postActions';
import PollOptionsExpired from '../PollTile/PollOptionsExpired';
import PollOptions from '../PollTile/PollOptions';
import Auxiliary from '../../layouts/Auxiliary';
import poll from '../../../images/appAlerts/poll.svg';
import glasses from '../../../images/vibe/glasses.png';

const mapStateToProps = state => ({
  user: state.usersDomain.user,
});

const mapDispatchToProps = dispatch => ({
  votePoll: (id, data) => dispatch(POST_ACTIONS.votePoll(id, data)),
});

class PollAlertTile extends Component {
  constructor(props) {
    super(props);
  }

  updatePoll = (e, id) => {
    e.preventDefault();
    const data = new FormData();
    data.append('poll[selectedOption]', id);
    if (this.props.post.id > 0) {
      this.props.votePoll(this.props.post.id, data);
    } else {
      return false;
    }
  };

  render() {
    const content = (
      <Auxiliary>
        <div className="post-tile-title">
          <h4 className="poll-content">
            <span>Poll: </span>
            <span
              dangerouslySetInnerHTML={{
                __html: striptags(this.props.post.content, ['a', 'br']),
              }}
            />
          </h4>
        </div>
        {this.props.post.expired || this.props.post.is_poll_selected ? (
          <PollOptionsExpired
            options={this.props.post.poll_options}
            poll={this.props.post}
            votes={this.props.post.votes}
          />
        ) : (
          <PollOptions
            options={this.props.post.poll_options}
            poll={this.props.post}
            updatePoll={this.updatePoll}
          />
        )}
      </Auxiliary>
    );

    if (this.props.post) {
      return (
        <div className="col-lg-6 col-xs-12 post-tile-wrap initial-hide">
          <div className={`post-tile poll-tile ${this.props.post.status}`}>
            <div className="post-tile-header">
              <AppAlertsDropdown post={this.props.post} />
            </div>
            <div className="post-tile-body poll-alert-body">
              <img src={poll} className="poll-alert-icon" />
              {content}
              <div className="clear text-muted u-sizevr-1 vibe-anon">
                <img src={glasses} className="icon" alt="" style={{ width: '24px' }} />
                <span>Your response is anonymous</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PollAlertTile));
