import styled from '@emotion/styled';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const Picture = styled.img`
  width: 30px;
  margin-left: 10px;
  margin-right: 12px;
  border-radius: 50%;
`;

const LeadWrap = styled.div`
  text-align: left;
`;

const SpaceLead = ({ leads }) => {
  const leadName = useRef(null);
  const lead = leads[0];
  const length = leads.length - 1;
  return (
    <LeadWrap ref={leadName}>
      <Picture src={lead.image_url} alt="" />
      {length > 0 ? (
        <span>
          + {length} other{length > 1 && 's'}
        </span>
      ) : (
        <span title={`${lead.first_name} ${lead.last_name}`}>
          {`${lead.first_name} ${lead.last_name}`}
        </span>
      )}
    </LeadWrap>
  );
};

SpaceLead.propTypes = {
  leads: PropTypes.array.isRequired,
};

export default SpaceLead;
