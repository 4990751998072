/** @jsx jsx */
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { css, jsx } from '@emotion/core';
import { APP_URL, AWS_S3_URL } from '../../../constants';
import { BODY_CLASSES, CHECK_UNAUTHORIZED, HEADERS, PASSWORD_REGEX } from '../../../helpers';

class EditPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: false,
      errorType: false,
      message: '',
      redirect: '',
      passwordConfirm: '',
      password: '',
    };
  }

  componentDidMount() {
    BODY_CLASSES(['password', 'edit']);
  }

  resetPassword(e) {
    e.preventDefault();
    const url = this.props.location.pathname + this.props.location.search;
    if (!PASSWORD_REGEX.test(this.state.password)) {
      this.setState({ error: true });
      this.setState({ errorType: 'passwordFormat' });
    } else {
      const data = new FormData();
      data.append('password', this.state.password);
      data.append('password_confirmation', this.state.passwordConfirm);

      const headers = HEADERS();
      fetch(APP_URL + url, { method: 'post', headers, body: data })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            this.setState({ error: jsonResponse.error });
            this.setState({ message: jsonResponse.message });
            this.setState({ errorType: jsonResponse.errorType });
            if (jsonResponse.redirect) {
              localStorage.clear();

              this.setState({ redirect: true });
            }
          });
        })
        .catch(() => {});
    }
  }

  render() {
    const classesPassword =
      this.state.error && this.state.errorType === 'password'
        ? 'form-group password optional user_password has-error'
        : 'form-group password optional user_password';
    const classesPasswordConfirmation =
      this.state.error && this.state.errorType === 'password_confirmation'
        ? 'form-group password optional user_password_confirmation has-error'
        : 'form-group password optional user_password_confirmation';
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: '/login',
            state: {
              message: this.state.message,
            },
          }}
        />
      );
    }
    return (
      <div>
        <div className="logo-public centered">
          <img
            src={`${AWS_S3_URL}public/images/Heelix-Logo-black.svg`}
            alt=""
            style={{ height: 56 }}
          />
        </div>
        <div className="floating-panel">
          <div className="panel-body">
            <h1 className="centered">Type a new password</h1>
            <form className="form-compact">
              {this.state.errorType === 'general' ? (
                <div id="error_explanation">
                  <h3>1 error prohibited this user from being saved:</h3>
                  <ul>
                    <li>{this.state.message}</li>
                  </ul>
                </div>
              ) : (
                ''
              )}
              <div className={classesPassword}>
                <label className="password optional control-label" htmlFor="user_password">
                  New password
                </label>
                <input
                  autoFocus="autofocus"
                  className="password optional form-control"
                  id="user_password"
                  name="user[password]"
                  type="password"
                  onChange={e => this.setState({ password: e.target.value })}
                />
                {this.state.errorType === 'password' ? (
                  <span className="help-block" id="help-message-password">
                    {this.state.message}
                  </span>
                ) : (
                  ''
                )}
              </div>
              <div className={classesPasswordConfirmation}>
                <label
                  className="password optional control-label"
                  htmlFor="user_password_confirmation"
                >
                  Confirm new password
                </label>
                <input
                  className="password optional form-control"
                  id="user_password_confirmation"
                  name="user[password_confirmation]"
                  type="password"
                  onChange={e => this.setState({ passwordConfirm: e.target.value })}
                />
                {this.state.errorType === 'password_confirmation' ? (
                  <span className="help-block" id="help-message-password-confirmation">
                    {this.state.message}
                  </span>
                ) : (
                  ''
                )}
              </div>
              <div
                css={css`
                  text-align: left;
                  color: ${this.state.error && this.state.errorType === 'passwordFormat'
                    ? '#F5351B'
                    : '#9A9A9A'};
                  font-size: 13px;
                  margin-bottom: 14px;
                `}
              >
                Must be at least 8 characters long and contain one digit, one special character, one
                uppercase and one lowercase character
              </div>
              <input
                className="btn btn-primary btn-full"
                name="commit"
                type="submit"
                id="reset-password-btn"
                value="Reset password"
                onClick={e => this.resetPassword(e)}
              />
            </form>
            <div className="centered u-margin-top-sm">
              <div className="u-size0">
                Already have an account?&nbsp;
                <Link className="invisible-link ul-link" to="/login" id="sign-in-btn">
                  Sign in here
                </Link>
                <br />
                <Link
                  className="invisible-link ul-link"
                  to="/confirmation/new"
                  id="new-confirmation-btn"
                >
                  Didn't receive confirmation instructions?
                </Link>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditPassword;
