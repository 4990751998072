import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class InstructionsModal extends Component {
  render() {
    return (
      <Modal
        show={this.props.showInstructionsModal}
        onHide={this.props.close}
        dialogClassName="instructions-modal"
        backdrop
        keyboard
      >
        <div className="close-cross" onClick={this.props.close} />
        <h1>Setting up your account</h1>
        <p>
          When you add users to Heelix you can first choose where you want them to be before sending
          an invitation.
          <br />
          <br />
          Simply add their email address, first and last name (even a title if you like). We’ll then
          add all names to a user bucket where you can drag and drop them to the teams you create.
          <br />
          <br />
          When you're ready you can then choose who should receive an invitation. As a bonus, after
          you've finished setting up your account we'll create an org chart for you.
        </p>
      </Modal>
    );
  }
}

export default InstructionsModal;
