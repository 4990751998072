/** @jsx jsx */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { jsx } from '@emotion/core';
import { connect } from 'react-redux';

import { BODY_CLASSES } from 'helpers';
import blueGearImg from './blue-gear.svg';
import TeamTags from './partials/TeamTags';
import CompanyValues from './partials/CompanyValues';

class CompanySettingsEdit extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    BODY_CLASSES(['settings', 'edit', 'heelix', 'settings-page', 'new-payments-page']);
    this.props.setHeader('Edit Company Settings');
    this.props.closeFlash();
  }

  render() {
    return (
      <>
        <div id="company-settings-header">
          <div className="row">
            <div className="col-xs-12">
              <div id="company-settings-title">
                <img src={blueGearImg} alt="" className="blue-gear-img" />
                <h1 id="settings-title">Company settings</h1>
              </div>
            </div>
          </div>
        </div>
        <div id="company-settings-wrapper">
          {/* TODO uncomment when export shoutout is ready
          <div className="row">
            <div className="col-xs-12">
              <h2>Shoutouts settings</h2>
              <a href="" id="export-shoutouts-link">
                Click here to export shoutouts
              </a>
              <hr className="settings-divider" />
            </div>
          </div>*/}
          <div className="row">
            <div className="col-xs-12">
              <TeamTags />
              <hr className="settings-divider" />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <CompanyValues />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanySettingsEdit));
