/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import Prompt, { PromptButton, promptColors } from 'components/payments/prompts/Prompt';

const upgradeMessage = daysLeft => {
  switch (daysLeft) {
    case 0:
      return {
        title: 'Your trial of Heelix Connect & Perform is now over',
        content:
          "Don't worry you won't lose any data, but you also won't be able to access a number of your Insights and other key features. To get your access back, check out our plans and upgrade now.",
        button: 'UPGRADE NOW',
      };
    case 1:
      return {
        title: 'Your free trial ends in 1 day.',
        content:
          'Please upgrade to enjoy the full functionality of Heelix and all benefits of connected, performing teams',
        button: 'UPGRADE',
      };
    case 3:
      return {
        title:
          "Just a quick reminder that you've only got a few days left until the end of your trial of Heelix Connect & Perform",
        content: 'To avoid missing out on any of our great premium features, upgrade now.',
        button: 'UPGRADE NOW',
      };
    case 7:
      return {
        title: "You're now over halfway through your trial of Heelix Connect & Perform",
        content:
          "To keep enjoying our premium features, upgrade now or when your trial is up. We'll send you another prompt closer to the time to let you know.",
        button: 'UPGRADE NOW',
      };
    default:
      return {
        title: `Your free trial ends in ${daysLeft} days.`,
        content:
          'Please upgrade to enjoy the full functionality of Heelix and all benefits of connected, performing teams',
        button: 'UPGRADE',
      };
  }
};

const redirect = (hide, history) => {
  hide();
  history.push('/payments/choose_plan');
};

const TrialUpgradePrompt = ({ prompt, history }) => {
  const message = upgradeMessage(prompt.data.daysLeft);

  return (
    <Prompt
      color={promptColors.yellow}
      title={message.title}
      content={message.content}
      button={hide => (
        <PromptButton color={promptColors.yellow} onClick={() => redirect(hide, history)}>
          {message.button}
        </PromptButton>
      )}
    />
  );
};

export default withRouter(TrialUpgradePrompt);
