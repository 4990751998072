/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { noScrollBar } from 'styles';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as MediaActive } from '../../CreateNote/assets/media_active.svg';
import { ReactComponent as MediaInactive } from '../../CreateNote/assets/media_inactive.svg';
import { ReactComponent as FilesActive } from '../../CreateNote/assets/files_active.svg';
import { ReactComponent as FilesInactive } from '../../CreateNote/assets/files_inactive.svg';
import { POLL_BUTTONS } from '../../CreateNote/buttons';
import useCan from '../../../../../rbac/useCan';
import { general } from '../../../../../rbac/plans';
import { TOOLTIP_CONTENT } from '../../../../../helpers';

const OuterWrap = styled.div`
  overflow-x: hidden;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  @media (min-width: 660px) {
    flex-direction: row;
  }
`;

const SubsectionButtonsWrap = styled.div`
  margin-left: 0;
  display: flex;
  align-items: flex-start;
  @media (min-width: 660px) {
    align-items: flex-end;
    margin-left: auto;
  }
`;

const PollOptionsWrapper = styled.div`
  margin-left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
  margin-bottom: 2px;
  @media (min-width: 660px) {
    align-items: center;
    margin-left: auto;
  }
`;

const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  ${noScrollBar}
  justify-content: space-between;
  margin-bottom: ${props => (props.subsectionVisible ? '20px' : 0)};
  @media (min-width: 660px) {
    justify-content: flex-start;
    margin-bottom: 0;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 9px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  color: ${props => (props.toggled ? '#ffffff' : '#7b7b7b')};
  @media (min-width: 660px) {
    margin-right: 24px;
    justify-content: flex-start;
  }
  ${props => props.css}
`;

const AttachmentButtonWrap = styled(ButtonWrap)`
  transition: opacity 0.5s ease-in-out;
  cursor: ${props => (props.enabled ? 'pointer' : 'not-allowed')};
  opacity: ${props => (props.enabled ? 1 : 0.5)};
`;

const Button = ({ onClick, toggled, children, ...props }) => (
  <ButtonWrap onClick={onClick} toggled={toggled} {...props}>
    {children}
  </ButtonWrap>
);

const AttachmentButton = ({ onClick, toggled, children, enabled, ...props }) => (
  <AttachmentButtonWrap enabled={enabled} onClick={onClick} toggled={toggled} {...props}>
    {children}
  </AttachmentButtonWrap>
);

const Label = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #6a6a6a;
  margin-bottom: 10px;
  color: ${props => (props.toggled ? '#11A9FF' : '#6A6A6A')};
`;

const PollLabel = styled.div`
  font-size: 13px;
  line-height: 15px;
  font-weight: ${props => (props.toggled ? 'bold' : 'normal')};
  background-color: ${props => (props.toggled ? '#11A9FF' : '#FFFFFF')};
  color: ${props => (props.toggled ? '#FFFFFF' : '#6A6A6A')};
  padding: 13px 22px;
  border-radius: 50px;
`;

const RemainingShoutouts = styled.div`
  display: inline-block;
  text-align: center;
  border-radius: 25px;
  min-width: 18px;
  height: 18px;
  margin-bottom: 20px;
  padding: 0 5px;
  font-size: 13px;
  line-height: 15px;
  color: #11a9ff;
`;

const ShoutoutsCount = styled.span`
  background-color: #ffffff;
  border-radius: 50%;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  padding: 8px;
  margin-right: 8px;
  width: 36px;
  height: 36px;
  display: inline-flex;
  text-align: center;
  justify-content: center;
`;

const AttachmentsButton = ({
  name,
  attachmentsToggled,
  onClickAttachments,
  active,
  inactive,
  enabled,
  reachedDataLimit,
  isAccountAdmin,
}) => {
  return (
    <AttachmentButton
      enabled={enabled && !reachedDataLimit}
      toggled={attachmentsToggled}
      onClick={onClickAttachments}
      data-tip
      data-for="media-tooltip"
    >
      {reachedDataLimit && (
        <ReactTooltip
          id="media-tooltip"
          class="react-tooltip limit-reached"
          event="click"
          place="top"
          type="dark"
          effect="solid"
          globalEventOff="click"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: TOOLTIP_CONTENT(isAccountAdmin),
            }}
          />
        </ReactTooltip>
      )}
      <Label className="media-target" toggled={attachmentsToggled}>
        {name}
      </Label>
      {attachmentsToggled ? active : inactive}
    </AttachmentButton>
  );
};

const Buttons = ({
  toggled,
  onClick,
  buttons,
  onClickAttachments,
  showFileDropzone,
  showMediaDropzone,
  shownFileDropzone,
  shownMediaDropzone,
  reachedDataLimit,
  ...props
}) => {
  const canAccessQuestionnaires = useCan(general.accessQuestionnaires);
  const attachmentsEnabled =
    !toggled || toggled.includes('create-poll') || toggled.includes('create-discussion');
  const attachmentsVisible =
    !toggled ||
    toggled.includes('create-poll') ||
    toggled.includes('create-discussion') ||
    toggled.includes('create-questionnaire') ||
    toggled.includes('create-shoutout');
  const subsectionVisible =
    (toggled && (toggled.includes('create-poll') || toggled.includes('create-shoutout'))) ||
    (toggled && (toggled === 'create-questionnaire' || toggled === 'create-poll'));
  return (
    <OuterWrap>
      <ButtonsWrap {...props} subsectionVisible={subsectionVisible}>
        {attachmentsVisible && (
          <AttachmentsButton
            attachmentsToggled={shownMediaDropzone}
            onClickAttachments={showMediaDropzone}
            active={<MediaActive />}
            inactive={<MediaInactive />}
            enabled={attachmentsEnabled}
            name="Media"
            reachedDataLimit={reachedDataLimit}
          />
        )}
        {buttons.map((button, index) => {
          const active =
            toggled === button.key ||
            (toggled === 'create-questionnaire' && button.key === 'create-poll');
          return (
            <Button
              key={index}
              toggled={active}
              onClick={() => onClick(button.key)}
              data-intercom-target={`${button.caption} tab`}
            >
              <Label className={`${button.key}-target`} toggled={active}>
                {button.caption}
              </Label>
              {button.icons
                ? toggled && active
                  ? button.icons.active
                  : button.icons.inactive
                : null}
            </Button>
          );
        })}
        {attachmentsVisible && (
          <AttachmentsButton
            attachmentsToggled={shownFileDropzone}
            onClickAttachments={showFileDropzone}
            active={<FilesActive />}
            inactive={<FilesInactive />}
            enabled={attachmentsEnabled}
            name="Files"
            reachedDataLimit={reachedDataLimit}
          />
        )}
      </ButtonsWrap>
      {subsectionVisible && (
        <SubsectionButtonsWrap>
          {toggled && toggled.includes('create-shoutout') && (
            <RemainingShoutouts>
              <ShoutoutsCount>{props.remainingShoutouts}</ShoutoutsCount>{' '}
              {`${props.remainingShoutouts === 1 ? 'Shoutout' : 'Shoutouts'}`} left to give this
              month
            </RemainingShoutouts>
          )}
          {toggled &&
            (toggled.includes('create-poll') || toggled.includes('create-questionnaire')) && (
              <PollOptionsWrapper>
                {POLL_BUTTONS(canAccessQuestionnaires).map((button, index) => (
                  <Button
                    key={index}
                    toggled={toggled === button.key}
                    onClick={() => onClick(button.key)}
                    data-intercom-target={`${button.caption} tab`}
                    css={css`
                      margin-right: 18px !important;
                      margin-left: 0 !important;
                      @media (min-width: 660px) {
                        margin-right: 0 !important;
                        margin-left: 18px !important;
                      }
                    `}
                  >
                    <PollLabel className={`${button.key}-target`} toggled={toggled === button.key}>
                      {button.caption}
                    </PollLabel>
                  </Button>
                ))}
              </PollOptionsWrapper>
            )}
        </SubsectionButtonsWrap>
      )}
    </OuterWrap>
  );
};

Buttons.propTypes = {
  toggled: PropTypes.string.isRequired,
  attachmentsToggled: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onClickAttachments: PropTypes.func.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      caption: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

Buttons.defaultProps = {
  attachmentsToggled: '',
  onClickAttachments: () => {},
};

const mapStateToProps = state => ({
  isAccountAdmin: state.usersDomain.user.isAccountAdmin,
  remainingShoutouts: state.usersDomain.user.remainingShoutouts,
  reachedDataLimit: state.plansDomain.reachedDataLimit,
  isLeadOrAdmin:
    state.usersDomain.user &&
    Boolean(
      state.usersDomain.user.isAccountAdmin || state.usersDomain.user.formalLeadTeamsIds.length
    ),
});

export default connect(mapStateToProps)(Buttons);
