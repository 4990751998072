import qs from 'qs';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import PropTypes from 'prop-types';
import fetchApi from 'fetchApi';
import { htmlspecialchars_decode } from 'helpers';

const seeMoreUrl = (teamId, factorPosition) =>
  `/vibes/performance_over_time?${qs.stringify({
    team: teamId,
    period: 60,
    factor: factorPosition,
  })}`;

export const handleSeeMore = (push, teamId, factorPosition) => {
  push(seeMoreUrl(teamId, factorPosition));
};

export const viewGrowPost = (push, postId, search) => {
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  push(
    `/${growProfileUrl}?${qs.stringify({
      ...params,
      post: postId,
    })}`
  );
};

export const handleComplete = async actionPlanId => {
  await fetchApi(`/action_plan/complete/${actionPlanId}`, {
    method: 'put',
  });
};

export const convertManagerPromptToWin = (setAction, note, idToConvert) => {
  const {
    content,
    goals,
    company_values,
    title,
    due_date,
    media,
    member_id,
    category,
    files,
  } = note;
  const formattedContent = htmlspecialchars_decode(content).replace(/<br\s*[\/]?>/gi, '\n');
  const sharedTeam = note?.action_plan.team;
  setAction('create-note-win', {
    id: idToConvert,
    title,
    media,
    oldFiles: files,
    noteRecipient: member_id,
    category,
    dueDate: due_date,
    content: formattedContent,
    companyValues: company_values.map(value => value.name),
    goals: goals.map(value => value.name),
    shareWith: sharedTeam
      ? [
          {
            image_url: sharedTeam.image_url,
            label: sharedTeam.name,
            title: '',
            thumbnail_details: sharedTeam.thumbnail_details,
            value: `${sharedTeam.id}_team`,
            type: 'team',
            id: sharedTeam.id,
          },
        ]
      : [],
    isSharing: Boolean(sharedTeam),
    converting: 'win',
  });
};

export const noteShape = {
  content: PropTypes.string.isRequired,
  action_plan: PropTypes.shape({
    id: PropTypes.number.isRequired,
    team_id: PropTypes.number.isRequired,
    factor_position: PropTypes.number.isRequired,
    main_grow_post: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};
