import React, { Component } from 'react';

class AutotrimString extends Component {
  state = {
    string: '',
  };

  lastAction = 'shrink'; // ---shrink/grow

  handleTrim = e => {
    if (e) {
      if (this.prevViewportWidth > window.innerWidth) this.lastAction = 'shrink';
      else this.lastAction = 'grow';
    }
    const selfWidth = parseInt(window.getComputedStyle(this.self).getPropertyValue('width'), 10);
    const parentWidth = parseInt(
      window.getComputedStyle(this.self.parentNode).getPropertyValue('width'),
      10
    );
    if (
      selfWidth + this.offset > parentWidth &&
      this.lastAction !== 'grow' &&
      this.state.string.length > this.minLength
    ) {
      this.lastAction = 'shrink';
      this.setState(
        {
          string: this.state.string.substr(0, this.state.string.length - 1),
        },
        () => this.handleTrim()
      );
    } else if (
      selfWidth + this.offset < parentWidth &&
      this.state.string !== this.props.string &&
      this.lastAction !== 'shrink'
    ) {
      this.lastAction = 'grow';
      this.setState(
        {
          string: this.props.string.substr(0, this.state.string.length + 1),
        },
        () => this.handleTrim()
      );
    }
    this.prevViewportWidth = window.innerWidth;
  };

  componentDidMount = () => {
    this.offset = this.props.offset ? this.props.offset : 30;
    this.minLength = this.props.minLength ? this.props.minLength : 8;
    window.addEventListener('resize', this.handleTrim);
    this.setState({ string: this.props.string }, () => this.handleTrim());
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleTrim);
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.string !== this.props.string)
      this.setState({ string: nextProps.string }, () => this.handleTrim());
  };

  render() {
    return (
      <span
        ref={el => (this.self = el)}
        className={`autotrim ${this.props.className ? this.props.className : ''}`}
        style={{
          whiteSpace: 'nowrap',
          display: 'inline-block',
        }}
      >
        {this.state.string}
        {this.state.string !== this.props.string ? (
          <span
            className="ending"
            style={{
              display: 'inline-block',
              marginLeft: 4,
            }}
          >
            {this.props.ending}
          </span>
        ) : (
          ''
        )}
      </span>
    );
  }
}

export default AutotrimString;
