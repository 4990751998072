import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { APP_URL } from '../../constants';
import { BODY_CLASSES, CHECK_UNAUTHORIZED, CONVERT_NAME, HEADERS } from '../../helpers';
import LoadingScale from '../shared/LoadingScale';

class TeamsNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      teams: [],
      teamName: '',
      teamParentId: '',
      redirect: false,
      message: '',
      error: '',
      disabled: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.props.closeFlash();
  }

  componentWillMount() {
    BODY_CLASSES(['teams', 'new']);
    this.props.setHeader('Create new team');
    const headers = HEADERS();
    fetch(`${APP_URL}/teamsList`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({ teams: jsonResponse.teams });
        });
      })
      .catch(() => {});
  }

  setTeamName(e) {
    this.setState({ teamName: e.target.value });
  }

  setParentTeam(e) {
    this.setState({ teamParentId: e.target.value });
  }

  createTeam(e) {
    e.preventDefault();
    this.setState({ loading: true });
    this.setState({ disabled: true });
    const data = new FormData();
    data.append('team[name]', this.state.teamName);
    if (this.state.teamParentId !== '') {
      data.append('team[parent_id]', this.state.teamParentId);
    } else {
      data.append('team[parent_id]', this.state.teams[0].id);
    }
    const headers = HEADERS();
    fetch(`${APP_URL}/teams/store`, { method: 'post', headers, body: data })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({ teamId: jsonResponse.teamId });
          if (jsonResponse.message) {
            this.setState({ message: jsonResponse.message });
            this.setState({ redirect: true });
          }
          if (jsonResponse.error) {
            this.setState({ error: jsonResponse.error });
            this.setState({ disabled: false });
          }
          this.setState({ loading: false });
        });
      })
      .catch(() => {});
  }

  render() {
    if (this.state.redirect === true) {
      return (
        <Redirect
          push
          to={{
            pathname: `/team/${this.state.teamId}`,
            state: {
              message: this.state.message,
            },
          }}
        />
      );
    }
    let classes = 'form-group string required team_name';
    if (this.state.error !== '') {
      classes = 'form-group string required team_name has-error';
    }
    return (
      <div className="form-container">
        {this.state.loading && <LoadingScale />}
        <form className="simple_form new_team" id="new_team">
          <div className={classes}>
            <label className="string required control-label" htmlFor="team_name">
              Space name
            </label>
            <input
              type="text"
              className="string required form-control"
              id="team_name"
              name="team[name]"
              onChange={e => this.setTeamName(e)}
            />
            <p className="help-block">eg. Management, Accounting, Human Resources.</p>
            <span className="help-block">{this.state.error}</span>
          </div>
          <div className="form-group select optional team_parent">
            <label htmlFor="team_parent_id" className="select optional control-label">
              Parent Space
            </label>
            <select
              name="team[parent_id]"
              id="team_parent_id"
              tabIndex={-1}
              aria-hidden="true"
              className="select optional select2 form-control"
              onChange={e => this.setParentTeam(e)}
            >
              {this.state.teams.map(team => (
                <option value={team.id} key={team.id}>
                  {CONVERT_NAME(team.name)}
                </option>
              ))}
            </select>
            <p className="help-block">The group this space belongs to.</p>
          </div>
          <hr />
          <div className="clear">
            <input
              id="create-team-btn"
              type="submit"
              className="btn btn-lg btn-primary pull-right btn-wide"
              name="commit"
              value="Save"
              disabled={this.state.disabled}
              onClick={e => this.createTeam(e)}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default TeamsNew;
