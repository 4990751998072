/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import { withRouter } from 'react-router-dom';
import feedTour from './feedTour.svg';
import insightsTour from './insightsTour.svg';
import finishedTour from './finishedTour.svg';

const TourImage = ({ type, ...props }) => {
  switch (type) {
    case 'finished':
      return <img src={finishedTour} alt={type} />;
    case 'insights':
      return <img src={insightsTour} alt={type} />;
    case 'feed':
    case 'grow':
      return <img src={feedTour} alt={type} />;
  }
};

const TourTitle = ({ type, selected, ...props }) => {
  let tourTitle = '';
  switch (type) {
    case 'insights':
      tourTitle = 'Insights';
      if (!selected) {
        tourTitle = 'Show me how Insights works';
      }
      break;
    case 'feed':
      tourTitle = 'Feed';
      if (!selected) {
        tourTitle = 'Show me how the Feed works';
      }
      break;
    case 'grow':
      tourTitle = 'Grow section';
      if (!selected) {
        tourTitle = 'Show me how Grow works';
      }
  }
  return (
    <h3
      css={css`
        text-align: center;
        font-size: 15px;
        line-height: 140%;
        color: ${selected ? '#FFFFFF' : '#11A9FF'};
        padding-top: 24px;
        max-width: 130px;
        margin: 0 auto;
      `}
    >
      {tourTitle}
    </h3>
  );
};

const TourType = ({
  content,
  selected,
  resetChecklist,
  type,
  repeatFunction,
  history,
  disabled,
  ...props
}) => {
  let redirectUrl = '';
  switch (type) {
    case 'insights':
      redirectUrl = '/vibes/dashboard';
      break;
    case 'feed':
      redirectUrl = '/';
      break;
    case 'grow':
      redirectUrl = '/grow_profile';
      break;
  }
  return (
    <div
      onClick={() => {
        if (!selected && !disabled) {
          repeatFunction();
          history.push(redirectUrl);
        }
      }}
      css={css`
        color: ${selected ? '#FFFFFF' : '#11A9FF'};
        background-color: ${selected ? '#11A9FF' : '#FFFFFF'};
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 140%;
        width: 190px;
        height: 206px;
        border: 1px solid ${selected ? '#11A9FF' : '#C8EBFF'};
        border-radius: 6px;
        display: inline-flex;
        padding-top: 31px;
        margin: 0 17px;
        justify-content: center;
        cursor: ${selected || disabled ? 'auto' : 'pointer'};
        opacity: ${disabled ? '40%' : '100%'};
      `}
      {...props}
    >
      <div
        css={css`
          text-align: center;
        `}
      >
        <TourImage type={selected ? 'finished' : type} />
        <TourTitle type={type} selected={selected} />
        {selected && (
          <div
            onClick={() => {
              if (!disabled) {
                repeatFunction();
                history.push(redirectUrl);
              }
            }}
            css={css`
              text-align: center;
              font-size: 13px;
              line-height: 140%;
              color: #ffffff;
              padding-top: 12px;
              text-decoration: underline;
              cursor: pointer;
            `}
          >
            Repeat
          </div>
        )}
      </div>
      {content}
    </div>
  );
};

const ModalHeader = ({ type, ...props }) => {
  let modalHeader = '';
  switch (type) {
    case 'insights':
      modalHeader = "That's it for Insights!";
      break;
    case 'feed':
      modalHeader = "That's it for the Feed and Spaces!";
      break;
    case 'grow':
      modalHeader = "That's it for Grow!";
      break;
    case 'setup':
      modalHeader = "That's it for Setup!";
      break;
  }
  return (
    <h3
      css={css`
        color: #323232;
        font-weight: 900;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        margin-bottom: 24px;
      `}
    >
      {modalHeader}
    </h3>
  );
};

const Description = ({ type, content, ...props }) => (
  <p
    css={css`
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      padding-bottom: 35px;
      text-align: center;
      max-width: 575px;
      margin: 0 auto;
    `}
    {...props}
  />
);

const TourModal = ({
  showTourModal,
  toggleTourModal,
  startFeedTour,
  finishedTour,
  startInsightsTour,
  startGrowTour,
  isAccountAdmin,
  isTeamLead,
  feedTourFinished,
  growTourFinished,
  insightsTourFinished,
  growEnabled,
  feedEnabled,
  ...props
}) => {
  const { history } = props;
  const tours = ['feed', 'insights', 'grow'];
  const [modalOpened, setModalOpened] = useState(false);
  return (
    <Modal
      show={showTourModal}
      onHide={() => {
        if (modalOpened) {
          setModalOpened(false);
          toggleTourModal(!showTourModal);
        }
      }}
      dialogClassName="tour-modal"
      backdrop
      keyboard
      onEntered={() => setModalOpened(true)}
      onExit={() => setModalOpened(false)}
    >
      <div
        css={css`
          width: 766px;
          padding: 44px 0 34px;
          text-align: center;
        `}
      >
        <ModalHeader type={finishedTour} />
        <Description>
          Check out the other sections of the app or start connecting and collaborating now. You can
          always access the tour from the
          <span
            css={css`
              background-color: #11a9ff;
              color: #ffffff;
              font-size: 13px;
              height: 20px;
              width: 20px;
              border-radius: 50%;
              display: inline-block;
              margin: 0 7px;
            `}
          >
            ?
          </span>
          at the top right of your screen.
        </Description>
        {tours.map((tour, index) => (
          <TourType
            type={tour}
            key={index}
            selected={
              tour === 'insights'
                ? insightsTourFinished
                : tour === 'grow'
                ? growTourFinished
                : feedTourFinished
            }
            repeatFunction={
              tour === 'insights'
                ? startInsightsTour
                : tour === 'grow'
                ? startGrowTour
                : startFeedTour
            }
            history={history}
            disabled={(tour === 'grow' && !growEnabled) || (tour === 'feed' && !feedEnabled)}
          />
        ))}
        <div
          onClick={() => toggleTourModal(false)}
          css={css`
            color: #11a9ff;
            font-size: 13px;
            line-height: 140%;
            margin-top: 42px;
            text-decoration: underline;
            cursor: pointer;
          `}
        >
          I'll finish later
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  showTourModal: state.onboardingDomain.showTourModal,
  finishedTour: state.onboardingDomain.finishedTour,
  isAccountAdmin: !!state.usersDomain.user && Boolean(state.usersDomain.user.isAccountAdmin),
  isTeamLead: !!state.usersDomain.user && Boolean(state.usersDomain.user.formalLeadTeamsIds.length),
  feedTourFinished: state.onboardingDomain.feedTourFinished,
  insightsTourFinished: state.onboardingDomain.insightsTourFinished,
  growTourFinished: state.onboardingDomain.growTourFinished,
  feedEnabled: state.usersDomain.feedEnabled,
  growEnabled: state.usersDomain.growEnabled,
});

const mapDispatch = {
  toggleTourModal: toggle => ONBOARDING_ACTIONS.toggleTourModal(toggle),
  startFeedTour: () => ONBOARDING_ACTIONS.startFeedTour(),
  startGrowTour: () => ONBOARDING_ACTIONS.startGrowTour(),
  startInsightsTour: () => ONBOARDING_ACTIONS.startInsightsTour(),
};

export default withRouter(connect(mapStateToProps, mapDispatch)(TourModal));
