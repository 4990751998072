/** @jsx jsx */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { MentionsInput, Mention } from 'react-mentions';
import { connect } from 'react-redux';
import qs from 'qs';
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import {
  SHOW_MENTION_TOOLTIP,
  HIDE_MENTION_TOOLTIP,
} from 'components/shared/tooltips/MentionTooltip';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';
import { APP_URL } from '../../constants';

const MentionWrap = styled.div`
  width: 100%;
  display: flex;
  textarea {
    min-height: 65px;
    @media (min-width: 450px) {
      min-height: 56px;
    }
  }
`;

const SendButton = styled.div`
  position: relative;
  font-weight: bold;
  font-size: 13px;
  color: #11a9ff;
  border: 1px solid #e7e7e7;
  border-left: 0;
  border-radius: 0 4px 4px 0;
  line-height: 38px;
  min-width: 60px;
  text-align: center;
  cursor: pointer;
`;

const ButtonCaption = styled.div`
  position: absolute;
  bottom: 0;
  left: 14px;
`;

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({
  hashtags: state.tagsDomain.hashtags,
});

// setup AbortController
let controller;
// signal to pass to fetch
let signal;

class MentionsInputWrapper extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();

    if (props.enableMentions) {
      // do not use react fragment here as it terribly slows down the component
      this.mention = () => [
        <Mention
          trigger={this.mentionRegex}
          data={this.getData}
          type="people"
          appendSpaceOnAdd
          renderSuggestion={this.renderUserSuggestion}
          onAdd={this.styleMention}
          key="mention-people"
        />,
        <Mention
          trigger={this.hashtagRegex}
          type="hashtags"
          data={this.getTagData}
          renderSuggestion={this.renderTagSuggestion}
          appendSpaceOnAdd
          key="mention-hashtags"
        />,
      ];
    } else {
      this.mention = () => null;
    }
  }

  state = {
    users: [],
    mentions: [],
    refForwarded: false,
  };

  componentDidMount() {
    // --Even though you can pass value formatted with markups to MentionsInput, it doesn't trigger onChange required to get all mentions
    // --from text (including users slugs needed to display tooltips)
    // --This problem is solved by extracting mention information beforehand and passing them to wrapper's state
    this.setState({ mentions: this.props.mentions ? this.props.mentions : [] }, () =>
      this.styleMention()
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.showSendButton !== prevProps.showSendButton) {
      const node = ReactDOM.findDOMNode(this.inputRef).querySelector('textarea');
      node.focus();
      const { length } = node.value;
      node.setSelectionRange(length, length);
    }
  }

  renderDisplay = (id, display, type) => {
    if (type === 'hashtags') {
      return `#${id}`;
    }
    return `${display}`;
  };

  getData = (search, callback) => {
    if (!search.trim()) return;
    if (controller !== undefined) {
      // Cancel the previous request
      controller.abort();
    }
    if ('AbortController' in window) {
      controller = new window.AbortController();
      signal = controller.signal;
    }
    const headers = HEADERS();
    fetch(`${APP_URL}/account_users?${qs.stringify({ phrase: search })}`, {
      method: 'get',
      headers,
      signal,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => response.json())
      .then(json =>
        json.map(user => ({
          display: `${user.first_name}\u00A0${user.last_name}`,
          id: user.slug,
          img: user.image_url,
        }))
      )
      .then(callback)
      .catch(error => {});
  };

  renderUserSuggestion = entry => (
    <div className="mention-suggestion">
      <img src={entry.img} alt="avatar" />
      <span>
        {entry.display} ({entry.id})
      </span>
    </div>
  );

  renderTagSuggestion = entry => <div className="hashtag-suggestion">#{entry.id}</div>;

  getTagData = search => {
    const tags = this.props.hashtags;
    if (search.length > 0) {
      return tags.filter(tags => tags.id.includes(search)).slice(0, 10);
    }

    // --Display is required for correct markup detection when inserting mentions or hashtags with
    // --buttons on feed
    return tags.slice(0, 10).map(tag => ({
      ...tag,
      display: tag.id,
    }));
  };

  styleMention = () => {
    setTimeout(() => {
      const mentionElements = Array.from(
        document.querySelectorAll('.mentions__highlighter strong')
      );
      this.state.mentions
        .filter(mention => mention.type === 'people')
        .forEach(mention => {
          mentionElements.forEach(element => {
            if (element.innerHTML.replace(/&nbsp;/g, '\u00A0') === mention.display) {
              element.setAttribute('slug', mention.id);
              element.classList.add('mention');
              element.removeEventListener('mouseover', SHOW_MENTION_TOOLTIP);
              element.addEventListener('mouseover', SHOW_MENTION_TOOLTIP);

              element.removeEventListener('mouseleave', HIDE_MENTION_TOOLTIP);
              element.addEventListener('mouseleave', HIDE_MENTION_TOOLTIP);
            }
          });
        });
      this.state.mentions
        .filter(mention => mention.type === 'hashtags')
        .forEach(hashtag => {
          mentionElements.forEach(element => {
            if (element.innerHTML === `#${hashtag.id}`) {
              element.classList.add('hashtag');
            }
          });
        });
    }, 0);
  };

  contentChangedHandler = (event, newValue, newPlainTextValue, mentions) => {
    this.setState({ mentions }, () => this.styleMention());
    this.props.contentChangedHandler(event, newPlainTextValue);
  };

  mentionRegex = /(@([a-z A-Z]*))(?![a-z A-Z]*[@#][A-Z a-z]*)/;

  hashtagRegex = /(#([a-zA-Z 0-9]*))(?![a-zA-Z 0-9]*[@#][A-Za-z 0-9]*)/;

  // ---All padding styles should be applied to wrapper, as Textarea in MentionsInput is positioned absolutely and it's text won't match
  // ---the position of TextHighlight that can be styled properly
  render = () => (
    <MentionWrap>
      <div
        css={
          this.props.showSendButton
            ? css`
                border-radius: 4px 0 0 4px !important;
              `
            : null
        }
        className={`mentions-input-wrap ${this.props.className}`}
      >
        <MentionsInput
          ref={ref => {
            this.inputRef = ref;
            // --Nasty workaround here - using React's forwardRef() creates big mess when used on higher order components
            // --This solution is a lot more transparent
            if (this.props.setInputRef && ReactDOM.findDOMNode(ref) && !this.state.refForwarded) {
              this.setState({ refForwarded: true });
              this.props.setInputRef(ReactDOM.findDOMNode(ref).querySelector('textarea'));
            }
          }}
          autoFocus={this.props.focus}
          onFocus={function (e) {
            const val = e.target.value;
            e.target.value = '';
            e.target.value = val;
          }}
          maxLength={this.props.maxLength}
          className="mentions"
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.contentChangedHandler}
          onSelect={this.props.handleSelection}
          onBlur={e => {
            this.styleMention();
            if (this.props.handleBlur) this.props.handleBlur(e);
          }}
          markup="[-/-markupStart-/-]__type__:__id__[--__display__--]"
          displayTransform={this.renderDisplay}
          spellCheck="false"
          allowSpaceInQuery
          // ---Required for reapplying styles to mentions
          onKeyDown={e => {
            if (this.props.onKeyDown) this.props.onKeyDown(e);
            this.styleMention();
          }}
          onClick={this.styleMention}
          onContextMenu={this.styleMention}
          onKeyUp={this.props.onKeyUp}
        >
          {this.mention()}
        </MentionsInput>
      </div>
      {this.props.showSendButton && (
        <SendButton
          onClick={() => {
            const event = {
              key: 'Enter',
              target: { value: { trim: () => true } },
              preventDefault: () => {},
            };
            return this.props.onKeyDown(event);
          }}
        >
          <ButtonCaption>ADD</ButtonCaption>
        </SendButton>
      )}
    </MentionWrap>
  );
}

MentionsInputWrapper.defaultProps = {
  enableMentions: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(MentionsInputWrapper);
