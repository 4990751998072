import React from 'react';
import { BODY_CLASSES } from 'helpers';
import PlanSizeSelect from 'components/payments/PaymentChoose/PlanSizeSelect';
import MobilePlanSelector from 'components/payments/PaymentChoose/MobilePlanSelector';
import planTypes from 'components/payments/PaymentChoose/planTypes';
import DowngradeModal from 'components/payments/PaymentChoose/DowngradeModal';
import UpgradeModalContainer from 'components/payments/PaymentChoose/UpgradeModalContainer';
import fetchApi from 'fetchApi';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ContactModal from './PaymentChoose/ContactModal';
import SpacesTooltip from './PaymentChoose/tooltips/SpacesTooltip';
import GrowTooltip from './PaymentChoose/tooltips/GrowTooltip';
import VibeTooltip from './PaymentChoose/tooltips/VibeTooltip';
import DesktopPlanSelector from './PaymentChoose/DesktopPlanSelector';
import FaqSection from './PaymentChoose/FaqSection';

const source = {
  enterprise: 'enterprise',
  annual: 'annual',
};

const modal = {
  contact: 'contact',
  freeMonth: 'freeMonth',
  downgrade: 'downgrade',
  upgrade: 'upgrade',
};

class PaymentChoose extends React.Component {
  state = {
    showModal: false,
    source: '',
    freeMonth: false,
    planSize: Object.keys(planTypes)[0],
    upgradeModalData: {},
    currency: 'usd',
    show: false,
  };

  componentDidMount() {
    const { location, history } = this.props;
    if (location.search) {
      history.replace('/payments/choose_plan');
    }
    BODY_CLASSES(['settings', 'edit', 'heelix', 'settings-page', 'choose-plan']);

    this.fetchData();
  }

  handleClose = () => {
    this.setState({ showModal: false, source: '' });
  };

  handleOpen = source => () => {
    this.setState({ showModal: modal.contact, source });
  };

  openDowngradeModal = () => {
    this.setState({ showModal: modal.downgrade });
  };

  openUpgradeModal = (plan, planSize) => {
    this.setState({
      showModal: modal.upgrade,
      upgradeModalData: { plan, planSize },
    });
  };

  fetchData = async () => {
    const data = await fetchApi('/payments/choose');
    this.setState({ currency: data.currency, show: data.show });
  };

  render() {
    const { updateFlash, isManaged } = this.props;
    const {
      source: source1,
      upgradeModalData,
      show,
      currency,
      freeMonth,
      showModal,
      planSize,
    } = this.state;
    return isManaged || !show ? null : (
      <div className="settings-wrapper pricing">
        <div className="row">
          <div className="col-xs-12">
            <h1 id="settings-title" className="text-center">
              Choose your plan
            </h1>
          </div>
        </div>
        <div className="picker">
          <PlanSizeSelect value={planSize} setValue={planSize => this.setState({ planSize })} />
          <MobilePlanSelector
            openContactModal={this.handleOpen(source.enterprise)}
            planSize={planSize}
            currency={currency}
            openDowngradeModal={this.openDowngradeModal}
            openUpgradeModal={this.openUpgradeModal}
          />
          <DesktopPlanSelector
            openContactModal={this.handleOpen(source.enterprise)}
            freeMonth={freeMonth}
            planSize={planSize}
            currency={currency}
            openDowngradeModal={this.openDowngradeModal}
            openUpgradeModal={this.openUpgradeModal}
          />
        </div>
        <FaqSection openContactModal={this.handleOpen(source.annual)} />
        <VibeTooltip id="vibe-tooltip" />
        <GrowTooltip id="grow-tooltip" />
        <SpacesTooltip id="spaces-tooltip" />
        <ContactModal
          handleClose={this.handleClose}
          show={showModal === modal.contact}
          source={source1}
        />
        <DowngradeModal
          show={showModal === modal.downgrade}
          handleClose={this.handleClose}
          updateFlash={updateFlash}
        />
        <UpgradeModalContainer
          show={showModal === modal.upgrade}
          handleClose={this.handleClose}
          updateFlash={updateFlash}
          planData={upgradeModalData}
        />
      </div>
    );
  }
}

const mapState = state => ({
  isManaged: state.plansDomain.isManaged,
});

PaymentChoose.propTypes = {
  updateFlash: PropTypes.func.isRequired,
  isManaged: PropTypes.bool.isRequired,
};

export default connect(mapState)(PaymentChoose);
