/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import Flash from 'components/shared/Flash';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import MeetingSchedule from 'components/vibes/createForms/CreateMeeting/MeetingSchedule';
import MeetingScheduleSuccess from '../../../onboarding/MeetingScheduleSuccess';
import NoteButtons from '../CreateNote/NoteButtons';

const CreateMeetingWrapper = styled.div`
  padding: 0;
  border-radius: 0 0 8px 8px;
  background: #f3f3f3;
  display: flex;
  flex-wrap: wrap;
`;
const NoteButtonsWrapper = styled.div`
  padding: 0 24px;
  width: 100%;
`;

const MeetingScheduleSuccessWrapper = styled.div`
  margin-top: 20px;
`;

const CreateMeeting = ({
  settings,
  stopShowingButton,
  onClick,
  setRedirectToYourSchedule,
  converting,
  closePopup,
  users,
  leads,
  handleResponse,
  flash,
  closeFlash,
  closeMeetingModal,
  toggledMode,
}) => {
  const [step, setStep] = useState('set-up-meeting');
  const [meetingMember, setMeetingMember] = useState(null);
  const [reminder, setReminder] = useState(null);

  const nextStep = () => {
    closeMeetingModal();
    switch (step) {
      case 'set-up-meeting':
        setStep('success');
        break;
      case 'success':
        setStep('set-up-meeting');
        break;
      default:
        setStep('success');
        break;
    }
  };

  return (
    <CreateMeetingWrapper>
      {flash.toggled && flash.message && (
        <div
          css={css`
            width: 100%;
            margin-bottom: 15px;
          `}
        >
          <Flash
            closeFlash={closeFlash}
            flashMessage={flash.message}
            flashType={flash.type}
            dismissable
          />
        </div>
      )}
      <NoteButtonsWrapper>
        <NoteButtons onClick={onClick} toggled={toggledMode} converting={converting} />
      </NoteButtonsWrapper>
      {step === 'set-up-meeting' ? (
        <MeetingSchedule
          nextStep={nextStep}
          setMeetingMember={setMeetingMember}
          setReminder={setReminder}
          settings={settings}
          stopShowingButton={stopShowingButton}
          setRedirectToYourSchedule={setRedirectToYourSchedule}
          users={users}
          leads={leads}
          handleResponse={handleResponse}
          toggledMode={toggledMode}
        />
      ) : (
        <MeetingScheduleSuccessWrapper>
          <MeetingScheduleSuccess
            nextStep={nextStep}
            member={meetingMember}
            reminder={reminder}
            setStep={setStep}
            closePopup={closePopup}
          />
        </MeetingScheduleSuccessWrapper>
      )}
    </CreateMeetingWrapper>
  );
};
const mapStateToProps = state => ({
  converting: state.app.body.converting,
  isAccountAdmin: state.usersDomain.user.isAccountAdmin,
  settings: state.onboardingDomain.settings,
  stopShowingButton: state.onboardingDomain.stopShowingButton,
});

const mapDispatchToProps = dispatch => ({
  closeMeetingModal: () => dispatch(ONBOARDING_ACTIONS.closeMeetingModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateMeeting);
