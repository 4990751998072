import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
`;
export const FormContent = styled.form`
  position: relative;
  padding: 0 20px 20px;
  text-align: left;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
  @media (max-width: 577px) {
    div.select-question {
      width: 100%;
    }
  }
`;
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  text-align: right;
  background-color: #ffffff;
  padding: 15px;

  a.btn {
    font-size: 14px;
    line-height: 35px;
  }
`;
export const SwitchButtonWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
`;
export const SwitchButtonText = styled.div`
  font-size: 15px;
  line-height: 150%;
  color: #323232;
  margin-left: 5px;
`;
export const PromptWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;

  > .select-question {
    width: 47% !important;
  }
`;
