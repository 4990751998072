import fetchApi from 'fetchApi';
import DOMAINS from '../domains';

export const PLAN_ACTION_TYPES = {
  SHOW_UPGRADE_MODAL: 'SHOW_UPGRADE_MODAL',
  HIDE_UPGRADE_MODAL: 'HIDE_UPGRADE_MODAL',
  RECEIVE_PLAN_LIMITATIONS: 'RECEIVE_PLAN_LIMITATIONS',
  RECEIVE_DATA_LIMIT: 'RECEIVE_DATA_LIMIT',
  HIDE_PROMPT: 'HIDE_PROMPT',
  SHOW_PROMPT: 'SHOW_PROMPT',
};

export const PLAN_ACTIONS = {
  showUpgradeModal: payload => ({
    domain: DOMAINS.PLANS,
    type: PLAN_ACTION_TYPES.SHOW_UPGRADE_MODAL,
    payload,
  }),
  hideUpgradeModal: () => ({ domain: DOMAINS.PLANS, type: PLAN_ACTION_TYPES.HIDE_UPGRADE_MODAL }),
  receiveDataLimit: json => ({
    domain: DOMAINS.PLANS,
    type: PLAN_ACTION_TYPES.RECEIVE_DATA_LIMIT,
    payload: json,
  }),
  receivePlanLimitations: json => ({
    domain: DOMAINS.PLANS,
    type: PLAN_ACTION_TYPES.RECEIVE_PLAN_LIMITATIONS,
    payload: json,
  }),
  fetchPlanLimitations: () => async dispatch => {
    const response = await fetchApi('/plan_limitations');
    dispatch(PLAN_ACTIONS.receivePlanLimitations(response));
  },
  hidePrompt: () => {
    fetchApi('/prompts', { method: 'delete' });
    return {
      domain: DOMAINS.PLANS,
      type: PLAN_ACTION_TYPES.HIDE_PROMPT,
    };
  },
  showPrompt: prompt => {
    fetchApi('/prompts', { method: 'delete' });
    return {
      domain: DOMAINS.PLANS,
      type: PLAN_ACTION_TYPES.SHOW_PROMPT,
      payload: prompt,
    };
  },
};
