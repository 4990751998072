/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { APP_URL, AWS_S3_URL } from 'constants.js';
import { AVATAR_ERROR, HEADERS } from 'helpers';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { USER_ACTIONS } from 'redux/actions/userActions';
import { setAction, setShowActionModal, toggleUserModal } from 'redux/app/actions';

const Container = styled.div`
  text-align: center;
  padding: 36px 50px 0;
`;

const Avatar = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 22px;
  color: #323232;
  margin-top: 18px;
`;

const Title = styled.div`
  font-size: 13px;
  color: #6a6a6a;
  margin-top: 6px;
`;

const Description = styled.div`
  font-size: 14px;
  color: #323232;
  margin-top: 25px;
`;

const Tags = styled.div`
  font-size: 14px;
  color: #11a9ff;
  word-spacing: 4px;
  margin-top: 20px;
`;

const TimezoneWrap = styled.div`
  font-size: 12px;
  color: #6a6a6a;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
  padding-bottom: 38px;
`;

const Timezone = ({ children, icon }) => (
  <div
    css={css`
      margin: 0 8px;
    `}
  >
    <img
      src={`${AWS_S3_URL}public/images/icons/${icon}.svg`}
      alt=""
      css={css`
        margin-right: 9px;
        position: relative;
        bottom: 1px;
      `}
    />
    {children}
  </div>
);

const VibeStar = () => (
  <img
    css={css`
      margin-left: 1px;
      margin-bottom: 2px;
    `}
    src={`${AWS_S3_URL}public/images/icons/vibe-star.svg`}
    alt=""
  />
);

export const CloseButton = ({ onClick, ...props }) => (
  <img
    css={css`
      position: absolute;
      top: 15px;
      right: 13px;
      cursor: pointer;
      z-index: 1;
    `}
    src={`${AWS_S3_URL}public/images/icons/close-modal.svg`}
    alt=""
    onClick={onClick}
    {...props}
  />
);

const EditButton = ({ userId, onClick }) => (
  <Link
    css={css`
      position: absolute;
      top: 24px;
      left: 5px;
      cursor: pointer;
    `}
    to={`/user/${userId}/edit`}
    onClick={onClick}
  >
    <ActionButton icon="pencil-blue" style={{ color: '#F0932D', size: '25px' }}>
      Edit user
    </ActionButton>
  </Link>
);

const BottomActions = styled.div`
  height: 175px;
  border-top: 1px solid #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 600px) {
    flex-direction: row;
    height: 100px;
  }
  > div {
    @media (max-width: 599.98px) {
      margin: 10px 15px;
    }
  }
`;

const ActionButton = ({
  children,
  icon,
  onClick,
  style: { color = '#11A9FF', size = '30px' } = {},
}) => (
  <div
    onClick={onClick}
    css={css`
      font-size: 14px;
      color: ${color};
      margin: 0 15px;
      display: flex;
      align-items: center;
      cursor: pointer;
    `}
  >
    <div
      css={css`
        background: ${color};
        border-radius: 50%;
        width: ${size};
        height: ${size};
        margin-right: 12px;
        position: relative;
      `}
    >
      <img
        css={css`
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 1px;
          margin: auto;
        `}
        src={`${AWS_S3_URL}public/images/icons/${icon}.svg`}
        alt=""
      />
    </div>
    {children}
  </div>
);

const showRole = role => (role && role.length > 0 ? `${role}, ` : null);

const UserModal = ({
  userId,
  onHide,
  unfollowUser,
  setAction,
  show = false,
  setShowActionModal,
  feedEnabled,
}) => {
  const [user, setUser] = useState({});
  const [teamName, setTeamName] = useState('');
  const [localTime, setLocalTime] = useState('');
  const [showVibeBadge, setShowVibeBadge] = useState(false);
  const [isUnfollowed, setIsUnfollowed] = useState(false);
  const [isAuthUser, setIsAuthUser] = useState(true);
  const [canEdit, setCanEdit] = useState(false);

  const fetchData = async () => {
    if (userId === -1 || userId === undefined) return;
    const headers = HEADERS();
    const response = await fetch(`${APP_URL}/users/show_modal/${userId}`, {
      method: 'get',
      headers,
    });
    const json = await response.json();
    setUser(json.user);
    setTeamName(json.teamName);
    setLocalTime(json.localTime);
    setShowVibeBadge(json.userAlreadyVibed);
    setIsUnfollowed(json.isUnfollowed);
    setIsAuthUser(json.isAuthUser);
    setCanEdit(json.canEdit);
  };

  useEffect(() => {
    fetchData();

    return () => {
      setUser({});
      setTeamName('');
      setLocalTime('');
      setShowVibeBadge(false);
      setIsUnfollowed(false);
      setIsAuthUser(true);
      setCanEdit(false);
    };
  }, [userId]);

  return show ? (
    <Modal show={show} onHide={onHide} dialogClassName="win-modal" backdrop keyboard>
      <Container>
        {canEdit && <EditButton userId={userId} onClick={onHide} />}
        <CloseButton onClick={onHide} />
        <Avatar src={user.image_url} alt="" onError={e => AVATAR_ERROR(e.target)} />
        <Name>
          {user.first_name} {user.last_name} {showVibeBadge && <VibeStar />}
        </Name>
        <Title>
          {showRole(user.role)}
          {teamName}
        </Title>
        <Description>{user.description}</Description>
        <Tags>
          {user.tags && user.tags.map ? user.tags.map(user => `#${user}`).join(' ') : null}
        </Tags>
        <TimezoneWrap>
          <Timezone icon="timezone1">{user.time_zone}</Timezone>
          <Timezone icon="timezone2">Local time: {localTime}</Timezone>
        </TimezoneWrap>
        {!isAuthUser && (
          <BottomActions>
            {feedEnabled && (
              <ActionButton
                onClick={() => {
                  onHide();
                  setAction('create-shoutout', userId);
                  setShowActionModal(true);
                }}
                icon="star"
              >
                Give shoutout
              </ActionButton>
            )}
            <ActionButton
              icon="paper-plane"
              onClick={() => (window.location.href = `mailto:${user.email}`)}
            >
              Send a message
            </ActionButton>
            {feedEnabled && (
              <ActionButton
                icon="mute-speaker"
                onClick={() => {
                  unfollowUser(+userId);
                  setIsUnfollowed(!isUnfollowed);
                }}
              >
                {isUnfollowed ? 'Follow' : 'Mute posts from'} {user.first_name || '...'}
              </ActionButton>
            )}
          </BottomActions>
        )}
      </Container>
    </Modal>
  ) : null;
};

UserModal.propTypes = {
  userId: PropTypes.number.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  userId: state.app.userId,
  show: state.app.userModal,
  feedEnabled: state.usersDomain.feedEnabled,
});

const mapDispatchToProps = dispatch => ({
  unfollowUser: userId => dispatch(USER_ACTIONS.unfollowUser(userId)),
  setAction: (action, userId) => dispatch(setAction(action, { userId })),
  onHide: () => dispatch(toggleUserModal(false, -1)),
  setShowActionModal: showActionModal => dispatch(setShowActionModal(showActionModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserModal);
