import PropTypes from 'prop-types';
import React, { useLayoutEffect } from 'react';
import { BODY_CLASSES } from '../../helpers';
import HeelixLogo from './HeelixLogo';

const WelcomeContainer = ({ title, body }) => {
  useLayoutEffect(() => {
    BODY_CLASSES(['new', 'sessions', 'welcome']);
  }, []);

  return (
    <div className="vertical-center-container">
      <HeelixLogo />
      <div className="welcome-body mx-auto">
        <h1 className="welcome-title">{title}</h1>
        <div className="text-center overlay-wrapper">{body}</div>
      </div>
    </div>
  );
};

WelcomeContainer.propTypes = {
  title: PropTypes.node.isRequired,
  body: PropTypes.element.isRequired,
};

export default WelcomeContainer;
