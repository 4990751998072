import React, { Component } from 'react';
import { connect } from 'react-redux';
import { COMMENT_ACTIONS } from 'redux/actions/commentActions';
import menuDots from 'images/feed/menu_dots_heelix.svg';
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';

class CommentDropdown extends Component {
  state = {
    open: false,
  };

  commentDeletedHandler = () => {
    this.props.deleteComment(this.props.postId, this.props.commentId, this.props.type);
    if (this.props.commentDeletedHandler) {
      this.props.commentDeletedHandler(this.props.commentId);
    }
  };

  render() {
    return (
      <RootCloseWrapper
        disabled={!this.state.open}
        onRootClose={() => this.setState({ open: false })}
      >
        <div
          className="comment-dropdown"
          custom-open={this.state.open.toString()}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <img src={menuDots} alt="menu" />
          <div className="options-wrap">
            {this.props.deleteVisible && (
              <div className="delete" onClick={() => this.commentDeletedHandler()}>
                Delete
              </div>
            )}
            {this.props.editVisible && (
              <div className="edit" onClick={() => this.props.changeEditingMode()}>
                Edit
              </div>
            )}
          </div>
        </div>
      </RootCloseWrapper>
    );
  }
}

const mapStateToProps = state => ({
  user: state.usersDomain.user,
});

const mapDispatchToProps = dispatch => ({
  deleteComment: (postId, commentId, type) =>
    dispatch(COMMENT_ACTIONS.deleteComment(postId, commentId, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentDropdown);
