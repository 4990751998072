import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import NotificationItem from './NotificationItem';

class NotificationList extends Component {
  propTypes = {
    notifications: PropTypes.arrayOf().isRequired,
    closeAllPanesHandler: PropTypes.func.isRequired,
  };

  getNotifications() {
    const { notifications, closeAllPanesHandler } = this.props;
    return notifications.map(notification => (
      <NotificationItem
        key={notification.id}
        notification={notification}
        closeAllPanesHandler={closeAllPanesHandler}
      />
    ));
  }

  render() {
    const notifications = this.getNotifications();
    return (
      <div data-reactroot>
        {notifications.length > 0 ? (
          <ul>{notifications}</ul>
        ) : (
          <ul className="no-notifications">
            <li className="notification text-center">No notifications to see here.</li>
          </ul>
        )}
        <div className="notification-footer centered">
          <Link
            id="notification-settings-btn"
            to="/notification_settings"
            onClick={() => {
              const { closeAllPanesHandler } = this.props;
              document.dispatchEvent(new MouseEvent('click'));
              closeAllPanesHandler();
            }}
          >
            Notification settings
          </Link>
        </div>
      </div>
    );
  }
}

export default NotificationList;
