import React, { Component } from 'react';

export default class CustomScrollbar extends Component {
  state = {
    grabbed: false,
    grabStartCursorY: null,
    grabStartSelfY: null,
  };

  componentDidMount = () => {
    this.self.parentNode.addEventListener('scroll', this.handlePosition);
    window.addEventListener('resize', this.handleResize, false);
    this.handleResize();
    this.handlePosition();
  };

  componentWillUnmount = () => {
    this.self.parentNode.removeEventListener('scroll', this.handlePosition);
    window.removeEventListener('resize', this.handleResize, false);
  };

  // ---Recalc scrollbar's dimensions in case parent's state updates (parent renders different number of children)
  componentDidUpdate = () => this.handleResize();

  handleResize = () => {
    if (this.getChildrenHeight() > this.getParentHeight()) {
      const scrollbarHeight = (this.getParentHeight() * 100) / this.getChildrenHeight();
      this.self.style.height = `${scrollbarHeight}%`;
    } else {
      this.self.style.height = 0;
    }
  };

  handlePosition = () => {
    const overflowingHeight = this.getChildrenHeight() - this.getParentHeight();
    const scrollPercentage = (100 * this.self.parentNode.scrollTop) / overflowingHeight;
    this.self.style.top = `${
      ((this.getParentHeight() - this.getSelfHeight()) * scrollPercentage) / 100
    }px`;
  };

  getChildrenHeight = () =>
    Array.from(this.self.parentNode.children).reduce((a, b) => {
      if (
        b === this.self ||
        window.getComputedStyle(b).getPropertyValue('position') === 'absolute' ||
        window.getComputedStyle(b).getPropertyValue('position') === 'fixed'
      )
        return a;
      return (
        a +
        parseInt(window.getComputedStyle(b).getPropertyValue('height'), 10) +
        parseInt(window.getComputedStyle(b).getPropertyValue('margin-top'), 10) +
        parseInt(window.getComputedStyle(b).getPropertyValue('margin-bottom'), 10)
      );
    }, 0);

  getParentHeight = () =>
    parseInt(window.getComputedStyle(this.self.parentNode).getPropertyValue('height'), 10);

  getSelfHeight = () => parseInt(window.getComputedStyle(this.self).getPropertyValue('height'), 10);

  handleGrabStop = () =>
    this.setState(
      {
        grabbed: false,
        grabStartCursorY: null,
        grabStartSelfY: null,
      },
      () => {
        window.removeEventListener('mousemove', this.handleGrabScrolling, false);
        window.removeEventListener('mouseup', this.handleGrabStop, false);
      }
    );

  handleGrabScrolling = e => {
    // ---Manage scrollbar's position
    const maxTop = this.getParentHeight() - this.getSelfHeight();
    const calculatedTop = this.state.grabStartSelfY + e.clientY - this.state.grabStartCursorY;
    const finalTop = maxTop < calculatedTop ? maxTop : calculatedTop < 0 ? 0 : calculatedTop;
    this.self.style.top = `${finalTop}px`;

    // ---Scale numbers and calculate parent's scroll value
    const scrollPercentage = (finalTop * 100) / maxTop;
    const scrollValue =
      ((this.getChildrenHeight() - this.getParentHeight()) * scrollPercentage) / 100;
    this.self.parentNode.scrollTop = scrollValue;
  };

  render = () => (
    <div
      className={`custom-scrollbar ${this.state.grabbed ? 'grabbed' : ''}`}
      ref={ref => (this.self = ref)}
      onMouseDown={e =>
        this.setState(
          {
            grabbed: true,
            grabStartCursorY: e.clientY,
            grabStartSelfY: parseInt(this.self.style.top, 10),
          },
          () => {
            window.addEventListener('mousemove', this.handleGrabScrolling, false);
            window.addEventListener('mouseup', this.handleGrabStop, false);
          }
        )
      }
    />
  );
}
