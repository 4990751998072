/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { HEADERS, getUrl } from 'helpers';
import { connect } from 'react-redux';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import { withRouter } from 'react-router-dom';
import { USER_ACTIONS } from 'redux/actions/userActions';
import { FEED_STEPS, INSIGHTS_STEPS, GROW_STEPS, SETUP_STEPS } from 'onboardingSteps';
import confetti from './confetti.svg';
import unchecked from './unchecked.svg';
import checked from './checked.svg';

const ChecklistHeader = styled.div`
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 12px;
  padding-top: 3px;
`;

const Checkbox = styled.img`
  width: 15px;
  margin-right: 11px;
  border-radius: 50%;
  padding-bottom: 3px;
`;

const ChecklistElement = ({
  content,
  selected,
  resetChecklist,
  type,
  tour,
  step,
  tourCallback,
  samplePostsProps,
  onboardingData,
  checklist,
  ...props
}) => (
  <div
    css={css`
      color: ${selected ? '#11A9FF' : '#6A6A6A'};
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      display: table;
    `}
    {...props}
  >
    <Checkbox src={selected ? checked : unchecked} />
    <div
      css={css`
        display: table-cell;
        vertical-align: middle;
        cursor: pointer;
      `}
      onClick={() => tourCallback(tour, step, samplePostsProps, onboardingData, checklist)}
    >
      {content}
    </div>
  </div>
);

const ChecklistSection = ({
  title,
  elements,
  onboardingData,
  resetChecklist,
  tour,
  tourCallback,
  feedEnabled,
  samplePostsProps,
  checklist,
  ...props
}) => (
  <div
    css={css`
      padding-top: 18px;
    `}
    {...props}
  >
    <ChecklistHeader>{title}</ChecklistHeader>
    <div>
      {elements
        .filter(element => !(element.key === 'feedback' && !feedEnabled))
        .map((element, index) => (
          <ChecklistElement
            key={index}
            content={element.content}
            type={element.key}
            checklist={checklist}
            selected={onboardingData[element.key].finished || false}
            onboardingData={onboardingData}
            resetChecklist={resetChecklist}
            tourCallback={tourCallback}
            step={element.step}
            tour={title}
            samplePostsProps={samplePostsProps}
          />
        ))}
    </div>
  </div>
);

const Completed = styled.img`
  padding: 27px;
`;

const CompletedTitle = styled.div`
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
`;
const CompletedContent = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: normal;
  padding: 20px 80px;
`;

const CompletedTour = ({ resetChecklist }) => (
  <div
    css={css`
      background-image: url('${confetti}');
      background-repeat: no-repeat;
      height: 400px;
      text-align: center;
    `}
  >
    <div
      css={css`
        padding: 60px 0;
      `}
    >
      <Completed src={checked} />
      <CompletedTitle>
        Congratulations,&nbsp;
        <br />
        you’ve completed your tour!
      </CompletedTitle>
      <CompletedContent>
        You’re all set now it is time to sit back and relax. Remember, you can always do it again at
        any time by clicking&nbsp;
        <a
          css={css`
            cursor: pointer;
          `}
          onClick={() => {
            const headers = HEADERS();
            fetch(getUrl('onboarding/reset_checklist'), {
              method: 'get',
              headers,
            })
              .then(response => {
                response.json().then(jsonResponse => {
                  resetChecklist(jsonResponse.onboardingData, jsonResponse.finished);
                });
              })
              .catch(console.error);
          }}
        >
          this link
        </a>
        .
      </CompletedContent>
    </div>
  </div>
);

const OnboardingTour = ({
  onboardingData,
  finished,
  resetChecklist,
  isLeadOrAdmin,
  startFeedTour,
  startInsightsTour,
  startSetupTour,
  startGrowTour,
  setOpenOnboarding,
  userAlreadyVibed,
  feedEnabled,
  growEnabled,
  isHideSamplePosts,
  enableSamplePosts,
  isAccountAdmin,
  ...props
}) => {
  const setupOnboardingKeys = {
    title: 'Setup',
    elements: [
      {
        key: 'start_setup',
        content: 'Setup Process',
        step: 'initialSetupStep',
      },
      {
        key: 'add_teams',
        content: 'Adding new teams',
        step: 'addTeamStep',
      },
      {
        key: 'add_user',
        content: 'How to add a user',
        step: 'addUserStep',
      },
      {
        key: 'move_user',
        content: 'Moving users to teams',
        step: 'userTileStep',
      },
      {
        key: 'team_lead',
        content: 'Setting leads of your teams',
        step: 'teamLeadStep',
      },
      {
        key: 'invite_users',
        content: 'Inviting new users to the app',
        step: 'firstUserTileStep',
      },
      {
        key: 'unactive_users',
        content: 'Identifying unactivated users',
        step: 'sendInvitationsStep',
      },
    ],
  };

  const checklists = [
    {
      title: 'Grow',
      elements: [
        {
          key: 'my_notes',
          content: 'Review My notes',
          step: 'initialGrowStep',
        },
        {
          key: 'new_goal',
          content: 'Create a goal',
          step: 'clickPlusBtnStep',
        },
        {
          key: 'status',
          content: 'Update the status on your Grow Board',
          step: 'boardStagesStep',
        },
        {
          key: 'meetings',
          content: 'Set up new meeting',
          step: 'setUpMeetingStep',
        },
      ],
    },
    {
      title: 'Insights',
      elements: [
        {
          key: 'dashboard',
          content: 'Review the dashboard',
          step: 'initialInsightsStep',
        },
        {
          key: 'metrics',
          content: 'Understanding your key performance metrics',
          step: 'keyPerformanceStep',
        },
        {
          key: 'vibe',
          content: 'Learn more about the Vibe',
          step: userAlreadyVibed ? 'takeActionBtnStep' : 'clickPlusBtnStep',
        },
        {
          key: 'feedback',
          content: 'How to share feedback',
          step: 'takeActionBtnStep',
        },
      ],
    },
    {
      title: 'FEED',
      elements: [
        {
          key: 'home_feed',
          content: 'Checking out the Home feed',
          step: 'initialFeedStep',
        },
        {
          key: 'new_content',
          content: 'Creating new content',
          step: 'clickPlusBtnStep',
        },
        {
          key: 'post',
          content: 'How to post a start / stop /continue',
          step: 'giveGetFeedbackStep',
        },
        {
          key: 'spaces',
          content: 'How Spaces work',
          step: 'createSpaceBtnStep',
        },
      ],
    },
  ];
  if (isAccountAdmin) {
    checklists.push(setupOnboardingKeys);
  }

  const getTourCallback = (tour, step, samplePostsProps, onboardingData, checklist) => {
    let redirectUrl = '/grow_profile';
    switch (tour) {
      case 'FEED':
        FEED_STEPS.forEach((stepObj, index) => {
          if (stepObj.key === step) {
            props.history.push('/');
            startFeedTour(index);
          }
        });
        break;
      case 'Insights':
        INSIGHTS_STEPS.forEach((stepObj, index) => {
          if (stepObj.key === step) {
            props.history.push('/vibes/dashboard');
            startInsightsTour(index);
          }
        });
        break;
      case 'Grow':
        if (step === 'boardStagesStep') {
          redirectUrl = '/grow_board';
        }
        if (step === 'setUpMeetingStep') {
          if (samplePostsProps.isHideSamplePosts) {
            samplePostsProps.enableSamplePosts();
          }
          redirectUrl = '/grow_profile?tab=meetings';
        }
        GROW_STEPS.forEach((stepObj, index) => {
          if (stepObj.key === step) {
            props.history.push(redirectUrl);
            startGrowTour(index);
          }
        });
        break;
      case 'Setup':
        const checklistKey = checklist.elements.find(checklist => checklist.step === step).key;
        redirectUrl = '/company_structure?mode=setup';
        if (step === 'firstUserTileStep' || step === 'sendInvitationsStep') {
          redirectUrl = '/company_structure?mode=setup&setupStep=2';
        }

        props.toggleAddChildTeam(true);
        SETUP_STEPS.forEach((stepObj, index) => {
          if (stepObj.key === step) {
            if (onboardingData[checklistKey].canPerform) {
              props.history.push(redirectUrl);
              startSetupTour(index);
            } else {
              props.history.push(redirectUrl);
              startSetupTour(0);
            }
          }
        });
        break;
    }
    setOpenOnboarding(false);
  };

  return (
    <div
      css={css`
        padding: ${finished ? '0 5px' : '23px 32px'};
        text-align: left;
        color: #323232;
      `}
    >
      {!finished && (
        <div
          css={css`
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
          `}
        >
          Here are the different tours you can explore to get you more familiar with Heelix. Once
          you’ve completed something it will be marked as done below but you can always do it again
          later.
        </div>
      )}
      {!finished &&
        onboardingData &&
        checklists
          .filter(
            checklist =>
              (checklist.title === 'Grow' && growEnabled) ||
              (checklist.title === 'FEED' && feedEnabled) ||
              checklist.title === 'Insights' ||
              checklist.title === 'Setup'
          )
          .map((checklist, index) => (
            <ChecklistSection
              key={index}
              checklist={checklist}
              title={checklist.title}
              elements={checklist.elements}
              onboardingData={onboardingData}
              resetChecklist={resetChecklist}
              tourCallback={getTourCallback}
              feedEnabled={feedEnabled}
              samplePostsProps={{
                isHideSamplePosts,
                enableSamplePosts,
              }}
            />
          ))}
      {finished && <CompletedTour resetChecklist={resetChecklist} />}
    </div>
  );
};

const mapStateToProps = state => ({
  isLeadOrAdmin: Boolean(
    state.usersDomain.user.isAccountAdmin || state.usersDomain.user.formalLeadTeamsIds.length
  ),
  isAccountAdmin: state.usersDomain.user.isAccountAdmin,
  userAlreadyVibed: !!state.usersDomain.user && state.usersDomain.user.alreadyVibed,
  feedEnabled: state.usersDomain.feedEnabled,
  growEnabled: state.usersDomain.growEnabled,
  isHideSamplePosts: state.usersDomain.user.hideSamplePosts,
});

const mapDispatch = {
  startInsightsTour: step => ONBOARDING_ACTIONS.startInsightsTour(step),
  startGrowTour: step => ONBOARDING_ACTIONS.startGrowTour(step),
  startFeedTour: step => ONBOARDING_ACTIONS.startFeedTour(step),
  startSetupTour: step => ONBOARDING_ACTIONS.startSetupTour(step),
  enableSamplePosts: () => USER_ACTIONS.hideSamplePosts(),
  toggleAddChildTeam: addChildTeamState => ONBOARDING_ACTIONS.toggleAddChildTeam(addChildTeamState),
};

export default withRouter(connect(mapStateToProps, mapDispatch)(OnboardingTour));
