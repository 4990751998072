import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import TeamTileDropdown from './TeamTileDropdown';

class TeamTileDropdownWrap extends Component {
  state = {
    triggered: false,
  };

  handleDelete = () => {
    const { membersCount, deleteSpace, team } = this.props;

    const confirmationMessage = `Are you sure you would like to delete this Team and all of its sub teams?${
      membersCount === 0
        ? ''
        : ` It has ${membersCount} active user${
            membersCount !== 1 ? 's' : ''
          } who will be moved to your user bucket and need to be re-assigned.`
    }`;

    if (window.confirm(confirmationMessage)) {
      deleteSpace(team);
    }
  };

  handleRename = () => {
    const { team, history } = this.props;
    history.push(`/team/${team.id}/edit`);
  };

  render = () => (
    <div
      className="team-tile-dropdown-button"
      onMouseDown={e => e.stopPropagation()}
      onClick={() => this.setState({ triggered: !this.state.triggered })}
    >
      {this.state.triggered ? (
        <TeamTileDropdown
          triggerUnmount={() => this.setState({ triggered: false })}
          handleDelete={this.handleDelete}
          handleRename={this.handleRename}
        />
      ) : (
        ''
      )}
    </div>
  );
}

TeamTileDropdownWrap.propTypes = {
  membersCount: PropTypes.number,
  deleteSpace: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
};

export default withRouter(TeamTileDropdownWrap);
