import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import SpaceLogo from '../../components/spaces/SpaceLogo';

const Wrap = styled.div`
  display: flex;
  .space-img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  .space-logo {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    border-radius: 100%;
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }
  &.progress-board {
    .space-logo,
    .space-img {
      width: 18px;
      height: 18px;
      font-size: 11px;
      margin-bottom: 0;
      margin-right: 6px;
    }
    .team-name {
      font-size: 11px;
      line-height: 19px;
    }
  }
  div {
    height: 18px;
  }
`;
const TeamInfo = styled.div`
  height: 32px;
`;
const TeamName = styled.div`
  font-size: 14px;
  line-height: 32px;
  font-weight: bold;
  align-self: center;
  color: #323232;
`;

const SharedTeam = ({ team, ...props }) => {
  return (
    <Wrap {...props}>
      <SpaceLogo space={team} />
      <TeamInfo>
        <TeamName className="team-name">{team.name}</TeamName>
      </TeamInfo>
    </Wrap>
  );
};

SharedTeam.propTypes = {
  team: PropTypes.object.isRequired,
};

export default SharedTeam;
