/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import OptionButton from '../partials/OptionButton';

const Input = styled.input`
  border: 1px solid #ededed !important;
  margin-bottom: ${props => (props.hasOptions ? '19' : '60')}px !important;
  font-size: 14px;
  line-height: 21px;
`;

const OptionWrapper = styled.div`
  padding: 0;
  width: 100%;
  display: table;
  margin-bottom: 17px;
  position: relative;
`;

const RemoveButton = styled.span`
  cursor: pointer;
  &:before,
  &:after {
    width: 10px;
    height: 2px;
    background: #d5d5d5;
    border-radius: 1px;
    position: absolute;
    right: -25px;
    top: 25px;
    content: '';
    transition: transform 0.3s;
  }
  &:before {
    transform: translate(0, -50%) rotateZ(45deg);
  }
  &:after {
    transform: translate(0, -50%) rotateZ(135deg);
  }
`;

const LabelWrapper = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const Label = styled.div`
  border: 2px solid #d5d5d5;
  border-radius: ${props => (props.multiple ? '3px' : '50%')};
  left: 0;
  top: -2px;
  height: 18px;
  width: 18px;
  margin-top: 8px;
`;

const OptionInput = styled.input`
  border: 1px solid #ededed !important;
  width: 100%;
  display: table-cell;
  margin-bottom: 0 !important;
  font-size: 14px;
  line-height: 21px;
`;

const ButtonsWrapper = styled.div`
  padding: 10px 2px;
`;

const ErrorMessage = styled.div`
  padding: 0 0 15px 2px;
`;

const QuestionOptions = ({ question, setQuestionState, questionKey, errors }) => {
  const maxOptions = 10;
  const hasOptions = question.type === 'multiple' || question.type === 'checkbox';

  const addOption = () => {
    const options = [
      ...question.options,
      {
        id: null,
        content: '',
      },
    ];
    setQuestionState(questionKey, 'options', options);
  };

  const removeOption = optionKey => {
    const options = [...question.options];
    options.splice(optionKey, 1);
    setQuestionState(questionKey, 'options', options);
  };

  const changeOptionContent = (value, optionKey) => {
    const options = [...question.options];
    const option = { ...options[optionKey] };
    option.content = value;
    options[optionKey] = option;
    setQuestionState(questionKey, 'options', options);
  };

  const numbers = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];

  const getOptionKey = (questionKey, optionKey) => {
    return `questions.${questionKey}.options.${optionKey}.content`;
  };
  return (
    <>
      <Input
        className="name-input"
        placeholder="Enter your question here"
        type="text"
        hasOptions={hasOptions}
        value={question.content}
        onChange={e => setQuestionState(questionKey, 'content', e.target.value)}
      />
      {errors.find(error => error.key === `questions.${questionKey}.content`) && (
        <ErrorMessage className="error-message text-danger">
          {errors.find(error => error.key === `questions.${questionKey}.content`).value}
        </ErrorMessage>
      )}
      {hasOptions && (
        <>
          {question.options.map((option, key) => (
            <>
              <OptionWrapper key={key}>
                <LabelWrapper>
                  <Label multiple={question.type === 'multiple'} />
                </LabelWrapper>
                <OptionInput
                  className="name-input"
                  placeholder={`Response option #${key + 1}`}
                  type="text"
                  value={option.content}
                  onChange={e => changeOptionContent(e.target.value, key)}
                />
                <RemoveButton onClick={() => removeOption(key)} />
              </OptionWrapper>
              {errors.find(error => error.key === getOptionKey(questionKey, key)) && (
                <ErrorMessage className="error-message text-danger">
                  {errors.find(error => error.key === getOptionKey(questionKey, key)).value}
                </ErrorMessage>
              )}
            </>
          ))}
          {question.options.length < maxOptions && (
            <ButtonsWrapper>
              <OptionButton
                onClick={() => addOption()}
                label="Add option"
                type="gray4"
                css={css`
                  width: 109px;
                  padding-left: 0;
                `}
              />
            </ButtonsWrapper>
          )}
        </>
      )}
    </>
  );
};

export default QuestionOptions;
