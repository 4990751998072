import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const PreparationWrapper = styled.div`
  padding: 0 25px 25px 25px;
`;
export const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 19px;
`;
export const List = styled.ul`
  list-style: none;
  padding-inline-start: 20px;
  padding-bottom: 8px;
  text-indent: -20px;
  margin-bottom: 0;
`;
export const Point = styled.li`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 17px;
  word-break: break-word;

  :before {
    content: '─';
    color: #aaaaaa;
    padding-right: 11px;
  }
`;
export const Notes = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 20px;
  word-break: break-word;
  white-space: pre-wrap;
`;
export const Button = styled.button`
  margin-top: 7px;
  margin-bottom: 10px;
  width: 130px;
  height: 36px;
  background: #11a9ff;
  border-radius: 4px;
  border: 1px solid #11a9ff;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  text-align: center;
  ${props =>
    props.small
      ? css`
          width: 129px;
        `
      : css`
          width: 168px;
          margin-right: 17px;
          font-weight: bold;
        `}
`;
export const Checkbox = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid;
  border-radius: 1px;
  cursor: pointer;
  ${props =>
    props.completed
      ? css`
          background: #11a9ff;
          border-color: #11a9ff;
          color: #ffffff;
          transition: 0.2s opacity, 0.2s background;
          position: relative;

          &:before,
          &:after {
            background: #e3e3e3;
            content: '';
            position: absolute;
            border-radius: 2px;
            width: 2px;
          }

          &:before {
            height: 7px;
            left: 3px;
            transform: rotateZ(-50deg);
            top: 5px;
          }

          &:after {
            height: 9px;
            left: 8px;
            top: 2px;
            transform: rotateZ(44deg);
          }
        `
      : css`
          border-color: #e7e7e7;
        `}
  margin-right: 13px;
`;
export const StepWrapper = styled.div`
  ${props =>
    props.completed
      ? css`
          text-decoration: line-through;
          color: #9f9f9f;
        `
      : css`
          color: #323232;
        `}
  word-break: break-word;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto 1fr;
  grid-gap: 0vw;
  margin-bottom: 20px;
`;
export const Content = styled.div`
  vertical-align: middle;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  cursor: pointer;
`;
export const ContentWrapper = styled.div`
  display: flex;
`;
export const Steps = styled.div`
  margin-top: 7px;
`;
export const Header = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
  word-break: break-word;
`;

export const StepDetails = styled.div`
  color: #a6a6a6;
  font-size: 14px;
  margin-left: 12px;
`;
