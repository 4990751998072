import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

class DropzoneWrap extends Component {
  render() {
    return (
      <div id="dropzone-wrap">
        <Dropzone
          style={{
            width: '100%',
            height: '70px',
            border: 'dashed 1px #D5D5D5',
            borderRadius: '3px',
            position: 'relative',
            zIndex: '1',
          }}
          ref="dropzone"
          accept="image/*, video/*"
          onDrop={e => this.props.fileUploadHandler(e)}
        />
        <h3>
          <svg
            width="19"
            height="16"
            viewBox="0 0 19 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.6759 3H17.0344C18.1155 3 19 3.90002 19 5V14C19 15.1 18.1155 16 17.0344 16H1.96558C0.884521 16 0 15.1 0 14V5C0 3.90002 0.884521 3 1.96558 3H3.66895C3.99316 3 4.34229 2.74402 4.44507 2.43103L5.05481 0.569214C5.15723 0.255981 5.50671 0 5.83105 0H12.5138C12.8381 0 13.1871 0.255981 13.2899 0.56897L13.8997 2.43079C14.0021 2.74402 14.3516 3 14.6759 3ZM5.24146 8.99988C5.24146 6.97546 6.85461 5.33325 8.84485 5.33325C10.8347 5.33325 12.4484 6.97546 12.4484 8.99988C12.4484 11.0251 10.8347 12.6666 8.84485 12.6666C6.85461 12.6666 5.24146 11.0251 5.24146 8.99988ZM8.84497 14C6.1311 14 3.93115 11.7614 3.93115 9C3.93115 6.23877 6.1311 4 8.84497 4C11.5583 4 13.7588 6.23877 13.7588 9C13.7588 11.7614 11.5583 14 8.84497 14ZM15.7241 5.33337C15.7241 5.60632 16.0176 6.00012 16.3793 6.00012C16.7411 6.00012 17.0344 5.60632 17.0344 5.33337C17.0344 5.06079 16.7413 4.66675 16.3793 4.66675C16.0176 4.66675 15.7241 5.06079 15.7241 5.33337Z"
              fill="#11A9FF"
              fillOpacity="0.37"
            />
          </svg>
          Drop your image here to upload or <span>choose file</span>
        </h3>
      </div>
    );
  }
}
export default DropzoneWrap;
