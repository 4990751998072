import { SPACE_ACTION_TYPES, SPACE_ACTIONS } from '../actions/spaceActions';
import { USER_ACTIONS } from '../actions/userActions';
import store from '../store';
import { APP_URL } from '../../constants';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';

const spaceReducer = (state, action) => {
  const headers = HEADERS();
  let newState;
  switch (action.type) {
    case SPACE_ACTION_TYPES.FAV_SPACE:
      const url = `/spaces/favourite/${action.payload.spaceId}`;
      fetch(APP_URL + url, { method: 'put', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            store.dispatch(SPACE_ACTIONS.receiveFavStatus(action.payload.spaceId, jsonResponse));
            action.payload.callback();
          });
        });
      return state;
    case SPACE_ACTION_TYPES.RECEIVE_FAV_STATUS:
      const spacesCopy = JSON.parse(JSON.stringify(state.spacesDomain.spaces));
      const index = spacesCopy.map(space => space.id).indexOf(action.payload.spaceId);
      if (action.payload.json.favourite) spacesCopy[index].favourite = true;
      else spacesCopy[index].favourite = false;
      return {
        ...state,
        spacesDomain: {
          ...state.spacesDomain,
          spaces: spacesCopy,
        },
      };
    case SPACE_ACTION_TYPES.FETCH_FORMAL_SPACE:
      fetch(`${APP_URL}/spaces/${action.payload}`, { method: 'get', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            store.dispatch(SPACE_ACTIONS.receiveFormalSpace(jsonResponse));
            store.dispatch(SPACE_ACTIONS.receiveSpaceMembers(jsonResponse.spaceMembers));
          });
        });
      return state;
    case SPACE_ACTION_TYPES.RECEIVE_FORMAL_SPACE:
      newState = JSON.parse(JSON.stringify(state));
      const spacesIndex = newState.spacesDomain.spaces
        .map(space => space.id)
        .indexOf(action.payload.space.id);
      if (spacesIndex > -1) {
        newState.spacesDomain.spaces[spacesIndex] = action.payload.space;
      } else {
        newState.spacesDomain.spaces[newState.spacesDomain.spaces.length] = action.payload.space;
      }
      return newState;
    case SPACE_ACTION_TYPES.RECEIVE_SPACE_MEMBERS:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          spaceMembers: action.payload,
        },
      };
    case SPACE_ACTION_TYPES.FETCH_SPACE_NOTIFICATION_SETTINGS:
      fetch(`${APP_URL}/notification_settings`, { method: 'get', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            store.dispatch(SPACE_ACTIONS.receiveSpaceNotificationSettings(jsonResponse));
          });
        })
        .catch(error => {});
      return state;
    case SPACE_ACTION_TYPES.RECEIVE_SPACE_NOTIFICATION_SETTINGS:
      return {
        ...state,
        spacesDomain: {
          ...state.spacesDomain,
          notificationSettings: action.payload.spaces,
        },
        usersDomain: {
          ...state.usersDomain,
          weeklyDigest: action.payload.weeklyDigest,
        },
      };
    case SPACE_ACTION_TYPES.FETCH_SPACE_DETAILS:
      fetch(`${APP_URL}/space_details/${action.payload}`, { method: 'get', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            store.dispatch(SPACE_ACTIONS.receiveSpaceDetails(jsonResponse));
          });
        });
      return state;
    case SPACE_ACTION_TYPES.RECEIVE_SPACE_DETAILS:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          spaceId: action.payload.spaceId,
          spaceMembers: action.payload.spaceMembers,
          user: {
            ...state.usersDomain.user,
            should_see_content: action.payload.should_see_content,
            should_see_sample_data: action.payload.should_see_sample_data,
          },
        },
        teamsDomain: {
          ...state.teamsDomain,
          team: action.payload.space,
        },
        spacesDomain: {
          ...state.spacesDomain,
          editedSpaceMembers: action.payload.spaceMembers,
          editedSpaceId: action.payload.spaceId,
        },
        plansDomain: {
          ...state.plansDomain,
          reachedDataLimit: action.payload.reachedDataLimit,
        },
      };
    case SPACE_ACTION_TYPES.UNFOLLOW_SPACE:
      fetch(`${APP_URL}/unfollow-space/${action.payload.id}/${action.payload.notificationType}`, {
        method: 'put',
        headers,
      })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            store.dispatch(SPACE_ACTIONS.receiveUnfollowSpaceStatus(action.payload.id, json));
          });
        });
      return state;
    case SPACE_ACTION_TYPES.RECEIVE_UNFOLLOW_SPACE_STATUS:
      const spaceIndex = state.spacesDomain.notificationSettings
        .map(space => space.space_id)
        .indexOf(action.payload.spaceId);
      newState = JSON.parse(JSON.stringify(state));
      if (spaceIndex > -1) {
        newState.spacesDomain.notificationSettings[spaceIndex].email_enabled =
          action.payload.json.space.email_enabled;
        newState.spacesDomain.notificationSettings[spaceIndex].mobile_enabled =
          action.payload.json.space.mobile_enabled;
        newState.spacesDomain.notificationSettings[spaceIndex].mention_enabled =
          action.payload.json.space.mention_enabled;
        newState.spacesDomain.notificationSettings[spaceIndex].comments_enabled =
          action.payload.json.space.comments_enabled;
      }
      return newState;
    case SPACE_ACTION_TYPES.FETCH_SPACES:
      fetch(`${APP_URL}/spaces/list`, { method: 'get', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            store.dispatch(SPACE_ACTIONS.receiveSpaces(json));
            action.payload.successCallback();
          });
        });
      return state;
    case SPACE_ACTION_TYPES.RECEIVE_SPACES:
      return {
        ...state,
        spacesDomain: {
          ...state.spacesDomain,
          spaces: action.payload.spaces,
        },
      };

    case SPACE_ACTION_TYPES.UPDATE_SPACE_NAME:
      return {
        ...state,
        spacesDomain: {
          ...state.spacesDomain,
          spaces: state.spacesDomain.spaces.map((space, i) => {
            if (i === action.payload.spaceIndex) return { ...space, name: action.payload.newName };

            return space;
          }),
        },
      };

    default:
      return state;
  }
};
export default spaceReducer;
