import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import FlipSelect from 'components/shared/FlipSelect';

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  teams: state.teamsDomain.teams,
  isFreePlan: state.plansDomain.plan === 'free',
});

const SelectWrap = styled.div`
  width: 247px;
  padding-top: 29px;
  margin-left: 24px;

  & > div {
    width: 100% !important;
  }
  .Select-placeholder {
    color: #6a6a6a !important;
  }
  .Select-control {
    border: none !important;
    height: 35px !important;
    background: #f3f3f3 !important;
  }
  .Select-option {
    background-color: #f3f3f3;
  }
`;

const OptionWrap = styled.div`
  color: #6a6a6a;
  font-size: 14px;

  span {
    font-weight: bold;
  }
`;

const Option = ({ option }) => (
  <OptionWrap>
    Posting to <span>{option.label}</span>
  </OptionWrap>
);

class TeamDropdown extends Component {
  state = {
    showList: false,
    tab: '',
  };

  getSpacesList = () => {
    return (this.props.isFreePlan
      ? [this.props.spaces.find(space => space.account_default_space)]
      : this.props.spaces.filter(space => space.is_team_lead || space.is_member || space.is_visitor)
    ).map(space => ({
      value: space.id,
      label: space.name,
    }));
  };

  getTeamsList = () => {
    return this.props.teams
      .filter(team => team.account_id === this.props.user.accountId)
      .map(team => ({
        value: team.id,
        label: team.name,
      }));
  };

  getPostToId = () => {
    const { spaceId, user, postToTeam, spaces } = this.props;
    let postTo;
    if (spaceId) {
      postTo = spaceId;
    } else if (postToTeam) {
      postTo = user.teamId;
    } else {
      postTo = user.spaceId || spaces.find(space => space.account_default_space).id;
    }
    this.props.changeSpace(postTo);
    return postTo;
  };

  render = () => {
    const spaceId = this.getPostToId();
    const dropdownContainer = document.querySelector('#action-bottom-bar #choose-space-dropdown');
    // ---Dropdown portal is usually rerendered before post creation tab and catches reference to old view that is about to be unmounted
    // ---This condition ensures that portal catches right reference
    if (dropdownContainer && this.state.tab === this.props.tab) {
      return ReactDOM.createPortal(
        <SelectWrap>
          <FlipSelect
            options={this.props.postToTeam ? this.getTeamsList() : this.getSpacesList()}
            inputProps={{ id: 'shoutout-for-input' }}
            value={this.props.disabled ? null : spaceId}
            isSearchable
            onChange={({ value }) => this.props.changeSpace(value)}
            clearable={false}
            className="select-component recipients orange"
            placeholder={
              this.props.disabled
                ? 'Select a user/team first'
                : this.props.postToTeam
                ? 'Choose a team to post'
                : 'Choose a space to post'
            }
            pageSize={5}
            disabled={this.props.disabled}
            valueRenderer={option => <Option option={option} />}
          />
        </SelectWrap>,
        dropdownContainer
      );
    }

    this.setState({ tab: this.props.tab });
    return null;
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamDropdown);
