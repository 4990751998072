import React from 'react';

export const FEED_STEPS = [
  // 0
  {
    key: 'initialFeedStep',
    placement: 'center',
    target: 'body',
    content:
      'Welcome to the feed, where you can connect and collaborate with your team. From sharing photo or video updates, giving shoutouts, running polls or retrospectives, there’s a lot you can do to improve (and enjoy!) the way you work together.',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 1
  {
    key: 'clickPlusBtnStep',
    target: '#add-btn-target',
    content: (
      <div>
        Whatever you want to create or share, it all starts here.
        <b>&nbsp;Hit the + button to see what you can do.</b>
      </div>
    ),
    placement: 'right-start',
    disableOverlayClose: true,
    hideFooter: true,
    spotlightClicks: true,
    placementBeacon: 'center',
    disableOverlay: true,
    event: 'click',
    offset: 60,
    disableBeacon: true,
  },
  // 2
  {
    key: 'sharePostStep',
    target: '.create-forms-wrap',
    content:
      'Whether it’s a photo, a video or just text, it’s super simple to share with your team. Great for celebrating success or sharing important updates.',
    placement: 'top-left',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 3
  {
    key: 'giveGetFeedbackStep',
    target: '.create-discussion-target',
    content:
      'You can also give your team mates shoutouts to say thanks, ask for feedback or suggest something to start / stop / continue.',
    placement: 'bottom-right',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 4
  {
    key: 'createDiscussionStep',
    target: '#post-btn',
    content:
      'Let’s try sharing something you want to start / stop / continue. You can try this out just for yourself to start with (no one else will see what you post).',
    placement: 'right-end',
    hideFooter: true,
    disableOverlay: true,
    disableBeacon: true,
  },
  // 5
  {
    key: 'createSpaceBtnStep',
    target: '.add-space',
    content:
      'We’ll automatically create a Space for your entire account where you can post content to, however you can also create your own space for a specific team or group.',
    placement: 'right',
    disableBeacon: true,
    disableOverlayClose: true,
  },
];

export const INSIGHTS_STEPS = [
  // 0
  {
    key: 'initialInsightsStep',
    placement: 'center',
    target: 'body',
    content:
      'Welcome to your Insights dashboard. Here you can check in to get a quick overview of how the team is feeling overall, their key performance drivers and the associated triggers. Let’s have a quick look around.',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 1
  {
    key: 'vibeScoreStep',
    target: '.large-speedometer',
    content:
      'This is your team’s emotional state. We call it the Vibe. It’s calculated on a scale of -100 to 100 and is based on the answers provided in the weekly feedback cycle.',
    placement: 'right-end',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 2
  {
    key: 'scoreHistoryChartStep',
    target: '#score-history-chart',
    content:
      'See how things change over time by looking at your score history over the last 9 months. Visit the ‘Performance over time’ tab to dive into more detail.',
    placement: 'left',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 3
  {
    key: 'dateRangeStep',
    target: '.date-range-wrap',
    content:
      'Toggle the date range you’re looking at here. It’s the same across all Insights views; whether it’s what’s happening right now to what happened 3 months ago.',
    placement: 'left',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 4
  {
    key: 'togglePeriodStep',
    target: '.period-switch-wrap',
    content:
      'A great way to see how thing have changed is to turn on the ‘Show previous period’ toggle. Find out what to work on now and where you’re doing well.',
    placement: 'left',
    hideFooter: true,
    disableOverlay: true,
    disableBeacon: true,
  },
  // 5
  {
    key: 'keyPerformanceStep',
    target: '.key_performance_target',
    content:
      'Your key performance metrics are the drivers fuelling how the team is feeling. They’re all based on neuroscience and are key to your overall team performance.',
    placement: 'left',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 6
  {
    key: 'clickPlusBtnStep',
    target: '#add-btn-target',
    content: 'You can also Vibe by using (+) button',
    placement: 'right-start',
    disableOverlayClose: true,
    hideFooter: true,
    spotlightClicks: true,
    placementBeacon: 'center',
    disableOverlay: true,
    event: 'click',
    offset: 60,
    disableBeacon: true,
  },
  // 7
  {
    key: 'insightsTabStep',
    target: '.factor_target',
    content:
      'You can dive as deep as you like into the data, from analysing the factors in more detail, to looking at performance and mood over time and finally comparing mood against specific factors.',
    placement: 'bottom',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 8
  {
    key: 'takeActionBtnStep',
    target: '.take_action_target',
    content: (
      <div>
        'How easy was that? Once you’ve recorded your Vibe and checked out the data you can take
        action.
        <b>&nbsp;Click ‘Take action’ to see how.</b>
      </div>
    ),
    placement: 'bottom',
    hideFooter: true,
    disableOverlay: true,
    disableBeacon: true,
  },
  // 9
  {
    key: 'giveGetFeedbackStep',
    target: '.create-shoutout-target',
    content:
      'Looking for more feedback on something specific? Try running a poll. You can also give shoutouts to your team members and run retrospectives using the start / stop / continue tab.',
    placement: 'left-start',
    disableOverlayClose: true,
    disableBeacon: true,
  },
];

export const GROW_STEPS = [
  // 0
  {
    key: 'initialGrowStep',
    placement: 'center',
    target: 'body',
    content:
      'This is Grow, the place where you can find everything related to you personally. Whether it’s a note, meeting, ' +
      'win or action/goal, you can view everything or filter by content type if you’re after something specific.',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 1
  {
    key: 'filterGrowStep',
    placement: 'left',
    target: '#meetings',
    content:
      'Here’s where you will find your filters. You will start with viewing everything but can choose to sort by specific ' +
      'post type, user, date range and tag if you are looking for something in particular.',
    disableBeacon: true,
    disableOverlayClose: true,
  },
  // 2
  {
    key: 'boardGrowStep',
    placement: 'left',
    target: '#board-view-btn',
    content:
      'In addition to the timeline, you can also view everything on a Board which puts things into a number of columns. ' +
      'We’ll come back to this in a moment.',
    disableBeacon: true,
    disableOverlayClose: true,
  },
  // 3
  {
    key: 'clickPlusBtnStep',
    target: '#add-btn-target',
    content:
      'Let’s add something to Grow by setting yourself a goal. You can do that by clicking on the + button here ' +
      'or through the relevant buttons in Grow itself.',
    placement: 'right-start',
    disableOverlayClose: true,
    hideFooter: true,
    spotlightClicks: true,
    placementBeacon: 'center',
    disableOverlay: true,
    event: 'click',
    offset: 60,
    disableBeacon: true,
  },
  // 4
  {
    key: 'addGoalContentStep',
    target: '.action_icon_target',
    content:
      'To save some time we’ve taken you straight to the Action / Goal option under ‘Perform’. Add a title and a short ' +
      'description. We recommend ‘Test’ and ‘Test’. Then select a due date.',
    placement: 'right',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 5
  {
    key: 'postGoalStep',
    target: '#post-btn',
    content:
      'You can also tag a specific company value or a KPI if applicable. When you’re ready, simply press ‘Create’.',
    placement: 'right-end',
    hideFooter: true,
    disableOverlay: true,
    disableBeacon: true,
  },
  // 6
  {
    key: 'finalGrowFeedStep',
    placement: 'center',
    target: 'body',
    content:
      'Nice work! You’ve just created a test goal. Once a goal has been created it automatically appears here in your ' +
      'Timeline and also on your Board. Let’s see how we can use the Board to keep a visual track of where things are up to.',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 7
  {
    key: 'boardStagesStep',
    target: '.todo_target',
    content:
      'We give you four key stages - To do, In Progress, Blocked and Complete. Let’s see what happens when you drag a goal ' +
      'into the Complete column.',
    placement: 'right-start',
    hideFooter: true,
    disableOverlayClose: true,
    disableOverlay: true,
    disableBeacon: true,
  },
  // 8
  {
    key: 'convertGoalToWinStep',
    target: '.win_icon_target',
    content:
      'Yeeha! Once you have completed a goal you will be automatically prompted to turn it into a win. You can access it ' +
      'at anytime by selecting the ‘Achievements’ filter.',
    placement: 'left',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 9
  {
    key: 'winConvertFinishStep',
    target: '#post-btn',
    content:
      'It means that the next time you meet up with your manager, you’re ready to showcase all your great work. Press ' +
      '‘Convert’ now.',
    placement: 'right-end',
    hideFooter: true,
    disableOverlay: true,
    disableBeacon: true,
  },
  // 9
  {
    key: 'setUpMeetingStep',
    target: '#create-meeting-btn',
    content:
      'Got a meeting coming up? We make that all easier by helping you prepare and track what you discussed.',
    placement: 'right-start',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 10
  {
    key: 'meetingNoteStep',
    target: 'body',
    content:
      'There are two ways to create a meeting. You can either create a note with next steps as you’re having a meeting, ' +
      'or use the schedule function to create recurring meetings to keep you on track individually or with your team.',
    placement: 'center',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 11
  {
    key: 'recurringMeetingStep',
    target: 'body',
    content:
      'If you’re setting up a recurring meeting, select who it’s with, when the next one is, how often it happens and ' +
      'when you want to be reminded to prepare. Based on what you select we will then send you alerts to make sure you’re ready.',
    placement: 'center',
    disableOverlayClose: true,
    disableBeacon: true,
  },
];

export const SETUP_STEPS = [
  // 0
  {
    key: 'initialSetupStep',
    placement: 'center',
    target: 'body',
    content:
      'This is your setup screen. Here you can create your team structure, add users and assign them with simple drag an drop functionality. You can set up everything before sending anyone an invite. Let’s look in closer detail at how you do it.',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 1
  {
    key: 'teamStep',
    placement: 'right-end',
    target: '#main-team-paragraph',
    content:
      'You automatically have one team for your entire account that everyone belongs to. If you have just one team, leave your users in the bucket.',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 2
  {
    key: 'addTeamStep',
    placement: 'right',
    target: '#company-setup .add-team-tile',
    content: (
      <div>
        If you have multiple teams as part of your account, you can add them by hitting the button
        here. It works like a hierarchy where each team on the right is another level down.
        <b>&nbsp;Hit the + Add another team button</b>&nbsp;to try it.
      </div>
    ),
    disableOverlayClose: true,
    disableBeacon: true,
    hideFooter: true,
    spotlightClicks: true,
    disableOverlay: true,
    event: 'click',
    // offset: 10,
  },
  // 3
  {
    key: 'addTeamBtnStep',
    placement: 'right',
    target: '#company-setup .add-team-tile',
    content: 'Give it a name and click ‘Add’. You can always change this later.',
    disableOverlayClose: true,
    disableBeacon: true,
    hideFooter: true,
    spotlightClicks: true,
    disableOverlay: true,
    event: 'click',
    // offset: 10,
  },
  // 4
  {
    key: 'addUserStep',
    placement: 'right-start',
    target: '#add-users-button',
    content: (
      <div>
        Ok let’s try adding a user. You can either add them one by one or copy and paste from a
        list.
        <b>&nbsp;Hit the button to try it out.</b>
      </div>
    ),
    disableOverlayClose: true,
    disableBeacon: true,
    hideFooter: true,
    spotlightClicks: true,
    placementBeacon: 'center',
    disableOverlay: true,
    event: 'click',
    offset: 10,
  },
  // 5
  {
    key: 'nextBtnStep',
    placement: 'right-start',
    target: '.add-users-modal .next-step-button',
    content: (
      <div>
        Simply type in their email, first and last name. They won’t be sent an invitation until the
        second stage of the setup process. Click the
        <b>&nbsp;‘Next step’ button to finish adding them.</b>
      </div>
    ),
    disableOverlayClose: true,
    disableBeacon: true,
    hideFooter: true,
    spotlightClicks: true,
    placementBeacon: 'center',
    disableOverlay: true,
    event: 'click',
    offset: 10,
  },
  // 6
  {
    key: 'userTileStep',
    placement: 'top',
    target: '.main-team-members-list-wrap',
    content: (
      <div>
        To get a team member in their team, simply drag them over.
        <b>&nbsp;Click and hold on the person to make it happen.</b>
      </div>
    ),
    disableOverlayClose: true,
    disableBeacon: true,
    hideFooter: true,
    spotlightClicks: true,
    placementBeacon: 'center',
    disableOverlay: true,
    event: 'click',
    offset: 10,
  },
  // 7
  {
    key: 'teamLeadStep',
    placement: 'right-start',
    target: '.team-leads-list .Select-control',
    content:
      'Set the lead by selecting a user from the list. Leads do not contribute to a team vibe and you can have the same user as a lead of multiple teams in case they manage more than one.',
    disableOverlayClose: true,
    disableBeacon: true,
  },
  // 8
  {
    key: 'nextStepBtn',
    placement: 'left-start',
    target: '#company-setup .next-step-button',
    content:
      'That’s how easy it is to create teams and add users. Once you’re happy with the team structure, let’s see how you send your team an invite to join Heelix.',
    disableOverlayClose: true,
    disableBeacon: true,
    hideFooter: true,
    spotlightClicks: true,
    placementBeacon: 'center',
    disableOverlay: true,
    event: 'click',
    offset: 10,
  },
  // 9
  {
    key: 'firstUserTileStep',
    placement: 'right-start',
    target: '.team-tile-header .invite-checkbox',
    content:
      'Welcome to Stage 2 of the setup process, where you can select which team members to invite. Simply tick the checkbox next to a user or the box at the top of the team to select all users. Who you invite when is up to you. It can be everyone at once or you can invite people in stages.',
    disableOverlayClose: true,
    disableOverlay: true,
    disableBeacon: true,
  },
  // 10
  {
    key: 'sendInvitationsStep',
    placement: 'top',
    target: '#company-setup .next-step-button',
    content:
      'Once you’re happy with who you’d like to invite, hit the ‘Send invitations’ button here and they’ll get an email inviting them to join up, together with an automated onboarding process.',
    disableOverlayClose: true,
    disableBeacon: true,
    hideFooter: true,
    spotlightClicks: true,
    placementBeacon: 'center',
    disableOverlay: true,
    event: 'click',
    offset: 10,
  },
  // 11
  {
    key: 'notificationStep',
    placement: 'bottom',
    target: '.company-view-switch-wrap .switch',
    content:
      'The notification bar will alert you of any unactivated users. You can go back to the setup process at any time and edit your teams and users. Just hit the ‘Setup’ cog in the top right of the ‘Org chart’.',
    disableOverlayClose: true,
    disableBeacon: true,
  },
];
