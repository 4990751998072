import Cookies from 'universal-cookie';
import {
  ADMIN_ROLE,
  APP_URL,
  COMPANY_OWNER_ROLE,
  LOGIN_TIMEOUT_MILISECONDS,
  TEAM_MANAGER_ROLE,
  TEAM_MEMBER_ROLE,
} from '../../constants';
import { CHECK_UNAUTHORIZED, CLEAR_COOKIE_TOKEN, LOCALSTORAGE_GET, SIGN_OUT } from '../../helpers';
import axios from './axiosAuthenticated';

export default function cookieSignIn(redirect, handleError) {
  const cookies = new Cookies();
  const token = cookies.get('heelix_auth_token');
  if (token) {
    sessionStorage.clear();
    localStorage.clear();

    localStorage.setItem('access_token', token);
    localStorage.setItem('is_authenticated', true);

    localStorage.setItem(
      'token_expires_at',
      new Date(new Date().getTime() + LOGIN_TIMEOUT_MILISECONDS)
    );
    sessionStorage.setItem(
      'token_expires_at',
      new Date(new Date().getTime() + LOGIN_TIMEOUT_MILISECONDS)
    );

    axios.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${LOCALSTORAGE_GET('access_token')}`);

    fetch(`${APP_URL}/user_basic_data`, { headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (response.status === 200 && response.ok === true) {
          response.json().then(jsonResponse => {
            localStorage.setItem('user_first_name', jsonResponse.first_name);
            localStorage.setItem('user_last_name', jsonResponse.last_name);
            localStorage.setItem('user_image_uid', jsonResponse.image_uid);
            localStorage.setItem('user_id', jsonResponse.id);
            localStorage.setItem('user_email', jsonResponse.email);
            localStorage.setItem('user_created_at', jsonResponse.created_at);
            localStorage.setItem('user_type', jsonResponse.type);
            localStorage.setItem('account_id', jsonResponse.account_id);
            localStorage.setItem('account_name', jsonResponse.account_name);
            localStorage.setItem('upgrade_account', jsonResponse.upgrade_account);

            jsonResponse.roles.forEach(role => {
              switch (role.name) {
                case ADMIN_ROLE:
                  localStorage.setItem('is_admin', true);
                  break;
                case COMPANY_OWNER_ROLE:
                  localStorage.setItem('is_company_owner', true);
                  break;
                case TEAM_MANAGER_ROLE:
                  localStorage.setItem('is_team_manager', true);
                  break;
                case TEAM_MEMBER_ROLE:
                  localStorage.setItem('is_team_member', true);
                  break;
                default:
              }
            });
            onSuccess(redirect);
          });
        } else {
          response.json().then(json => {
            onFailure(() => handleError(json?.error ?? json?.errors ?? ''));
          });
        }
      })
      .catch(error => {
        onFailure(() => handleError(error));
      });
  } else {
    onFailure(() => handleError('Missing token, please try again.'));
  }
}

function onSuccess(redirect) {
  CLEAR_COOKIE_TOKEN();
  redirect();
}

function onFailure(handleError) {
  SIGN_OUT(false);
  handleError();
}
