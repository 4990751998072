/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';
import { withRouter } from 'react-router-dom';
import WelcomeContainer from './WelcomeContainer';

const ErrorPage = ({ title, body, goBack, history }) => {
  return (
    <WelcomeContainer
      className="text-center"
      title={<span className="error-color">{title}</span>}
      body={
        <React.Fragment>
          {body}
          <div
            css={css`
              margin-top: 20px;
            `}
          >
            <a
              onClick={goBack ?? (() => history.push('/'))}
              css={css`
                cursor: pointer;
              `}
            >
              Go back
            </a>
          </div>
        </React.Fragment>
      }
    />
  );
};

ErrorPage.propTypes = {
  title: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  goBack: PropTypes.func,
};

export default withRouter(ErrorPage);
