/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import AnsweredQuestionnaire from 'components/feed/QuestionnaireTile/AnsweredQuestionnaire';
import { Button } from 'components/feed/QuestionnaireTile/Questionnaire';

const AnsweredView = ({ post, setTeam, tileRealignHandler = null }) => {
  const [full, setFull] = useState(post.questions.length === 1);
  return (
    <>
      <AnsweredQuestionnaire post={post} full={full} setTeam={setTeam} />
      {!full && (
        <>
          <Button
            onClick={e => {
              e.stopPropagation();
              setFull(true);
              tileRealignHandler && tileRealignHandler();
            }}
          >
            View all results
          </Button>
        </>
      )}
    </>
  );
};

export default AnsweredView;
