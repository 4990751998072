import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { POST_ACTIONS } from '../../redux/actions/postActions';
import { SPACE_ACTIONS } from '../../redux/actions/spaceActions';
import SpaceLogo from '../spaces/SpaceLogo';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';
import { APP_URL } from '../../constants';

class GuestView extends Component {
  requestAccess(e, spaceId) {
    e.stopPropagation();
    const url = `${APP_URL}/spaces/request_access/${spaceId}`;
    const headers = HEADERS();
    fetch(url, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        this.props.resetPosts();
        this.props.fetchPosts();
        this.props.fetchSpaceDetails(spaceId);
      });
  }

  joinSpace(e, spaceId) {
    e.preventDefault();
    const url = `/spaces/join/${spaceId}`;
    const headers = HEADERS();
    fetch(APP_URL + url, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        this.props.resetPosts();
        this.props.fetchPosts();
        this.props.fetchSpaceDetails(spaceId);
        this.props.fetchFormalSpace(spaceId);
      });
  }

  render() {
    return (
      <div className="guest-view-feed">
        <div className="guest-view-wrap">
          <div className="col-md-2 circle-team-logo">
            <SpaceLogo space={this.props.team} />
          </div>
          <div className="col-md-10 team-info">
            <h1>{this.props.team.name}</h1>
            <p>{this.props.team.description}</p>
            {this.props.team.private ? (
              this.props.team.not_confirmed ? (
                <input
                  id="join-btn"
                  type="button"
                  disabled
                  name="join-space"
                  className="btn btn-primary"
                  value="Request sent"
                />
              ) : (
                <input
                  id="join-btn"
                  type="button"
                  name="join-space"
                  className="btn btn-primary"
                  value="Request access"
                  onClick={e => this.requestAccess(e, this.props.team.id)}
                />
              )
            ) : (
              <input
                id="join-btn"
                type="button"
                name="join-space"
                className="btn btn-primary"
                value="Join"
                onClick={e => this.joinSpace(e, this.props.team.id)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  team: state.teamsDomain.team,
});

const mapDispatchToProps = dispatch => ({
  fetchPosts: () => dispatch(POST_ACTIONS.fetchPosts()),
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  fetchSpaceDetails: spaceId => dispatch(SPACE_ACTIONS.fetchSpaceDetails(spaceId)),
  fetchFormalSpace: spaceId => dispatch(SPACE_ACTIONS.fetchFormalSpace(spaceId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GuestView));
