import fetchApi from 'fetchApi';

export const fetchChampionsData = async (month, year) => {
  const response = await fetchApi(`/champions/${month}/${year}`);
  const data = response.leaderboard.company_values.filter(el => el.users.length);
  return {
    ...response.leaderboard,
    companyValues: data,
  };
};
