/** @jsx jsx */
import { Modal } from 'react-bootstrap';
import { modalCss } from 'components/payments/PaymentChoose/shared/layout';
import { css, jsx } from '@emotion/core';
import React, { useState } from 'react';
import fetchApi from 'fetchApi';
import { BlueButton } from 'components/payments/PaymentChoose/shared/PlanButton';
import LoadingOverlay from 'components/signup/LoadingOverlay';
import { withRouter } from 'react-router-dom';
import { OBJECT_TO_FORMDATA } from 'helpers';
import CreditCard from 'components/payments/CreditCard';
import styled from '@emotion/styled';

const CardHeader = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin-top: 39px;
`;

export const initialData = {
  currentPlanDetails: '',
  upgradePlanDetails: '',
  upgradePlanMoreDetails: null,
  upgradePlanId: null,
  prorationTime: null,
  card: {
    brand: '',
    last4: '',
    name: '',
  },
};

const UpgradeModal = ({ show, handleClose, loading, content, data, history }) => {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    setFetching(true);
    setError(null);

    const body = {
      plan: data.upgradePlanId,
      ...(data.prorationTime ? { proration_time: data.prorationTime } : {}),
    };

    const response = await fetchApi('/payments/upgrade', {
      method: 'post',
      body: OBJECT_TO_FORMDATA(body),
    });

    if (!response.ok) {
      setError(response.message ?? 'Something went wrong.');
    } else {
      history.push('/payments/success');
    }

    setFetching(false);
  };
  const Content = content;

  return (
    <Modal show={show} onHide={handleClose} css={modalCss}>
      {loading ? (
        <div
          css={css`
            min-height: 400px;
          `}
        >
          <LoadingOverlay toggled={loading} height="100%" />
        </div>
      ) : (
        <div
          css={css`
            text-align: center;
            padding: 50px 25px 55px;
          `}
        >
          <Content />
          <CardHeader>Credit card details</CardHeader>
          <CreditCard
            lastFourDigits={data.card.last4}
            name={data.card.name}
            brand={data.card.brand}
            css={css`
              max-width: 240px;
              margin: 17px auto 40px;
            `}
            editLinkTo="/settings?editCard"
            editOnClick={handleClose}
            editText="Pay with different card"
          />
          {error && (
            <div
              css={css`
                color: #ff5c3e;
                margin-bottom: 10px;
              `}
            >
              {error}
            </div>
          )}
          <BlueButton
            onClick={handleSubmit}
            css={css`
              width: auto;
              padding: 11px 24px;
              font-size: 14px;
            `}
            disabled={fetching}
          >
            Confirm
          </BlueButton>
        </div>
      )}
    </Modal>
  );
};

export default withRouter(UpgradeModal);
