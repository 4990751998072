import React, { Component } from 'react';
import { Pagination } from 'react-bootstrap';
import qs from 'qs';
import { Link, withRouter } from 'react-router-dom';
import { AVATAR_ERROR, BODY_CLASSES, CHECK_UNAUTHORIZED } from '../../helpers';
import axios from '../../axiosAuthenticated';
import { APP_URL } from '../../constants';
import LoadingScale from '../shared/LoadingScale';
import Auxiliary from '../layouts/Auxiliary';

class UsersByTag extends Component {
  state = {
    subtitle: 'Shared interests',
    currentPage: 1,
    totalPages: 1,
    page: 1,
    perPage: 10,
    tag: '',
    tags: [],
    loading: false,
    showOtherTags: false,
    users: [],
  };

  componentDidMount() {
    BODY_CLASSES(['users', 'tags', 'jobvibe-future']);
    this.props.setHeader('Shared interests', '/grow_profile', 'Back', '');
    if (window.location.hash.split('?')[0] === '#/users/tags') {
      const query = window.location.href.split('?')[1];
      const parsedQuery = qs.parse(query);
      this.setState(
        {
          tag: parsedQuery.t,
          page: parsedQuery.page ? parsedQuery.page : 1,
        },
        () => this.updateData()
      );
    }
  }

  updateData = () => {
    if (window.location.hash.split('?')[0] === '#/users/tags') {
      this.setState({
        loading: true,
      });

      const query = window.location.href.split('?')[1];
      const parsedQuery = qs.parse(query);

      // --Items count
      let url;
      url = `${APP_URL}/users/tags/${parsedQuery.t}/${parsedQuery.page}`;

      axios
        .get(url)
        .then(response => {
          this.setState({
            totalPages: Math.ceil(response.data.users.total / response.data.users.per_page),
            page: parseInt(response.data.users.current_page),
            users: response.data.users.data,
            tags: response.data.tags,
            loading: false,
          });
        })
        .catch(error => {
          CHECK_UNAUTHORIZED(error.response);
          this.setState({
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    const query = window.location.href.split('?')[1];
    const parsedQuery = qs.parse(query);
    if (parsedQuery.t !== this.state.tag) {
      this.updateData();
    }
  }

  pageChangeHandler(number) {
    let query = this.props.location.search;
    query = qs.parse(query.slice(1, query.length));
    let stringified = '';
    if (query.t) {
      stringified = qs.stringify({ t: query.t }, { encode: false, arrayFormat: 'brackets' });
    }
    this.props.history.push(`/users/tags?page=${number}&${stringified}`);
  }

  showOtherTagsHandler = () => {
    this.setState({
      showOtherTags: true,
    });
  };

  render() {
    let content = <LoadingScale />;
    const otherTags =
      this.state.tags.length > 0 ? (
        this.state.tags.map(tag => (
          <a
            href={`/#/users/tags?t=${tag}&page=1`}
            onClick={this.updateData}
            key={tag}
            className="user-profile-tag"
          >
            #{tag}
          </a>
        ))
      ) : (
        <p>No other user shared any interests</p>
      );
    const users =
      this.state.users.length > 0 ? (
        this.state.users.map(user => {
          const user_tags = user.user_tags.slice(1, -1).split(',');
          const tags = user_tags.map(tag => (
            <Link
              key={tag}
              to={{
                pathname: '/users/tags',
                search: `?t=${tag.slice(1, -1)}&page=1`,
                state: { t: tag },
              }}
              className="user-profile-tag"
            >
              #{tag.slice(1, -1)}
            </Link>
          ));

          return (
            <div className="media users-tags-tile" key={user.id} id={`user-tag-${user.id}`}>
              <a href={`#/user/${user.id}`}>
                <div className="media-left">
                  <img
                    className="media-object"
                    src={user.image_url}
                    alt={`${user.first_name} ${user.last_name}`}
                    onError={e => AVATAR_ERROR(e.target)}
                  />
                </div>
                <div className="media-body">
                  <h4 className="media-heading">
                    {user.first_name} {user.last_name}
                  </h4>
                  <p>
                    Tags:
                    {tags}
                  </p>
                </div>
              </a>
            </div>
          );
        })
      ) : (
        <Auxiliary>
          <p>
            There are no users who share this same interest.
            <span id="view-all-interests-btn" onClick={this.showOtherTagsHandler}>
              {' '}
              View other interests from the team.
            </span>
          </p>
          <div id="other-interests-wrapper" hidden={!this.state.showOtherTags}>
            {otherTags}
          </div>
        </Auxiliary>
      );

    if (!this.state.loading) {
      content = (
        <Auxiliary>
          <div className="row">
            <div className="col-xs-12">
              <h1 className="fix-list-header">{this.state.subtitle}</h1>
            </div>
            <div className="col-xs-12">{users}</div>
          </div>
          <Pagination
            bsSize="medium"
            className={`
                            ${this.state.page === 1 ? 'prev-hidden' : ''}
                            ${
                              this.state.page === this.state.totalPages ||
                              this.state.totalPages === 0
                                ? 'next-hidden'
                                : ''
                            }
                        `}
            prev
            next
            first
            last
            maxButtons={5}
            items={this.state.totalPages}
            activePage={parseInt(this.state.page)}
            onSelect={this.pageChangeHandler}
          />
        </Auxiliary>
      );
    }

    return <div id="users-by-tags-container">{content}</div>;
  }
}

export default withRouter(UsersByTag);
