import loginWithGoogle from 'components/signup/integration/loginWithGoogle';
import { APP_URL } from '../../constants';

export default function registerWithGoogle(body, teamName, agreedToTerms, onSuccess, onFailure) {
  const data = new FormData();
  data.append('username', body.profileObj.email);
  data.append('token', body.accessToken);
  data.append('name', teamName);
  data.append('terms', agreedToTerms);

  fetch(`${APP_URL}/register_google`, { method: 'post', body: data })
    .then(response => {
      if (response.status === 200 && response.ok === true) {
        response
          .json()
          .then(() => {
            loginWithGoogle(body, onFailure).then(() => {
              onSuccess();
            });
          })
          .catch(error => {
            onFailure(error);
          });
      } else if (response.status === 422) {
        response.json().then(json => {
          onFailure(json);
        });
      } else {
        response.json().then(json => {
          onFailure(json);
        });
      }
    })
    .catch(error => {
      onFailure(error);
    });
}
