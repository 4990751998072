import styled from '@emotion/styled';

export const FileDropzoneWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const TextButton = styled.span`
  font-size: 12px;
  color: #11a9ff;
  cursor: pointer;
`;
