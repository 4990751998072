import TeamModal from 'components/company_structure/view_mode/TeamModal';
import React, { useEffect, useState } from 'react';
import fetchApi from 'fetchApi';
import PropTypes from 'prop-types';

const TeamModalContainer = ({ selectedId, setSelectedId }) => {
  const [teams, setTeams] = useState(undefined);
  const [selectedTeam, setSelectedTeam] = useState(undefined);

  const fetchTeams = async () => {
    const data = await fetchApi('/team_details');
    setTeams(
      data.map(team => ({
        ...team,
        parent_ids: team.parent_ids ? JSON.parse(team.parent_ids) : [],
      }))
    );
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  useEffect(() => {
    if (selectedId === null) {
      return setSelectedTeam(undefined);
    }

    return setSelectedTeam(teams.find(team => team.id === selectedId));
  }, [selectedId]);

  const showModal = selectedId !== null && selectedTeam !== undefined;

  return !teams || !selectedTeam ? null : (
    <TeamModal
      issuesMode={false}
      showTeamModal={showModal}
      close={() => setSelectedId(null)}
      team={selectedTeam}
      teams={teams}
      seeReviewButton={false}
    />
  );
};

TeamModalContainer.propTypes = {
  selectedId: PropTypes.number,
  setSelectedId: PropTypes.func.isRequired,
};

TeamModalContainer.defaultProps = {
  selectedId: null,
};

export default TeamModalContainer;
