/** @jsx jsx */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import styled from '@emotion/styled';
import { PropTypes } from 'prop-types';
import Page from 'components/layouts/Page';
import { APP_URL, INITIAL_GROW_FILTERS } from 'constants.js';
import { BODY_CLASSES, CHECK_UNAUTHORIZED, downloadCsv, HEADERS } from 'helpers';
import { USER_ACTIONS } from 'redux/actions/userActions';
import { GROW_ACTIONS } from 'redux/actions/growActions';
import { jsx } from '@emotion/core';
import { toggleNoteModal } from 'redux/app/actions';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import { getDatesWithPosts, filterSelectedPosts } from 'pages/Grow/Utils/utils';
import TopBarGrowProfile from 'pages/Grow/GrowProfile/TopBarGrowProfile';
import GrowProfileRightSidebar from 'pages/Grow/GrowProfile/GrowProfileRightSidebar';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import NextUpAgenda from 'pages/Grow/GrowProfile/NextUpAgenda';
import TodayEmptyAgenda from 'pages/Grow/GrowProfile/TodayEmptyAgenda';
import NewGrowPosts from 'pages/Grow/GrowProfile/NewGrowPosts';
import GrowPostModal from 'pages/Grow/GrowPostModal';
import UserProfile from 'components/grow/UserProfile';

const HR = styled.hr`
  background: linear-gradient(180deg, #dfdfdf 0%, rgba(232, 232, 232, 0) 100%);
  height: 12px;
  margin: 17px 0 0 0;
  @media (min-width: 768px) {
    margin: 17px -4px 0 0;
  }
`;
export const CustomPageWrapper = styled(Page.Wrapper)`
  display: flex;
  margin: 0 !important;
  max-width: ${({ isProfile }) => (isProfile ? '1200px' : '1000px')};
  flex: 1;
  @media (min-width: 768px) {
    margin: 0 -15px !important;
  }
`;
export const CustomPageContent = styled(Page.Content)`
  flex: 1;
  padding: 0 !important;
  @media (min-width: 768px) {
    padding: 0 18px !important;
  }
`;
export const CustomPageSection = styled(Page.Section)`
  padding: 0 !important;
  @media (min-width: 768px) {
    padding: 20px !important;
  }
`;

class GrowProfileNew extends Component {
  initialFilters = INITIAL_GROW_FILTERS;

  constructor(props) {
    super(props);
    this.state = {
      viewAllHashtags: false,
      growPostId: '',
      growPostType: '',
      auth: '',
      teamName: '',
      localTime: null,
      userAlreadyVibed: false,
      loading: true,
      filterByUserOptions: [],
      exportModeOn: false,
      isExporting: false,
      postRenderAlignment: false,
      isDeleting: false,
      hasDeleteError: false,
    };
  }

  fetchData() {
    this.setState({ loading: true });
    const headers = HEADERS();
    fetch(`${APP_URL}/grow`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => response.json())
      .then(response => {
        this.setState({
          auth: response.auth,
          teamName: response.teamName,
          loading: false,
          localTime: response.local_time,
          userAlreadyVibed: response.userAlreadyVibed,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    BODY_CLASSES(['grow', 'index', 'jobvibe-future']);
    this.fetchMembersData();
    const qsFilters = qs.parse(window.location.href.split('?')[1]);
    const hasFilters = Object.keys(qsFilters).some(
      param =>
        Object.keys(this.initialFilters).includes(param) && qsFilters[param] && param !== 'tab'
    );
    const {
      startGrowTour,
      resetPosts,
      updateVisited,
      filtersFromObject,
      fetchPosts,
      location,
      user,
    } = this.props;
    const { grow } = user.visited;
    const { pathname, search } = location;
    if (
      pathname === `/${growProfileUrl}` &&
      (search === '' || 'grow_tour' in qsFilters || (!hasFilters && qsFilters['tab'] === 'grow'))
    ) {
      filtersFromObject(this.initialFilters);
      resetPosts();
      fetchPosts(qs.parse(window.location.search, { ignoreQueryPrefix: true }).tab ?? 'grow');
    }
    this.fetchData();
    if ('grow_tour' in qsFilters) {
      startGrowTour();
    }
    if (!grow) {
      startGrowTour();
      updateVisited('grow');
    }
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.showNoteModal && prevProps.showNoteModal) {
      this.clearPostFromQuery();
    }

    const { filters, resetPosts, fetchPosts, filtersFromObject, loadedPostsCount } = this.props;
    const filtersCopy = JSON.parse(JSON.stringify(filters));
    const qsFilters = qs.parse(window.location.href.split('?')[1]);
    if (
      window.location.href.split('?')[1] !== 'undefined' &&
      window.location.href.indexOf(growProfileUrl) > 0
    ) {
      Object.keys(filtersCopy).map(key => {
        filtersCopy[key] = qsFilters[key] ? qsFilters[key] : this.initialFilters[key];
      });
      if (JSON.stringify(filtersCopy) !== JSON.stringify(filters)) {
        filtersFromObject(filtersCopy);

        if (filters.post && !filtersCopy.post) return;
        if (loadedPostsCount && filtersCopy.post) {
          this.handlePostModal(filtersCopy.post);
          return;
        }

        if (
          qs.parse(prevProps.location.search, { ignoreQueryPrefix: true }).tab !==
          qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).tab
        ) {
          this.setState(
            {
              exportModeOn: false,
              isExporting: false,
            },
            () => {
              this.props.resetPostsSelection();
            }
          );
        }

        resetPosts();
        fetchPosts(qs.parse(window.location.search, { ignoreQueryPrefix: true }).tab);
        this.handlePostModal(filtersCopy.post);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { fetchingPosts, hasMorePostsToLoad, fetchPosts } = this.props;

    if (window.scrollY + window.innerHeight >= document.body.offsetHeight - 500) {
      if (fetchingPosts === false && hasMorePostsToLoad && window.scrollY > 0) {
        fetchPosts(qs.parse(window.location.search, { ignoreQueryPrefix: true }).tab);
      }
    }
  };

  handlePostModal = id => {
    const headers = HEADERS();
    fetch(`${APP_URL}/grow_post/${id}`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => response.json())
      .then(response => {
        if (response.post) {
          this.setPostData(response.post, response.growPostId, response.growPostType);
        }
      })
      .catch(() => {});
  };

  openPostModal = (post, growPostId, growPostType, fetchPost = false) => {
    if (post.type !== 'Meeting') {
      if (fetchPost || post.total_comments_count > 2) {
        this.handlePostModal(growPostId);
      } else {
        this.setPostData(post, growPostId, growPostType);
      }
    }
  };

  setPostData = (post, growPostId, growPostType) => {
    this.setState({
      growPostId,
      growPostType,
    });
    this.props.receivePost(post);
    this.props.toggleNoteModal(true);
  };

  fetchMembersData = async () => {
    const headers = HEADERS();
    const response = await fetch(`${APP_URL}/member_notes/modal_data`, { headers });
    const json = await response.json();

    json.teams = json.teams.map(team => ({
      value: `${team.id}_team`,
      id: team.id,
      label: team.name,
      type: 'team',
    }));

    json.leads = json.leads
      .filter(lead => lead.id !== this.props.user.id)
      .map(lead => ({
        value: `${lead.id}_user`,
        id: lead.id,
        label: `${lead.first_name} ${lead.last_name}`,
        type: 'user',
      }));

    const shareOptions = json.teams.concat(json.leads);

    shareOptions.sort((recipientA, recipientB) => {
      const nameA = recipientA.label ? recipientA.label.toLowerCase() : '';
      const nameB = recipientB.label ? recipientB.label.toLowerCase() : '';
      if (nameA < nameB) {
        return -1;
      }
      if (nameA >= nameB) {
        return 1;
      }
      return 0;
    });
    this.setState({ filterByUserOptions: shareOptions });
  };

  clearPostFromQuery = () => {
    const { history, location } = this.props;
    const newQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (newQuery.post) {
      delete newQuery.post;
      history.replace(`${location.pathname}?${qs.stringify(newQuery)}`);
    }
  };

  closeNoteModal = () => {
    const { toggleNoteModal } = this.props;
    toggleNoteModal(false);
    this.clearPostFromQuery();
  };

  exportSelectedPostsHandler = async () => {
    this.setState({ isExporting: true, isDeleting: true, hasDeleteError: false });
    const urlSearch = new URLSearchParams();
    this.props.selectedPosts.forEach(value => urlSearch.append('postId[]', value));
    const headers = HEADERS();
    const response = await fetch(`${APP_URL}/member_notes/report?${urlSearch}`, { headers });
    const json = await response.json();
    downloadCsv(json.data, json.fileName);
    this.props.resetPostsSelection();
    this.setState({
      exportModeOn: false,
      isExporting: false,
      isDeleting: false,
      hasDeleteError: false,
    });
  };

  deleteSelectedPostsHandler = async () => {
    this.props.closeFlash();
    this.setState({ hasDeleteError: false });
    const { selectedPosts } = this.props;
    const sharedSelectedPosts = this.props.posts.filter(
      post =>
        selectedPosts.includes(post.id) &&
        (post.type.includes('Shared') ||
          (post.type.includes('Shoutout') && post.post.shoutout.author_id !== this.props.user.id))
    );
    if (sharedSelectedPosts.length > 0) {
      this.setState({ hasDeleteError: true });
      return;
    }
    this.setState({ isExporting: true, isDeleting: true, hasDeleteError: false });
    this.props
      .deleteSelectedPosts(this.props.selectedPosts)
      .then(json => {
        if (json?.flashName) this.props.updateFlash(json.flashName, json.message);
        this.setState({
          exportModeOn: false,
          isExporting: false,
          isDeleting: false,
          hasDeleteError: false,
          postRenderAlignment: true,
        });
      })
      .catch(err => {
        if (err?.flashName) this.props.updateFlash(err.flashName, err.message);
        this.setState({
          isExporting: false,
          isDeleting: false,
        });
      });
  };

  exportModeChangedHandler = () => {
    this.setState({ exportModeOn: !this.state.exportModeOn }, () => {
      if (!this.state.exportModeOn) this.props.resetPostsSelection();
    });
  };

  resetPostsSelectionHandler = () => {
    this.props.resetPostsSelection();
  };

  selectAllPostsHandler = () => {
    const allPosts = [...this.props.posts];
    const filteredPostsIds = allPosts
      .filter(post => filterSelectedPosts(post))
      .map(post => post.id);
    if (!filteredPostsIds.length) {
      alert('There are no posts to be selected. Please load more posts.');
      return;
    }
    this.props.selectAllPosts();
  };

  handleSelectPost = id => {
    this.props.selectPost(id);
    return;
    const array = [...this.state.selectedPosts];
    const indexOf = array.indexOf(id);
    if (indexOf < 0) {
      array.push(id);
    } else {
      array.splice(indexOf, 1);
    }
    this.setState({ selectedPosts: array }, () => {
      const allPosts = [...this.props.posts]
        .filter(post => filterSelectedPosts(post))
        .map(post => post.id);
      this.setState({
        allPostsSelected: JSON.stringify(array.sort()) === JSON.stringify(allPosts.sort()),
      });
    });
  };

  tileRealignHandler = () => {
    this.setState({ postRenderAlignment: true });
  };

  render() {
    const setState = this.setState.bind(this);
    const {
      showNoteModal,
      post,
      showActionModal,
      user,
      dates,
      posts,
      hasMorePostsToLoad,
      history,
      filters,
    } = this.props;
    const {
      growPostId,
      growPostType,
      viewAllHashtags,
      filterByUserOptions,
      exportModeOn,
      isExporting,
      isDeleting,
      hasDeleteError,
    } = this.state;
    const isTodayInDates = dates.some(el => el.today);
    const { auth, teamName, localTime, userAlreadyVibed, loading } = this.state;
    const isProfile =
      qs.parse(window.location.search, { ignoreQueryPrefix: true }).tab === 'profile';
    const isTodayChosenInSelectedPeriodTime = period => {
      if (period === 'custom') {
        const startDate = new Date(filters.start_date);
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(filters.end_date);
        endDate.setHours(23, 59, 59, 999);
        const today = new Date();
        if (startDate <= today && today <= endDate) {
          return true;
        }
      }
      return period >= 0;
    };

    return (
      <>
        <CustomPageSection>
          <CustomPageWrapper>
            {!isProfile && (
              <CustomPageContent>
                <TopBarGrowProfile />
                <HR />
                <NextUpAgenda openPostModal={this.openPostModal} />
                {!isTodayInDates && isTodayChosenInSelectedPeriodTime(filters.period) && (
                  <TodayEmptyAgenda />
                )}
                {dates && (
                  <NewGrowPosts
                    hasMorePostsToLoad={hasMorePostsToLoad}
                    user={user}
                    openPostModal={this.openPostModal}
                    tileRealignHandler={this.tileRealignHandler}
                    posts={posts}
                    dates={getDatesWithPosts(dates, posts)}
                    history={history}
                    exportModeOn={exportModeOn}
                    hasDeleteError={hasDeleteError}
                    handleSelectPost={this.handleSelectPost}
                  />
                )}
              </CustomPageContent>
            )}
            {isProfile && !loading && (
              <UserProfile
                user={auth}
                teamName={teamName}
                localTime={localTime}
                userAlreadyVibed={userAlreadyVibed}
                loading={loading}
              />
            )}
          </CustomPageWrapper>

          <Page.Sidebar>
            <GrowProfileRightSidebar
              viewAllHashtags={viewAllHashtags}
              toggleViewAllHashtags={() => setState({ viewAllHashtags: !viewAllHashtags })}
              history={history}
              filterByUserOptions={filterByUserOptions}
              exportModeOn={exportModeOn}
              changeExportMode={this.exportModeChangedHandler}
              isExportContentVisible={!isProfile && !loading}
              isExporting={isExporting}
              isDeleting={isDeleting}
              hasDeleteError={hasDeleteError}
              exportSelected={this.exportSelectedPostsHandler}
              deleteSelected={this.deleteSelectedPostsHandler}
            />
          </Page.Sidebar>
          {post && (
            <GrowPostModal
              showNote={showNoteModal}
              showActionModal={showActionModal}
              closeNoteModal={this.closeNoteModal}
              post={post}
              growPostId={growPostId}
              growPostType={growPostType}
            />
          )}
        </CustomPageSection>
      </>
    );
  }
}

GrowProfileNew.propTypes = {
  showNoteModal: PropTypes.func.isRequired,
  showActionModal: PropTypes.func.isRequired,
  dates: PropTypes.instanceOf(Object).isRequired,
  post: PropTypes.instanceOf(Object).isRequired,
  hasMorePostsToLoad: PropTypes.func.isRequired,
  posts: PropTypes.instanceOf(Array).isRequired,
  filters: PropTypes.instanceOf(Array).isRequired,
  startGrowTour: PropTypes.func.isRequired,
  resetPosts: PropTypes.func.isRequired,
  updateVisited: PropTypes.func.isRequired,
  filtersFromObject: PropTypes.func.isRequired,
  fetchPosts: PropTypes.func.isRequired,
  receivePost: PropTypes.func.isRequired,
  toggleNoteModal: PropTypes.func.isRequired,
  fetchingPosts: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  plan: state.plansDomain.plan,
  selectedPosts: state.growDomain.selectedPosts,
  allPostsSelected: state.growDomain.allPostsSelected,
  filters: state.growDomain.filters,
  posts: state.growDomain.posts,
  dates: state.growDomain.dates,
  post: state.growDomain.post,
  fetchingPosts: state.growDomain.fetchingPosts,
  hasMorePostsToLoad: state.growDomain.hasMorePostsToLoad,
  loadedPostsCount: state.growDomain.loadedPostsCount,
  hashtags: state.tagsDomain.hashtags,
  showNoteModal: state.app.showNoteModal,
  showActionModal: state.app.showActionModal,
  spaces: state.spacesDomain.spaces
    ? state.spacesDomain.spaces.filter(
        space =>
          !space.account_default_space &&
          (space.is_member || space.is_team_lead || space.is_visitor)
      )
    : [],
  feedEnabled: state.usersDomain.feedEnabled,
});

const mapDispatch = dispatch => ({
  selectPost: id => dispatch(GROW_ACTIONS.selectPost(id)),
  selectAllPosts: () => dispatch(GROW_ACTIONS.selectAllPosts()),
  deleteSelectedPosts: postIds => dispatch(GROW_ACTIONS.deleteSelectedPosts(postIds)),
  resetPostsSelection: () => dispatch(GROW_ACTIONS.resetPostsSelection()),
  fetchPosts: tab => dispatch(GROW_ACTIONS.fetchPosts(tab)),
  resetPosts: () => dispatch(GROW_ACTIONS.resetPosts()),
  receivePost: post => dispatch(GROW_ACTIONS.receivePost(post)),
  filtersFromObject: object => dispatch(GROW_ACTIONS.filtersFromObject(object)),
  toggleNoteModal: toggled => dispatch(toggleNoteModal(toggled)),
  startGrowTour: () => dispatch(ONBOARDING_ACTIONS.startGrowTour()),
  updateVisited: page => dispatch(USER_ACTIONS.updateVisited(page)),
});

export default connect(mapStateToProps, mapDispatch)(GrowProfileNew);
