import { TEAM_ACTION_TYPES, TEAM_ACTIONS } from '../actions/teamActions';
import store from '../store';
import { APP_URL } from '../../constants';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';

const teamReducer = (state, action) => {
  const headers = HEADERS();
  switch (action.type) {
    case TEAM_ACTION_TYPES.FETCH_TEAMS:
      fetch(`${APP_URL}/teams/refresh_list`, { method: 'get', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            store.dispatch(TEAM_ACTIONS.receiveTeams(json));
            action.payload.successCallback();
          });
        });
      return state;
    case TEAM_ACTION_TYPES.RECEIVE_TEAMS:
      return {
        ...state,
        teamsDomain: {
          ...state.teamsDomain,
          teams: action.payload.teams,
        },
      };
    default:
      return state;
  }
};
export default teamReducer;
