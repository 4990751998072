import React, { Component } from 'react';

class ChangeBillingContactModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: '',
    };
  }

  changeBillingContact(e) {
    e.preventDefault();
    const id = this.state.userId === '' ? this.props.accountAdmins[0].id : this.state.userId;
    this.props.changeBillingContact(id);
  }

  render() {
    const display = this.props.popup_display ? 'block' : 'none';
    return (
      <div
        className="modal in"
        id="move-users-modal"
        style={{ display, background: 'rgba(0,0,0,0.75)' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header no-border centered">
              <button
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.closePopup}
              >
                <i className="fa fa-times" aria-hidden="true" />
              </button>
              <h2 className="modal-title">Change billing contact</h2>
            </div>
            <div className="modal-body centered u-padding-horizontal-md">
              <select
                name="team_id"
                id="team_id"
                className="select required form-control select2"
                tabIndex={-1}
                aria-hidden="true"
                onChange={e => this.setState({ userId: e.target.value })}
              >
                {this.props.accountAdmins &&
                  this.props.accountAdmins.map(admin => (
                    <option value={admin.id} key={admin.id}>
                      {admin.first_name} {admin.last_name}
                    </option>
                  ))}
              </select>
              <input
                type="button"
                value="Confirm"
                className="btn btn-primary u-margin-top-sm u-margin-bottom-sm u-padding-horizontal-md"
                data-dismiss="modal"
                data-name="change-billing"
                onClick={e => this.changeBillingContact(e)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ChangeBillingContactModal;
