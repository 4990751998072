/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import awesome from 'images/vibe/awesome.svg';
import good from 'images/vibe/good.svg';
import okay from 'images/vibe/okay.svg';
import bad from 'images/vibe/bad.svg';
import terrible from 'images/vibe/terrible.svg';
import { TooltipIcon } from './TooltipIcon';

const Wrapper = styled.div`
  width: 100%;
  padding: 0 52px;
  float: left;
  @media (max-width: 1199px) {
    padding: 0;
    width: 100%;
  }
`;

const moods = ['terrible', 'bad', 'okay', 'good', 'awesome'];

const activeLabel = css`
  font-size: 16px;
  color: #323232;
`;

const inactiveLabel = css`
  font-size: 16px;
  color: #d2d2d2;
`;

const Mood = styled.span`
  margin-right: 12px;
  img:not(:first-child) {
    margin-left: -20px;
  }
  display: inline-block;
  transform: scale(1, 1);
`;

const Image = styled.img`
  width: 42px;
  height: 42px;
  border: 3px solid white;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  transform: scale(-1, 1);
`;

const EmptyImage = styled.div`
  width: 42px;
  height: 42px;
  border: 3px solid white;
  background-color: ${props => props.color};
  color: #ffffff;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  transform: scale(1, 1);
  margin-left: -20px;
  vertical-align: bottom;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding-top: 9px;
  font-weight: bold;
  cursor: default;
`;

const getImage = type => {
  switch (type) {
    case 'awesome':
      return awesome;
    case 'good':
      return good;
    case 'okay':
      return okay;
    case 'bad':
      return bad;
    case 'terrible':
    default:
      return terrible;
  }
};

const getColor = type => {
  switch (type) {
    case 'awesome':
      return '#52BA05';
    case 'good':
      return '#8ED04C';
    case 'okay':
      return '#BEB49E';
    case 'bad':
      return '#FF7F63';
    case 'terrible':
    default:
      return '#FE483C';
  }
};

const getImages = (number, type) => {
  const images = [];
  const moreThanFive = number > 5;
  for (let i = 0; i < (moreThanFive ? 5 : number); i++) {
    if (i === 4 && moreThanFive) {
      const leftUsers = number - 4;
      images.push(
        <EmptyImage key={i} color={getColor(type)}>
          +{leftUsers}
        </EmptyImage>
      );
    } else {
      images.push(<Image key={i} src={getImage(type)} alt={type} />);
    }
  }
  return images.length > 0 ? <Mood>{images.map(value => value)}</Mood> : '';
};

const OtherMetrics = ({ data }) => {
  const tooltip = 'Free-text responses from your team';
  const hasCurrent = data && Object.values(data.current).find(el => el > 0);
  const hasPrevious = data && Object.values(data.previous).find(el => el > 0);
  return (
    <Wrapper>
      <h4 css={hasCurrent || hasPrevious ? activeLabel : inactiveLabel}>
        Other
        {tooltip && <TooltipIcon data-tip={tooltip} />}
      </h4>
      {data && <div>{moods.map(value => getImages(data.current[value], value))}</div>}
    </Wrapper>
  );
};
export default OtherMetrics;
