import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Sidebar from '../partials/Sidebar';
import { HEADERS } from '../../../helpers';
import { APP_URL } from '../../../constants';
import { USER_ACTIONS } from '../../../redux/actions/userActions';
import AssignLeadModal from '../../spaces/AssignLeadModal';

const TeamsActions = ({ history, selected, teams, updateFlash, reloadData, user }) => {
  const [assignLeadModalToggled, setAssignLeadModalToggled] = useState(false);

  const deleteSpace = async team => {
    const headers = HEADERS();
    const response = await fetch(`${APP_URL}/team_with_users/${team.id}`, {
      method: 'delete',
      headers,
    });
    const json = await response.json();
    reloadData();
    updateFlash(json.flashName, json.message);
  };

  const handleDelete = () => {
    const team = teams.find(team => team.id === selected.id);
    const membersCount = team.members_count;

    const confirmationMessage = `Are you sure you would like to delete this Team and all of its sub teams?${
      membersCount === 0
        ? ''
        : ` It has ${membersCount} active user${
            membersCount !== 1 ? 's' : ''
          } who will be moved to your user bucket and need to be re-assigned.`
    }`;

    if (window.confirm(confirmationMessage)) {
      deleteSpace(team);
    }
  };

  const handleEdit = () => {
    history.push(`/team/${selected.id}/edit`);
  };

  return (
    <>
      <Sidebar.Actions
        actions={[
          ...(selected && (selected.is_team_lead || user.isAccountAdmin)
            ? [
                {
                  label: 'Delete team',
                  onClick: handleDelete,
                },
                {
                  label: 'Edit team profile',
                  onClick: handleEdit,
                },
                {
                  label: 'Edit team lead',
                  onClick: () => setAssignLeadModalToggled(true),
                },
              ]
            : []),
        ]}
      />
      <AssignLeadModal
        showAssignLeadModal={assignLeadModalToggled}
        closeAssignLeadModalHandler={() => setAssignLeadModalToggled(false)}
        leads={selected.team_leads}
        assignTo={{ id: selected.id }}
        turnOnSpinner={() => {}}
        type="teams"
        dispatchAfterFetch={reloadData}
      />
    </>
  );
};

TeamsActions.propTypes = {
  teams: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  updateFlash: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  changeUsersCapacity: isReached => dispatch(USER_ACTIONS.changeUsersCapacity(isReached)),
});

const mapStateToProps = state => ({
  user: state.usersDomain.user,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamsActions));
