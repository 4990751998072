import React from 'react';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setShowActionModal } from '../../../redux/app/actions';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Paragraph = styled.p`
  font-weight: normal;
  font-size: 13px;
  color: #58575f;
  line-height: 150%;
  margin: 0;
`;

const SettingsLink = styled.p`
  font-weight: normal;
  font-size: 13px;
  color: #11a9ff;
  line-height: 150%;
  margin: 0;
  cursor: pointer;
`;

const NoCompanyValuesOption = ({ setShowActionModal, isAccountAdmin, valuesLength, history }) => {
  const linkClickedHandler = () => {
    setShowActionModal(false);
    history.push('/company-settings');
  };

  const isZeroValues = valuesLength === 0;
  return (
    <Wrapper>
      <Paragraph>Company values have {isZeroValues ? 'not' : ''} been set</Paragraph>
      {isAccountAdmin && (
        <SettingsLink onClick={() => linkClickedHandler()}>
          {isZeroValues ? 'Click here to fix this' : 'Click here to add more'}
        </SettingsLink>
      )}
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  valuesLength: state.tagsDomain.companyValues.length,
  isAccountAdmin: state.usersDomain.user.isAccountAdmin,
});

const mapDispatchToProps = dispatch => ({
  setShowActionModal: show => dispatch(setShowActionModal(show)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NoCompanyValuesOption));
