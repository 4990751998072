import React from 'react';
import PropTypes from 'prop-types';
import { AWS_S3_URL } from '../../../constants';

const CvvDetails = ({ image, title, body }) => (
  <div className="large-card-container col-xs-12 col-sm-6">
    <div className="large-card">
      <img src={`${AWS_S3_URL}public/images/cards/${image}.svg`} alt="" />
      <div className="large-card-title">{title}</div>
      <div className="large-card-body">{body}</div>
    </div>
  </div>
);

CvvDetails.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default CvvDetails;
