import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { POST_ACTIONS } from '../../redux/actions/postActions';
import { SPACE_ACTIONS } from '../../redux/actions/spaceActions';
import SpaceLogo from '../spaces/SpaceLogo';
import { APP_URL, AWS_S3_URL } from '../../constants';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';
import AssignLeadModal from '../spaces/AssignLeadModal';

class GuestViewPrivate extends Component {
  state = {
    showAssignLeadModal: false,
  };

  requestAccess(e, spaceId) {
    e.preventDefault();
    const url = `/spaces/request_access/${spaceId}`;
    const headers = HEADERS();
    fetch(APP_URL + url, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.props.resetPosts();
          this.props.fetchPosts();
          this.props.fetchSpaceDetails(spaceId);
        });
      });
  }

  assignLead(e, space) {
    e.preventDefault();
    this.setState({
      showAssignLeadModal: true,
      assignTo: space,
    });
  }

  closeAssignLeadModalHandler() {
    this.setState({
      showAssignLeadModal: false,
      loadedForSpace: null,
    });
  }

  turnOnSpinner(spaceId) {
    this.setState({
      loadedForSpace: spaceId,
    });
  }

  render() {
    const buttonValue =
      !this.props.team.is_visitor && this.props.team.not_confirmed
        ? 'Request sent'
        : 'Request access';
    return (
      <div className="guest-view-feed">
        <div className="guest-view-wrap">
          <div className="circle-team-logo">
            <SpaceLogo space={this.props.team} />
          </div>
          <div className="team-info">
            <h1>
              {console.log(this.props.team)}
              {this.props.team.name} &nbsp;
              <img src={`${AWS_S3_URL}public/images/public/glasses.png`} className="icon" alt="" />
            </h1>
            <p>
              This space is private. You can join this space and see its content after your request
              to join is approved.
            </p>
            {this.props.user.isAccountAdmin && this.props.team.team_leads.length === 0 ? (
              <input
                id="join-btn"
                type="button"
                name="join-space"
                className="btn btn-primary"
                value="Assign lead"
                onClick={e => this.assignLead(e, this.props.team)}
              />
            ) : (
              <input
                id="join-btn"
                type="button"
                name="join-space"
                className="btn btn-primary"
                value={buttonValue}
                disabled={!this.props.team.is_visitor && this.props.team.not_confirmed}
                onClick={e => this.requestAccess(e, this.props.team.id)}
              />
            )}
          </div>
        </div>
        <AssignLeadModal
          showAssignLeadModal={this.state.showAssignLeadModal}
          closeAssignLeadModalHandler={this.closeAssignLeadModalHandler.bind(this)}
          assignTo={this.state.assignTo}
          turnOnSpinner={this.turnOnSpinner.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  team: state.teamsDomain.team,
  user: state.usersDomain.user,
});

const mapDispatchToProps = dispatch => ({
  fetchPosts: () => dispatch(POST_ACTIONS.fetchPosts()),
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  fetchSpaceDetails: spaceId => dispatch(SPACE_ACTIONS.fetchSpaceDetails(spaceId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GuestViewPrivate));
