import React, { Component } from 'react';
import thumbsup from '../../../../../images/insights/thumbsup.svg';
import thumbsupActive from '../../../../../images/insights/thumbsup-active.svg';
import thumbsdown from '../../../../../images/insights/thumbsdown.svg';
import thumbsdownActive from '../../../../../images/insights/thumbsdown-active.svg';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../../../../helpers';
import { APP_URL } from '../../../../../constants';

class SingleRecommendation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recommendation: {},
    };
  }

  componentDidMount() {
    this.setState({ recommendation: this.props.recommendation });
  }

  isUseful(isUseful) {
    const headers = HEADERS();
    const data = new FormData();
    data.append('useful', isUseful);
    fetch(`${APP_URL}/recommendation/${this.state.recommendation.id}/useful`, {
      body: data,
      method: 'post',
      headers,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          if (jsonResponse) {
            this.setState({
              recommendation: {
                ...this.state.recommendation,
                useful: jsonResponse.useful,
              },
            });
          }
        });
      });
  }

  completeRecommendation() {
    const headers = HEADERS();
    fetch(`${APP_URL}/recommendation/${this.state.recommendation.id}/complete`, {
      method: 'post',
      headers,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          if (jsonResponse) {
            this.setState({
              recommendation: { ...this.state.recommendation, completed: jsonResponse.completed },
            });
            this.props.updatedCompleted(this.state.recommendation.id, jsonResponse.completed);
          }
          this.props.hideSelect();
        });
      });
  }

  render() {
    return (
      <>
        <tr className="mobile single-recommendation hidden-md hidden-lg">
          <td>
            <div
              className="round recommendation-checkbox"
              onClick={() => this.completeRecommendation()}
            >
              <input
                type="checkbox"
                id={`recommendation-checkbox-${this.state.recommendation.id}`}
                checked={this.state.recommendation.completed || ''}
                readOnly
              />
              <label htmlFor="checkbox" />
            </div>
          </td>
          <td
            className={`recommendation-content mobile ${
              this.state.recommendation.completed && 'completed'
            }`}
          >
            {this.state.recommendation.content}
            <div className="mobile-category">{this.state.recommendation.category}</div>
            <div className="mobile-actions">
              <span>USEFUL?</span>
              <img
                alt="useful"
                onClick={() => this.isUseful(1)}
                src={
                  this.state.recommendation.useful !== '' &&
                  this.state.recommendation.useful === true
                    ? thumbsupActive
                    : thumbsup
                }
              />
              <img
                alt="useless"
                onClick={() => this.isUseful(0)}
                src={
                  this.state.recommendation.useful !== null && !this.state.recommendation.useful
                    ? thumbsdownActive
                    : thumbsdown
                }
              />
            </div>
          </td>
        </tr>
        <tr className="single-recommendation hidden-xs hidden-sm">
          <td>
            <div
              className="round recommendation-checkbox"
              onClick={() => this.completeRecommendation()}
            >
              <input
                type="checkbox"
                id={`recommendation-checkbox-${this.state.recommendation.id}`}
                checked={this.state.recommendation.completed || ''}
                readOnly
              />
              <label htmlFor="checkbox" />
            </div>
          </td>
          <td
            className={`recommendation-content ${
              this.state.recommendation.completed && 'completed'
            }`}
          >
            {this.state.recommendation.content}
          </td>
          <td className="recommendation-category">{this.state.recommendation.category}</td>
          <td className="recommendation-actions">
            <img
              alt="useful"
              onClick={() => this.isUseful(1)}
              src={
                this.state.recommendation.useful !== '' && this.state.recommendation.useful === true
                  ? thumbsupActive
                  : thumbsup
              }
            />
            <img
              alt="useless"
              onClick={() => this.isUseful(0)}
              src={
                this.state.recommendation.useful !== null && !this.state.recommendation.useful
                  ? thumbsdownActive
                  : thumbsdown
              }
            />
          </td>
        </tr>
      </>
    );
  }
}

export default SingleRecommendation;
