/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { isSampleUser } from 'helpers';
import { toggleUserModal } from 'redux/app/actions';
import { connect } from 'react-redux';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import qs from 'qs';

const AuthorInfo = ({ post, user, toggleUserModal, ...props }) => {
  const rolePosition = [];
  if (post.user.role !== '') {
    rolePosition.push(post.user.role);
  }
  rolePosition.push(post.user.team.name);

  return (
    <div className="author-info">
      {post.user.id > 0 ? (
        <Link
          to=""
          onClick={e => {
            e.preventDefault();
            if (isSampleUser(post.user.id)) return;
            if (post.user.id === user.id) {
              props.history.push(`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`);
            } else {
              return toggleUserModal(true, post.user.id);
            }
          }}
        >
          {`${post.user.first_name} ${post.user.last_name}`}&nbsp;
        </Link>
      ) : (
        `${post.user.first_name} ${post.user.last_name}`
      )}
      <span className="author-role-position">{rolePosition.join(', ')}</span>
    </div>
  );
};
const mapDispatchToProps = dispatch => ({
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
});

export default withRouter(connect(null, mapDispatchToProps)(AuthorInfo));
