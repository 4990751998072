import {
  ADMIN_ROLE,
  APP_URL,
  COMPANY_OWNER_ROLE,
  LOGIN_TIMEOUT_MILISECONDS,
  TEAM_MANAGER_ROLE,
  TEAM_MEMBER_ROLE,
} from 'constants.js';
import { LOCALSTORAGE_GET, SIGN_OUT } from 'helpers';

export default async function loginUser(jsonResponse, onFailure, onSuccess = () => null) {
  localStorage.setItem('access_token', jsonResponse.token);
  localStorage.setItem('is_authenticated', true);

  localStorage.setItem(
    'token_expires_at',
    new Date(new Date().getTime() + LOGIN_TIMEOUT_MILISECONDS)
  );
  sessionStorage.setItem(
    'token_expires_at',
    new Date(new Date().getTime() + LOGIN_TIMEOUT_MILISECONDS)
  );

  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('authorization', `Bearer ${LOCALSTORAGE_GET('access_token')}`);

  return fetch(`${APP_URL}/user_basic_data`, { headers })
    .then(response => {
      if (response.status === 200 && response.ok === true) {
        return response.json().then(jsonResponse => {
          localStorage.setItem('is_authenticated', true);
          localStorage.setItem('user_first_name', jsonResponse.first_name);
          localStorage.setItem('user_last_name', jsonResponse.last_name);
          localStorage.setItem('user_image_uid', jsonResponse.image_uid);
          localStorage.setItem('user_id', jsonResponse.id);
          localStorage.setItem('user_email', jsonResponse.email);
          localStorage.setItem('user_created_at', jsonResponse.created_at);
          localStorage.setItem('user_type', jsonResponse.type);
          localStorage.setItem('account_id', jsonResponse.account_id);
          localStorage.setItem('account_name', jsonResponse.account_name);
          localStorage.setItem('upgrade_account', jsonResponse.upgrade_account);

          jsonResponse.roles.forEach(role => {
            switch (role.name) {
              case ADMIN_ROLE:
                localStorage.setItem('is_admin', true);
                break;
              case COMPANY_OWNER_ROLE:
                localStorage.setItem('is_company_owner', true);
                break;
              case TEAM_MANAGER_ROLE:
                localStorage.setItem('is_team_manager', true);
                break;
              case TEAM_MEMBER_ROLE:
                localStorage.setItem('is_team_member', true);
                break;
              default:
            }
          });
          onSuccess();
        });
      }
      response.json().then(json => {
        sessionStorage.clear();
        localStorage.clear();
        localStorage.setItem('is_authenticated', false);
        let msg;
        if (response.status === 403) {
          msg = json.errors ?? json.error;
        } else {
          msg = json.error && json.message;
        }
        onFailure({ error: msg ?? 'Authentication failed' });
      });
    })
    .catch(error => {
      SIGN_OUT();
      onFailure({ error: 'Authentication failed' });
    });
}
