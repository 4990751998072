import React, { Component } from 'react';
import PropTypes from 'prop-types';
import unmountOnOuterClick from '../../HOC/unmountOnOuterClick';

class TeamTileDropdown extends Component {
  render = () => (
    <div className="team-tile-dropdown" onClick={e => e.stopPropagation()}>
      <div onClick={this.props.handleRename}>Rename</div>
      <div onClick={this.props.handleDelete}>Delete</div>
    </div>
  );
}

TeamTileDropdown.propTypes = {
  handleRename: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default unmountOnOuterClick(TeamTileDropdown);
