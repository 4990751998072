import React from 'react';
import { Link } from 'react-router-dom';
import { AWS_S3_URL } from '../../constants';

const HeelixLogo = () => (
  <div className="heelix-logo">
    <Link to="/">
      <img src={`${AWS_S3_URL}public/images/Heelix-Logo.svg`} alt="" />
    </Link>
  </div>
);

export default HeelixLogo;
