import React from 'react';
import PropTypes from 'prop-types';
import {
  ShoutoutsQuantity,
  UserData,
  UserIcon,
  UserName,
  UserPosition,
  UserWrapper,
} from 'pages/Grow/Champions/Champions.styles';
import styled from '@emotion/styled';

const UserCompanyValueData = ({ name, role, teamName, count, imageUrl }) => {
  return (
    <UserWrapper>
      <UserIcon src={imageUrl} />
      <UserData>
        <UserName>
          {name}&nbsp;{count > 1 && <ShoutoutsQuantity>{count}</ShoutoutsQuantity>}
        </UserName>
        <UserPosition>{role ? `${role}, ${teamName}` : `${teamName}`}</UserPosition>
      </UserData>
    </UserWrapper>
  );
};

UserCompanyValueData.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  count: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default UserCompanyValueData;
