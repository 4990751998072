import React from 'react';
import styled from '@emotion/styled';
import { Wrapper } from './NoteLayout';
import MyNote from './GrowPost/MyNote';
import ProgressBoardModal from './ProgressBoard/ProgressBoardModal';
import { ReactComponent as BackButton } from './GrowPost/assets/back.svg';

const CloseModalButton = styled.div`
  top: -14px;
  right: 8px;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  cursor: pointer;
  transform: translate(50%, -50%);
  &:before,
  &:after {
    display: block;
    content: '';
    height: 3px;
    width: 18px;
    position: absolute;
    left: 50%;
    top: 50%;
    background: #d5d5d5;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  @media (max-width: 480px) {
    display: none;
  }
`;

const BackModalButton = styled.div`
  display: block;
  @media (min-width: 480px) {
    display: none;
  }
`;

const BackButtonStyled = styled(BackButton)`
  margin: 18px 25px;
`;

const GrowPostModal = ({
  showNote,
  showActionModal,
  closeNoteModal,
  post,
  growPostId,
  growPostType,
}) => {
  return (
    <ProgressBoardModal showModal={showNote && !showActionModal} hideModal={closeNoteModal}>
      {post && (
        <Wrapper style={{ marginBottom: 0, border: 'none' }}>
          <CloseModalButton onClick={closeNoteModal} />
          <BackModalButton onClick={closeNoteModal}>
            <BackButtonStyled />
          </BackModalButton>
          <MyNote
            note={post}
            type={growPostType}
            postId={growPostId}
            exportModeOn={false}
            isPostSelected={false}
            addToProgressBoardEnabled={false}
            progressStatus={post.progress_status}
            preparationNote={post.preparationNote}
          />
        </Wrapper>
      )}
    </ProgressBoardModal>
  );
};

export default GrowPostModal;
