import DOMAINS from '../domains';

export const COMMENT_ACTION_TYPES = {
  // --Used by lower level (not root) reducer
  ADD_COMMENT: 'ADD_COMMENT',
  APPEND_ADDED_COMMENT: 'APPEND_ADDED_COMMENT',
  DELETE_COMMENT: 'DELETE_COMMENT',
  REMOVE_DELETED_COMMENT: 'REMOVE_DELETED_COMMENT',

  FETCH_COMMENTS: 'FETCH_COMMENTS',
  RECEIVE_COMMENTS: 'RECEIVE_COMMENTS',
  UPDATE_COMMENT: 'UPDATE_COMMENT',
  REFRESH_UPDATED_COMMENT: 'REFRESH_UPDATED_COMMENT',
  REFRESH_GROW_COMMENT: 'REFRESH_GROW_COMMENT',
};

export const COMMENT_ACTIONS = {
  // --Used mostly in React components during dispatch
  addComment: (postId, data, type = 'post', commentAddedHandler = null) => ({
    domain: DOMAINS.COMMENTS,
    type: COMMENT_ACTION_TYPES.ADD_COMMENT,
    payload: { postId, data, type, commentAddedHandler },
  }),
  appendAddedComment: (postId, json, type = 'post', commentAddedHandler = null) => ({
    domain: DOMAINS.COMMENTS,
    type: COMMENT_ACTION_TYPES.APPEND_ADDED_COMMENT,
    payload: { postId, json, type, commentAddedHandler },
  }),
  deleteComment: (postId, commentId, type) => ({
    domain: DOMAINS.COMMENTS,
    type: COMMENT_ACTION_TYPES.DELETE_COMMENT,
    payload: { postId, commentId, type },
  }),
  removeDeletedComment: (postId, commentId, type) => ({
    domain: DOMAINS.COMMENTS,
    type: COMMENT_ACTION_TYPES.REMOVE_DELETED_COMMENT,
    payload: { postId, commentId, type },
  }),

  fetchComments: (postId, loadedCount, singlePost = false, type = 'post') => ({
    domain: DOMAINS.COMMENTS,
    type: COMMENT_ACTION_TYPES.FETCH_COMMENTS,
    payload: { postId, loadedCount, type, singlePost },
  }),
  receiveComments: (postId, json, singlePost = false, type = 'post') => ({
    domain: DOMAINS.COMMENTS,
    type: COMMENT_ACTION_TYPES.RECEIVE_COMMENTS,
    payload: { postId, json, type, singlePost },
  }),

  updateComment: (postId, commentId, data, grow = false, receiveComment = null) => ({
    domain: DOMAINS.COMMENTS,
    type: COMMENT_ACTION_TYPES.UPDATE_COMMENT,
    payload: { postId, commentId, data, grow, receiveComment },
  }),
  refreshUpdatedComment: json => ({
    domain: DOMAINS.COMMENTS,
    type: COMMENT_ACTION_TYPES.REFRESH_UPDATED_COMMENT,
    payload: { json },
  }),
  refreshGrowComment: json => ({
    domain: DOMAINS.COMMENTS,
    type: COMMENT_ACTION_TYPES.REFRESH_GROW_COMMENT,
    payload: { json },
  }),
};
