import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { APP_URL, AWS_S3_URL } from '../../constants';
import { CHECK_UNAUTHORIZED, HEADERS, LOCALSTORAGE_BOOL, LOCALSTORAGE_GET } from '../../helpers';

class IntroModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  closePopup(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({ display: false });
  }

  getParameterByName(name, url) {
    if (!url) url = document.location;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  componentDidMount() {
    let accessToken = '';
    if (this.getParameterByName('uta')) {
      accessToken = this.getParameterByName('uta');
    } else if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    const headers = HEADERS(accessToken);
    fetch(`${APP_URL}/vibes/first_vibe`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          if (
            jsonResponse.first_vibe &&
            localStorage != null &&
            LOCALSTORAGE_BOOL('onboarding_flow')
          ) {
            this.setState({ display: true });
          } else {
            this.setState({ display: false });
          }
        });
      })
      .catch(() => {});
  }

  render() {
    return (
      <Modal
        className="intro-modal js-intro-modal"
        show={this.state.display}
        onHide={e => this.closePopup(e)}
      >
        <Modal.Header className="no-border centered">
          <button
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={e => this.closePopup(e)}
          >
            <i className="icon fa fa-close" onClick={() => this.closePopup(null)} />
          </button>
          <Modal.Title>
            <img
              src={`${AWS_S3_URL}public/images/jv-logo-rings.png`}
              alt="logo"
              className="u-margin-right-xxs u-height-md"
            />
            What's the Vibe?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="centered modal-body-no-padding">
          <p className="u-padding-horizontal-md">
            Vibing is your opportunity to share quick feedback with your team. Doing this every week
            keeps you up-to-date on how the team’s tracking and helps everyone improve together.
          </p>
          <p className="u-padding-horizontal-md u-margin-vertical-sm" />
          <Link
            to=""
            className="btn btn-primary btn-lg u-margin-bottom-md"
            data-dismiss="modal"
            aria-label="Close"
            onClick={e => this.closePopup(e)}
          >
            Let's Vibe
          </Link>
          <div className="intro-modal-footer u-padding-vertical-sm">
            <h3 className="u-margin-top-none"> How does it work?</h3>
            <div className="u-padding-horizontal-sm u-margin-top-xs text-left u-size0">
              <p>
                &bull; Your answer to the question <strong>How's work going?</strong> is anonymous.
                <br /> &bull; The factor you select in response to the question of how you feel is
                anonymous.
                <br />
                &bull; You can vibe at any time, but if you don’t get around to it, we'll send you a
                reminder during the week.
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default IntroModal;
