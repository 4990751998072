import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const TeamTagsWrapper = styled.div`
  display: inline-block;
  @media (min-width: 768px) {
    display: none;
  }
  @media (min-width: 1400px) {
    display: inline-block;
  }
`;

const TeamTag = styled.span`
  font-size: 10px;
  color: #11a9ff;
  padding: 3px 8px;
  background: #d3efff;
  border-radius: 2px;
  margin-right: 8px;
  font-weight: normal;
`;

const TeamTags = ({ tags }) => {
  if (!Boolean(tags.length)) {
    return null;
  }
  tags.sort((t1, t2) => {
    if (t1.name.length > t2.name.length) {
      return 1;
    } else if (t2.name.length > t1.name.length) {
      return -1;
    } else {
      return 0;
    }
  });

  const tagsLength = tags.length;
  let displayMore = tagsLength > 2;

  if (tagsLength >= 2) {
    if (tags[0].name.length + tags[1].name.length > 45) {
      tags = tags.slice(0, 1);
      displayMore = true;
    } else {
      tags = tags.slice(0, 2);
    }
  }

  const teamTags = tags.map(tag => <TeamTag>{tag.name}</TeamTag>);
  if (displayMore) {
    teamTags.push(<TeamTag>+{tagsLength - tags.length}</TeamTag>);
  }
  return <TeamTagsWrapper>{teamTags}</TeamTagsWrapper>;
};

TeamTags.defaultProps = {
  tags: [],
};

TeamTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default TeamTags;
