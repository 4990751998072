export const noteTypes = {
  win: 'Win',
  want: 'IWantTo',
  private: 'Private',
  freeText: 'FreeText',
  goal: 'Goal',
  meeting: 'Meeting',
  quickMeeting: 'QuickMeeting',
  meetingSchedule: 'MeetingSchedule',
  meetingOneOff: 'MeetingOneOff',
  meetingPreparation: 'MeetingPreparation',
  managerPrompt: 'ManagerPrompt',
  managerPromptPreparation: 'ManagerPromptPreparation',
  managerAlertImprovement: 'ManagerAlertImprovement',
  managerAlertDeterioration: 'ManagerAlertDeterioration',
  managerAlertSuccess: 'ManagerAlertSuccess',
  managerAlertClosure: 'ManagerAlertClosure',
  meetingStep: 'MeetingStep',
};
