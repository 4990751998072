import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import VibeDoughnut from '../../vibes/VibeDoughnut';
import CustomTooltip from '../../shared/CustomTooltip';

class VibeDoughnutWrap extends Component {
  state = {
    showTooltip: false,
  };

  render() {
    const { value, trend, id, enoughMembers } = this.props;
    return enoughMembers ? (
      <Link
        to={{
          pathname: '/vibes/dashboard',
          search: `team=${id}&period=30`,
        }}
      >
        <VibeDoughnut
          score={value}
          trend={trend}
          text={value}
          primary_color={value > 0 ? '#11A9FF' : '#FC7355'}
          width={35}
          height={35}
          cutout={75}
        />
      </Link>
    ) : (
      <span
        onMouseEnter={() => this.setState({ showTooltip: true })}
        onMouseLeave={() => this.setState({ showTooltip: false })}
      >
        <VibeDoughnut score={null} text="?" trend={null} width={35} height={35} cutout={75} />
        <CustomTooltip isMounted={this.state.showTooltip}>
          To remain anonymous, Spaces need at least three members to show the vibe score.
        </CustomTooltip>
      </span>
    );
  }
}

VibeDoughnutWrap.propTypes = {
  value: PropTypes.number,
  trend: PropTypes.number,
  id: PropTypes.number,
};

export default VibeDoughnutWrap;
