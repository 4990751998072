/** @jsx jsx */
import PlanModal from 'rbac/components/PlanModal/PlanModal';
import { css, jsx } from '@emotion/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

export const grayFilterCss = css`
  opacity: 0.6;
  filter: grayscale(1);
  pointer-events: none;
`;

const UpgradeModal = ({ isAccountAdmin, history }) => {
  const [show, setShow] = useState(true);

  return !show ? null : (
    <div
      css={css`
        position: absolute;
        z-index: 1;
        width: 100%;
      `}
    >
      <PlanModal
        css={css`
          max-width: 500px;
          position: relative;
          margin: 200px auto;
        `}
      >
        <PlanModal.TopBar />
        <PlanModal.CloseButton onClick={() => setShow(false)} />
        <PlanModal.Content>
          <PlanModal.Title>
            <PlanModal.Crown
              css={css`
                width: 24px;
                height: 24px;
                margin-right: 12px;
              `}
            />
            Detailed analysis available on paid plans only
          </PlanModal.Title>
          <div
            css={css`
              margin-top: 13px;
              margin-bottom: 18px;
            `}
          >
            {isAccountAdmin
              ? 'Keen on learning more about your team? Upgrade to enjoy the full functionality of Heelix including dashboards, performance tools and more'
              : 'Keen on learning more about your team? Chat with your account owner about upgrading your plan to access detailed insights, personal and team performance tools and more'}
          </div>
          {isAccountAdmin && (
            <PlanModal.Button onClick={() => history.push('/payments/choose_plan')}>
              UPGRADE
            </PlanModal.Button>
          )}
        </PlanModal.Content>
      </PlanModal>
    </div>
  );
};

const mapState = state => ({
  isAccountAdmin: state.usersDomain.user.isAccountAdmin,
});

export default connect(mapState)(withRouter(UpgradeModal));
