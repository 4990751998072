import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getStringFromNumber } from 'helpers';
import { USER_ACTIONS } from '../../redux/actions/userActions';

class FreePlanWarningMessage extends Component {
  getFlashMessage(message) {
    if (message.type === 'ended') {
      return (
        'You have reached the end of your free trial and are now on Heelix Connect (our free version). ' +
        'To keep using our perform and engage features, you can upgrade '
      );
    }
    return (
      `You have ${getStringFromNumber(message.days_left)} (${message.days_left}) ` +
      `${message.days_left === 1 ? 'day' : 'days'} left on your free ` +
      `trial after which time you will move to Heelix Connect (our free version). To keep using our perform and engage features, you can upgrade `
    );
  }

  closeWarningMessage(message) {
    if (message.type === 'ended') {
      this.props.closeFreePlanWarningMessage();
    }
  }

  render() {
    const image = (
      <svg
        width="23"
        height="19"
        viewBox="0 0 23 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8284 0.698169C11.2193 0.0664309 12.1382 0.0664303 12.5291 0.698169L22.4665 16.7576C22.8788 17.4238 22.3996 18.2838 21.6162 18.2838H1.74133C0.957905 18.2838 0.478733 17.4238 0.890969 16.7576L10.8284 0.698169ZM12.3688 7.16943C12.3688 6.80836 12.0599 6.51564 11.6787 6.51564C11.2976 6.51564 10.9886 6.80836 10.9886 7.16943V11.746C10.9886 12.107 11.2976 12.3997 11.6787 12.3997C12.0599 12.3997 12.3688 12.107 12.3688 11.746V7.16943ZM11.6787 13.7073C12.0599 13.7073 12.3688 14 12.3688 14.3611C12.3688 14.7222 12.0599 15.0149 11.6787 15.0149C11.2976 15.0149 10.9886 14.7222 10.9886 14.3611C10.9886 14 11.2976 13.7073 11.6787 13.7073Z"
          fill="#FFBC57"
        />
      </svg>
    );
    const message = this.props.freePlanWarningMessage;
    return message ? (
      <div className="flash error">
        {image}
        <div>
          {this.getFlashMessage(message)}
          <a
            style={{ color: 'red', textDecoration: 'underline' }}
            href="/payments/choose_plan?from_flash"
          >
            here
          </a>{' '}
          or to chat with one of our team, click{' '}
          <a
            style={{ color: 'red', textDecoration: 'underline' }}
            href="https://calendly.com/heelix/call-with-heelix"
          >
            here
          </a>
          .
        </div>
        {message.type === 'ended' && (
          <div className="dismiss-cross" onClick={() => this.closeWarningMessage(message)} />
        )}
      </div>
    ) : (
      <div />
    );
  }
}

const mapStateToProps = state => ({
  freePlanWarningMessage: !!state.usersDomain.user && state.usersDomain.user.freePlanWarningMessage,
});

const mapDispatchToProps = dispatch => ({
  closeFreePlanWarningMessage: () => dispatch(USER_ACTIONS.closeFreePlanWarningMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreePlanWarningMessage);
