import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

class InvoicesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      amountDue: 0,
      code: '',
      startDate: null,
      endDate: null,
      paymentDate: null,
      dateDue: null,
      selectedAccount: '',
      redirect: false,
      sent: false,
      paid: false,
      error: '',
    };
  }

  componentDidMount() {
    this.setState({ accounts: this.props.accounts });
    if (this.props.invoice) {
      const inv = this.props.invoice;
      this.setState({
        startDate: inv.date_start ? inv.date_start : null,
        endDate: inv.date_end ? inv.date_end : null,
        paymentDate: inv.payment_date ? inv.payment_date : null,
        dateDue: inv.date_due ? inv.date_due : null,
        selectedAccount: inv.account_id,
        amountDue: inv.amount_due,
        code: inv.code,
        sent: inv.sent,
        paid: inv.paid,
      });
    }
  }

  appendState(data, name, tableName) {
    if (this.state[name] !== null) {
      data.append(`offline_invoice[${tableName}]`, this.state[name]);
    }
  }

  sendForm(e) {
    e.preventDefault();
    if (this.state.amountDue === (null || '')) {
      this.setState({ error: "can't be blank" });
    } else {
      const data = new FormData();
      this.appendState(data, 'startDate', 'date_start');
      this.appendState(data, 'endDate', 'date_end');
      this.appendState(data, 'paymentDate', 'payment_date');
      this.appendState(data, 'dateDue', 'date_due');
      this.appendState(data, 'amountDue', 'amount_due');
      this.appendState(data, 'code', 'code');
      this.appendState(data, 'sent', 'sent');
      this.appendState(data, 'paid', 'paid');
      const account = this.state.selectedAccount
        ? this.state.selectedAccount
        : this.state.accounts[0].id;
      data.append('offline_invoice[account_id]', account);
      this.props.sendForm(data);
    }
  }

  setDate(date, name) {
    this.setState({ [name]: date.format('YYYY-MM-DD') });
  }

  setParam(e, param) {
    if (param === 'amountDue') {
      if (e.target.value.length > 0) {
        this.setState({ error: '' });
      } else {
        this.setState({ error: "can't be blank" });
      }
    }
    this.setState({ [param]: e.target.value });
  }

  setAccount(e) {
    this.setState('selectedAccount', e.target.value);
  }

  changeCheckbox(name) {
    this.setState({ [name]: !this.state[name] });
  }

  render() {
    return (
      <form className="simple_form new_offline_invoice">
        <div className="form-group select optional offline_invoice_account_id">
          <label className="select optional control-label" htmlFor="offline_invoice_account_id">
            Account
          </label>
          <select
            className="select optional form-control"
            name="offline_invoice[account_id]"
            id="offline_invoice_account_id"
            onChange={e => this.setAccount(e)}
            value={this.state.selectedAccount}
          >
            {this.state.accounts.map(account => (
              <option key={account.id} value={account.id}>
                {account.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group string optional offline_invoice_code">
          <label className="string optional control-label" htmlFor="offline_invoice_code">
            Code
          </label>
          <input
            type="text"
            className="string optional form-control"
            name="offline_invoice[code]"
            id="offline_invoice_code"
            value={this.state.code}
            onChange={e => this.setParam(e, 'code')}
          />
        </div>
        <div className="form-group boolean optional offline_invoice_sent">
          <div className="checkbox">
            <input
              className="boolean optional"
              id="offline_invoice_sent"
              name="offline_invoice[sent]"
              type="checkbox"
              onChange={e => this.changeCheckbox('sent')}
              checked={this.state.sent}
              value={this.state.sent}
            />
            <label className="boolean optional" htmlFor="offline_invoice_sent">
              Sent
            </label>
          </div>
        </div>
        <div
          className={`form-group integer required offline_invoice_amount_due${
            this.state.error ? ' has-error' : ''
          }`}
        >
          <label className="integer required control-label" htmlFor="offline_invoice_code">
            <abbr title="required" />
            Amount due
          </label>
          <input
            type="number"
            className="numeric integer required form-control"
            name="offline_invoice[amount_due]"
            id="offline_invoice_amount_due"
            step="1"
            value={this.state.amountDue}
            onChange={e => this.setParam(e, 'amountDue')}
          />
          {this.state.error ? <span className="help-block">can't be blank</span> : ''}
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group date optional offline_invoice_date_start">
              <label
                className="date optional control-label"
                htmlFor="offline_invoice_date_start_1i"
              >
                Date start
              </label>
              <div className="input-group ">
                <DatePicker
                  dropdownMode="select"
                  className="form-control datepicker"
                  dateFormat="YYYY-MM-DD"
                  id="offline_invoice_date_start"
                  name="offline_invoice[date_start]"
                  selected={this.state.startDate !== null ? moment(this.state.startDate) : null}
                  onChange={e => this.setDate(e, 'startDate')}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group date optional offline_invoice_date_end">
              <label className="date optional control-label" htmlFor="offline_invoice_date_end_1i">
                Date end
              </label>
              <div className="input-group ">
                <DatePicker
                  dropdownMode="select"
                  className="form-control datepicker"
                  dateFormat="YYYY-MM-DD"
                  id="offline_invoice_date_end"
                  name="offline_invoice[date_end]"
                  selected={this.state.endDate !== null ? moment(this.state.endDate) : null}
                  onChange={e => this.setDate(e, 'endDate')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group date optional offline_invoice_date_due">
          <label className="date optional control-label" htmlFor="offline_invoice_date_due_1i">
            Date due
          </label>
          <div className="input-group ">
            <DatePicker
              dropdownMode="select"
              className="form-control datepicker"
              dateFormat="YYYY-MM-DD"
              id="offline_invoice_date_due"
              name="offline_invoice[date_due]"
              selected={this.state.dateDue !== null ? moment(this.state.dateDue) : null}
              onChange={e => this.setDate(e, 'dateDue')}
            />
          </div>
        </div>
        <div className="form-group boolean optional offline_invoice_paid">
          <div className="checkbox">
            <input
              className="boolean optional"
              id="offline_invoice_paid"
              name="offline_invoice[paid]"
              type="checkbox"
              onChange={e => this.changeCheckbox('paid')}
              checked={this.state.paid}
              value={this.state.paid}
            />
            <label className="boolean optional" htmlFor="offline_invoice_paid">
              Paid
            </label>
          </div>
        </div>
        <div className="form-group date optional offline_invoice_payment_date">
          <label className="date optional control-label" htmlFor="offline_invoice_payment_date_1i">
            Payment date
          </label>
          <div className="input-group ">
            <DatePicker
              dropdownMode="select"
              className="form-control datepicker"
              dateFormat="YYYY-MM-DD"
              id="offline_invoice_payment_date"
              name="offline_invoice[payment_date]"
              selected={this.state.paymentDate !== null ? moment(this.state.paymentDate) : null}
              onChange={e => this.setDate(e, 'paymentDate')}
            />
          </div>
        </div>
        <hr />
        <input
          className="btn btn-lg btn-fixed btn-wide btn-primary"
          name="commit"
          type="submit"
          value="save"
          onClick={e => this.sendForm(e)}
        />
      </form>
    );
  }
}

export default InvoicesForm;
