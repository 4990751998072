import generateGoogleToken from 'components/shared/fileUpload/generateGoogleToken';

const captureVideoFrame = require('capture-video-frame');

export default async function generateFileFormData(data, mediaUrl, files, oldMedia = []) {
  for (let i = 0; i < mediaUrl.length; i++) {
    if (mediaUrl[i].drive) {
      data.append('post_media_drive[]', JSON.stringify(mediaUrl[i]));
    } else {
      data.append('post_media[]', JSON.stringify(mediaUrl[i]));
    }
  }

  for (let i = 0; i < oldMedia.length; i++) {
    data.append('post_old_media[]', oldMedia[i].id);
  }

  for (let i = 0; i < files.length; i++) {
    if (files[i].drive) {
      data.append('post_files_drive[]', JSON.stringify(files[i]));
    } else {
      data.append('post_files[]', files[i]);
    }
  }

  if (
    (files.length > 0 && files.find(file => Boolean(file.drive))) ||
    (mediaUrl.length > 0 && mediaUrl.find(url => Boolean(url.drive)))
  ) {
    const token = await generateGoogleToken();
    token && data.append('google_token', token);
  }

  if (mediaUrl[0] && !mediaUrl[0].drive) {
    appendVideoThumbnail(mediaUrl, data);
  }

  return data;
}

/**
 *
 * @param {array} mediaUrl
 * @param {FormData} data
 * @param {string} [mediaFormat]
 */
export function appendVideoThumbnail(mediaUrl, data, mediaFormat = 'post_media') {
  if (mediaUrl[0] && mediaUrl[0].type === 'v') {
    const player = document.querySelector('.video-add-modal .video-react-video');
    player.crossOrigin = 'anonymous';
    const thumbnail = captureVideoFrame(player, 'png');
    data.append(
      `${mediaFormat}[]`,
      JSON.stringify({
        type: 'i',
        url:
          thumbnail && thumbnail.dataUri !== 'data:,'
            ? thumbnail.dataUri
            : 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/wAALCAFoAeABAREA/8QAFQABAQAAAAAAAAAAAAAAAAAAAAj/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/9oACAEBAAA/AJ/AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA' +
              'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA' +
              'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA' +
              'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA' +
              'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA' +
              'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA' +
              'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB//9k=',
      })
    );
  }
}
