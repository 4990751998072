import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import striptags from 'striptags';

import AppAlertsDropdown from '../../vibes/AppAlertsDropdown';

class FeedbackAlertTile extends Component {
  state = {
    showFullFeedback: 'hide-full-feedback',
  };

  showFullFeedback = () => {
    this.setState({
      showFullFeedback: 'show-full-feedback',
    });
    this.props.tileRealignHandler();
  };

  render() {
    const content = JSON.parse(this.props.post.content);
    const redirectUrl = `/vibes/dashboard?team=${this.props.post.direct_team.id}&period=-7`;

    return (
      <div className="col-lg-6 col-xs-12 post-tile-wrap initial-hide">
        <div className={`post-tile app-alert-tile ${this.state.showFullFeedback}`}>
          <div className="post-tile-header">
            <AppAlertsDropdown post={this.props.post} />
          </div>
          <div className="post-tile-body app-alert-body ">
            <h3 className="app-alert-header">Here's what you need to know this week:</h3>
            <div className={`feedback-wrapper ${this.state.showFullFeedback}`}>
              {content.map((feedback, index) => (
                <div className="app-alert-feedback">
                  <span className="feedback-counter">{index + 1}</span>
                  <span
                    className="feedback-paragraph"
                    dangerouslySetInnerHTML={{
                      __html: striptags(feedback, ['a', 'br']),
                    }}
                  />
                </div>
              ))}
              <div className="feedback-info">
                Check out the other feedback that's been received and some suggested actions by
                visiting your&nbsp;
                <Link to={redirectUrl}>insights page on web here</Link>.
              </div>
            </div>
            <svg
              className={this.state.showFullFeedback}
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100"
            >
              <defs>
                <linearGradient id="lgrad" x1="50%" y1="100%" x2="50%" y2="0%">
                  <stop offset="0%" stopColor="#fff" stopOpacity="1" />
                  <stop offset="21%" stopColor="#fff" />
                  <stop offset="100%" stopColor="#fff" stopOpacity="0.25" />
                </linearGradient>
              </defs>
              <rect width="100%" height="100%" fill="url(#lgrad)" />
            </svg>
            <div
              onClick={() => this.showFullFeedback()}
              className={`show-feedback-btn ${this.state.showFullFeedback}`}
            >
              Show more
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeedbackAlertTile;
