/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  Assistance,
  AssistanceDescription,
  AssistanceLetsTalk,
  CheckmarkList,
  ShortDescription,
} from 'components/payments/PaymentChoose/shared/layout';
import AssistanceSvg from 'components/payments/PaymentChoose/shared/Assistance.svg';
import plans from 'components/payments/PaymentChoose/plans';
import TableFeatureRow from 'components/payments/PaymentChoose/desktop/TableFeatureRow';
import PriceSection from 'components/payments/PaymentChoose/shared/PriceSection';
import planTypes from 'components/payments/PaymentChoose/planTypes';
import { css, jsx } from '@emotion/core';
import mapPlans from 'components/payments/PaymentChoose/shared/mapPlans';
import PlanButton from 'components/payments/PaymentChoose/shared/PlanButton';
import { connect } from 'react-redux';

const Container = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
    overflow: hidden;
  }
`;

const Table = styled.table`
  border-top: #dfebf0 1px solid;
  border-bottom: #dfebf0 1px solid;
  table-layout: fixed;
  th,
  td {
    width: 27.27%;
    &:first-child {
      width: 18.18%;
    }
  }
`;

export const Tr = styled.tr`
  border-top: #dfebf0 1px solid;
`;

const Th = styled.th`
  padding-top: 39px;
  font-weight: normal;
  vertical-align: top;
  border-left: #dfebf0 1px solid;

  &:first-child {
    vertical-align: middle;
    border-left: none;
  }
`;

export const Td = styled.td`
  padding: 17px 20px;
  vertical-align: top;
  border-right: #dfebf0 1px solid;

  &:last-child {
    border-right: none;
  }
`;

const AssistanceContent = styled.div`
  font-size: 14px;
  line-height: 150%;
  text-align: left;
  margin-left: 18px;
`;

const AssistanceStyled = styled(Assistance)`
  display: flex;
  img {
    max-width: 30px;
  }
`;

const PlanTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #11a9ff;
  margin-bottom: 20px;
`;

const DesktopPlanSelector = ({
  openContactModal,
  planSize,
  openDowngradeModal,
  currency,
  openUpgradeModal,
  currentPlanSize,
}) => {
  const { prices } = planTypes[planSize];

  return (
    <Container>
      <Table>
        <thead>
          <Tr>
            <Th>
              <AssistanceStyled>
                <img src={AssistanceSvg} alt="" />
                <AssistanceContent>
                  <AssistanceDescription>Need assistance?</AssistanceDescription>
                  <AssistanceLetsTalk onClick={openContactModal}>Let's talk!</AssistanceLetsTalk>
                </AssistanceContent>
              </AssistanceStyled>
            </Th>
            {mapPlans({ planSize, currentPlanSize }, ({ plan, disabled }) => (
              <Th
                key={plan.key}
                css={
                  disabled &&
                  css`
                    opacity: 0.2;
                    pointer-events: none;
                  `
                }
              >
                <PlanTitle>{plan.name instanceof Function ? plan.name() : plan.name}</PlanTitle>
                <ShortDescription>{plan.shortDescription}</ShortDescription>
                <PriceSection
                  price={plan.price ?? prices[plan.key][currency]}
                  isPriceString={Boolean(plan.priceString)}
                  description={plan.priceDescription}
                  image={prices[plan.key]?.image}
                  currency={currency}
                />
                <PlanButton
                  disabled={disabled}
                  planSize={planSize}
                  plan={plan.key}
                  openContactModal={openContactModal}
                  openDowngradeModal={openDowngradeModal}
                  openUpgradeModal={openUpgradeModal}
                />
                <CheckmarkList icon={plan.pointsIcon}>
                  {plan.points.map(point => (
                    <li>{point}</li>
                  ))}
                </CheckmarkList>
              </Th>
            ))}
          </Tr>
        </thead>
        <tbody>
          {plans[0].features.map((feature, index) => (
            <TableFeatureRow feature={feature} index={index} key={index} planSize={planSize} />
          ))}
          <Tr>
            <Td>
              <AssistanceStyled>
                <img src={AssistanceSvg} alt="" />
                <AssistanceContent>
                  <AssistanceDescription>Need assistance?</AssistanceDescription>
                  <AssistanceLetsTalk onClick={openContactModal}>Let's talk!</AssistanceLetsTalk>
                </AssistanceContent>
              </AssistanceStyled>
            </Td>
            {mapPlans({ planSize, currentPlanSize }, ({ plan, disabled }) => (
              <Td
                css={css`
                  padding-top: 0;
                  ${disabled &&
                  css`
                    opacity: 0.2;
                    pointer-events: none;
                  `}
                `}
                key={plan.key}
              >
                <PriceSection
                  price={plan.price ?? prices[plan.key][currency]}
                  isPriceString={Boolean(plan.priceString)}
                  description={plan.priceDescription}
                  image={prices[plan.key]?.image}
                  currency={currency}
                />
                <PlanButton
                  disabled={disabled}
                  planSize={planSize}
                  plan={plan.key}
                  openContactModal={openContactModal}
                  openDowngradeModal={openDowngradeModal}
                  openUpgradeModal={openUpgradeModal}
                />
              </Td>
            ))}
          </Tr>
        </tbody>
      </Table>
    </Container>
  );
};

DesktopPlanSelector.propTypes = {
  openContactModal: PropTypes.func.isRequired,
};

const mapState = state => ({
  currentPlanSize: state.plansDomain.planSize,
});

export default connect(mapState)(DesktopPlanSelector);
