import React from 'react';
import grow from '../grow_onboarding.png';
import insights from '../insights_onboarding.png';
import spaces from '../spaces_onboarding.png';

export const OnboardingUserData = [
  {
    title: 'Boost your performance',
    introduction:
      "It's all about helping you have a better conversations with your manager and the people you work with.",
    section: 'Grow',
    image: grow,
    points: [
      'Create goals with due dates to keep you on track',
      'Record wins as you make them, rather than forgetting all your good work',
      'Create notes about something you want to bring up at a future meeting / discussion',
      'Track your goals and notes on a dedicated Grow Board',
    ],
    final:
      "It’s all private to you. Just hit the + button to get started and head to 'My notes'. You can find Grow at the top of your left nav bar.",
  },
  {
    title: 'Stay connected',
    introduction:
      'Keep in touch with your team through a simple social collaboration tool focused on the stuff that matters.',
    section: 'Spaces',
    image: spaces,
    points: [
      'Share text photos or videos, great for celebrating your success and keeping the team informed of any key events or updates',
      'Share files rather than them getting lost or clogging up your email',
      'Give shoutouts to each other to recognise awesome work or support from teammates',
      'Discuss ways to improve your work; from polls to suggesting something you’d like to start / stop / continue doing',
    ],
    final:
      'We give you one Space for your company or you can create your own and invite specific teammates. To create content, hit the +.',
  },
  {
    title: 'Learn more about how you work together',
    introduction:
      "Find out how the team's feeling, what's driving them and the triggers that help or hinder the way they work.",
    section: 'Insights',
    image: insights,
    points: [
      'View a dashboard for each team you’re in to see your Vibe and your specific performance drivers. Toggle by date range and previous period to see how things have changed.',
      'Deep-dive into the data, from factor analysis to mood over time and mood vs factor',
      'Take action directly from your dashboard, from running a poll to suggesting something you’d like to start / stop / continue doing',
    ],
    final:
      'We’ll prompt you each Friday to record your anonymous feedback but you can do it anytime by hitting the + button and clicking the ‘Vibe’ option.',
  },
];
