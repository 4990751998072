import qs from 'qs';
import fetchApi from 'fetchApi';
import { POST_ACTION_TYPES, POST_ACTIONS } from '../actions/postActions';
import { TAG_ACTIONS } from '../actions/tagActions';
import { APP_URL } from '../../constants';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';
import store from '../store';

const postReducer = (state, action) => {
  const pageLength = 10;
  const headers = HEADERS();
  let postIndex;
  let newState;
  let growPostIndex;

  function handleErrors(response, payload) {
    if (!response.ok) {
      response.json().then(json => {
        payload.receiveErrors(json);
      });
      throw Error('Could not add post');
    }
    return response;
  }

  switch (action.type) {
    case POST_ACTION_TYPES.DELETE_POST:
      fetch(`${APP_URL}/post/${action.payload}`, {
        method: 'delete',
        headers,
        body: JSON.stringify(action.payload),
      })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(() => {
          store.dispatch(POST_ACTIONS.removeDeletedPost(action.payload));
          store.dispatch(TAG_ACTIONS.fetchHashtags());
        });
      return state;
    case POST_ACTION_TYPES.REMOVE_DELETED_POST:
      const newPosts = state.growDomain.posts.filter(obj =>
        !obj.post ? true : obj.post.id !== action.payload
      );
      const newPostsIds = newPosts.map(post => post.id);

      const postIdsToRemove = state.growDomain.posts
        .filter(post => !newPostsIds.includes(post.id))
        .map(post => post.id);

      const newDates = state.growDomain.dates
        .map(date => {
          const newPostIds = date.post_ids.filter(
            postIdInDate => !postIdsToRemove.includes(postIdInDate)
          );
          return { ...date, post_ids: newPostIds };
        })
        .filter(date => date.post_ids.length);

      return {
        ...state,
        postsDomain: {
          ...state.postsDomain,
          posts: state.postsDomain.posts.filter(obj => obj.id !== action.payload),
          loadedPostsCount: state.postsDomain.loadedPostsCount - 1,
          singlePost: null,
        },
        growDomain: {
          ...state.growDomain,
          posts: newPosts,
          dates: newDates,
          loadedPostsCount: state.growDomain.loadedPostsCount - 1,
        },
      };
    case POST_ACTION_TYPES.ADD_POST:
      fetch(`${APP_URL}/post`, { method: 'post', headers, body: action.payload.data })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          handleErrors(response, action.payload);
        })
        .then(() => {
          store.dispatch(TAG_ACTIONS.fetchHashtags());
          store.dispatch(TAG_ACTIONS.fetchDiscussionTags());
          action.payload.receiveErrors();
        })
        .catch(error => {
          action.payload.generalError(error.message);
        });
      return state;
    case POST_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        postsDomain: {
          ...state.postsDomain,
          fetchingPosts: action.payload,
          hasMorePostsToLoad: action.payload,
        },
      };
    case POST_ACTION_TYPES.FETCH_MANY:
      const queryString = qs.stringify({
        loaded: state.postsDomain.loadedPostsCount,
        ...state.postsDomain.filters,
      });
      (async () => {
        const response = await fetchApi(`/post?${queryString}`, { json: false });
        const json = await response.json();
        json.timestamp = response.timestamp;
        await store.dispatch(POST_ACTIONS.receivePosts(json));
        store.dispatch(POST_ACTIONS.fetchOpenQuestionnaires());
      })();
      return {
        ...state,
        postsDomain: {
          ...state.postsDomain,
          fetchingPosts: true,
        },
      };
    case POST_ACTION_TYPES.FETCH_QUESTIONNAIRES:
      const query = qs.stringify({
        loaded: action.payload,
        ...state.postsDomain.filters,
      });
      (async () => {
        const response = await fetchApi(`/questionnaires?${query}`, { json: false });
        const json = await response.json();
        json.timestamp = response.timestamp;
        await store.dispatch(POST_ACTIONS.receivePosts(json));
        store.dispatch(POST_ACTIONS.fetchOpenQuestionnaires(true));
      })();
      return {
        ...state,
        postsDomain: {
          ...state.postsDomain,
          fetchingPosts: true,
        },
      };
    case POST_ACTION_TYPES.RECEIVE_MANY:
      if (action.payload.timestamp < state.postsDomain.timestamp) {
        return state;
      }
      if (action.payload.loaded === '0') {
        return {
          ...state,
          postsDomain: {
            ...state.postsDomain,
            timestamp: action.payload.timestamp,
            posts: action.payload.posts,
            fetchingPosts: false,
            loadedPostsCount: pageLength, // + action.payload.length was replaced by pageLength to avoid loading the post doubled by update over and over again
            hasMorePostsToLoad: action.payload.posts.length >= pageLength,
            postErrors: null,
            teamVibe: action.payload.teamVibe,
          },
          tagsDomain: {
            ...state.tagsDomain,
            feedTags: action.payload.tags,
          },
        };
      }
      const ids = state.postsDomain.posts.map(post => post.id);
      const filteredPosts = action.payload.posts.filter(post => ids.indexOf(post.id) < 0);
      return {
        ...state,
        postsDomain: {
          ...state.postsDomain,
          timestamp: action.payload.timestamp,
          posts: [...state.postsDomain.posts, ...filteredPosts],
          fetchingPosts: false,
          loadedPostsCount: state.postsDomain.loadedPostsCount + pageLength, // + action.payload.length was replaced by pageLength to avoid loading the post doubled by update over and over again
          hasMorePostsToLoad: action.payload.posts.length >= pageLength,
          postErrors: null,
          teamVibe: action.payload.teamVibe,
        },
      };
    case POST_ACTION_TYPES.RESET_MANY:
      return {
        ...state,
        postsDomain: {
          ...state.postsDomain,
          posts: [],
          loadedPostsCount: 0,
          hasMorePostsToLoad: true,
          postErrors: null,
          vibe: null,
        },
        usersDomain: {
          ...state.usersDomain,
          spaceMembers: [],
          user: {
            ...state.usersDomain.user,
            should_see_content: true,
            should_see_sample_data: false,
          },
        },
        spacesDomain: {
          ...state.spacesDomain,
          editedSpaceMembers: [],
          editedSpaceId: null,
        },
        teamsDomain: {
          ...state.teamsDomain,
          team: {
            thumbnail_details: [],
            hashtags: [],
          },
        },
        tagsDomain: {
          ...state.tagsDomain,
          feedTags: [],
        },
      };
    case POST_ACTION_TYPES.FETCH_SINGLE:
      fetch(`${APP_URL}/post/${action.payload.id}`, { method: 'get', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          if (response.status === 200) {
            response.json().then(jsonResponse => {
              store.dispatch(POST_ACTIONS.receivePost(jsonResponse));
            });
          } else action.payload.failureCallback();
        });
      return state;
    case POST_ACTION_TYPES.RECEIVE_SINGLE:
      return {
        ...state,
        postsDomain: {
          ...state.postsDomain,
          singlePost: action.payload,
          filters: {
            ...state.postsDomain.filters,
            space: null,
          },
        },
      };
    case POST_ACTION_TYPES.RESET_SINGLE:
      return {
        ...state,
        postsDomain: {
          ...state.postsDomain,
          singlePost: null,
        },
      };
    case POST_ACTION_TYPES.SAVE_SCROLL:
      return {
        ...state,
        postsDomain: {
          ...state.postsDomain,
          scrollPosition: action.payload,
        },
      };
    case POST_ACTION_TYPES.OWN_POST:
      fetch(`${APP_URL}/post/own/${action.payload}`, { method: 'put', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            store.dispatch(POST_ACTIONS.receiveOwnStatus(action.payload, json));
          });
        });
      return state;
    case POST_ACTION_TYPES.RECEIVE_OWN_STATUS:
      postIndex = state.postsDomain.posts.map(post => post.id).indexOf(action.payload.postId);
      growPostIndex = state.growDomain.posts
        .map(post => (post.post ? post.post.id : null))
        .indexOf(action.payload.postId);
      newState = JSON.parse(JSON.stringify(state));
      if (action.payload.json.owned) {
        if (postIndex > -1) {
          newState.postsDomain.posts[postIndex].is_owner = true;
          newState.postsDomain.posts[postIndex].owners.push(action.payload.json.owner);
        }
        if (growPostIndex > -1) {
          newState.growDomain.posts[growPostIndex].post.is_owner = true;
          newState.growDomain.posts[growPostIndex].post.owners.push(action.payload.json.owner);
        }
        if (newState.postsDomain.singlePost) {
          newState.postsDomain.singlePost.is_owner = true;
          newState.postsDomain.singlePost.owners.push(action.payload.json.owner);
        }
      } else {
        if (postIndex > -1) {
          newState.postsDomain.posts[postIndex].is_owner = false;
          newState.postsDomain.posts[postIndex].owners = newState.postsDomain.posts[
            postIndex
          ].owners.filter(owner => owner.id !== state.usersDomain.user.id);
        }
        if (growPostIndex > -1) {
          newState.growDomain.posts[growPostIndex].post.is_owner = false;
          newState.growDomain.posts[growPostIndex].post.owners = newState.growDomain.posts[
            growPostIndex
          ].post.owners.filter(owner => owner.id !== state.usersDomain.user.id);
        }
        if (newState.postsDomain.singlePost) {
          newState.postsDomain.singlePost.is_owner = false;
          newState.postsDomain.singlePost.owners = newState.postsDomain.singlePost.owners.filter(
            owner => owner.id !== state.usersDomain.user.id
          );
        }
      }
      return newState;
    case POST_ACTION_TYPES.PIN_POST:
      fetch(`${APP_URL}/post/pin/${action.payload}`, { method: 'put', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            store.dispatch(POST_ACTIONS.receivePinStatus(action.payload, json));
          });
        });
      return state;
    case POST_ACTION_TYPES.RECEIVE_PIN_STATUS:
      newState = JSON.parse(JSON.stringify(state));
      const post = newState.postsDomain.posts.find(post => post.id === action.payload.postId);
      if (post) {
        post.pinned = action.payload.json.pinned;
        newState.postsDomain.posts = [
          ...newState.postsDomain.posts.filter(post => post.pinned),
          ...newState.postsDomain.posts.filter(post => !post.pinned),
        ];
      }
      if (newState.postsDomain.singlePost) {
        newState.postsDomain.singlePost.pinned = action.payload.json.pinned;
      }
      return newState;
    case POST_ACTION_TYPES.FAV_POST:
      fetch(`${APP_URL}/post/favourite/${action.payload.id}`, { method: 'put', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            store.dispatch(
              POST_ACTIONS.receiveFavStatus(action.payload.id, json, action.payload.type)
            );
          });
        });
      return state;
    case POST_ACTION_TYPES.RECEIVE_FAV_STATUS:
      const newFavState = JSON.parse(JSON.stringify(state));
      if (action.payload.type === 'growPost') {
        const favPostIndex = state.growDomain.posts
          .map(post => (post.post ? post.post.id : null))
          .indexOf(action.payload.postId);
        newFavState.growDomain.posts[favPostIndex].post.favourite = action.payload.json.favourite;
      } else {
        const favPostIndex = state.postsDomain.posts
          .map(post => post.id)
          .indexOf(action.payload.postId);
        if (action.payload.json.favourite) {
          if (favPostIndex > -1) {
            newFavState.postsDomain.posts[favPostIndex].favourite = true;
          }
          if (newFavState.postsDomain.singlePost) {
            newFavState.postsDomain.singlePost.favourite = true;
          }
        } else {
          if (favPostIndex > -1) {
            newFavState.postsDomain.posts[favPostIndex].favourite = false;
          }
          if (newFavState.postsDomain.singlePost) {
            newFavState.postsDomain.singlePost.favourite = false;
          }
        }
      }
      return newFavState;
    case POST_ACTION_TYPES.RESOLVE_POST:
      fetch(`${APP_URL}/post/resolve/${action.payload.id}?reason=${action.payload.reason}`, {
        method: 'put',
        headers,
      })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            store.dispatch(POST_ACTIONS.receiveResolveStatus(action.payload.id, json));
          });
        });
      return state;
    case POST_ACTION_TYPES.RECEIVE_RESOLVE_STATUS:
      postIndex = state.postsDomain.posts.map(post => post.id).indexOf(action.payload.id);
      growPostIndex = state.growDomain.posts
        .map(post => (post.post ? post.post.id : null))
        .indexOf(action.payload.id);
      newState = JSON.parse(JSON.stringify(state));
      if (postIndex > -1) {
        newState.postsDomain.posts[postIndex].status = 'resolved';
        newState.postsDomain.posts[postIndex].updater = action.payload.json.updater;
      }
      if (growPostIndex > -1) {
        newState.growDomain.posts[growPostIndex].post.status = 'resolved';
        newState.growDomain.posts[growPostIndex].post.updater = action.payload.json.updater;
      }
      if (newState.postsDomain.singlePost) {
        newState.postsDomain.singlePost.status = 'resolved';
        newState.postsDomain.singlePost.reason = action.payload.json.reason;
        newState.postsDomain.singlePost.updater = action.payload.json.updater;
      }
      return newState;
    case POST_ACTION_TYPES.LIKE_POST:
      fetch(`${APP_URL}/post/like/${action.payload}`, { method: 'put', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            store.dispatch(POST_ACTIONS.receiveLikeStatus(action.payload, json));
          });
        });
      return state;
    case POST_ACTION_TYPES.RECEIVE_LIKE_STATUS:
      postIndex = state.postsDomain.posts.map(post => post.id).indexOf(action.payload.id);
      growPostIndex = state.growDomain.posts
        .map(post => (post.post ? post.post.id : null))
        .indexOf(action.payload.id);
      newState = JSON.parse(JSON.stringify(state));
      if (action.payload.json.like) {
        if (postIndex > -1) {
          newState.postsDomain.posts[postIndex].cache_total_likes++;
          newState.postsDomain.posts[postIndex].is_liker = true;
        }
        if (growPostIndex > -1) {
          newState.growDomain.posts[growPostIndex].post.cache_total_likes++;
          newState.growDomain.posts[growPostIndex].post.is_liker = true;
        }
        if (newState.postsDomain.singlePost) {
          newState.postsDomain.singlePost.cache_total_likes++;
          newState.postsDomain.singlePost.is_liker = true;
          newState.postsDomain.singlePost.likers.push(action.payload.json.liker);
        }
      } else {
        if (postIndex > -1) {
          newState.postsDomain.posts[postIndex].cache_total_likes--;
          newState.postsDomain.posts[postIndex].is_liker = false;
        }
        if (growPostIndex > -1) {
          newState.growDomain.posts[growPostIndex].post.cache_total_likes--;
          newState.growDomain.posts[growPostIndex].post.is_liker = false;
        }
        if (newState.postsDomain.singlePost) {
          newState.postsDomain.singlePost.cache_total_likes--;
          newState.postsDomain.singlePost.is_liker = false;
          newState.postsDomain.singlePost.likers = newState.postsDomain.singlePost.likers.filter(
            liker => liker.id !== state.usersDomain.user.id
          );
        }
      }
      return newState;
    case POST_ACTION_TYPES.UPDATE_POST:
      fetch(`${APP_URL}/post/${action.payload.id}`, {
        method: 'post',
        headers,
        body: action.payload.data,
      })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          const { status } = response;
          response.json().then(json => {
            store.dispatch(POST_ACTIONS.receiveUpdateStatus(json, status));
            store.dispatch(TAG_ACTIONS.fetchHashtags());
            store.dispatch(TAG_ACTIONS.fetchDiscussionTags());
            store.dispatch(TAG_ACTIONS.fetchCompanyValues());
          });
        });
      newState = JSON.parse(JSON.stringify(state));
      if (newState.postsDomain.singlePost) {
        newState.postsDomain.singlePost.isFetching = true;
        newState.postsDomain.singlePost.error = undefined;
      }
      return newState;
    case POST_ACTION_TYPES.RECEIVE_UPDATE_STATUS:
      postIndex = state.postsDomain.posts.map(post => post.id).indexOf(action.payload.json.id);
      newState = JSON.parse(JSON.stringify(state));

      const status = action.payload.status || 200;
      switch (status) {
        case 200:
          if (postIndex > -1) newState.postsDomain.posts[postIndex] = action.payload.json;
          if (newState.postsDomain.singlePost) {
            newState.postsDomain.singlePost = action.payload.json;
            newState.postsDomain.singlePost.isFetching = false;
          }
          break;
        case 422:
          const errors = Object.values(action.payload.json);
          if (errors.length) {
            newState.postsDomain.singlePost.error = errors[0];
            newState.postsDomain.singlePost.isFetching = false;
          }
          break;
        case 404:
          const { error } = action.payload.json;
          if (error) {
            newState.postsDomain.singlePost.error = error;
            newState.postsDomain.singlePost.isFetching = false;
          }
          break;
      }
      return newState;
    case POST_ACTION_TYPES.VOTE_POLL:
      fetch(`${APP_URL}/post/vote/${action.payload.id}`, {
        method: 'post',
        headers,
        body: action.payload.data,
      })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            store.dispatch(POST_ACTIONS.receiveUpdateStatus(json));
          });
        });
      return state;
    case POST_ACTION_TYPES.UNFOLLOW_POST:
      fetch(`${APP_URL}/unfollow-post/${action.payload}`, { method: 'put', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            store.dispatch(POST_ACTIONS.receiveUnfollowStatus(action.payload, json));
          });
        });
      return state;
    case POST_ACTION_TYPES.RECEIVE_UNFOLLOW_STATUS:
      postIndex = state.postsDomain.posts.map(post => post.id).indexOf(action.payload.postId);
      newState = JSON.parse(JSON.stringify(state));
      if (action.payload.json.unfollowed) {
        if (postIndex > -1) {
          newState.postsDomain.posts[postIndex].is_unfollowed = true;
        }
        if (newState.postsDomain.singlePost) {
          newState.postsDomain.singlePost.is_unfollowed = true;
        }
      } else {
        if (postIndex > -1) {
          newState.postsDomain.posts[postIndex].is_unfollowed = false;
        }
        if (newState.postsDomain.singlePost) {
          newState.postsDomain.singlePost.is_unfollowed = false;
        }
      }
      return newState;
    case POST_ACTION_TYPES.ADD_SHOUTOUT:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user: {
            ...state.usersDomain.user,
            remainingShoutouts: state.usersDomain.user.remainingShoutouts - 1,
          },
        },
      };
    case POST_ACTION_TYPES.RESET_FILTERS:
      return {
        ...state,
        postsDomain: {
          ...state.postsDomain,
          filters: {
            space: null,
            types: [],
            excludedSpaces: [],
            hashtags: [],
            string: '',
          },
        },
      };
    case POST_ACTION_TYPES.FILTERS_FROM_OBJECT:
      return {
        ...state,
        postsDomain: {
          ...state.postsDomain,
          filters: action.payload,
        },
      };
    case POST_ACTION_TYPES.UPDATE_REMAINING_SHOUTOUTS:
      let usersRemainingShoutouts = state.usersDomain.user.remainingShoutouts;
      if (action.payload === 'added') {
        usersRemainingShoutouts -= 1;
        if (usersRemainingShoutouts < 0) {
          usersRemainingShoutouts = 0;
        }
      } else if (action.payload === 'removed') {
        usersRemainingShoutouts += 1;
      }

      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user: {
            ...state.usersDomain.user,
            remainingShoutouts: usersRemainingShoutouts,
          },
        },
      };
    case POST_ACTION_TYPES.FETCH_OPEN_QUESTIONNAIRES:
      fetch(`${APP_URL}/open_questionnaires`, {
        method: action.payload.visitedTab ? 'post' : 'get',
        headers,
      })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            store.dispatch(POST_ACTIONS.receiveOpenQuestionnaires(json));
          });
        });
      return state;
    case POST_ACTION_TYPES.RECEIVE_OPEN_QUESTIONNAIRES:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user: {
            ...state.usersDomain.user,
            hasOpenQuestionnaires: action.payload.hasOpenQuestionnaires,
          },
        },
      };
    default:
      return state;
  }
};
export default postReducer;
