import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from '@emotion/styled';
import { ButtonWrapper } from 'components/onboarding/ExistedMeetingModal';
import PostponeButton from 'components/onboarding/partials/PostponeButton';
import OnboardingButton from 'components/onboarding/partials/OnboardingButton';
import { CloseButton } from 'components/shared/Modal/UserModal';

export const ModalWrapper = styled.div`
  h2 {
    margin: 0 auto;
  }
  @media (min-width: 620px) {
    img.close-button {
      display: none;
    }
  }
`;

const AccountDeletionModal = ({ isModalOpen, closeModal, submitAccountDeletion }) => {
  return (
    <Modal
      show={isModalOpen}
      onHide={closeModal}
      dialogClassName="account-deletion-modal"
      backdrop
      keyboard
    >
      <ModalWrapper>
        <h2>
          Do you really want to schedule your account for removal?
          <br />
          All data will be lost. This action cannot be undone
        </h2>
        <ButtonWrapper>
          <OnboardingButton
            label="Delete permanently"
            submit
            top={10}
            width={'auto'}
            height={36}
            left={0}
            onClick={submitAccountDeletion}
          />
          <PostponeButton text="Cancel" onClick={closeModal} />
        </ButtonWrapper>
        <CloseButton className="close-button" onClick={closeModal} />
      </ModalWrapper>
    </Modal>
  );
};

export default AccountDeletionModal;
