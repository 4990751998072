import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import qs from 'qs';
import FactorsTooltipVertical from './FactorsTooltipVertical';

class FactorsBarVertical extends Component {
  state = {
    showTooltip: false,

    awesomeHeight: 0,
    goodHeight: 0,
    okayHeight: 0,
    badHeight: 0,
    terribleHeight: 0,
  };

  componentDidMount = () => {
    setTimeout(
      () =>
        this.setState({
          awesomeHeight: (this.props.data.awesome * this.props.chartHeight) / this.props.maxStep,
          goodHeight: (this.props.data.good * this.props.chartHeight) / this.props.maxStep,
          okayHeight: (this.props.data.okay * this.props.chartHeight) / this.props.maxStep,
          badHeight: (this.props.data.bad * this.props.chartHeight) / this.props.maxStep,
          terribleHeight: (this.props.data.terrible * this.props.chartHeight) / this.props.maxStep,
        }),
      0
    );
  };

  componentWillReceiveProps = nextProps => {
    if (
      JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data) ||
      nextProps.maxStep !== this.props.maxStep
    ) {
      this.setState({
        awesomeHeight: (nextProps.data.awesome * this.props.chartHeight) / nextProps.maxStep,
        goodHeight: (nextProps.data.good * this.props.chartHeight) / nextProps.maxStep,
        okayHeight: (nextProps.data.okay * this.props.chartHeight) / nextProps.maxStep,
        badHeight: (nextProps.data.bad * this.props.chartHeight) / nextProps.maxStep,
        terribleHeight: (nextProps.data.terrible * this.props.chartHeight) / nextProps.maxStep,
      });
    }
  };

  render() {
    const { dashboard, factorKey, factor } = this.props;
    return (
      <Link
        className={`bar ${this.props.className}`}
        onMouseEnter={() => this.setState({ showTooltip: true })}
        onMouseLeave={() => this.setState({ showTooltip: false })}
        onClick={e => {
          if (!dashboard && factor === 'Other') {
            e.preventDefault();
          }
        }}
        to={{
          pathname: dashboard ? '/vibes/factor_analysis' :'/vibes/performance_over_time',
          search: qs.stringify(this.props.queryString),
          state: { factor: dashboard ? factorKey + 1 : factor },
        }}
      >
        <div className="awesome-subbar subbar" style={{ height: this.state.awesomeHeight }} />
        <div className="good-subbar subbar" style={{ height: this.state.goodHeight }} />
        <div className="okay-subbar subbar" style={{ height: this.state.okayHeight }} />
        <div className="bad-subbar subbar" style={{ height: this.state.badHeight }} />
        <div className="terrible-subbar subbar" style={{ height: this.state.terribleHeight }} />
        <FactorsTooltipVertical
          data={this.props.data}
          isMounted={this.state.showTooltip}
          delayTime={300}
        />
      </Link>
    );
  }
}

export default FactorsBarVertical;
