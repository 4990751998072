import mimeTypes from './mimeTypes';

const processGoogleFiles = (files, state) => {
  const fileArray = [];
  const mediaUrls = [];
  const mediaSizes = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (mimeTypes.FILES.includes(file.mimeType)) {
      fileArray.push(file);
    } else if (mimeTypes.IMAGE.includes(file.mimeType) || mimeTypes.VIDEO.includes(file.mimeType)) {
      mediaUrls.push({
        url: file.thumbnailLink,
        type: file.type === 'photo' ? 'i' : 'v',
        drive: true,
        id: file.id,
      });
      mediaSizes.push({ size: file.sizeBytes });
    }
  }
  const newState = {
    mediaUrl: [...state.mediaUrl, ...mediaUrls],
    mediaSize: [...state.mediaSize, ...mediaSizes],
    files: [...state.files, ...fileArray],
  };

  if (mediaUrls.length > 0) {
    newState.disabledButton = false;
  }

  return newState;
};

export default processGoogleFiles;
