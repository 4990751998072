import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import Croppie from 'components/shared/croppie';
import { connect } from 'react-redux';
import LoadingScale from '../shared/LoadingScale';
import {
  BODY_CLASSES,
  LOCALSTORAGE_BOOL,
  CONVERT_NAME,
  HEADERS,
  CHECK_UNAUTHORIZED,
} from '../../helpers';
import { APP_URL, AWS_S3_URL } from '../../constants';
import CroppieRotate from '../shared/CroppieRotate';
import verifyAvatarImage from '../shared/fileUpload/verifyAvatarImage';
import { TEAM_ACTIONS } from 'redux/actions/teamActions';

class TeamsEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      team: '',
      teams: [],
      redirect: false,
      image: '',
      name: '',
      parentId: '',
      imagePreviewUrl: null,
      imagePreviewCroppyUrl: false,
      sendImage: false,
      loading: false,
      teamTags: [],
      teamTagsValue: '',
    };
  }

  componentDidMount() {
    this.props.closeFlash();
  }

  componentDidUpdate() {
    if (this.state.imagePreviewCroppyUrl) {
      const el = this.refs.croppie;
      if (el) {
        this.croppie = new Croppie(el, {
          url: this.state.imagePreviewCroppyUrl,
          viewport: {
            width: 200,
            height: 200,
            type: 'circle',
          },
          boundary: {
            width: 300,
            height: 300,
          },
          enableExif: true,
          enableOrientation: true,
        });
      }
    }
  }

  componentWillMount() {
    BODY_CLASSES(['teams', 'edit']);
    const path = this.props.location.pathname;
    const headers = HEADERS();
    fetch(APP_URL + path, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (response.status !== 200) {
          this.props.setErrorPage(response.status);
        } else {
          response.json().then(jsonResponse => {
            if (jsonResponse.message) {
              this.setState({ redirect: true });
              this.props.updateFlash(jsonResponse.flashName, jsonResponse.message);
            } else {
              this.setState({
                team: jsonResponse.team,
                name: jsonResponse.team.name,
                parentId: jsonResponse.team.parent_id,
                teams: jsonResponse.teams,
                teamTags: jsonResponse.team_tags,
                teamTagsValue: jsonResponse.team.teamTags,
              });
              if (jsonResponse.team.image_uid) {
                this.setState({ imagePreviewUrl: jsonResponse.team.image_url });
              }
            }
          });
        }
      })
      .catch(() => {});
  }

  async deleteTeam(e) {
    e.preventDefault();
    const membersCount =
      this.state.teams
        .filter(stateTeam => stateTeam.parent_ids.indexOf(this.state.team.id) > -1)
        .map(stateTeam => stateTeam.users_count)
        .reduce((a, b) => a + b, 0) + this.state.team.users.length;
    const confirmationMessage = `Are you sure you would like to delete this Team and all of its sub teams?${
      membersCount === 0
        ? ''
        : ` It has ${membersCount} active user${
            membersCount !== 1 ? 's' : ''
          } who will be moved to your user bucket and need to be re-assigned.`
    }`;
    if (window.confirm(confirmationMessage)) {
      this.setState({ loading: true });
      const headers = HEADERS();
      const response = await fetch(`${APP_URL}/team_with_users/${this.state.team.id}`, {
        method: 'delete',
        headers,
      });
      const json = await response.json();
      this.props.updateFlash(json.flashName, json.message);
      if (json.flashName === 'success') {
        this.setState({ redirect: true, flashName: json.flashName, message: json.message });
      } else {
        this.setState({ loading: false });
      }
    }
  }

  showCroppieModal(e, state) {
    if (e) {
      e.preventDefault();
    }
    this.setState({ croppieModal: state });
    if (!state) {
      this.setState({ imagePreviewCroppyUrl: false });
      document.getElementById('trigger-croppie').value = '';
    }
  }

  uploadImage(e) {
    const reader = new FileReader();
    const image = e.target.files[0];
    if (verifyAvatarImage(image)) {
      this.showCroppieModal(null, true);
      this.setState({ image });
      reader.onloadend = () => {
        this.setState({
          file: image,
          imagePreviewCroppyUrl: reader.result,
        });
      };
      reader.readAsDataURL(image);
    } else {
      e.target.value = null;
    }
  }

  deleteImage(e) {
    e.preventDefault();
    this.setState({ loading: true });
    const headers = HEADERS();
    const data = new FormData();
    data.append('remove_image', true);
    fetch(`${APP_URL}/team/${this.state.team.id}`, { headers, body: data, method: 'POST' })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.props.updateFlash(jsonResponse.flashName, jsonResponse.message);
          this.setState({ imagePreviewUrl: null });
          this.setState({ loading: false });
        });
      })
      .catch(() => {});
  }

  setTeamParent(e) {
    this.setState({ parentId: e.target.value });
  }

  setTeamName(e) {
    this.setState({ name: e.target.value });
  }

  submitForm(e) {
    e.preventDefault();
    this.setState({ loading: true });
    const data = new FormData();
    data.append('team[name]', this.state.name);
    data.append('team[tags]', this.state.teamTagsValue);
    if (this.state.parentId) {
      data.append('team[parent_id]', this.state.parentId);
    }
    data.append('teamImage', this.state.image);
    if (this.state.sendImage) {
      data.append('teamImageBase64', JSON.stringify(this.state.imagePreviewUrl));
    }
    const headers = HEADERS();
    fetch(`${APP_URL}/team/${this.state.team.id}`, { method: 'post', headers, body: data })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          if (!jsonResponse.flashName === 'error') {
            this.setState({
              team: jsonResponse.team,
              loading: false,
            });
          } else {
            this.setState({ loading: false });
          }
          this.props.fetchTeams();
          this.props.updateFlash(jsonResponse.flashName, jsonResponse.message);
        });
      })
      .catch(() => {});
  }

  getCroppieResult() {
    this.showCroppieModal(null, false);
    this.croppie.result('blob').then(blob => {
      if (blob.size > 2000000) {
        alert('File is too big! Maximum size allowed is 2MB');
      } else {
        this.croppie.result('base64').then(base => {
          this.setState({ imagePreviewCroppyUrl: false });
          this.setState({ imagePreviewUrl: base });
          this.setState({ croppieModal: false });
          this.setState({ sendImage: true });
        });
      }
    });
  }

  handleTeamTagChange = value => {
    this.setState({ teamTagsValue: value });
  };

  rotateCroppie = deg => this.croppie.rotate(deg);

  render() {
    const { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} alt={this.state.team.name} />;
    } else {
      $imagePreview = (
        <img
          src={`${AWS_S3_URL}public/images/defaults/team.jpg`}
          alt={this.state.team.name}
          style={{ width: '100%' }}
        />
      );
    }
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: '/spaces',
            state: {
              flashName: this.state.flashName,
              message: this.state.message,
            },
          }}
        />
      );
    }
    return (
      <div>
        {this.state.loading && <LoadingScale />}
        <div className="header clear">
          <h1 title={this.state.team.name}>Edit&nbsp;{CONVERT_NAME(this.state.team.name)}</h1>
        </div>
        <div id="croppie-form" data-action="" data-model="team" data-redirect="">
          <div className="image-input margin-below clear">
            <a className="square-box pull-left" onClick={() => this.refs.uploadInput.click()}>
              <div className="fa fa-pencil" />
              <div className="circle-image md">{$imagePreview}</div>
            </a>
            <div className="space-to-left-md pull-left">
              <label htmlFor="trigger-croppie" className="upload-label">
                Upload your photo...
              </label>
              <input
                accept="image/*"
                type="file"
                className="trigger-croppie"
                id="trigger-croppie"
                onChange={e => this.uploadImage(e)}
                ref="uploadInput"
              />
              <p className="help-block">Photos should be at least 100 x 100px</p>
              <a
                href="#"
                className="primary-link"
                style={imagePreviewUrl ? { display: 'block' } : { display: 'none' }}
                onClick={e => this.deleteImage(e)}
              >
                Remove current photo
              </a>
            </div>
          </div>
          <Modal
            id="avatar_edit_modal"
            show={this.state.croppieModal}
            onHide={() => this.showCroppieModal(null, false)}
          >
            <Modal.Header className="no-border centered">
              <Modal.Title>Crop your image</Modal.Title>
              <p className="u-margin-bottom-none">Zoom in and out to crop your image</p>
            </Modal.Header>
            <Modal.Body className="clear">
              <div id="croppie" ref="croppie" />
              <CroppieRotate rotate={this.rotateCroppie.bind(this)} />
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-sm btn-wide btn-primary pull-right upload-result"
                onClick={() => this.getCroppieResult()}
              >
                Save
              </button>
              <a
                className="cancel btn btn-sm btn-fixed pull-right"
                onClick={() => this.showCroppieModal(null, false)}
              >
                Cancel
              </a>
            </Modal.Footer>
          </Modal>
        </div>
        <form className="simple_form edit_team" id="edit_team">
          <div className="form-group string required team_name">
            <label htmlFor="team_name" className="string required control-label">
              Team name
            </label>
            <input
              type="text"
              className="string required form-control"
              id="team_name"
              name="team[name]"
              value={this.state.name || ''}
              onChange={e => this.setTeamName(e)}
            />
            <p className="help-block">eg. Management, Accounting, Human Resources.</p>
          </div>
          {this.state.team.type !== 'DefaultTeam' && LOCALSTORAGE_BOOL('is_company_owner') ? (
            <div className="form-group select optional team_parent">
              <label htmlFor="team_parent_id" className="select optional control-label">
                Parent Team
              </label>
              <select
                className="select optional select2 form-control"
                tabIndex={-1}
                id="team_parent_id"
                name="team[parent_id]"
                onChange={e => this.setTeamParent(e)}
                value={this.state.parentId || ''}
              >
                {this.state.teams.map(team => (
                  <option value={team.id} key={team.id}>
                    {CONVERT_NAME(team.name)}
                  </option>
                ))}
              </select>
              <p className="help-block">The group this team belongs to.</p>
            </div>
          ) : (
            ''
          )}
          <Select
            closeOnSelect={false}
            multi
            onChange={this.handleTeamTagChange}
            options={this.state.teamTags}
            placeholder="Choose tag"
            simpleValue
            value={this.state.teamTagsValue}
            style={{ marginBottom: '25px' }}
          />
          <p>Tags can help you segment your data. For instance, if a team is in specific geographical location. You can add multiple tags.</p>
        </form>
        <div className="clear">
          <input
            type="submit"
            value="Save"
            className="btn btn-lg btn-primary pull-right btn-wide"
            onClick={e => this.submitForm(e)}
          />
          <Link
            to="/teams"
            className="primary-link form-footer-text-link pull-right"
            onClick={e => this.deleteTeam(e)}
          >
            Delete Team
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companyValues: state.tagsDomain.companyValues,
});

const mapDispatch = dispatch => ({
  fetchTeams: () => dispatch(TEAM_ACTIONS.fetchTeams()),
});

export default connect(mapStateToProps, mapDispatch)(TeamsEdit);
