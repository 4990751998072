import PropTypes from 'prop-types';
import React from 'react';
import VerticalCenterContainer from 'components/organisation/partials/VerticalCenterContainer';
import { AWS_S3_URL } from 'constants.js';
import styled from '@emotion/styled';

const Container = styled(VerticalCenterContainer)`
  z-index: 10000;
  position: absolute;
  top: 0;
  width: 100%;
  height: 70%;
  background: white;
  opacity: 0.9;
  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;

const OverlayContainer = styled.div`
  display: flex;
  margin: 0 auto;
`;

const Spinner = styled.div`
  animation: rotate 1s linear infinite;
  background: url('${AWS_S3_URL}public/images/circular-spinner.png') no-repeat;
  background-size: cover;
  height: 46px;
  width: 46px;
`;

const OverlayDescription = styled.div`
  display: flex !important;
  align-items: center;
  font-size: 18px;
  color: #6a6a6a;
  position: relative;
  margin-left: 19px;
`;

const LoadingOverlay = ({ toggled, message, height = '70%' }) =>
  toggled ? (
    <Container style={{ height }}>
      <OverlayContainer>
        <Spinner />
        <OverlayDescription>{message}</OverlayDescription>
      </OverlayContainer>
    </Container>
  ) : null;

LoadingOverlay.propTypes = {
  toggled: PropTypes.bool.isRequired,
  message: PropTypes.string,
  height: PropTypes.string,
};

export default LoadingOverlay;
