import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import Cookies from 'universal-cookie';
import { useEffect, useRef } from 'react';
import moment from 'moment';
import mimeTypes from 'components/shared/GooglePicker/mimeTypes';
import { APP_URL, AVATAR_GENERIC, CDN_URL } from './constants';

export const CLEAR_COOKIE_TOKEN = () => {
  const cookies = new Cookies();
  cookies.remove('heelix_auth_token', { path: '/', domain: '.heelix.com' });
  cookies.remove('heelix_auth_token', { path: '/' });
};

export const SIGN_OUT = (withReload = true) => {
  CLEAR_COOKIE_TOKEN();
  if (localStorage == null) {
    return;
  }
  sessionStorage.clear();
  localStorage.clear();
  localStorage.setItem('is_authenticated', false);
  if (withReload) {
    window.location.reload();
  }
};

/**
 *
 * @param {Response} response
 * @returns {Response|*}
 */
export const CHECK_UNAUTHORIZED = response => {
  if (response.status === 401) {
    SIGN_OUT();
    throw Error('Could not add post');
  }
  return response;
};
/**
 * Get a prestored setting
 *
 * @returns String The value of the setting | null
 * @param name
 */
export const LOCALSTORAGE_GET = name => {
  if (typeof Storage !== 'undefined' && localStorage != null) {
    return localStorage.getItem(name);
  }
  console.log('no storage');
  return null;
};
/**
 *
 * @param name
 * @returns {*}
 * @constructor
 */
export const LOCALSTORAGE_BOOL = name => {
  const value = LOCALSTORAGE_GET(name);

  if (!value || value.localeCompare('false') === 0) {
    return false;
  }
  return true;
};

/**
 *
 * @constructor
 */
export const CHECK_IF_AUTHENTICATED = () => {
  if (
    LOCALSTORAGE_BOOL('is_authenticated') &&
    LOCALSTORAGE_GET('access_token') &&
    !LOCALSTORAGE_BOOL('is_admin') &&
    !LOCALSTORAGE_BOOL('is_company_owner') &&
    !LOCALSTORAGE_BOOL('is_team_manager') &&
    !LOCALSTORAGE_BOOL('is_team_member')
  ) {
    SIGN_OUT();
  }
};

export const FORMATTER = buildFormatter({
  prefixAgo: null,
  prefixFromNow: null,
  suffixAgo: '',
  suffixFromNow: 'from now',
  seconds: 'just now',
  minute: '1min',
  minutes: '%dmin',
  hour: '1h',
  hours: '%dh',
  day: '1d',
  days: '%dd',
  month: '1m',
  months: '%dm',
  year: '1y',
  years: '%dy',
  wordSeparator: '',
  numbers: [],
});

export const IS_TOKEN_VALID = () => {
  let value = LOCALSTORAGE_GET('token_expires_at');

  if (!value) {
    value = sessionStorage.getItem('token_expires_at');
    if (!value || new Date() > value) {
      SIGN_OUT();
      return false;
    }
  }
  return true;
};
export const IS_AUTHENTICATED = () => {
  const headers = new Headers();
  let accessToken;

  if (localStorage != null) {
    accessToken = LOCALSTORAGE_GET('access_token');
  }
  if (!accessToken) {
    const url = document.location;
    const regex = new RegExp('[?&]uta(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results[2]) {
      accessToken = '';
    }
    accessToken = decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  headers.append('Accept', 'application/json');
  headers.append('authorization', `Bearer ${accessToken}`);
  fetch(`${APP_URL}/check`, { method: 'get', headers })
    .then(response => CHECK_UNAUTHORIZED(response))
    .then(response => {
      if (response.status === 401 || response.status === 403) {
        SIGN_OUT();
      }
    })
    .catch(() => {});
};

export const BODY_CLASSES = classes => {
  document.body.removeAttribute('class');
  if (classes) {
    for (let i = 0; i < classes.length; i += 1) {
      document.body.classList.add(classes[i]);
    }
  }
};

export const CONVERT_NAME = name => {
  if (name) {
    if (name.length > 30) {
      return `${name.substr(0, 31)}...`;
    }
    return name.substr(0, 31);
  }
};

export const HEADERS = accessTokenParam => {
  const accessToken = accessTokenParam || LOCALSTORAGE_GET('access_token') || '';
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('authorization', `Bearer ${accessToken}`);
  headers.append('heelix-timestamp', Date.now());
  if (LOCALSTORAGE_GET('admin_access_token')) {
    headers.append(
      'Impersonator-Authorization',
      `Bearer ${LOCALSTORAGE_GET('admin_access_token')}`
    );
  }
  return headers;
};

export const OBJECT_TO_FORMDATA = object => {
  const formData = new FormData();
  for (const key in object) {
    formData.append(key, object[key]);
  }
  return formData;
};

export const FORMAT_FILTERS = (element, currentFilters) => {
  const filters = JSON.parse(JSON.stringify(currentFilters));

  // --Used only for advanced search
  if (element.getAttribute('filter-type') === 'multiple-spaces') {
    if (element.getAttribute('custom-checked') === 'false')
      filters.spaces.push(element.getAttribute('space-id'));
    else filters.spaces = filters.spaces.filter(id => id !== element.getAttribute('space-id'));
  }
  if (element.getAttribute('filter-type') === 'filter-spaces') {
    const alreadyExcluded = filters.excludedSpaces.findIndex(
      spaceId => parseInt(spaceId) === parseInt(element.getAttribute('space-id'))
    );
    if (alreadyExcluded >= 0) {
      filters.excludedSpaces.splice(alreadyExcluded, 1);
    } else {
      filters.excludedSpaces.push(element.getAttribute('space-id'));
    }
  }
  //--------------------------------

  if (element.getAttribute('filter-type') === 'string') filters.string = element.value;
  if (element.getAttribute('filter-type') === 'type') {
    if (element.getAttribute('custom-checked') === 'false')
      filters.types.push(element.getAttribute('type-filter'));
    else filters.types = filters.types.filter(id => id !== element.getAttribute('type-filter'));
  }
  if (element.getAttribute('filter-type') === 'new-grow-type') {
    filters.types.length = 0;
    filters.tab = element.getAttribute('type-filter');
  }

  if (element.getAttribute('filter-type') === 'grow-type') {
    const alreadyExcluded = filters.types.findIndex(
      id => id === element.getAttribute('type-filter')
    );
    if (alreadyExcluded >= 0) {
      filters.types.splice(alreadyExcluded, 1);
    } else {
      filters.types.push(element.getAttribute('type-filter'));
    }
  }

  if (element.getAttribute('filter-type') === 'user-type') {
    const alreadyExcluded = filters.users.findIndex(
      id => id === element.getAttribute('type-filter')
    );
    if (alreadyExcluded >= 0) {
      filters.users.splice(alreadyExcluded, 1);
    } else {
      filters.users.push(element.getAttribute('type-filter'));
    }
  }
  if (element.getAttribute('filter-type') === 'hashtag') {
    if (element.classList.contains('inline-hashtag')) {
      // ---Clear filters and apply only single hashtag filter when an inline one is clicked
      return {
        space: filters.space,
        types: [],
        excludedSpaces: [],
        hashtags: [element.getAttribute('hashtag-slug')],
        string: '',
      };
    }

    if (element.getAttribute('custom-checked') === 'false')
      filters.hashtags.push(element.getAttribute('hashtag-slug'));
    else
      filters.hashtags = filters.hashtags.filter(
        slug => slug !== element.getAttribute('hashtag-slug')
      );
  }
  return filters;
};

export const AUTOCOMPLETE_NATIVE_VALUE_RESET = idOrElement => {
  // React autocomplete is quite tricky element that uses some sort of onChange and onKeyDown combination to
  // display options. If `trigger` is set to '', component will not close options when value is set to '' and there are more than one option containing
  // phrase in input (ex. 'Account' and 'Account management' - when 'Account' is applied, options will still be opened with 'Account management' displayed)
  const element =
    typeof idOrElement === 'string' ? document.getElementById(idOrElement) : idOrElement;

  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    'value'
  ).set;
  nativeInputValueSetter.call(element, '');

  let event;
  if (typeof Event === 'function') {
    event = new Event('input', { bubbles: true });
  } else {
    event = document.createEvent('Event');
    event.initEvent('input', true, true);
  }

  element.dispatchEvent(event);
};

export const AVATAR_ERROR = img => {
  img.onerror = '';
  img.src = `${CDN_URL + AVATAR_GENERIC}?d=170x170`;
};

// ---Required for some pages extending over 1200px, as negative margin will break view in case of huge difference
export const PAGE_WRAPPER_CLASSES = classes => {
  const wrapper = document.querySelector('div.page-wrapper');
  wrapper.removeAttribute('class');
  wrapper.classList.add('page-wrapper');
  if (classes) {
    for (let i = 0; i < classes.length; i += 1) {
      wrapper.classList.add(classes[i]);
    }
  }
};

export const GET_MENTIONS_FROM_CONTENT = content => {
  const helper = document.createElement('div');
  helper.insertAdjacentHTML('afterbegin', content);
  const mentions = [];
  Array.from(helper.querySelectorAll('a')).forEach(node => {
    if (node.classList.contains('inline-mention')) {
      mentions.push({
        id: node.getAttribute('slug'),
        display: node.innerHTML,
        type: 'people',
      });
    } else if (node.classList.contains('inline-hashtag')) {
      mentions.push({
        id: node.getAttribute('hashtag-slug'),
        type: 'hashtags',
      });
    }
  });
  return mentions;
};

export const FORMAT_CONTENT_WITH_MARKUPS = content => {
  // --Used for formatting data before putting it in editing inputs
  const helper = document.createElement('div');
  helper.insertAdjacentHTML('afterbegin', content);
  const mentionsMarkupArray = [];
  const hashtagsMarkupArray = [];
  const linksMarkupArray = [];
  Array.from(helper.querySelectorAll('a.inline-mention')).forEach(node => {
    mentionsMarkupArray.push(
      `[-/-markupStart-/-]people:${node.getAttribute('slug')}[--${node.innerHTML}--]`
    );
  });
  Array.from(helper.querySelectorAll('a.inline-hashtag')).forEach(node => {
    hashtagsMarkupArray.push(
      `[-/-markupStart-/-]hashtags:${node.getAttribute('hashtag-slug')}[--${node.innerHTML}--]`
    );
  });
  Array.from(helper.querySelectorAll('a[target="_blank"]')).forEach(node => {
    linksMarkupArray.push(`${node.getAttribute('title')}`);
  });

  let halfFormattedString = '';
  content.split(/<a class='inline-mention'.*?<\/a>/).forEach((textPart, index) => {
    halfFormattedString += textPart;
    halfFormattedString += mentionsMarkupArray[index] ? mentionsMarkupArray[index] : '';
  });

  let formattedString = '';
  halfFormattedString.split(/<a class='inline-hashtag'.*?<\/a>/).forEach((textPart, index) => {
    formattedString += textPart;
    formattedString += hashtagsMarkupArray[index] ? hashtagsMarkupArray[index] : '';
  });

  let finalFormattedString = '';
  formattedString.split(/<a href="http.*?<\/a>/).forEach((textPart, index) => {
    finalFormattedString += textPart;
    finalFormattedString += linksMarkupArray[index] ? linksMarkupArray[index] : '';
  });
  finalFormattedString = finalFormattedString.split('<br/>').join('\n');

  return htmlspecialchars_decode(finalFormattedString);
};

export const TOOLTIP_CONTENT = isAccountAdmin => {
  if (isAccountAdmin) {
    return 'You\'ve reached your 2GB data limit<br/>and won\'t be able to add any further attachments to your posts.<br/>To increase your data limit you can <a href="/#/settings">upgrade here</a>.';
  }
  return "You've reached your 2GB data limit<br/>and won't be able to add any further attachments to your posts.<br/>Please speak with your Team Lead <br/>or Company Admin to increase your data limit.";
};

export const isIE = () => window.navigator.userAgent.match(/(MSIE|Trident)/);

export const ROTATE_IE_IMAGE = base64image => {
  if (!isIE()) {
    return base64image;
  }

  const img = document.createElement('img');
  img.setAttribute('src', base64image);
  img.src = img.getAttribute('src');

  let orientation;
  window.EXIF.getData(img, () => {
    orientation = window.EXIF.getTag(img, 'Orientation');
  });

  const { width } = img;
  const { height } = img;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (orientation > 4 && orientation < 9) {
    canvas.width = height;
    canvas.height = width;
  } else {
    canvas.width = width;
    canvas.height = height;
  }

  switch (orientation) {
    case 2:
      ctx.transform(-1, 0, 0, 1, width, 0);
      break;
    case 3:
      ctx.transform(-1, 0, 0, -1, width, height);
      break;
    case 4:
      ctx.transform(1, 0, 0, -1, 0, height);
      break;
    case 5:
      ctx.transform(0, 1, 1, 0, 0, 0);
      break;
    case 6:
      ctx.transform(0, 1, -1, 0, height, 0);
      break;
    case 7:
      ctx.transform(0, -1, -1, 0, height, width);
      break;
    case 8:
      ctx.transform(0, -1, 1, 0, 0, width);
      break;
    default:
      break;
  }

  ctx.drawImage(img, 0, 0);

  return canvas.toDataURL('image/jpeg');
};

export const getUrl = path => `${APP_URL}/${path}`;

/**
 * @param {number} id
 * @returns {boolean}
 */
export const isSampleUser = id => +id === 15297;

/**
 * Hook that alerts clicks outside of the passed refs
 * refs can be either an array or a single object
 */
export const useOutsideAlerter = (refs, onClick) => {
  const isRefValid = (ref, event) => ref.current && !ref.current.contains(event.target);

  function handleClickOutside(event) {
    if (Array.isArray(refs)) {
      if (refs.every(ref => isRefValid(ref, event))) {
        return onClick();
      }
    } else if (isRefValid(refs, event)) {
      return onClick();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

export const downloadCsv = (data, name) => {
  let csv = '';
  data.forEach(row => {
    csv += row.join(',');
    csv += '\n';
  });
  const universalBOM = '\uFEFF';
  if (navigator.msSaveBlob) {
    // IE 10+
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    navigator.msSaveBlob(blob, name);
  } else {
    const hiddenElement = document.createElement('a');
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURIComponent(universalBOM + csv)}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = name;
    document.body.appendChild(hiddenElement);
    hiddenElement.click();
    document.body.removeChild(hiddenElement);
  }
};

export const htmlspecialchars_decode = (string, quoteStyle) => {
  let optTemp = 0;
  let i = 0;
  let noquotes = false;

  if (typeof quoteStyle === 'undefined') {
    quoteStyle = 2;
  }
  string = string.toString().replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  const OPTS = {
    ENT_NOQUOTES: 0,
    ENT_HTML_QUOTE_SINGLE: 1,
    ENT_HTML_QUOTE_DOUBLE: 2,
    ENT_COMPAT: 2,
    ENT_QUOTES: 3,
    ENT_IGNORE: 4,
  };
  if (quoteStyle === 0) {
    noquotes = true;
  }
  if (typeof quoteStyle !== 'number') {
    // Allow for a single string or an array of string flags
    quoteStyle = [].concat(quoteStyle);
    for (i = 0; i < quoteStyle.length; i++) {
      // Resolve string input to bitwise e.g. 'PATHINFO_EXTENSION' becomes 4
      if (OPTS[quoteStyle[i]] === 0) {
        noquotes = true;
      } else if (OPTS[quoteStyle[i]]) {
        optTemp |= OPTS[quoteStyle[i]];
      }
    }
    quoteStyle = optTemp;
  }
  if (quoteStyle & OPTS.ENT_HTML_QUOTE_SINGLE) {
    // PHP doesn't currently escape if more than one 0, but it should:
    string = string.replace(/&#0*39;/g, "'");
    // This would also be useful here, but not a part of PHP:
    // string = string.replace(/&apos;|&#x0*27;/g, "'");
  }
  if (!noquotes) {
    string = string.replace(/&quot;/g, '"');
  }
  // Put this in last place to avoid escape being double-decoded
  string = string.replace(/&amp;/g, '&');

  return string;
};

export const getTruncatedTitle = (ref, title) => {
  const e = ref.current;
  if (!e) {
    return null;
  }
  return e.offsetWidth < e.scrollWidth ? title : null;
};

export const getStringFromNumber = number => {
  switch (number) {
    case 1:
      return 'one';
    case 2:
      return 'two';
    case 3:
      return 'three';
    case 4:
      return 'four';
    case 5:
      return 'five';
    default:
      return 'zero';
  }
};

/**
 * @param {string} dueDate
 * @param {string} createdAt
 * @param {string} type
 * @param {string} timeZone
 * @returns {{date: string, color: string}}
 */
export const getFollowUp = (dueDate, createdAt, type, timeZone) => {
  let color = '#11A9FF';
  if (!dueDate) {
    return {
      date: `Created ${moment.utc(createdAt).local().fromNow()}. Add a due date?`,
      color,
    };
  }
  const date =
    type === 'MeetingPreparation'
      ? moment.utc(dueDate).tz(timeZone).endOf('day')
      : moment(dueDate).endOf('day');
  const now = moment();
  let string = '';
  if (date.clone().subtract(14, 'days').isAfter(now)) {
    string = date.format('MMMM Do');
  } else if (date.clone().subtract(6, 'days').isAfter(now)) {
    string = 'next week';
  } else if (date.clone().isAfter(now)) {
    string = date.calendar(null, {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: '[on ]dddd',
    });
    color = '#FF5A26';
  } else {
    return {
      date: `Overdue by ${date.toNow(true)}`,
      color: '#DA553A',
    };
  }

  return {
    date: `Due ${string}`,
    color,
  };
};

export const VALIDATE_FILE = (file, media, allFilesSize) => {
  const type = file.type.split('/')[0];

  if (type === 'video' && media.length > 0) {
    if (media[0].type === 'v') {
      alert('You cannot add a video once there is another video in the post');
    } else {
      alert('You cannot add a video once there are images in the post');
    }
    return false;
  }

  if (type === 'image' && media.length === 1 && media[0].type === 'v') {
    alert('You cannot add an image once there is a video in the post');
    return false;
  }

  if (allFilesSize + file.size > 18 * 1024 * 1024) {
    alert(
      'Your attachments are a bit big... Maximum total size allowed is 18MB. Please reduce your file size or remove some images to continue.'
    );
    return false;
  }

  const maxSize = mimeTypes.VIDEO.includes(file.mimeType ?? file.type) ? 18 : 6;

  if (file.size > maxSize * 1024 * 1024) {
    alert(`One of files is too big! Maximum size allowed is ${maxSize}MB`);
    return false;
  }
  return true;
};

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_+]{8,}/;

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const handleHashRedirect = () => {
  const url = window.location.href;
  if (/\/#\//.test(url)) {
    window.location.replace(url.replace('/#', ''));
    return true;
  }
  return false;
};

export const getIndexOfObjectInArray = (arrayOfObjects, key, condition) => {
  let _index = -1;

  for (let i = 0; i < arrayOfObjects.length; i++)
    if (arrayOfObjects[i][key] === condition) {
      _index = i;
      break;
    }

  return _index;
};
