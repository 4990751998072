import React, { useState } from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  background: #11a9ff;
  width: 100%;
  padding: 20px 70px 20px 20px;
  display: flex;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  margin: auto;
  position: sticky;
  bottom: 0;
  z-index: 5;
  @media (max-width: 1200px) {
    padding-right: 20px;
  }
`;

const Section = styled.div`
  items-align: center;
  margin: auto;
`;

const Description = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  padding-right: 30px;
  margin: auto;
`;

const Button = styled.button`
  display: inline-block;
  background: #ffffff;
  color: #11a9ff;
  padding: 10px 30px;
  font-size: 14px;
  line-height: 16px;
  :hover {
    background: #ffffff;
    color: #11a9ff;
  }
  @media (max-width: 1208px) {
    margin-top: 10px;
  }
`;

const SamplePostsBanner = ({ hideSamplePosts }) => {
  const [disable, setDisable] = useState(false);
  return (
    <Wrapper>
      <Section>
        <Description className="pull-left">
          We’re displaying some sample posts to give an idea of what you can do
          <br />
          on Heelix. You can remove them here. Find them again in your ? support centre.
        </Description>
        <Button
          className="btn btn-primary pull-left"
          onClick={() => {
            if (!disable) {
              setDisable(true);
              hideSamplePosts();
            }
          }}
        >
          Discard
        </Button>
      </Section>
    </Wrapper>
  );
};

export default SamplePostsBanner;
