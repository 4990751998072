import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import qs from 'qs';
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';
import delayUnmounting from '../../../../HOC/delayUnmounting';
import SpaceLogo from '../../../../spaces/SpaceLogo';
import AutotrimString from './AutotrimString';

class DropdownList extends Component {
  state = {
    search: '',
    mounted: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        mounted: true,
      });
    }, 0);
  }

  componentWillReceiveProps(props) {
    if (this.state.mounted !== props.isMounted) this.setState({ mounted: !this.state.mounted });
  }

  getTeams() {
    let teams = [];
    if (this.state.search) {
      teams = this.props.teams.filter(
        team =>
          team.id !== parseInt(this.props.queryString[this.props.teamString]) &&
          team.name.toUpperCase().indexOf(this.state.search.toUpperCase()) > -1
      );
    } else {
      teams = this.props.teams
        .sort(
          (a, b) =>
            this.props.user.recentSpaces.indexOf(a.id) - this.props.user.recentSpaces.indexOf(b.id)
        )
        .filter(
          team =>
            team.id !== parseInt(this.props.queryString[this.props.teamString]) &&
            this.props.user.recentSpaces.includes(team.id) &&
            team.name.toUpperCase().indexOf(this.state.search.toUpperCase()) > -1
        )
        .slice(0, 5);
    }
    return teams;
  }

  render = () => (
    <RootCloseWrapper disabled={!this.state.mounted} onRootClose={() => this.props.toggle()}>
      <div
        className={`teams-dropdown ${this.state.mounted ? 'mounted' : ''}`}
        onClick={e => e.stopPropagation()}
      >
        <h1>
          Select team
          <span className="close-cross" onClick={this.props.toggle} />
        </h1>
        <input
          onChange={e => this.setState({ search: e.target.value })}
          placeholder="Search by typing team name"
        />
        {this.state.search.length === 0 ? <h2>Recent teams</h2> : <h2 />}
        <div className={`teams-list ${this.state.search && 'search'}`}>
          {this.getTeams().map(team => {
            const qsCopy = JSON.parse(JSON.stringify(this.props.queryString));
            qsCopy[this.props.teamString] = team.id;
            return (
              <Link
                className="team-tile"
                to={`${this.props.tabLink}?${qs.stringify(qsCopy)}`}
                onClick={e => {
                  if (!this.props.withRedirect) {
                    e.preventDefault();
                  }
                  this.props.toggle(team.id);
                }}
                key={team.id}
              >
                <SpaceLogo space={team} />
                <span className="team-name">
                  <AutotrimString string={team.name} ending="..." />
                </span>
              </Link>
            );
          })}
        </div>
      </div>
    </RootCloseWrapper>
  );
}

DropdownList.defaultProps = {
  teamString: 'team',
  withRedirect: true,
};

export default delayUnmounting(DropdownList);
