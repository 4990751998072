import React, { Component } from 'react';
import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import qs from 'qs';
import { AVATAR_GENERIC, INITIAL_POST_FILTERS } from 'constants.js';
import { BODY_CLASSES, FORMAT_FILTERS } from 'helpers.js';
import { POST_ACTIONS } from 'redux/actions/postActions';
import { SPACE_ACTIONS } from 'redux/actions/spaceActions';
import debounce from 'lodash/debounce';
import { toggleUserModal } from 'redux/app/actions';
import { USER_ACTIONS } from 'redux/actions/userActions';
import SamplePostsBanner from 'pages/Grow/SamplePostsBanner';
import FeedRightPanel from './FeedRightPanel';
import InformalSpaceModal from '../spaces/InformalSpaceModal';
import NoResultsPage from './NoResultsPage';
import Loader from '../shared/Loader';
import GuestView from './GuestView';
import GuestViewPrivate from './GuestViewPrivate';
import PostsMasonry from './PostsMasonry';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';

const mapDispatchToProps = dispatch => ({
  fetchPosts: () => dispatch(POST_ACTIONS.fetchPosts()),
  setLoadingPosts: () => dispatch(POST_ACTIONS.setLoadingPosts()),
  fetchSpaceDetails: spaceId => dispatch(SPACE_ACTIONS.fetchSpaceDetails(spaceId)),
  addPost: post => dispatch(POST_ACTIONS.addPost(post)),
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  saveScroll: position => dispatch(POST_ACTIONS.saveScroll(position)),
  filtersFromObject: object => dispatch(POST_ACTIONS.filtersFromObject(object)),
  resetFilters: () => dispatch(POST_ACTIONS.resetFilters()),
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
  hideSamplePosts: () => dispatch(USER_ACTIONS.hideSamplePosts()),
});
const mapStateToProps = state => ({
  posts: state.postsDomain.posts,
  fetchingPosts: state.postsDomain.fetchingPosts,
  hasMorePostsToLoad: state.postsDomain.hasMorePostsToLoad,
  loadedPostsCount: state.postsDomain.loadedPostsCount,
  scrollPosition: state.postsDomain.scrollPosition,
  filters: state.postsDomain.filters,

  user: state.usersDomain.user,

  spaces: state.spacesDomain.spaces,
  teams: state.teamsDomain.teams,
  team: state.teamsDomain.team,

  spaceId: state.usersDomain.spaceId,
  spaceMembers: state.usersDomain.spaceMembers,

  editedSpaceId: state.spacesDomain.editedSpaceId,
});

class SpaceFeed extends Component {
  constructor(props) {
    super(props);
    this.firstName = this.props.user ? this.props.user.firstName : ''; // LOCALSTORAGE_GET('user_first_name');
    this.image =
      this.props.user && this.props.user.image_uri ? this.props.user.image_url : AVATAR_GENERIC; // LOCALSTORAGE_GET('user_image_uid');
    this.state = {
      loaded: false,

      postRenderAlignment: false,

      showCroppie: false,
      imageCroppieUrl: '',
      croppieMounted: false,
      showFixModal: false,
      mediaUrl: [],
      subjects: [],
      subject: '',
      showShoutoutModal: false,
      showEditModal: false,
      vibeTileLoaded: false,
    };
    this.vibe_factors = [];
    this.links = [];
    this.scale = null;
    this.raw_text = [];
    this.images = [];
    this.enable_vibe_comments = [];
  }

  initialFilters = {
    ...INITIAL_POST_FILTERS,
    space: this.props.spaceId,
  };

  componentDidUpdate(props) {
    const filtersCopy = JSON.parse(JSON.stringify(this.props.filters));
    const qsFilters = qs.parse(window.location.href.split('?')[1]);
    if (window.location.href.split('?')[1] && window.location.href.indexOf('feed') > 0) {
      Object.keys(filtersCopy).map(key => {
        filtersCopy[key] = qsFilters[key] ? qsFilters[key] : this.initialFilters[key];
      });
      if (
        JSON.stringify({
          ...filtersCopy,
          excludedSpaces: this.props.filters.excludedSpaces,
        }) !== JSON.stringify(this.props.filters)
      ) {
        const isValidIdTeamId = this.props.spaces.find(team => team.id == filtersCopy.space);
        if (isValidIdTeamId) {
          this.props.setLoadingPosts();
          this.debouncedFetch(filtersCopy);
        } else {
          this.props.setErrorPage(404);
        }
      }
    }
  }

  handleFetch = filtersCopy => {
    this.props.fetchSpaceDetails(filtersCopy.space);
    this.props.filtersFromObject({
      ...filtersCopy,
      excludedSpaces: this.props.filters.excludedSpaces,
    });
    this.props.resetPosts();
    this.props.fetchPosts();
  };

  debouncedFetch = debounce(this.handleFetch, 1000, { leading: true });

  fetchTriggerThreshold = 1000;

  getParameterByName(name, url) {
    if (!url) url = document.location;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  componentDidMount() {
    BODY_CLASSES(['vibes', 'index', 'jobvibe-future']);

    this.props.closeFlash();
    window.onscroll = () => {
      if (
        this.props.loadedPostsCount !== 0 &&
        window.innerHeight + window.scrollY + this.fetchTriggerThreshold >=
          document.body.offsetHeight &&
        !this.props.fetchingPosts &&
        this.props.hasMorePostsToLoad &&
        window.scrollY > 0
      ) {
        // --last condition prevent fetch on popstate
        this.props.fetchPosts();
      }
    };
    scroll.scrollTo(this.props.scrollPosition, { duration: 0 });
  }

  componentWillUnmount() {
    this.props.saveScroll(window.scrollY);
    window.onscroll = () => {};
    this.props.resetFilters();
  }

  tileRealignHandler() {
    // --Simply push new state to trigger rendering
    this.setState({ postRenderAlignment: true });
  }

  // ---Hashtags listener and filter apply
  hashtagClickedHandler = e => {
    e.preventDefault();
    this.props.history.push(
      `feed?${qs.stringify(
        FORMAT_FILTERS(e.currentTarget, {
          space: this.props.filters.space,
          types: [],
          hashtags: [],
          string: '',
        })
      )}`
    );
  };

  //--------------------
  handleShowModal = () => {
    if (!this.props.editedSpaceId) {
      this.props.getEditedSpace(this.props.spaceId);
    }
    this.setState({ showEditModal: true });
  };

  closeEditModalHandler = () => {
    this.setState({ showEditModal: false });
  };

  mentionClickedHandler = e => {
    e.preventDefault();
    const mentionedId = parseInt(e.currentTarget.getAttribute('user-id'));
    if (mentionedId === this.props.user.id) {
      this.props.history.push(`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`);
    } else {
      return this.props.toggleUserModal(true, mentionedId);
    }
  };

  shouldShowPost = spaceId => {
    const { spaces } = this.props;
    return spaces.map(space => space.id).includes(spaceId);
  };

  render() {
    return (
      <div className="vibe-index">
        <div className="heelix-feed-wrap">
          <div className="create-and-masonry-wrap">
            <div className="row post-feed-wrap">
              {this.props.user.should_see_sample_data &&
                this.props.team.name &&
                !this.props.spaceMembers.find(member => member.id === this.props.user.id) &&
                (!this.props.team.private ? <GuestView /> : <GuestViewPrivate />)}
              {this.props.spaceMembers.find(member => member.id === this.props.user.id) &&
              !this.props.posts.length &&
              !this.props.hasMorePostsToLoad &&
              !this.props.user.should_see_sample_data ? (
                <NoResultsPage user={this.props.user} space={this.props.team} />
              ) : (
                <PostsMasonry
                  posts={this.props.posts}
                  tileRealignHandler={this.tileRealignHandler.bind(this)}
                  user={this.props.user}
                  hasMorePostsToLoad={this.props.hasMorePostsToLoad}
                  shouldShowPost={this.shouldShowPost}
                  mentionClickedHandler={this.mentionClickedHandler}
                  vibeTile=""
                />
              )}
              {this.props.hasMorePostsToLoad ? <Loader /> : ''}
            </div>
            {!this.props.user.hideSamplePosts && this.props.team.account_default_space && (
              <SamplePostsBanner hideSamplePosts={() => this.props.hideSamplePosts()} />
            )}
          </div>
          {this.props.team &&
            this.props.user.leadTeamsIds.includes(parseInt(this.props.filters.space)) && (
              <InformalSpaceModal
                showInformalModal={this.state.showEditModal}
                mode="edit"
                space={this.props.team}
                spaceId={this.props.team.id}
                closeInformalModalHandler={this.closeEditModalHandler}
              />
            )}
          <FeedRightPanel
            home={false}
            showModal={this.handleShowModal}
            advSearchVisible={
              !this.props.hasMorePostsToLoad &&
              this.props.posts.length === 0 &&
              this.props.filters.string
            }
            updateFlash={this.props.updateFlash}
            isDefaultSpace={this.props.team.account_default_space}
            resetSpaceView={this.handleFetch}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpaceFeed);
