import React, { Component } from 'react';
import Auxiliary from '../layouts/Auxiliary';
import { AWS_S3_URL } from '../../constants';

class NoResultsPage extends Component {
  render() {
    return (
      <Auxiliary>
        <div className="col-xs-12 text-center">
          <img src={`${AWS_S3_URL}public/images/icons/no-fixes-img.png`} alt="" />
        </div>
        <div className="col-xs-12 text-center no-fixes-page">
          <h1>No results found</h1>
          <h2>Are you sure you're looking in the right place?</h2>
        </div>
      </Auxiliary>
    );
  }
}

export default NoResultsPage;
