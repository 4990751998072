import mimeTypes from '../GooglePicker/mimeTypes';

const verifyVideo = (files, mediaUrl, typeProperty = 'type') => {
  const media = [];
  for (let i = 0; i < files.length; i++) {
    if (!mimeTypes.FILES.includes(files[i][typeProperty])) {
      media.push(files[i]);
    }
  }
  for (let i = 0; i < media.length; i++) {
    const file = media[i];

    if (
      !(
        (mimeTypes.IMAGE.includes(file[typeProperty]) &&
          !(mediaUrl[0] && mediaUrl[0].type === 'v')) ||
        (mimeTypes.VIDEO.includes(file[typeProperty]) && mediaUrl.length === 0)
      ) ||
      (mimeTypes.VIDEO.includes(file[typeProperty]) && media.length > 1)
    ) {
      alert('You may attach either one video or few images.');
      return false;
    }
  }

  return true;
};

export default verifyVideo;
