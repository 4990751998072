import React, { Component } from 'react';
import striptags from 'striptags';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { toggleUserModal } from 'redux/app/actions';
import styled from '@emotion/styled';
import PostDetails from 'components/shared/posts/PostDetails';
import pin from 'images/feed/pin.svg';
import AuthorInfo from 'components/shared/posts/AuthorInfo';
import ShoutoutStarImage from 'components/shared/ShoutoutStarImage';
import CommentInput from '../post/shared/CommentInput';
import PostDropdown from './PostDropdown';
import PostTileComments from './PostTileComments';
import { POST_ACTIONS } from '../../redux/actions/postActions';
import { AVATAR_ERROR } from '../../helpers';
import favActiveBtn from '../../images/feed/star_active.png';
import favBtn from '../../images/feed/star.png';
import FavPostTooltip from '../grow/tooltips/FavPostTooltip';
import TeamShoutoutLogo from '../shared/posts/TeamShoutoutLogo';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import qs from 'qs';
import * as Layout from '../../pages/Grow/NoteLayout';
import { jsx } from '@emotion/core';

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  hashtags: state.tagsDomain.hashtags,
});

const mapDispatchToProps = dispatch => ({
  pinPost: postId => dispatch(POST_ACTIONS.pinPost(postId)),
  favPost: (postId, type) => dispatch(POST_ACTIONS.favPost(postId, type)),
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
});

const ShoutoutSelect = styled.div`
  float: right;
  position: relative;
`;
const CheckboxWrapper = styled.div`
  input[type='checkbox']:checked + label:before {
    background-color: #11a9ff !important;
    border-color: #11a9ff !important;
  }
  input[type='checkbox']:focus + label::before {
    outline: none !important;
    margin-top: -5px !important;
    margin-left: -15px !important;
  }
  label:after {
    width: 20px !important;
    height: 20px !important;
    margin-top: -5px !important;
    margin-left: -15px !important;
    padding-top: 3px !important;
    font-size: 15px !important;
    line-height: 25px;
  }
`;

const CheckboxLabel = styled.label`
  font-size: 13px;
  text-decoration: none;
  color: #11a9ff;
  cursor: pointer;
  line-height: 25px;
  &:before {
    width: 20px !important;
    height: 20px !important;
    border: 2px solid #11a9ff !important;
    background: transparent !important;
    top: 6px !important;
    margin-top: -5px !important;
    margin-left: -15px !important;
  }
`;

class ShoutoutTile extends Component {
  state = {
    errorAction: null,
    unfollowVisible: false,
  };

  componentDidMount() {
    this.setState({
      tags: this.props.tags,
      unfollowVisible: true, // this.props.auth.id !== this.props.fix.user.id,
    });
  }

  render() {
    const { exportModeOn, isPostSelected, handleSelectPost, post, hasDeleteError } = this.props;
    let shoutoutRecipientImage = null;
    let shoutoutRecipientName = '';
    if (post.shoutout.recipient) {
      shoutoutRecipientImage = post.shoutout.recipient.image_url;
      shoutoutRecipientName = `${post.shoutout.recipient.first_name} ${post.shoutout.recipient.last_name}`;
    } else {
      shoutoutRecipientImage = post.shoutout.recipient_team.image_url;
      shoutoutRecipientName = post.shoutout.recipient_team.name;
    }

    let content = (
      <>
        <div className="shoutout-receiver">
          {shoutoutRecipientImage ? (
            <ShoutoutStarImage image={shoutoutRecipientImage} />
          ) : (
            <TeamShoutoutLogo thumbnailDetails={post.shoutout.recipient_team.thumbnail_details} />
          )}
          {post.subtype === 'SuperShoutout' ? (
            <h3 className="buzz-shoutout-title">
              <Link
                to=""
                onClick={e => {
                  e.preventDefault();
                  if (post.shoutout.recipient.id === this.props.user.id) {
                    this.props.history.push(
                      `/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`
                    );
                  } else {
                    return this.props.toggleUserModal(true, post.shoutout.recipient.id);
                  }
                }}
              >
                {shoutoutRecipientName}
              </Link>
              :&nbsp;{post.name}
            </h3>
          ) : (
            <h3 className="buzz-shoutout-title">
              Shoutout to&nbsp;
              <Link
                to=""
                onClick={e => {
                  e.preventDefault();
                  if (post.shoutout.recipient) {
                    if (post.shoutout.recipient.id === this.props.user.id) {
                      this.props.history.push(
                        `/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`
                      );
                    } else {
                      return this.props.toggleUserModal(true, post.shoutout.recipient.id);
                    }
                  } else {
                    this.props.history.push(`/post/${post.id}`);
                  }
                }}
              >
                {shoutoutRecipientName}
              </Link>
            </h3>
          )}
        </div>
        <div
          className="post-tile-content shoutout-buzz-post"
          dangerouslySetInnerHTML={{
            __html: striptags(post.content, ['a', 'br']),
          }}
        />
        {post.shoutout && post.shoutout.company_values.length > 0 && (
          <div className="tags-wrapper">
            {post.shoutout.company_values.map(value => (
              <span key={value.id}>{value.name}&nbsp;</span>
            ))}
          </div>
        )}
        <PostDetails post={post} showSpaceInfo={this.props.showSpaceInfo} />
      </>
    );
    const rolePosition = [];
    if (post.user.role !== '') {
      rolePosition.push(post.user.role);
    }
    rolePosition.push(post.user.team.name);

    if (post.id > 0) {
      content = <Link to={`/post/${post.id}`}>{content}</Link>;
    }

    const notAuthorInfo = hasDeleteError && post.shoutout.author_id !== this.props.user.id;
    return (
      <div className="col-lg-6 col-xs-12 post-tile-wrap initial-hide">
        <div className="post-tile">
          <div className="post-tile-header">
            <div className="author-avatar">
              <img
                alt={`${post.user.first_name} ${post.user.last_name}`}
                src={post.user.image_url}
                onError={e => AVATAR_ERROR(e.target)}
              />
            </div>
            <AuthorInfo post={post} user={this.props.user} />
            {this.props.type !== 'growPost' && post.pinned ? (
              <div
                className="pinned-post"
                onClick={
                  this.props.user.leadTeamsIds.includes(post.audience_id) && post.id > 0
                    ? () => this.props.pinPost(post.id)
                    : null
                }
              >
                <img src={pin} />
              </div>
            ) : null}
            <img
              src={post.favourite ? favActiveBtn : favBtn}
              data-tip
              data-for={`post_fav_status${post.id}`}
              className={`heart ${post.favourite ? 'marked' : ''}`}
              onClick={() => this.props.favPost(post.id, this.props.type)}
              alt=""
            />
            {exportModeOn ? (
              <ShoutoutSelect>
                <CheckboxWrapper className="checkbox checkbox-primary">
                  <input
                    type="checkbox"
                    checked={isPostSelected}
                    id={`select-${post.id}`}
                    name={`select-${post.id}`}
                    className={`select-${post.id}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSelectPost(this.props.postId)}
                  />
                  <CheckboxLabel
                    htmlFor={`select-${post.id}`}
                    className="boolean"
                    id={`select-${post.id}-label`}
                  />
                </CheckboxWrapper>
                {notAuthorInfo && (
                  <Layout.NotAuthorInfo>You are not the author of this post</Layout.NotAuthorInfo>
                )}
              </ShoutoutSelect>
            ) : (
              <PostDropdown type={this.props.type} post={post} />
            )}
          </div>
          <div className="post-tile-body">
            {content}
            <CommentInput
              post={post}
              tileRealignHandler={this.props.tileRealignHandler}
              type={this.props.type}
            />
          </div>
          <div className="post-tile-comments-section">
            <div className="post-tile-comments">
              <PostTileComments post={post} user={this.props.user} type={this.props.type} />
            </div>
          </div>
          <FavPostTooltip postId={post.id} isFavourite={post.favourite} />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShoutoutTile));
