/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import ValuesPicker from 'components/vibes/createForms/CreateNote/ValuesPicker';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectPicker from 'components/vibes/createForms/CreateNote/SelectPicker';

export const inputStyle = css`
  width: 50%;
  box-sizing: border-box;
  border: 1px solid rgb(237, 237, 237);
  height: 42px;
  background-color: #ffffff;
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  padding: 0 16px;
  ::placeholder {
    color: #c4c4c4;
  }
`;

export const Input = styled.input`
  ${inputStyle}
`;

const Wrap = styled.div`
  input {
    ${inputStyle}
    ::placeholder {
      color: #c4c4c4 !important;
      opacity: 1;
    }
  }
`;
export const SelectWrap = styled.div`
  .Select-input {
    padding: 0 !important;
  }
  .Select-placeholder {
    color: #c4c4c4 !important;
  }
`;

const ValuesAndGoals = ({
  initialValues,
  companyValues,
  setCompanyValues,
  initialGoals,
  goals,
  setGoals,
  valuesToggle,
  goalsToggle,
}) => {
  return (
    <Wrap>
      {valuesToggle && (
        <SelectPicker
          placeholder="Tag a related company value (optional) "
          values={companyValues}
          setValues={setCompanyValues}
          initialValues={initialValues}
        />
      )}
      {goalsToggle && (
        <ValuesPicker
          placeholder="Tag a related goal or KPI (if applicable) "
          values={goals}
          setValues={setGoals}
          initialValues={initialGoals}
        />
      )}
    </Wrap>
  );
};

ValuesAndGoals.propTypes = {
  companyValues: PropTypes.array.isRequired,
  setCompanyValues: PropTypes.func.isRequired,
  goals: PropTypes.array.isRequired,
  setGoals: PropTypes.func.isRequired,
  valuesToggle: PropTypes.bool.isRequired,
  goalsToggle: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  initialValues: state.tagsDomain.companyValues,
  initialGoals: state.tagsDomain.goals,
});

export default connect(mapStateToProps)(ValuesAndGoals);
