import React, { Component } from 'react';

class IssuesUserTile extends Component {
  state = {
    mounted: false,
  };

  dragStart = e => {
    if (!this.props.dragHelperVariables.draggedUserDOM) {
      e.stopPropagation();
      document.body.classList.add('dragging');
      Array.from(document.querySelectorAll('.drag-capture-area')).forEach(node => {
        node.classList.add('active');
      });
      const element = e.currentTarget;
      const elementX = element.getBoundingClientRect().left;
      const elementY = element.getBoundingClientRect().top;
      this.props.setDragHelperVariables({
        dragHelperVariables: {
          draggedUserDOM: element,
          draggedUserData: this.props.user,
          draggedUsersData: [this.props.user],
          dragStartX: e.clientX,
          dragStartY: e.clientY,
          draggedElementStartX: elementX,
          draggedElementStartY: elementY,
        },
      });
      element.style.width = `${element.getBoundingClientRect().width}px`;
      element.parentNode.style.height = window
        .getComputedStyle(element.parentNode)
        .getPropertyValue('height');
      element.style.top = `${element.getBoundingClientRect().top}px`;
      element.style.left = `${element.getBoundingClientRect().left}px`;
      element.classList.add('stop-position-transition', 'dragged');
      setTimeout(() => {
        element.classList.remove('stop-position-transition');
        element.style.position = 'fixed';
        element.style.transform = 'rotateZ(8deg)';
        element.style.top = `${elementY + 52}px`;
        element.style.left = `${elementX}px`;
      }, 0);
    }
  };

  componentDidMount() {
    setTimeout(() => this.setState({ mounted: true }), 5);
  }

  render() {
    return (
      <div className={`user-tile-background ${this.state.mounted ? 'mounted' : ''}`}>
        <div className="user-tile" onMouseDown={this.dragStart}>
          <span className="user-name">
            {this.props.user.first_name} {this.props.user.last_name}
          </span>
          {this.props.user.is_lead ? <span className="lead-tooltip">LEAD</span> : ''}
          {this.props.team.parent_ids.length !== 0 ? (
            <span
              className={`invite-checkbox ${this.props.sendingInvitations ? 'disabled' : ''} ${
                !this.props.user.marked_to_invite ? '' : 'selected'
              } ${this.props.user.state === 'active' ? 'already-invited' : ''}`}
              onMouseDown={e => e.stopPropagation()}
              onClick={() =>
                !this.props.sendingInvitations && this.props.user.state !== 'active'
                  ? this.props.markInvitedUsers(null, this.props.user)
                  : null
              }
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

export default IssuesUserTile;
