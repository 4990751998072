import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const FaqSection = ({ openContactModal }) => (
  <div className="faq">
    <div className="faq-header row">
      <div className="col-xs-12 faq-title">FAQ</div>
    </div>
    <div className="row">
      <div className="col-xs-12 col-md-6 ">
        <div className="question">
          <div className="question-title">How do you count active users?</div>
          <div className="question-body">
            You only pay for a user once they have accepted their invite to Heelix. You can
            deactivate users at any time and your bill will be adjusted accordingly. We also offer
            the ability to pay by user bracket to give you more flexibility.
          </div>
        </div>
        <div className="question">
          <div className="question-title">Do I have to sign a contract?</div>
          <div className="question-body">
            No. You upgrade or cancel your subscription as you need.
          </div>
        </div>
        <div className="question">
          <div className="question-title">Can I get a discount if I pay annually?</div>
          <div className="question-body">
            Absolutely. Check out our <Link to="/payments/choose_plan">pricing page</Link> to learn
            more.
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-md-6 ">
        <div className="question">
          <div className="question-title">Can I add or remove users at any time?</div>
          <div className="question-body">
            If paying monthly you can easily add or remove users at any time. If you’re paying
            annually you will need to upgrade your user bracket and pay the difference, which is
            adjusted for the remaining months of your subscription.
          </div>
        </div>
        <div className="question">
          <div className="question-title">What if I decide to cancel my subscription?</div>
          <div className="question-body">
            Just go to the billing settings under your account and click the cancel link.
          </div>
        </div>
        <div className="question">
          <div className="question-title">Have more questions?</div>
          <div className="question-body">
            We're here to help. You can get in touch with us anytime at&nbsp;
            <a
              className="more-question"
              href="mailto:support@heelix.com?subject=Question about Heelix"
            >
              support@heelix.com
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);
FaqSection.propTypes = {
  openContactModal: PropTypes.func,
};

export default FaqSection;
