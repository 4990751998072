import React from 'react';
import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { FORMAT_FILTERS } from 'helpers';
import { growNavigationList, growProfileUrl } from 'pages/Grow/GrowProfile/constants';

const NavigationsList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 36px;
`;

const NavigateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  outline: none;
  background: initial;
  padding: 0;
`;
const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: ${props => (props.isSelected ? '#11a9ff' : 'white')};
  & > svg {
    & > rect,
    & > path {
      fill: ${({ type, isSelected }) =>
        type !== 'achievements' ? (isSelected ? 'white' : '#A6A6A6') : 'none'};
      stroke: ${({ type, isSelected }) =>
        type === 'achievements' ? (isSelected ? 'white' : '#A6A6A6') : 'none'};
    }
    & > svg > path {
      fill: ${props => (props.isSelected ? '#11a9ff' : 'white')};
    }
  }
`;

const NavigationTitle = styled.span`
  margin-left: 12px;
  font-weight: ${props => (props.isSelected ? 'bold' : 'normal')};
  font-size: 14px;
  color: ${props => (props.isSelected ? '#11a9ff' : '#A6A6A6')};
`;

const NavigationElement = styled.li`
  margin-bottom: 18px;
  '&:last-child': {
    margin-bottom: 0;
  }
`;

const GrowProfileNavigation = ({ history, filters }) => {
  return (
    <NavigationsList>
      {growNavigationList.map(navigationEl => {
        const currentTab = qs.parse(window.location.search, { ignoreQueryPrefix: true }).tab;
        const isSelected =
          (!currentTab && navigationEl.filter === 'grow') || currentTab === navigationEl.filter;
        return (
          <NavigationElement key={navigationEl.id}>
            <div
              filter-type="new-grow-type"
              type-filter={navigationEl.filter}
              id={navigationEl.filter}
              onClick={e =>
                history.push(
                  `/${growProfileUrl}?${
                    qs.stringify(FORMAT_FILTERS(e.currentTarget, filters)) || ' '
                  }`
                )
              }
            >
              <NavigateButton>
                <IconWrapper isSelected={isSelected} type={navigationEl.filter}>
                  <navigationEl.icon />
                </IconWrapper>
                <NavigationTitle isSelected={isSelected}>{navigationEl.title}</NavigationTitle>
              </NavigateButton>
            </div>
          </NavigationElement>
        );
      })}
    </NavigationsList>
  );
};

export default withRouter(GrowProfileNavigation);
