import React from 'react';
import { AWS_S3_URL } from 'constants.js';
import styled from '@emotion/styled';
import OtherSection from './OtherSection';
import SubmitButton from './SubmitButton';
import PageSelectorSection from './PageSelectorSection';

const MessageTitle = styled.span`
  font-weight: bold;
  color: #323232;
`;

const MessageBody = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #6a6a6a;
  margin-bottom: 6px;
  padding: 0 28px;
`;

export default class WhyFactorsScreen extends React.Component {
  render() {
    const factors = this.props.getFactors();
    const emptyWhyFactors = this.props.whyFactors.length === 0;
    const isPositiveVibe = this.props.type === 'good';
    return (
      <div className="why-factors-screen">
        <div className="panel-header bold centered u-size2">
          <a
            className="btn btn-previous"
            id="prev-step-btn"
            onClick={() => this.props.previousStep()}
          >
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5448 6.30927H3.36588L8.24388 1.67799C8.56792 1.37034 8.56792 0.871528 8.24388 0.563872C7.91983 0.256216 7.39445 0.256216 7.0704 0.563872L0.90966 6.41301C0.739581 6.57449 0.65877 6.78863 0.667227 7.00014C0.659113 7.20795 0.737154 7.41822 0.901351 7.57854C0.950749 7.64243 1.00975 7.69852 1.07618 7.74465L7.07043 13.4357C7.39448 13.7434 7.91986 13.7434 8.24391 13.4357C8.56795 13.1281 8.56795 12.6292 8.24391 12.3216L3.57083 7.88487H16.5448C16.9799 7.88487 17.3326 7.53216 17.3326 7.09707C17.3326 6.66198 16.9799 6.30927 16.5448 6.30927Z"
                fill="#11A9FF"
              />
            </svg>
          </a>
          <span>
            {emptyWhyFactors ? (
              <MessageTitle>
                {isPositiveVibe ? 'Awesome! Glad to hear it' : 'We hope all is ok'}
              </MessageTitle>
            ) : (
              this.props.title
            )}
          </span>
        </div>
        {emptyWhyFactors ? (
          <div className="panel-body u-padding-vertical-none message-body-wrap">
            <MessageBody>
              {isPositiveVibe
                ? 'If you have more to say and want this to go direct to the lead of your team, ' +
                  "use the box below. It's completely anonymous."
                : "We won't pry but if you have more to say and want this to go direct to the lead of your team, " +
                  "use the box below. It's completely anonymous."}
            </MessageBody>
          </div>
        ) : (
          <div className="panel-body u-padding-vertical-none">
            <div className="centered u-margin-bottom-sm">
              <div className="vibe-factors">{factors}</div>
            </div>
          </div>
        )}
        {this.props.enable_vibe_comments && (
          <OtherSection
            emptyWhyFactors={emptyWhyFactors}
            handleMessageChange={this.props.setMessageState}
            value={this.props.message}
            disabled={this.props.factors.find(factor => factor.selected)}
          />
        )}
        {(this.props.message || emptyWhyFactors) && (
          <SubmitButton
            submit={emptyWhyFactors}
            nextStep={() => this.props.nextStep()}
            disabledSubmitButton={this.props.disabledSubmitButton}
          />
        )}
        <PageSelectorSection step={emptyWhyFactors ? 4 : 3} />
        <div className="clear text-muted u-sizevr-1 u-padding-vertical-xxs vibe-anon">
          <span className="seen-by">{this.props.raw_text.seen_by_you}</span>
          <span className="anonymous-vibe" onClick={() => this.props.showAnonDescModal()}>
            <img
              src={`${AWS_S3_URL}public/images/public/glasses.png`}
              className="icon"
              alt=""
              style={{ width: '24px' }}
            />
            &nbsp;
            {this.props.raw_text.your_vibe_is_anonymous}
          </span>
        </div>
      </div>
    );
  }
}
