import React, { Component } from 'react';
import delayUnmounting from '../../../../HOC/delayUnmounting';

class FactorsTooltipHorizontal extends Component {
  state = {
    mounted: false,
  };

  componentDidMount() {
    // ---Flex options do not allow for auto width match in flex which size depends on it's children
    // ---Workaround below - set width to match the widest element
    const innerElements = this.self.querySelectorAll('span');
    const commonWidth = Array.from(innerElements).reduce((currentMax, element) => {
      const elementWidth = parseInt(window.getComputedStyle(element).getPropertyValue('width'), 10);
      return currentMax > elementWidth ? currentMax : elementWidth;
    }, 0);
    Array.from(innerElements).forEach(element => (element.style.width = `${commonWidth}px`));
    setTimeout(() => {
      this.setState({
        mounted: true,
      });
    }, 0);
  }

  componentWillReceiveProps(props) {
    if (this.state.mounted !== props.isMounted) this.setState({ mounted: !this.state.mounted });
  }

  render() {
    return (
      <div
        ref={el => (this.self = el)}
        className={`factors-tooltip ${this.state.mounted ? 'mounted' : ''}`}
      >
        <span className="terrible">{this.props.data.terrible}</span>
        <span className="bad">{this.props.data.bad}</span>
        <span className="okay">{this.props.data.okay}</span>
        <span className="good">{this.props.data.good}</span>
        <span className="awesome">{this.props.data.awesome}</span>
      </div>
    );
  }
}

export default delayUnmounting(FactorsTooltipHorizontal);
