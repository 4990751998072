import React, { useEffect, useState } from 'react';
import LoadingOverlay from './LoadingOverlay';
import cookieSignIn from './cookieSignIn';
import ErrorPage from 'components/signup/ErrorPage';

const CookieLogin = ({ history }) => {
  const [error, setError] = useState('');

  useEffect(() => {
    cookieSignIn(() => history.push('/'), setError);
  }, []);

  return error ? (
    <ErrorPage title="Something went wrong" body={error} />
  ) : (
    <div className="welcome">
      <LoadingOverlay toggled message="" height="100%" />
    </div>
  );
};

export default CookieLogin;
