import { css } from '@emotion/core';

export const noScrollBar = css`
  ::-webkit-scrollbar {
    width: 0px;
  }
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  -ms-overflow-style: none;
`;
