import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { APP_URL } from 'constants.js';

const Container = styled.div`
  margin: 20px auto 0;
  height: 50px;
  max-width: 380px;
  div:focus {
    outline: none;
    rect {
      stroke: #1297e3;
    }
  }
  div {
    max-width: 380px !important;
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
  }
`;

const AppleLogin = ({ state }) => {
  useEffect(() => {
    window.AppleID.auth.init({
      clientId: 'com.winning.heelix',
      scope: 'name email',
      // redirectURI: `http://dev.coderspeak.com:9001/apple_redirect`, //for local debugging
      redirectURI: `${APP_URL}/apple_redirect`,
      state,
    });
  }, []);

  return (
    <Container
      id="appleid-signin"
      className="signin-button"
      data-color="white"
      data-border="true"
      data-type="sign in"
    />
  );
};

AppleLogin.propTypes = {
  state: PropTypes.string,
};

export default AppleLogin;
