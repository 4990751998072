import React from 'react';

import HeelixLogoSmall from '../../images/vibe/heelix_logo_small.png';

export default class LoadingScreen extends React.Component {
  render() {
    return (
      <div className="panel-body loading-screen">
        <div className="final-screen-logo">
          <div className="centered bold u-size1">
            <div className="loader text-center vibe_new">
              <img src={HeelixLogoSmall} alt="" className="shadowfilter" />
            </div>
            {this.props.raw_text.sending_your_vibe}
          </div>
        </div>
      </div>
    );
  }
}
