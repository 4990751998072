/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import styled from '@emotion/styled';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';

const List = styled.ul`
  list-style: none;
  padding-inline-start: 17px;
  padding-bottom: 11px;
  text-indent: -21px;
  margin-bottom: 0;
  word-break: break-word;
`;

const Point = styled.li`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  :not(:last-of-type) {
    margin-bottom: 18px;
  }
  :before {
    content: '─';
    color: #aaaaaa;
    padding-right: 11px;
  }
`;

const Count = styled.span`
  font-weight: bold;
`;

const ListWrap = ({ steps }) => (
  <List>
    {steps.map(step => (
      <Point key={step.id}>
        {step.content}
        {Boolean(step.count) && <Count> ({step.count})</Count>}
      </Point>
    ))}
  </List>
);

ListWrap.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
      count: PropTypes.number,
    }).isRequired
  ).isRequired,
};

export default ListWrap;
