/** @jsx jsx */
import React from 'react';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/core';
import { ReactComponent as Add } from 'components/layouts/Navigation/add.svg';

const Label = styled.div`
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  color: #11a9ff;
`;

const Wrapper = styled.div`
  height: 70px;
  text-align: center;
  cursor: pointer;
`;
const AddQuestionButton = ({ addQuestion }) => {
  return (
    <Wrapper onClick={() => addQuestion()}>
      <Add
        css={css`
          height: 100%;
          vertical-align: middle;
          margin-right: 13px;
        `}
      />
      <Label>Add question</Label>
    </Wrapper>
  );
};

export default AddQuestionButton;
