import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ReCaptcha } from 'components/shared/Recaptcha';
import WelcomeEmail from './WelcomeEmail';
import { APP_URL, CAPTCHA_KEY } from '../../constants';
import { HEADERS } from '../../helpers';
import steps from './steps';

const WelcomeEmailContainer = ({ handleEmail, handleGoogle, history }) => {
  const captcha = useRef(null);
  const [isFetching, setIsFetching] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const emailRef = useRef('');

  const handleLookupMail = async token => {
    const email = emailRef.current;
    const headers = HEADERS();
    const response = await fetch(
      `${APP_URL}/lookup?email=${encodeURIComponent(email)}&captcha_token=${encodeURIComponent(
        token
      )}`,
      {
        method: 'get',
        headers,
      }
    );
    const json = await response.json();

    handleEmail(json);
    setIsFetching(false);
  };

  const onGoogleFailure = () => {
    history.push(`/login?step${steps.welcomeError}`);
  };
  const onGoogleSuccess = async data => {
    setIsFetching(true);
    const response = await fetch(`${APP_URL}/lookup_google?token=${data.accessToken}`);
    const json = await response.json();
    handleGoogle(json, data);
  };

  const onLoadRecaptcha = () => {
    if (captcha.current) {
      captcha.current.reset();
    }
  };

  const verifyCallback = token => {
    setIsFetching(true);
    setCaptchaToken(token);
    handleLookupMail(token);
    captcha.current.reset();
  };

  const challengeCaptcha = e => {
    e.preventDefault();
    const tempEmail = e.target.email.value;
    emailRef.current = tempEmail;
    captcha.current.execute();
  };

  return (
    <div>
      <WelcomeEmail
        lookupMail={challengeCaptcha}
        isFetching={isFetching}
        googleFailure={onGoogleFailure}
        googleResponse={onGoogleSuccess}
        verifyCallback={verifyCallback}
        captchaToken={captchaToken}
      />
      <ReCaptcha
        ref={captcha}
        size="invisible"
        render="explicit"
        sitekey={CAPTCHA_KEY}
        onloadCallback={onLoadRecaptcha}
        verifyCallback={verifyCallback}
      />
    </div>
  );
};

WelcomeEmailContainer.propTypes = {
  handleEmail: PropTypes.func.isRequired,
  handleGoogle: PropTypes.func.isRequired,
};

export default withRouter(WelcomeEmailContainer);
