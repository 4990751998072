import React from 'react';
import styled from '@emotion/styled';

const CheckboxLabel = styled.label`
  font-size: 14px;
  line-height: 32px;
  font-weight: normal;
  position: relative;
  cursor: pointer;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 29px;
`;

const CustomCheckbox = styled.span`
  margin-top: 7px;
  background: #f9fdff;
  border: 1px solid #c8ebff;
  box-sizing: border-box;
  border-radius: 1px;
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  :after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  :checked ~ span {
    background-color: #11a9ff;
  }
  :checked ~ span:after {
    display: block;
  }
`;

const Checkbox = ({ option, value, setValue }) => {
  return (
    <CheckboxLabel>
      {option.description}
      <CheckboxInput
        type="checkbox"
        value={option.key}
        checked={option.key === value}
        onClick={() => setValue(option.key)}
      />
      <CustomCheckbox />
    </CheckboxLabel>
  );
};
export default Checkbox;
