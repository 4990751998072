import React, { Component } from 'react';
import { connect } from 'react-redux';
import striptags from 'striptags';
import { withRouter } from 'react-router-dom';
import { POST_ACTIONS } from '../../../redux/actions/postActions';

import PollOptionsExpired from '../../feed/PollTile/PollOptionsExpired';
import PollOptions from '../../feed/PollTile/PollOptions';
import poll from '../../../images/appAlerts/poll.svg';
import glasses from '../../../images/vibe/glasses.png';

class PollAlert extends Component {
  state = {
    vote: false,
  };

  updatePoll = (e, id) => {
    e.preventDefault();
    const data = new FormData();
    data.append('poll[selectedOption]', id);
    this.props.votePoll(this.props.post.id, data);
  };

  render() {
    if (this.props.post) {
      return (
        <div className="post-wrapper poll-wrapper">
          <div className="post-header">
            <img src={poll} className="poll-alert-icon" alt="poll" />
          </div>
          <div className="post-body">
            <div className="post-content">
              <div>
                <div className="post-tile-title">
                  <h4 className="poll-content">
                    <span>Poll: </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: striptags(this.props.post.content, ['a', 'br']),
                      }}
                    />
                  </h4>
                </div>
                {this.props.post.expired ||
                this.props.post.is_poll_selected ||
                (this.props.post.is_author && !this.state.vote) ? (
                  <PollOptionsExpired poll={this.props.post} />
                ) : (
                  <PollOptions poll={this.props.post} updatePoll={this.updatePoll} />
                )}
              </div>
              <div className="poll-info">
                <div className="clear text-muted u-sizevr-1 vibe-anon">
                  <img src={glasses} className="icon" alt="" style={{ width: '24px' }} />
                  <span>Your response is anonymous</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }
}

const mapStateToProps = state => ({
  post: state.postsDomain.singlePost,
});

const mapDispatchToProps = dispatch => ({
  votePoll: (id, data) => dispatch(POST_ACTIONS.votePoll(id, data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PollAlert));
