import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { USER_ACTIONS } from 'redux/actions/userActions';
import Page from '../../layouts/Page';
import { Container, Wrapper } from './BlankState.styles';

const BlankState = ({
  hideSamplePosts,
  openModal,
  closeBlankState,
  Component,
  shouldDisplaySidePlaceholder,
  isTopSpace,
  isHideSamplePosts,
  blankStateProps,
}) => {
  return (
    <Container isTopSpace={isTopSpace}>
      <Wrapper width={blankStateProps?.width} isTopSpace={isTopSpace}>
        <Component
          turnOffSampleContent={hideSamplePosts}
          openModal={openModal}
          closeBlankState={closeBlankState}
          isHideSamplePosts={isHideSamplePosts}
        />
      </Wrapper>
      {shouldDisplaySidePlaceholder && <Page.SidebarPlaceholder />}
    </Container>
  );
};

BlankState.propTypes = {
  hideSamplePosts: PropTypes.func.isRequired,
  openModal: PropTypes.func,
  closeBlankState: PropTypes.func.isRequired,
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  shouldDisplaySidePlaceholder: PropTypes.bool,
  isHideSamplePosts: PropTypes.bool.isRequired,
  isTopSpace: PropTypes.bool,
  maxWidth: PropTypes.number,
};

BlankState.defaultProps = {
  shouldDisplaySidePlaceholder: true,
  isTopSpace: true,
};

const mapStateToProps = state => ({
  isHideSamplePosts: state.usersDomain.user.hideSamplePosts,
});

const mapDispatchToProps = dispatch => ({
  hideSamplePosts: () => dispatch(USER_ACTIONS.hideSamplePosts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlankState);
