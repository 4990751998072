import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { ReCaptcha } from 'components/shared/Recaptcha';
import WelcomeContainer from '../WelcomeContainer';
import {
  ADMIN_ROLE,
  APP_URL,
  CLIENT_ID,
  CLIENT_SECRET,
  COMPANY_OWNER_ROLE,
  GOOGLE_KEY,
  TEAM_MANAGER_ROLE,
  TEAM_MEMBER_ROLE,
  LOGIN_TIMEOUT_MILISECONDS,
  CAPTCHA_KEY,
} from '../../../constants';
import {
  CHECK_UNAUTHORIZED,
  LOCALSTORAGE_BOOL,
  LOCALSTORAGE_GET,
  SIGN_OUT,
} from '../../../helpers';
import axios from '../axiosAuthenticated';
import LoadingOverlay from '../LoadingOverlay';
import steps from '../steps';

class SignIn extends React.Component {
  state = {
    google_key: GOOGLE_KEY,
    grant_type: 'password',
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    username: this.props.email,
    password: '',
    scope: '',
    token: '',
    redirectToReferrer: localStorage != null ? LOCALSTORAGE_BOOL('is_authenticated') : false,
    errors: '',
    redirectMessage: '',
    flash: '',
    flashClass: 'notice',
    demoAccount: false,
    isLoading: false,
    captchaToken: '',
  };

  captcha = React.createRef();

  componentDidMount() {
    if (!this.props.email) {
      this.props.history.push('/login');
    }
  }

  addDemoToEmail(email) {
    const array = email.split('@');
    array[0] += '+demo';
    return array.join('@');
  }

  challengeCaptcha = e => {
    e.preventDefault();
    this.captcha.current.execute();
  };

  signIn = token => {
    this.setState({ isLoading: true });
    let data = new FormData();
    data.append('grant_type', this.state.grant_type);
    data.append('client_id', this.state.client_id);
    data.append('client_secret', this.state.client_secret);
    let { username } = this.state;
    if (
      (this.props.step === steps.both && this.state.demoAccount) ||
      this.props.step === steps.demo
    ) {
      username = this.addDemoToEmail(this.state.username);
    }
    data.append('username', username);
    data.append('password', this.state.password);
    data.append('scope', this.state.scope);
    data.append('captcha_token', token);

    fetch(`${APP_URL}/oauth/token`, { method: 'post', body: data })
      .then(response => {
        if (response.status === 200 && response.ok === true) {
          response
            .json()
            .then(jsonResponse => {
              localStorage.setItem('access_token', jsonResponse.access_token);
              localStorage.setItem('is_authenticated', true);

              localStorage.setItem(
                'token_expires_at',
                new Date(new Date().getTime() + jsonResponse.expires_in * 1000)
              );
              sessionStorage.setItem(
                'token_expires_at',
                new Date(new Date().getTime() + LOGIN_TIMEOUT_MILISECONDS)
              );
              axios.interceptors.request.use(config => {
                config.headers.Authorization = `Bearer ${jsonResponse.access_token}`;
                return config;
              });
              const headers = new Headers();
              headers.append('Accept', 'application/json');
              headers.append('authorization', `Bearer ${LOCALSTORAGE_GET('access_token')}`);

              fetch(`${APP_URL}/user_basic_data`, { headers })
                .then(response => CHECK_UNAUTHORIZED(response))
                .then(response => {
                  if (response.status === 200 && response.ok === true) {
                    response.json().then(jsonResponse => {
                      localStorage.setItem('user_first_name', jsonResponse.first_name);
                      localStorage.setItem('user_last_name', jsonResponse.last_name);
                      localStorage.setItem('user_image_uid', jsonResponse.image_uid);
                      localStorage.setItem('user_id', jsonResponse.id);
                      localStorage.setItem('user_email', jsonResponse.email);
                      localStorage.setItem('user_created_at', jsonResponse.created_at);
                      localStorage.setItem('user_type', jsonResponse.type);
                      localStorage.setItem('account_id', jsonResponse.account_id);
                      localStorage.setItem('account_name', jsonResponse.account_name);
                      localStorage.setItem('upgrade_account', jsonResponse.upgrade_account);

                      jsonResponse.roles.forEach(role => {
                        switch (role.name) {
                          case ADMIN_ROLE:
                            localStorage.setItem('is_admin', true);
                            break;
                          case COMPANY_OWNER_ROLE:
                            localStorage.setItem('is_company_owner', true);
                            break;
                          case TEAM_MANAGER_ROLE:
                            localStorage.setItem('is_team_manager', true);
                            break;
                          case TEAM_MEMBER_ROLE:
                            localStorage.setItem('is_team_member', true);
                            break;
                          default:
                        }
                      });
                      this.setState({
                        redirectToReferrer: LOCALSTORAGE_BOOL('is_authenticated'),
                        isLoading: false,
                      });
                    });
                  } else {
                    data = new FormData();
                    data.append('email', this.state.username);
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('is_authenticated');
                    localStorage.removeItem('token_expires_at');
                    fetch(`${APP_URL}/check_error`, { method: 'post', body: data }).then(
                      response => {
                        if (response.status === 200 && response.ok === true) {
                          response.json().then(jsonResponse => {
                            this.setState({ flash: jsonResponse.error });
                            document.body.classList.add('with_flashes');
                          });
                        }
                      }
                    );
                    this.setState({ errors: 'Authentication failed.' });
                  }
                })
                .catch(error => {
                  SIGN_OUT();
                  this.setState({ errors: 'Authentication failed.' });
                });
            })
            .catch(error => {
              this.setState({ errors: 'Authentication failed.' });
            });
        } else {
          response.json().then(jsonResponse => {
            this.setState({
              flash: jsonResponse.message,
              flashClass: 'alert',
              errors: 'Authentication failed.',
              password: '',
              isLoading: false,
            });
            document.body.classList.add('with_flashes');
          });
        }
      })
      .catch(error => {
        this.setState({
          flash: 'Invalid email or password.',
          flashClass: 'alert',
          errors: 'Authentication failed.',
          password: '',
          isLoading: false,
        });
        document.body.classList.add('with_flashes');
      });
  };

  setTitle = () => {
    const { errors, flash } = this.state;
    if (errors === 'Authentication failed.') {
      if (flash === 'The user credentials were incorrect.') {
        return <span className="error-color">Oops, that password doesn't match</span>;
      }
      return <span className="error-color">{flash}</span>;
    }
    return 'Welcome back!';
  };

  toggleCheckbox() {
    this.setState({ demoAccount: !this.state.demoAccount });
  }

  onLoadRecaptcha = () => {
    if (this.captcha.current) {
      this.captcha.current.reset();
    }
  };

  verifyCallback = token => {
    this.setState({
      isLoading: true,
      captchaToken: token,
    });
    this.signIn(token);
    this.captcha.current.reset();
  };

  render() {
    const { email } = this.props;

    const { redirectToReferrer } = this.state;

    if (redirectToReferrer !== 'false' && redirectToReferrer) {
      if (localStorage != null && LOCALSTORAGE_BOOL('is_admin')) {
        return <Redirect to="/admin" />;
      }
      if (localStorage !== null && LOCALSTORAGE_BOOL('is_authenticated')) {
        if (this.props.redirect.indexOf('vibe/new') !== -1) {
          return <Redirect to={this.props.redirect} />;
        }
        return <Redirect to="/" />;
      }
    }
    return (
      <WelcomeContainer
        title={this.setTitle()}
        body={
          <>
            <LoadingOverlay
              toggled={this.state.isLoading}
              message="Verifying credentials"
              height="85%"
            />
            <form
              onSubmit={this.challengeCaptcha}
              action=""
              className="simple_form form-compact"
              id="new_user"
            >
              <div className="form-group email required user_email">
                <input
                  name="email"
                  type="email"
                  className="string email required form-control email-disabled"
                  id="user_email"
                  placeholder="Start with your email"
                  value={email}
                  disabled
                />
              </div>
              <div className="form-group password required user_password">
                <input
                  name="password"
                  type="password"
                  className={`string password required form-control 
                  ${this.state.errors === 'Authentication failed.' ? 'error' : ''}`}
                  id="user_password"
                  placeholder="Enter your password"
                  value={this.state.password}
                  onChange={event => this.setState({ password: event.target.value })}
                />
              </div>
              {this.props.step === steps.both && (
                <div className="form-group pull-left demo-checkbox checkbox-primary">
                  <div className="checkbox" onClick={() => this.toggleCheckbox()}>
                    <input type="checkbox" checked={this.state.demoAccount} />
                    <label> Log in to demo account</label>
                  </div>
                </div>
              )}
              <button
                disabled={false}
                type="submit"
                className="btn btn-primary btn-full"
                id="sign-in-btn"
              >
                Login
              </button>
            </form>

            <div className="password-reset-link">
              <Link to="/password/new" id="">
                Forgot your password?
              </Link>
            </div>
            <ReCaptcha
              ref={this.captcha}
              size="invisible"
              render="explicit"
              sitekey={CAPTCHA_KEY}
              onloadCallback={this.onLoadRecaptcha}
              verifyCallback={this.verifyCallback}
            />
          </>
        }
      />
    );
  }
}

SignIn.propTypes = {
  email: PropTypes.string.isRequired,
};

export default withRouter(SignIn);
