import React from 'react';
import { connect } from 'react-redux';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import { clearActionBody, setAction, setShowActionModal } from 'redux/app/actions';
import styled from '@emotion/styled';
import DropdownBlue from 'pages/Grow/DropdownBlue';
import { ReactComponent as Calendar } from './Calendar.svg';

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ icon }) => (icon ? '25px' : '0')};
  & svg {
    margin-right: 12px;
  }
`;

const CreateNewMeetingDropdown = ({
  showMeetingModal,
  clearActionBody,
  setAction,
  setShowActionModal,
  text,
  id,
  icon = false,
}) => {
  const openCreateModal = action => {
    clearActionBody();
    setAction(action);
    setShowActionModal(true);
  };

  return (
    <DropdownBlue
      id={id}
      options={[
        {
          label: 'Setup a new recurring meeting',
          onClick: e => {
            e.preventDefault();
            setShowActionModal(true);
            clearActionBody();
            setAction('create-note-meeting-schedule');
          },
        },
        {
          label: 'Create a quick meeting note',
          onClick: () => openCreateModal('create-note-quick-meeting'),
        },
      ]}
      optionsStyles="
                  width: 110%;
                  width: 260px;
                  button:hover {
                    color: #11A9FF;
                  }
                "
      onClick={() => openCreateModal('create-note-quick-meeting')}
    >
      <ContentWrapper icon>
        {icon && <Calendar />}
        {text}
      </ContentWrapper>
    </DropdownBlue>
  );
};
const mapDispatch = {
  showMeetingModal: stopShowingButton => ONBOARDING_ACTIONS.showMeetingModal(stopShowingButton),
  setAction,
  setShowActionModal,
  clearActionBody,
};
export default connect(null, mapDispatch)(CreateNewMeetingDropdown);
