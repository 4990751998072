/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { AVATAR_ERROR } from 'helpers';
import SpaceLogo from '../../../spaces/SpaceLogo';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';

const Wrap = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 50%;
`;

const Description = styled.div`
  margin-left: 11px;
`;

const Name = styled.div`
  font-size: 12px;
  color: #323232;
  margin-bottom: 1px;
`;

const Title = styled.div`
  font-size: 10px;
  color: #6a6a6a;
`;

const ShareProfile = ({
  user: { label, image, title, type = 'user' } = {},
  user,
  isAssignee,
  ...props
}) => (
  <Wrap {...props}>
    {type === 'user' ? (
      <Image src={image} alt="" onError={e => AVATAR_ERROR(e.target)} />
    ) : (
      <SpaceLogo space={user} className="team-logo" />
    )}
    <Description>
      <Name>
        {isAssignee && 'Assigned to '}
        <strong>{label}</strong>
      </Name>
      <Title>{title}</Title>
    </Description>
  </Wrap>
);

ShareProfile.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  mode: PropTypes.string.isRequired,
};

export default ShareProfile;
