import React, { Component } from 'react';
import UserList from './UserList';
import TeamTileDropdownWrap from './TeamTileDropdownWrap';
import SpaceLogo from '../../spaces/SpaceLogo';
import LeadList from './LeadList';

class SetupTeamTile extends Component {
  dragStart = e => {
    if (
      this.props.setupStep === 1 &&
      !this.props.dragHelperVariables.draggedUserDOM &&
      !this.props.dragHelperVariables.draggedTeamDOM
    ) {
      document.body.classList.add('dragging');
      Array.from(document.querySelectorAll('.drag-capture-area')).forEach(node => {
        node.classList.add('active');
      });
      const element = e.currentTarget;
      this.props.setDragHelperVariables({
        dragHelperVariables: {
          draggedTeamDOM: element,
          draggedTeamData: this.props.team,
          dragStartX: e.clientX,
          dragStartY: e.clientY,
          draggedElementStartX: element.getBoundingClientRect().left,
          draggedElementStartY: element.getBoundingClientRect().top,
        },
      });
      element.style.width = `${element.getBoundingClientRect().width}px`;
      element.parentNode.style.height = window
        .getComputedStyle(element.parentNode)
        .getPropertyValue('height');
      element.parentNode.style.width = window
        .getComputedStyle(element.parentNode)
        .getPropertyValue('width');
      element.style.top = `${element.getBoundingClientRect().top}px`;
      element.style.left = `${element.getBoundingClientRect().left}px`;
      element.classList.add('stop-position-transition', 'dragged');
      setTimeout(() => {
        element.classList.remove('stop-position-transition');
        element.style.position = 'fixed';
        element.style.transform = 'rotateZ(8deg)';
      }, 0);
    }
  };

  render() {
    const membersCount =
      this.props.teams
        .filter(stateTeam => stateTeam.parent_ids.indexOf(this.props.team.id) > 0)
        .map(stateTeam => stateTeam.users.length)
        .reduce((a, b) => a + b, 0) + this.props.team.users.length;
    const subsCount = this.props.teams.filter(
      stateTeam => stateTeam.parent_id === this.props.team.id
    ).length;
    return (
      <div
        className={`team-tile-background ${
          (this.props.setupStep > 1 &&
            this.props.teams.map(team => team.parent_id).indexOf(this.props.team.id) === -1) ||
          (!this.props.team.childrenUnfolded &&
            this.props.teams.map(team => team.parent_id).indexOf(this.props.team.id) !== -1)
            ? 'hide-horizontal-branch'
            : ''
        }`}
      >
        <div
          className={`team-tile ${this.props.setupStep > 1 ? 'remove-grab-cursor' : ''}`}
          onMouseDown={this.dragStart}
        >
          {this.props.teams.filter(team => team.parent_id === this.props.team.id).length > 0 ? (
            <div
              className={`unfold-button ${this.props.team.childrenUnfolded ? 'unfolded' : ''}`}
              onClick={e => {
                e.stopPropagation();
                this.props.teamFolding(this.props.team);
              }}
              onMouseDown={e => e.stopPropagation()}
            >
              <span>{subsCount}</span>
            </div>
          ) : (
            ''
          )}
          <TeamTileDropdownWrap
            membersCount={membersCount}
            deleteSpace={this.props.deleteSpace}
            team={this.props.team}
          />
          <div className="team-tile-header">
            <SpaceLogo space={this.props.team} className="team-letters" />
            <span>{this.props.team.name}</span>
            {this.props.setupStep > 1 &&
            this.props.team.users.filter(
              user => user.state === 'pending' || user.state === 'invited'
            ).length > 0 ? (
              <div
                className={`invite-checkbox ${this.props.sendingInvitations ? 'disabled' : ''} ${
                  this.props.team.users.filter(
                    user =>
                      !user.marked_to_invite &&
                      (user.state === 'pending' || user.state === 'invited')
                  ).length === 0
                    ? 'selected'
                    : ''
                }`}
                onClick={() =>
                  !this.props.sendingInvitations
                    ? this.props.markInvitedUsers(false, this.props.team)
                    : null
                }
              />
            ) : (
              ''
            )}
          </div>
          <div className="team-info-wrap">
            {`${membersCount} member${membersCount > 1 || membersCount === 0 ? 's' : ''}`} <span />{' '}
            {`${subsCount} sub${subsCount > 1 || subsCount === 0 ? 's' : ''}`}
          </div>
          {this.props.setupStep === 1 ||
          (this.props.setupStep > 1 && this.props.team.team_leads.length > 0) ? (
            <LeadList
              team={this.props.team}
              setupStep={this.props.setupStep}
              updateUserRole={this.props.updateUserRole}
            />
          ) : (
            ''
          )}
          {this.props.setupStep === 1 ||
          (this.props.setupStep > 1 && this.props.team.users.length > 0) ? (
            <UserList
              makeUsersLead={this.props.makeUsersLead}
              moveUser={this.props.moveUser}
              moveSelectedUsers={this.props.moveSelectedUsers}
              selectUser={this.props.selectUser}
              markInvitedUsers={this.props.markInvitedUsers}
              team={this.props.team}
              dragHelperVariables={this.props.dragHelperVariables}
              setDragHelperVariables={this.props.setDragHelperVariables}
              setupStep={this.props.setupStep}
              sendingInvitations={this.props.sendingInvitations}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

export default SetupTeamTile;
