import React, { Component } from 'react';
import styled from '@emotion/styled';

const TeamInitials = styled.span`
  font-size: 30px;
  font-weight: bold;
  position: absolute;
  margin-left: -91px;
  margin-top: 38px;
  width: 62px;
  text-align: center;
`;

class TeamShoutoutLogo extends Component {
  render() {
    return (
      <>
        <svg height={120} width={120} viewBox="0 -2 100 100">
          <polygon
            id="polygonStar"
            fill="#E6E6E6"
            points="50 0, 68 28, 98 35, 77 59, 79 91, 50 78, 21 91, 23 58, 2 35, 32 28"
            transform="scale(1.02) translate(-1, -1)"
          />
          <polygon
            fill={this.props.thumbnailDetails.colors.background}
            stroke={this.props.thumbnailDetails.colors.border}
            strokeWidth={1}
            points="50 0, 68 28, 98 35, 77 59, 79 91, 50 78, 21 91, 23 58, 2 35, 32 28"
          />
        </svg>
        <TeamInitials style={{ color: this.props.thumbnailDetails.colors.letters }}>
          {this.props.thumbnailDetails.letters}
        </TeamInitials>
      </>
    );
  }
}

export default TeamShoutoutLogo;
