import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Component } from 'react';
import check from './check';
import plans from './plans';

/**
 * Can be used either by using `yes`, `no` props to conditionally render content
 * or by passing a child with render props
 *
 * This needs to be a class component to allow for compatibility with legacy class refs rendered
 * from render props
 */
class Can extends Component {
  render() {
    const { children, plan, perform, yes, no, data } = this.props;
    const allowed = check(plans, plan, perform, data);
    if (children) {
      return children(allowed);
    }

    return allowed ? yes() : no();
  }
}

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

Can.propTypes = {
  yes: PropTypes.func,
  no: PropTypes.func,
  perform: PropTypes.string.isRequired,
  data: PropTypes.object,
};

const mapStateToProps = state => ({
  plan: state.plansDomain.rbacPlan,
});

export default connect(mapStateToProps)(Can);
