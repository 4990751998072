import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { APP_URL } from '../../../constants';
import { BODY_CLASSES, CHECK_UNAUTHORIZED, LOCALSTORAGE_GET } from '../../../helpers';
import OfflineInvoices from './OfflineInvoices';

class InvoicesIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      totalOutstanding: 0.0,
      user: {},
    };
  }

  componentDidMount() {
    BODY_CLASSES(['offline_invoices', 'index']);
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);

    fetch(`${APP_URL}/admin/offline_invoices`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({ invoices: jsonResponse.invoices });
          this.setState({ totalOutstanding: jsonResponse.totalOutstanding });
          this.setState({ user: jsonResponse.user });
        });
      })
      .catch(() => {});
  }

  render() {
    return (
      <div>
        <h1>Offline Invoices</h1>
        <div className="action clear">
          <Link
            className="btn btn-primary margin-above pull-right"
            to="/admin/offline_invoices/new"
          >
            create new invoice
          </Link>
        </div>
        <div className="stats-bar">
          <div className="stats-content">
            <div className="stats-heading">Total Accounts Receivable</div>
            <div className="stats-figure">${this.state.totalOutstanding}</div>
          </div>
          <div className="stats-content">
            <div className="stats-heading" />
            <div className="stats-figure" />
          </div>
          <div className="stats-content">
            <div className="stats-heading" />
            <div className="stats-figure" />
          </div>
        </div>
        <table className="table">
          <tbody>
            <tr>
              <th />
              <th>Name</th>
              <th>Code</th>
              <th>Sent</th>
              <th>Paid</th>
              <th className="hidden-sm hidden-xs">Created</th>
              <th className="hidden-sm hidden-xs">Invoice Due</th>
              <th />
            </tr>
            {this.state.invoices.map(invoice => (
              <OfflineInvoices key={invoice.id} invoice={invoice} user={this.state.user} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default InvoicesIndex;
