/** @jsx jsx */
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/core';
import WhitePlus from '../CreateNote/WhitePlus.svg';
import DarkPlus from '../CreateNote/DarkPlus.svg';
import DarkerPlus from '../CreateNote/assets/DarkerPlus.svg';

const types = {
  white: 'white',
  gray1: 'gray1',
  gray2: 'gray2',
  gray3: 'gray3',
  gray4: 'gray4',
};

const Wrap = styled.div`
  font-size: 12px;
  line-height: 30px;
  border-radius: 25px;
  padding-left: 10px;
  padding-right: 13px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  ${props => props.style}
`;

const getPlus = type => {
  switch (type) {
    case types.gray1:
      return WhitePlus;
    case types.white:
    case types.gray2:
      return DarkPlus;
    case types.gray3:
    case types.gray4:
      return DarkerPlus;
    default:
      throw 'Incorrect button type.';
  }
};

const getStyle = type => {
  switch (type) {
    case types.white:
      return css`
        color: #6a6a6a;
        background: #ffffff;
      `;
    case types.gray1:
      return css`
        color: #ffffff;
        background: #dedede;
        font-weight: bold;
      `;
    case types.gray2:
    case types.gray3:
      return css`
        color: #6a6a6a;
        background: #f3f3f3;
      `;
    case types.gray4:
      return css`
        color: #6a6a6a;
        background: #ffffff;
      `;
    default:
      throw 'Incorrect button type.';
  }
};

const OptionButton = ({ label, type, withPlus = true, ...props }) => {
  return (
    <Wrap style={getStyle(type)} {...props}>
      {withPlus && (
        <img
          css={css`
            margin-right: 10px;
            margin-bottom: 3px;
          `}
          src={getPlus(type)}
          alt=""
        />
      )}
      {label}
    </Wrap>
  );
};

OptionButton.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(types)).isRequired,
  withPlus: PropTypes.bool,
};

export default OptionButton;
