import React from 'react';
import styled from '@emotion/styled';
import CreateNewMeetingDropdown from 'components/meetings/createNewMeetingDropdown/createNewMeetingDropdown';
import GrowProfileVibeButton from 'pages/Grow/GrowProfile/GrowProfileVibeButton';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as BoardView } from './boardView.svg';

const TopBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  padding: 0 0 0 18px;
  @media (max-width: 850px) {
    flex-direction: column;
    padding: 18px 18px 0 18px;
  }
  @media (max-width: 1200px) {
    justify-content: space-between;
  }
`;

const ViewBoardButton = styled.button`
  display: flex;
  align-items: center;
  background: white;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 14px;
  box-shadow: 1px 4px 8px rgba(106, 106, 106, 0.12);
  padding: 12px 16px;
  margin-left: auto;
  margin-right: 18px;
  & svg {
    margin-right: 12px;
  }
  @media (max-width: 850px) {
    width: 100%;
    margin: 0;
  }
  @media (max-width: 1200px) {
    margin-left: 0;
    font-size: 12px;
  }
`;

const VibeButton = styled.button`
  background: #11a9ff;
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  outline: none;
  border: none;
  box-shadow: 1px 4px 8px rgba(106, 106, 106, 0.12);
  border-radius: 4px;
  padding: 12px 16px;
  & svg {
    margin-right: 12px;
  }
  margin-right: 34px;
  @media (max-width: 850px) {
    width: 100%;
    margin: 0;
    margin-bottom: 16px;
  }
  @media (max-width: 1200px) {
    margin-right: 0;
    font-size: 12px;
  }
`;

const DropdownBlueWrapper = styled.div`
  @media (max-width: 850px) {
    width: 100%;
    margin-bottom: 16px;
  }
  & > div {
    @media (max-width: 850px) {
      width: 100%;
    }
    & > button {
      padding-left: 16px;
      font-size: 15px;
      outline: none;
      @media (max-width: 850px) {
        width: 100%;
      }
      @media (max-width: 1200px) {
        font-size: 12px;
      }
      & > div {
        margin-right: 23px;
      }
    }
  }
`;

const TopBarGrowProfile = ({ history }) => {
  return (
    <TopBarWrapper>
      <GrowProfileVibeButton
        VibeButtonContent={VibeButton}
        titleContent="Add note or an action item"
        customAction="create-note"
      />
      <DropdownBlueWrapper>
        <CreateNewMeetingDropdown text="Create meeting" id="create-meeting-btn" icon />
      </DropdownBlueWrapper>
      <ViewBoardButton onClick={() => history.push('/grow_board')} id={'board-view-btn'}>
        <BoardView /> <span>Board view</span>
      </ViewBoardButton>
    </TopBarWrapper>
  );
};

TopBarGrowProfile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(TopBarGrowProfile);
