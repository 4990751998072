import React from 'react';
import { PropTypes } from 'prop-types';
import { APP_URL } from '../../constants';
import { AVATAR_ERROR, CHECK_UNAUTHORIZED, HEADERS, LOCALSTORAGE_GET } from '../../helpers';

const getParameterByName = (name, url = document.location) => {
  const regName = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${regName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

class NotificationItem extends React.Component {
  constructor(props) {
    super(props);
    const { notification } = props;
    this.state = {
      unread: notification.state !== 'viewed',
    };
  }

  markAsRead = e => {
    e.preventDefault();

    const { notification, closeAllPanesHandler } = this.props;
    this.setState({ unread: false });
    const accessToken =
      (localStorage && LOCALSTORAGE_GET('access_token')) || getParameterByName('uta') || '';
    const headers = HEADERS(accessToken);
    fetch(`${APP_URL}/notifications/${notification.id}/viewed`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          if (jsonResponse.markedAsRead) {
            window.location = decodeURIComponent(notification.path);
            closeAllPanesHandler();
          }
          document.dispatchEvent(new MouseEvent('click'));
        });
      })
      .catch(() => {});
  };

  render() {
    const { notification } = this.props;
    const { unread } = this.state;
    const unreadClass = unread ? 'notification-unread' : '';
    return (
      <li className="notification" id={`notification-box-${notification.id}`}>
        <a
          href={notification.path}
          className={`invisible-link no-underline ${unreadClass}`}
          onClick={this.markAsRead}
        >
          <div className="media">
            <div className="media-left">
              <img
                className="img-circle space-to-left"
                width="28"
                height="28"
                alt={notification.actioner}
                src={notification.image}
                onError={e => AVATAR_ERROR(e.target)}
              />
            </div>

            <div className="media-body">
              <strong>{notification.actioner}</strong>
              {` ${notification.action}`}
              <br />
              <span className="greyed u-sizevr-2<">
                <i className="fa fa-clock-o" aria-hidden="true" />
                {` ${notification.time_ago}`}
              </span>
            </div>
          </div>
        </a>
      </li>
    );
  }
}
NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number,
    state: PropTypes.string,
    path: PropTypes.string,
    actioner: PropTypes.string,
    action: PropTypes.string,
    image: PropTypes.string,
    time_ago: PropTypes.string,
  }).isRequired,
  closeAllPanesHandler: PropTypes.func.isRequired,
};
export default NotificationItem;
