/** @jsx jsx */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FeedbackButtons from 'components/vibes/createForms/CreateNote/FeedbackButtons';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { CHECK_UNAUTHORIZED, HEADERS, htmlspecialchars_decode } from 'helpers.js';
import { APP_URL } from 'constants.js';
import SwitchButton from 'components/shared/buttons/SwitchButton';
import { connect } from 'react-redux';
import { POST_ACTIONS } from 'redux/actions/postActions';
import BottomBar from './partials/BottomBar';
import AddQuestionButton from './partials/AddQuestionButton';
import Question from './CreateQuestionnaire/Question';
import { initialQuestionnaireState } from './createPostHelper';

const maxQuestions = 25;
const QuestionsWrapper = styled.div`
  padding: 12px 0;
  display: flex;
  width: 100%;
`;

const initialQuestion = {
  type: 'checkbox',
  content: '',
  required: false,
  options: [
    {
      id: null,
      content: '',
    },
    {
      id: null,
      content: '',
    },
  ],
};

const DescriptionWrapper = styled.div`
  display: block;
  vertical-align: middle;
  overflow: auto;
  font-size: 14px;
`;

const Textarea = styled.textarea`
  display: inline-block;
  float: left;
  width: 63% !important;
  padding: 12px 13px 10px !important;
  height: 84px;
`;

const Name = styled.input`
  font-size: 14px;
  ${props => props.editing && 'margin-top: 20px;'}
`;

const SwitchWrapper = styled.div`
  width: 37%;
  float: right;
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  color: #6a6a6a;
  vertical-align: middle;
  padding: 30px 0 0 18px;
  span.switch {
    vertical-align: bottom;
  }
`;

const ErrorMessage = styled.div`
  padding-left: 2px;
  display: inline-block;
`;

let initialState = initialQuestionnaireState;

class CreateQuestionnaire extends Component {
  state = initialState;

  componentWillUnmount() {
    initialState = this.state;
  }

  componentDidMount() {
    const { noteBody } = this.props;
    if (this.state.id !== noteBody.id) {
      if (noteBody.id) {
        const converting = noteBody.converting ?? false;
        this.setState({
          ...noteBody,
          description: noteBody.not_formatted_content
            ? htmlspecialchars_decode(noteBody.not_formatted_content)
            : initialState.description,
          name: noteBody.name ? htmlspecialchars_decode(noteBody.name) : initialState.name,
          converting,
          id: noteBody.id ?? null,
          errors: [],
          disabled: this.checkIfHasEmptyQuestion(noteBody.questions, noteBody.name, converting),
        });
      } else {
        this.setState({ ...initialQuestionnaireState });
      }
    } else {
      this.setState({ ...initialState });
    }
  }

  setQuestionState = (questionKey, name, value) => {
    const questions = [...this.state.questions];
    const question = { ...questions[questionKey] };
    question[name] = value;
    questions[questionKey] = question;
    this.setState({
      questions,
      disabled: this.checkIfHasEmptyQuestion(questions),
    });
  };

  removeQuestion = questionKey => {
    const questions = [...this.state.questions];
    questions.splice(questionKey, 1);
    this.setState({
      questions,
      disabled: this.checkIfHasEmptyQuestion(questions),
    });
  };

  checkIfHasEmptyQuestion = (
    changedQuestions = this.state.questions,
    changedName = this.state.name,
    converting = this.state.converting
  ) => {
    const questions = changedQuestions ?? this.state.questions;
    const name = changedName ?? this.state.name;
    return (
      name === '' ||
      (!converting && !this.props.spaceId) ||
      questions.find(question => {
        if (question.type === 'short') {
          return question.content === '';
        }
        return (
          question.content === '' ||
          question.options.filter(option => option.content !== '').length < 2
        );
      })
    );
  };

  addQuestion = () => {
    const errors = [...this.state.errors];
    this.setState({
      questions: [...this.state.questions, JSON.parse(JSON.stringify(initialQuestion))],
      disabled: true,
      errors: errors.filter(error => error.key !== 'questions'),
    });
  };

  async submitForm() {
    this.setState({ disabled: true });
    const data = new FormData();
    data.append('description', this.state.description);
    data.append('name', this.state.name);
    data.append('open', this.state.open);
    data.append('team_id', this.props.spaceId);
    if (!this.state.questions.length) {
      this.setState({
        errors: [
          {
            key: 'questions',
            value: 'A questionnaire requires a question to be created.',
          },
        ],
      });
    } else {
      for (let i = 0; i < this.state.questions.length; i++) {
        const question = this.state.questions[i];
        data.append('questions[]', JSON.stringify(question));
      }
      const url = this.state.id
        ? `${APP_URL}/questionnaires/${this.state.id}`
        : `${APP_URL}/questionnaires`;
      const headers = HEADERS();
      fetch(url, { method: 'post', headers, body: data })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            if (jsonResponse.errors) {
              this.setState({ errors: jsonResponse.errors });
            } else if (jsonResponse.error) {
              this.setState({
                errors: [
                  {
                    key: 'questions',
                    value: jsonResponse.error,
                  },
                ],
              });
            } else {
              const isUserInTeam = this.props.spaceId === this.props.user.teamId;
              this.props.resetPosts();
              this.fetchPosts(isUserInTeam);
              this.setState(initialQuestionnaireState);
              this.props.closePopup();
              this.reloadPage(isUserInTeam);
            }
          });
        });
    }
  }

  fetchPosts = isUserInTeam => {
    if (this.props.location.pathname === '/questionnaires' || !isUserInTeam) {
      this.props.fetchQuestionnaires(0);
    } else {
      this.props.fetchPosts();
    }
  };

  reloadPage = isUserInTeam => {
    if (!isUserInTeam) {
      this.props.history.push(`/questionnaires`);
    } else if (this.props.location.pathname !== '/questionnaires') {
      this.props.history.push(`/`);
    }
  };

  renderErrorMessage = type => {
    return this.state.errors.find(error => error.key === type) ? (
      <ErrorMessage className="error-message text-danger">
        {this.state.errors.find(error => error.key === type).value}
      </ErrorMessage>
    ) : null;
  };

  render() {
    const editing = this.state.converting;
    return (
      <div className="create-questionnaire-tab">
        <div className="create-questionnaire-feed">
          {!editing && (
            <FeedbackButtons onClick={this.props.changeTab} toggled="create-questionnaire" />
          )}
          <Name
            editing={editing}
            className="name-input"
            placeholder="Name your questionnaire"
            type="text"
            value={this.state.name}
            onChange={e =>
              this.setState({
                name: e.target.value,
                disabled: this.checkIfHasEmptyQuestion(null, e.target.value),
              })
            }
          />
          {this.renderErrorMessage('name')}
          <DescriptionWrapper>
            <Textarea
              className="description"
              placeholder="Give a short intro or description (optional)"
              value={this.state.description}
              onChange={e => this.setState({ description: e.target.value })}
            />
            <SwitchWrapper>
              <SwitchButton
                turnedOn={this.state.open}
                onClick={() => this.setState({ open: !this.state.open })}
              />
              <span>Anyone can see results</span>
            </SwitchWrapper>
            {this.renderErrorMessage('description')}
          </DescriptionWrapper>
          {this.state.questions.map((question, key) => (
            <QuestionsWrapper key={key}>
              <Question
                questionKey={key}
                question={question}
                setQuestionState={this.setQuestionState}
                removeQuestion={this.removeQuestion}
                errors={this.state.errors}
              />
            </QuestionsWrapper>
          ))}
          {this.renderErrorMessage('questions')}
          {this.state.questions.length < maxQuestions && (
            <AddQuestionButton addQuestion={this.addQuestion} />
          )}
          <BottomBar>
            <div className="controls-panel pull-left" />
            <div className="info-panel pull-right">
              <input
                id="post-questionnaire-btn"
                type="button"
                data-disable-with="Creating..."
                disabled={this.state.disabled}
                name="commit"
                className="btn btn-primary"
                value={this.state.id ? 'Update questionnaire' : 'Create questionnaire'}
                onClick={() => this.submitForm()}
              />
            </div>
          </BottomBar>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  noteBody: state.app.body,
});

const mapDispatchToProps = dispatch => ({
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  fetchPosts: () => dispatch(POST_ACTIONS.fetchPosts()),
  fetchQuestionnaires: loaded => dispatch(POST_ACTIONS.fetchQuestionnaires(loaded)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateQuestionnaire));
