/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import ExifOrientationImg from 'react-exif-orientation-img';
import { Player } from 'video-react';

const EditMediaWrap = ({ mediaUrl, media, removeNewMediaHandler, removeMediaHandler }) => (
  <div id="images-wrap">
    {mediaUrl &&
      mediaUrl.map((media, index) =>
        media.type === 'i' ? (
          <div
            className="preview"
            onClick={() => removeNewMediaHandler(index)}
            id={`image-preview-${index}`}
            key={index}
          >
            <ExifOrientationImg src={media.url} alt="" className="preview" />
            <div className="preview-cross" onClick={() => removeNewMediaHandler(index)} />
          </div>
        ) : (
          <div
            className="preview video-add-modal preview-video"
            key={index}
            id={`image-preview-${index}`}
            onClick={() => removeNewMediaHandler(index)}
          >
            <span className="preview-cross" onClick={() => removeNewMediaHandler(index)} />
            <Player playsInline src={media.url} />
          </div>
        )
      )}
    {media &&
      media.map(media =>
        media.type === 'i' ? (
          <div
            className="preview"
            onClick={() => removeMediaHandler(media.id)}
            key={media.id}
            css={css`
              img.preview {
                max-height: 80px;
                width: auto !important;
              }
            `}
          >
            <ExifOrientationImg src={media.url} alt="" className="preview" />
          </div>
        ) : (
          <div
            className="preview video-add-modal preview-video"
            key={media.id}
            onClick={() => removeMediaHandler(media.id)}
          >
            <Player playsInline src={media.path} />
          </div>
        )
      )}
  </div>
);

export default EditMediaWrap;
