import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ChampionWrapper,
  CompanyValueTitle,
  UsersContainer,
} from 'pages/Grow/Champions/Champions.styles';
import UserCompanyValueData from 'pages/Grow/Champions/UserCompanyValueData';
import styled from '@emotion/styled';

const ViewAllButton = styled.button`
  display: flex;
  align-items: center;
  border: 1px solid #d5d5d5;
  outline: none;
  cursor: pointer;
  position: relative;
  background: white;
  border-radius: 22px;
  font-size: 12px;
  color: #6a6a6a;
  width: 86px;
  height: 26px;
  line-height: 26px;
  padding-left: 29px;
  padding-right: 11px;
  &:after,
  &:before {
    width: 10px;
    height: 2px;
    background: #d5d5d5;
    border-radius: 1px;
    position: absolute;
    left: 10px;
    top: 50%;
    content: '';
    transition: transform 0.3s;
  }
  &:before {
    transform: ${({ expand }) =>
      expand ? 'translate(0, -50%)' : 'translate(0, -50%) rotateZ(45deg)'};
  }
  &:after {
    transform: ${({ expand }) =>
      expand ? 'translate(0, -50%) rotateZ(90deg)' : 'translate(0, -50%) rotateZ(135deg)'};
  }
`;

const CompanyValueChampions = ({ name, users }) => {
  const [viewAllData, setViewAll] = useState(true);

  const usersData = viewAllData ? users.slice(0, 5) : users;
  return (
    <ChampionWrapper>
      <CompanyValueTitle>{name}</CompanyValueTitle>
      <UsersContainer>
        {usersData.map(({ name, role, team_name, count, image_url }) => (
          <UserCompanyValueData
            name={name}
            role={role}
            teamName={team_name}
            count={count}
            imageUrl={image_url}
          />
        ))}
      </UsersContainer>
      {users.length > 5 && (
        <ViewAllButton onClick={() => setViewAll(!viewAllData)} expand={viewAllData}>
          {viewAllData ? 'View all' : 'Collapse'}
        </ViewAllButton>
      )}
    </ChampionWrapper>
  );
};

CompanyValueChampions.propTypes = {
  name: PropTypes.string.isRequired,
  users: PropTypes.array,
};
export default CompanyValueChampions;
