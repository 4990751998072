/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Select from 'react-select';
import NoCompanyValuesOption from '../../../settings/partials/NoCompanyValuesOption';

const Wrap = styled.div`
  margin-bottom: 15px;
`;

const ValueTag = styled.div`
  padding: 8px 30px 8px 18px;
  color: white;
  background: #11a9ff;
  position: relative;
  border-radius: 18px;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  display: inline-block;
  margin: 18px 10px 5px 0px;
`;

const ValueCross = styled.div`
  cursor: pointer;
  position: absolute;
  display: inline-block;
  height: 10px;
  width: 10px;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  &:before,
  &:after {
    border-radius: 2px;
    width: 10px;
    height: 2px;
    background: white;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

const SelectWrap = styled.div`
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
    > div {
      padding: 0 !important;
      width: 100% !important;
      :first-of-type {
        padding-bottom: 20px !important;
      }
    }
  }
  #company-value-input {
    padding: 0;
    border: none;
  }
  .Select-option {
    word-break: break-word;
  }
  .Select-placeholder {
    color: #c4c4c4 !important;
  }
  .Select-menu-outer {
    z-index: 3 !important;
  }
`;

const SelectPicker = ({ placeholder, initialValues, values, setValues }) => {
  const removeValueHandler = value => {
    const valueIndex = values.indexOf(value);
    const chosenHelperArray = values;
    chosenHelperArray.splice(valueIndex, 1);
    setValues(chosenHelperArray || []);
  };

  const getSelectCompanyValuesOptions = () => {
    const options = initialValues
      ? initialValues
          .filter(value => values.indexOf(value) < 0)
          .map(companyValue => {
            return { value: companyValue, label: companyValue };
          })
      : [];
    return options;
  };

  return (
    <Wrap>
      <SelectWrap>
        <Select
          options={getSelectCompanyValuesOptions()}
          inputProps={{ id: 'company-value-input' }}
          isSearchable
          value={null}
          onChange={e => setValues([...values, e.value])}
          clearable={false}
          className="select-component company-values orange"
          placeholder={placeholder}
          disabled={false}
          noResultsText={<NoCompanyValuesOption />}
        />
      </SelectWrap>
      <div>
        {values.map((value, index) => (
          <ValueTag key={index}>
            {value}
            <ValueCross onClick={() => removeValueHandler(value)} />
          </ValueTag>
        ))}
      </div>
    </Wrap>
  );
};

SelectPicker.propTypes = {
  values: PropTypes.any.isRequired,
  setValues: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  initialValues: PropTypes.array.isRequired,
};

export default SelectPicker;
