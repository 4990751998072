const factors = {
  1: 'Excitement & Energy',
  2: 'Learning & Growth',
  3: 'Autonomy & Independence',
  4: 'Valued',
  5: 'Clarity & Certainty',
  6: 'Being Connected',
  7: 'Safety & Comfort',
  8: 'Purpose',
  9: 'Productivity',
  10: 'External',
};

export default factors;
