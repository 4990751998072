import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { APP_URL, AWS_S3_URL } from '../../../constants';
import { BODY_CLASSES, CHECK_UNAUTHORIZED } from '../../../helpers';

class NewConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: false,
      message: '',
      redirect: false,
    };
  }

  componentDidMount() {
    BODY_CLASSES(['confirmations', 'new']);
  }

  send(e) {
    e.preventDefault();
    const data = new FormData();
    data.append('email', this.state.email);

    fetch(`${APP_URL}/confirmation/resend`, { method: 'post', body: data })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (response.status === 200 && response.ok === true) {
          response.json().then(jsonResponse => {
            if (jsonResponse.error) {
              this.setState({ error: true });
              this.setState({ message: jsonResponse.message });
            } else {
              this.setState({ message: jsonResponse.message });
              this.setState({ redirect: true });
            }
          });
        }
      });
  }

  render() {
    const classes = this.state.error
      ? 'form-group email optional user_email has-error'
      : 'form-group email optional user_email';

    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: '/login',
            state: {
              message: this.state.message,
            },
          }}
        />
      );
    }
    return (
      <div>
        <div className="logo-public centered">
          <img
            src={`${AWS_S3_URL}public/images/Heelix-Logo-black.svg`}
            alt=""
            style={{ height: 56 }}
          />
        </div>
        <div className="floating-panel">
          <div className="panel-body">
            <div className="centered">
              <h1>Resend confirmation instructions</h1>
              <p>Enter your email address and we'll get you started.</p>
            </div>
            <form className="simple-form form-compact">
              <div className={classes}>
                <label className="email optional control-label" htmlFor="user_email">
                  Email
                </label>
                <input
                  autoFocus="autofocus"
                  className="string email optional form-control"
                  name="user[email]"
                  placeholder="your@email.com"
                  type="email"
                  id="user_email"
                  onKeyPress={e => (e.key === 'Enter' ? this.send(e) : '')}
                  onChange={e => this.setState({ email: e.target.value })}
                />
                <span className="help-block">{this.state.message}</span>
              </div>
              <input
                className="btn btn-primary btn-full"
                name="commit"
                id="resend-confirmation-btn"
                type="submit"
                value="Resend confirmation instructions"
                onClick={e => this.send(e)}
              />
            </form>
            <div className="centered u-margin-top-sm">
              <div className="u-size0">
                Already have an account?&nbsp;
                <Link className="invisible-link ul-link" to="/login" id="sign-in-btn">
                  Sign in here
                </Link>
                <br />
                <Link
                  to="/password/new"
                  className="invisible-link ul-link"
                  id="forgot-password-btn"
                >
                  Forgot your password?
                </Link>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewConfirmation;
