import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as RemoveMedia } from '../CreateNote/assets/remove_file.svg';

class FileUpload extends Component {
  state = {
    loaded: false,
    enableDelete: false,
  };

  componentDidMount() {
    setTimeout(() => this.setState({ loaded: true }), 100);
    setTimeout(() => this.setState({ enableDelete: true }), 4200);
  }

  render() {
    const { typeProperty, file, removeFileHandler, fileIndex } = this.props;
    return (
      <div className={`file-upload-tile ${this.state.loaded && 'loaded'}`}>
        <span
          className="file-button"
          onClick={() => (this.state.enableDelete ? removeFileHandler(fileIndex) : null)}
        >
          <svg
            className="tick"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
              fill="#4DA60B"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.12469 12.6429L13.5699 6.15356L12.2594 5.05396L7.90095 10.2482L5.09119 7.8905L3.99158 9.20096L8.10211 12.6501L8.11499 12.6348L8.12469 12.6429Z"
              fill="white"
            />
          </svg>
          <RemoveMedia />
        </span>
        <span className="file-name">{file.name}</span>
      </div>
    );
  }
}

FileUpload.propTypes = {
  file: PropTypes.object.isRequired,
  fileIndex: PropTypes.number.isRequired,
  removeFileHandler: PropTypes.func.isRequired,
  typeProperty: PropTypes.string,
};

FileUpload.defaultProps = {
  typeProperty: 'type',
};

export default FileUpload;
