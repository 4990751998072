export const APP_SET_ACTION = 'APP_SET_ACTION';
export const APP_CLEAR_ACTION_BODY = 'APP_CLEAR_ACTION_BODY';
export const APP_TOGGLE_ACTION_MODAL = 'APP_TOGGLE_ACTION_MODAL';
export const APP_TOGGLE_NOTE_MODAL = 'APP_TOGGLE_NOTE_MODAL';
export const APP_SET_RECENT_NOTE = 'APP_SET_RECENT_NOTE';
export const APP_RESET_RECENT_NOTE = 'APP_RESET_RECENT_NOTE';
export const APP_TOGGLE_ADD_USER_MODAL = 'APP_TOGGLE_ADD_USER_MODAL';
export const APP_TOGGLE_ADD_CSV_USER_MODAL = 'APP_TOGGLE_ADD_CSV_USER_MODAL';
export const APP_MODAL_NOTE = 'APP_MODAL_NOTE';
export const APP_RESET_MODAL_NOTE = 'APP_RESET_MODAL_NOTE';
export const APP_SET_CSV_USERS = 'APP_SET_CSV_USERS';
export const APP_RESET_CSV_USERS = 'APP_RESET_CSV_USERS';

export const setAction = (action, body) => {
  return {
    type: APP_SET_ACTION,
    payload: {
      action,
      body,
    },
  };
};

export const clearActionBody = () => {
  return {
    type: APP_CLEAR_ACTION_BODY,
  };
};

export const setShowActionModal = showActionModal => {
  return {
    type: APP_TOGGLE_ACTION_MODAL,
    payload: {
      showActionModal,
    },
  };
};

export const setShowAddUserModal = showModal => {
  return {
    type: APP_TOGGLE_ADD_USER_MODAL,
    payload: {
      showModal,
    },
  };
};

export const setShowAddCSVUserModal = showModal => {
  return {
    type: APP_TOGGLE_ADD_CSV_USER_MODAL,
    payload: {
      showModal,
    },
  };
};

export const setCurrentNoteModal = currentNoteModal => {
  return {
    type: APP_MODAL_NOTE,
    payload: currentNoteModal,
  };
};

export const resetCurrentNoteModal = () => {
  return {
    type: APP_RESET_MODAL_NOTE,
  };
};

export const toggleNoteModal = showNoteModal => {
  return {
    type: APP_TOGGLE_NOTE_MODAL,
    payload: {
      showNoteModal,
    },
  };
};

export const setRecentNote = (note, id = null) => {
  return {
    type: APP_SET_RECENT_NOTE,
    payload: {
      note,
      id: id ?? note.id,
    },
  };
};

export const resetRecentNote = () => {
  return {
    type: APP_RESET_RECENT_NOTE,
  };
};

export const setCSVUsers = users => {
  return {
    type: APP_SET_CSV_USERS,
    payload: users,
  };
};

export const resetCSVUsers = () => {
  return {
    type: APP_RESET_CSV_USERS,
  };
};

export const APP_TOGGLE_USER_MODAL = 'APP_TOGGLE_USER_MODAL';

export const toggleUserModal = (userModal, userId) => {
  return {
    type: APP_TOGGLE_USER_MODAL,
    payload: {
      userModal,
      userId,
    },
  };
};
