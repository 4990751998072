/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import { PropTypes } from 'prop-types';
import { Wrapper } from 'pages/Grow/NoteLayout';
import ManagerPrompt from 'pages/Grow/GrowPost/ManagerPrompts/ManagerPrompt';
import TeamContribution from './TeamContribution';
import MyNote from './MyNote';
import ManagerPromptSelector from 'pages/Grow/GrowPost/ManagerPrompts/ManagerPromptSelector';

const GrowPost = ({
  post: {
    id,
    type,
    post,
    note,
    win,
    preparation_note,
    progress_status,
    add_to_progress_board_enabled,
  },
  exportModeOn,
  hasDeleteError,
  handleSelectPost,
  isPostSelected,
  openPostModal,
  tileRealignHandler,
  isFirstMeetingNote,
  isFirstMeetingPreparationNote,
  isExportAvailable,
}) => (
  <>
    {(type === 'TeamContribution' || type === 'Shoutout' || type === 'PostAlert') && (
      <TeamContribution
        post={post}
        postId={id}
        exportModeOn={exportModeOn && isExportAvailable}
        hasDeleteError={hasDeleteError}
        handleSelectPost={handleSelectPost}
        isPostSelected={isPostSelected}
        openPostModal={openPostModal}
        tileRealignHandler={tileRealignHandler}
      />
    )}
    {(type === 'MyNote' ||
      type === 'SharedNote' ||
      type === 'MeetingStep' ||
      type === 'MeetingStepNote' ||
      type === 'SharedMeetingStep' ||
      type === 'MyWin' ||
      type === 'SharedWin' ||
      type === 'MeetingNote' ||
      type === 'SharedMeeting' ||
      type === 'ManagerPromptNote' ||
      type === 'SharedManagerPrompt' ||
      type === 'MeetingPreparationNote') && (
      <div
        {...(isFirstMeetingPreparationNote && { id: 'MeetingPreparationNote_0' })}
        {...(isFirstMeetingNote && { id: 'MeetingNote_0' })}
        className="col-lg-6 col-xs-12"
        css={css`
          padding: 0 10px !important;
          ${note &&
          note.type === 'MeetingPreparation' &&
          css`
            box-shadow: 0 -17px 0px -11px #fadd6a;
          `}
        `}
      >
        <Wrapper>
          <MyNote
            note={note}
            preparationNote={preparation_note}
            win={win}
            type={type}
            postId={id}
            exportModeOn={exportModeOn && isExportAvailable}
            hasDeleteError={hasDeleteError}
            handleSelectPost={handleSelectPost}
            openPostModal={openPostModal}
            isPostSelected={isPostSelected}
            progressStatus={progress_status}
            addToProgressBoardEnabled={add_to_progress_board_enabled}
            tileRealignHandler={tileRealignHandler}
          />
        </Wrapper>
      </div>
    )}
    {(type === 'ManagerPromptPreparationNote' || type === 'ManagerPromptAlertNote') && (
      <div
        className="col-lg-6 col-xs-12"
        css={css`
          padding: 0 10px !important;
        `}
      >
        <Wrapper>
          <ManagerPromptSelector note={note} />
        </Wrapper>
      </div>
    )}
  </>
);
GrowPost.propTypes = {
  exportModeOn: PropTypes.bool,
  isPostSelected: PropTypes.bool,
  handleSelectPost: PropTypes.func,
  openPostModal: PropTypes.func,
  isFirstMeetingNote: PropTypes.bool,
  isFirstMeetingPreparationNote: PropTypes.bool,
  post: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    post: PropTypes.shape(),
    note: PropTypes.shape(),
  }).isRequired,
};

GrowPost.defaultProps = {
  exportModeOn: false,
  isPostSelected: false,
  handleSelectPost: null,
  openPostModal: null,
  isFirstMeetingNote: false,
  isFirstMeetingPreparationNote: false,
};

export default GrowPost;
