import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { PropTypes } from 'prop-types';
import { ReactComponent as ExportContent } from './assets/exportContent.svg';
import exportArrow from './assets/exportArrow.svg';
import { GROW_ACTIONS } from '../../redux/actions/growActions';

const ExportSectionWrapper = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

const Export = styled.div`
  display: flex;
  flex-direction: column;
`;

const SwitchButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchButton = styled.button`
  border: none;
  background: transparent;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  color: #11a9ff;
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
  margin: 0 0 24px;
  padding: 0;
  position: relative;
  &:after {
    position: absolute;
    right: 0;
    top: 5px;
    content: '';
    width: 10px;
    height: 6px;
    background: transparent url(${exportArrow}) center no-repeat;
    transform: rotate(${props => (props.exportModeOn ? '180deg' : '0deg')});
  }
`;

const ExportContentIcon = styled(ExportContent)`
  margin-right: 10px;
`;

const ExportContentDescription = styled.p`
  margin: 0 0 24px;
  padding: 0;
  font-size: 13px;
  line-height: 150%;
  color: #6a6a6a;
  text-align: justify;
  width: 145px;
`;

const DeleteContentError = styled(ExportContentDescription)`
  color: #fe483c;
`;

const ExportActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const ResetLink = styled.span`
  font-size: 11px;
  text-decoration: none;
  color: #11a9ff;
  line-height: 15px;
  cursor: pointer;
`;

const ExportButton = styled.button`
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  padding: 12px 24px;
  color: #ffffff;
  background: #11a9ff;
  border-radius: 3px;
  cursor: pointer;
  border: none;
  outline: none;
  width: 154px;
  margin-bottom: 12px;
  &:hover {
    color: #ffffff;
  }
`;

const DeleteButton = styled(ExportButton)`
  background: #fe483c;
`;

const CheckboxWrapper = styled.div`
  position: relative;
  input[type='checkbox'] {
    top: 0;
    left: 20px;
  }
  input[type='checkbox']:checked + label:before {
    background-color: #11a9ff !important;
    border-color: #11a9ff !important;
  }
  input[type='checkbox']:focus + label::before {
    outline: none !important;
  }
  label:after {
    width: 14px !important;
    height: 14px !important;
    margin-left: -20px !important;
    padding-top: 0 !important;
    font-size: 9px !important;
    line-height: 25px;
  }
`;

const CheckboxLabel = styled.label`
  font-size: 11px;
  text-decoration: none;
  color: #11a9ff;
  cursor: pointer;
  line-height: 25px;
  &:before {
    width: 14px !important;
    height: 14px !important;
    border: 1px solid #11a9ff !important;
    background: transparent !important;
    top: 6px !important;
  }
`;

const ExportSection = ({
  exportModeOn,
  changeExportMode,
  selectAllPosts,
  resetPostsSelection,
  selectedPosts,
  exportSelected,
  deleteSelected,
  allPostsSelected,
  isExporting,
  isDeleting,
  hasDeleteError,
}) => (
  <ExportSectionWrapper exportModeOn={exportModeOn}>
    <Export exportModeOn={exportModeOn}>
      <SwitchButtonWrapper>
        <SwitchButton onClick={changeExportMode} exportModeOn={exportModeOn}>
          <ExportContentIcon /> Export content
        </SwitchButton>
      </SwitchButtonWrapper>
      {exportModeOn && (
        <>
          <ExportActionsWrapper>
            <CheckboxWrapper className="checkbox checkbox-primary">
              <input
                checked={allPostsSelected}
                type="checkbox"
                id="select-all"
                name="select-all"
                className="select-all"
                style={{ cursor: 'pointer' }}
                onClick={() => selectAllPosts()}
              />
              <CheckboxLabel htmlFor="select-all" className="boolean" id="select-all-label">
                <span className="hidden-xs">Select all</span>
                <span className="visible-xs">All</span>
              </CheckboxLabel>
            </CheckboxWrapper>
            <ResetLink onClick={() => resetPostsSelection()}>
              Reset<span className="hidden-xs"> selection</span>
            </ResetLink>
          </ExportActionsWrapper>
          <ExportContentDescription>
            Select the items you’d like to export and press the button below
          </ExportContentDescription>
          <ExportButton
            className="btn"
            disabled={selectedPosts.length === 0 || isExporting}
            onClick={selectedPosts.length === 0 || isExporting ? null : () => exportSelected()}
          >
            Export<span className="hidden-xs"> selected</span>
          </ExportButton>
          <DeleteButton
            className="btn"
            disabled={selectedPosts.length === 0 || isDeleting}
            onClick={selectedPosts.length === 0 || isDeleting ? null : () => deleteSelected()}
          >
            Delete<span className="hidden-xs"> selected</span>
          </DeleteButton>
          {hasDeleteError && (
            <DeleteContentError>
              You’ve selected to delete a post that you are not the author of. Please review and
              update.
            </DeleteContentError>
          )}
        </>
      )}
    </Export>
  </ExportSectionWrapper>
);

ExportSection.propTypes = {
  exportModeOn: PropTypes.bool.isRequired,
  changeExportMode: PropTypes.func.isRequired,
  resetPostsSelection: PropTypes.func.isRequired,
  exportSelected: PropTypes.func.isRequired,
  deleteSelected: PropTypes.func.isRequired,
};

const mapDispatch = dispatch => ({
  selectAllPosts: () => dispatch(GROW_ACTIONS.selectAllPosts()),
  resetPostsSelection: () => dispatch(GROW_ACTIONS.resetPostsSelection()),
});

const mapStateToProps = state => ({
  posts: state.growDomain.posts,
  allPostsSelected: state.growDomain.allPostsSelected,
  selectedPosts: state.growDomain.selectedPosts,
});

export default connect(mapStateToProps, mapDispatch)(ExportSection);
