import React, { Component } from 'react';
import { connect } from 'react-redux';
import striptags from 'striptags';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import loadImage from 'blueimp-load-image';
import { POST_ACTIONS } from 'redux/actions/postActions';
import PostMedia from 'pages/Grow/GrowPost/PostMedia';
import { toggleUserModal } from 'redux/app/actions';
import PostDetails from 'components/shared/posts/PostDetails';
import AuthorInfo from 'components/shared/posts/AuthorInfo';
import DropzoneWrap from 'components/shared/posts/DropzoneWrap';
import {
  AVATAR_ERROR,
  FORMAT_CONTENT_WITH_MARKUPS,
  FORMAT_FILTERS,
  GET_MENTIONS_FROM_CONTENT,
  ROTATE_IE_IMAGE,
  VALIDATE_FILE,
} from '../../../helpers';
import PostComments from './PostComments';
import PostFile from '../../feed/PostFile';
import favActiveBtn from '../../../images/feed/star_active.png';
import favBtn from '../../../images/feed/star.png';
import FavPostTooltip from '../../grow/tooltips/FavPostTooltip';
import MentionsInputWrapper from '../../shared/MentionsInputWrapper';
import CommentInput from '../shared/CommentInput';
import EditMediaWrap from '../shared/EditMediaWrap';
import PinnedPost from '../shared/PinnedPost';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';

const mapStateToProps = state => ({
  user: state.usersDomain.user,

  hashtags: state.tagsDomain.hashtags,

  post: state.postsDomain.singlePost,
  filters: state.postsDomain.filters,
  reachedDataLimit: state.plansDomain.reachedDataLimit,
});

const mapDispatchToProps = dispatch => ({
  updatePost: (postId, data) => dispatch(POST_ACTIONS.updatePost(postId, data)),
  favPost: postId => dispatch(POST_ACTIONS.favPost(postId)),
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
});

class Post extends Component {
  constructor(props) {
    super(props);
    this.allFilesSize = 0;
    this.commentAllFilesSize = 0;
  }

  state = {
    errorAction: null,
    lightboxIsOpen: false,
    currentImage: 0,

    // ---For editing purposes
    commentEditing: false,
    commentEditingId: null,
    content: '',
    media: [],
    mediaUrl: [],
    commentMediaUrl: [],
    team: '',
    message: null,
    errors: null,
    processing: false,
    commentCharsLeft: 250,
    errorContent: '',
    loader: false,
  };

  componentDidMount() {
    if (this.props.locationState !== undefined && this.props.locationState.editing) {
      this.setState({ editing: true }, () => this.editClickedHandler());
    }

    if (this.props.editing) {
      this.setState({
        name: this.props.post.name,
        content: FORMAT_CONTENT_WITH_MARKUPS(this.props.post.content),
        media: this.props.post.media,
      });
    }

    const renderedHashtags = document.getElementsByClassName('inline-hashtag');
    Array.from(renderedHashtags).forEach(hashtag => {
      hashtag.addEventListener('click', this.hashtagClickedHandler, false);
    });

    const renderedMentions = document.getElementsByClassName('inline-mention');
    Array.from(renderedMentions).forEach(mention => {
      mention.addEventListener('click', this.mentionClickedHandler, false);
    });
  }

  // ---Hashtags listener and filter apply
  hashtagClickedHandler = e => {
    this.props.history.push(
      `/feed?${qs.stringify(
        FORMAT_FILTERS(e.currentTarget, {
          space: this.props.filters.space,
          types: [],
          hashtags: [],
          string: '',
        })
      )}`
    );
  };
  //--------------------

  mentionClickedHandler = e => {
    e.preventDefault();
    const mentionedId = parseInt(e.currentTarget.getAttribute('user-id'));
    if (mentionedId === this.props.user.id) {
      this.props.history.push(`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`);
    } else {
      return this.props.toggleUserModal(true, mentionedId);
    }
  };

  componentWillReceiveProps(props) {
    if (props.editing) {
      this.setState({
        name: this.props.post.name,
        content: FORMAT_CONTENT_WITH_MARKUPS(this.props.post.content),
        media: this.props.post.media,
      });
    }
    if (this.state.loader === true && this.props.post !== props.post) {
      this.props.changeEditingState();
      this.setState({
        loader: false,
      });
    }
  }

  fileUploadHandler = files => {
    let mediaUrl = [];

    for (let i = 0; i < files.length; i++) {
      if (!VALIDATE_FILE(files[i], this.state.media, this.allFilesSize)) {
        break;
      }

      const file = files[i];
      const index = i;
      const type = file.type.split('/')[0];

      this.allFilesSize += file.size;

      if (
        (type === 'image' && !(this.state.mediaUrl[0] && this.state.mediaUrl[0].type === 'v')) ||
        (type === 'video' && this.state.mediaUrl.length === 0)
      ) {
        if (type === 'video' && files.length > 1) {
          // Breaks loop if there are images and videos simultaneously
          this.setState({
            mediaUrl: [],
            disabledButton: false,
          });
          alert('You may attach either one video or few images.');
          break;
        }

        const reader = new FileReader();
        reader.onload = ((index, type) => e => {
          if (type === 'image') {
            const loadImageOptions = {
              orientation: true,
              noRevoke: true,
            };
            const img = ROTATE_IE_IMAGE(e.target.result);
            loadImage(
              img,
              canvas => {
                mediaUrl[index] = {
                  url: img,
                  type: type === 'image' ? 'i' : 'v',
                  size: file.size,
                };
                if (mediaUrl.filter(Boolean).length === files.length) {
                  this.setState({
                    mediaUrl: [...this.state.mediaUrl, ...mediaUrl],
                    disabledButton: false,
                  });
                }
              },
              loadImageOptions
            );
          } else {
            mediaUrl[index] = {
              url: reader.result,
              type: type === 'image' ? 'i' : 'v',
              size: file.size,
            };
            if (mediaUrl.filter(Boolean).length === files.length) {
              this.setState({
                mediaUrl: [...this.state.mediaUrl, ...mediaUrl],
                disabledButton: false,
              });
            }
          }
        })(index, type);
        reader.readAsDataURL(file);
      } else {
        mediaUrl = [];
        alert('You may attach either one video or a few images.');
        return;
      }
    }
  };

  removeMediaHandler = index => {
    const updatedMedia = [...this.state.media];

    const mediaIndex = updatedMedia.findIndex(m => m.id === index);

    updatedMedia.splice(mediaIndex, 1);
    this.setState({
      media: updatedMedia,
    });
  };

  removeNewMediaHandler = index => {
    this.allFilesSize -= this.state.mediaUrl[index].size;
    const updatedMediaUrl = [...this.state.mediaUrl];
    updatedMediaUrl.splice(index, 1);
    this.setState({
      mediaUrl: updatedMediaUrl,
    });
  };

  updatePostHandler = () => {
    if (!this.state.loader) {
      if (this.state.errorContent) {
        alert('Please reduce the number of characters');
        return;
      }

      if (
        this.state.content.length > 0 ||
        (this.state.content.length === 0 &&
          (this.state.mediaUrl.length > 0 || this.state.media.length > 0))
      ) {
        const data = new FormData();
        data.append('_method', 'PUT');
        data.append('name', this.state.name ? this.state.name : '');
        data.append('content', this.state.content);
        data.append('type', 'Buzz');
        /*
              for (let i = 0; i < this.state.subjects.length; i++)
                  data.append('post_subjects[]', this.state.subjects[i]);
              */
        for (let i = 0; i < this.state.mediaUrl.length; i++)
          data.append('post_media[]', JSON.stringify(this.state.mediaUrl[i]));
        for (let i = 0; i < this.state.media.length; i++)
          data.append('post_old_media[]', this.state.media[i].id);
        this.props.updatePost(this.props.post.id, data);
        this.setState({
          mediaUrl: [],
          loader: true,
        });
      }
    }
  };

  contentChangedHandler = event => {
    const input = event.target.value;
    this.setState({
      content: input,
      charsLeftContent: 1000 - input.length,
      errorContent:
        input.length > 1000 ? 'Your post exceeds the maximum length of 1000 characters' : '',
    });
  };

  render() {
    if (!this.props.post) {
      return null;
    }

    // todo: potentially block of dead code
    const nameError = null;
    let descriptionError = null;

    if (this.state.errors) {
      if (this.state.errors['post.content']) {
        descriptionError = (
          <span id="helpBlock" className="help-block text-danger">
            {this.state.errors['post.content'][0]}
          </span>
        );
      }
    }

    const rolePosition = [];
    if (this.props.post.user.role !== '') {
      rolePosition.push(this.props.post.user.role);
    }
    rolePosition.push(this.props.post.user.team.name);

    return (
      <div className="post-wrapper">
        <div className="post-header">
          <div className="author-avatar">
            <img
              alt={`${this.props.post.user.first_name} ${this.props.post.user.last_name}`}
              src={this.props.post.user.image_url}
              onError={e => AVATAR_ERROR(e.target)}
            />
          </div>
          <AuthorInfo post={this.props.post} user={this.props.user} />
          <PinnedPost post={this.props.post} />
          <img
            src={this.props.post.favourite ? favActiveBtn : favBtn}
            data-tip
            data-for={`post_fav_status${this.props.post.id}`}
            className={`heart ${this.props.post.favourite ? 'marked' : ''}`}
            onClick={() => this.props.favPost(this.props.post.id)}
            alt=""
          />
        </div>
        <div className="post-body form-group">
          {this.props.editing ? (
            <>
              <DropzoneWrap fileUploadHandler={this.fileUploadHandler} />
              <EditMediaWrap
                mediaUrl={this.state.mediaUrl}
                media={this.state.media}
                removeNewMediaHandler={this.removeNewMediaHandler}
                removeMediaHandler={this.removeMediaHandler}
              />
            </>
          ) : (
            <div className="post-show-media">
              <PostMedia
                media={this.props.post.media}
                embed={{
                  id: this.props.post.integration_video_id,
                  type: this.props.post.integration_video_type,
                }}
              />
            </div>
          )}
          <div className="post-content">
            {this.props.editing ? (
              <>
                <MentionsInputWrapper
                  value={this.state.content}
                  contentChangedHandler={this.contentChangedHandler}
                  placeholder="Type here..."
                  mentions={GET_MENTIONS_FROM_CONTENT(this.props.post.content)}
                  maxLength="1001"
                />
                {this.state.errorContent ? (
                  <div className="fix-tile-error">
                    <span className="text-danger">{this.state.errorContent}</span>
                  </div>
                ) : (
                  ''
                )}
                {this.props.editing &&
                this.state.content.length === 0 &&
                this.state.media.length === 0 &&
                this.state.mediaUrl.length === 0 ? (
                  <div className="help-block text-danger">
                    Post title cannot be empty unless you add some media
                  </div>
                ) : (
                  ''
                )}
                <span
                  id="save-edit-btn"
                  className={`edit-save btn-sm btn btn-fixed btn-primary ${
                    this.state.loader ? 'disabled' : ''
                  }`}
                  onClick={this.updatePostHandler}
                >
                  {this.state.loader ? 'Saving' : 'Save'}
                </span>
              </>
            ) : (
              <div
                className="post-content-text"
                dangerouslySetInnerHTML={{
                  __html: striptags(this.props.post.content, ['a', 'br']),
                }}
              />
            )}
            {this.props.post.files.map((file, index) => (
              <PostFile file={file} key={index} />
            ))}
            <PostDetails post={this.props.post} showSpaceInfo={this.props.showSpaceInfo} large />
          </div>
          <CommentInput post={this.props.post} />
        </div>
        <div className="post-comments-section">
          <div className="post-comments">
            <PostComments />
          </div>
        </div>
        <FavPostTooltip postId={this.props.post.id} isFavourite={this.props.post.favourite} />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Post));
