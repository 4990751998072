/** @jsx jsx */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setAction, setCurrentNoteModal, setShowActionModal } from 'redux/app/actions';
import { USER_ACTIONS } from 'redux/actions/userActions';
import { CHECK_UNAUTHORIZED, HEADERS } from 'helpers.js';
import { APP_URL } from 'constants.js';
import { CloseButton } from 'components/shared/Modal/UserModal';
import { css, jsx } from '@emotion/core';
import { PLAN_ACTIONS } from 'redux/actions/planActions';
import HeelixModal from 'components/shared/modals/HeelixModal';
import CreatePost from '../../createForms/CreatePost';
import CreateShoutout from '../../createForms/CreateShoutout';
import CreatePoll from '../../createForms/CreatePoll';
import CreateDiscussion from '../../createForms/CreateDiscussion';
import CreateNote from '../../createForms/CreateNote';
import TeamDropdown from './TakeActionModal/TeamDropdown';
import { FEED_STEPS, GROW_STEPS, INSIGHTS_STEPS } from 'onboardingSteps';
import CreateVibe from '../../createForms/CreateVibe';
import MeetingHeader from 'components/vibes/team-vibe-show/partials/Headers/MeetingHeader';
import PreparationNoteWrap from 'components/vibes/team-vibe-show/partials/RightPanel/PreparationNoteWrap';
import CreateQuestionnaire from '../../createForms/CreateQuestionnaire';
import ManagerPromptPanel from 'components/vibes/team-vibe-show/partials/RightPanel/ManagerPromptPanel';
import ManagerPromptHeader from 'components/vibes/team-vibe-show/partials/Headers/ManagerPromptHeader';
import WhatWasDiscussed, {
  Container,
} from 'components/vibes/team-vibe-show/partials/WhatWasDiscussed';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import qs from 'qs';
import AttendeesPanel from 'components/vibes/team-vibe-show/partials/RightPanel/AttendeesPanel';

const feedTabs = ['create-post', 'create-shoutout', 'create-poll', 'create-discussion'];
const growTabs = [
  'create-note',
  'create-note-private',
  'create-note-want-to',
  'create-note-win',
  'create-note-goal',
  'create-note-todo',
  'create-note-progress',
  'create-note-blocked',
  'create-note-complete',
  'create-note-meeting',
  'create-note-meeting-schedule',
  'create-note-meeting-oneoff',
  'create-note-meeting-preparation',
  'create-note-quick-meeting',
  'create-note-not_complete',
  'create-note-manager-prompt',
  'create-note-',
];

class TakeActionModal extends Component {
  state = {
    mediaOn: false,
    filesOn: false,
    recipient: null,
    spaces: [],
    spaceId: null,
    tab: this.props.action ? this.props.action : 'create-post',
    showAlreadyVibedModal: false,
    redirectToYourSchedule: false,
  };

  setRedirectToYourSchedule = redirect => {
    this.setState({ redirectToYourSchedule: redirect });
  };

  componentDidMount() {
    this.getVibeData();
    this.setState({ spaceId: this.props.spaceId });
    this.checkSelectedTab();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.action !== this.state.tab) {
      return this.setState({
        tab: nextProps.action,
        spaceId:
          this.checkIfSpaceFeed() && this.checkIfActionPostedToSpace(nextProps.action)
            ? this.props.spaceId
            : null,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.spaceId !== this.props.spaceId ||
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      if (this.checkIfSpaceFeed()) {
        this.setState({ spaceId: this.props.spaceId });
      } else {
        this.setState({ spaceId: this.props.defaultSpaceId });
      }
    }
    if (
      prevProps.feedTourStep !== this.props.feedTourStep &&
      FEED_STEPS[this.props.feedTourStep]?.key === 'createDiscussionStep' &&
      this.props.onboardingSpace !== null
    ) {
      this.setState({
        spaceId: this.props.onboardingSpace.id,
        spaces: [this.props.onboardingSpace],
      });
    }
    if (
      prevProps.action !== this.props.action ||
      prevProps.feedEnabled !== this.props.feedEnabled ||
      prevProps.growEnabled !== this.props.growEnabled
    ) {
      this.checkSelectedTab();
    }
  }

  checkSelectedTab = () => {
    let tab = this.props.action;
    if (!this.props.feedEnabled && feedTabs.includes(this.props.action)) {
      tab = 'create-note';
    } else if (!this.props.growEnabled && growTabs.includes(this.props.action)) {
      tab = 'create-post';
    }
    if ((!this.props.feedEnabled && !this.props.growEnabled) || tab === 'create-vibe') {
      if (this.props.userAlreadyVibed) {
        tab = 'already-vibed';
      } else {
        tab = 'create-vibe';
      }
    }

    this.setTab(tab);
  };

  checkIfSpaceFeed = () => {
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    return this.props.location.pathname === '/feed' && params.space && /^\d+$/.test(params.space);
  };

  checkIfActionPostedToSpace = action => {
    const postedToSpaceActions = ['create-post', 'create-poll', 'create-discussion'];
    return postedToSpaceActions.includes(action);
  };

  getVibeData = () => {
    const mobileParam = '';
    const headers = HEADERS();
    fetch(`${APP_URL}/vibes/get_data${mobileParam}`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (response.ok && response.status === 200) {
          response.json().then(jsonResponse => {
            this.setState(jsonResponse);
          });
        }
      })
      .catch(console.error);
  };

  closeVibeModal = (finished = false) => {
    const { updateUserAlreadyVibed } = this.props;
    if (finished) {
      updateUserAlreadyVibed();
      this.closeModal(true);
    }
    this.closeModal();
  };

  getTab = () => {
    switch (this.state.tab) {
      case 'create-post':
        return (
          <CreatePost
            changeTab={tab => this.changeTabHandler(null, tab)}
            spaceId={this.state.spaceId}
            togglePoll={() =>
              this.changeTabHandler(
                null,
                this.state.tab === 'create-post' ? 'create-poll' : 'create-post'
              )
            }
            isPollToggled={this.state.tab === 'create-poll'}
            closePopup={() => this.closeModal(true)}
          />
        );
      case 'create-shoutout':
        return (
          <CreateShoutout
            spaceId={this.state.spaceId}
            changeTab={tab => this.changeTabHandler(null, tab)}
            closePopup={() => this.closeModal(true)}
            recipientChanged={this.recipientSelectedHandler}
          />
        );

      case 'create-poll':
        return (
          <CreatePoll
            spaceId={this.state.spaceId}
            togglePoll={() =>
              this.changeTabHandler(
                null,
                this.state.tab === 'create-post' ? 'create-poll' : 'create-post'
              )
            }
            changeTab={tab => this.changeTabHandler(null, tab)}
            isPollToggled={this.state.tab === 'create-poll'}
            closePopup={() => this.closeModal(true)}
          />
        );
      case 'create-vibe':
        return (
          <CreateVibe
            vibe_factors={this.state.vibe_factors}
            feel_factors={this.state.feel_factors}
            links={this.state.links}
            raw_text={this.state.raw_text}
            scale={this.state.scale}
            sentimentChanged=""
            images={this.state.images}
            enable_vibe_comments={this.state.enable_vibe_comments}
            tileRealignHandler={this.tileRealignHandler}
            fetchPostsOnClose={this.fetchPostsOnClose}
            closePopup={this.closeVibeModal}
          />
        );
      case 'already-vibed':
        return (
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              height: 200px;
              padding-bottom: 10px;
              background: #f3f3f3;
            `}
          >
            {' '}
            You've already vibed this week.
          </div>
        );
      case 'create-note':
      case 'create-note-private':
      case 'create-note-want-to':
      case 'create-note-win':
      case 'create-note-goal':
      case 'create-note-todo':
      case 'create-note-progress':
      case 'create-note-blocked':
      case 'create-note-complete':
      case 'create-note-meeting':
      case 'create-note-meeting-preparation':
      case 'create-note-meeting-schedule':
      case 'create-note-meeting-oneoff':
      case 'create-note-quick-meeting':
      case 'create-note-not_complete':
      case 'create-note-manager-prompt-preparation':
      case 'create-note-manager-prompt':
      case 'create-note-':
        console.log(this.state.tab);
        return (
          <CreateNote
            spaceId={this.state.spaceId}
            action={this.state.tab}
            closePopup={() => this.closeModal(true)}
            setRedirectToYourSchedule={this.setRedirectToYourSchedule}
          />
        );
      case 'create-questionnaire':
        return (
          <CreateQuestionnaire
            spaceId={this.state.spaceId}
            changeTab={tab => this.changeTabHandler(null, tab)}
            closePopup={() => this.closeModal(true)}
          />
        );
      case 'create-discussion':
      default:
        return (
          <CreateDiscussion
            spaceId={this.state.spaceId}
            changeTab={tab => this.changeTabHandler(null, tab)}
            closePopup={() => this.closeModal(true)}
          />
        );
    }
  };

  changeTabHandler = (e, element = null) => {
    if (this.props.isConverting) return;
    if (FEED_STEPS[this.props.feedTourStep]?.key === 'createDiscussionStep') return;
    const { setAction } = this.props;
    let tab = '';
    if (e) {
      tab = e.currentTarget.getAttribute('tab');
    } else {
      tab = element;
    }
    if (tab === 'create-vibe' && this.props.userAlreadyVibed) {
      this.setState({ showAlreadyVibedModal: true });
      return;
    }
    if (tab === this.state.tab && tab !== 'create-vibe') {
      if (
        tab.includes('create-shoutout') ||
        tab.includes('create-poll') ||
        tab.includes('create-questionnaire') ||
        tab.includes('create-discussion')
      ) {
        tab = 'create-post';
      } else {
        tab = 'create-note';
      }
    }
    this.setState({ tab });
    setAction(tab);
  };

  recipientSelectedHandler = (recipient, sharedTeams) => {
    this.setState({
      recipient,
      spaces: sharedTeams,
    });
  };

  changeSpace = id => {
    const currentSpaceId = this.state.spaceId;
    try {
      this.setState({ spaceId: id });
    } catch (err) {
      this.setState({ spaceId: currentSpaceId });
    }
  };

  closeModal = (reset = false) => {
    if (reset) {
      this.props.closeMeetingModal();
      this.props.resetSettings();
      this.setTab('create-post');
    }
    if (
      ['sharePostStep', 'giveGetFeedbackStep', 'createDiscussionStep'].includes(
        FEED_STEPS[this.props.feedTourStep]?.key
      ) ||
      INSIGHTS_STEPS[this.props.insightsTourStep]?.key === 'giveGetFeedbackStep' ||
      [
        'addGoalContentStep',
        'postGoalStep',
        'convertGoalToWinStep',
        'winConvertFinishStep',
      ].includes(GROW_STEPS[this.props.growTourStep]?.key)
    ) {
      return true;
    }
    if (this.state.redirectToYourSchedule) {
      this.setState({ redirectToYourSchedule: false });
      this.props.history.push('/your_schedule');
    }
    this.props.setCurrentNoteModal({});
    this.props.setShowActionModal(false);
  };

  setTab = tab => {
    this.setState({ tab });
    this.props.setAction(tab);
  };

  render() {
    const {
      showActionModal,
      noteBody,
      isConverting,
      growEnabled,
      feedEnabled,
      setAction,
      currentNoteModal,
      user,
    } = this.props;
    const { tab } = this.state;
    const spacesList =
      tab === 'create-shoutout' ||
      (tab === 'create-discussion' &&
        FEED_STEPS[this.props.feedTourStep]?.key === 'createDiscussionStep')
        ? this.state.spaces.length > 0
          ? this.state.spaces
          : this.props.spaces
        : this.props.spaces;
    const isMeetingTab =
      tab === 'create-note-meeting-preparation' ||
      tab === 'create-note-meeting' ||
      tab === 'create-note-quick-meeting';
    const meeting = tab === 'create-note-meeting' && noteBody.preparationNote;
    const isManagerPrompt = tab === 'create-note-manager-prompt';
    const isMeetingSchedule =
      tab === 'create-note-meeting-schedule' || tab === 'create-note-meeting-oneoff';
    const quickMeeting = tab === 'create-note-quick-meeting';

    const shouldRenderWhatWasDiscussed =
      currentNoteModal &&
      currentNoteModal.previous_meeting &&
      this.state.tab === 'create-note-meeting-preparation';

    const shouldRenderAttendeesNotes = Boolean(
      currentNoteModal.attendees_preparation_notes?.length
    );

    const withRightPanel = shouldRenderWhatWasDiscussed || shouldRenderAttendeesNotes;

    return (
      <Modal
        show={showActionModal}
        onHide={() => this.closeModal(isMeetingTab || isManagerPrompt || isMeetingSchedule)}
        className={`take-action-modal ${meeting || isManagerPrompt ? 'meeting' : ' '} ${
          quickMeeting ? 'quick-meeting' : ''
        } ${withRightPanel ? 'with-right-panel' : ' '}`}
        keyboard
        backdrop
      >
        <div className="create-mobile-view-wrapper">
          <div
            className="create-posts-feed"
            css={css`
              ${(meeting || isManagerPrompt) && 'width: 70%; float: left;'}
            `}
          >
            <div
              className="create-forms-wrap"
              css={css`
                box-shadow: none !important;
              `}
            >
              {isManagerPrompt ? (
                <ManagerPromptHeader />
              ) : isMeetingTab ? (
                <MeetingHeader
                  isMeeting={tab === 'create-note-meeting'}
                  isQuickMeeting={quickMeeting}
                />
              ) : isConverting && tab === 'create-questionnaire' ? null : (
                <div className="menu-outer-wrap">
                  <div className="menu">
                    {!isConverting && (
                      <>
                        <div
                          id="create-vibe-tab-btn"
                          tab="create-vibe"
                          active={tab === 'create-vibe' ? 'true' : ''}
                          onClick={this.changeTabHandler}
                        >
                          Vibe
                        </div>
                        {feedEnabled && (
                          <div
                            id="create-post-tab-btn"
                            tab="create-post"
                            active={
                              tab === 'create-post' ||
                              tab === 'create-shoutout' ||
                              tab === 'create-discussion' ||
                              tab === 'create-poll' ||
                              tab === 'create-questionnaire'
                                ? 'true'
                                : ''
                            }
                            onClick={this.changeTabHandler}
                          >
                            Connect
                          </div>
                        )}
                      </>
                    )}
                    {growEnabled && (
                      <div
                        id="create-note-tab-btn"
                        tab="create-note"
                        active={
                          tab.includes('create-note') || tab === 'meetingSchedule' ? 'true' : ''
                        }
                        onClick={this.changeTabHandler}
                      >
                        Perform
                      </div>
                    )}
                    <div
                      css={css`
                        pointer-events: none;
                      `}
                    >
                      &nbsp;
                    </div>
                  </div>
                </div>
              )}
              {!isMeetingTab && !isManagerPrompt && <div className="border-shadow" />}
              {this.getTab()}
              {tab !== 'create-vibe' &&
                tab !== 'already-vibed' &&
                !tab.startsWith('create-note') &&
                !(tab === 'create-questionnaire' && noteBody.id) && (
                  <TeamDropdown
                    changeSpace={this.changeSpace}
                    spaces={spacesList}
                    spaceId={this.state.spaceId}
                    tab={tab}
                    disabled={tab === 'create-shoutout' && !this.state.recipient}
                    postToTeam={tab === 'create-questionnaire'}
                  />
                )}
            </div>
          </div>
          {meeting && <PreparationNoteWrap />}
          {isManagerPrompt && (
            <ManagerPromptPanel resetActionModalTab={() => this.setTab('create-post')} />
          )}
          <HeelixModal
            showModal={this.state.showAlreadyVibedModal}
            hideModal={() => this.setState({ showAlreadyVibedModal: false })}
            height="85"
            title="No action needed here!"
          >
            <h2>You've already vibed this week.</h2>
          </HeelixModal>
          <CloseButton onClick={() => this.closeModal(true)} />
        </div>
        {withRightPanel && (
          <Container
            css={css`
              background-color: ${shouldRenderAttendeesNotes ? '#fff9e0' : '#fffff'};
            `}
          >
            {shouldRenderAttendeesNotes && (
              <AttendeesPanel notes={currentNoteModal.attendees_preparation_notes} />
            )}
            {shouldRenderWhatWasDiscussed && (
              <WhatWasDiscussed previousMeeting={currentNoteModal.previous_meeting} user={user} />
            )}
          </Container>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  noteBody: state.app.body,
  teams: state.spacesDomain.spaces,
  spaceId: state.usersDomain.spaceId,
  defaultSpaceId: state.usersDomain.defaultSpaceId,
  plan: state.plansDomain.plan,
  spaces: state.spacesDomain.spaces,
  userAlreadyVibed: !!state.usersDomain.user && state.usersDomain.user.alreadyVibed,
  action: state.app.action,
  isConverting: Boolean(state.app.body.converting),
  showActionModal: state.app.showActionModal,
  isFreePlan: state.plansDomain.plan === 'free' && !state.usersDomain.user.isAccountAdmin,
  feedTourStep: state.onboardingDomain.feedTourStep,
  insightsTourStep: state.onboardingDomain.insightsTourStep,
  growTourStep: state.onboardingDomain.growTourStep,
  onboardingSpace: state.onboardingDomain.onboardingSpace,
  isLeadOrAdmin:
    state.usersDomain.user &&
    Boolean(
      state.usersDomain.user.isAccountAdmin || state.usersDomain.user.formalLeadTeamsIds.length
    ),
  feedEnabled: state.usersDomain.feedEnabled,
  growEnabled: state.usersDomain.growEnabled,
  currentNoteModal: state.app.currentNoteModal,
  user: state.usersDomain.user,
});

const mapDispatch = {
  updateUserAlreadyVibed: USER_ACTIONS.updateUserAlreadyVibed,
  showUpgradeModal: PLAN_ACTIONS.showUpgradeModal,
  resetSettings: ONBOARDING_ACTIONS.resetSettings,
  closeMeetingModal: ONBOARDING_ACTIONS.closeMeetingModal,
  setAction,
  setShowActionModal,
  setCurrentNoteModal,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(TakeActionModal));
