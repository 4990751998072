import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { APP_URL, AWS_S3_URL } from '../../../constants';
import { LOCALSTORAGE_GET } from '../../../helpers';

class All extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: [],
      infoAccount: [],
      search: this.props.search,
      column: '',
      order: '',
      user: {},
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.search !== this.props.search) {
      this.setState({ search: nextProps.search }, () => {
        this.fetchData();
      });
    }
  }

  fetchData(order = null) {
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);
    let url = `/admin/accounts?tab=all&search=${this.state.search}`;
    if (order) {
      url += order;
    }
    fetch(APP_URL + url, { method: 'get', headers })
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({
            accounts: jsonResponse.accounts,
            infoAccount: jsonResponse.info_account,
            column: jsonResponse.column,
            order: jsonResponse.order,
            user: jsonResponse.user,
          });
          this.props.updateAccountsCount(jsonResponse.accounts_count);
        });
      })
      .catch(() => {});
    if (url !== this.props.location.pathname + this.props.location.search) {
      this.props.history.push(url);
    }
  }

  imgSrc(account) {
    const defaultTeam = this.state.infoAccount[account.id];

    if (defaultTeam) {
      return defaultTeam.image_uid
        ? defaultTeam.image_url
        : `${AWS_S3_URL}public/images/defaults/team.jpg`;
    }
  }

  accountState(account) {
    const { plan } = this.state.infoAccount[account.id];
    if (plan && plan.free === false) {
      if (plan.managed) {
        return <div className="label label-info">{plan.name}</div>;
      }
      return <div className="label label-success">Subscribed</div>;
    }
  }

  warning(account) {
    if (this.state.infoAccount[account.id].vibe) {
      return <div className="label label-warning">Vibe</div>;
    }
    if (this.state.infoAccount[account.id].response) {
      return <div className="label label-warning">Response</div>;
    }
  }

  downloadAccountReport(e, accountId) {
    e.preventDefault();
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);
    fetch(`${APP_URL}/admin/accounts/${accountId}/download_report`, { method: 'get', headers })
      .then(response => {
        response.json().then(jsonResponse => {
          this.downloadCsv(jsonResponse.data, jsonResponse.fileName);
        });
      })
      .catch(() => {});
  }

  downloadCsv(data, name) {
    let csv = '';
    data.forEach(row => {
      csv += row.join(',');
      csv += '\n';
    });
    if (navigator.msSaveBlob) {
      // IE 10+
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      navigator.msSaveBlob(blob, name);
    } else {
      const hiddenElement = document.createElement('a');
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = `${name}.csv`;
      document.body.appendChild(hiddenElement);
      hiddenElement.click();
      document.body.removeChild(hiddenElement);
    }
  }

  orderBy(e, column, order = 'desc') {
    e.preventDefault();
    if (column === this.state.column) {
      order = this.state.order === 'desc' ? 'asc' : 'desc';
    }
    this.fetchData(`&order[${column}]=${order}`);
  }

  setIcon(name) {
    const caret = this.state.column !== name ? 'left' : this.state.order === 'asc' ? 'up' : 'down';
    return <i className={`fa fa-caret-${caret}`} />;
  }

  render() {
    return (
      <table className="table">
        <tbody>
          <tr>
            <th />
            <th onClick={e => this.orderBy(e, 'name')}>
              <a href="#">
                Name
                {this.setIcon('name')}
              </a>
            </th>
            <th onClick={e => this.orderBy(e, 'created_at')}>
              <a href="#">
                Signed up
                {this.setIcon('created_at')}
              </a>
            </th>
            <th>No. Users</th>
            <th>State</th>
            <th>Warning</th>
            <th>Report</th>
            <th>Impersonate</th>
          </tr>
          {this.state.accounts.map(account => (
            <tr key={account.id}>
              <td style={{ width: '40px' }}>
                <img
                  src={this.imgSrc(account)}
                  alt={account.name}
                  className="shadow"
                  style={{ height: '20px' }}
                />
              </td>
              <td>
                <Link to={`/admin/accounts/${account.id}`} className="primary-link">
                  {account.name}
                </Link>
              </td>
              <td className="hidden-sm hidden-xs">
                <TimeAgo date={`${account.created_at} ${this.state.user.time_zone}`} />
              </td>
              <td>
                {this.state.infoAccount[account.id].user_active_count} (
                {this.state.infoAccount[account.id].user_invited_count})
              </td>
              <td>{this.accountState(account)}</td>
              <td className="text-center">{this.warning(account)}</td>
              <td className="text-center">
                <Link to="#" onClick={e => this.downloadAccountReport(e, account.id)}>
                  <i className="fa fa-download" />
                </Link>
              </td>
              <td className="text-center">
                {this.state.infoAccount[account.id].user && (
                  <Link
                    to={`/admin/impersonate/${this.state.infoAccount[account.id].user.id}`}
                    className="primary-link"
                  >
                    <i className="fa fa-home" />
                  </Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
export default All;
