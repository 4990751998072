/** @jsx jsx */
import React, { Component } from 'react';
import { css, jsx } from '@emotion/core';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AVATAR_ERROR } from 'helpers.js';
import { toggleUserModal } from 'redux/app/actions';
import { CloseButton } from 'components/shared/Modal/UserModal';
import SpaceLogo from '../../spaces/SpaceLogo';
import VibeDoughnut from '../../vibes/VibeDoughnut';
import PropTypes from 'prop-types';

const mapDispatchToProps = dispatch => ({
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
});
const mapStateToProps = state => ({
  user: state.usersDomain.user,
  insightsTeams: state.teamsDomain.teams,
});

class TeamModal extends Component {
  state = {
    visibleUsersCount: 20,
  };

  render() {
    this.props.teams
      .filter(stateTeam => stateTeam.parent_ids.indexOf(this.props.team.id) > -1)
      .reduce((a, b) => [...a, ...b.users], []);
    const usersHelper = [
      ...this.props.teams
        .filter(stateTeam => stateTeam.parent_ids.indexOf(this.props.team.id) > -1)
        .reduce((a, b) => [...a, ...b.users], []),
      ...this.props.team.users,
    ];
    const uninvitedCount = usersHelper.filter(user => user.state === 'pending').length;
    const inactiveCount = usersHelper.filter(user => user.state === 'invited').length;
    const subsCount = this.props.teams.filter(team => team.parent_id === this.props.team.id).length;
    const shouldSeeButton =
      this.props.insightsTeams.filter(team => team.id === this.props.team.id).length > 0;
    const vibeValue = this.props.team.vibe_stats?.length > 0 ? this.props.team.vibe_stats[0] : null;
    return (
      <Modal
        show={this.props.showTeamModal}
        onHide={this.props.close}
        dialogClassName="team-modal"
        backdrop
        keyboard
      >
        <CloseButton
          css={css`
            top: 7px;
            right: 7px;
            cursor: pointer;
            @media (min-width: 500px) {
              display: none;
            }
          `}
          onClick={this.props.close}
        />
        <div
          css={css`
            @media (max-width: 500px) {
              display: none;
            }
          `}
          className="close-cross"
          onClick={this.props.close}
        />
        <div className="team-modal-header-wrap">
          <SpaceLogo space={this.props.team} className="team-letters" />
          <h1>{this.props.team.name}</h1>
          {/* <div className='request-access'>Request access</div> */}
          {this.props.user.isAccountAdmin && (
            <div
              className={`team-score pull-right ${
                vibeValue ? (vibeValue.trend > 0 ? 'positive-trend' : 'negative-trend') : ''
              }`}
            >
              <Link to={`/vibes/dashboard?team=${this.props.team.id}&period=30`}>
                {vibeValue ? (
                  <VibeDoughnut
                    score={vibeValue.value}
                    trend={vibeValue.trend}
                    text={vibeValue.value}
                    primary_color={vibeValue.value > 0 ? '#11A9FF' : '#FC7355'}
                    width={46}
                    height={46}
                    cutout={75}
                  />
                ) : (
                  <VibeDoughnut
                    score={null}
                    text="?"
                    trend={null}
                    width={46}
                    height={46}
                    cutout={75}
                  />
                )}
              </Link>
            </div>
          )}
          {!this.props.dashboard && shouldSeeButton && (
            <Link
              to={{
                pathname: '/vibes/dashboard',
                search: `?team=${this.props.team.id}&period=30`,
              }}
              onClick={this.props.close}
              className="btn btn-primary"
              css={css`
                background: #11a9ff;
                border-radius: 4px;
                color: #ffffff;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                padding: 9px 19px;
                margin-left: ${this.props.user.isAccountAdmin ? '20px' : 'auto'};
                @media (max-width: 500px) {
                  margin-left: 10px;
                }
              `}
            >
              View dashboard
            </Link>
          )}
        </div>
        <div className="members-wrap">
          <h2>
            {usersHelper.length} member
            {usersHelper.length !== 1 ? 's' : ''}{' '}
            {uninvitedCount || inactiveCount ? (
              <>
                ({inactiveCount ? `${inactiveCount} non-activated` : ''}
                {uninvitedCount && inactiveCount ? ', ' : ''}
                {uninvitedCount ? `${uninvitedCount} not invited` : ''})
                {this.props.user.isAccountAdmin &&
                !this.props.dashboard &&
                this.props.seeReviewButton ? (
                  <span onClick={this.props.issuesMode}>Review now</span>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </h2>
          {usersHelper
            .filter(user => user.state === 'active')
            .sort((a, b) =>
              (a.last_name ? a.last_name.toLowerCase() : '') >
              (b.last_name ? b.last_name.toLowerCase() : '')
                ? 1
                : -1
            )
            .map((user, index) =>
              index < this.state.visibleUsersCount ? (
                <Link
                  key={user.id}
                  to={`/user/${user.id}`}
                  onClick={e => {
                    e.preventDefault();
                    this.props.close();
                    return this.props.toggleUserModal(true, user.id);
                  }}
                >
                  <img src={user.image_url} onError={e => AVATAR_ERROR(e.target)} alt="avatar" />
                  <div className="user-info-wrap">
                    <span className="name">
                      {user.first_name} {user.last_name}
                    </span>
                    {user.role ? <span className="role">{user.role}</span> : ''}
                  </div>
                </Link>
              ) : (
                ''
              )
            )}
          {usersHelper.filter(user => user.state === 'active').length >
          this.state.visibleUsersCount ? (
            <div
              className="unfold-users"
              onClick={() =>
                this.setState({ visibleUsersCount: this.state.visibleUsersCount + 20 })
              }
            >
              +
              {usersHelper.filter(user => user.state === 'active').length -
                this.state.visibleUsersCount}{' '}
              more
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="subs-wrap">
          <h2>
            {subsCount} sub
            {subsCount !== 1 ? 's' : ''}
          </h2>
          {this.props.teams
            .filter(team => team.parent_id === this.props.team.id)
            .map(team => {
              const subMembersCount =
                this.props.teams
                  .filter(stateTeam => stateTeam.parent_ids.indexOf(team.id) > -1)
                  .map(stateTeam => stateTeam.users.length)
                  .reduce((a, b) => a + b, 0) + team.users.length;
              return (
                <div key={team.id} className="sub-tile">
                  <span
                    className="team-letters"
                    style={{
                      borderColor: team.thumbnail_details.colors.border,
                      backgroundColor: team.thumbnail_details.colors.background,
                      color: team.thumbnail_details.colors.letters,
                    }}
                  >
                    {team.thumbnail_details.letters}
                  </span>
                  <div className="team-info-wrap">
                    <h1>{team.name}</h1>
                    <div className="sub-members-count">
                      {subMembersCount} member
                      {subMembersCount !== 1 ? 's' : ''}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Modal>
    );
  }
}

TeamModal.propTypes = {
  seeReviewButton: PropTypes.bool,
};

TeamModal.defaultProps = {
  seeReviewButton: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamModal);
