/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { USER_ACTIONS } from 'redux/actions/userActions';
import OnboardingButton from './partials/OnboardingButton';

const Wrapper = styled.div`
  padding: 30px 35px;
  text-align: left;
`;

const Description = styled.div`
  color: #323232;
  font-size: 14px;
  line-height: 21px;
  font-weight: normal;
`;
const OnboardingSamplePosts = ({ hideSamplePosts, hiddenSamplePosts }) => {
  return (
    <Wrapper>
      <Description>
        Press the button below to bring back all demo content.
        <br />
        Click it again to toggle it back off.
      </Description>
      <OnboardingButton
        label={`Turn demo content ${hiddenSamplePosts ? 'on' : 'off'}`}
        submit
        left={0}
        onClick={() => hideSamplePosts()}
      />
    </Wrapper>
  );
};

const mapDispatchToProps = dispatch => ({
  hideSamplePosts: () => dispatch(USER_ACTIONS.hideSamplePosts()),
});

const mapStateToProps = state => ({
  hiddenSamplePosts: state.usersDomain.user.hideSamplePosts,
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingSamplePosts);
