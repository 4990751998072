import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WelcomeContainer from '../WelcomeContainer';
import { PASSWORD_REGEX } from '../../../helpers';

const InvitePassword = ({ handlePassword }) => {
  const [passwordError, setPasswordError] = useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    const password = e.target.password.value;
    if (!PASSWORD_REGEX.test(password)) {
      return setPasswordError(true);
    }
    handlePassword(password);
  };

  const setTitle = () => {
    if (passwordError) return <span className="error-color">Something's not right...</span>;
    return 'Almost there!';
  };

  return (
    <WelcomeContainer
      className="text-center"
      title={setTitle()}
      body={
        <>
          <div className="password-set">
            <div>Create a password for your new account.</div>
            <form
              onSubmit={handleSubmit}
              action=""
              className="simple_form form-compact password-form"
              id="new_user"
            >
              <div className="form-group  required password ">
                <input
                  required
                  name="password"
                  type="password"
                  className={`string email required form-control ${passwordError ? 'error' : ''}`}
                  placeholder="Password"
                />
              </div>
              <div className={`password-description ${passwordError ? 'description-error' : ''}`}>
                Must be at least 8 characters long and contain one digit, one special character, one
                uppercase and one lowercase character
              </div>
              <button type="submit" className="btn btn-primary btn-full" id="sign-in-btn">
                Set password
              </button>
            </form>
          </div>
        </>
      }
    />
  );
};

InvitePassword.propTypes = {
  handlePassword: PropTypes.func.isRequired,
};

export default InvitePassword;
