import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import striptags from 'striptags';

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 23px
  color: #323232;
  text-align: left;
  padding-bottom: 10px;
`;

const WeeklySummaryWrap = styled.div`
  border: 1px solid #eaeaea;
  font-size: 15px;
  padding: 25px 29px;
  margin-top: 25px;
`;

const FormattedDate = styled.span`
  color: #706e84;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  padding-left: 9px;
`;
const SeeMore = styled.span`
  color: #11a9ff;
  cursor: pointer;
`;

const WeeklySummary = ({ content, date }) => {
  const [showAll, setShowAll] = useState(false);
  const showSeeMoreBtn = content.length > 250;
  const trimmedContent = `${content.substring(0, 250)}...`;
  const seeMore = ' See more';
  const seeLess = ' See less';
  const contentToShow = showAll || !showSeeMoreBtn ? content : trimmedContent;
  return (
    <WeeklySummaryWrap>
      <Title>
        Weekly Summary
        <FormattedDate>{date}</FormattedDate>
      </Title>
      <span
        dangerouslySetInnerHTML={{
          __html: striptags(contentToShow, ['br']),
        }}
      />
      {showSeeMoreBtn && (
        <SeeMore
          onClick={() => {
            setShowAll(!showAll);
          }}
        >
          {showAll ? seeLess : seeMore}
        </SeeMore>
      )}
    </WeeklySummaryWrap>
  );
};

WeeklySummary.propTypes = {
  content: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default WeeklySummary;
