import DOMAINS from '../domains';

export const INITIAL_ACTION_TYPES = {
  FETCH: 'FETCH',
  RECEIVE: 'RECEIVE',
  FETCH_ACCOUNT_DATA: 'FETCH_ACCOUNT_DATA',
  RECEIVE_ACCOUNT_DATA: 'RECEIVE_ACCOUNT_DATA',
};
export const INITIAL_ACTIONS = {
  // --Used mostly in React components during dispatch
  fetchData: () => ({ domain: DOMAINS.INITIAL, type: INITIAL_ACTION_TYPES.FETCH }),
  receiveData: json => ({
    domain: DOMAINS.INITIAL,
    type: INITIAL_ACTION_TYPES.RECEIVE,
    payload: json,
  }),
  fetchAccountData: () => ({
    domain: DOMAINS.INITIAL,
    type: INITIAL_ACTION_TYPES.FETCH_ACCOUNT_DATA,
  }),
  receiveAccountData: json => ({
    domain: DOMAINS.INITIAL,
    type: INITIAL_ACTION_TYPES.RECEIVE_ACCOUNT_DATA,
    payload: json,
  }),
};
