import React from 'react';
import { EVENTS, STATUS } from 'react-joyride';
import { connect } from 'react-redux';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import { withRouter } from 'react-router-dom';
import { clearActionBody, setAction, setShowActionModal } from 'redux/app/actions';
import { GROW_ACTIONS } from 'redux/actions/growActions';
import { GROW_STEPS } from 'onboardingSteps';
import JoyrideWrapper from 'components/onboarding/JoyrideWrapper';

class GrowTour extends React.Component {
  state = {
    run: true,
    steps: GROW_STEPS,
  };

  componentDidMount() {
    this.finishGrowStep(this.props.growTourStep);
  }

  componentDidUpdate(prevProps) {
    const { run, steps } = this.state;

    if (
      ['clickPlusBtnStep', 'boardStagesStep'].includes(steps[this.props.growTourStep]?.key) &&
      this.props.showActionModal !== prevProps.showActionModal &&
      this.props.showActionModal === true
    ) {
      if (steps[this.props.growTourStep]?.key === 'clickPlusBtnStep') {
        this.props.setAction('create-note-goal');
      }
      this.setState({
        run: false,
      });
      if (
        steps[this.props.growTourStep]?.key === 'clickPlusBtnStep' ||
        (steps[this.props.growTourStep]?.key === 'boardStagesStep' &&
          this.props.action === 'create-note-win')
      ) {
        this.props.setGrowTourStep(this.props.growTourStep + 1);
        setTimeout(() => {
          this.setState({
            run: true,
          });
        }, 1000);
      } else {
        this.props.setGrowTourStep(-1);
      }
    }
    if (
      steps[this.props.growTourStep]?.key === 'setUpMeetingStep' &&
      this.props.growTourStep !== prevProps.growTourStep
    ) {
      this.props.history.push(`/grow_profile?tab=meetings`);
    }
    if (
      [
        'addGoalContentStep',
        'postGoalStep',
        'convertGoalToWinStep',
        'winConvertFinishStep',
        'meetingNoteStep',
        'recurringMeetingStep',
      ].includes(steps[this.props.growTourStep]?.key)
    ) {
      this.props.setShowActionModal(true);
      if (
        ['meetingNoteStep', 'recurringMeetingStep'].includes(steps[this.props.growTourStep]?.key)
      ) {
        this.props.clearActionBody();
        this.props.setAction('create-note-meeting-schedule');
      }
    }

    if (this.props.growTourStep !== prevProps.growTourStep) {
      this.finishGrowStep(this.props.growTourStep);
    }

    if (
      steps[this.props.growTourStep]?.key === 'boardStagesStep' &&
      window.location.pathname !== '/grow_board'
    ) {
      this.setState({ run: false });
      this.props.setGrowTourStep(-1);
    }
  }

  finishGrowStep = step => {
    const { steps } = this.state;
    const stepName = steps[step]?.key;
    switch (stepName) {
      case 'initialGrowStep':
        this.props.finishStep('my_notes');
        break;
      case 'clickPlusBtnStep':
        this.props.finishStep('new_goal');
        break;
      case 'boardStagesStep':
        this.props.finishStep('status');
        break;
      case 'meetingNoteStep':
        this.props.finishStep('meetings');
        break;
    }
  };

  handleJoyrideCallback = data => {
    const { action, index, status, type } = data;
    const { steps } = this.state;

    if (action !== 'close' && [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (steps[index]?.key === 'finalGrowFeedStep') {
        this.props.history.push(`/grow_board`);
      }
      this.props.setGrowTourStep(index + 1);
    } else if (action === 'close' || [STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ run: false });
      this.props.setGrowTourStep(-1);
      if (status === STATUS.FINISHED) {
        this.props.setShowActionModal(false);
        this.props.setFinishedTour('grow');
        this.props.toggleTourModal(true);
      }
      if (this.props.hideSamplePosts) {
        this.props.resetPosts();
        this.props.fetchPosts();
      }
    }
  };

  render() {
    const { steps, run } = this.state;

    return (
      <JoyrideWrapper
        run={run}
        stepIndex={this.props.growTourStep}
        steps={steps}
        callback={this.handleJoyrideCallback}
      />
    );
  }
}

const mapStateToProps = state => ({
  action: state.app.action,
  showActionModal: state.app.showActionModal,
  growTourStep: state.onboardingDomain.growTourStep,
  isLeadOrAdmin: Boolean(
    state.usersDomain.user.isAccountAdmin || state.usersDomain.user.formalLeadTeamsIds.length
  ),
  hideSamplePosts: state.usersDomain.user.hideSamplePosts,
});

const mapDispatch = {
  setAction,
  setShowActionModal,
  clearActionBody,
  setGrowTourStep: step => ONBOARDING_ACTIONS.setGrowTourStep(step),
  toggleTourModal: toggle => ONBOARDING_ACTIONS.toggleTourModal(toggle),
  setFinishedTour: tour => ONBOARDING_ACTIONS.setFinishedTour(tour),
  finishStep: step => ONBOARDING_ACTIONS.finishStep(step),
  resetPosts: () => GROW_ACTIONS.resetPosts(),
  fetchPosts: () => GROW_ACTIONS.fetchPosts(),
};

export default withRouter(connect(mapStateToProps, mapDispatch)(GrowTour));
