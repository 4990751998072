import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import qs from 'qs';
import PropTypes from 'prop-types';

import { USER_ACTIONS } from 'redux/actions/userActions';
import { INITIAL_ACTIONS } from 'redux/actions/initialActions';

import TeamVibeMoodOverTime from 'components/vibes/team-vibe-show/TeamVibeMoodOverTime';
import TeamVibeMoodVsFactor from 'components/vibes/team-vibe-show/TeamVibeMoodVsFactor';
import PerformanceOverTime from 'components/vibes/team-vibe-show/PerformanceOverTime';

import awesomeGray from 'images/insights/awesome-gray.svg';
import goodGray from 'images/insights/good-gray.svg';
import okayGray from 'images/insights/okay-gray.svg';
import badGray from 'images/insights/bad-gray.svg';
import terribleGray from 'images/insights/terrible-gray.svg';

import awesome from 'images/insights/awesome.svg';
import good from 'images/insights/good.svg';
import okay from 'images/insights/okay.svg';
import bad from 'images/insights/bad.svg';
import terrible from 'images/insights/terrible.svg';

import { BODY_CLASSES, LOCALSTORAGE_GET } from 'helpers';
import Factors from 'pages/Insights/Factors';
import Dashboard from './Dashboard';
import { ONBOARDING_ACTIONS } from '../../redux/actions/onboardingActions';
import VibeModal from './shared/VibeModal';
import DetailedFeedback from './DetailedFeedback';

export const getImage = (active, id) => {
  switch (id) {
    case 2:
      return active ? awesome : awesomeGray;
    case 1:
      return active ? good : goodGray;
    case 0:
      return active ? okay : okayGray;
    case -1:
      return active ? bad : badGray;
    case -2:
      return active ? terrible : terribleGray;
    default:
      return active ? okay : okayGray;
  }
};

class Insights extends Component {
  componentDidMount() {
    BODY_CLASSES();
    const { plan, closeFlash, setErrorPage } = this.props;
    closeFlash();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.user.hideSamplePosts !== this.props.user.hideSamplePosts &&
      !this.props.user.hideSamplePosts
    ) {
      const queryParsed = qs.parse(this.props.location.search.substr(1));
      setTimeout(() => {
        const team = this.props.teams.find(t => t.account_id === null);
        if (team) {
          const newQuery = {
            ...queryParsed,
            team: team.id,
          };
          this.props.history.push(`${this.props.location.pathname}?${qs.stringify(newQuery)}`);
        }
      }, 1500);
    }
    if (
      !this.props.user.visited.insights &&
      !this.props.insightsTourFinished &&
      !this.props.displayOnboardingModal
    ) {
      this.props.startInsightsTour();
      this.props.updateVisited('insights');
    }
  }

  vibeModalClose = (finished = false) => {
    if (finished) {
      this.props.updateUserAlreadyVibed();
    } else if (this.props.feedEnabled) {
      this.props.history.push('/');
    } else {
      this.props.updateUserShouldSeeInsightsModal();
    }
  };

  reloadInitialData = () => {
    if (LOCALSTORAGE_GET('user_id') && LOCALSTORAGE_GET('user_id') != this.props.user.id) {
      this.props.initialFetchData();
    }
  };

  render() {
    const { teams, user, location, closeFlash, updateFlash, refreshRecentTeams } = this.props;
    const queryParsed = qs.parse(location.search.substr(1));
    // those query params are required for mobile apps
    if (!queryParsed.team || !queryParsed.period) {
      const userTeam = teams.find(team => team.id === parseInt(user.teamId));
      const newQuery = {
        ...queryParsed,
        team: userTeam.id,
        period: 30,
      };
      return (
        <Redirect
          to={{
            search: `?${qs.stringify(newQuery)}`,
            // keep the old state
            state: location.state,
          }}
        />
      );
    }
    return (
      <>
        <Route
          path="/vibes/dashboard"
          render={routeProps => (
            <Dashboard
              closeFlash={closeFlash}
              updateFlash={updateFlash}
              params={queryParsed}
              reloadInitialData={this.reloadInitialData}
              {...routeProps}
            />
          )}
        />
        <Route
          path="/vibes/performance_over_time"
          render={routeProps => (
            <PerformanceOverTime
              {...routeProps}
              closeFlash={closeFlash}
              updateFlash={updateFlash}
              queryString={queryParsed}
              refreshRecentTeams={refreshRecentTeams}
              hideThisLastWeek
              reloadInitialData={this.reloadInitialData}
            />
          )}
        />
        <Route
          path="/vibes/mood_over_time"
          render={routeProps => (
            <TeamVibeMoodOverTime
              {...routeProps}
              closeFlash={closeFlash}
              updateFlash={updateFlash}
              getImage={this.getImage}
              queryString={queryParsed}
              refreshRecentTeams={refreshRecentTeams}
              hideThisLastWeek
              reloadInitialData={this.reloadInitialData}
            />
          )}
        />
        <Route
          path="/vibes/mood_vs_factor"
          render={routeProps => (
            <TeamVibeMoodVsFactor
              {...routeProps}
              closeFlash={closeFlash}
              updateFlash={updateFlash}
              getImage={this.getImage}
              queryString={queryParsed}
              refreshRecentTeams={refreshRecentTeams}
              hideThisLastWeek
              reloadInitialData={this.reloadInitialData}
            />
          )}
        />
        <Route
          path="/vibes/factor_analysis"
          render={routeProps => (
            <Factors
              {...routeProps}
              closeFlash={closeFlash}
              updateFlash={updateFlash}
              getImage={this.getImage}
              queryString={queryParsed}
              refreshRecentTeams={refreshRecentTeams}
              hideThisLastWeek
              reloadInitialData={this.reloadInitialData}
            />
          )}
        />
        <Route
          path="/vibes/detailed_feedback"
          render={routeProps => (
            <DetailedFeedback
              {...routeProps}
              closeFlash={closeFlash}
              updateFlash={updateFlash}
              getImage={this.getImage}
              queryString={queryParsed}
              refreshRecentTeams={refreshRecentTeams}
              hideThisLastWeek
              reloadInitialData={this.reloadInitialData}
            />
          )}
        />
        <VibeModal vibeModalClose={this.vibeModalClose} />
      </>
    );
  }
}

Insights.propTypes = {
  teams: PropTypes.arrayOf().isRequired,
  user: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  plan: PropTypes.string.isRequired,
  closeFlash: PropTypes.func.isRequired,
  updateFlash: PropTypes.func.isRequired,
  setErrorPage: PropTypes.func.isRequired,
  refreshRecentTeams: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  plan: state.plansDomain.plan,
  teams: state.teamsDomain.teams,
  user: state.usersDomain.user,
  feedEnabled: state.usersDomain.feedEnabled,
  displayOnboardingModal: state.onboardingDomain.displayOnboardingModal,
  insightsTourFinished: state.onboardingDomain.insightsTourFinished,
});

const mapDispatchToProps = {
  refreshRecentTeams: USER_ACTIONS.refreshRecentTeams,
  updateVisited: USER_ACTIONS.updateVisited,
  initialFetchData: INITIAL_ACTIONS.fetchData,
  updateUserAlreadyVibed: USER_ACTIONS.updateUserAlreadyVibed,
  startInsightsTour: ONBOARDING_ACTIONS.startInsightsTour,
  updateUserShouldSeeInsightsModal: USER_ACTIONS.updateUserShouldSeeInsightsModal,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Insights));
