/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';

export const TooltipIcon = props => (
  <span
    css={css`
      width: 14px;
      height: 14px;
      background: #e8e8e8;
      color: #706e84;
      border-radius: 50%;
      display: inline-block;
      font-size: 11px;
      line-height: 15px;
      text-align: center;
      margin: 0 10px;
      cursor: pointer;
      :hover {
        background: #e1f2fa;
      }
    `}
    data-for="performance-icon-tooltip"
    {...props}
  >
    ?
  </span>
);
