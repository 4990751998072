import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { fetchNextUpData } from 'pages/Grow/GrowProfile/utils';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { takeRight } from 'lodash';
import moment from 'moment';

const NextUpWrapper = styled.div`
  margin-top: 6px;
  padding: 0 25px 25px 18px;
  border-bottom: 1px solid #e7e7e7;
`;

const NextUpHeader = styled.h3`
  font-weight: bold;
  font-size: 18px;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ItemDate = styled.span`
  white-space: nowrap;
  font-size: 12px;
  color: #58575f;
  min-width: 80px;
`;

const ItemsWrapper = styled.ul`
  margin-left: 12px;
  font-size: 13px;
  color: #323232;
  list-style: none;
`;

const ItemButton = styled.button`
  border: none;
  background-color: initial;
  outline: none;
  text-align: left;
`;

const ViewAllButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  background: white;
  border-radius: 22px;
  font-size: 12px;
  color: #6a6a6a;
  width: 86px;
  height: 26px;
  line-height: 26px;
  padding-left: 29px;
  padding-right: 11px;
  &:after,
  &:before {
    width: 10px;
    height: 2px;
    background: #d5d5d5;
    border-radius: 1px;
    position: absolute;
    left: 10px;
    top: 50%;
    content: '';
    transition: transform 0.3s;
  }
  &:before {
    transform: ${({ expand }) =>
      expand ? 'translate(0, -50%)' : 'translate(0, -50%) rotateZ(45deg)'};
  }
  &:after {
    transform: ${({ expand }) =>
      expand ? 'translate(0, -50%) rotateZ(90deg)' : 'translate(0, -50%) rotateZ(135deg)'};
  }
`;

const NextUpAgenda = ({ openPostModal, user, history, fetchingPosts }) => {
  const [nextUpData, setData] = useState([]);
  const [viewAllData, setViewAll] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (fetchingPosts) {
      (async () => {
        setIsLoading(true);
        const data = await fetchNextUpData();
        setData(data);
        setIsLoading(false);
      })();
    }
  }, [fetchingPosts]);
  const renderData = viewAllData ? takeRight(nextUpData, 2) : nextUpData;

  return (
    <NextUpWrapper>
      <NextUpHeader>Next up</NextUpHeader>
      {!isLoading && (
        <>
          {renderData.map(nextUpItem => {
            const postsDate = moment(nextUpItem.date);
            const postsDisplayDate = `${postsDate.date()} ${moment.months(postsDate.month())}`;
            return (
              <ItemWrapper>
                <ItemDate>{postsDisplayDate}</ItemDate>
                <ItemsWrapper>
                  {nextUpItem.posts.map(post => {
                    const isMeeting = !post.note;
                    let meetingMember;
                    let meetingType = 'Meeting';
                    if (isMeeting) {
                      meetingMember = user.id !== post.user.id ? post.user : post.member;
                      meetingMember = meetingMember
                        ? meetingMember.first_name + ' ' + meetingMember.last_name
                        : (post.team !==null ?post.team.name: "");
                      meetingType =
                        user.id !== post.user.id && post.team ? 'Team meeting' : 'Meeting';
                    }
                    return (
                      <li>
                        <ItemButton
                          onClick={() => {
                            if (!isMeeting) {
                              openPostModal(post.note, post.id, post.type, true);
                            } else {
                              history.push(`/your_schedule`);
                            }
                          }}
                        >
                          {isMeeting && <span>{`${meetingType} with ${meetingMember}`}</span>}
                          {!isMeeting && <span>{post.note.title}</span>}
                        </ItemButton>
                      </li>
                    );
                  })}
                </ItemsWrapper>
              </ItemWrapper>
            );
          })}
          {nextUpData.length > 2 && (
            <ViewAllButton onClick={() => setViewAll(!viewAllData)} expand={viewAllData}>
              {viewAllData ? 'View all' : 'Collapse'}
            </ViewAllButton>
          )}
        </>
      )}
    </NextUpWrapper>
  );
};

NextUpAgenda.propTypes = {
  openPostModal: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  fetchingPosts: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  fetchingPosts: state.growDomain.fetchingPosts,
});

const mapDispatch = {
  showMeetingModal: (stopShowingButton, settings) =>
    ONBOARDING_ACTIONS.showMeetingModal(stopShowingButton, settings),
};
export default withRouter(connect(mapStateToProps, mapDispatch)(NextUpAgenda));
