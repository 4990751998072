// ---This reducer will be called at each app initialization
import { INITIAL_ACTION_TYPES, INITIAL_ACTIONS } from '../actions/initialActions';
import { POST_ACTIONS } from '../actions/postActions';
import store from '../store';
import { APP_URL, INITIAL_POST_FILTERS, INITIAL_GROW_FILTERS } from '../../constants';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';

const initialReducer = (state, action) => {
  const headers = HEADERS();
  switch (action.type) {
    case INITIAL_ACTION_TYPES.FETCH:
      const url = '/initial';
      fetch(APP_URL + url, { method: 'get', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            localStorage.setItem('user_email', jsonResponse.user.email);
            store.dispatch(INITIAL_ACTIONS.receiveData(jsonResponse));
            store.dispatch(INITIAL_ACTIONS.fetchAccountData());
          });
        });
      return state;
    case INITIAL_ACTION_TYPES.RECEIVE:
      const { teams } = action.payload;
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user: action.payload.user,
          spaceMembers: [],
          usersForShoutoutModal: [],
          spaceId: action.payload.spaceId,
          defaultSpaceId: action.payload.defaultSpaceId,
          unfollowedUsersIds: action.payload.unfollowedUsersIds,
          weeklyDigest: true,
          slackTeamId: action.payload.slackTeamId,
          feedEnabled: action.payload.feedEnabled,
          growEnabled: action.payload.growEnabled,
          requestDeletionStatus: action.payload.requestDeletionStatus,
        },
        plansDomain: {
          ...state.plansDomain,
          plan: action.payload.plan,
          reachedDataLimit: action.payload.reachedDataLimit,
          showUpgradePlanModal: false,
          rbacPlan: action.payload.rbacPlan,
          planSize: action.payload.planSize,
          usersCount: action.payload.usersCount,
          planLimitations: action.payload.planLimitations,
          displayAnnualUpgradeModal: action.payload.displayAnnualUpgradeModal,
          prompt: action.payload.prompt,
          accountAdmin: action.payload.accountAdmin,
          isManaged: action.payload.isManaged,
        },
        teamsDomain: {
          ...state.teamsDomain,
          teams,
          allTeams: action.payload.allTeams,
        },
        postsDomain: {
          ...state.postsDomain,
          filters: {
            ...INITIAL_POST_FILTERS,
            excludedSpaces: action.payload.excludedSpaces,
          },
        },
        growDomain: {
          ...state.growDomain,
          filters: INITIAL_GROW_FILTERS,
          tab: 'grow',
        },
        spacesDomain: {
          ...state.spacesDomain,
          thumbColors: action.payload.thumbColors,
          notificationSettings: [],
          spaces: action.payload.spaces,
        },
        onboardingDomain: {
          ...state.onboardingDomain,
          showTourModal: false,
          finishedTour: null,
          feedTourStep: -1,
          insightsTourStep: -1,
          growTourStep: -1,
          displayOnboardingModal: action.payload.displayOnboardingModal,
          displayMeetingModal: action.payload.displayMeetingModal,
          onboardingTeam: action.payload.onboardingTeam,
          feedTourFinished: false,
          insightsTourFinished: false,
          growTourFinished: false,
        },
      };
    case INITIAL_ACTION_TYPES.FETCH_ACCOUNT_DATA:
      const accountUrl = '/account_data';
      fetch(APP_URL + accountUrl, { method: 'get', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            store.dispatch(INITIAL_ACTIONS.receiveAccountData(jsonResponse));
          });
        });
      return state;
    case INITIAL_ACTION_TYPES.RECEIVE_ACCOUNT_DATA:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          usersForShoutoutModal: action.payload.usersForShoutoutModal,
        },
        tagsDomain: {
          ...state.tagsDomain,
          hashtags: action.payload.hashtags,
          companyValues: action.payload.companyValues,
          goals: action.payload.goals,
          discussionTags: action.payload.discussionTags.map(tag => tag.name),
        },
      };
    default:
      return state;
  }
};
export default initialReducer;
