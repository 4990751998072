import React, { Component } from 'react';
import striptags from 'striptags';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { toggleUserModal } from 'redux/app/actions';
import PostMedia from 'pages/Grow/GrowPost/PostMedia';
import PostDetails from 'components/shared/posts/PostDetails';
import AuthorInfo from 'components/shared/posts/AuthorInfo';
import PinnedPost from 'components/post/shared/PinnedPost';
import CommentInput from '../post/shared/CommentInput';
import PostDropdown from './PostDropdown';
import PostTileComments from './PostTileComments';
import { POST_ACTIONS } from '../../redux/actions/postActions';
import { AVATAR_ERROR } from '../../helpers';
import PostFile from './PostFile';
import favBtn from '../../images/feed/star.png';
import favActiveBtn from '../../images/feed/star_active.png';
import FavPostTooltip from '../grow/tooltips/FavPostTooltip';
import MetaInfo from './MetaInfo';

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  hashtags: state.tagsDomain.hashtags,
});

const mapDispatchToProps = dispatch => ({
  favPost: (postId, type) => dispatch(POST_ACTIONS.favPost(postId, type)),
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
});

class PostTile extends Component {
  state = {
    errorAction: null,
    unfollowVisible: false,
    lightboxIsOpen: false,
    currentImage: 0,
  };

  componentDidMount() {
    this.setState({
      tags: this.props.tags,
      unfollowVisible: true, // this.props.auth.id !== this.props.fix.user.id,
    });
  }

  render() {
    let content = (
      <>
        {this.props.post.name ? <div className="post-tile-title">{this.props.post.name}</div> : ''}
        <div
          className="post-tile-content"
          dangerouslySetInnerHTML={{
            __html: striptags(this.props.post.content, ['a', 'br']),
          }}
        />
      </>
    );

    if (this.props.post.id > 0) {
      content = <Link to={`/post/${this.props.post.id}`}>{content}</Link>;
    }

    const rolePosition = [];
    if (this.props.post.user.role !== '') {
      rolePosition.push(this.props.post.user.role);
    }
    rolePosition.push(this.props.post.user.team.name);

    return (
      <div className="col-lg-6 col-xs-12 post-tile-wrap initial-hide">
        <div className="post-tile">
          <div className="post-tile-header">
            <div className="author-avatar">
              <img
                alt={`${this.props.post.user.first_name} ${this.props.post.user.last_name}`}
                src={this.props.post.user.image_url}
                onError={e => AVATAR_ERROR(e.target)}
              />
            </div>
            <AuthorInfo post={this.props.post} user={this.props.user} />
            <PinnedPost post={this.props.post} />
            <img
              src={this.props.post.favourite ? favActiveBtn : favBtn}
              data-tip
              data-for={`post_fav_status${this.props.post.id}`}
              className={`heart ${this.props.post.favourite ? 'marked' : ''}`}
              onClick={() => this.props.favPost(this.props.post.id, this.props.type)}
              alt=""
            />
            <PostDropdown type={this.props.type} post={this.props.post} />
          </div>
          <div className="post-tile-body">
            <PostMedia
              media={this.props.post.media}
              embed={{
                id: this.props.post.integration_video_id,
                type: this.props.post.integration_video_type,
              }}
            />
            {content}
            {this.props.post.files.map((file, index) => (
              <PostFile file={file} key={index} />
            ))}
            <PostDetails post={this.props.post} showSpaceInfo={this.props.showSpaceInfo} />
            <CommentInput
              post={this.props.post}
              tileRealignHandler={this.props.tileRealignHandler}
              type={this.props.type}
            />
          </div>
          <div className="post-tile-comments-section">
            <div className="post-tile-comments">
              <PostTileComments
                post={this.props.post}
                user={this.props.user}
                type={this.props.type}
              />
            </div>
          </div>
          <FavPostTooltip postId={this.props.post.id} isFavourite={this.props.post.favourite} />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostTile));
