const scoreColors = [
  {
    score: 70,
    backgroundColor: '#55A600',
    secondaryColor: '#AAD27F',
    color: '#FFFFFF',
  },
  {
    score: 40,
    backgroundColor: '#6FC022',
    secondaryColor: '#b8db94',
    color: '#FFFFFF',
  },
  {
    score: 15,
    backgroundColor: '#A0CC42',
    secondaryColor: '#cbe5af',
    color: '#FFFFFF',
  },
  {
    score: -15,
    backgroundColor: '#F5DC9A',
    secondaryColor: '#FFF6DD',
    color: '#323232',
  },
  {
    score: -39,
    backgroundColor: '#F1825A',
    secondaryColor: '#FFC4B1',
    color: '#FFFFFF',
  },
  {
    score: -69,
    backgroundColor: '#F3613F',
    secondaryColor: '#FFC4B1',
    color: '#FFFFFF',
  },
  {
    score: -100,
    backgroundColor: '#E33614',
    secondaryColor: '#FFC4B1',
    color: '#FFFFFF',
  },
];

const participationColors = [
  {
    score: 75,
    backgroundColor: '#55A600',
    secondaryColor: '#AAD27F',
    color: '#FFFFFF',
  },
  {
    score: 50,
    backgroundColor: '#6FC022',
    secondaryColor: '#b8db94',
    color: '#FFFFFF',
  },
  {
    score: 25,
    backgroundColor: '#F5DC9A',
    secondaryColor: '#FFF6DD',
    color: '#323232',
  },
  {
    score: 0,
    backgroundColor: '#F1825A',
    secondaryColor: '#FFC4B1',
    color: '#FFFFFF',
  },
];

const getScoreColor = (score, isParticipationView) => {
  if (score === null) {
    return {
      backgroundColor: '#E9E9E9',
      color: '#323232',
    };
  }
  return (isParticipationView ? participationColors : scoreColors).find(
    option => score >= option.score
  );
};

export default getScoreColor;
