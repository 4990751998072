import React, { Component } from 'react';
import { TooltipIcon } from 'pages/Insights/Dashboard/TooltipIcon';
import PropTypes from 'prop-types';
import ComparisonScore from 'components/vibes/team-vibe-show/partials/ComparisonScore';
import {
  INSIGHTS_COMPARISON_COLOR_1,
  INSIGHTS_COMPARISON_COLOR_2,
} from 'pages/Insights/OrganisationInsights/ScoreComparison/layout';

class SmallSpeedometer extends Component {
  state = {
    currentValueRotation: 0,
    previousValueRotation: 0,
    currentValueRotation2: 90,
  };

  componentDidMount() {
    const { value, value2, previousValue, comparisonMode } = this.props;
    this.calculateRotation(value, previousValue);
    if (comparisonMode) {
      this.calculateRotation(value2, 0, '2');
    }
  }

  componentDidUpdate(prevProps) {
    const { value, value2, previousValue, comparisonMode } = this.props;
    if (prevProps.value !== value || prevProps.previousValue !== previousValue) {
      this.calculateRotation(value, previousValue);
    }
    if (comparisonMode && prevProps.value2 !== value2) {
      this.calculateRotation(value2, 0, '2');
    }
  }

  calculateRotation = (value, previousValue, index = '') => {
    setTimeout(() => {
      this.setState({
        [`currentValueRotation${index}`]: (value * 180) / 100,
        [`previousValueRotation${index}`]: (previousValue * 180) / 100,
      });
    }, 0);

    const interval = `interval${index}`;
    const counter = `counter${index}`;

    let preservedCalcValue = parseInt(this[counter].innerHTML, 10);
    const calcHelper = (preservedCalcValue - value) / 50; // ---Values difference / 50
    clearInterval(this[interval]);
    this[interval] = setInterval(() => {
      // ---Setting counter's value directly is much faster and more CPU friendly than setting state several times
      preservedCalcValue -= calcHelper;
      if (this[counter]) {
        this[counter].innerHTML = `${Math.floor(preservedCalcValue)}%`;
        if (preservedCalcValue <= value + 1 && preservedCalcValue >= value - 1) {
          clearInterval(this[interval]);
          this[counter].innerHTML = `${Math.floor(value)}%`;
        }
      }
    }, 30);
  };

  render() {
    const {
      showPrevious,
      previousValue,
      value,
      className,
      title,
      description,
      comparisonMode,
    } = this.props;
    const { currentValueRotation, currentValueRotation2, previousValueRotation } = this.state;
    const polygonColor = comparisonMode ? INSIGHTS_COMPARISON_COLOR_1 : '#444444';
    return (
      <div className={`small-speedometer ${className}`}>
        <div className="empty-graph" style={{ transform: `rotateZ(${currentValueRotation}deg)` }} />
        {showPrevious ? (
          <div
            className="previous-value"
            style={{
              transform: `translate(0, -50%) rotateZ(${previousValueRotation}deg)`,
              backgroundColor: value > previousValue ? '#FFFFFF' : '#A5A5A5',
            }}
          >
            <div className="position-helper">
              <div
                style={{
                  transform: `translate(0, -50%) rotateZ(${-previousValueRotation}deg)`,
                }}
              >
                {previousValue}%
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <svg
          height="11"
          width="77"
          className="needle"
          style={{ transform: `translate(0, -50%) rotateZ(${currentValueRotation}deg)` }}
        >
          <polygon points="77,0 77,11 0,4" fill="white" stroke="white" strokeWidth="3" y="4" />
          <polygon
            points="77,0 77,11 0,6"
            fill={polygonColor}
            stroke={polygonColor}
            strokeWidth="1"
          />
        </svg>
        {comparisonMode && (
          <svg
            height="11"
            width="77"
            className="needle"
            style={{ transform: `translate(0, -50%) rotateZ(${currentValueRotation2}deg)` }}
          >
            <polygon points="77,0 77,11 0,4" fill="white" stroke="white" strokeWidth="3" y="4" />
            <polygon
              points="77,0 77,11 0,6"
              fill={INSIGHTS_COMPARISON_COLOR_2}
              stroke={INSIGHTS_COMPARISON_COLOR_2}
              strokeWidth="1"
            />
          </svg>
        )}
        <div className="speedometer-center-caption">
          {!comparisonMode && (
            <span
              className={
                showPrevious
                  ? value > previousValue
                    ? 'incline'
                    : value === previousValue
                    ? 'equal'
                    : 'decline'
                  : ''
              }
              ref={el => {
                this.counter = el;
              }}
            >
              0%
            </span>
          )}
        </div>
        {comparisonMode && (
          <>
            <ComparisonScore
              ref={el => {
                this.counter = el;
              }}
              rotation={currentValueRotation}
              otherRotation={currentValueRotation2}
              isFirst
              color={INSIGHTS_COMPARISON_COLOR_1}
              radius={86}
              minOffsetDegree={27}
            >
              0%
            </ComparisonScore>
            <ComparisonScore
              ref={el => {
                this.counter2 = el;
              }}
              rotation={currentValueRotation2}
              otherRotation={currentValueRotation}
              color={INSIGHTS_COMPARISON_COLOR_2}
              radius={86}
              minOffsetDegree={27}
            >
              0%
            </ComparisonScore>
          </>
        )}
        <div className="speedometer-bottom-caption-wrap">
          <h2>
            {title}
            <TooltipIcon data-tip={description} />
          </h2>
        </div>
      </div>
    );
  }
}

SmallSpeedometer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  previousValue: PropTypes.number,
  showPrevious: PropTypes.bool,
  comparisonMode: PropTypes.bool,
  value2: PropTypes.number,
  className: PropTypes.string,
};
SmallSpeedometer.defaultProps = {
  previousValue: null,
  showPrevious: false,
  comparisonMode: false,
  value2: null,
  className: '',
};
export default SmallSpeedometer;
