/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { connect } from 'react-redux';
import history from './history';
import { LastLocationProvider } from 'react-router-last-location';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Welcome from './components/signup/Welcome';
import FinalizeUser from './components/signup/finalizeUser/FinalizeUser';
import Invite from './components/signup/invite/Invite';
import NewPassword from './components/devise/password/NewPassword';
import EditPassword from './components/devise/password/EditPassword';
import NewConfirmation from './components/devise/confirmations/NewConfirmation';
import ResendConfirmation from './components/devise/confirmations/ResendConfirmation';
import ImpersonateIndex from './components/impersonate/ImpersonateIndex';
import CookieLogin from './components/signup/CookieLogin';
import AppleLoginRedirect from './components/signup/integration/AppleLoginRedirect';
import SlackRedirect from './components/slack/SlackRedirect';
import Application from './components/layouts/Application';
import VibesNew from './components/vibes/VibesNew';
import PaymentsNew from './components/payments/PaymentsNew';
import PaymentsSuccess from './components/payments/PaymentsSuccess';
import GoogleCalendarPopup from 'pages/Grow/GoogleCalendarPopup';
import Admin from './components/layouts/Admin';
import Error404 from './components/errors/Error404';
import { APP_URL, FEED_FEATURE_ROUTES, GROW_FEATURE_ROUTES } from './constants';
import RedirectToHeelixModal from './components/layouts/RedirectToHeelixModal';
import { handleHashRedirect, IS_TOKEN_VALID, LOCALSTORAGE_BOOL } from './helpers';

const CustomRouter = ({ feedEnabled, growEnabled }) => {
  const redirectGuards = {
    user: 'user',
    admin: 'admin',
    teamManager: 'teamManager',
    teamManagerOrCompanyOwner: 'teamManagerOrCompanyOwner',
    companyOwner: 'companyOwner',
  };

  const manageRedirects = (Component, props, routeType) => {
    switch (routeType) {
      case redirectGuards.user:
        return localStorage != null &&
          LOCALSTORAGE_BOOL('is_authenticated') &&
          IS_TOKEN_VALID() &&
          (LOCALSTORAGE_BOOL('is_team_member') ||
            LOCALSTORAGE_BOOL('is_team_manager') ||
            LOCALSTORAGE_BOOL('is_company_owner')) ? (
          (!feedEnabled && FEED_FEATURE_ROUTES.includes(props.match.path)) ||
          (!growEnabled && GROW_FEATURE_ROUTES.includes(props.match.path)) ? (
            <Redirect to={{ pathname: '/vibes/dashboard' }} />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to={{ pathname: '/login', state: { location: props.location } }} />
        );
      case redirectGuards.admin:
        return localStorage != null &&
          LOCALSTORAGE_BOOL('is_authenticated') &&
          IS_TOKEN_VALID() &&
          LOCALSTORAGE_BOOL('is_admin') ? (
          <Component {...props} />
        ) : LOCALSTORAGE_BOOL('is_authenticated') ? (
          <Redirect to={{ pathname: '/' }} />
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        );
      case redirectGuards.teamManager:
        return localStorage != null &&
          LOCALSTORAGE_BOOL('is_authenticated') &&
          IS_TOKEN_VALID() &&
          LOCALSTORAGE_BOOL('is_team_manager') ? (
          <Component {...props} />
        ) : LOCALSTORAGE_BOOL('is_authenticated') ? (
          <Redirect to={{ pathname: '/' }} />
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        );
      case redirectGuards.teamManagerOrCompanyOwner:
        return localStorage != null &&
          LOCALSTORAGE_BOOL('is_authenticated') &&
          IS_TOKEN_VALID() &&
          (LOCALSTORAGE_BOOL('is_team_manager') || LOCALSTORAGE_BOOL('is_company_owner')) ? (
          <Component {...props} />
        ) : LOCALSTORAGE_BOOL('is_authenticated') ? (
          <Redirect to={{ pathname: '/' }} />
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        );
      case redirectGuards.companyOwner:
        return localStorage != null &&
          LOCALSTORAGE_BOOL('is_authenticated') &&
          IS_TOKEN_VALID() &&
          LOCALSTORAGE_BOOL('is_company_owner') ? (
          <Component {...props} />
        ) : LOCALSTORAGE_BOOL('is_authenticated') ? (
          <Redirect to={{ pathname: '/' }} />
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        );
      default:
        return <Component {...props} />;
    }
  };

  return (
    <Router history={history}>
      <LastLocationProvider watchOnlyPathname={true}>
        <div className="route-wrapper">
          {handleHashRedirect() ? null : (
            <Switch>
              <Route exact path="/login" component={Welcome} />
              <Route path="/sign_up" render={() => <Redirect to="/login" />} />
              <Route path="/confirm_email" component={FinalizeUser} />
              <Route path="/invitation/confirm" component={Invite} />
              <Route path="/password/new" component={NewPassword} />
              <Route path="/password/edit" component={EditPassword} />
              <Route path="/confirmation/new" component={NewConfirmation} />
              <Route path="/confirm" component={ResendConfirmation} />
              <Route path="/admin/impersonate/:id" component={ImpersonateIndex} />
              <Route exact path="/cookie_login" component={CookieLogin} />
              <Route exact path="/apple_login" component={AppleLoginRedirect} />
              <Route exact path="/slack_redirect" component={SlackRedirect} />
              <Route exact path="/google_calendar_sync" component={GoogleCalendarPopup} />
              <Route
                exact
                path="/user/:id"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/feed"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/advanced_search"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/view_profile"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/edit_profile"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/notification_settings"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/vibe/new"
                render={props => manageRedirects(VibesNew, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/show/vibes/:date?"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                path="/vibes"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                path="/organisation_insights"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/posts/list"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/fixes"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/:filter/fixes"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/fixes/hashtag/:hashtag"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/post/:id"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/mine/buzz"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/buzz/hashtag/:hashtag"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/buzz"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/comment/hashtag/:hashtag"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/grow_profile"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/shoutouts"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/wins"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/team_contributions"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/grow_board"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/spaces/accept_request/:userId/:spaceId"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/spaces"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/payments/new"
                render={props => manageRedirects(Application, props, redirectGuards.companyOwner)}
              />
              <Route
                exact
                path="/payments/choose_plan"
                render={props => manageRedirects(Application, props, redirectGuards.companyOwner)}
              />
              <Route
                exact
                path="/company_structure"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/payments/new"
                render={props => manageRedirects(PaymentsNew, props, redirectGuards.companyOwner)}
              />
              <Route
                exact
                path="/payments/success"
                render={props =>
                  manageRedirects(PaymentsSuccess, props, redirectGuards.companyOwner)
                }
              />
              <Route
                exact
                path="/settings"
                render={props => manageRedirects(Application, props, redirectGuards.companyOwner)}
              />
              <Route
                exact
                path="/company-settings"
                render={props => manageRedirects(Application, props, redirectGuards.companyOwner)}
              />
              <Route
                exact
                path="/your_schedule"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/questionnaires"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/questionnaires/:id"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                path="/users"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                path="/user/:id/edit"
                render={props =>
                  manageRedirects(Application, props, redirectGuards.teamManagerOrCompanyOwner)
                }
              />
              <Route
                path="/invitation/new"
                render={props =>
                  manageRedirects(Application, props, redirectGuards.teamManagerOrCompanyOwner)
                }
              />
              <Route
                path="/teams/new"
                render={props =>
                  manageRedirects(Application, props, redirectGuards.teamManagerOrCompanyOwner)
                }
              />
              <Route
                path="/teams"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                path="/team/:id/edit"
                render={props =>
                  manageRedirects(Application, props, redirectGuards.teamManagerOrCompanyOwner)
                }
              />
              <Route
                path="/champions"
                render={props => manageRedirects(Application, props, redirectGuards.user)}
              />
              <Route
                exact
                path="/admin"
                render={props => manageRedirects(Admin, props, redirectGuards.admin)}
              />
              <Route
                path="/admin/accounts"
                render={props => manageRedirects(Admin, props, redirectGuards.admin)}
              />
              <Route
                path="/admin/users"
                render={props => manageRedirects(Admin, props, redirectGuards.admin)}
              />
              <Route
                path="/admin/offline_invoices"
                render={props => manageRedirects(Admin, props, redirectGuards.admin)}
              />
              <Route
                path="/admin/offline_invoices/new"
                render={props => manageRedirects(Admin, props, redirectGuards.admin)}
              />
              <Route
                path="/admin/offline_invoices/:id/edit"
                render={props => manageRedirects(Admin, props, redirectGuards.admin)}
              />
              <Route
                path="/admin/analytics/:date/show"
                render={props => manageRedirects(Admin, props, redirectGuards.admin)}
              />
              <Route
                path="/admin/analytics/:year?/:week?"
                render={props => manageRedirects(Admin, props, redirectGuards.admin)}
              />
              <Route
                path="/admin/reports/setup"
                render={props => manageRedirects(Admin, props, redirectGuards.admin)}
              />
              /*---- OUTDATED ROUTES ------------*/
              <Route
                exact
                path="/fixes/list"
                render={props => <Redirect to={{ pathname: '/posts/list' }} />}
              />
              <Route
                exact
                path="/buzz/:id"
                render={props => <Redirect to={{ pathname: `/post/${props.match.params.id}` }} />}
              />
              <Route
                exact
                path="/fixes/:id"
                render={props => <Redirect to={{ pathname: `/post/${props.match.params.id}` }} />}
              />
              <Route
                exact
                path="/vibes/:slug/:date"
                render={props => (
                  <Redirect to={{ pathname: `/vibes/${props.match.params.slug}` }} />
                )}
              />
              <Route
                exact
                path="/platform_overview"
                render={props => <Redirect to={{ pathname: '/' }} />}
              />
              <Route
                exact
                path="/progress_board"
                render={props => <Redirect to={{ pathname: '/grow_board' }} />}
              />
              <Route
                exact
                path="/grow"
                render={props => <Redirect to={{ pathname: '/grow_profile' }} />}
              />
              /*--------------------------------*/
              <Route component={Error404} />
            </Switch>
          )}
          {APP_URL === 'https://api.jobvibe.me' && <RedirectToHeelixModal />}
        </div>
      </LastLocationProvider>
    </Router>
  );
};

const mapStateToProps = state => ({
  growEnabled: state.usersDomain.growEnabled,
  feedEnabled: state.usersDomain.feedEnabled,
});

export default connect(mapStateToProps)(CustomRouter);
