import React from 'react';
import grow from '../grow_onboarding.png';
import insights from '../insights_onboarding.png';
import spaces from '../spaces_onboarding.png';

export const OnboardingAdminData = [
  {
    title: 'Boost your performance',
    introduction: "It's all about helping you have better conversations with your team.",
    section: 'Grow',
    image: grow,
    points: [
      'Create goals with due dates and assign them to specific team members',
      'Record a private note to rate a piece of work / behaviour so you don’t forget it for a future meeting',
      'Track your goals and notes on a dedicated Grow Board, filter by team member',
      'Export your notes',
    ],
    final:
      "It’s all private to you. Just hit the + button to get started and head to 'My notes'. You can find Grow at the top of your left nav bar.",
  },
  {
    title: 'Stay connected',
    introduction:
      'Keep in touch with your team through a simple social collaboration tool and align them to your brand.',
    section: 'Spaces',
    image: spaces,
    points: [
      'Share text photos or videos, great for celebrating your success and keeping the team informed of any key events or updates',
      'Share files rather than them getting lost or clogging up your email',
      'Give shoutouts to recognise awesome work and make your team feel valued',
      'Discuss ways to improve your work; from polls to suggesting something you’d like to start / stop / continue doing',
    ],
    final:
      'We give you one Space for your company, or you can create your own and invite specific teammates. To create content, hit the +.',
  },
  {
    title: 'Understand how the team’s feeling',
    introduction:
      "Find out how the team's Vibe, what's driving them and the triggers that help or hinder the way they work.",
    section: 'Insights',
    image: insights,
    points: [
      'View a dashboard for each team to see their emotional state, specific performance drivers and the associated triggers. Toggle by date range and previous period to see how things have changed',
      'Deep-dive into the data, from factor analysis to mood over time and mood vs factor',
      'Take action directly from your dashboard; from running a poll to giving recognition',
    ],
    final:
      'We’ll prompt teams each Friday to record their anonymous feedback but they can also do it anytime by hitting the + button and clicking on the ‘Vibe’ option.',
  },
];
