/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Sidebar from '../partials/Sidebar';
import UsersActions from './UsersActions';

const UsersSidebar = ({
  users,
  handleFilter,
  selectedUsers,
  updateFlash,
  reloadData,
  filter,
  shouldSeeActions,
  isMember,
}) => (
  <React.Fragment>
    {shouldSeeActions && <Sidebar.Header>Actions</Sidebar.Header>}
    {shouldSeeActions && (
      <UsersActions
        selectedUsers={selectedUsers}
        teams={users.teams}
        updateFlash={updateFlash}
        reloadData={reloadData}
        filter={filter}
      />
    )}
    <Sidebar.Header
      css={
        shouldSeeActions &&
        css`
          margin-top: 35px;
        `
      }
    >
      Show users
    </Sidebar.Header>
    <Sidebar.Filters
      filters={[
        {
          label: 'Active',
          key: 'active',
          count: users.active.total,
        },
        !isMember && {
          label: 'Invited, but not activated',
          key: 'invited',
          count: users.invited.total,
        },
        !isMember && {
          label: 'Not invited',
          key: 'notInvited',
          count: users.notInvited.total,
        },
        !isMember && {
          label: 'Disabled',
          key: 'disabled',
          count: users.disabled.total,
        },
      ]}
      onChange={handleFilter}
      selectedKey={users.filter}
    />
  </React.Fragment>
);

UsersSidebar.propTypes = {
  users: PropTypes.object.isRequired,
  handleFilter: PropTypes.func.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  updateFlash: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  filter: PropTypes.oneOf(['active', 'disabled', 'invited']).isRequired,
  shouldSeeActions: PropTypes.bool.isRequired,
  isMember: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  shouldSeeActions:
    !!state.usersDomain.user &&
    (state.usersDomain.user.isAccountAdmin || state.usersDomain.user.formalLeadTeamsIds.length > 0),
});

export default withRouter(connect(mapStateToProps, null)(UsersSidebar));
