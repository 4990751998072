/** @jsx jsx */
import { Calendar } from 'react-date-range';
import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useOutsideAlerter } from 'helpers';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';

const Wrap = styled.div`
  margin-right: 20px;
  margin-bottom: 15px;
  width: 100%;
  @media (min-width: 600px) {
    width: 50%;
  }
`;

const SelectWrap = styled.div`
  position: relative;
  cursor: pointer;
`;

const SelectButton = styled.div`
  width: 100%;
  padding-left: 14px;
  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 5px;
  line-height: 40px;
  font-size: 14px;
  color: #c4c4c4;
  user-select: none;
  :after {
    content: '';
    position: absolute;
    top: 50%;
    right: 13px;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    display: inline-block;
    height: 0;
    width: 0;
  }
`;

const DateLabel = styled.span`
  color: #323232;
`;

const SelectedDate = ({ date }) =>
  date ? (
    <span>
      <span
        css={css`
          color: #adadad;
          margin-right: 1px;
        `}
      >
        Due{' '}
      </span>
      <DateLabel>{moment(date).format('MMMM Do, YYYY')}</DateLabel>
    </span>
  ) : (
    'Select a due date'
  );

const NoteDueDate = ({ date, setDate, mode, initialToggled }) => {
  const [calendarToggled, setCalendarToggled] = useState(false);
  const calendarRef = useRef(null);
  const selectRef = useRef(null);

  useEffect(() => {
    setCalendarToggled(initialToggled);
  }, [initialToggled]);

  const onDateSelection = date => {
    setCalendarToggled(false);
    setDate(moment(date).format('YYYY-MM-DD'));
  };

  useOutsideAlerter([calendarRef, selectRef], () => setCalendarToggled(false));
  return mode === noteTypes.goal ? (
    <Wrap>
      <SelectWrap onClick={() => setCalendarToggled(!calendarToggled)}>
        <SelectButton ref={selectRef}>
          <SelectedDate date={date} />
        </SelectButton>
      </SelectWrap>
      <div ref={calendarRef}>
        <Calendar
          minDate={new Date()}
          onChange={onDateSelection}
          showMonthArrow
          onRangeFocusChange={() => setCalendarToggled(false)}
          css={css`
            position: absolute;
            z-index: 10;
            line-height: initial;
            padding: 0 10px 10px;
            border: 1px solid #e6e6e6;
            box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35);
            border-radius: 3px;
            pointer-events: ${calendarToggled ? 'auto' : 'none'};
            opacity: ${calendarToggled ? '1' : '0'};
            transition: 0.3s opacity;
            .rdrDateDisplay {
              margin: 0 -10px;
            }
          `}
        />
      </div>
    </Wrap>
  ) : null;
};

NoteDueDate.propTypes = {
  date: PropTypes.string,
  setDate: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  initialToggled: PropTypes.bool.isRequired,
};

export default NoteDueDate;
