import React from 'react';

const SubmitButton = ({ nextStep, disabledSubmitButton, submit = false }) => {
  const label = submit ? 'Submit' : 'Next';
  return (
    <div className="vibe-submit">
      <a
        className={`btn btn-md btn-primary btn-fixed btn-next ${disabledSubmitButton} ${
          submit ? 'large-button' : ''
        }`}
        onClick={() => nextStep()}
        id="next-step-btn"
      >
        {label}
      </a>
    </div>
  );
};

export default SubmitButton;
