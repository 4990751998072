import styled from '@emotion/styled';
import TeamSelect from 'components/vibes/team-vibe-show/partials/TeamSelect';
import { ReactComponent as BackArrow } from 'pages/Insights/OrganisationInsights/media/back-arrow.svg';
import { PerformanceContainer } from 'pages/Insights/Dashboard/PerformanceMetric';
import React from 'react';
import DateRange from 'components/vibes/team-vibe-show/partials/DateRange';
import { SCORE_MOBILE_BREAKPOINT } from 'pages/Insights/OrganisationInsights/Score/layout';

export const INSIGHTS_COMPARISON_COLOR_1 = '#11A9FF';

export const INSIGHTS_COMPARISON_COLOR_2 = '#F63C95';

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const BackButton = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #11a9ff;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const BackArrowStyled = styled(BackArrow)`
  margin-right: 12px;
`;

export const Title = styled.div`
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  color: #323232;
  margin-left: 36px;
`;

export const TopSection = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 25px;
  border-bottom: #e9e9e9 1px solid;

  flex-direction: column;
  > div {
    margin-top: 17px;
  }
  @media (min-width: ${SCORE_MOBILE_BREAKPOINT}) {
    flex-direction: row;
    > div {
      margin-top: inherit;
    }
  }
`;

export const TopVs = styled.div`
  font-size: 18px;
  line-height: 18px;
  color: #323232;
  padding: 0 16px;
`;

export const TeamSelectStyled = styled(TeamSelect)`
  margin: 0;
  padding: 10px 13px;
  font-size: 18px;
  width: 258px;
`;

export const StatsContainer = styled.div`
  text-align: center;
  border-bottom: #e9e9e9 1px solid;
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    margin: 20px 0 10px;
    > div {
      flex-shrink: 0;
    }
  }

  @media (min-width: ${SCORE_MOBILE_BREAKPOINT}) {
    flex-direction: row;
    > div {
      justify-content: space-evenly;
      width: 50%;
    }
  }
`;

export const ColoredSpan = styled.span`
  font-weight: bold;
  color: ${({ color }) => color};
`;

export const PerformanceMetricsHeader = styled.div`
  font-size: 20px;
  text-align: center;
  margin-top: 40px;
  font-weight: bold;
`;

export const PerformanceContainerStyled = styled(props => <PerformanceContainer {...props} />)`
  padding-top: 28px;
`;

export const DateRangeStyled = styled(DateRange)`
  margin-right: auto;
  @media (min-width: ${SCORE_MOBILE_BREAKPOINT}) {
    margin-right: 0;
  }
`;
