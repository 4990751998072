/** @jsx jsx */
import React, { Component } from 'react';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';

const WistiaResponsive = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
  margin-bottom: 5px;
`;

const WistiaResponsiveWrapper = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const WistiaEmbed = styled.div`
  height: 100%;
  width: 100%;
  @media (min-width: 450px) {
    .wistia_click_to_play {
      height: 118px !important;
      width: 211px !important;
    }
    .wistia_click_to_play div {
      height: 118px !important;
      width: 211px !important;
    }
    .wistia_click_to_play div:first-child div {
      border-radius: 50%;
      width: 42px !important;
      height: 42px !important;
      margin-left: 8px;
      left: 76px;
      top: 40px;
    }
    .wistia_click_to_play div:first-child div[aria-label^='Play'] {
      top: 42px !important;
      left: 69px !important;
    }
    .wistia_click_to_play div:first-child img {
      width: 211px !important;
      height: 132px !important;
      top: -7px !important;
    }
  }
`;

class WistiaVideo extends Component {
  constructor(props) {
    super(props);
    const { hashedId, ...embedOptions } = { ...this.props };
    window._wq = window._wq || [];
    window._wq.push({
      id: hashedId,
      options: embedOptions,
      onHasData: video => {
        this.handle = video;
      },
    });
  }

  componentWillUnmount() {
    this.handle && this.handle.remove();
  }

  componentDidMount() {
    if (!document.getElementById('wistia_script')) {
      const wistiaScript = document.createElement('script');
      wistiaScript.id = 'wistia_script';
      wistiaScript.type = 'text/javascript';
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      wistiaScript.async = true;
      document.body.appendChild(wistiaScript);
    }
  }

  render() {
    return (
      <WistiaResponsive className="wistia_responsive_padding">
        <WistiaResponsiveWrapper className="wistia_responsive_wrapper">
          <WistiaEmbed className={`wistia_embed wistia_async_${this.props.videoId} popover=true`}>
            &nbsp;
          </WistiaEmbed>
        </WistiaResponsiveWrapper>
      </WistiaResponsive>
    );
  }
}

export default WistiaVideo;
