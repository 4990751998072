import React from 'react';
import PropTypes from 'prop-types';
import {
  setAction,
  setCurrentNoteModal,
  setShowActionModal,
  toggleNoteModal,
} from 'redux/app/actions';
import { connect } from 'react-redux';
import { generateTitle } from 'components/vibes/createForms/CreateNote';
import moment from 'moment-timezone';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';
import { htmlspecialchars_decode } from '../../../../helpers';

/**
 *
 * @param note
 * @param type
 * @param setAction
 * @param setShowActionModal
 * @param toggleNoteModal
 * @param progressStatus
 * @param toggleDatePicker
 * @param preparationNote
 * @param user
 */
export const handleEdit = (
  note,
  type,
  setAction,
  setShowActionModal,
  toggleNoteModal,
  progressStatus = null,
  toggleDatePicker = false,
  preparationNote = null,
  user,
  setCurrentNoteModal
) => {
  const {
    content,
    goals,
    company_values,
    title,
    assignee_id,
    shared_with,
    shared_with_team,
    due_date,
    created_at,
    assignee,
    id,
    media,
    member_id,
    team_id,
    category,
    files,
    additional_notes,
    steps,
    member,
    member_team,
    team,
  } = note;
  setCurrentNoteModal && note && setCurrentNoteModal(note);
  const formattedContent =
    note.type !== noteTypes.meetingPreparation
      ? htmlspecialchars_decode(content).replace(/<br\s*[\/]?>/gi, '\n')
      : '';
  const actionType = type => {
    if (type !== 'edit') {
      return type;
    }
    switch (note.type) {
      case noteTypes.win:
        return 'win';
      case noteTypes.goal:
        return 'goal';
      case noteTypes.private:
        return 'private';
      case noteTypes.freeText:
        return '';
      case noteTypes.meeting:
        return 'meeting';
      case noteTypes.meetingPreparation:
        return 'meeting-preparation';
      case noteTypes.managerPromptPreparation:
      case noteTypes.managerPrompt:
        return 'manager-prompt';
    }
  };

  const isConvertingAssignedGoalToWin =
    note.type === noteTypes.goal && assignee_id && type === 'win';

  const sharedTeams = shared_with_team.map(team => ({
    value: `${team.id}_team`,
    id: team.id,
    label: team.name,
    image_url: team.image_url,
    title: '',
    type: 'team',
    thumbnail_details: team.thumbnail_details,
  }));

  const sharedUsers = shared_with.map(user => ({
    value: `${user.id}_user`,
    id: user.id,
    label: `${user.first_name} ${user.last_name}`,
    image: user.image_url,
    title: generateTitle(user.role, user.team.name),
    type: 'user',
  }));

  const assignedUsers = isConvertingAssignedGoalToWin
    ? [
        {
          value: `${assignee_id}_user`,
          id: assignee_id,
          label: `${assignee.first_name} ${assignee.last_name}`,
          image: assignee.image_url,
          title: generateTitle(assignee.role, assignee.team.name),
          type: 'user',
        },
      ]
    : [];

  const sharedUsersTeams = isConvertingAssignedGoalToWin
    ? assignedUsers
    : sharedUsers.concat(sharedTeams);

  setAction(`create-note-${actionType(type)}`, {
    id,
    title,
    additionalNotes: additional_notes,
    media,
    oldFiles: files,
    noteRecipient: member
      ? {
          value: `${member.id}_user`,
          id: member.id,
          label: `${member.first_name} ${member.last_name}`,
          image: member.image_url,
          type: 'user',
        }
      : member_team
      ? {
          value: `${member_team.id}_team`,
          id: member_team.id,
          label: member_team.name,
          image_url: member_team.image_url,
          type: 'team',
          thumbnail_details: member_team.thumbnail_details,
        }
      : '',
    member,
    type: note.type,
    category,
    dueDate: due_date,
    createdAt: created_at,
    content: formattedContent,
    companyValues: company_values.map(value => value.name),
    goals: goals.map(value => value.name),
    [note.type === noteTypes.meeting ? 'meetingShareWith' : 'shareWith']: sharedUsersTeams.length
      ? sharedUsersTeams
      : [],
    assignTo:
      assignee_id && !isConvertingAssignedGoalToWin
        ? {
            image: assignee.image_url,
            label: `${assignee.first_name} ${assignee.last_name}`,
            title: generateTitle(assignee.role, assignee.team.name),
            value: assignee.id,
          }
        : '',
    steps:
      steps && steps.length
        ? steps.map((value, key) => ({
            id: key,
            value: value.content,
            label: '',
            dbId: value.id,
            assign: value.assignee
              ? {
                  user: {
                    value: value.assignee.user.id,
                    name: `${value.assignee.user.first_name} ${value.assignee.user.last_name}`,
                    label: `${value.assignee.user.first_name} ${value.assignee.user.last_name}`,
                  },
                  date: value.assignee.due_date ? value.assignee.due_date.split(' ')[0] : null,
                }
              : null,
          }))
        : [],
    stepsCount: steps ? Math.max(parseInt(steps.length), 2) : 2,
    meetingDate: due_date
      ? moment.utc(due_date, 'YYYY-MM-DD HH:mm:ss').tz(user.timeZone).format('YYYY-MM-DD')
      : '',
    meetingTime: due_date
      ? moment.utc(due_date, 'YYYY-MM-DD HH:mm:ss').tz(user.timeZone).format('h:mm A')
      : '12 PM',
    meetingMember: member_id
      ? { type: 'user', id: member_id }
      : team_id
      ? { type: 'team', id: team_id }
      : null,
    meetingMemberId: member_id,
    meetingTeamId: team_id,
    meetingContent: formattedContent,
    [note.type === noteTypes.meeting ? 'meetingIsSharing' : 'isSharing']: Boolean(
      sharedUsersTeams.length || assignee_id
    ),
    converting: type,
    preparationNote,
    toggleDatePicker,
    progressStatus,
    team,
  });

  setShowActionModal(true);
  toggleNoteModal(false);
};
const Convert = ({
  type,
  note,
  setAction,
  setShowActionModal,
  toggleNoteModal,
  progressStatus,
  preparationNote,
  user,
  setCurrentNoteModal,
}) => {
  return (
    <div
      onClick={() =>
        handleEdit(
          note,
          type,
          setAction,
          setShowActionModal,
          toggleNoteModal,
          progressStatus,
          false,
          preparationNote,
          user,
          setCurrentNoteModal
        )
      }
    >
      {type === 'edit' ? 'Edit' : `Convert to ${type}`}
    </div>
  );
};

Convert.propTypes = {
  type: PropTypes.oneOf(['goal', 'win', 'edit']).isRequired,
  note: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.usersDomain.user,
});

const mapDispatch = {
  setAction,
  setShowActionModal,
  toggleNoteModal,
  setCurrentNoteModal,
};

export default connect(mapStateToProps, mapDispatch)(Convert);
