import React, { Component } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';

const root = document.getElementById('root');

class CustomDateRangeWrap extends Component {
  constructor(props) {
    super(props);
    this.wrap = React.createRef();
    if (props.portal) {
      this.el = document.createElement('div');
      this.el.classList.add('date-range-wrap');

      this.el.style.position = 'fixed';
      this.el.style.zIndex = '1032';
      this.adjustPosition();
    }
  }

  state = {
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  };

  handleAdjustPosition = () => {
    const dateRange = this.props.dateRangeRef.current;
    if (!dateRange) return;

    const rect = dateRange.getBoundingClientRect();
    this.el.style.top = `${rect.bottom}px`;
    const windowWidth = Math.min(document.documentElement.clientWidth, window.innerWidth || 0);
    this.el.style.right = `${windowWidth - rect.right}px`;
  };

  adjustPosition = throttle(this.handleAdjustPosition, 40);

  rangeChangeHandler = ranges => {
    if (ranges.selection.startDate.getTime() !== ranges.selection.endDate.getTime()) {
      const { startDate } = ranges.selection;
      const { endDate } = ranges.selection;

      const qsCopy = {
        ...this.props.queryString,
        period: 'custom',
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      };
      this.props.history.push(`${this.props.tabLink}?${qs.stringify(qsCopy)}`);
      this.props.closeCustomRangeHandler();
    }
    this.setState(ranges);
  };

  escapeClose = e => {
    if (e.key === 'Escape') this.props.closeCustomRangeHandler();
  };

  componentDidMount = () => {
    const { portal, dateRangeRef, queryString, closeCustomRangeHandler } = this.props;

    if (portal) {
      this.wrap.current.querySelector('.custom-range').style.position = 'static';
      root.appendChild(this.el);
    }

    if (queryString.period === 'custom') {
      this.setState({
        selection: {
          startDate: new Date(queryString.start_date),
          endDate: new Date(queryString.end_date),
          key: 'selection',
        },
      });
    }

    setTimeout(() => {
      this.setState({ mounted: true });
      window.addEventListener('keydown', this.escapeClose);
      window.addEventListener('click', closeCustomRangeHandler);
      this.wrap.current.addEventListener('click', e => e.stopPropagation());
      if (portal) {
        window.addEventListener('scroll', this.adjustPosition, true);
        window.addEventListener('resize', this.adjustPosition);
      }
    }, 0);
  };

  componentWillUnmount() {
    window.removeEventListener('keydown', this.escapeClose);
    window.removeEventListener('click', this.props.closeCustomRangeHandler);
    if (this.props.portal) {
      root.removeChild(this.el);
      window.removeEventListener('scroll', this.adjustPosition);
      window.removeEventListener('resize', this.adjustPosition);
    }
  }

  render() {
    const dateRange = (
      <div ref={this.wrap}>
        <DateRange
          className={`custom-range ${this.state.mounted ? 'mounted' : ''}`}
          minDate={new Date(2013, 0, 1)}
          maxDate={new Date()}
          onChange={this.rangeChangeHandler}
          ranges={[this.state.selection]}
          showMonthArrow={false}
        />
      </div>
    );

    return this.props.portal ? ReactDOM.createPortal(dateRange, this.el) : dateRange;
  }
}

CustomDateRangeWrap.propTypes = {
  portal: PropTypes.bool,
  dateRangeRef: PropTypes.object,
};

export default withRouter(CustomDateRangeWrap);
