import React, { Component } from 'react';
import { LineChart } from 'react-chartkick';
import { APP_URL } from '../../../constants';
import { CHECK_UNAUTHORIZED, LOCALSTORAGE_GET } from '../../../helpers';

class StatsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountSignUps: [],
      accounts: [],
    };
  }

  componentDidMount() {
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);
    fetch(`${APP_URL}/admin/dashboard`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({
            accountSignUps: jsonResponse.accountSignUps,
            accounts: jsonResponse.accounts,
          });
        });
      })
      .catch(() => {});
  }

  render() {
    return (
      <div>
        <h3>Newest Accounts</h3>
        <h4>This Month</h4>
        {this.state.accountSignUps ? (
          <LineChart data={this.state.accountSignUps} library={{ backgroundColor: '#eee' }} />
        ) : (
          ''
        )}
        {this.state.accounts
          ? this.state.accounts.map(account => (
              <div className="media" key={account.id}>
                <div className="media-left">
                  {account.image ? (
                    <img
                      src={account.image}
                      alt={account.name}
                      style={{ width: '50px', height: '50px' }}
                    />
                  ) : (
                    ''
                  )}
                </div>
                <div className="media-body">
                  <h3>{account.name}</h3>
                  <h4>Buzz Posted</h4>
                  <LineChart data={account.buzz} library={{ backgroundColor: '#eee' }} />
                </div>
              </div>
            ))
          : ''}
      </div>
    );
  }
}

export default StatsIndex;
