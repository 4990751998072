import React from 'react';
import { withRouter } from 'react-router-dom';
import { APP_URL } from '../../../../constants';
import { HEADERS } from '../../../../helpers';

const AddToProgressBoard = ({ id, history }) => {
  const handleClick = () => {
    const headers = HEADERS();
    fetch(`${APP_URL}/progress_board/post/${id}/todo`, { method: 'put', headers }).then(
      response => {
        if (response.ok) {
          history.push('/grow_board');
        }
      }
    );
  };

  return <div onClick={handleClick}>Add to Grow Board</div>;
};

export default withRouter(AddToProgressBoard);
