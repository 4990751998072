import styled from '@emotion/styled';

export const ComparisonContainer = styled.div`
  display: flex;
  max-width: 480px;
  margin: 0 auto;
`;
export const ComparisonItem = styled.div`
  flex: 1 1 0;
  position: relative;
  padding: 14px 0;

  &:first-child:after {
    display: block;
    width: 1px;
    height: 100%;
    background: #dfebf0;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
  }
`;
export const ComparisonTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #11a9ff;
`;
export const ComparisonDescription = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
`;
