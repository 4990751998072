import { Button, Header, List, Notes, Point, Title } from 'pages/Grow/Meeting/layout';
import DateWrapper from 'pages/Grow/Meeting/DateWrapper';
import striptags from 'striptags';
import * as Layout from 'pages/Grow/NoteLayout';
import PostMedia from 'pages/Grow/GrowPost/PostMedia';
import PostFile from 'components/feed/PostFile';
import React from 'react';
import PropTypes from 'prop-types';

const MeetingPreparation = ({ note, user, isOwner, editPostModal, editAgendaModal }) => {
  return (
    <>
      <Header>
        {note.title}{' '}
        {note.member ? `${note.member.first_name} ${note.member.last_name}` : (note.team ? note.team.name: "")}
      </Header>
      <DateWrapper note={note} user={user} />
      <Title>Points to be discussed</Title>
      <List>
        {note.steps.map((step, index) => (
          <Point key={index}>{step.content}</Point>
        ))}
      </List>
      <Title>Other notes</Title>
      <Notes
        dangerouslySetInnerHTML={{
          __html: striptags(note.additional_notes, ['a', 'br']),
        }}
      />
      {Boolean(note.media.length) && (
        <Layout.MediaWrap>
          <PostMedia media={note.media} />
        </Layout.MediaWrap>
      )}
      {Boolean(note.files.length) && (
        <Layout.FileWrap>
          {note.files.map((file, index) => (
            <PostFile file={file} key={index} />
          ))}
        </Layout.FileWrap>
      )}
      {/* startMeetingBtn class is used by onboarding process */}
      {isOwner && (
        <Button className="startMeetingBtn" onClick={editPostModal}>
          Start the meeting
        </Button>
      )}
      <Button small onClick={editAgendaModal}>
        Edit agenda
      </Button>
    </>
  );
};

MeetingPreparation.propTypes = {
  note: PropTypes.shape({
    title: PropTypes.string.isRequired,
    member: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }),
    team: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    steps: PropTypes.array.isRequired,
    media: PropTypes.array.isRequired,
    files: PropTypes.array.isRequired,
    additional_notes: PropTypes.string,
  }).isRequired,
  isOwner: PropTypes.bool.isRequired,
  user: PropTypes.shape({}).isRequired,
  editPostModal: PropTypes.func.isRequired,
  editAgendaModal: PropTypes.func.isRequired,
};

export default MeetingPreparation;
