import DOMAINS from '../domains';

export const POST_ACTION_TYPES = {
  // --Used by lower level (not root) reducer
  ADD_POST: 'ADD_POST',
  DELETE_POST: 'DELETE_POST',
  REMOVE_DELETED_POST: 'REMOVE_DELETED_POST',
  FETCH_SINGLE: 'FETCH_SINGLE',
  RECEIVE_SINGLE: 'RECEIVE_SINGLE',
  RESET_SINGLE: 'RESET_SINGLE',
  FETCH_MANY: 'FETCH_MANY',
  SET_LOADING: 'SET_LOADING',
  RECEIVE_MANY: 'RECEIVE_MANY',
  RESET_MANY: 'RESET_MANY',
  SAVE_SCROLL: 'SAVE_SCROLL',
  RESET_FILTERS: 'RESET_FILTERS',
  FILTERS_FROM_OBJECT: 'FILTERS_FROM_OBJECT',

  OWN_POST: 'OWN_POST', // --Performs both own and unown actions
  RECEIVE_OWN_STATUS: 'RECEIVE_OWN_STATUS',

  PIN_POST: 'PIN_POST', // --Performs both pin and unpin actions
  RECEIVE_PIN_STATUS: 'RECEIVE_PIN_STATUS',

  FAV_POST: 'FAV_POST', // --Performs both pin and unpin actions
  RECEIVE_FAV_STATUS: 'RECEIVE_FAV_STATUS',

  RESOLVE_POST: 'RESOLVE_POST',
  RECEIVE_RESOLVE_STATUS: 'RECEIVE_RESOLVE_STATUS',

  LIKE_POST: 'LIKE_POST', // --Performs both like and unlike actions
  RECEIVE_LIKE_STATUS: 'RECEIVE_LIKE_STATUS',

  UPDATE_POST: 'UPDATE_POST',
  RECEIVE_UPDATE_STATUS: 'RECEIVE_UPDATE_STATUS',

  UNFOLLOW_POST: 'UNFOLLOW_POST', // --Performs both unfollow and follow actions
  RECEIVE_UNFOLLOW_STATUS: 'RECEIVE_UNFOLLOW_STATUS',

  VOTE_POLL: 'VOTE_POLL',

  RETURN_ERRORS: 'RETURN_ERRORS',

  ADD_SHOUTOUT: 'ADD_SHOUTOUT',

  UPDATE_REMAINING_SHOUTOUTS: 'UPDATE_REMAINING_SHOUTOUTS',
  FETCH_QUESTIONNAIRES: 'FETCH_QUESTIONNAIRES',
  FETCH_OPEN_QUESTIONNAIRES: 'FETCH_OPEN_QUESTIONNAIRES',
  RECEIVE_OPEN_QUESTIONNAIRES: 'RECEIVE_OPEN_QUESTIONNAIRES',
};
export const POST_ACTIONS = {
  // --Used mostly in React components during dispatch (or in fetch results in reducers)
  addPost: (data, receiveErrors, generalError) => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.ADD_POST,
    payload: { data, receiveErrors, generalError },
  }),
  deletePost: id => ({ domain: DOMAINS.POSTS, type: POST_ACTION_TYPES.DELETE_POST, payload: id }),
  removeDeletedPost: id => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.REMOVE_DELETED_POST,
    payload: id,
  }),
  updateRemainingShoutouts: type => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.UPDATE_REMAINING_SHOUTOUTS,
    payload: type,
  }),
  fetchPost: (id, failureCallback) => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.FETCH_SINGLE,
    payload: { id, failureCallback },
  }),
  receivePost: json => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.RECEIVE_SINGLE,
    payload: json,
  }),
  resetPost: () => ({ domain: DOMAINS.POSTS, type: POST_ACTION_TYPES.RESET_SINGLE }),

  votePoll: (id, data) => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.VOTE_POLL,
    payload: { id, data },
  }),

  fetchPosts: () => ({ domain: DOMAINS.POSTS, type: POST_ACTION_TYPES.FETCH_MANY }),
  fetchQuestionnaires: loaded => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.FETCH_QUESTIONNAIRES,
    payload: loaded,
  }),
  setLoadingPosts: (toggle = true) => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.SET_LOADING,
    payload: toggle,
  }),
  receivePosts: json => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.RECEIVE_MANY,
    payload: json,
  }),
  resetPosts: () => ({ domain: DOMAINS.POSTS, type: POST_ACTION_TYPES.RESET_MANY }),
  resetFilters: () => ({ domain: DOMAINS.POSTS, type: POST_ACTION_TYPES.RESET_FILTERS }),
  filtersFromObject: object => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.FILTERS_FROM_OBJECT,
    payload: object,
  }),

  saveScroll: position => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.SAVE_SCROLL,
    payload: position,
  }),

  ownPost: id => ({ domain: DOMAINS.POSTS, type: POST_ACTION_TYPES.OWN_POST, payload: id }),
  receiveOwnStatus: (postId, json) => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.RECEIVE_OWN_STATUS,
    payload: { postId, json },
  }),

  resolvePost: (id, reason) => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.RESOLVE_POST,
    payload: { id, reason },
  }),
  receiveResolveStatus: (id, json) => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.RECEIVE_RESOLVE_STATUS,
    payload: { id, json },
  }),

  pinPost: id => ({ domain: DOMAINS.POSTS, type: POST_ACTION_TYPES.PIN_POST, payload: id }),
  receivePinStatus: (postId, json) => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.RECEIVE_PIN_STATUS,
    payload: { postId, json },
  }),

  likePost: id => ({ domain: DOMAINS.POSTS, type: POST_ACTION_TYPES.LIKE_POST, payload: id }),
  receiveLikeStatus: (id, json) => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.RECEIVE_LIKE_STATUS,
    payload: { id, json },
  }),

  updatePost: (id, data) => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.UPDATE_POST,
    payload: { id, data },
  }),
  receiveUpdateStatus: (json, status) => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.RECEIVE_UPDATE_STATUS,
    payload: { json, status },
  }),

  favPost: (id, type) => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.FAV_POST,
    payload: { id, type },
  }),
  receiveFavStatus: (postId, json, type) => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.RECEIVE_FAV_STATUS,
    payload: { postId, json, type },
  }),

  unfollowPost: id => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.UNFOLLOW_POST,
    payload: id,
  }),
  receiveUnfollowStatus: (postId, json) => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.RECEIVE_UNFOLLOW_STATUS,
    payload: { postId, json },
  }),

  addShoutout: () => ({ domain: DOMAINS.POSTS, type: POST_ACTION_TYPES.ADD_SHOUTOUT }),

  fetchOpenQuestionnaires: (visitedTab = false) => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.FETCH_OPEN_QUESTIONNAIRES,
    payload: { visitedTab },
  }),
  receiveOpenQuestionnaires: json => ({
    domain: DOMAINS.POSTS,
    type: POST_ACTION_TYPES.RECEIVE_OPEN_QUESTIONNAIRES,
    payload: json,
  }),
};
