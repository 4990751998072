/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import TimeAgo from 'react-timeago';
import { FORMATTER, htmlspecialchars_decode } from 'helpers';
import { toggleUserModal } from 'redux/app/actions';
import PostMedia from 'pages/Grow/GrowPost/PostMedia';
import { TimestampWrap } from 'pages/Grow/NoteLayout';
import CommentDropdown from 'components/shared/posts/CommentDropdown';
import striptags from 'striptags';
import { COMMENT_ACTIONS } from '../../../redux/actions/commentActions';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import qs from 'qs';

const EditButton = styled.i`
  cursor: pointer;
  float: right;
  padding-left: 3px;
  margin-top: 7px;
  color: #939da3;
`;

const CommentInput = styled.textarea`
  height: ${props => props.height && props.height}px;
  min-height: unset;
  border: 1px solid #e7e7e7;
  font-size: 12px;
  line-height: 16px;
  outline: none !important;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: auto;
  resize: none;
  min-height: unset;
  vertical-align: middle;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f2f2f2;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
  }
`;

const useRefState = initialValue => {
  const [state, setState] = useState(initialValue);
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  return [state, stateRef, setState];
};

const CommentWrapper = styled.div`
  display: table-cell !important;
  ${props =>
    props.edit &&
    css`
      display: flex !important;
      position: relative !important;
      margin-right: 0 !important;
    `}
`;

const EditCommentWrapper = styled.div`
  width: 100%;
  margin-left: 5px;
`;

const CommentError = styled.div`
  margin-top: 7px;
`;

const Comment = ({
  postId,
  audienceId,
  comment,
  user,
  type,
  toggleUserModal,
  history,
  updateComment,
  commentDeletedHandler,
  tileRealignHandler,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [content, contentRef, setContent] = useRefState(comment.content);
  const [notFormattedContent, setNotFormattedContent] = useState(comment.not_formatted_content);
  const [height, setHeight] = useState(comment.content.length > 80 ? 82 : 38);
  const [prevContent, setPrevContent] = useState(htmlspecialchars_decode(comment.content));
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);
  const [commentCharsLeft, setCommentCharsLeft] = useState(1000);

  const receiveComment = receivedComment => {
    setContent(receivedComment.content);
    setPrevContent(receivedComment.content);
    setNotFormattedContent(receivedComment.not_formatted_content);
    setEditMode(false);
  };

  const sendData = useCallback(() => {
    if (commentCharsLeft >= 0) {
      if (isSending) return;
      setIsSending(true);
      const data = new FormData();
      data.append('comment[content]', contentRef.current);
      data.append('_method', 'PUT');
      updateComment(postId, comment.id, data, true, receiveComment);
      setIsSending(false);
    }
  }, [isSending, commentCharsLeft]);

  useEffect(() => {
    receiveComment(comment);
  }, [comment.content]);

  const cancelEdit = useCallback(() => {
    setContent(prevContent);
    setEditMode(false);
    tileRealignHandler();
  });

  const editComment = input => {
    setContent(input);
    setCommentCharsLeft(1000 - input.length);
    setError(
      input.length > 1000 ? 'You’ve hit the maximum comment length which is 1000 characters.' : ''
    );
  };

  const handleKeyUp = evt => {
    const newHeight = Math.max(Math.min(evt.target.scrollHeight + 2, 82), 38);
    if (newHeight !== height) {
      setHeight(newHeight);
    }
  };

  if (!comment.user) return null;
  return (
    <li
      className="comment user-comment"
      css={css`
        div.options-wrap {
          right: -35px !important;
        }
      `}
    >
      <CommentWrapper className="comment-content" edit={editMode}>
        <Link
          to=""
          onClick={e => {
            e.preventDefault();

            if (comment.user.id === user.id) {
              history.push(`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`);
            } else {
              return toggleUserModal(true, comment.user.id);
            }
          }}
          css={css`
            white-space: ${editMode ? 'unset' : 'nowrap'};
          `}
        >
          {comment.user.first_name} {comment.user.last_name}{' '}
        </Link>
        {!editMode ? (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: striptags(content, ['a', 'br']),
              }}
            />
            {type !== 'post' && (
              <TimestampWrap>
                <TimeAgo
                  date={`${comment.created_at} ${comment.user.time_zone}`}
                  formatter={FORMATTER}
                  title=""
                />
              </TimestampWrap>
            )}
            <div className="comment-show-media">
              <PostMedia media={comment.media} />
            </div>
          </>
        ) : (
          <>
            <EditCommentWrapper>
              <CommentInput
                className={error ? 'error' : ''}
                type="text"
                height={height}
                value={htmlspecialchars_decode(content)}
                onChange={e => editComment(e.target.value)}
                onKeyUp={e => handleKeyUp(e)}
              />
              {error ? <CommentError className="comment-error">{error}</CommentError> : ''}
            </EditCommentWrapper>
            <EditButton className="fa fa-check pull-right" onClick={sendData} />
            <EditButton className="fa fa-close pull-right" onClick={cancelEdit} />
          </>
        )}
      </CommentWrapper>
      {!editMode && (user.leadTeamsIds.includes(audienceId) || user.id === comment.author_id) && (
        <CommentDropdown
          id={`comment_${comment.id}`}
          commentId={comment.id}
          postId={postId}
          type={type}
          editVisible={user.id === comment.author_id}
          deleteVisible={user.leadTeamsIds.includes(audienceId) || user.id === comment.author_id}
          changeEditingMode={() => {
            setContent(notFormattedContent);
            setEditMode(!editMode);
            tileRealignHandler && tileRealignHandler();
          }}
          commentDeletedHandler={commentDeletedHandler}
        />
      )}
    </li>
  );
};

const mapDispatchToProps = dispatch => ({
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
  updateComment: (postId, commentId, data, grow, receiveComment) =>
    dispatch(COMMENT_ACTIONS.updateComment(postId, commentId, data, grow, receiveComment)),
});

export default withRouter(connect(null, mapDispatchToProps)(Comment));
