import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const cssTrend = trend => {
  if (trend === 0) return 'minus';
  if (trend > 0) return 'caret-up';
  if (trend < 0) return 'caret-down';
};

const VibeDoughnutTrend = ({ trend }) => (
  <span className="trend-icon">
    <i className={`fa fa-${cssTrend(trend)}`} />
  </span>
);

const options = (cutout = 88) => ({
  legend: false,
  animation: false,
  tooltips: false,
  cutoutPercentage: cutout,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
});

const data = (score, primary_color, secondary_color) => {
  const values = [Math.abs(score), 100 - Math.abs(score)];
  const colours = score > 0 ? [primary_color, secondary_color] : [secondary_color, primary_color];
  return {
    labels: [],
    datasets: [
      {
        data: score > 0 ? values : values.reverse(),
        backgroundColor: colours,
        hoverBackgroundColor: colours,
        borderWidth: 0,
      },
    ],
  };
};

const VibeDoughnut = ({
  score,
  text,
  width,
  height,
  trend,
  primary_color,
  secondary_color,
  remaining,
  cutout,
  customClasses = '',
}) => (
  <div className={`doughnut chart ${customClasses}`}>
    <div className="margin-above margin-below-sm">
      <div className="doughnut-value">
        <div className="number">
          {text || score}
          {trend !== null && <VibeDoughnutTrend trend={trend} />}
        </div>
      </div>
      <Doughnut
        width={width}
        height={height}
        data={data(score, primary_color, secondary_color)}
        options={options(cutout)}
      />
    </div>
    {remaining && <p className="text-center">Remaining</p>}
  </div>
);

VibeDoughnut.defaultProps = {
  width: 130,
  height: 130,
  trend: undefined,
  primary_color: '#1297E3',
  secondary_color: '#EFEFEF',
  text: undefined,
  remaining: false,
};

export default VibeDoughnut;
