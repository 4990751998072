import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { POST_ACTIONS } from 'redux/actions/postActions';
import { USER_ACTIONS } from 'redux/actions/userActions';
import menuDots from 'images/feed/menu_dots_heelix.svg';
import styled from '@emotion/styled';
import { setAction, setShowActionModal } from 'redux/app/actions';

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  unfollowedUserIds: state.usersDomain.unfollowedUsersIds,
});

const mapDispatchToProps = dispatch => ({
  deletePost: postId => dispatch(POST_ACTIONS.deletePost(postId)),
  updateRemainingShoutouts: type => dispatch(POST_ACTIONS.updateRemainingShoutouts(type)),
  ownPost: postId => dispatch(POST_ACTIONS.ownPost(postId)),
  pinPost: postId => dispatch(POST_ACTIONS.pinPost(postId)),
  unfollowPost: postId => dispatch(POST_ACTIONS.unfollowPost(postId)),
  unfollowUser: userId => dispatch(USER_ACTIONS.unfollowUser(userId)),
  setAction: (action, post) => dispatch(setAction(action, post)),
  setShowActionModal: (action, post) => dispatch(setShowActionModal(action, post)),
});

const MuteButton = styled.div`
  width: 100%;
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
`;

class PostDropdown extends Component {
  state = {
    open: false,
  };

  outerClick = () => {
    this.setState({ open: !this.state.open });
    document.removeEventListener('click', this.outerClick);
  };

  toggleDropdown(e) {
    if (this.state.open) {
      e.nativeEvent.stopImmediatePropagation();
      document.removeEventListener('click', this.outerClick);
    } else document.addEventListener('click', this.outerClick);
    this.setState({ open: !this.state.open });
  }

  editPost() {
    if (this.props.post.type === 'Questionnaire') {
      this.props.setAction('create-questionnaire', { ...this.props.post, converting: true });
      this.props.setShowActionModal(true);
    } else {
      return this.props.post.id > 0
        ? this.props.history.push({
            pathname: `/post/${this.props.post.id}`,
            state: { editing: true },
          })
        : null;
    }
  }

  render() {
    const { post, type, user, disableEdit } = this.props;
    return (
      <div
        className="post-dropdown"
        custom-open={this.state.open.toString()}
        onClick={e => this.toggleDropdown(e)}
      >
        <img src={menuDots} alt="menu" />
        <div className="options-wrap">
          {post.has_admin_permissions && type !== 'growPost' ? (
            <div onClick={post.id > 0 ? () => this.props.pinPost(post.id) : null}>
              {post.pinned ? 'Unpin it' : 'Pin it'}
            </div>
          ) : (
            ''
          )}
          {post.type === 'Fix' && post.status !== 'resolved' ? (
            <div onClick={post.id > 0 ? () => this.props.ownPost(post.id) : null}>
              {post.is_owner ? 'Stop owning' : 'Own it'}
            </div>
          ) : (
            ''
          )}
          {post.type === 'Fix' && post.status !== 'resolved' ? (
            <div onClick={post.id > 0 ? this.props.markAsDone : null}>Mark as resolved</div>
          ) : (
            ''
          )}
          {user.id !== post.user.id && (
            <MuteButton
              onClick={() => this.props.unfollowUser(post.user.id)}
              dangerouslySetInnerHTML={{
                __html:
                  this.props.unfollowedUserIds &&
                  this.props.unfollowedUserIds.indexOf(post.user.id) !== -1
                    ? `Follow <span>${post.user.first_name} ${post.user.last_name}</span>`
                    : `Mute posts from <span>${post.user.first_name} ${post.user.last_name}</span>`,
              }}
            />
          )}
          {user.id !== post.user.id ? (
            <div onClick={post.id > 0 ? () => this.props.unfollowPost(post.id) : null}>
              {post.is_unfollowed
                ? 'Turn on notifications for this post'
                : 'Turn off notifications for this post'}
            </div>
          ) : (
            ''
          )}
          {user.id === post.user.id &&
          (post.type === 'Poll' ? !post.has_votes && !post.selected_options?.length : true) &&
          (post.type === 'Questionnaire' ? !disableEdit : true) ? (
            <div onClick={() => this.editPost()} className="edit">
              Edit post
            </div>
          ) : (
            ''
          )}
          {user.id === post.user.id || post.has_admin_permissions ? (
            <div
              className="delete"
              onClick={
                post.id > 0
                  ? () => {
                      this.props.deletePost(post.id);
                      if (post.type === 'Shoutout') {
                        this.props.updateRemainingShoutouts('removed');
                      }
                    }
                  : null
              }
            >
              Delete post
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostDropdown));
