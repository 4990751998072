import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

const GrowTooltip = ({ id }) => (
  <ReactTooltip id={id} class="react-tooltip" place="top" type="dark" effect="solid">
    <span>
      Lightweight performance management tools to give feedback on the go. Users can track their
      achievements & managers can create notes to use in regular 1:1 conversations.
    </span>
  </ReactTooltip>
);

GrowTooltip.propTypes = {
  id: PropTypes.string.isRequired,
};
export default GrowTooltip;
