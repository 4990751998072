import GooglePickerComponent from 'react-google-picker';
import styled from '@emotion/styled';
import React from 'react';
import PropTypes from 'prop-types';
import { GOOGLE_DEVELOPER_KEY, GOOGLE_KEY } from '../../../constants';
import mimes from './mimeTypes';

import { ReactComponent as GoogleDriveIcon } from './GoogleDriveIcon.svg';
import { ReactComponent as GoogleDriveIconGrey } from './GoogleDriveIconGrey.svg';

const GooglePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  margin-left: 20px;
  > p.title {
    margin: 0 0 9px 0;
    line-height: 14px;
    font-size: 12px;
  }
`;

const GooglePicker = ({
  handleFiles,
  mimeTypes = [
    ...mimes.IMAGE,
    ...mimes.VIDEO,
    ...mimes.FILES,
    'application/vnd.google-apps.folder',
  ],
  thumbnailSize = 'w80',
  iconType = 'white',
}) => {
  let token = '';

  const handlePicked = files => {
    const results = files.map(async file => {
      const response = await fetch(
        `https://www.googleapis.com/drive/v3/files/${file.id}?fields=thumbnailLink`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const json = await response.json();
      let thumb = json.thumbnailLink;
      thumb = thumb ? thumb.substr(0, thumb.lastIndexOf('=') + 1) + thumbnailSize : null;
      return { ...file, thumbnailLink: thumb, drive: true };
    });
    Promise.all(results).then(handleFiles);
  };

  const handleChange = data => {
    if (data.action === 'picked') {
      handlePicked(data.docs);
    }
  };

  return (
    <GooglePickerWrapper>
      <p className="title">Google</p>
      <GooglePickerComponent
        clientId={GOOGLE_KEY}
        developerKey={GOOGLE_DEVELOPER_KEY}
        scope={['https://www.googleapis.com/auth/drive.readonly']}
        onChange={handleChange}
        onAuthenticate={googleToken => {
          token = googleToken;
        }}
        multiselect
        navHidden
        authImmediate={false}
        mimeTypes={mimeTypes}
        viewId="DOCS"
      >
        {iconType === 'grey' ? <GoogleDriveIconGrey /> : <GoogleDriveIcon />}
      </GooglePickerComponent>
    </GooglePickerWrapper>
  );
};

GooglePicker.propTypes = {
  handleFiles: PropTypes.func.isRequired,
  mimeTypes: PropTypes.array,
  thumbnailSize: PropTypes.string,
  iconType: PropTypes.string,
};

export default GooglePicker;
