import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

class MobileNotificationsTooltip extends Component {
  render() {
    return (
      <ReactTooltip id={this.props.id} class="react-tooltip" place="top" type="dark" effect="solid">
        <span>
          Choosing this option will send
          <br />
          push notifications when any new
          <br />
          post is created in this space
        </span>
      </ReactTooltip>
    );
  }
}
export default MobileNotificationsTooltip;
