/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import styled from '@emotion/styled';
import checked from 'components/onboarding/checked.svg';
import { CHECK_UNAUTHORIZED, HEADERS, htmlspecialchars_decode } from 'helpers.js';
import { APP_URL } from 'constants.js';
import { css, jsx } from '@emotion/core';
import { CloseButton } from 'components/shared/Modal/UserModal';
import { connect } from 'react-redux';
import { ReactComponent as Glasses } from 'pages/Grow/GrowPost/assets/glasses.svg';
import { POST_ACTIONS } from 'redux/actions/postActions';
import PageSelector from './PageSelector';
import {
  Name,
  Image,
  Description,
  ShortAnswer,
  OptionWrapper,
  QuestionContent,
  QuestionWrapper,
  Button,
  Anonymous,
} from './Questionnaire';
import previous from './previous.svg';
import questionnaire from '../questionnaire.svg';
import striptags from 'striptags';

const Wrapper = styled.div`
  padding: 30px;
  text-align: center;
  @media (max-width: 600px) {
    padding: 30px 20px;
  }
`;

const NameWrapper = styled.div`
  padding-bottom: 25px;
  position: relative;
`;

const MessageWrapper = styled.div`
  padding: 40px;
  text-align: center;
`;

const OptionContent = styled.div`
  height: 100%;
  padding: 20px;
  background: ${props => (props.selected ? '#11A9FF' : '#F3F3F3')};
  border-radius: 4px;
  font-weight: ${props => (props.selected ? 'bold' : 'normal')};
  font-size: 14px;
  line-height: 18px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${props => (props.selected ? '#FFFFFF' : props.disabled ? ' #C4C4C4' : '#6A6A6A')};
  word-break: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

const OptionItem = styled.div`
  padding: 0 8px 8px;
  width: 50%;
  display: inline-block;
  @media (max-width: 600px) {
    width: 100%;
  }
  &:last-of-type {
    float: left;
  }
`;

const Hint = styled.div`
  color: #a6a6a6;
  font-style: italic;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  padding: 0 22px 24px;
`;

const PreviousButton = styled.img`
  display: inline-block;
  float: left;
  padding-top: 5px;
  cursor: pointer;
`;

const questionBody = (question, selectOption, selectedAnswer, setShortAnswer) => {
  if (question.type === 'short') {
    return (
      <OptionWrapper>
        <ShortAnswer
          onChange={e => setShortAnswer(e.target.value)}
          value={selectedAnswer ? selectedAnswer.short : ''}
          placeholder="Type your response here"
        />
      </OptionWrapper>
    );
  }
  return (
    <OptionWrapper>
      {question.options.map(option => {
        const disabled =
          question.type === 'checkbox' &&
          selectedAnswer &&
          selectedAnswer.selected.length > 0 &&
          !selectedAnswer.selected.includes(option.id);
        return (
          <OptionItem key={option.id} onClick={() => selectOption(option.id, disabled)}>
            <OptionContent
              selected={selectedAnswer ? selectedAnswer.selected.includes(option.id) : false}
              disabled={disabled}
            >
              {option.content}
            </OptionContent>
          </OptionItem>
        );
      })}
    </OptionWrapper>
  );
};

const SuccessScreenWrapper = styled.div`
  padding: 20px;
`;

const SuccessImage = styled.img`
  margin-right: 14px;
  width: 30px;
  height: 30px;
  display: inline-block;
`;

const Message = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  color: #11a9ff;
  vertical-align: middle;
  display: inline-block;
`;

const ErrorMessage = styled.div`
  display: block;
  font-size: 14px;
  padding-bottom: 25px;
  text-align: left;
  margin-top: -25px;
`;

const SuccessScreen = ({ post, user, close, history }) => (
  <SuccessScreenWrapper>
    <MessageWrapper>
      <SuccessImage src={checked} />
      <Message>Thanks for your response!</Message>
    </MessageWrapper>
    {(post.open || post.author_id === user.id) && (
      <Button
        onClick={() => {
          if (post.author_id === user.id) {
            history.push(`/questionnaires/${post.id}`);
          } else {
            close();
          }
        }}
      >
        View results
      </Button>
    )}
  </SuccessScreenWrapper>
);

const QuestionnaireModal = ({
  show,
  close,
  post,
  user,
  resetPosts,
  fetchPosts,
  fetchQuestionnaires,
  ...props
}) => {
  const [step, setStep] = useState(0);
  const [voted, setVoted] = useState(false);
  const question = post.questions[step] ?? post.questions[step - 1];
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const multiple = question.type === 'multiple';

  useEffect(() => {
    initialValue();
  }, []);

  const initialValue = () => {
    const answers = post.questions.map(question => ({
      question: question.id,
      selected: [],
      short: '',
    }));

    setSelectedAnswers(answers);
  };

  const selectOption = (id, disabled) => {
    if (!disabled) {
      const answer = selectedAnswers.find(answer =>
        answer.question ? answer.question === question.id : false
      );
      const index = answer.selected.indexOf(id);
      if (index > -1) {
        answer.selected.splice(index, 1);
      } else {
        answer.selected.push(id);
      }
      setSelectedAnswers([...selectedAnswers]);
    }
  };

  const setShortAnswer = value => {
    const index = selectedAnswers.findIndex(answer =>
      answer.question ? answer.question === question.id : false
    );
    selectedAnswers[index].short = value;
    if (value.length > 250) {
      setError('Maximum answer content character limit of 250 reached.');
    } else {
      setError(null);
    }
    setSelectedAnswers([...selectedAnswers]);
  };

  const answer = selectedAnswers.find(answer =>
    answer.question ? answer.question === question.id : false
  );
  const disabled =
    error ||
    (answer && question.required ? answer.selected.length === 0 && answer.short === '' : false);

  const submitForm = () => {
    setSending(true);
    const data = new FormData();
    for (let i = 0; i < selectedAnswers.length; i++) {
      data.append('answers[]', JSON.stringify(selectedAnswers[i]));
    }
    const headers = HEADERS();
    fetch(`${APP_URL}/questionnaires/${post.id}/answer`, { method: 'post', headers, body: data })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          if (response.status === 200 && response.ok === true) {
            setVoted(true);
            setStep(step + 1);
          } else {
            if (jsonResponse.error) {
              setError(jsonResponse.error);
            }
            setSending(false);
          }
        });
      });
  };

  const closeModal = () => {
    if (voted) {
      resetPosts();
      if (props.location.pathname === '/questionnaires') {
        fetchQuestionnaires(0);
      } else {
        fetchPosts();
      }
    }
    close();
  };

  return (
    <Modal show={show} onHide={closeModal} dialogClassName="questionnaire-modal" backdrop keyboard>
      <CloseButton
        css={css`
          top: 7px;
          right: 7px;
          cursor: pointer;
        `}
        onClick={closeModal}
      />
      {step === post.questions.length ? (
        <SuccessScreen post={post} user={user} close={closeModal} history={props.history} />
      ) : (
        <Wrapper>
          <NameWrapper>
            <Image src={questionnaire} />
            <Name>{htmlspecialchars_decode(post.name)}</Name>
          </NameWrapper>
          <Description
            tile
            dangerouslySetInnerHTML={{
              __html: striptags(post.content, ['a', 'br']),
            }}
          />
          <QuestionWrapper key={question.id}>
            {step > 0 && step < post.questions.length && (
              <PreviousButton src={previous} onClick={() => setStep(step - 1)} />
            )}
            <QuestionContent multiple={multiple} modal>
              {question.content}
            </QuestionContent>
            {multiple && <Hint>Choose all that apply</Hint>}
            {questionBody(question, selectOption, answer, setShortAnswer)}
          </QuestionWrapper>
          {error && <ErrorMessage className="error-message text-danger">{error}</ErrorMessage>}
          {post.questions.length !== step + 1 ? (
            <Button onClick={() => !disabled && setStep(step + 1)} disabled={disabled}>
              Next
            </Button>
          ) : (
            <Button onClick={() => !disabled && submitForm()} disabled={disabled || sending}>
              Submit
            </Button>
          )}
          <PageSelector selectedStep={step} steps={post.questions.length} />
          <>
            <Glasses />
            <Anonymous>Your response is anonymous</Anonymous>
          </>
        </Wrapper>
      )}
    </Modal>
  );
};

const mapStateToProps = state => ({
  user: state.usersDomain.user,
});

const mapDispatchToProps = dispatch => ({
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  fetchPosts: () => dispatch(POST_ACTIONS.fetchPosts()),
  fetchQuestionnaires: loaded => dispatch(POST_ACTIONS.fetchQuestionnaires(loaded)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionnaireModal));
