import React from 'react';

import { ReactComponent as ShoutoutActive } from './assets/shoutout_active.svg';
import { ReactComponent as ShoutoutInactive } from './assets/shoutout_inactive.svg';
import { ReactComponent as PollActive } from './assets/poll_active.svg';
import { ReactComponent as PollInactive } from './assets/poll_inactive.svg';
import { ReactComponent as SuggestActive } from './assets/suggest_active.svg';
import { ReactComponent as SuggestInactive } from './assets/suggest_inactive.svg';
import { ReactComponent as MeetActive } from './assets/meet_active.svg';
import { ReactComponent as MeetInactive } from './assets/meet_inactive.svg';
import { ReactComponent as FeedbackActive } from './assets/feedback_active.svg';
import { ReactComponent as FeedbackInactive } from './assets/feedback_inactive.svg';
import { ReactComponent as ActionActive } from './assets/action_active.svg';
import { ReactComponent as ActionInactive } from './assets/action_inactive.svg';
import { ReactComponent as WinActive } from './assets/win_active.svg';
import { ReactComponent as WinInactive } from './assets/win_inactive.svg';
import { noteTypes } from './NoteTypes/noteTypes';

export const PERFORM_BUTTONS = converting =>
  converting !== 'edit'
    ? [
        ...(!converting
          ? [
              {
                key: noteTypes.meetingOneOff,
                caption: 'Meet',
                icons: {
                  active: <MeetActive />,
                  inactive: <MeetInactive />,
                },
              },
            ]
          : []),
        ...(converting !== 'win'
          ? [
              {
                key: noteTypes.goal,
                caption: 'Action',
                icons: {
                  active: <ActionActive className="action_icon_target" />,
                  inactive: <ActionInactive className="action_icon_target" />,
                },
              },
            ]
          : []),
        ...(!converting
          ? [
              {
                key: noteTypes.private,
                caption: 'Feedback',
                icons: {
                  active: <FeedbackActive />,
                  inactive: <FeedbackInactive />,
                },
              },
            ]
          : []),
        {
          key: noteTypes.win,
          caption: 'Win',
          icons: {
            active: <WinActive className="win_icon_target" />,
            inactive: <WinInactive className="win_icon_target" />,
          },
        },
      ]
    : [];

export const CONNECT_BUTTONS = () => {
  return [
    {
      key: 'create-shoutout',
      caption: 'Shoutout',
      icons: {
        active: <ShoutoutActive />,
        inactive: <ShoutoutInactive />,
      },
    },
    {
      key: 'create-poll',
      caption: 'Poll',
      icons: {
        active: <PollActive />,
        inactive: <PollInactive />,
      },
    },
    /* ...(canAccessQuestionnaires
      ? [
          {
            key: 'create-questionnaire',
            caption: 'Questionnaire',
            isActionable: true,
          },
        ]
      : []), */
    {
      key: 'create-discussion',
      caption: 'Suggest',
      icons: {
        active: <SuggestActive />,
        inactive: <SuggestInactive />,
      },
    },
  ];
};

export const POLL_BUTTONS = canAccessQuestionnaires => {
  return [
    {
      key: 'create-poll',
      caption: 'Simple poll',
    },
    ...(canAccessQuestionnaires
      ? [
          {
            key: 'create-questionnaire',
            caption: 'Questionnaire',
            isActionable: true,
          },
        ]
      : []),
  ];
};
