import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { APP_URL } from 'constants.js';
import { HEADERS } from 'helpers.js';
import { POST_ACTIONS } from 'redux/actions/postActions';
import { USER_ACTIONS } from 'redux/actions/userActions';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import SelectWhyFactors from './select_factors/SelectWhyFactors';
import LoadingScreen from './LoadingScreen';
import SelectFactors from './select_factors/SelectFactors';
import SelectSentiment from './selectSentiment/SelectSentiment';
import VibeAnonModal from './VibeAnonModal';
import SelectFeelFactors from './select_factors/SelectFeelFactors';

class VibeFlowContainer extends Component {
  // INFO: all steps
  // - select_sentiment
  // - select_good_factors
  // - select_better_factors
  // - loading_screen
  // - good_final_screen
  // - better_final_screen
  //
  constructor(props) {
    super(props);
    this.vibeType = null;
    this.links = this.props.links;
    this.raw_text = this.props.raw_text;
    this.scale = this.props.scale;
    this.images = this.props.images;
    this.vibe_factors = this.props.vibe_factors;
    this.feel_factors = this.props.feel_factors;
    this.why_vibe_factors = [];
    this.enable_vibe_comments = this.props.enable_vibe_comments;
    this.state = {
      step: 'select_sentiment',
      sentiment: null,
      message: '',
      other: '',
      showVibeDesc: false,
      showOneTimeVibeDesc: false,
      shownOneTimeVibeDescAlready: false,
    };
    this.rootRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    // INFO: send vibe on the loading screen
    const node = this.rootRef.current;
    if (
      this.state.step === 'select_good_factors' ||
      this.state.step === 'select_better_factors' ||
      this.state.step === 'select_why_good_factors' ||
      this.state.step === 'select_why_better_factors'
    ) {
      node.classList.remove('full-screen');
    } else if (!node.classList.contains('full-screen')) {
      node.classList.add('full-screen');
    }
    if (prevState.step !== this.state.step && this.state.step === 'loading_screen') {
      this._send();
    }
  }

  openDescModal = () => {
    this.setState({
      showVibeDesc: true,
    });
  };

  closeDescModal = () => {
    this.setState({
      showVibeDesc: false,
    });
  };

  closeOneTimeVibeModal = () => {
    this.setState({ showOneTimeVibeDesc: false });
  };

  openOneTimeVibeModal = () => {
    this.setState(
      {
        showOneTimeVibeDesc: true,
        shownOneTimeVibeDescAlready: true,
      },
      () => this.props.oneTimeVibeModalShown()
    );
  };

  redirectToFeed = () => {
    this.props.resetPosts();
    this.props.history.push('/');
  };

  render() {
    const closeLink =
      localStorage == null ? (
        <a href="/">
          <span className="icon fa fa-close" />
        </a>
      ) : (
        <Link to="/">
          <span className="icon fa fa-close" />
        </Link>
      );
    const step = this._getStep();
    return (
      <div data-reactroot ref={this.rootRef} className="full-screen">
        <div className="fullscreen-close hidden-sm hidden-xs">{closeLink}</div>
        <div className="vibe-step">
          <div className={`panel panel-fixed-md centered ${this.state.step}`}>{step}</div>
        </div>
        <VibeAnonModal
          closeDescModal={this.closeDescModal}
          display={this.state.showVibeDesc}
          vibeDescription={this.raw_text.vibes.your_vibe_is_anonymous_desc}
        />
      </div>
    );
  }

  _getStep() {
    switch (this.state.step) {
      case 'select_sentiment':
        return (
          <SelectSentiment
            sentiment={this.state.sentiment}
            scale={this.scale}
            raw_text={this.raw_text.vibes}
            links={this.links}
            nextStep={this.nextStep.bind(this)}
            previousStep={this.previousStep.bind(this)}
            selectVibeFactors={this.selectVibeFactors.bind(this)}
            redirectToFeed={this.redirectToFeed}
            tileRealignHandler={this.props.tileRealignHandler}
            showAnonDescModal={this.openDescModal}
            showOneTimeVibeModal={this.state.showOneTimeVibeDesc}
            shouldSeeOneTimeVibeModal={this.props.user.shouldSeeOneTimeVibeModal}
            shownOneTimeVibeDescAlready={this.state.shownOneTimeVibeDescAlready}
            openOneTimeVibeModal={this.openOneTimeVibeModal}
            closeOneTimeVibeModal={this.closeOneTimeVibeModal}
            hideCancelBar={this.props.hideCancelBar}
          />
        );
      case 'select_good_factors':
        return (
          <SelectFactors
            type="good"
            key="good"
            selectedFactors={this.state.goodFactors}
            title={this.raw_text.vibes.second_screen_message}
            factors={this.vibe_factors}
            raw_text={this.raw_text.vibes}
            nextStep={this.nextStep.bind(this)}
            previousStep={this.previousStep.bind(this)}
            enable_vibe_comments={this.enable_vibe_comments}
            tileRealignHandler={this.props.tileRealignHandler}
            showAnonDescModal={this.openDescModal}
            other={this.state.other}
          />
        );
      case 'select_better_factors':
        return (
          <SelectFactors
            type="better"
            key="better"
            title={this.raw_text.vibes.second_screen_message}
            factors={this.vibe_factors}
            raw_text={this.raw_text.vibes}
            nextStep={this.nextStep.bind(this)}
            previousStep={this.previousStep.bind(this)}
            enable_vibe_comments={this.enable_vibe_comments}
            tileRealignHandler={this.props.tileRealignHandler}
            showAnonDescModal={this.openDescModal}
            other={this.state.other}
          />
        );
      case 'loading_screen':
        return <LoadingScreen raw_text={this.raw_text.vibes} />;
      case 'select_why_good_factors':
        return (
          <SelectWhyFactors
            type="good"
            key="good"
            selectedFactors={this.state.goodFactors}
            title={this.raw_text.vibes.third_screen_message}
            whyFactors={this.why_vibe_factors}
            raw_text={this.raw_text.vibes}
            nextStep={this.nextStep.bind(this)}
            previousStep={this.previousStep.bind(this)}
            enable_vibe_comments={this.enable_vibe_comments}
            tileRealignHandler={this.props.tileRealignHandler}
            showAnonDescModal={this.openDescModal}
            message={this.state.message}
          />
        );
      case 'select_why_better_factors':
        return (
          <SelectWhyFactors
            type="better"
            key="better"
            title={this.raw_text.vibes.third_screen_message}
            whyFactors={this.why_vibe_factors}
            raw_text={this.raw_text.vibes}
            nextStep={this.nextStep.bind(this)}
            previousStep={this.previousStep.bind(this)}
            enable_vibe_comments={this.enable_vibe_comments}
            tileRealignHandler={this.props.tileRealignHandler}
            showAnonDescModal={this.openDescModal}
            message={this.state.message}
          />
        );
      case 'select_feel_good_factors':
        return (
          <SelectFeelFactors
            type="good"
            key="good"
            title={this.raw_text.vibes.forth_screen_message}
            factors={this.feel_factors}
            raw_text={this.raw_text.vibes}
            nextStep={this.nextStep.bind(this)}
            previousStep={this.previousStep.bind(this)}
            enable_vibe_comments={this.enable_vibe_comments}
            tileRealignHandler={this.props.tileRealignHandler}
            showAnonDescModal={this.openDescModal}
            message={this.state.message}
          />
        );
      case 'select_feel_better_factors':
        return (
          <SelectFeelFactors
            type="better"
            key="better"
            title={this.raw_text.vibes.forth_screen_message}
            factors={this.feel_factors}
            raw_text={this.raw_text.vibes}
            nextStep={this.nextStep.bind(this)}
            previousStep={this.previousStep.bind(this)}
            enable_vibe_comments={this.enable_vibe_comments}
            tileRealignHandler={this.props.tileRealignHandler}
            showAnonDescModal={this.openDescModal}
            message={this.state.message}
          />
        );
      case 'redirect':
        if (!this.props.fetchPostsOnClose) {
          return <LoadingScreen raw_text={{ sending_your_vibe: 'Thanks for your feedback!' }} />;
        }
        this.props.resetPosts();
        return <Redirect to="/" />;

      default:
        return '';
    }
  }

  nextStep(params = {}) {
    Object.assign(params, this.props);

    if (params.message !== undefined) {
      this.setState({ message: params.message });
    }
    if (params.other !== undefined) {
      this.setState({ other: params.other });
    }

    const updateState = {};
    const next = this._getNextStep(params.sentiment, params.other);

    if (this.state.step === 'select_sentiment') {
      updateState.sentiment = params.sentiment;
    }

    if (params.sentimentChanged === true) {
      // INFO: reset all selected factors
      this.vibe_factors.forEach(vibe_factor => {
        vibe_factor.selected = false;
        vibe_factor.alwaysVisible = false;
      });
    }
    if (next === 'select_good_factors') {
      this.vibeType = 'good';
    } else if (next === 'select_better_factors') {
      this.vibeType = 'better';
    }

    if (
      next === 'select_feel_good_factors' ||
      next === 'select_feel_better_factors' ||
      (next === 'loading_screen' && this.why_vibe_factors.length === 0)
    ) {
      this.getFactors();
    }

    if (next === 'select_why_good_factors' || next === 'select_why_better_factors') {
      const factor = this.vibe_factors.find(factor => factor.selected === true);
      if (factor) {
        this.why_vibe_factors = factor.why;
      } else {
        this.why_vibe_factors = [];
      }
    }
    this.setState(Object.assign(updateState, { step: next }), () =>
      this.props.tileRealignHandler ? this.props.tileRealignHandler() : null
    );
  }

  getFactors() {
    let sentimentName = this.scale.find(sent => sent.point === this.state.sentiment);
    sentimentName = sentimentName.name;
    const category = sentimentName.charAt(0).toUpperCase() + sentimentName.slice(1);
    this.feel_factors = this.props.feel_factors[category];
  }

  _getNextStep(sentiment = this.state.sentiment, other = this.state.other) {
    const factor = this.vibe_factors.find(factor => factor.selected === true);
    switch (this.state.step) {
      case 'select_sentiment':
        if (this._contextualSentiment(sentiment) === 'factors_went_well') {
          return 'select_good_factors';
        }
        return 'select_better_factors';
      case 'select_why_good_factors':
        if (this.why_vibe_factors.length === 0) {
          return 'loading_screen';
        }
        return 'select_feel_good_factors';
      case 'select_feel_good_factors':
      case 'select_feel_better_factors':
        return 'loading_screen';
      case 'select_why_better_factors':
        if (this.why_vibe_factors.length === 0) {
          return 'loading_screen';
        }
        return 'select_feel_better_factors';
      case 'select_good_factors':
        if (other) {
          return 'select_feel_good_factors';
        }
        if (factor) {
          return 'select_why_good_factors';
        }
        return 'loading_screen';
      case 'select_better_factors':
        if (other) {
          return 'select_feel_better_factors';
        }
        if (factor) {
          return 'select_why_better_factors';
        }
        return 'loading_screen';
      case 'loading_screen':
      case 'redirect':
        return 'redirect';
      default:
        return '';
    }
  }

  _contextualSentiment(sentiment = this.state.sentiment) {
    if (sentiment > 0) {
      return 'factors_went_well';
    }
    return 'factors_could_be_improved';
  }

  previousStep(params = {}) {
    let prevStep = 'select_sentiment';
    switch (this.state.step) {
      case 'select_feel_better_factors':
        if (this.state.other) {
          prevStep = 'select_better_factors';
        } else {
          prevStep = 'select_why_better_factors';
        }
        break;
      case 'select_feel_good_factors':
        if (this.state.other) {
          prevStep = 'select_good_factors';
        } else {
          prevStep = 'select_why_good_factors';
        }
        break;
      case 'select_why_good_factors':
        prevStep = 'select_good_factors';
        break;
      case 'select_why_better_factors':
        prevStep = 'select_better_factors';
        break;
      default:
        prevStep = 'select_sentiment';
        break;
    }
    Object.assign(params, this.props);

    this.setState(
      {
        step: prevStep,
      },
      () => (this.props.tileRealignHandler ? this.props.tileRealignHandler() : null)
    );
  }

  _selectedVibeFactors() {
    let factors = this.feel_factors.filter(factor => factor.selected === true);
    if (factors.length === 0) {
      factors = this.feel_factors.filter(factor => factor.position === 10);
    }
    return factors;
  }

  _selectedCategory() {
    let factor = this.vibe_factors.find(factor => factor.selected === true);
    if (!factor) {
      // other category
      factor = this.vibe_factors.find(factor => factor.position === 11);
    }
    return factor;
  }

  _selectedWhyVibeFactors() {
    return this.why_vibe_factors.filter(factor => factor.selected === true);
  }

  selectVibeFactors(sentiment) {
    let sentimentName = this.scale.find(sent => sent.point === sentiment);
    sentimentName = sentimentName.name;
    const category = sentimentName.charAt(0).toUpperCase() + sentimentName.slice(1);
    this.vibe_factors = this.props.vibe_factors[category];
  }

  getParameterByName(name, url) {
    if (!url) {
      url = document.location;
    }
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  resetFactors() {
    this.vibe_factors.forEach(function (factor) {
      if (factor.selected === true) {
        factor.selected = false;
      }
    });
    // eslint-disable-next-line prefer-object-spread
    Object.assign({ vibe_factors: this.vibe_factors }, this.props);
  }

  _send() {
    const vibeFactorIds = [];
    this._selectedVibeFactors().forEach(object => {
      vibeFactorIds.push(object.id);
    });
    const whyVibeFactorIds = [];
    this._selectedWhyVibeFactors().forEach(object => {
      whyVibeFactorIds.push(object.id);
    });
    const formData = new FormData();
    if (this.vibeType === 'good') {
      for (let i = 0; i < vibeFactorIds.length; i++) {
        formData.append('vibe_answer[good_reason_ids][]', vibeFactorIds[i]);
        if (whyVibeFactorIds[i]) {
          formData.append('vibe_answer[good_why_reason_ids][]', whyVibeFactorIds[i]);
        }
      }
    } else {
      for (let i = 0; i < vibeFactorIds.length; i++) {
        formData.append('vibe_answer[better_reason_ids][]', vibeFactorIds[i]);
        if (whyVibeFactorIds[i]) {
          formData.append('vibe_answer[better_why_reason_ids][]', whyVibeFactorIds[i]);
        }
      }
    }
    const categoryId = this._selectedCategory().id;
    formData.append('vibe_answer[category_id]', categoryId);
    formData.append('vibe_answer[value]', this.state.sentiment);
    const message = this.state.other ? this.state.other : this.state.message;
    formData.append('vibe_answer[message]', message);
    const headers = HEADERS();
    fetch(`${APP_URL}/vibes`, { method: 'post', headers, body: formData })
      .then(response => {
        if (response.status === 200 && response.ok === true) {
          response.json().then(jsonResponse => {
            if (jsonResponse.stored === true) {
              this.nextStep();
              setTimeout(() => {
                if (jsonResponse.displayMeetingModal) {
                  this.props.showMeetingModal();
                }
                this.props.updateVibeStatus(true);
              }, 3000);
            } else {
              if (this.props.insightsTourStep >= 0) {
                this.props.updateVibeStatus(true);
              } else {
                this.props.updateVibeStatus(false);
              }
              this.nextStep();
              this.resetFactors();
            }
            if (this.props.closePopup) {
              this.props.closePopup(jsonResponse.stored);
            }
          });
        }
      })
      .catch(() => {});
  }
}

const mapDispatchToProps = dispatch => ({
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  updateVibeStatus: vibed => dispatch(USER_ACTIONS.vibeStatusUpdated(vibed)),
  oneTimeVibeModalShown: () => dispatch(USER_ACTIONS.oneTimeVibeModalShown()),
  showMeetingModal: () => dispatch(ONBOARDING_ACTIONS.showMeetingModal()),
});
const mapStateToProps = state => ({
  posts: state.postsDomain.posts,
  user: state.usersDomain.user,
  insightsTourStep: state.onboardingDomain.insightsTourStep,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VibeFlowContainer));
