import styled from '@emotion/styled';

export const ChampionsWrapper = styled.ul`
  display: flex;
  flex-flow: column;
  background: white;
  border-radius: 5px;
  padding: 24px 36px;
  list-style: none;
  @media (min-width: 1100px) {
    flex-flow: wrap;
  }
`;

export const ChampionWrapper = styled.li`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 18px;
  max-width: 424px;
  @media (min-width: 1100px) {
    width: 50%;
  }
`;

export const CompanyValueTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  color: rgb(17, 169, 255);
  word-break: break-word;
  border-bottom: 1px solid #f3f3f3;
  margin-right: 32px;
  margin-bottom: 22px;
`;

export const UsersContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
`;
export const UserWrapper = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 19px;
`;
export const UserIcon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;
export const UserData = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;
export const UserName = styled.span`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  color: #323232;
  margin-bottom: 3px;
`;
export const ShoutoutsQuantity = styled.div`
  flex: none;
  background: #ccc;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  font-weight: bold;
  font-size: 9px;
  color: #ffffff;
  margin-left: 7px;
`;
export const UserPosition = styled.div`
  font-weight: normal;
  font-size: 11px;
  color: #bbbbbb;
`;

export const DateWrapper = styled.h3`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #6a6a6a;
  text-align: center;
`;

export const CompanyValueSelectWrapper = styled.div`
  margin-top: 40px;
`;

export const RightSidebarWrapper = styled.div`
  margin-top: 65px;
`;
