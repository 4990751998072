/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import styled from '@emotion/styled';
import 'react-datepicker/dist/react-datepicker.css';
import { htmlspecialchars_decode } from 'helpers.js';
import moment from 'moment-timezone';
import MeetingSteps from './partials/MeetingSteps';
import { ReactComponent as Calendar } from '../../shared/buttons/Calendar.svg';

const Wrap = styled.div`
  padding: 0 4px;
`;

const Header = styled.div`
  font-weight: bold;
  line-height: 150%;
  ${props => (props.small ? 'font-size: 15px;' : 'font-size: 16px;')}
  margin-bottom: 12px;
  ${props => props.margin && 'margin-top: 25px;'}
  display: inline-block;
  margin-right: 18px;
`;

const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 29px;
`;

const Textarea = styled.textarea`
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  height: 106px;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f2f2f2;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
  }
`;

const Date = styled.div`
  margin-left: 10px;
  display: inline-block;
  font-size: 12px;
  line-height: 140%;
  vertical-align: text-top;
`;

const DateWrapper = styled.div`
  color: #11a9ff;
  margin-bottom: 16px;
  display: inline-block;
`;

const EditMeeting = ({ state, setMeetingState, user }) => (
  <Wrap>
    <Header>
      {state.title}{' '}
      {state.member ? state.member.first_name + ' ' + state.member.last_name : state.team.name}
    </Header>
    <DateWrapper>
      <Calendar
        css={css`
          vertical-align: sub;
        `}
      />
      <Date>{moment.utc(state.dueDate).tz(user.timeZone).format('Do MMMM [at] h:mmA')}</Date>
    </DateWrapper>
    <Description>
      Use this to write down what you’d like to cover and we’ll give you an agenda for your meeting.
    </Description>
    <Header small>What points do you want to raise?</Header>
    <MeetingSteps
      stepsCount={state.stepsCount}
      steps={state.steps}
      id={state.id}
      fromNote={state.fromNote}
      toggledNote={state.toggledNote}
      setMeetingState={setMeetingState}
      disableAssignUser
      label="point"
    />
    <Header margin>Any other notes?</Header>
    <Textarea
      value={htmlspecialchars_decode(state.additionalNotes)}
      placeholder="Enter here ..."
      onChange={e => setMeetingState('additionalNotes', e.target.value)}
    />
  </Wrap>
);

export default EditMeeting;
