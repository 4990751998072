import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import CreateVibe from 'components/vibes/createForms/CreateVibe.js';
import { APP_URL } from 'constants.js';
import { USER_ACTIONS } from 'redux/actions/userActions';
import { BODY_CLASSES, CHECK_UNAUTHORIZED, HEADERS } from '../../../helpers';

class VibeModal extends Component {
  state = {};

  componentDidMount() {
    this.changeBlurBackground();
    this.getVibeData();
  }

  componentDidUpdate() {
    this.changeBlurBackground();
  }

  changeBlurBackground() {
    if (this.props.shouldSeeInsightsModal) {
      if (!document.body.classList.contains('blur-background')) {
        BODY_CLASSES(['blur-background']);
      }
    } else if (document.body.classList.contains('blur-background')) {
      document.body.removeAttribute('class');
    }
  }

  componentWillUnmount() {
    BODY_CLASSES();
  }

  getVibeData = () => {
    const headers = HEADERS();
    fetch(`${APP_URL}/vibes/get_data`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (response.ok && response.status === 200) {
          response.json().then(jsonResponse => {
            const { raw_text } = jsonResponse;
            raw_text.vibes.question =
              'It’s been a little while since you gave feedback.\n' +
              'Vibe now to unlock your insights.\n';
            this.setState({
              vibe_factors: jsonResponse.vibe_factors,
              feel_factors: jsonResponse.feel_factors,
              links: jsonResponse.links,
              scale: jsonResponse.scale,
              raw_text,
              images: jsonResponse.images,
              enable_vibe_comments: jsonResponse.enable_vibe_comments,
            });
          });
        }
        if (response.status === 409 && this.props.shouldSeeInsightsModal) {
          this.props.updateUserAlreadyVibed();
        }
      })
      .catch(console.error);
  };

  render() {
    return (
      <Modal
        show={this.props.shouldSeeInsightsModal}
        className="need-to-vibe-modal"
        keyboard
        backdrop
        onHide={this.props.vibeModalClose}
      >
        <div className="create-forms-wrap">
          {this.state.vibe_factors && (
            <CreateVibe
              feel_factors={this.state.feel_factors}
              vibe_factors={this.state.vibe_factors}
              links={this.state.links}
              raw_text={this.state.raw_text}
              scale={this.state.scale}
              images={this.state.images}
              enable_vibe_comments={this.state.enable_vibe_comments}
              closePopup={this.props.vibeModalClose}
            />
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  shouldSeeInsightsModal:
    state.usersDomain.user.shouldSeeInsightsModal && state.onboardingDomain.insightsTourStep < 0,
});
const mapDispatchToProps = {
  updateUserAlreadyVibed: USER_ACTIONS.updateUserAlreadyVibed,
};

export default connect(mapStateToProps, mapDispatchToProps)(VibeModal);
