import React from 'react';
import PropTypes from 'prop-types';
import teamShape from 'pages/Insights/OrganisationInsights/teamShape';
import {
  SCORE_MOBILE_BREAKPOINT,
  ScoreLabelContainer,
  Table,
  Th,
  Tr,
  TrTagged,
} from 'pages/Insights/OrganisationInsights/Score/layout';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import Team from './Team';

export const getScore = (score, isParticipationView) => {
  if (score === null) return '?';
  return !isParticipationView ? score : `${score}%`;
};

const redirectToDashboard = (history, location, teamId) => {
  const { period, start_date, end_date } = qs.parse(location.search, { ignoreQueryPrefix: true });
  history.push(`/vibes/dashboard?${qs.stringify({ team: teamId, period, start_date, end_date })}`);
};

const Score = withRouter(
  ({
    teams,
    isHierarchical,
    isParticipationView,
    checkedValues,
    setCheckedValues,
    highlightIndex,
    setSelectedTeamId,
    inputRef,
    isByTag,
    teamsByTag,
    showPrevious,
  }) => {
    const mainTeam = teams.find(team => team.depth === 0);
    return (
      <div style={{ minHeight: '100vh' }}>
        <Table>
          <thead>
            <Tr>
              {['Name', 'Members', isParticipationView ? 'Participation' : 'Score'].map(
                (label, index) => {
                  const mobileBreakpoint = index => {
                    switch (index) {
                      case 2:
                        return SCORE_MOBILE_BREAKPOINT;
                      case 1:
                        return '450px';
                      default:
                        return false;
                    }
                  };

                  return <Th hideOnMobile={mobileBreakpoint(index)}>{label}</Th>;
                }
              )}
              <Th>
                <ScoreLabelContainer>
                  {(isParticipationView ? [0, 50, 100] : [-100, 0, 100]).map(x => (
                    <div>{x}</div>
                  ))}
                </ScoreLabelContainer>
              </Th>
            </Tr>
          </thead>
          <tbody>
            {!isByTag ? (
              teams.map(({ id, name, depth, members_count, score, previous_score }, index) => (
                <Tr
                  id={`team-${id}`}
                  key={index}
                  depth={depth}
                  highlight={id === highlightIndex}
                  isHierarchical={isHierarchical}
                  index={index}
                  ref={id === highlightIndex ? inputRef : null}
                >
                  <Team
                    checkedValues={checkedValues}
                    depth={depth}
                    id={id}
                    index={index}
                    isHierarchical={isHierarchical}
                    isParticipationView={isParticipationView}
                    members_count={members_count}
                    name={name}
                    redirectToDashboard={redirectToDashboard}
                    score={score}
                    previous_score={previous_score}
                    setCheckedValues={setCheckedValues}
                    setSelectedTeamId={setSelectedTeamId}
                    teams={teams}
                    showPrevious={showPrevious}
                  />
                </Tr>
              ))
            ) : (
              <>
                <Tr
                  key={0}
                  depth={0}
                  highlight={mainTeam?.id === highlightIndex}
                  isHierarchical={false}
                  index={0}
                  ref={mainTeam?.id === highlightIndex ? inputRef : null}
                >
                  <Team
                    checkedValues={checkedValues}
                    depth={0}
                    index={mainTeam?.id}
                    id={mainTeam?.id}
                    isHierarchical={false}
                    isParticipationView={isParticipationView}
                    members_count={mainTeam?.members_count}
                    name={mainTeam?.name}
                    redirectToDashboard={redirectToDashboard}
                    score={mainTeam?.score}
                    previous_score={mainTeam?.previous_score}
                    setCheckedValues={setCheckedValues}
                    setSelectedTeamId={setSelectedTeamId}
                    teams={teams}
                    showPrevious={showPrevious}
                  />
                </Tr>
                {teamsByTag.map(({ id, name, teamIds }) => (
                  <>
                    <Tr
                      key={id}
                      depth={0}
                      highlight={false}
                      isHierarchical={false}
                      index={id}
                      ref={`tag-${id}` === highlightIndex ? inputRef : null}
                    >
                      <Team
                        checkedValues={checkedValues}
                        depth={1}
                        index={id}
                        id={id}
                        isHierarchical={false}
                        isParticipationView={isParticipationView}
                        members_count={null}
                        name={name}
                        redirectToDashboard={redirectToDashboard}
                        score={0}
                        setCheckedValues={setCheckedValues}
                        setSelectedTeamId={setSelectedTeamId}
                        teams={teams}
                        tagLabel={true}
                        showPrevious={showPrevious}
                      />
                    </Tr>
                    {teams
                      .filter(team => teamIds.includes(team.id))
                      .map(({ id, name, members_count, score, previous_score }, index) => {
                        return (
                          <TrTagged
                            key={index}
                            depth={0}
                            highlight={id === highlightIndex}
                            isHierarchical={false}
                            index={index}
                            ref={id === highlightIndex ? inputRef : null}
                          >
                            <Team
                              checkedValues={checkedValues}
                              depth={1}
                              index={id}
                              id={id}
                              isHierarchical={false}
                              isParticipationView={isParticipationView}
                              members_count={members_count}
                              name={name}
                              redirectToDashboard={redirectToDashboard}
                              score={score}
                              previous_score={previous_score}
                              setCheckedValues={setCheckedValues}
                              setSelectedTeamId={setSelectedTeamId}
                              teams={teams}
                              showPrevious={showPrevious}
                            />
                          </TrTagged>
                        );
                      })}
                  </>
                ))}
              </>
            )}
          </tbody>
        </Table>
      </div>
    );
  }
);
Score.propTypes = {
  teams: PropTypes.arrayOf(teamShape).isRequired,
  isHierarchical: PropTypes.bool.isRequired,
  isParticipationView: PropTypes.bool.isRequired,
  checkedValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  setCheckedValues: PropTypes.func.isRequired,
  setSelectedTeamId: PropTypes.func.isRequired,
  highlightIndex: PropTypes.number,
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  showPrevious: PropTypes.bool.isRequired,
};

Score.defaultProps = {
  highlightIndex: null,
  isByTag: false,
  teamsByTag: [],
};

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <Score {...props} inputRef={ref} />);
