import React, { Component } from 'react';
import Masonry from 'react-masonry-component';
import { HIDE_MENTION_TOOLTIP, SHOW_MENTION_TOOLTIP } from '../shared/tooltips/MentionTooltip';
import DiscussionTile from './DiscussionTile';
import PostTile from './PostTile';
import ShoutoutTile from './ShoutoutTile';
import PollAlertTile from './AppAlerts/PollAlertTile';
import PollTile from './PollTile';
import PostAlertTile from './AppAlerts/PostAlertTile';
import FeedbackAlertTile from './AppAlerts/FeedbackAlertTile';
import BlankPost from './BlankPost';
import QuestionnaireTile from './QuestionnaireTile';
import ConsolidatedShoutoutsTile from './ConsolidatedShoutoutsTile/ConsolidatedShoutoutsTile';

class PostsMasonry extends Component {
  render() {
    return (
      <Masonry
        onLayoutComplete={() => {
          // --Masonry is unable to calculate position of tiles at first render, as
          // --they are objects of custom classes
          // --Need to rewrite tiles as combination of divs, spans (etc.) for it to work automatically
          // --Solution below is kind of hack

          const last = document.querySelector('.post-feed-wrap .post-tile-wrap:last-child');
          if (
            last &&
            (last.style.top == '' || last.style.top == '0px') &&
            this.props.posts.length > 2
          )
            this.props.tileRealignHandler();

          const renderedHashtags = document.getElementsByClassName('inline-hashtag');
          Array.from(renderedHashtags).forEach(hashtag => {
            hashtag.removeEventListener('click', this.props.hashtagClickedHandler);
            hashtag.addEventListener('click', this.props.hashtagClickedHandler, false);
          });

          Array.from(document.getElementsByClassName('inline-mention')).forEach(mention => {
            mention.removeEventListener('mouseover', SHOW_MENTION_TOOLTIP);
            mention.addEventListener('mouseover', SHOW_MENTION_TOOLTIP, false);
            mention.removeEventListener('mouseout', HIDE_MENTION_TOOLTIP);
            mention.addEventListener('mouseout', HIDE_MENTION_TOOLTIP, false);
            mention.addEventListener('click', this.props.mentionClickedHandler, false);
          });
        }}
      >
        {this.props.user.should_see_sample_data ||
        (!this.props.posts.length && this.props.hasMorePostsToLoad) ? (
          [...Array(4)].map((item, index) => (
            <BlankPost animated={this.props.hasMorePostsToLoad} key={index} />
          ))
        ) : (
          <>
            {this.props.vibeTile}
            {this.props.posts.map((post, index) => {
              const showSpaceInfo = this.props.shouldShowPost(post.space_id);
              switch (post.type) {
                case 'Fix':
                  return (
                    <DiscussionTile
                      showSpaceInfo={showSpaceInfo}
                      post={post}
                      key={post.id}
                      tileRealignHandler={() => this.props.tileRealignHandler()}
                    />
                  );
                case 'Buzz':
                  return (
                    <PostTile
                      showSpaceInfo={showSpaceInfo}
                      post={post}
                      key={post.id}
                      tileRealignHandler={() => this.props.tileRealignHandler()}
                    />
                  );
                case 'ConsolidatedShoutouts':
                case 'MonthlyValueWinners':
                  return (
                    <ConsolidatedShoutoutsTile
                      showSpaceInfo={showSpaceInfo}
                      post={post}
                      key={post.id}
                      tileRealignHandler={() => this.props.tileRealignHandler()}
                    />
                  );
                case 'Shoutout':
                  if (post.shoutout && (post.shoutout.recipient || post.shoutout.recipient_team)) {
                    return (
                      <ShoutoutTile
                        showSpaceInfo={showSpaceInfo}
                        post={post}
                        key={post.id}
                        tileRealignHandler={() => this.props.tileRealignHandler()}
                      />
                    );
                  }
                  return '';
                case 'Poll':
                  if (post.subtype === 'PollAlert') {
                    return (
                      <PollAlertTile
                        showSpaceInfo={showSpaceInfo}
                        post={post}
                        key={post.id}
                        tileRealignHandler={() => this.props.tileRealignHandler()}
                      />
                    );
                  }
                  return (
                    <PollTile
                      showSpaceInfo={showSpaceInfo}
                      post={post}
                      key={post.id}
                      tileRealignHandler={() => this.props.tileRealignHandler()}
                    />
                  );
                case 'LeadPostAlert':
                case 'PostAlert':
                  return (
                    <PostAlertTile
                      showSpaceInfo={showSpaceInfo}
                      post={post}
                      key={post.id}
                      tileRealignHandler={() => this.props.tileRealignHandler()}
                    />
                  );
                case 'AdminFeedbackAlert':
                case 'LeadFeedbackAlert':
                  return (
                    <FeedbackAlertTile
                      showSpaceInfo={showSpaceInfo}
                      post={post}
                      key={post.id}
                      tileRealignHandler={() => this.props.tileRealignHandler()}
                    />
                  );
                case 'Questionnaire':
                  return (
                    <QuestionnaireTile
                      showSpaceInfo={showSpaceInfo}
                      post={post}
                      key={post.id}
                      tileRealignHandler={() => this.props.tileRealignHandler()}
                    />
                  );
              }
            })}
          </>
        )}
      </Masonry>
    );
  }
}

export default PostsMasonry;
