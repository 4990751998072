/** @jsx jsx */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import PropTypes from 'prop-types';
import qs from 'qs';
import FeedbackButtons from 'components/vibes/createForms/CreateNote/FeedbackButtons';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/core';
import { PLAN_ACTIONS } from 'redux/actions/planActions';
import { grow } from 'rbac/plans';
import Can from 'rbac/Can';
import { APP_URL } from '../../../constants';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../../helpers';
import { POST_ACTIONS } from '../../../redux/actions/postActions';
import { TAG_ACTIONS } from '../../../redux/actions/tagActions';
import MentionsInputWrapper from '../../shared/MentionsInputWrapper';
import BottomBar from './partials/BottomBar';
import { SPACE_ACTIONS } from '../../../redux/actions/spaceActions';
import { GROW_ACTIONS } from '../../../redux/actions/growActions';
import NoCompanyValuesOption from '../../settings/partials/NoCompanyValuesOption';

let initialState = {
  title: '',
  content: '',
  isHashtag: false,
  isMention: false,
  recipientId: null,
  team: '',
  companyValue: '',
  companyValues: [],
  errorState: false,
  errorContent: false,
  posting: false,
  charsLeftContent: 250,
  errorCompanyValue: false,
  selection: { start: 0, end: 0 },
  markup: '[-/-markupStart-/-]__type__:__id__[--__display__--]',
  postError: null,
  recipientType: 'user',
};

const ShoutoutsLeft = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -8px;
  margin-bottom: 29px;
`;
const SelectWrap = styled.div`
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
    > div {
      padding: 0 !important;
      width: 100% !important;
      :first-of-type {
        padding-bottom: 20px !important;
      }
    }
  }
  .Select-option {
    word-break: break-word;
  }
  .Select-placeholder {
    color: #c4c4c4 !important;
  }
`;

class CreateShoutout extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.spaceId !== this.props.spaceId) {
      this.setState({ postErrors: [] });
    }
  }

  contentChangedHandler = (event, unformattedContent) => {
    const input = event.target.value;
    this.setState({
      content: input,
      charsLeftContent: 1000 - unformattedContent.length,
      errorContent:
        unformattedContent.length > 1000
          ? 'Your content exceeds the maximum length of 1000 characters'
          : false,
      postErrors: [],
      isHashtag:
        this.checkIfContainHashtagOrMention(input, '[-/-markupStart-/-]hashtags:') ||
        this.checkIfContainHashtagOrMention(input, '#'),
      isMention: this.checkIfContainHashtagOrMention(input, '[-/-markupStart-/-]people:'),
    });
  };

  checkIfContainHashtagOrMention(string, type) {
    return string.includes(type);
  }

  companyValueChoiceHandler(e) {
    const companyValues = [...this.state.companyValues];
    companyValues.push(e.value);
    this.setState({
      companyValues: companyValues,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.recipientId !== this.state.recipientId) {
      this.props.recipientChanged(null, []);
    }
  }

  componentDidMount() {
    if (this.props.userId && this.props.userId !== -1) {
      this.changeRecipient({ id: this.props.userId, type: 'user' });
    }
  }

  componentWillUnmount() {
    initialState = this.state;
  }

  removeCompanyValueHandler(companyValue) {
    const companyValueIndex = this.state.companyValues.indexOf(companyValue);
    const chosenHelperArray = this.state.companyValues;
    chosenHelperArray.splice(companyValueIndex, 1);
    this.setState({
      companyValues: chosenHelperArray || [],
    });
  }

  submitForm(canSeeLimitPrompt) {
    if (!(this.state.content && this.state.recipientId))
      this.setState({ errorState: 'Form contains empty fields that are required' });
    else if (this.state.content.length > 1000)
      this.setState({ errorState: 'Your post exceeds the maximum length of 1000 characters' });
    else if (this.props.spaceId == null) this.setState({ errorState: 'Space cannot be empty' });
    else {
      this.setState({
        posting: true,
        errorState: false,
        errorContent: false,
        postErrors: null,
        postError: null,
        errorCompanyValue: false,
      });
      const data = new FormData();
      data.append(
        'shoutout[audience_id]',
        this.props.spaceId ? this.props.spaceId : this.props.filters.space
      );
      data.append('shoutout[content]', this.state.content);
      data.append('shoutout[recipient_id]', this.state.recipientId);
      data.append('shoutout[type]', 'Shoutout');
      data.append(
        'shoutout[validate_mentions]',
        !(this.state.postErrors && this.state.postErrors.mention_error && this.state.isMention)
      );
      data.append('shoutout[recipient_type]', this.state.recipientType);

      for (let i = 0; i < this.state.companyValues.length; i++)
        data.append('shoutout_values[]', this.state.companyValues[i]);

      const headers = HEADERS();
      fetch(`${APP_URL}/shoutout`, { method: 'post', headers, body: data })
        .then(response => {
          if (!response.ok) {
            response.json().then(json => {
              this.setState({
                postErrors: json.error,
                posting: false,
              });
            });
            throw Error(response.statusText);
          } else {
            return response;
          }
        })
        .then(response => {
          response
            .json()
            .then(() => {
              this.setState({
                content: '',
                recipientId: null,
                companyValues: [],
                companyValue: '',
                isHashtag: false,
                isMention: false,
              });
              this.setState({
                posting: false,
              });
              this.props.addShoutout();
              this.props.fetchCompanyValues();
              this.props.resetPosts();
              this.props.resetGrowPosts();
              const spaceQuery =
                qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).space || '';
              const spaceId = +this.props.spaceId;
              if (canSeeLimitPrompt && this.props.user.remainingShoutouts === 1) {
                this.props.showPrompt({
                  type: 'ShoutoutPrompt',
                  color: 'yellow',
                });
              }

              if (+spaceQuery === spaceId) {
                this.props.fetchSpaceDetails(spaceId);
                this.props.fetchPosts();
                this.props.fetchGrowPosts();
                this.props.closePopup();
              } else {
                this.props.closePopup();
                this.props.history.push(`/feed?space=${spaceId}`);
              }
            })
            .catch(error => {
              this.setState({
                posting: false,
                postError: 'Could not add a shoutout',
              });
            });
        })
        .catch(error => {
          this.setState({
            posting: false,
            postError: 'Could not add a shoutout',
          });
        });
    }
  }

  getSelectCompanyValuesOptions() {
    const options = this.props.companyValues
      ? this.props.companyValues
          .filter(value => this.state.companyValues.indexOf(value) < 0)
          .map(companyValue => {
            return { value: companyValue, label: companyValue };
          })
      : [];
    return options;
  }

  getSelectRecipientsOptions() {
    let recipients = this.props.spaceMembers.filter(user => user.id !== this.props.user.id);
    recipients = recipients.concat(this.props.allTeams);
    if (recipients.length > 0) {
      recipients.sort((recipientA, recipientB) => {
        const nameA = recipientA.name ? recipientA.name.toLowerCase() : '';
        const nameB = recipientB.name ? recipientB.name.toLowerCase() : '';
        if (nameA < nameB) {
          return -1;
        }
        if (nameA >= nameB) {
          return 1;
        }
        return 0;
      });

      recipients = recipients.map(recipient => ({
        type: typeof recipient.role !== 'undefined' ? 'user' : 'team',
        id: recipient.id,
        value: recipient.id + (typeof recipient.role !== 'undefined' ? 'user' : 'team'),
        label: `${recipient.name} ${
          typeof recipient.role !== 'undefined' ? `(${recipient.slug})` : ''
        }`,
      }));
    }

    return recipients;
  }

  changeRecipient = recipient => {
    if (recipient) {
      this.setState({
        recipientId: recipient.id,
        recipientType: recipient.type,
      });
      const headers = HEADERS();
      fetch(`${APP_URL}/shoutout/get_teams/${recipient.type}/${recipient.id}`, {
        method: 'get',
        headers,
      })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            this.props.recipientChanged(recipient.id, json.sharedTeams);
          });
        })
        .catch(() => {});
    }
  };

  handleBlur = event => {
    this.setState({ inputElement: event.target });
  };

  handleSelection = event => {
    this.setState({
      inputElement: event.target,
      selection: {
        start: event.target.selectionStart,
        end: event.target.selectionEnd,
      },
    });
  };

  render() {
    return (
      <div className="create-shoutout-tab">
        <div className="create-shoutout-feed">
          <FeedbackButtons onClick={this.props.changeTab} toggled="create-shoutout" />
          <SelectWrap>
            <Select
              options={this.getSelectRecipientsOptions()}
              inputProps={{ id: 'shoutout-for-input' }}
              value={this.state.recipientId + this.state.recipientType}
              isSearchable
              onChange={this.changeRecipient}
              clearable={false}
              className="select-component recipients orange"
              placeholder="Search for users here"
              disabled={false}
            />
            {(this.props.isAccountAdmin || Boolean(this.props.companyValues.length)) && (
              <Select
                options={this.getSelectCompanyValuesOptions()}
                inputProps={{ id: 'company-value-input' }}
                isSearchable
                value={this.state.companyValue}
                onChange={e => this.companyValueChoiceHandler(e)}
                clearable={false}
                className="select-component company-values orange"
                placeholder="Select related company values"
                disabled={false}
                noResultsText={<NoCompanyValuesOption />}
              />
            )}
          </SelectWrap>
          <div className="company-values-wrapper">
            {this.state.companyValues.map((companyValue, index) => (
              <span id={`company-value-${index}`} className="value box" key={index}>
                {companyValue}
                <div
                  onClick={() => this.removeCompanyValueHandler(companyValue)}
                  className="cross"
                />
              </span>
            ))}
          </div>
          {this.state.errorCompanyValue ? (
            <div className="tag-error text-danger">{this.state.errorCompanyValue}</div>
          ) : (
            ''
          )}
          <MentionsInputWrapper
            handleBlur={this.handleBlur}
            handleSelection={this.handleSelection}
            value={this.state.content}
            contentChangedHandler={this.contentChangedHandler}
            placeholder="What was the impact (in a few sentences)?"
            setInputRef={ref => this.setState({ inputElement: ref })}
          />
          {this.state.errorContent ? (
            <div className="fix-tile-error">
              <span className="text-danger">{this.state.errorContent}</span>
            </div>
          ) : (
            ''
          )}
          {this.state.postErrors && this.state.postErrors['shoutout.content'] ? (
            <div className="fix-tile-error">
              <span className="text-danger">{this.state.postErrors['shoutout.content'][0]}</span>
            </div>
          ) : (
            ''
          )}
          {this.state.postErrors && this.state.postErrors.mention_error && this.state.isMention ? (
            <div className="fix-tile-error">
              <span className="text-danger">{this.state.postErrors.mention_error}</span>
            </div>
          ) : (
            ''
          )}
          <span
            id="error-message"
            className={`error-message text-danger ${this.state.errorState && 'shown'}`}
          >
            {this.state.errorState}
          </span>
          <BottomBar>
            <div className="controls-panel pull-left" />
            <div className="info-panel pull-right">
              <Can
                perform={grow.seeShoutoutLimitPrompt}
                data={{ isAccountAdmin: this.props.isAccountAdmin }}
              >
                {canSeeLimitPrompt => (
                  <input
                    id="post-btn"
                    type="button"
                    data-disable-with="Posting..."
                    disabled={
                      this.state.posting ||
                      this.props.user.remainingShoutouts < 1 ||
                      this.state.charsLeftContent < 0
                    }
                    name="commit"
                    className="btn btn-primary"
                    value={
                      this.state.postErrors &&
                      this.state.postErrors.mention_error &&
                      this.state.isMention
                        ? 'Give shoutout anyway'
                        : 'Give shoutout'
                    }
                    onClick={() => this.submitForm(canSeeLimitPrompt)}
                  />
                )}
              </Can>
            </div>
          </BottomBar>
        </div>
      </div>
    );
  }
}

CreateShoutout.propTypes = {
  spaceId: PropTypes.number,
};

const mapDispatchToProps = dispatch => ({
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  fetchPosts: () => dispatch(POST_ACTIONS.fetchPosts()),
  resetGrowPosts: () => dispatch(GROW_ACTIONS.resetPosts()),
  fetchGrowPosts: () => dispatch(GROW_ACTIONS.fetchPosts()),
  fetchSpaceDetails: spaceId => dispatch(SPACE_ACTIONS.fetchSpaceDetails(spaceId)),
  addShoutout: () => dispatch(POST_ACTIONS.addShoutout()),
  fetchCompanyValues: () => dispatch(TAG_ACTIONS.fetchCompanyValues()),
  filtersFromObject: object => dispatch(POST_ACTIONS.filtersFromObject(object)),
  showPrompt: prompt => dispatch(PLAN_ACTIONS.showPrompt(prompt)),
});

const mapStateToProps = state => ({
  companyValues: state.tagsDomain.companyValues,
  hashtags: state.tagsDomain.hashtags,
  user: state.usersDomain.user,
  spaceMembers: state.usersDomain.usersForShoutoutModal,
  allTeams: state.teamsDomain.allTeams,
  filters: state.postsDomain.filters,
  userId: state.app.body.userId,
  isAccountAdmin: state.usersDomain.user.isAccountAdmin,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateShoutout));
