import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { AVATAR_ERROR } from 'helpers';
import { connect } from 'react-redux';
import { toggleUserModal } from 'redux/app/actions';
import { withRouter } from 'react-router-dom';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import qs from 'qs';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';

const Wrap = styled.div`
  display: flex;
`;
const AuthorInfo = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  align-self: center;
`;
const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
`;
const AuthorInfoName = styled.a`
  color: #323232;
  text-decoration: none;
  cursor: pointer;
`;
const AuthorInfoPosition = styled.div`
  font-size: 10px;
  color: ${props => (props.shared ? '#6A6A6A' : '#BBBBBB')};
  font-weight: normal;
  margin-top: 4px;
`;
const SharedInfo = styled.span`
  font-size: 11px;
  color: ${props => (props.assigned ? '#323232' : '#11A9FF')};
  margin-left: 22px;
`;
const getUserName = user => `${user.first_name} ${user.last_name}`;

const Author = ({
  user,
  shared = false,
  assigned = false,
  type,
  currentUser,
  history,
  toggleUserModal,
  ...props
}) => {
  const username = getUserName(user);

  const rolePosition = [];
  if (user.role !== '') {
    rolePosition.push(user.role);
  }
  rolePosition.push(user.team.name);
  return (
    <Wrap {...props}>
      <Avatar src={user.image_url} alt={username} onError={e => AVATAR_ERROR(e.target)} />
      <AuthorInfo>
        <AuthorInfoName
          onClick={() => {
            if (currentUser.id === user.id) {
              history.push(`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`);
            } else {
              return toggleUserModal(true, user.id);
            }
          }}
        >
          {username}
        </AuthorInfoName>
        <AuthorInfoPosition shared={shared}>
          {rolePosition.join(', ')}
          {shared && (
            <SharedInfo assigned={assigned}>
              {assigned ? 'assigned' : 'shared'} this {type === noteTypes.goal ? 'goal' : 'note'}{' '}
              {assigned ? 'to' : 'with'} you
            </SharedInfo>
          )}
        </AuthorInfoPosition>
      </AuthorInfo>
    </Wrap>
  );
};

Author.propTypes = {
  user: PropTypes.object.isRequired,
  shared: PropTypes.bool,
  assigned: PropTypes.bool,
  type: PropTypes.string,
};

const mapStateToProps = state => ({
  currentUser: state.usersDomain.user,
});

const mapDispatchToProps = {
  toggleUserModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Author));
