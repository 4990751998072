import React from 'react';

const Loader = props => (
  <div className="loader-wrap">
    <div className="la-line-scale-pulse-out jobvibe-blue" {...props}>
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);
export default Loader;
