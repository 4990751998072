import React from 'react';
import { PropTypes } from 'prop-types';
import SlidingPane from 'react-sliding-pane';

import NotificationList from '../../navigation/NotificationList';

const Notifications = ({ open, notifications, onClose }) => (
  <SlidingPane
    isOpen={open}
    title="Notifications"
    subtitle={<button type="button" className="close-notification" onClick={() => onClose()} />}
    width="230px"
    className="side-notification-pane notification-list"
    onRequestClose={onClose}
  >
    <NotificationList notifications={notifications} closeAllPanesHandler={onClose} />
  </SlidingPane>
);
Notifications.propTypes = {
  open: PropTypes.bool.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
};
export default Notifications;
