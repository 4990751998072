import qs from 'qs';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import integrationTypes from 'components/signup/integration/integrationTypes';
import steps from '../steps';
import InviteWelcomeContainer from './InviteWelcomeContainer';
import InvitePassword from './InvitePassword';
import InviteTerms from './InviteTerms';
import {
  ADMIN_ROLE,
  APP_URL,
  COMPANY_OWNER_ROLE,
  TEAM_MANAGER_ROLE,
  TEAM_MEMBER_ROLE,
  LOGIN_TIMEOUT_MILISECONDS,
} from '../../../constants';
import axios from '../axiosAuthenticated';
import { CHECK_UNAUTHORIZED } from '../../../helpers';
import ErrorPage from '../ErrorPage';

const Invite = ({ location, history }) => {
  const [token, setToken] = useState('');
  const [googleToken, setGoogleToken] = useState('');
  const [names, setNames] = useState({});
  const [password, setPassword] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('Please try again');

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isIntegration = () => googleToken || query.appleToken;

  const handleNames = data => {
    setNames(data);
    if (data.first_name && data.last_name) {
      history.push(`/invitation/confirm?step=${steps.invitePassword}`);
    } else {
      history.push(`/invitation/confirm?step=${steps.inviteName}`);
    }
  };

  const handleToken = data => {
    if (data.error) {
      setError(data.error);
      history.push(`/invitation/confirm?step=${steps.inviteError}`);
    } else {
      return setToken(data);
    }
  };

  const handleGoogle = token => {
    setGoogleToken(token);
    if (token.error) {
      setError(token.error);
      history.push(`/invitation/confirm?step=${steps.inviteError}`);
    } else {
      history.push(`/invitation/confirm?step=${steps.inviteTerms}`);
    }
  };

  const handlePassword = password => {
    setPassword(password);
    if (password) {
      history.push(`/invitation/confirm?step=${steps.inviteTerms}`);
    } else {
      history.push(`/invitation/confirm?step=${steps.invitePassword}`);
    }
  };

  const handleSubmit = terms => {
    if (!terms) return;
    setIsFetching(true);

    if (googleToken) {
      if (googleToken.error) {
        return history.push(`/invitation/confirm?step=${steps.inviteError}`);
      }
      return handleGoogleSignup();
    }
    if (query.appleToken) {
      return handleAppleSignup();
    }

    const data = new FormData();
    data.append('invitationToken', token);
    data.append('firstName', names.first_name);
    data.append('lastName', names.last_name);
    data.append('password', password);

    fetch(`${APP_URL}/invitation/confirm`, { method: 'post', body: data })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          if (jsonResponse.error) {
            if (jsonResponse.redirect) {
              history.push(`/invitation/confirm?${steps.inviteError}`);
            } else {
              history.push(`/invitation/confirm?${steps.inviteError}`);
            }
          } else {
            localStorage.setItem('access_token', jsonResponse.accessToken);
            axios.interceptors.request.use(config => {
              config.headers.Authorization = `Bearer ${jsonResponse.accessToken}`;
              return config;
            });
            localStorage.setItem('is_authenticated', true);
            localStorage.setItem(
              'token_expires_at',
              new Date(new Date().getTime() + LOGIN_TIMEOUT_MILISECONDS)
            );
            sessionStorage.setItem(
              'token_expires_at',
              new Date(new Date().getTime() + LOGIN_TIMEOUT_MILISECONDS)
            );

            localStorage.setItem('user_first_name', jsonResponse.user.first_name);
            localStorage.setItem('user_last_name', jsonResponse.user.last_name);
            localStorage.setItem('user_image_uid', jsonResponse.user.image_uid);
            localStorage.setItem('user_id', jsonResponse.user.id);
            localStorage.setItem('user_type', jsonResponse.user.type);
            localStorage.setItem('is_admin', false);
            localStorage.setItem('is_company_owner', false);
            localStorage.setItem('is_team_manager', false);
            localStorage.setItem('is_team_member', false);
            localStorage.setItem('visibleForManagement', false);

            jsonResponse.roles.forEach(role => {
              switch (role.name) {
                case ADMIN_ROLE:
                  localStorage.setItem('is_admin', true);
                  break;
                case COMPANY_OWNER_ROLE:
                  localStorage.setItem('is_company_owner', true);
                  break;
                case TEAM_MANAGER_ROLE:
                  localStorage.setItem('is_team_manager', true);
                  break;
                case TEAM_MEMBER_ROLE:
                  localStorage.setItem('is_team_member', true);
                  break;
                default:
                  localStorage.setItem('is_team_member', true);
              }
            });
            setIsFetching(false);
            history.push('/');
          }
        });
      });
  };

  const handleAppleSignup = () => {
    const data = new FormData();
    data.append('token', query.appleToken);
    data.append('refreshToken', query.refreshToken);
    data.append('invitationToken', query.invitation_token);

    return handleIntegrationSignup(data, integrationTypes.APPLE);
  };

  const handleGoogleSignup = () => {
    const data = new FormData();
    data.append('token', googleToken);
    data.append('invitationToken', token);

    return handleIntegrationSignup(data, integrationTypes.GOOGLE);
  };

  const handleIntegrationSignup = (data, type) => {
    fetch(`${APP_URL}/signup_${type}`, { method: 'post', body: data })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          if (jsonResponse.error) {
            setError(jsonResponse.error);
            history.push(`/invitation/confirm?step=${steps.inviteError}`);
          } else {
            localStorage.setItem('access_token', jsonResponse.accessToken);
            axios.interceptors.request.use(config => {
              config.headers.Authorization = `Bearer ${jsonResponse.accessToken}`;
              return config;
            });
            localStorage.setItem('is_authenticated', true);
            localStorage.setItem(
              'token_expires_at',
              new Date(new Date().getTime() + LOGIN_TIMEOUT_MILISECONDS)
            );
            sessionStorage.setItem(
              'token_expires_at',
              new Date(new Date().getTime() + LOGIN_TIMEOUT_MILISECONDS)
            );

            localStorage.setItem('user_first_name', jsonResponse.user.first_name);
            localStorage.setItem('user_last_name', jsonResponse.user.last_name);
            localStorage.setItem('user_image_uid', jsonResponse.user.image_uid);
            localStorage.setItem('user_id', jsonResponse.user.id);
            localStorage.setItem('user_type', jsonResponse.user.type);
            localStorage.setItem('is_admin', false);
            localStorage.setItem('is_company_owner', false);
            localStorage.setItem('is_team_manager', false);
            localStorage.setItem('is_team_member', false);
            localStorage.setItem('visibleForManagement', false);

            jsonResponse.roles.forEach(role => {
              switch (role.name) {
                case ADMIN_ROLE:
                  localStorage.setItem('is_admin', true);
                  break;
                case COMPANY_OWNER_ROLE:
                  localStorage.setItem('is_company_owner', true);
                  break;
                case TEAM_MANAGER_ROLE:
                  localStorage.setItem('is_team_manager', true);
                  break;
                case TEAM_MEMBER_ROLE:
                  localStorage.setItem('is_team_member', true);
                  break;
                default:
                  localStorage.setItem('is_team_member', true);
              }
            });
            setIsFetching(false);
            history.push('/');
          }
        });
      });
  };

  const queryStep = query.step;
  switch (queryStep) {
    case steps.inviteName:
    case undefined:
      return (
        <InviteWelcomeContainer
          handleNames={handleNames}
          handleToken={handleToken}
          handleGoogle={handleGoogle}
        />
      );
    case steps.invitePassword:
      return names.first_name ? (
        <InvitePassword handlePassword={handlePassword} />
      ) : (
        <Redirect push to={{ pathname: '/invitation/confirm' }} />
      );
    case steps.inviteTerms:
      return password || isIntegration() ? (
        <InviteTerms loading={isFetching} handleTerms={handleSubmit} />
      ) : (
        <Redirect push to={{ pathname: '/invitation/confirm' }} />
      );
    case steps.inviteError:
      return (
        <ErrorPage
          title="Something went wrong"
          body={error}
          goBack={() => {
            setGoogleToken('');
            window.history.back();
          }}
        />
      );
    default:
      return null;
  }
};

export default Invite;
