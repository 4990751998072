import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  padding: 0 20px 0px;
  ${({ isTopSpace }) =>
    isTopSpace &&
    `
      padding: 20px 20px 0px;
    `}
`;

export const Wrapper = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  margin-top: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 23px;
  color: #6a6a6a;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  width: 756px;
  ${({ width }) =>
    width &&
    `
        width: ${width}px;
    `}
  ${({ isTopSpace }) =>
    isTopSpace &&
    `
        margin-top: 20px;
    `}
`;
