import React from 'react';
import Joyride from 'react-joyride';

const JoyrideWrapper = ({ run, stepIndex, steps, callback, disableScrolling = true }) => {
  return (
    <div className="app">
      <Joyride
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        hideBackButton
        continuous
        callback={callback}
        locale={{ last: 'Done' }}
        scrollDuration={500}
        disableScrolling={disableScrolling}
        floaterProps={{
          disableAnimation: true,
        }}
        styles={{
          options: {
            zIndex: 10000,
            arrowColor: '#11A9FF',
            backgroundColor: '#11A9FF',
            primaryColor: '#FFFFFF',
            textColor: '#FFFFFF',
            beaconSize: 54,
          },
          tooltip: {
            borderRadius: '3px',
            padding: '24px 36px',
          },
          tooltipContent: {
            fontSize: '15px',
            padding: '0',
          },
          tooltipContainer: {
            textAlign: 'left',
            lineHeight: '150%',
          },
          buttonNext: {
            color: '#11A9FF',
            fontSize: '15px',
            fontWeight: 'bold',
            lineHeight: '18px',
            padding: '11px 20px',
          },
          buttonClose: {
            height: 10,
            width: 10,
            padding: 15,
            fontSize: '10px',
          },
          tooltipFooter: {
            marginTop: '25px',
            display: 'block',
          },
          overlay: {
            backgroundColor: 'none',
          },
        }}
      />
    </div>
  );
};

export default JoyrideWrapper;
