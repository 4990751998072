import React, { Component } from 'react';
import styled from '@emotion/styled';
import { CHECK_UNAUTHORIZED, HEADERS, htmlspecialchars_decode } from 'helpers.js';
import { APP_URL } from 'constants.js';
import Page from 'components/layouts/Page';
import back from 'pages/Grow/back.svg';
import { withRouter } from 'react-router-dom';
import AnsweredQuestionnaire from 'components/feed/QuestionnaireTile/AnsweredQuestionnaire';
import qs from 'qs';

const BackIcon = styled.img`
  margin-right: 13px;
  cursor: pointer;
`;

const PostWrapper = styled.div`
  padding: 29px;
  border-radius: 4px;
  position: relative;
  border: 1px solid #f3f3f3;
  background-color: #fff;
  border-bottom-width: 2px;
  margin-bottom: 23px;
  width: 100%;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 150%;
  color: #323232;
  padding-bottom: 23px;
`;

const ResponsesWrapper = styled.div`
  font-weight: normal;
  font-size: 15px;
  line-height: 140%;
  color: #11a9ff;
`;

const Counter = styled.div`
  background: #11a9ff;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  border-radius: 20px;
  text-align: center;
  padding: 4px 9px;
  display: inline-block;
  margin-right: 8px;
`;

const QuestionsWrapper = styled.div`
  width: 60%;
  @media (max-width: 1250px) {
    width: 70%;
  }
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const BackButton = styled.a`
  position: absolute;
`;

const Content = styled.span`
  padding-left: 40px;
  display: block;
  @media (min-width: 1000px) {
    padding-right: 200px;
  }
`;

class QuestionnairesDetails extends Component {
  state = {
    hasMorePosts: false,
  };

  componentDidMount() {
    this.fetchPost(this.props.location.pathname);
  }

  fetchPost = path => {
    const headers = HEADERS();
    fetch(`${APP_URL}${path}`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (response.ok && response.status === 200) {
          response.json().then(jsonResponse => {
            this.setState({
              post: jsonResponse.post,
              answersCount: jsonResponse.post.questionnaire_answered_count,
            });
          });
        }
      })
      .catch(function () {});
  };

  setTeam = team => {
    this.fetchPost(`${this.props.location.pathname}?${qs.stringify({ teamId: team?.id })}`);
  };

  render() {
    const { post, answersCount } = this.state;
    const { history } = this.props;
    return post ? (
      <>
        <Page.Section
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 20px 0',
          }}
        >
          <Page.Wrapper className="grow-feed-header">
            <Page.Header
              style={{
                padding: '0 15px',
                marginBottom: '0',
                wordBreak: 'break-word',
              }}
            >
              <BackButton onClick={() => history.goBack()}>
                <BackIcon src={back} alt="back" />
              </BackButton>
              <Content>
                {htmlspecialchars_decode(post.name)} {post.name ? 'results' : ''}
              </Content>
            </Page.Header>
          </Page.Wrapper>
        </Page.Section>
        <Page.Section>
          <Page.Wrapper>
            <Page.Content
              style={{
                flex: '1',
                padding: '0 15px',
                overflowX: 'visible',
              }}
            >
              <PostWrapper>
                <Description>{htmlspecialchars_decode(post.content)}</Description>
                <QuestionsWrapper>
                  <AnsweredQuestionnaire setTeam={this.setTeam} post={post} full details />
                </QuestionsWrapper>
              </PostWrapper>
            </Page.Content>
            <Page.Sidebar>
              {answersCount >= 0 && (
                <ResponsesWrapper>
                  <Counter>{answersCount}</Counter>
                  {answersCount > 1 ? 'responses' : 'response'} in total
                </ResponsesWrapper>
              )}
            </Page.Sidebar>
          </Page.Wrapper>
        </Page.Section>
      </>
    ) : null;
  }
}

export default withRouter(QuestionnairesDetails);
