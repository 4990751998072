import React from 'react';
import { withRouter } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import PropTypes from 'prop-types';
import { AWS_S3_URL } from '../../../constants';

const BackButton = ({ history }) => {
  const lastLocation = useLastLocation();
  return (
    <a
      className="button icon-left back-btn"
      id="jf-back-btn"
      role="doc-backlink"
      tabIndex={0}
      onClick={() => (lastLocation ? history.goBack() : history.push('/'))}
    >
      <img src={`${AWS_S3_URL}public/images/icons/back-arrow.svg`} alt="Go back" />
      Back
    </a>
  );
};

BackButton.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(BackButton);
