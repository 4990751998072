import React, { Component } from 'react';
import StartGuide from './StartGuide';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import { connect } from 'react-redux';

class AddChildTeam extends Component {
  defaultDelayTime = 400;
  immediateDelayTime = 0;

  state = {
    triggered: false,
    teamName: '',
    dragOver: false,
    delayTime: this.defaultDelayTime,
    placeholderHeight: 0,
    placeholderWidth: 0,
  };

  componentDidUpdate() {
    if((this.props.addChildTeamState || this.props.setupTourStep === 4) && this.state.triggered) {
        this.setState({ triggered: false })
    }
  }

  render() {
    return (
      <div
        className={`${this.props.teams.length === 1 ? 'add-team-tile' : 'add-subteam-tile'} ${
          this.state.dragOver ? 'drag-over' : ''
        } ${this.state.triggered ? 'triggered' : ''}`}
      >
        <div className="untriggered-content" onClick={() => {
          this.props.setWasAddTeamButtonClicked(true)
          this.setState({ triggered: true })
        }}>
          <span className="plus-button" />
          {this.props.teams.length === 1 ? 'Add another team' : 'Add sub-team'}
        </div>
        <div className="triggered-content">
          <h1>Name your team</h1>
          <input
            value={this.state.teamName}
            onChange={e => this.setState({ teamName: e.target.value })}
          />
          <div className="buttons-wrap">
            <div
              className="add-button"
              onClick={() => {
                if (this.state.teamName) {
                  this.props.addTeam(this.state.teamName, this.props.team);
                  this.setState({
                    teamName: '',
                  });
                }
              }}
            >
              Add
            </div>
            <div className="cancel-button" onClick={() => {
              if(this.props.setupTourStep > -1) {
                this.props.setSetupTourStep(-1);
              }
              this.setState({ triggered: false })
            }}>
              Cancel
            </div>
          </div>
        </div>
        <div
          className="drag-capture-area"
          onMouseEnter={() => {
            if (this.props.dragHelperVariables.draggedTeamDOM) {
              this.setState({
                dragOver: true,
              });
              setTimeout(
                () =>
                  this.setState({
                    placeholderHeight: window
                      .getComputedStyle(this.props.dragHelperVariables.draggedTeamDOM)
                      .getPropertyValue('height'),
                    placeholderWidth: window
                      .getComputedStyle(this.props.dragHelperVariables.draggedTeamDOM)
                      .getPropertyValue('width'),
                  }),
                0
              );
            }
          }}
          onMouseLeave={() => {
            if (this.props.dragHelperVariables.draggedTeamDOM) {
              this.setState({
                dragOver: false,
                delayTime: this.defaultDelayTime,
                placeholderHeight: 0,
              });
            }
          }}
          onMouseUp={() => {
            if (
              this.props.dragHelperVariables.draggedTeamData &&
              [...this.props.team.parent_ids, this.props.team.id].indexOf(
                this.props.dragHelperVariables.draggedTeamData.id
              ) === -1
            )
              this.props.moveTeam(this.props.team, this.props.dragHelperVariables);
            this.setState({
              dragOver: false,
              delayTime: this.immediateDelayTime,
              placeholderHeight: 0,
            });
          }}
        />
        <div
          style={{
            height: this.state.placeholderHeight,
            width: this.state.placeholderWidth,
          }}
          className={`team-placeholder ${
            this.props.dragHelperVariables.draggedTeamData &&
            [...this.props.team.parent_ids, this.props.team.id].indexOf(
              this.props.dragHelperVariables.draggedTeamData.id
            ) !== -1
              ? 'invalid'
              : ''
          }`}
        />
        <div className="guides-wrap">
          <StartGuide
            className="first-guide"
            isMounted={!this.state.triggered && this.props.teams.length === 1}
          >
            Add another team to {this.props.teams[0].name}
            <svg
              width="52"
              height="62"
              viewBox="0 0 52 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.257 10.9952C16.8873 11.4055 16.9201 12.0378 17.3304 12.4075L24.016 18.4327C24.4262 18.8024 25.0586 18.7696 25.4283 18.3593C25.798 17.949 25.7652 17.3167 25.3549 16.947L19.4122 11.5913L24.7679 5.64852C25.1376 5.23826 25.1048 4.60595 24.6945 4.23622C24.2842 3.86648 23.6519 3.89934 23.2822 4.3096L17.257 10.9952ZM20.164 60.4864C28.6432 59.0732 34.7046 55.1776 38.5118 50.0915C42.3105 45.0169 43.819 38.8185 43.311 32.8664C42.2951 20.9632 33.1639 9.87543 17.948 10.666L18.0517 12.6633C32.0095 11.9381 40.3778 22.018 41.3182 33.0365C41.7884 38.5452 40.3892 44.2461 36.9107 48.893C33.4408 53.5286 27.8556 57.1769 19.8352 58.5136L20.164 60.4864Z"
                fill="#D7D6DE"
              />
            </svg>
          </StartGuide>
          <StartGuide
            className="second-guide"
            isMounted={this.state.triggered && this.props.teams.length === 1}
          >
            Name your team and then you will be able to drag team members into it.
            <svg
              width="59"
              height="86"
              viewBox="0 0 59 86"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.25746 14.9952C1.88772 15.4055 1.92057 16.0378 2.33083 16.4075L9.01643 22.4327C9.42669 22.8024 10.059 22.7696 10.4287 22.3593C10.7985 21.949 10.7656 21.3167 10.3554 20.947L4.41261 15.5913L9.76832 9.64852C10.1381 9.23826 10.1052 8.60595 9.69494 8.23621C9.28468 7.86648 8.65237 7.89933 8.28264 8.30959L2.25746 14.9952ZM31.7923 85.0713C44.8117 70.4249 48.6568 52.3104 43.6571 38.051C38.6303 23.7143 24.7565 13.5329 2.94841 14.666L3.05219 16.6633C24.1078 15.5693 37.0789 25.3341 41.7698 38.7128C46.4878 52.1688 42.9234 69.5389 30.2975 83.7425L31.7923 85.0713Z"
                fill="#D7D6DE"
              />
            </svg>
          </StartGuide>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  addChildTeamState: state.onboardingDomain.addChildTeamState,
  setupTourStep: state.onboardingDomain.setupTourStep
})

const mapDispatchToProps = (dispatch) => ({
  setWasAddTeamButtonClicked: (wasAddTeamButtonClicked) => dispatch(ONBOARDING_ACTIONS.setWasAddTeamButtonClicked(
    wasAddTeamButtonClicked)),
  toggleAddChildTeam: addChildTeamState => dispatch(ONBOARDING_ACTIONS.toggleAddChildTeam(addChildTeamState)),
  setSetupTourStep: step => dispatch(ONBOARDING_ACTIONS.setSetupTourStep(step)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddChildTeam);
