import mimeTypes from '../GooglePicker/mimeTypes';

const verifyFileSize = (files, allFilesSize, sizeProperty = 'size') => {
  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    if (sizeProperty === 'sizeBytes' && mimeTypes.FILES.includes(file.mimeType)) {
      if (file[sizeProperty] >= 10 * 1024 * 1024) {
        alert('One of files is too big! Maximum size allowed is 10MB');
        return { alert: true, size: 0 };
      }
      continue;
    }

    if (allFilesSize + file[sizeProperty] > 18 * 1024 * 1024) {
      alert(
        'Your attachments are a bit big... Maximum total size allowed is 18MB.' +
          ' Please reduce your file size or remove some images to continue.'
      );
      return { alert: true, size: 0 };
    }

    allFilesSize += file[sizeProperty];

    const maxSize = mimeTypes.VIDEO.includes(file.mimeType ?? file.type) ? 18 : 6;

    if (file[sizeProperty] > maxSize * 1024 * 1024) {
      alert(`One of files is too big! Maximum size allowed is ${maxSize}MB`);
      return { alert: true, size: 0 };
    }
  }

  return { alert: false, size: allFilesSize };
};

export default verifyFileSize;
