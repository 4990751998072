import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import SpaceLogo from 'components/spaces/SpaceLogo';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  padding: 8px 0;
  font-weight: 700;
`;
const SpaceLogoStyled = styled(SpaceLogo)`
  font-size: 10px;
  line-height: 22px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 15px;
  text-align: center;
`;
const SpaceHeader = ({ space }) => {
  if (!space) {
    return null;
  }
  return (
    <Wrapper>
      <SpaceLogoStyled space={space} />
      {space.name}
    </Wrapper>
  );
};
SpaceHeader.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  spaceId: PropTypes.number.isRequired,
  space: PropTypes.shape({
    name: PropTypes.string,
  }),
};
SpaceHeader.defaultProps = {
  space: null,
};
const mapState = (state, props) => ({
  space: state.spacesDomain.spaces.find(space => space.id === props.id),
});
export default connect(mapState)(SpaceHeader);
