import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import commentStyle from './comment.sass';
import { COMMENT_ACTIONS } from '../../../redux/actions/commentActions';
import Comment from './Comment';

const Wrap = styled.div`
  ${commentStyle}
`;

const Comments = ({
  post,
  user,
  type,
  fetchingComments,
  fetchComments,
  commentAddedHandler,
  commentDeletedHandler,
  tileRealignHandler,
}) => {
  return (
    <Wrap>
      <div className="post-tile-comments-section">
        <div className="post-tile-comments">
          <ul className="comment-section">
            {post.comments.map(comment => (
              <Comment
                postId={post.id}
                audienceId={post.audience_id}
                comment={comment}
                user={user}
                type={type}
                key={comment.id}
                commentAddedHandler={commentAddedHandler}
                commentDeletedHandler={commentDeletedHandler}
                tileRealignHandler={tileRealignHandler}
              />
            ))}
          </ul>
          {post.comments.length < post.total_comments_count && (
            <div
              className="post-tile-comments-unfold"
              onClick={() => {
                if (!fetchingComments) {
                  fetchComments(post.id, post.comments.length, type);
                }
              }}
            >
              View all {post.total_comments_count} comments
            </div>
          )}
        </div>
      </div>
    </Wrap>
  );
};

Comments.propTypes = {
  post: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  fetchingComments: state.postsDomain.fetchingComments || state.growDomain.fetchingComments,
});

const mapDispatchToProps = dispatch => ({
  fetchComments: (postId, loadedCount, type) =>
    dispatch(COMMENT_ACTIONS.fetchComments(postId, loadedCount, false, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
