import React, { Component } from 'react';
import { APP_URL } from '../../../constants';
import { LOCALSTORAGE_GET } from '../../../helpers';

class ReportsSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      showForm: false,
      report: '',
    };
  }

  componentDidMount() {
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);
    fetch(APP_URL + this.props.location.pathname, { method: 'get', headers })
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({ reports: jsonResponse.reports });
        });
      })
      .catch(() => {});
  }

  showForm(e) {
    e.preventDefault();
    const boolean = e.target.value !== '0';
    this.setState({ showForm: boolean });
    const report = e.target.value !== '0' ? e.target.value : null;
    this.setState({ report });
  }

  render() {
    return (
      <div>
        <form className="form-inline admin-reports-form">
          <div className="form-group">
            <select className="form-control" onChange={e => this.showForm(e)}>
              <option value={0}>Select a report...</option>
              {this.state.reports.map(report => (
                <option key={report.key} value={report.key}>
                  {report.value}
                </option>
              ))}
            </select>
          </div>
        </form>
      </div>
    );
  }
}

export default ReportsSetup;
