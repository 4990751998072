import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as BackIcon } from 'components/layouts/Navigation/back_btn.svg';
import { useLastLocation } from 'react-router-last-location';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const Wrapper = styled.div`
  padding: 8px 0;
  font-weight: 700;
`;
const BackIconStyled = styled(BackIcon)`
  width: 22px;
  height: 22px;
  margin-right: 15px;
  vertical-align: bottom;
`;

const ShoutoutsHeader = ({ history }) => {
  const lastLocation = useLastLocation();
  return (
    <Wrapper>
      <BackIconStyled onClick={() => (lastLocation ? history.goBack() : history.push('/'))} />
      Shoutouts
    </Wrapper>
  );
};

ShoutoutsHeader.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(ShoutoutsHeader);
