import styled from '@emotion/styled';
import { jsx, css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import checkboxUnchecked from './checkboxUnchecked.svg';
import selectedAvatar from './selectedAvatar.svg';
import Pagination from './Pagination';
import dropdownTriangle from '../dropdownTriangle.svg';

const Table = styled.div`
  background: white;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  width: 938px;
  overflow: hidden;
  padding-left: 18px;
  padding-right: 18px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const TableHeader = styled.div`
  height: 60px;
  color: #6a6a6a;
  font-size: 12px;
  display: flex;
  border-bottom: 1px solid #f3f3f3;
`;

const TableRow = styled.div`
  height: 70px;
  color: #323232;
  font-size: 13px;
  display: flex;
  ${props =>
    !props.hierarchical &&
    css`
      border-bottom: 1px solid #f3f3f3;
    `}
  &:hover {
    cursor: ${props => props.cursor};
  }
  margin-left: ${props => props.indent};
  ${props =>
    props.selected &&
    css`
      margin-left: calc(-18px - ${props.indent});
      margin-right: calc(-18px - ${props.indent});
      padding-left: calc(18px + ${props.indent} * 2);
      padding-right: calc(18px + ${props.indent} * 2);
      background: linear-gradient(270deg, rgba(218, 242, 255, 0) 0%, #daf2ff 100%);
      @media (min-width: 768px) and (max-width: 1400px) {
        margin-left: ${props =>
          props.indent === '0px' ? '-18px' : `calc(-18px - ${parseInt(props.indent) - 20}px)`};
      }
    `}
`;

const HeaderColumn = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  min-width: ${props => props.cssWidth};
  max-width: ${props => props.cssWidth};
  ${props =>
    props.order
      ? css`
          cursor: pointer;
        `
      : ''}
  ${props => props.css}
`;

const NameHeaderColumn = styled(HeaderColumn)`
  @media (min-width: 768px) and (max-width: 1400px) {
    min-width: ${props => `${parseInt(props.cssWidth) / 2.5}px`};
    max-width: ${props => `${parseInt(props.cssWidth) / 2.5}px`};
  }
`;

const CheckBox = styled.img`
  margin-left: 11px;
  width: 18px;
  height: 18px;
  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
`;

const BodyColumn = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: calc(${props => props.cssWidth || '0px'} - ${props => props.indent || '0px'});
  max-width: calc(${props => props.cssWidth || '0px'} - ${props => props.indent || '0px'});
  ${props => props.css}
`;

const NameColumn = styled(BodyColumn)`
  overflow: unset;
  whitespace: unset;
  text-overflow: unset;
  @media (min-width: 768px) and (max-width: 1400px) {
    min-width: calc(
      ${props => `${parseInt(props.cssWidth) / 2.5}px` || '0px'} -
        ${props => `${parseInt(props.indent) - 20}px` || '0px'}
    );
    max-width: calc(
      ${props => `${parseInt(props.cssWidth) / 2.5}px` || '0px'} -
        ${props => `${parseInt(props.indent) - 20}px` || '0px'}
    );
  }
`;

const ScoreColumn = styled(BodyColumn)`
  overflow: unset;
`;

const Picture = styled.img`
  width: 30px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 50%;
`;

const TableFooter = styled.div`
  height: 90px;
  padding-top: 29px;
`;

const SortTriangle = styled.img`
  width: 8px;
  margin-left: 10px;
  ${props =>
    props.order === 'asc' &&
    css`
      transform: rotate(180deg);
    `}
`;

const DottedLine = styled.div`
  content: ' ';
  position: absolute;
  z-index: 0;
  height: 40px;
  width: 20px;
  background-image: linear-gradient(#bbbbbb 11%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1px 10px;
  background-repeat: repeat-y;
`;

const OrganisationTable = ({ columns, rows, pagination, sort, select, hierarchical = false }) => {
  rows.forEach((row, index) => {
    if (row.fields.length !== columns.length) {
      throw `Mismatch between ammount of columns and fields in row ${index} provided.`;
    }
  });
  const pictureColumnWidth = css`
    min-width: 40px !important;
  `;

  const handleSelect = (isSelected, index) => {
    if (!select.multi) return isSelected ? -1 : index;

    return isSelected ? select.indices.filter(val => val !== index) : [...select.indices, index];
  };
  const allSelected = (() => {
    if (!select.multi || rows.length < 1) return false;
    const all = [...Array(rows.length).keys()];
    return all.every(i => select.indices.includes(i));
  })();

  const selectAll = () => {
    return select.handleChange(allSelected ? [] : [...Array(rows.length).keys()]);
  };

  const getAvatar = (isSelected, picture) => {
    if (isSelected) return <Picture src={selectedAvatar} alt="" />;
    if (typeof picture === 'string') return <Picture src={picture} alt="" />;
    if (React.isValidElement(picture)) return picture;
    throw 'Value provided as rows.picture has to be either a url or a valid react element.';
  };

  const dottedLines = depth => {
    const lines = [];
    for (let i = 0; i < depth; i++) {
      lines.push(<DottedLine />);
    }
    return lines;
  };

  return (
    <TableContainer>
      <Table>
        <TableHeader>
          <HeaderColumn css={pictureColumnWidth}>
            {select.multi && select.allowSelect && (
              <CheckBox
                onClick={() => selectAll(select.indices)}
                src={allSelected ? selectedAvatar : checkboxUnchecked}
              />
            )}
          </HeaderColumn>
          {columns.map(({ label, key, width, css, isSortable = true }, index) => {
            return index === 0 && hierarchical ? (
              <NameHeaderColumn
                order={sort && sort.order ? 1 : 0}
                onClick={isSortable && sort ? () => sort.handleChange(key) : () => {}}
                cssWidth={width}
                key={index}
                css={css}
              >
                {label}
                {sort && key === sort.column && (
                  <SortTriangle order={sort.order} src={dropdownTriangle} alt="" />
                )}
              </NameHeaderColumn>
            ) : (
              <HeaderColumn
                order={sort && sort.order ? 1 : 0}
                onClick={isSortable && sort ? () => sort.handleChange(key) : () => {}}
                cssWidth={width}
                key={index}
                css={css}
              >
                {label}
                {sort && key === sort.column && (
                  <SortTriangle order={sort.order} src={dropdownTriangle} alt="" />
                )}
              </HeaderColumn>
            );
          })}
        </TableHeader>

        {rows.map(({ picture, fields, depth = 0 }, index) => {
          const isSelected = select.multi ? select.indices.includes(index) : select.index === index;
          let indent = '0px';
          if (depth !== undefined) {
            indent = `${depth * 45}px`;
          }
          return (
            <TableRow
              key={index || '0px'}
              onClick={() => select.handleChange(handleSelect(isSelected, index))}
              selected={isSelected}
              indent={indent}
              hierarchical={hierarchical}
              cursor={select.allowSelect ? 'pointer' : 'default'}
            >
              <BodyColumn css={pictureColumnWidth}>
                {dottedLines(depth)}
                {getAvatar(isSelected, picture)}
              </BodyColumn>
              {fields.map((field, i) => {
                if (i === 0 && hierarchical) {
                  return (
                    <NameColumn
                      css={columns[i].css}
                      cssWidth={columns[i].width}
                      indent={i === 0 ? indent : '0px'}
                      key={i}
                    >
                      {field}
                    </NameColumn>
                  );
                }
                return (
                  <BodyColumn
                    css={columns[i].css}
                    cssWidth={columns[i].width}
                    indent={i === 0 ? indent : '0px'}
                    key={i}
                  >
                    {field}
                  </BodyColumn>
                );
              })}
            </TableRow>
          );
        })}

        <TableFooter>{pagination && <Pagination {...pagination} />}</TableFooter>
      </Table>
    </TableContainer>
  );
};

OrganisationTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      css: PropTypes.object.isRequired,
    }).isRequired
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      picture: PropTypes.any.isRequired,
      fields: PropTypes.arrayOf(PropTypes.node).isRequired,
    }).isRequired
  ).isRequired,
  pagination: PropTypes.shape({
    index: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired,
  }),
  sort: PropTypes.shape({
    order: PropTypes.oneOf(['asc', 'desc', '']).isRequired,
    column: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
  }),
  select: PropTypes.shape({
    multi: PropTypes.bool.isRequired,
    indices: PropTypes.array,
    index: PropTypes.number,
    handleChange: PropTypes.func.isRequired,
  }).isRequired,
  hierarchical: PropTypes.bool,
};

export default OrganisationTable;
