import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AWS_S3_URL } from '../../constants';

const mapStateToProps = state => ({
  filters: state.postsDomain.filters,
  user: state.usersDomain.user,
  teams: state.teamsDomain.teams,
});

const mapDispatchToProps = dispatch => ({});

class SampleFeedRightPanel extends Component {
  render() {
    const sampleFilters = [];
    for (let i = 0; i < 3; i++) {
      sampleFilters.push(
        <div key={i}>
          <p>
            <img className="sample" alt="" src={`${AWS_S3_URL}public/images/text-odd.svg`} />
            <img className="sample" alt="" src={`${AWS_S3_URL}public/images/text-even.svg`} />
          </p>
        </div>
      );
    }
    const sampleMembers = [];
    for (let i = 4; i < 10; i++) {
      sampleMembers.push(
        <div key={i}>
          <a>
            <img className="sample" alt="" src={`${AWS_S3_URL}public/images/grey-img.svg`} />
            <img className="sample" alt="" src={`${AWS_S3_URL}public/images/grey-text.svg`} />
          </a>
        </div>
      );
    }
    const team = this.props.teams.find(team => team.id === parseInt(this.props.filters.space, 10));
    if (!team) return null;
    return (
      <div className="feed-right-panel-inner-wrap">
        <div className="search-input-wrap">
          <input filter-type="string" />
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7353 6.66667C11.7353 9.53059 9.43363 11.8333 6.61765 11.8333C3.80167 11.8333 1.5 9.53059 1.5 6.66667C1.5 3.80274 3.80167 1.5 6.61765 1.5C9.43363 1.5 11.7353 3.80274 11.7353 6.66667Z"
              stroke="#D5D5D5"
              strokeWidth="3"
            />
            <line
              x1="1.5"
              y1="-1.5"
              x2="6.01479"
              y2="-1.5"
              transform="matrix(0.704493 0.709711 -0.704493 0.709711 9 11)"
              stroke="#D5D5D5"
              strokeWidth="3"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <h3>
          Space members
          {(this.props.user.isAccountAdmin ||
            this.props.user.leadTeamsIds.includes(parseInt(this.props.filters.space, 10))) && (
            <Link to={`/team/${this.props.filters.space}`}>
              <img
                className="pull-right space-settings"
                src={`${AWS_S3_URL}public/images/icons/settings-grey.svg`}
                alt="settings"
              />
            </Link>
          )}
        </h3>
        <div className="filters-group space-members">{sampleMembers}</div>
        <h3>By post type</h3>
        <div className="filters-group">{sampleFilters}</div>
        <h3>By tag</h3>
        <div className="filters-group">{sampleFilters}</div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SampleFeedRightPanel);
