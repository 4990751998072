import React from 'react';
import DefaultFactorsScreen from './DefaultFactorsScreen';
import Factor from './Factor';

export default class SelectFeelFactors extends React.Component {
  // INFO: all screens
  // - default_factors
  // - other_factors
  constructor(props) {
    super(props);

    this.maxFactors = 1;

    this.state = {
      screen: 'default_factors',
      allowToSelect: this._totalSelectedFactors() < this.maxFactors,
      showAllFactors: false,
      emptyWhyFactors: false,
      message: this.props.message,
    };
  }

  render() {
    document.getElementsByClassName('page-wrapper')[0].firstChild.classList.remove('full-screen');
    return (
      <DefaultFactorsScreen
        title={this.props.title}
        getFactors={this.getFactors.bind(this)}
        raw_text={this.props.raw_text}
        nextStep={this.nextStep.bind(this)}
        previousStep={this.previousStep.bind(this)}
        enable_vibe_comments={this.props.enable_vibe_comments}
        disabledSubmitButton={this.disabledSelectButton()}
        emptyWhyFactors={this.state.emptyWhyFactors}
        showAnonDescModal={this.props.showAnonDescModal}
        factors={this.props.factors}
        setValueState={this.setMessageState.bind(this)}
        value={this.state.message}
        submit
        step={4}
      />
    );
  }

  setMessageState(e) {
    this.setState({ message: e.target.value });
  }

  addFactor(factor) {
    this.props.factors.forEach(function (selectedFactor) {
      if (selectedFactor.id === factor.id) {
        factor.selected = true;
      } else if (selectedFactor.selected == null) {
        selectedFactor.selected = false;
      }
    });
    this.props.factors.find(factor => (factor.alwaysVisible = true));

    // INFO: use == to avoid re-render every time
    if (this._totalSelectedFactors() === this.maxFactors) {
      this.setState({ allowToSelect: false });
    }
  }

  removeFactor(factor) {
    this.props.factors.forEach(function (selectedFactor) {
      if (selectedFactor.id === factor.id) {
        factor.selected = false;
      } else if (selectedFactor.selected == null) {
        selectedFactor.selected = false;
      }
    });

    // INFO: use == to avoid re-render every time
    if (this._totalSelectedFactors() === this.maxFactors - 1) {
      this.setState({ allowToSelect: true });
    }
    this.setState({ emptyWhyFactors: false });
  }

  previousStep() {
    const factor = this.props.factors.find(factor => factor.selected === true);
    if (factor) {
      this.removeFactor(factor);
    }
    this.props.previousStep({ feel_factors: this.props.factors });
  }

  nextStep() {
    this.props.nextStep({ feel_factors: this.props.factors, message: this.state.message });
  }

  maxSelectionReach() {
    return !this.state.allowToSelect;
  }

  disabledSelectButton() {
    const disableButton = this._totalSelectedFactors() === this.maxFactors;
    return disableButton ? '' : 'disabled';
  }

  _totalSelectedFactors() {
    return this.props.factors.filter(factor => factor.selected === true).length;
  }

  getFactors() {
    return this.props.factors
      .filter(function (factor) {
        return factor.position !== 10;
      })
      .map(factor => {
        return (
          <Factor
            factor={factor}
            disabled={this.maxSelectionReach()}
            addFactor={this.addFactor.bind(this)}
            removeFactor={this.removeFactor.bind(this)}
            key={factor.id}
          />
        );
      });
  }
}
