import React, { Component } from 'react';
import { AWS_S3_URL } from '../../constants';
import { BODY_CLASSES } from '../../helpers';

class Error404 extends Component {
  componentDidMount() {
    BODY_CLASSES(['Error']);
  }

  render() {
    return (
      <section className="ErrorPage ErrorPage-404">
        <header>
          <a href="/">
            <img src={`${AWS_S3_URL}public/images/jv-logo.png`} alt="Heelix Logo" />
          </a>
        </header>
        <span className="ErrorPage-type">
          <img src={`${AWS_S3_URL}public/images/404.png`} alt="404 Error - not found" />
        </span>
        <div className="ErrorPage-message">
          <p>There's nothing but tumbleweeds at this address.</p>
          <p>Are you sure you're at the right place?</p>
        </div>
        <a
          href="#"
          onClick={() => {
            window.history.back();
          }}
          className="ErrorPage-back"
        >
          Go back
        </a>
      </section>
    );
  }
}
export default Error404;
