import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import InvoicesForm from './InvoicesForm';
import { APP_URL } from '../../../constants';
import { BODY_CLASSES, CHECK_UNAUTHORIZED, LOCALSTORAGE_GET } from '../../../helpers';

class InvoicesNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
    };
  }

  componentDidMount() {
    BODY_CLASSES(['offline_invoices', 'new']);
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);
    fetch(`${APP_URL}/admin/offline_invoices/new`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({ accounts: jsonResponse.accounts });
        });
      })
      .catch(() => {});
  }

  sendForm(data) {
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);
    fetch(`${APP_URL}/admin/offline_invoices/new`, { method: 'post', headers, body: data })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({ redirect: jsonResponse.redirect });
        });
      })
      .catch(() => {});
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to="/admin/offline_invoices" />;
    }
    return (
      <div>
        <h1>New Offline Invoice</h1>
        {this.state.accounts.length > 0 ? (
          <InvoicesForm accounts={this.state.accounts} sendForm={this.sendForm.bind(this)} />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default InvoicesNew;
