/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import { Player } from 'video-react';
import ExifOrientationImg from 'react-exif-orientation-img';
import loadImage from 'blueimp-load-image';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import MentionsInputWrapper from 'components/shared/MentionsInputWrapper';
import FileUpload from 'components/vibes/createForms/partials/FileUpload';
import { POST_ACTIONS } from 'redux/actions/postActions';
import { HEADERS, ROTATE_IE_IMAGE, TOOLTIP_CONTENT } from 'helpers';
import { APP_URL, AWS_S3_URL } from 'constants.js';
import processGoogleFiles from 'components/shared/GooglePicker/processGoogleFiles';
import verifyVideo from 'components/shared/fileUpload/verifyVideo';
import verifyFileSize from 'components/shared/fileUpload/verifyFileSize';
import recalculateTotalFileSize from 'components/shared/fileUpload/recalculateTotalFileSize';
import GooglePicker from 'components/shared/GooglePicker';
import BottomBar from 'components/vibes/createForms/partials/BottomBar';
import { SPACE_ACTIONS } from 'redux/actions/spaceActions';
import ShareMenu from 'components/vibes/createForms/CreateNote/ShareMenu';
import generateFileFormData from 'components/shared/fileUpload/generateFileFormData';
import Flash from 'components/shared/Flash';
import { GROW_ACTIONS } from 'redux/actions/growActions';
import { icons } from 'components/vibes/createForms/partials/BottomBar/PreloadIcons';
import ShareBottomBar from 'components/vibes/createForms/CreateNote/ShareBottomBar';
import NoteButtons from 'components/vibes/createForms/CreateNote/NoteButtons';
import NoteTitle from 'components/vibes/createForms/CreateNote/NoteTitle';
import NoteRecipient from 'components/vibes/createForms/CreateNote/NoteRecipient';
import NoteDueDate from 'components/vibes/createForms/CreateNote/NoteDueDate';
import ValuesAndGoals from 'components/vibes/createForms/CreateNote/NoteTypes/ValuesAndGoals';
import ValuesAndGoalsButtons from 'components/vibes/createForms/CreateNote/NoteTypes/ValuesAndGoalsButtons';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import {
  resetRecentNote,
  setRecentNote,
  resetCurrentNoteModal,
  clearActionBody,
} from 'redux/app/actions';
import check from 'rbac/check';
import plans, { grow, limits } from 'rbac/plans';
import { PLAN_ACTIONS } from 'redux/actions/planActions';
import MeetingForm from 'components/vibes/createForms/CreateNote/NoteTypes/MeetingForm';
import ManagerPromptForm from 'components/vibes/createForms/CreateNote/NoteTypes/ManagerPromptForm';
import {
  getResponseTypeFromNoteType,
  handleTabRedirect,
} from 'components/vibes/createForms/CreateNote/typeParsers';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';
import PrivateCategoryButtons from './PrivateCategoryButtons';
import PostFile from '../../../feed/PostFile';
import {
  getNoteType,
  getPlaceholder,
  initialNoteState,
  recordNote,
  recordWin,
  validateNoteCreation,
} from './createNoteHelper';
import EditMeeting from '../EditMeeting';
import { FileDropzoneWrap } from '../utils/utils';
import CreateMeeting from '../CreateMeeting';
import AssigneeOption from './AssigneeOption';
import AssigneeValue from './AssigneeValue';
import { GROW_STEPS } from 'onboardingSteps';

let initialState = initialNoteState;

export const generateTitle = (role, team) => {
  if (role) return `${role}, ${team}`;
  return team;
};

const MAX_CHARS_CONTENT = 3000;

class CreateNote extends Component {
  allFilesSize = 0;

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  getButtonLabel = () => {
    if (this.props.converting) {
      return this.props.converting === 'edit' ? 'Submit' : 'Convert';
    }

    switch (this.state.toggledMode) {
      case noteTypes.win:
        return 'Create win';
      case noteTypes.private:
        return 'Give feedback';
      case noteTypes.goal:
        return 'Create task';
      default:
        return 'Create note';
    }
  };

  componentDidMount() {
    this.fetchData();
    this.initial = initialState;
    this.setState({
      toggledMode: getNoteType(this.props?.action),
      ...this.props.noteBody,
    });
    if (this.state.companyValues.length) this.setState({ valuesToggle: true });
    if (this.state.goals.length) this.setState({ goalsToggle: true });
    if (
      this.state.toggledMode === noteTypes.meeting &&
      this.props.action === 'create-note-quick-meeting'
    ) {
      if (this.state.meetingMember) {
        this.setMeetingMemberState(this.state.meetingMember);
      } else {
        this.setState({
          meetingIsSharing: false,
          meetingShareWith: [],
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.id && prevState.id !== this.state.id && this.props.recentNotes[this.state.id]) {
      this.setState(this.props.recentNotes[this.state.id]);
    }
    if (
      this.state.toggledMode !== prevState.toggledMode &&
      this.props.action !== 'create-note' &&
      this.props.action !== 'create-note-private' &&
      this.props.action !== 'create-note-win' &&
      this.props.action !== 'create-note-goal' &&
      this.props.action !== 'create-note-meeting-schedule' &&
      this.props.action !== 'create-note-meeting-oneoff' &&
      [noteTypes.meeting, noteTypes.quickMeeting, noteTypes.managerPrompt].includes(
        this.state.toggledMode
      )
    ) {
      this.setState(initialNoteState);
      this.setState({
        toggledMode: getNoteType(this.props.action),
        ...this.props.noteBody,
      });
    }
    if (this.props.action !== prevProps.action) {
      this.setState({ toggledMode: getNoteType(this.props.action) });
    }
    if (this.state.companyValues.length !== prevState.companyValues.length) {
      if (this.state.companyValues.length) this.setState({ valuesToggle: true });
    }
    if (this.state.goals.length !== prevState.goals.length) {
      if (this.state.goals.length) this.setState({ goalsToggle: true });
    }
  }

  componentWillUnmount() {
    if (this.props.converting) return;
    initialState = this.state;
    initialState.postErrors = [];
    initialState.errorState = false;
    initialState.redirectToYourSchedule = false;
    initialState.fromNote = false;
    initialState.preparationNote = null;
    initialState.meetingMemberId = null;
    initialState.flash.toggled = false;
    initialState.flash.type = 'success';
    initialState.toggledMode = noteTypes.freeText;
  }

  generalError = error => {
    if (error) {
      this.setState({
        generalError: error,
        disabledButton: false,
      });
    }
  };

  mediaUploadHandler = files => {
    let mediaUrl = [];
    let mediaSize = [];

    if (!this.verifyAndRecalculateFiles(files)) return;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const index = i;

      const type = file.type.split('/')[0];

      if (!this.checkIfCanAddFile(type)) {
        break;
      }

      if (
        (type === 'image' && !(this.state.mediaUrl[0] && this.state.mediaUrl[0].type === 'v')) ||
        (type === 'video' && this.state.mediaUrl.length === 0)
      ) {
        if (type === 'video' && files.length > 1) {
          // Breaks loop if there are images and videos simultaneously
          this.setState({
            mediaUrl: [],
            disabledButton: false,
            mediaSize: [],
            postErrors: [],
          });
          alert('You may attach either one video or few images.');
          break;
        }

        const reader = new FileReader();
        reader.onload = ((index, type) => e => {
          if (type === 'image') {
            const loadImageOptions = {
              orientation: true,
              noRevoke: true,
            };
            const img = ROTATE_IE_IMAGE(e.target.result);
            loadImage(
              img,
              canvas => {
                mediaUrl[index] = {
                  url: img,
                  type: type === 'image' ? 'i' : 'v',
                };
                mediaSize[index] = { size: file.size };
                if (mediaUrl.filter(Boolean).length === files.length) {
                  this.setState({
                    mediaUrl: [...this.state.mediaUrl, ...mediaUrl],
                    disabledButton: false,
                    mediaSize: [...this.state.mediaSize, ...mediaSize],
                    postErrors: [],
                  });
                }
              },
              loadImageOptions
            );
          } else {
            mediaUrl[index] = {
              url: reader.result,
              type: type === 'image' ? 'i' : 'v',
            };
            mediaSize[index] = { size: file.size };
            if (mediaUrl.filter(Boolean).length === files.length) {
              this.setState({
                mediaUrl: [...this.state.mediaUrl, ...mediaUrl],
                disabledButton: false,
                mediaSize: [...this.state.mediaSize, ...mediaSize],
                postErrors: [],
              });
            }
          }
        })(index, type);
        reader.readAsDataURL(file);
      } else {
        mediaSize = [];
        mediaUrl = [];
        alert('You may attach either one video or a few images.');
        return;
      }
    }
  };

  removeMediaHandler = index => {
    const updatedMediaUrl = [...this.state.mediaUrl];
    const updatedMediaSize = [...this.state.mediaSize];
    const { mediaSize } = this.state;

    this.allFilesSize -= mediaSize[index].size;
    updatedMediaUrl.splice(index, 1);
    updatedMediaSize.splice(index, 1);
    this.setState({
      mediaUrl: updatedMediaUrl,
      mediaSize: updatedMediaSize,
      postErrors: [],
    });
  };

  removeOldMediaHandler = index => {
    const updatedMedia = [...this.state.media];
    const mediaIndex = updatedMedia.findIndex(m => m.id === index);

    updatedMedia.splice(mediaIndex, 1);
    this.setState({
      media: updatedMedia,
      postErrors: [],
    });
  };

  fileUploadHandler = files => {
    for (let i = 0; i < files.length; i++) {
      if (files[i].size >= 10 * 1024 * 1024) {
        alert('One of files is too big! Maximum size allowed is 10MB');
        return;
      }
    }
    this.setState({
      files: [...this.state.files, ...files],
      postErrors: [],
    });
  };

  removeFileHandler = index => {
    const newFilesArray = [...this.state.files];
    newFilesArray.splice(index);
    this.setState({
      files: newFilesArray,
      postErrors: [],
    });
  };

  handleResponse = async (response, type) => {
    const json = await response.json();
    let message = '';
    if (response.status === 200 && response.ok) {
      if (!this.state.addAnother) {
        this.props.resetGrowPosts();
        this.props.fetchGrowPosts();
        this.props.fetchPostsCount();
        this.props.fetchPlanLimitations();
        this.setState(initialNoteState);
        if (this.props.progressStatus) {
          this.props.reloadProgressBoard();
          return this.props.closePopup(true);
        }
        handleTabRedirect(type, this.props.location, this.props.history.push);
        if (
          ['postGoalStep', 'winConvertFinishStep'].includes(
            GROW_STEPS[this.props.growTourStep]?.key
          )
        ) {
          this.props.setGrowTourStep(this.props.growTourStep + 1);
        }
        this.props.resetRecentNote();
        this.props.resetCurrentNote();
        this.props.clearActionBody();
        return this.props.closePopup(true);
      }
      message = `Successfully created ${type}.`;
      this.setState({
        ...initialState,
        data: this.state.data,
        addAnother: this.state.addAnother,
        flash: {
          ...this.state.flash,
          toggled: true,
          message,
          type,
        },
      });
    } else if (json.error && json.error.file_error) {
      this.setState({
        postErrors: json.error,
        errorState: false,
      });
    } else if (response.status === 422) {
      const errors = Object.values(json);
      for (const key in json) {
        const arr = key.split('.');
        if (arr[0] === 'steps') {
          const stepsArray = this.state.steps.map(s => ({ ...s }));
          stepsArray[arr[1]].error = json[key];
          this.setMeetingState('steps', stepsArray);
        }
      }
      if (errors.length) {
        message = errors[0];
        type = 'error';
      }
    } else {
      message = 'Something went wrong, please try again.';
      type = 'error';
    }
    this.setState({
      disabledButton: false,
      flash: {
        ...this.state.flash,
        toggled: true,
        message,
        type,
      },
    });
  };

  async submitForm() {
    const { content, files, mediaUrl, toggledMode, media } = this.state;

    const error = validateNoteCreation(
      this.state,
      toggledMode,
      this.props.noteBody.preparationNote,
      this.props.converting,
      MAX_CHARS_CONTENT
    );
    if (error) {
      return this.setState({ errorState: error });
    }
    if (toggledMode === noteTypes.meeting || toggledMode === noteTypes.managerPrompt) {
      if (this.props.noteBody.preparationNote) {
        this.setState({ meetingMemberId: this.props.noteBody.preparationNote.member_id });
        this.setState({ preparationNote: this.props.noteBody.preparationNote.id });
      } else if (this.state.member) {
        this.setState({ meetingMemberId: this.state.member.id });
      } else if (this.state.team) {
        this.setState({ meetingTeamId: this.state.team.id });
      }
    }
    this.setState({ disabledButton: true });
    let data = new FormData();
    data = await generateFileFormData(data, mediaUrl, files, media);
    const type = toggledMode;
    let response = [];
    if (type === noteTypes.win) {
      response = await recordWin(this.state, data, this.props.converting);
      this.handleResponse(response, 'win');
    } else {
      response = await recordNote(this.state, this.props, data, type, this.props.converting);
      this.handleResponse(response, getResponseTypeFromNoteType(type));
    }
  }

  contentChangedHandler = (event, unformattedContent) => {
    const input = event.target.value;
    this.setState({
      content: input,
      charsLeftContent: MAX_CHARS_CONTENT - unformattedContent.length,
      postErrors: [],
      isHashtag:
        this.checkIfContainHashtagOrMention(input, '[-/-markupStart-/-]hashtags:') ||
        this.checkIfContainHashtagOrMention(input, '#'),
      isMention: this.checkIfContainHashtagOrMention(input, '[-/-markupStart-/-]people:'),
    });
  };

  checkIfContainHashtagOrMention(string, type) {
    return string.includes(type);
  }

  handleBlur = event => {
    this.setState({ inputElement: event.target });
  };

  handleSelection = event => {
    this.setState({
      inputElement: event.target,
      selection: {
        start: event.target.selectionStart,
        end: event.target.selectionEnd,
      },
    });
  };

  showMediaDropzone(e) {
    if (this.props.reachedDataLimit) {
      e.preventDefault();
    } else {
      this.setState({ showMediaDropzone: !this.state.showMediaDropzone, showFileDropzone: false });
    }
  }

  showFileDropzone(e) {
    if (this.props.reachedDataLimit) {
      e.preventDefault();
    } else {
      this.setState({ showFileDropzone: !this.state.showFileDropzone, showMediaDropzone: false });
    }
  }

  handleGoogleFiles = files => {
    if (!this.verifyAndRecalculateFiles(files, 'sizeBytes', 'mimeType')) return;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const type = file.type.split('/')[0];
      if (!this.checkIfCanAddFile(type)) return;
    }
    this.setState(processGoogleFiles(files, this.state));
  };

  checkIfCanAddFile(uploadedType) {
    if (uploadedType === 'video' && this.state.media.length > 0) {
      if (this.state.media[0].type === 'v') {
        alert('You cannot add a video once there is another video in the post');
      } else {
        alert('You cannot add a video once there are images in the post');
      }
      return false;
    } else if (
      (uploadedType === 'photo' || uploadedType === 'image') &&
      this.state.media.length === 1 &&
      this.state.media[0].type === 'v'
    ) {
      alert('You cannot add an image once there is a video in the post');
      return false;
    }

    return true;
  }

  verifyAndRecalculateFiles = (files, sizeProperty = 'size', typeProperty = 'type') => {
    if (!verifyVideo(files, this.state.mediaUrl, typeProperty)) return false;
    const verifiedFiles = verifyFileSize(files, this.allFilesSize, sizeProperty);
    this.allFilesSize = verifiedFiles.size;
    if (verifiedFiles.alert) {
      this.allFilesSize = recalculateTotalFileSize(this.state.mediaSize);
      return false;
    }
    return true;
  };

  handleToggleMode = mode => {
    if (mode === this.state.toggledMode) {
      return this.setState({ toggledMode: noteTypes.freeText });
    }

    this.setState({ toggledMode: mode });
  };

  setSharing = toggle => () => {
    if (
      this.state.toggledMode === noteTypes.meeting ||
      this.state.toggledMode === noteTypes.quickMeeting
    ) {
      this.setState({ meetingIsSharing: toggle });
    } else {
      this.setState({ isSharing: toggle });
    }
  };

  setShareWith = users => {
    if (
      this.state.toggledMode === noteTypes.meeting ||
      this.state.toggledMode === noteTypes.quickMeeting
    ) {
      this.setState({ meetingShareWith: users });
    } else {
      this.setState({ shareWith: users });
    }
  };

  setAssignTo = user => {
    this.setState({ assignTo: user });
    if (!user) {
      this.setState({ isSharing: false });
    }
  };

  setMeetingState = (target, value, setRecent = true) => {
    this.setState({ [target]: value });

    if (!setRecent) return;
    this.props.setRecentNote({ [target]: value }, this.state.id);
  };

  setMeetingMemberState = value => {
    const user = this.state.data.leads.find(user => user.value === value.value);
    const sharing = !!user;
    this.setState(prevState => ({
      meetingMember: value,
      noteRecipient: value,
      meetingIsSharing: sharing,
      meetingShareWith: [value],
    }));
  };

  setNoteRecipient = value => {
    this.setState({ noteRecipient: value });
  };

  fetchData = async () => {
    this.setState({ loading: true });
    const headers = HEADERS();
    const response = await fetch(`${APP_URL}/member_notes/modal_data`, { headers });
    const json = await response.json();

    json.teams = json.teams.map(team => ({
      value: `${team.id}_team`,
      id: team.id,
      label: team.name,
      image_url: team.image_url,
      title: '',
      type: 'team',
      thumbnail_details: team.thumbnail_details,
    }));

    json.leads = json.leads
      .filter(lead => lead.id !== this.props.user.id)
      .map(lead => ({
        value: `${lead.id}_user`,
        id: lead.id,
        label: `${lead.first_name} ${lead.last_name}`,
        image: lead.image,
        title: generateTitle(lead.role, lead.team_name),
        type: 'user',
      }));

    const shareOptions = json.teams.concat(json.leads);

    shareOptions.sort((recipientA, recipientB) => {
      const nameA = recipientA.label ? recipientA.label.toLowerCase() : '';
      const nameB = recipientB.label ? recipientB.label.toLowerCase() : '';
      if (nameA < nameB) {
        return -1;
      }
      if (nameA >= nameB) {
        return 1;
      }
      return 0;
    });

    json.leads = shareOptions;

    json.users = json.users
      .filter(user => user.id !== this.props.user.id)
      .map(user => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
        image: user.image,
        title: generateTitle(user.role, user.team_name),
      }));

    json.feedbackUsers = json.feedbackUsers
      .filter(user => user.id !== this.props.user.id)
      .map(user => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
        image: user.image,
        title: generateTitle(user.role, user.team_name),
      }));

    let mediaArray = this.state.media;
    if (mediaArray.some(image => image.type === 'v')) {
      mediaArray = mediaArray.filter(image => {
        return image.type === 'v';
      });
    }

    this.setState({
      loading: false,
      data: json,
      media: mediaArray,
    });
    return json.leads;
  };

  closeFlash = () => {
    this.setState({
      flash: {
        ...this.state.flash,
        toggled: false,
      },
    });
  };

  setMembers = members => {
    const formattedMembers = members
      .filter(user => user.id !== this.props.user.id)
      .map(user => ({
        id: user.value,
        value: `${user.value}_user`,
        label: `${user.first_name} ${user.last_name}`,
        image: user.image_url,
        title: generateTitle(user.role, user?.team?.name),
        type: 'user',
      }));

    this.setState({
      members: formattedMembers,
    });
  };

  toggleMeeting = () => {
    if (
      this.state.toggledMode === noteTypes.meeting ||
      this.state.toggledMode === noteTypes.quickMeeting
    ) {
      this.setState(state => ({
        toggledMode: noteTypes.private,
        meetingShareWith: [],
        fromNote: false,
        content: state.meetingContent,
      }));
    } else {
      this.setState(state => ({
        toggledMode: noteTypes.meeting,
        fromNote: true,
        meetingContent: state.content,
      }));
    }
  };

  disableButtonBasedOnToggledMode = (
    mode,
    plan,
    { notesCount, privateNotesCount, goalsCount, meetingsCount, winsCount }
  ) => {
    switch (mode) {
      case noteTypes.freeText:
        return !check(plans, plan, grow.addNote, { notesCount });
      case noteTypes.private:
        return !check(plans, plan, grow.addManagerNote, { privateNotesCount });
      case noteTypes.goal:
        return !check(plans, plan, grow.addGoal, { goalsCount });
      case noteTypes.win:
        return !check(plans, plan, grow.addWin, { winsCount });
      case noteTypes.meeting:
      case noteTypes.quickMeeting:
        return !check(plans, plan, grow.addMeeting, { meetingsCount });
      default:
        return false;
    }
  };

  showPlanModal = (disabled, mode, plan) => {
    const { planLimitations, showUpgradeModal } = this.props;
    let permission;
    let type;
    let count;
    switch (mode) {
      case noteTypes.freeText:
        permission = grow.addNote;
        type = 'note';
        count = planLimitations.notesCount;
        break;
      case noteTypes.private:
        permission = grow.addManagerNote;
        type = 'manager note';
        count = planLimitations.privateNotesCount;
        break;
      case noteTypes.goal:
        permission = grow.addGoal;
        type = 'goal';
        count = planLimitations.goalsCount;
        break;
      case noteTypes.win:
        permission = grow.addWin;
        type = 'win';
        count = planLimitations.winsCount;
        break;
      case noteTypes.meeting:
      case noteTypes.quickMeeting:
        permission = grow.addMeeting;
        type = 'meeting note';
        count = planLimitations.meetingsCount;
        break;
      default:
        return;
    }

    const limit = limits[plan][permission];
    if (disabled) {
      showUpgradeModal(
        `On your plan you only have ${limit} ${type}${
          limit === 1 ? '' : 's'
        } available to create each month for your account. Chat with your account owner about upgrading your plan to get access to more notes, meetings, wins and more.`,
        `On your plan you only have ${limit} ${type}${
          limit === 1 ? '' : 's'
        } available to create each month for your account. Upgrade your plan to get access to more notes, meetings, wins and more.`
      );
    } else if (limit * 0.75 <= count + 1) {
      this.props.showPrompt({
        type: 'GrowLimitPrompt',
        color: 'yellow',
        data: { type, isLimitReached: limit === count + 1 },
      });
    }
  };

  companyValueChoiceHandler(e) {
    const companyValues = [...this.state.companyValues];
    companyValues.push(e);
    this.setState({
      companyValues,
    });
  }

  render() {
    let mediaArray = this.state.media;
    if (mediaArray.some(image => image.type === 'v')) {
      mediaArray = mediaArray.filter(image => {
        return image.type === 'v';
      });
    }
    const errorStep = this.state.steps.find(s => s.error && s.error !== '');
    const { toggledMode } = this.state;
    const disabledButtonBasedOnToggledMode = this.disableButtonBasedOnToggledMode(
      toggledMode,
      this.props.plan,
      this.props.planLimitations
    );
    const disabledButton =
      this.state.disabledButton ||
      this.state.charsLeftContent < 0 ||
      this.state.nameError ||
      !!errorStep ||
      disabledButtonBasedOnToggledMode;
    const isQuickMeeting = toggledMode === noteTypes.quickMeeting;
    const isMeeting = toggledMode === noteTypes.meeting || isQuickMeeting;
    const isMeetingOrPreparation = isMeeting || toggledMode === noteTypes.meetingPreparation;
    const isPreparation = toggledMode === noteTypes.meetingPreparation;
    const isManagerPrompt = toggledMode === noteTypes.managerPrompt;
    if (toggledMode === noteTypes.meetingSchedule || toggledMode === noteTypes.meetingOneOff) {
      return (
        <CreateMeeting
          onClick={this.handleToggleMode}
          setRedirectToYourSchedule={this.props.setRedirectToYourSchedule}
          closePopup={this.props.closePopup}
          users={this.state.data.users}
          leads={this.state.data.leads}
          handleResponse={this.handleResponse}
          flash={this.state.flash}
          closeFlash={this.closeFlash}
          toggledMode={toggledMode}
        />
      );
    }
    return (
      <div
        className="create-discussion-tab"
        css={css`
          ${(isManagerPrompt || isMeetingOrPreparation) &&
          !this.state.fromNote &&
          'background: #FFFFFF !important; padding: 24px 32px 0px 32px !important;'}
          ${this.props.action === 'create-note-quick-meeting' &&
          'padding: 10px 32px 0px 32px !important;'}
        `}
      >
        {this.state.flash.toggled && this.state.flash.message && (
          <div
            css={css`
              width: 100%;
              margin-bottom: ${isMeetingOrPreparation ? (isPreparation ? 40 : 30) : 15}px;
              margin-top: ${isPreparation ? -30 : 0}px;
            `}
          >
            <Flash
              closeFlash={this.closeFlash}
              flashMessage={this.state.flash.message}
              flashType={this.state.flash.type}
              dismissable
            />
          </div>
        )}
        <div
          className="create-discussion-feed"
          css={css`
            margin-top: -23px;
          `}
        >
          {(!isMeetingOrPreparation || this.state.fromNote) && !isManagerPrompt && (
            <>
              <NoteButtons
                onClick={this.handleToggleMode}
                toggled={
                  isMeetingOrPreparation && this.state.fromNote ? noteTypes.private : toggledMode
                }
                converting={this.props.converting}
                showMediaDropzone={e => this.showMediaDropzone(e)}
                shownMediaDropzone={this.state.showMediaDropzone}
                showFileDropzone={e => this.showFileDropzone(e)}
                shownFileDropzone={this.state.showFileDropzone}
              />
              <NoteTitle
                value={this.state.title}
                onChange={e => this.setState({ title: e.target.value })}
                mode={toggledMode}
              />
              {this.props.converting !== 'edit' &&
                (toggledMode === 'Private' || toggledMode === 'Meeting') && (
                  <NoteRecipient
                    users={
                      toggledMode === 'Private'
                        ? this.state.data.feedbackUsers
                        : this.state.data.users
                    }
                    fromNote={this.state.fromNote}
                    noteRecipient={this.state.noteRecipient}
                    setNoteRecipient={noteRecipient => this.setState({ noteRecipient })}
                    toggleMeeting={this.toggleMeeting}
                    isMeeting={isMeeting}
                    mode={toggledMode}
                    setMeetingMemberState={this.setMeetingMemberState}
                    setMembers={this.setMembers}
                    members={this.state.data.leads}
                  />
                )}
            </>
          )}
          {isMeetingOrPreparation && toggledMode === noteTypes.meetingPreparation && (
            <EditMeeting
              state={this.state}
              setMeetingState={this.setMeetingState}
              user={this.props.user}
            />
          )}
          {isMeeting && (
            <MeetingForm
              state={this.state}
              setMeetingState={this.setMeetingState}
              showDatePicker={!this.props.noteBody.preparationNote}
              showSelectMember={
                !this.props.noteBody.preparationNote && this.props.converting !== 'edit'
              }
              setMeetingMemberState={this.setMeetingMemberState}
              members={this.state.data.leads}
              setMembers={this.setMembers}
              contentChangedHandler={this.contentChangedHandler}
            />
          )}
          {isManagerPrompt && (
            <ManagerPromptForm
              stepsCount={this.state.stepsCount}
              steps={this.state.steps}
              setMeetingState={this.setMeetingState}
              notes={this.state.meetingContent}
              setNotes={value => this.setMeetingState('meetingContent', value)}
            />
          )}
          {!isMeetingOrPreparation && !isManagerPrompt && (
            <>
              <MentionsInputWrapper
                enableMentions={false}
                handleBlur={this.handleBlur}
                handleSelection={this.handleSelection}
                value={this.state.content}
                contentChangedHandler={this.contentChangedHandler}
                placeholder={getPlaceholder(toggledMode, this.state.wantCategory)}
                setInputRef={ref => this.setState({ inputElement: ref })}
                css={
                  toggledMode === noteTypes.private
                    ? css`
                        padding-bottom: 80px !important;
                        min-height: 164px !important;
                      `
                    : css`
                        textarea {
                          min-height: 112px !important;
                        }
                      `
                }
              />
              <PrivateCategoryButtons
                mode={toggledMode}
                categories={this.state.data.categories}
                setCategory={category =>
                  this.setState({
                    category: this.state.category === category ? '' : category,
                  })
                }
                category={this.state.category}
              />
              <div
                css={css`
                  display: flex;
                  flex-direction: ${toggledMode === noteTypes.goal ? 'column' : 'column-reverse'};
                `}
              >
                <div
                  css={css`
                    display: flex;
                    margin-top: 5px;
                    flex-direction: column;
                    align-items: baseline;
                    @media (min-width: 600px) {
                      flex-direction: row;
                    }
                  `}
                >
                  {toggledMode === noteTypes.goal && (
                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        > div {
                          margin-bottom: 10px;
                          width: 100% !important;
                          .Select-menu-outer {
                            z-index: 3 !important;
                          }
                        }
                        @media (min-width: 660px) {
                          width: 50%;
                        }
                      `}
                    >
                      {(!this.state.isSharing || (this.state.isSharing && this.state.assignTo)) && (
                        <Select
                          options={this.state.data.users}
                          inputProps={{ id: 'shoutout-for-input' }}
                          value={this.state.assignTo ? this.state.assignTo.value : null}
                          searchable
                          onChange={this.setAssignTo}
                          clearable
                          className="select-component recipients orange assign"
                          placeholder={
                            this.state.loading ? 'Getting users...' : 'Assign to: Myself'
                          }
                          disabled={false}
                          optionComponent={AssigneeOption}
                          valueComponent={AssigneeValue}
                        />
                      )}
                      <NoteDueDate
                        mode={toggledMode}
                        setDate={date => this.setState({ dueDate: date })}
                        date={this.state.dueDate}
                        initialToggled={this.state.toggleDatePicker}
                      />
                    </div>
                  )}
                  <ValuesAndGoalsButtons
                    shouldSeeCompanyValues={
                      this.props.isAccountAdmin || Boolean(this.props.companyValues.length)
                    }
                    mode={toggledMode}
                    setGoalsToggle={value => this.setState({ goalsToggle: value })}
                    goalsToggle={this.state.goalsToggle}
                    setValuesToggle={value => this.setState({ valuesToggle: value })}
                    valuesToggle={this.state.valuesToggle}
                    converting={this.props.converting}
                  />
                </div>
                {this.state.data.leads && toggledMode !== noteTypes.freeText && (
                  <ValuesAndGoals
                    companyValues={this.state.companyValues}
                    setCompanyValues={companyValues => this.setState({ companyValues })}
                    goals={this.state.goals}
                    setGoals={goals => this.setState({ goals })}
                    goalsToggle={this.state.goalsToggle}
                    valuesToggle={this.state.valuesToggle}
                  />
                )}
              </div>
            </>
          )}
          <span
            id="error-message"
            className={`error-message text-danger ${this.state.errorState && 'shown'}`}
            css={css`
              margin: 10px;
              display: block;
            `}
          >
            {this.state.errorState}
          </span>
          {this.state.charsLeftContent < 0 && (
            <p className="text-danger">
              Your post exceeds the maximum length of {MAX_CHARS_CONTENT} characters
            </p>
          )}
          {this.state.postErrors && this.state.postErrors['post.content'] ? (
            <div className="fix-tile-error">
              <span className="text-danger">{this.state.postErrors['post.content'][0]}</span>
            </div>
          ) : (
            ''
          )}
          {this.state.postErrors && this.state.postErrors.mention_error && this.state.isMention ? (
            <div className="fix-tile-error">
              <span className="text-danger">{this.state.postErrors.mention_error}</span>
            </div>
          ) : (
            ''
          )}
          {this.state.oldFiles.map((file, index) => (
            <PostFile file={file} key={index} />
          ))}
          {this.state.files.map((file, index) => (
            <FileUpload
              file={file}
              typeProperty={file.mimeType ? 'mimeType' : 'type'}
              key={index}
              fileIndex={index}
              removeFileHandler={this.removeFileHandler}
            />
          ))}
          {this.state.showMediaDropzone ? (
            <div className="dropzone-wrap">
              <Dropzone
                style={{
                  width: '100%',
                  height: '80px',
                  border: 'dashed 1px #11A9FF',
                  borderRadius: '3px',
                  position: 'relative',
                  zIndex: '2',
                }}
                accept="image/*, video/*"
                inputProps={{ capture: false }}
                onDrop={e => this.mediaUploadHandler(e)}
              />
              <div className="dropzone-placeholder">
                <h3>
                  <svg
                    width="19"
                    height="16"
                    viewBox="0 0 19 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.6759 3H17.0344C18.1155 3 19 3.90002 19 5V14C19 15.1 18.1155 16 17.0344 16H1.96558C0.884521 16 0 15.1 0 14V5C0 3.90002 0.884521 3 1.96558 3H3.66895C3.99316 3 4.34229 2.74402 4.44507 2.43103L5.05481 0.569214C5.15723 0.255981 5.50671 0 5.83105 0H12.5138C12.8381 0 13.1871 0.255981 13.2899 0.56897L13.8997 2.43079C14.0021 2.74402 14.3516 3 14.6759 3ZM5.24146 8.99988C5.24146 6.97546 6.85461 5.33325 8.84485 5.33325C10.8347 5.33325 12.4484 6.97546 12.4484 8.99988C12.4484 11.0251 10.8347 12.6666 8.84485 12.6666C6.85461 12.6666 5.24146 11.0251 5.24146 8.99988ZM8.84497 14C6.1311 14 3.93115 11.7614 3.93115 9C3.93115 6.23877 6.1311 4 8.84497 4C11.5583 4 13.7588 6.23877 13.7588 9C13.7588 11.7614 11.5583 14 8.84497 14ZM15.7241 5.33337C15.7241 5.60632 16.0176 6.00012 16.3793 6.00012C16.7411 6.00012 17.0344 5.60632 17.0344 5.33337C17.0344 5.06079 16.7413 4.66675 16.3793 4.66675C16.0176 4.66675 15.7241 5.06079 15.7241 5.33337Z"
                      fill="#11A9FF"
                    />
                  </svg>
                  Drop your image or video here to upload or <span>browse</span>
                </h3>
              </div>
            </div>
          ) : (
            ''
          )}
          {this.state.showFileDropzone ? (
            <FileDropzoneWrap>
              <div className="dropzone-wrap file-dropzone-wrap">
                <Dropzone
                  style={{
                    width: '100%',
                    height: '80px',
                    border: 'dashed 1px #11A9FF',
                    borderRadius: '3px',
                    position: 'relative',
                    zIndex: '2',
                  }}
                  accept="application/pdf, application/msword, application/vnd.ms-excel, text/plain"
                  onDropAccepted={e => this.fileUploadHandler(e)}
                  onDropRejected={() => alert('File extension mismatch')}
                >
                  <div className="dropzone-placeholder">
                    <h3>
                      <svg
                        width="24"
                        height="18"
                        viewBox="0 0 24 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.5363 8.99771L19.3488 8.99922C19.4463 8.50578 19.4988 8.00182 19.4988 7.49937C19.4988 3.36422 16.1345 0 11.9993 0C7.86703 0 4.49976 3.33123 4.49523 7.42292L4.50721 7.5744C1.93789 7.9433 0 10.1646 0 12.7504C0 15.3692 1.88687 17.622 4.49971 18H8.24948C8.66346 18 8.99942 17.6625 8.99942 17.2501C8.99942 16.8376 8.66346 16.5001 8.24948 16.5001L4.6092 16.5076C2.86631 16.2512 1.49989 14.5998 1.49989 12.7505C1.49989 10.9071 2.88278 9.32324 4.71869 9.06076L5.36363 8.96774C5.73258 8.91527 6.0071 8.5988 6.0071 8.22532L5.99512 7.4274C5.9996 4.15766 8.69196 1.4999 11.9992 1.4999C15.308 1.4999 17.9989 4.19069 17.9989 7.49941C17.9989 7.90588 17.9584 8.31536 17.8759 8.71281L17.6779 9.66675C17.6284 9.90521 17.6989 10.1512 17.8639 10.3297C18.0274 10.5067 18.2659 10.5982 18.5119 10.5607C18.5299 10.5592 18.9979 10.4977 19.5378 10.4977C21.1727 10.4977 22.5001 11.8431 22.5001 13.499C22.5001 15.1548 21.1562 16.4987 19.5003 16.4987H14.2506C12.1508 16.4987 12.0083 15.2404 12.0008 14.9988V10.0221L13.7766 11.7965C14.0676 12.086 14.5446 12.086 14.8371 11.7965C15.128 11.504 15.128 11.0255 14.8371 10.7361L11.8177 7.71834C11.6707 7.57133 11.4772 7.49937 11.2838 7.49937L11.2508 7.49337C11.0348 7.49337 10.8443 7.58639 10.7078 7.73183L7.68699 10.7496C7.39448 11.0406 7.39448 11.519 7.68699 11.81C7.97949 12.101 8.45496 12.101 8.74741 11.81L10.5008 10.0581V14.9987C10.5008 16.0352 11.2838 17.9985 14.2506 17.9985H19.5003C21.9811 17.9985 24 15.9797 24 13.4989C24 11.0181 21.9977 8.99771 19.5363 8.99771Z"
                          fill="#11A9FF"
                        />
                      </svg>
                      Drop your files here to upload or <span>browse</span>
                    </h3>
                  </div>
                </Dropzone>
              </div>
              <GooglePicker
                handleFiles={this.handleGoogleFiles}
                iconType={
                  isQuickMeeting ||
                  isPreparation ||
                  isManagerPrompt ||
                  this.props.action === 'create-note-meeting'
                    ? 'grey'
                    : 'white'
                }
              />
            </FileDropzoneWrap>
          ) : (
            ''
          )}
          {this.state.mediaUrl.length > 0 || this.state.media.length > 0 ? (
            <div id="images-wrap">
              {this.state.mediaUrl.map((media, index) =>
                media.type === 'i' ? (
                  <div className="preview" key={index} id={`image-preview-${index}`}>
                    <ExifOrientationImg src={media.url} alt="" className="preview-img" />
                    <div className="preview-cross" onClick={() => this.removeMediaHandler(index)} />
                  </div>
                ) : (
                  <div
                    className="preview video-add-modal"
                    key={index}
                    id={`image-preview-${index}`}
                  >
                    <div className="preview-cross" onClick={() => this.removeMediaHandler(index)} />
                    <Player playsInline src={media.url} />
                  </div>
                )
              )}
              {mediaArray.map(media =>
                media.type === 'i' ? (
                  <div className="preview" key={media.id} id={`image-preview-${media.id}`}>
                    <ExifOrientationImg src={media.url} alt="" className="preview-img" />
                    <div
                      className="preview-cross"
                      onClick={() => this.removeOldMediaHandler(media.id)}
                    />
                  </div>
                ) : (
                  <div
                    className="preview video-add-modal"
                    key={media.id}
                    id={`image-preview-${media.id}`}
                  >
                    <div
                      className="preview-cross"
                      onClick={() => this.removeOldMediaHandler(media.id)}
                    />
                    <Player playsInline src={media.path} />
                  </div>
                )
              )}
            </div>
          ) : (
            ''
          )}
          {this.state.postErrors && this.state.postErrors.file_error ? (
            <div className="fix-tile-error">
              <span className="text-danger">{this.state.postErrors.file_error}</span>
            </div>
          ) : (
            ''
          )}
          <BottomBar
            secondBar={
              toggledMode !== noteTypes.meetingPreparation &&
              (isMeetingOrPreparation ? this.state.meetingIsSharing : this.state.isSharing) &&
              !this.state.assignTo ? (
                <ShareBottomBar
                  users={
                    this.props.isLeadOrAdmin &&
                    this.props.growTourStep >= 0 &&
                    this.props.onboardingUser
                      ? this.props.onboardingUser
                      : this.state.data.users
                  }
                  leads={this.state.data.leads}
                  shareWith={
                    isMeetingOrPreparation ? this.state.meetingShareWith : this.state.shareWith
                  }
                  setShareWith={this.setShareWith}
                  assignTo={this.state.assignTo}
                  isSharing={
                    isMeetingOrPreparation ? this.state.meetingIsSharing : this.state.isSharing
                  }
                  mode={toggledMode}
                />
              ) : null
            }
            css={css`
              margin-top: 15px;
            `}
          >
            {toggledMode !== noteTypes.meetingPreparation && (
              <ShareMenu
                setShareWith={this.setShareWith}
                assignTo={this.state.assignTo}
                setAssignTo={this.setAssignTo}
                isSharing={
                  isMeetingOrPreparation ? this.state.meetingIsSharing : this.state.isSharing
                }
                setSharing={this.setSharing}
                mode={toggledMode}
                isSharedWithMultiple={
                  isMeetingOrPreparation
                    ? this.state.meetingShareWith.length > 1
                    : this.state.shareWith.length > 1
                }
              />
            )}
            {!isManagerPrompt && (
              <div className="controls-panel pull-left">
                {this.props.reachedDataLimit && (
                  <ReactTooltip
                    id="media-tooltip"
                    class="react-tooltip limit-reached"
                    event="click"
                    globalEventOff="click"
                    place="top"
                    type="dark"
                    effect="solid"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: TOOLTIP_CONTENT(this.props.user.isAccountAdmin),
                      }}
                    />
                  </ReactTooltip>
                )}

                {!this.state.showMediaDropzone ? (
                  <img
                    data-tip
                    data-for="media-tooltip"
                    className={this.props.reachedDataLimit ? 'cursor-not-allowed' : ''}
                    onClick={e => this.showMediaDropzone(e)}
                    src={`${AWS_S3_URL}public/images/icons/${icons.mediaIcon}`}
                    alt=""
                    id="media-button"
                  />
                ) : (
                  <img
                    onClick={() =>
                      this.setState({
                        showMediaDropzone: !this.state.showMediaDropzone,
                        showFileDropzone: false,
                      })
                    }
                    src={`${AWS_S3_URL}public/images/icons/${icons.mediaIconToggled}`}
                    id="media-button"
                    alt=""
                  />
                )}

                {this.props.reachedDataLimit && (
                  <ReactTooltip
                    id="file-tooltip"
                    class="react-tooltip limit-reached"
                    event="click"
                    globalEventOff="click"
                    place="top"
                    type="dark"
                    effect="solid"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: TOOLTIP_CONTENT(this.props.user.isAccountAdmin),
                      }}
                    />
                  </ReactTooltip>
                )}

                <div
                  data-tip
                  data-for="file-tooltip"
                  onClick={e => this.showFileDropzone(e)}
                  className={this.props.reachedDataLimit ? 'cursor-not-allowed' : ''}
                  custom-checked={this.state.showFileDropzone.toString()}
                >
                  {this.state.showFileDropzone ? (
                    <img
                      src={`${AWS_S3_URL}public/images/icons/${icons.clipIconToggled}`}
                      alt=""
                      id="file-button"
                    />
                  ) : (
                    <img
                      src={`${AWS_S3_URL}public/images/icons/${icons.clipIcon}`}
                      alt=""
                      id="file-button"
                    />
                  )}
                </div>
              </div>
            )}
            <div
              className="info-panel pull-right"
              css={
                (isManagerPrompt &&
                  css`
                    margin-left: auto !important;
                  `,
                isPreparation &&
                  css`
                    margin-left: unset !important;
                  `)
              }
              onClick={() =>
                this.showPlanModal(disabledButtonBasedOnToggledMode, toggledMode, this.props.plan)
              }
            >
              <input
                id="post-btn"
                type="button"
                data-disable-with="Posting..."
                disabled={disabledButton}
                name="commit"
                className="btn btn-primary"
                value={this.getButtonLabel()}
                onClick={() => this.submitForm()}
                css={
                  disabledButtonBasedOnToggledMode &&
                  css`
                    pointer-events: none;
                  `
                }
              />
            </div>
          </BottomBar>
        </div>
      </div>
    );
  }
}

CreateNote.propTypes = {
  spaceId: PropTypes.number,
  action: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  reloadProgressBoard: () => dispatch(GROW_ACTIONS.reloadProgressBoard()),
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  fetchPosts: () => dispatch(POST_ACTIONS.fetchPosts()),
  resetGrowPosts: () => dispatch(GROW_ACTIONS.resetPosts()),
  fetchGrowPosts: () => dispatch(GROW_ACTIONS.fetchPosts()),
  fetchPostsCount: () => dispatch(GROW_ACTIONS.fetchPostsCount()),
  fetchSpaceDetails: spaceId => dispatch(SPACE_ACTIONS.fetchSpaceDetails(spaceId)),
  filtersFromObject: object => dispatch(POST_ACTIONS.filtersFromObject(object)),
  setGrowTourStep: step => dispatch(ONBOARDING_ACTIONS.setGrowTourStep(step)),
  setRecentNote: (note, id) => dispatch(setRecentNote(note, id)),
  resetRecentNote: () => dispatch(resetRecentNote()),
  resetCurrentNote: () => dispatch(resetCurrentNoteModal()),
  clearActionBody: () => dispatch(clearActionBody()),
  showUpgradeModal: (normalContent, adminContent) =>
    dispatch(
      PLAN_ACTIONS.showUpgradeModal({
        normalContent,
        adminContent,
      })
    ),
  fetchPlanLimitations: () => dispatch(PLAN_ACTIONS.fetchPlanLimitations()),
  showPrompt: prompt => dispatch(PLAN_ACTIONS.showPrompt(prompt)),
});

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  tags: state.tagsDomain.hashtags,
  companyValues: state.tagsDomain.companyValues,
  hashtags: state.tagsDomain.hashtags,
  discussionTags: state.tagsDomain.discussionTags,
  filters: state.postsDomain.filters,
  reachedDataLimit: state.plansDomain.reachedDataLimit,
  converting: state.app.body.converting,
  noteBody: state.app.body,
  progressStatus: state.app.body.progressStatus,
  growTourStep: state.onboardingDomain.growTourStep,
  isAccountAdmin: state.usersDomain.user.isAccountAdmin,
  isLeadOrAdmin: Boolean(
    state.usersDomain.user.isAccountAdmin || state.usersDomain.user.formalLeadTeamsIds.length
  ),
  onboardingUser: state.onboardingDomain.onboardingUser,
  recentNotes: state.app.recentNotes,
  plan: state.plansDomain.rbacPlan,
  planLimitations: state.plansDomain.planLimitations,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateNote));
