import React, { Component } from 'react';
import FactorsBarVertical from './FactorsChartVertical/FactorsBarVertical';

class FactorsChartVertical extends Component {
  chartHeight = 200;

  render() {
    const { dashboard } = this.props;
    const maxValue = this.props.data.reduce((a, b) => {
      const sumCurrent =
        b.current.terrible + b.current.bad + b.current.okay + b.current.good + b.current.awesome;
      const sumPrevious = this.props.showPrevious
        ? b.previous.terrible +
          b.previous.bad +
          b.previous.okay +
          b.previous.good +
          b.previous.awesome
        : 0;
      const sum = sumCurrent > sumPrevious ? sumCurrent : sumPrevious;
      return a > sum ? a : sum;
    }, 0);
    const maxStep = maxValue + 5;
    return (
      <div className="factors-chart">
        {[...Array(6)].map((obj, index) => (
          <div
            className={`chart-step ${index === 0 ? 'start' : ''} ${index === 5 ? 'end' : ''}`}
            style={{ bottom: `${index * 20}%` }}
            key={index}
          >
            {!dashboard && index === 0 ? <div className="y-axis-legend">0</div> : ''}
            {!dashboard && index === 5 ? (
              <div className="y-axis-legend">{maxStep}</div>
            ) : (
              ''
            )}
          </div>
        ))}
        {this.props.data.map((data, index) => (
          <div className="chart-bar-group" key={index}>
            {this.props.showPrevious ? (
              <FactorsBarVertical
                data={data.previous}
                chartHeight={this.chartHeight}
                maxStep={maxStep}
                className="previous"
                factor={
                  typeof this.props.factors[index].props.children === 'string'
                    ? this.props.factors[index].props.children
                    : this.props.factors[index].props.children.reduce((a, b) => a + b, '')
                }
                factorKey={index}
                queryString={this.props.queryString}
                dashboard={dashboard}
              />
            ) : (
              ''
            )}
            <FactorsBarVertical
              data={data.current}
              chartHeight={this.chartHeight}
              maxStep={maxStep}
              className="current"
              factor={
                typeof this.props.factors[index].props.children === 'string'
                  ? this.props.factors[index].props.children
                  : this.props.factors[index].props.children.reduce((a, b) => a + b, '')
              }
              factorKey={index}
              queryString={this.props.queryString}
              dashboard={dashboard}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default FactorsChartVertical;
