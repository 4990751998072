import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import DropdownList from 'components/vibes/team-vibe-show/partials/TeamDropdown/DropdownList';
import styled from '@emotion/styled';
import ReactDOM from 'react-dom';
import throttle from 'lodash/throttle';

const DropdownContainer = styled.div`
  border: 1px solid #e6e6e6;
  border-radius: ${({ open }) => (!open ? '4px' : '0 0 4px 4px')};
  background: #ffffff;

  h1 {
    display: none;
  }

  .teams-list {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 310px;
  }

  input {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    padding: 10px 13px;
    height: 42px;
    outline: none;

    &::placeholder {
      color: #c4c4c4;
      font-style: italic;
    }
    &:focus {
      border: 1px solid #11a9ff;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 15px;
    margin-top: 15px;
    margin-left: 3px;
    margin-bottom: 11px;
  }

  a {
    text-decoration: none;
    color: inherit;
    margin-bottom: 13px;
    display: flex;
    align-items: center;

    .space-logo,
    img {
      display: inline-block;
      font-weight: 900;
      width: 25px;
      height: 25px;
      border: 1px solid white;
      border-radius: 100%;
      margin: 0;
      line-height: 25px;
      font-size: 15px;
      text-align: center;
    }

    .team-name {
      font-size: 14px;
      line-height: 16px;
      display: inline-block;
      position: relative;
      left: 11px;
      top: 1px;
      flex-grow: 1;
    }
  }

  > div {
    padding: 14px 15px 11px;
  }
`;

const root = document.getElementById('root');

const PortalDropdown = props => {
  const [portalRef, setPortalRef] = useState(null);
  const propFilterRef = useRef(null);

  const handleAdjustPosition = portalRef => {
    const filterRef = propFilterRef?.current?.current ?? props.filterButtonRef?.current;

    if (!portalRef || !filterRef) return;

    const rect = filterRef.getBoundingClientRect();
    portalRef.style.top = `${rect.bottom}px`;
    portalRef.style.width = `${rect.width + 2}px`;
    const windowWidth = Math.min(document.documentElement.clientWidth, window.innerWidth || 0);
    portalRef.style.right = `${windowWidth - rect.right - 1}px`;
  };

  useEffect(() => {
    propFilterRef.current = props.filterButtonRef;
  }, [props.filterButtonRef]);

  useLayoutEffect(() => {
    let adjustPosition;
    if (!portalRef) {
      const portal = document.createElement('div');
      portal.style.position = 'fixed';
      portal.style.zIndex = '1032';
      root.appendChild(portal);
      adjustPosition = throttle(() => handleAdjustPosition(portal), 10);
      adjustPosition();
      window.addEventListener('scroll', adjustPosition, true);
      window.addEventListener('resize', adjustPosition);
      setPortalRef(portal);
    }
    return () => {
      if (portalRef) {
        portalRef.remove();
        setTimeout(() => {
          window.removeEventListener('scroll', adjustPosition, true);
          window.removeEventListener('resize', adjustPosition);
        }, 0);
      }
    };
  }, [portalRef]);

  return !portalRef
    ? null
    : ReactDOM.createPortal(
        <DropdownContainer open={props.isMounted}>
          <DropdownList {...props} />
        </DropdownContainer>,
        portalRef
      );
};

export default PortalDropdown;
