import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useOutsideAlerter } from 'helpers';
import { ReactComponent as MenuDots } from '../../images/feed/menu_dots_heelix.svg';

export const DropdownWrap = styled.div`
  float: right;
  margin-left: auto;
`;

const Wrap = styled.div`
  margin-left: 22px;
  position: relative;
  user-select: none;
  cursor: pointer;
  text-align: left;
  .options-wrap {
    position: absolute;
    display: none;
    width: 182px;
    border: 1px solid #e7e7e7;
    box-shadow: 1px 4px 8px rgba(120, 120, 120, 0.35);
    border-radius: 8px;
    top: 31px;
    left: -93px;
    background: white;
    padding: 6px 0;
    z-index: 2;
    & > div {
      padding: 9px 28px 8px;
      font-size: 14px;
      color: #11a9ff;
      transition: color 0.3s;
      &:hover {
        color: #0065e2;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #f3f3f3;
      }
    }
    &:before {
      width: 16px;
      height: 16px;
      position: absolute;
      content: '';
      left: 50%;
      top: 0;
      transform: translate(-3px, -50%) rotate(-45deg);
      border-top: 1px solid #e7e7e7;
      border-right: 1px solid #e7e7e7;
      background: white;
    }
    @media (max-width: 1200px) {
      left: -149px;
      &:before {
        left: 81%;
      }
    }
  }
  .options-wrap {
    ${props => props.customOpen && 'display: block;'}
  }
`;

const MenuDotsStyled = styled(MenuDots)`
  path {
    fill: ${props =>
      props.type && props.type.includes('Shared')
        ? props.assignee
          ? '#E1C148'
          : '#8AD5FF'
        : '#DADADA'};
  }
`;

const Dropdown = ({ type = null, assignee = false, children, stopPropagation = true }) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  const optionRef = useRef(null);

  const toggleDropdown = () => {
    setOpen(!open);
  };

  useOutsideAlerter([wrapperRef, optionRef], toggleDropdown);

  return (
    <div ref={wrapperRef}>
      <Wrap customOpen={open} onClick={toggleDropdown}>
        <MenuDotsStyled type={type} assignee={assignee} />
        {open && (
          <div
            onClick={e => stopPropagation && e.stopPropagation()}
            ref={optionRef}
            className="options-wrap"
          >
            {children}
          </div>
        )}
      </Wrap>
    </div>
  );
};

export default Dropdown;
