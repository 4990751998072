import React, { useState } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { USER_ACTIONS } from 'redux/actions/userActions';

const Wrapper = styled.div`
  background: #11a9ff;
  width: 100%;
  padding: 15px 70px 15px 20px;
  display: flex;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin: auto;
  position: sticky;
  bottom: 0;
  margin-top: -28px;
  margin-bottom: 15px;
  @media (max-width: 1200px) {
    padding-right: 20px;
  }
`;

const Section = styled.div`
  items-align: center;
  margin: auto;
`;

const Description = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  padding-right: 30px;
  margin: auto;
  margin-top: 10px;
`;

const Button = styled.button`
  display: inline-block;
  background: #ffffff;
  color: #11a9ff;
  padding: 10px 30px;
  font-size: 14px;
  line-height: 16px;
  :hover {
    background: #ffffff;
    color: #11a9ff;
  }
  @media (max-width: 1208px) {
    margin-top: 10px;
  }
`;

const SampleContentBanner = ({ user, queryString, onboardingTeam, hideSamplePosts }) => {
  const [disable, setDisable] = useState(false);
  if (!user.hideSampleTeam && queryString.team == onboardingTeam.id) {
    return (
      <Wrapper>
        <Section>
          <Description className="pull-left">
            We’re displaying some sample data to give you an idea of how Insights works. You can
            remove it here.
          </Description>
          <Button
            className="btn btn-primary pull-left"
            onClick={() => {
              if (!disable) {
                setDisable(true);
                hideSamplePosts();
              }
            }}
          >
            Remove
          </Button>
        </Section>
      </Wrapper>
    );
  }
  return <div />;
};

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  onboardingTeam: state.onboardingDomain.onboardingTeam,
});

const mapDispatch = {
  hideSamplePosts: USER_ACTIONS.hideSamplePosts,
};

export default connect(mapStateToProps, mapDispatch)(SampleContentBanner);
