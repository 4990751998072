/** @jsx jsx */
import Prompt, { promptColors } from 'components/payments/prompts/Prompt';
import { css, jsx } from '@emotion/core';
import React from 'react';
import UpgradeButton from 'components/payments/prompts/UpgradeButton';
import { connect } from 'react-redux';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';

const getContent = (isAccountAdmin, firstName) => {
  if (isAccountAdmin) return '';
  return `Chat with your Account Admin, ${firstName}, to ask about getting access today.`;
};

const FeaturePrompt = ({
  prompt,
  isAccountAdmin,
  closeMeetingModal,
  accountAdmin: { first_name },
}) => {
  return (
    <Prompt
      color={promptColors.yellow}
      title={prompt.data.title}
      content={getContent(isAccountAdmin, first_name)}
      button={hide => (
        <UpgradeButton
          hide={() => {
            hide();
            closeMeetingModal();
          }}
        />
      )}
      css={css`
        z-index: 1060;
      `}
    />
  );
};

const mapDispatch = {
  closeMeetingModal: ONBOARDING_ACTIONS.closeMeetingModal,
};

const mapState = state => ({
  isAccountAdmin: state.usersDomain.user.isAccountAdmin,
  accountAdmin: state.plansDomain.accountAdmin,
});

export default connect(mapState, mapDispatch)(FeaturePrompt);
