import plans from 'components/payments/PaymentChoose/plans';

/**
 * @param {{planSize: string, currentPlanSize: string}} plan
 * @param {function({plan: object, [disabled]: boolean})} cb
 */
const mapPlans = ({ planSize, currentPlanSize }, cb) =>
  plans.map(plan => {
    if (
      (plan.key === 'free' && (planSize === 'custom' || currentPlanSize === 'trial')) ||
      (plan.key === 'pro' && planSize?.toString() === currentPlanSize?.toString())
    ) {
      return cb({ plan, disabled: true });
    }

    return cb({ plan });
  });

export default mapPlans;
