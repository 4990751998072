/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { ReactComponent as Glasses } from 'pages/Grow/GrowPost/assets/glasses.svg';
import PostDetails from 'components/shared/posts/PostDetails';
import {
  Anonymous,
  Button,
  Description,
  OptionWrapper,
  QuestionContent,
  QuestionWrapper,
  ShortAnswer,
} from './Questionnaire';
import AnsweredView from './AnsweredView';
import striptags from 'striptags';
import fetchApi from 'fetchApi';
import qs from 'qs';

const OptionLabel = styled.div`
  display: inline-block;
  border: 2px solid #d5d5d5;
  border-radius: ${props => (props.multiple ? '3px' : '50%')};
  height: 18px;
  width: 18px;
  margin-right: 12px;
  vertical-align: text-top;
  flex: none;
`;
const OptionDescription = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 18px;
  word-break: break-word;
`;

const AnonymousWrapper = styled.div`
  padding: 30px 0;

  svg path {
    fill: #adadad;
  }
`;

const ViewResults = styled.span`
  color: #11a9ff;
  cursor: pointer;
  font-size: 11px;
  line-height: 13px;
  padding-bottom: 30px;
`;

const questionBody = question => {
  if (question.type === 'short') {
    return (
      <OptionWrapper>
        <ShortAnswer placeholder="Type your answer here ..." disabled />
      </OptionWrapper>
    );
  }
  return (
    <div>
      {question.options.map(option => (
        <OptionWrapper key={option.id} flex>
          <OptionLabel multiple={question.type === 'multiple'} />
          <OptionDescription>{option.content}</OptionDescription>
        </OptionWrapper>
      ))}
    </div>
  );
};

const QuestionnaireDetails = ({
  post,
  showModal,
  user,
  tileRealignHandler,
  showResults,
  setShowResults,
}) => {
  const answered = post.questionnaire_already_answered || showResults;

  const [singlePost, setSinglePost] = useState();

  const setTeam = async team => {
    const response = await fetchApi(
      `/questionnaires/${post.id}?${qs.stringify({ teamId: team?.id })}`
    );

    setSinglePost(response.post);
  };

  post = singlePost ?? post;
  return (
    <div>
      <Description
        tile
        dangerouslySetInnerHTML={{
          __html: striptags(post.content, ['a', 'br']),
        }}
      />
      {answered ? (
        <AnsweredView post={post} tileRealignHandler={tileRealignHandler} setTeam={setTeam} />
      ) : (
        <>
          {post.questions.slice(0, 1).map(question => (
            <QuestionWrapper key={question.id}>
              <QuestionContent>{question.content}</QuestionContent>
              {questionBody(question)}
            </QuestionWrapper>
          ))}
          <Button onClick={showModal}>
            {post.questions.length > 1 ? `View all ${post.questions.length} questions` : 'Vote'}
          </Button>
        </>
      )}
      <AnonymousWrapper>
        <Glasses />
        <Anonymous>Your response is anonymous</Anonymous>
        {post.author_id === user.id && !answered && (
          <ViewResults
            onClick={e => {
              e.stopPropagation();
              setShowResults(true);
              tileRealignHandler();
            }}
          >
            View results
          </ViewResults>
        )}
      </AnonymousWrapper>
      <PostDetails post={post} showAuthorInfo={!answered} />
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.usersDomain.user,
});

export default connect(mapStateToProps, null)(QuestionnaireDetails);
