/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { Player, BigPlayButton } from 'video-react';
import { AWS_S3_URL } from 'constants.js';
import styled from '@emotion/styled';
import PostponeButton from './partials/PostponeButton';

const MediaWrapper = styled.div`
  font-size: 16px;
  padding: 13px 5px 35px 5px;
  line-height: 150%;
`;

const Intro = ({ nextStep, ...props }) => {
  return (
    <>
      <MediaWrapper>
        <Player
          playsInline
          src={`${AWS_S3_URL}public/heelix-intro.mov`}
          onEnded={() =>
            setTimeout(function () {
              nextStep();
            }, 1000)
          }
          css={css`
            .video-react-big-play-button {
              border-radius: 50% !important;
              width: 80px;
              height: 80px;
              top: 42% !important;
              left: 51% !important;
              :before {
                top: 17px !important;
              }
            }
          `}
        >
          <BigPlayButton position="center" />
        </Player>
      </MediaWrapper>
      <PostponeButton text="Skip" onClick={() => nextStep()} />
    </>
  );
};

export default Intro;
