import styled from '@emotion/styled';
import PlanModal from 'rbac/components/PlanModal/PlanModal';

export const PromptModal = styled(PlanModal)`
  position: fixed;
  z-index: 1040;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
  @media (min-width: 580px) {
    max-width: 500px;
    bottom: 64px;
    left: 42px;
    border-radius: 5px;
  }
`;
