import React from 'react';
import { css, jsx } from '@emotion/core';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../../helpers';
import { APP_URL } from '../../../constants';
import { ButtonWrapper, ModalContent, ModalWrapper } from '../../onboarding/ExistedMeetingModal';
import LoadingOverlay from '../../signup/LoadingOverlay';
import OnboardingButton from '../../onboarding/partials/OnboardingButton';
import PostponeButton from '../../onboarding/partials/PostponeButton';
import { CloseButton } from '../../shared/Modal/UserModal';
import { Modal } from 'react-bootstrap';

class TeamTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: '',
      tagToProcess: null,
      isTagEdited: false,
      formError: null,
      teamTags: [],
      showModal: false,
      loading: false,
    };
  }

  componentDidMount() {
    const headers = HEADERS();
    fetch(`${APP_URL}/account/team-tags`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({ teamTags: jsonResponse.tags });
        });
      })
      .catch(() => {
        this.setState({ teamTags: [] });
      });
  }

  tagChangedHandler = e => {
    this.setState({ tag: e.target.value });
  };

  editTagHandler = (e, id) => {
    const tagIndex = this.state.teamTags.findIndex(teamTag => teamTag.id === id);
    if (tagIndex < 0) {
      return false;
    }
    this.setState({
      isTagEdited: true,
      tagToProcess: this.state.teamTags[tagIndex],
      formError: null,
    });
  };

  deleteTagHandler = (e, id) => {
    const tagIndex = this.state.teamTags.findIndex(teamTag => teamTag.id === id);
    if (tagIndex < 0) {
      return false;
    }

    this.setState({
      tag: '',
      formError: null,
      isTagEdited: false,
      showModal: true,
      tagToProcess: this.state.teamTags[tagIndex],
    });
  };

  deleteTag = () => {
    this.setState({ loading: true });
    if (!this.state.tagToProcess) {
      this.setState({ loading: false, showModal: false });
      return false;
    }
    const headers = HEADERS();
    fetch(`${APP_URL}/account/team-tag/${this.state.tagToProcess.id}`, {
      method: 'delete',
      headers,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (response.ok) {
          response.json().then(() => {
            const teamTags = [...this.state.teamTags];
            const tagIndex = this.state.teamTags.findIndex(
              teamTag => teamTag.id === this.state.tagToProcess.id
            );
            if (tagIndex < 0) {
              return false;
            }
            teamTags.splice(tagIndex, 1);
            this.setState({
              teamTags: teamTags,
              loading: false,
              showModal: false,
            });
          });
        } else {
          this.setState({
            loading: false,
            showModal: false,
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          showModal: false,
        });
      });
    return false;
  };

  addTeamTag = e => {
    e.preventDefault();
    if (this.state.tag === '') {
      this.setState({ formError: 'Please input a team tag name.' });
      return false;
    }
    this.setState({ formError: null });

    const headers = HEADERS();

    const data = new FormData();
    data.append('name', this.state.tag);

    fetch(`${APP_URL}/account/team-tag`, { method: 'post', headers, body: data })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (response.ok) {
          response.json().then(jsonResponse => {
            const teamTags = [...this.state.teamTags];
            teamTags.push(jsonResponse.tag);
            this.setState({
              teamTags: teamTags,
              tag: '',
            });
          });
        } else {
          response.json().then(jsonResponse => {
            if ('error' in jsonResponse) {
              this.setState({ formError: jsonResponse.error });
            } else {
              this.setState({ formError: 'Something went wrong. Please try again later.' });
            }
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
    return false;
  };

  updateTeamTag = e => {
    e.preventDefault();
    if (this.state.tagToProcess.name === '') {
      this.setState({ formError: 'Please input a team tag name.' });
      return false;
    }
    this.setState({ formError: null });

    const headers = HEADERS();

    const data = new FormData();
    data.append('name', this.state.tagToProcess.name);
    data.append('_method', 'PUT');

    fetch(`${APP_URL}/account/team-tag/${this.state.tagToProcess.id}`, {
      method: 'post',
      headers,
      body: data,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (response.ok) {
          response.json().then(jsonResponse => {
            const teamTags = [...this.state.teamTags];
            const tagIndex = this.state.teamTags.findIndex(
              teamTag => teamTag.id === this.state.tagToProcess.id
            );
            if (tagIndex < 0) {
              return false;
            }
            teamTags[tagIndex] = jsonResponse.tag;
            this.setState({
              teamTags: teamTags,
              tag: '',
              formError: null,
              tagToProcess: null,
              isTagEdited: false,
            });
          });
        } else {
          response.json().then(jsonResponse => {
            if ('error' in jsonResponse) {
              this.setState({ formError: jsonResponse.error });
            } else {
              this.setState({ formError: 'Something went wrong. Please try again later.' });
            }
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
    return false;
  };

  closeModal = () => {
    this.setState({ showModal: false, loading: false });
  };

  cancelClickedHandler = e => {
    e.preventDefault();
    this.setState({
      tag: '',
      formError: null,
      tagToProcess: null,
      isTagEdited: false,
    });
    return false;
  };

  teamTagValueChangedHandler = e => {
    const editedTeamtag = { ...this.state.tagToProcess };
    editedTeamtag.name = e.target.value;
    this.setState({ tagToProcess: editedTeamtag });
  };

  render() {
    const { teamTags, tag, isTagEdited, formError, loading, showModal, tagToProcess } = this.state;
    let teamTagsList = (
      <p className="section-description">There are no team tags yet. Try adding some</p>
    );

    if (teamTags.length > 0) {
      teamTagsList = teamTags.map(teamTag => (
        <div className="current-value" key={teamTag.id}>
          {isTagEdited && tagToProcess.id === teamTag.id ? (
            <>
              <div className="current-value-name-wrapper">
                <input
                  type="text"
                  className="form-control edit-value-form"
                  id="edit-company-value"
                  value={tagToProcess.name}
                  onChange={this.teamTagValueChangedHandler}
                />
                {isTagEdited && formError && <p className="text-danger">{formError}</p>}
              </div>
              <p className="update-value-save" onClick={e => this.updateTeamTag(e, teamTag.id)}>
                Save
              </p>
              <p className="update-value-cancel" onClick={this.cancelClickedHandler}>
                Cancel
              </p>
            </>
          ) : (
            <>
              <div className="current-value-name-wrapper">
                <p className="current-value-name">{teamTag.name}</p>
                {Boolean(teamTag.teamsCount) && (
                  <p className="current-value-team-count">
                    {teamTag.teamsCount}&nbsp;{teamTag.teamsCount === 1 ? 'team' : 'teams'}
                  </p>
                )}
              </div>
              <div className="current-value-actions">
                <p
                  className="current-value-edit"
                  title={teamTag.name}
                  onClick={e => this.editTagHandler(e, teamTag.id)}
                >
                  Edit
                </p>
                <p
                  className="current-value-delete"
                  onClick={e => this.deleteTagHandler(e, teamTag.id)}
                >
                  Delete
                </p>
              </div>
            </>
          )}
        </div>
      ));
    }
    return (
      <>
        <h2 className="header-blue">Team tags</h2>
        <p className="section-description">
          Creating tags allows you to segment your data In Insights; allowing you to view teams by
          geographical location, function type and more. Once added, go to the Edit Team view to
          assign.
        </p>
        <h3 className="settings-header">Add new tag</h3>
        <form className="form-inline" onSubmit={this.addTeamTag}>
          <label className="sr-only" htmlFor="team-tag">
            Team tag
          </label>
          <input
            type="text"
            className="form-control input-value-form"
            id="team-tag"
            value={tag}
            onChange={this.tagChangedHandler}
          />
          <button type="submit" className="btn btn-primary add-value-form" id="add-tag">
            Add
          </button>
        </form>
        {!isTagEdited && formError && <p className="text-danger">{formError}</p>}
        <div className="current-values-list">
          <h3 className="settings-header">Current tags</h3>
          {teamTagsList}
        </div>
        <Modal
          show={showModal}
          dialogClassName="existed-meeting-modal"
          backdrop
          keyboard
          onHide={this.closeModal}
        >
          <ModalWrapper>
            <LoadingOverlay toggled={loading} message="Processing..." height="100%" />
            <ModalContent
              css={css`
                overflow: hidden;
              `}
            >
              Are you sure you want to remove this tag?
              <ButtonWrapper>
                <OnboardingButton
                  onClick={this.deleteTag}
                  label="Yes"
                  submit
                  top={10}
                  width={65}
                  height={36}
                />
                <PostponeButton
                  text="Cancel"
                  onClick={e => {
                    e.preventDefault();
                    this.closeModal();
                  }}
                />
              </ButtonWrapper>
            </ModalContent>
            <CloseButton className="close-button" onClick={this.closeModal} />
          </ModalWrapper>
        </Modal>
      </>
    );
  }
}

export default TeamTags;
