import React from 'react';
import styled from '@emotion/styled';

import Buttons from 'components/vibes/createForms/partials/Buttons';
import { PERFORM_BUTTONS } from './buttons';

const NoteButtonsWrapper = styled.div`
  margin-top: 21px;
  margin-bottom: -21px;
`;

const NoteButtons = ({
  onClick,
  toggled,
  converting,
  showFileDropzone,
  showMediaDropzone,
  shownFileDropzone,
  shownMediaDropzone,
}) => {
  return (
    <NoteButtonsWrapper>
      <Buttons
        onClick={onClick}
        toggled={toggled}
        buttons={PERFORM_BUTTONS(converting)}
        showMediaDropzone={showMediaDropzone}
        showFileDropzone={showFileDropzone}
        shownMediaDropzone={shownMediaDropzone}
        shownFileDropzone={shownFileDropzone}
      />
    </NoteButtonsWrapper>
  );
};

export default NoteButtons;
