export const initialPostState = {
  content: '',
  isHashtag: false,
  isMention: false,
  mediaUrl: [],
  mediaSize: [],
  showMediaDropzone: false,
  showFileDropzone: false,
  disabledButton: false,
  files: [],
  selection: { start: 0, end: 0 },
  markup: '[-/-markupStart-/-]__type__:__id__[--__display__--]',
  charsLeftContent: 1000,
  charsLeftTitle: 250,
  errorContent: '',
  errorTile: '',
  postErrors: [],
  generalError: null,
};

export const initialQuestionnaireState = {
  id: undefined,
  description: '',
  name: '',
  open: true,
  disabled: true,
  errors: [],
  converting: false,
  questions: [
    JSON.parse(
      JSON.stringify({
        type: 'checkbox',
        content: '',
        required: false,
        open: true,
        options: [
          {
            id: null,
            content: '',
          },
          {
            id: null,
            content: '',
          },
        ],
      })
    ),
  ],
};
