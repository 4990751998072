import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import AppAlertsDropdown from '../../vibes/AppAlertsDropdown';
import decrease from '../../../images/appAlerts/decrease.svg';
import increase from '../../../images/appAlerts/increase.svg';
import stars from '../../../images/appAlerts/stars.svg';
import trophy from '../../../images/appAlerts/trophy.svg';
import note from '../../../images/appAlerts/note.svg';
import vibeNow from '../../../images/appAlerts/vibe-now.svg';
import meetingIcon from '../../../images/appAlerts/meeting-icon.svg';
import MeetingAlertTile from './MeetingAlertTile';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import qs from 'qs';

class PostAlertTile extends Component {
  render() {
    const isMeeting = this.props.post.subtype === 'MeetingScheduleAlert';
    if (isMeeting) {
      return <MeetingAlertTile post={this.props.post} />;
    }

    const content = (
      <>
        <div className="app-alert-content">{this.props.post.content}</div>
      </>
    );

    let image = '';
    let redirectUrl = '';
    let buttonText = '';

    switch (this.props.post.subtype) {
      case 'StatsUpAlert':
        image = increase;
        redirectUrl = `/vibes/dashboard?period=-7`;
        if (this.props.post.direct_team) {
          redirectUrl = `/vibes/dashboard?team=${this.props.post.direct_team.id}&period=-7`;
        }
        buttonText = 'See more';
        break;
      case 'StatsDownAlert':
        image = decrease;
        redirectUrl = `/vibes/dashboard?period=-7`;
        if (this.props.post.direct_team) {
          redirectUrl = `/vibes/dashboard?team=${this.props.post.direct_team.id}&period=-7`;
        }
        buttonText = 'See more';
        break;
      case 'WinAlert':
        image = trophy;
        redirectUrl = `/${growProfileUrl}?${qs.stringify({ tab: 'achievements' })}`;
        buttonText = 'Record a win';
        break;
      case 'ShoutoutAlert':
        image = stars;
        redirectUrl = '/shoutouts';
        buttonText = 'Give a shoutout';
        break;
      case 'NoteAlert':
        image = note;
        redirectUrl = `/${growProfileUrl}?${qs.stringify({ tab: 'my-notes' })}`;
        buttonText = 'Record a note';
        break;
      case 'VibeAlert':
        image = vibeNow;
        redirectUrl = '/vibe/new';
        buttonText = 'Vibe now';
        break;
      case 'MeetingAlert':
        image = meetingIcon;
        redirectUrl = '/grow_board';
        buttonText = 'Get ready';
        break;
      default:
        break;
    }

    return (
      <div className="col-lg-6 col-xs-12 post-tile-wrap initial-hide">
        <div className="post-tile app-alert-tile">
          <div className="post-tile-header">
            <AppAlertsDropdown post={this.props.post} />
          </div>
          <div className="post-tile-body app-alert-body">
            <img src={image} />
            {content}
            <Link to={redirectUrl} className="app-alert-btn">
              {buttonText}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default PostAlertTile;
