/** @jsx jsx */
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import React from 'react';
import { css, jsx } from '@emotion/core';
import getScoreColor from 'pages/Insights/OrganisationInsights/getScoreColor';
import teamShape from 'pages/Insights/OrganisationInsights/teamShape';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Item = styled.div`
  height: 42px;
  border-radius: 2px;
  flex-shrink: 0;
  margin: 1px;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  padding: 5px 9px;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 90px;
  @media (min-width: 400px) {
    min-width: 112px;
  }
  @media (min-width: 1000px) {
    min-width: 136px;
  }
`;

const ItemName = styled.div`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 29px;
`;

const ItemScore = styled.div`
  margin-left: auto;
`;

const Heatmap = ({ teams, isParticipationView }) => {
  return (
    <Container>
      {[...teams]
        /** 101 assigned for null scores to put them at the end of the list */
        .sort((first, second) => (first.score ?? 101) - (second.score ?? 101))
        .map(team => (
          <Item
            css={css`
              ${getScoreColor(team.score, isParticipationView)};
            `}
          >
            <ItemName>{team.name}</ItemName>
            <ItemScore>
              {team.score}
              {team.score !== null && isParticipationView && '%'}
            </ItemScore>
          </Item>
        ))}
      {[...Array(5)].map(() => (
        <Item
          css={css`
            height: 0;
          `}
        />
      ))}
    </Container>
  );
};

Heatmap.propTypes = {
  teams: PropTypes.arrayOf(teamShape).isRequired,
  isParticipationView: PropTypes.bool.isRequired,
};

export default Heatmap;
