import React, { Component } from 'react';

import { connect } from 'react-redux';
import PostTileComment from './PostTileComment';
import { COMMENT_ACTIONS } from '../../redux/actions/commentActions';

const mapStateToProps = state => ({
  fetchingComments: state.postsDomain.fetchingComments || state.growDomain.fetchingComments,
});

const mapDispatchToProps = dispatch => ({
  fetchComments: (postId, loadedCount, type) =>
    dispatch(COMMENT_ACTIONS.fetchComments(postId, loadedCount, false, type)),
});

class PostTileComments extends Component {
  render() {
    return (
      <>
        <ul className="comment-section">
          {this.props.post.comments.map(comment => (
            <PostTileComment
              comment={comment}
              key={comment.id}
              user={this.props.user}
              type={this.props.type}
              post={this.props.post}
            />
          ))}
        </ul>
        {this.props.post.comments.length < this.props.post.total_comments_count ? (
          <div
            className="post-tile-comments-unfold"
            onClick={() => {
              if (!this.props.fetchingComments) {
                this.props.fetchComments(
                  this.props.post.id,
                  this.props.post.comments.length,
                  this.props.type
                );
              }
            }}
          >
            View all {this.props.post.total_comments_count} comments
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

PostTileComments.defaultProps = {
  type: 'post',
};

export default connect(mapStateToProps, mapDispatchToProps)(PostTileComments);
