import React, { Component } from 'react';
import delayUnmounting from '../../HOC/delayUnmounting';

class StartGuide extends Component {
  state = {
    mounted: false,
  };

  componentDidMount() {
    if (this.props.calcHeight) {
      this.height = window.getComputedStyle(this.self).getPropertyValue('height');
      this.self.style.height = 0;
      setTimeout(() => {
        this.self.style.height = this.height;
        this.setState({
          mounted: true,
        });
      }, 0);
    } else {
      setTimeout(() => {
        this.setState({
          mounted: true,
        });
      }, 0);
    }
  }

  componentWillReceiveProps(props) {
    if (this.props.calcHeight) {
      if (!props.isMounted) this.self.style.height = 0;
      else this.self.style.height = this.height;
      if (this.state.mounted !== props.isMounted) this.setState({ mounted: !this.state.mounted });
    } else if (this.state.mounted !== props.isMounted)
      this.setState({ mounted: !this.state.mounted });
  }

  render() {
    return (
      <div
        className={`start-guide ${this.props.className} ${this.state.mounted ? 'mounted' : ''}`}
        ref={el => (this.self = el)}
      >
        {this.props.children}
      </div>
    );
  }
}

export default delayUnmounting(StartGuide);
