import { PLAN_ACTION_TYPES } from '../actions/planActions';

const planReducer = (state, action) => {
  switch (action.type) {
    case PLAN_ACTION_TYPES.SHOW_UPGRADE_MODAL:
      return {
        ...state,
        plansDomain: {
          ...state.plansDomain,
          showUpgradePlanModal: true,
          upgradeModalNormalContent: action.payload?.normalContent,
          upgradeModalAdminContent: action.payload?.adminContent,
        },
      };
    case PLAN_ACTION_TYPES.HIDE_UPGRADE_MODAL:
      return {
        ...state,
        plansDomain: {
          ...state.plansDomain,
          showUpgradePlanModal: false,
          upgradeModalNormalContent: undefined,
          upgradeModalAdminContent: undefined,
        },
      };
    case PLAN_ACTION_TYPES.RECEIVE_PLAN_LIMITATIONS:
      return {
        ...state,
        plansDomain: {
          ...state.plansDomain,
          planLimitations: action.payload,
        },
      };
    case PLAN_ACTION_TYPES.HIDE_PROMPT:
      return {
        ...state,
        plansDomain: {
          ...state.plansDomain,
          prompt: null,
        },
      };
    case PLAN_ACTION_TYPES.SHOW_PROMPT:
      return {
        ...state,
        plansDomain: {
          ...state.plansDomain,
          prompt: action.payload,
        },
      };
    case PLAN_ACTION_TYPES.RECEIVE_DATA_LIMIT:
      return {
        ...state,
        plansDomain: {
          ...state.plansDomain,
          reachedDataLimit: action.payload,
        },
      };
    default:
      return state;
  }
};
export default planReducer;
