/** @jsx jsx */
import React, { Component } from 'react';
import { AVATAR_GENERIC, AWS_S3_URL } from 'constants.js';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'qs';

const Image = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 14px;
`;

const Date = styled.span`
  color: #979797;
  font-size: 13px;
  line-height: 18px;
`;

const Content = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #323232;
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  margin-bottom: 12px;
`;

const AnonymousComment = styled.div`
  margin-bottom: 25px;
`;

const EmptyMessage = styled.div`
  text-transform: uppercase;
  color: #706e84;
  font-size: 12px;
  padding-top: 13px;
`;

class AnonymousComments extends Component {
  options = ['30', '60', '90', 'custom'];

  redirectToTeamInsight(comment) {
    const { history, queryString } = this.props;
    const chosenOption = queryString.period
      ? this.options.find(option => option === queryString.period)
      : this.options[0];
    if (comment && comment.team_id && comment.position) {
      const params = qs.parse({
        team: comment.team_id,
        factor: comment.position,
        period: chosenOption,
      });
      history.push(`/vibes/mood_vs_factor?${qs.stringify(params)}`);
    }
  }

  render() {
    const { dashboard, anonymousComments } = this.props;
    return (
      <div
        className="anonymous-comments-section"
        id="anonymous"
        css={css`
          ${dashboard && 'padding: 0 15px; margin-top: 38px;'}
        `}
      >
        {anonymousComments.map((comment, index) => (
          <AnonymousComment className="anonymous-comment" key={index}>
            <ImageWrapper>
              <Image src={AWS_S3_URL + AVATAR_GENERIC} alt="generic avatar" />
              <Date className="date">{comment.created_at}</Date>
            </ImageWrapper>
            <Content
              onClick={() => this.redirectToTeamInsight(comment)}
              className="comment-content"
            >
              {comment.content}
            </Content>
          </AnonymousComment>
        ))}
        {anonymousComments.length === 0 && (
          <EmptyMessage>No data recorded for this time period</EmptyMessage>
        )}
      </div>
    );
  }
}

AnonymousComments.propTypes = {
  dashboard: PropTypes.bool,
  anonymousComments: PropTypes.object.isRequired,
  queryString: PropTypes.object.isRequired,
};

AnonymousComments.defaultProps = {
  dashboard: false,
};

export default withRouter(AnonymousComments);
