import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import qs from 'qs';
import SwitchButton from '../../shared/buttons/SwitchButton';
import { FORMAT_FILTERS } from '../../../helpers';

const Wrapper = styled.div`
  margin-bottom: 30px;
  overflow-y: auto;
`;

const Filter = styled.div`
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FilterName = styled.p`
  margin-left: 6px;
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin-bottom: 0;
  font-size: 13px;
  color: ${props => (props.enabled ? '#6A6A6A' : '#A6A6A6')};
`;

const FilterSwitch = styled.div`
  margin-top: 5px;
`;

const SpacesFilter = ({ spaces, filters, history }) => (
  <Wrapper>
    {spaces.map(({ name, id }) => {
      const excluded = filters.excludedSpaces.findIndex(spaceId => parseInt(spaceId) === id) >= 0;
      return (
        <Filter key={id}>
          <FilterName enabled={!excluded}>{name}</FilterName>
          <FilterSwitch>
            <SwitchButton
              turnedOn={!excluded}
              onClick={e =>
                history.push(`/?${qs.stringify(FORMAT_FILTERS(e.currentTarget, filters))}`)
              }
              filter-type="filter-spaces"
              space-id={id}
            />
          </FilterSwitch>
        </Filter>
      );
    })}
  </Wrapper>
);

SpacesFilter.propTypes = {
  spaces: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  filters: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};

export default withRouter(SpacesFilter);
