import { USER_ACTIONS, USER_ACTION_TYPES } from '../actions/userActions';
import { POST_ACTIONS } from '../actions/postActions';
import { GROW_ACTIONS } from '../actions/growActions';
import { ONBOARDING_ACTIONS } from '../actions/onboardingActions';
import store from '../store';
import { APP_URL, AVATAR_GENERIC } from '../../constants';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';
import axios from '../../axiosAuthenticated';

const userReducer = (state, action) => {
  const headers = HEADERS();

  switch (action.type) {
    case USER_ACTION_TYPES.FETCH_PROFILE:
      // fetch a profile
      action.payload.receiveErrors();
      return state;
    case USER_ACTION_TYPES.REMOVE_PHOTO:
      // remove a photo
      const removePhotoUrl = action.payload.id
        ? `/user/${action.payload.id}/update`
        : '/update_profile';
      axios
        .post(APP_URL + removePhotoUrl, action.payload.data)
        .then(response => {
          action.payload.receiveErrors(null, response);
          store.dispatch(USER_ACTIONS.removeImage(response.data.user));
          store.dispatch(POST_ACTIONS.resetPosts());
          store.dispatch(POST_ACTIONS.resetFilters());
        })
        .catch(error => {
          CHECK_UNAUTHORIZED(error.response);
          action.payload.receiveErrors(error.response.message, null);
        });

      return state;
    case USER_ACTION_TYPES.UPDATE_PHOTO:
      // update a photo
      const updatePhotoUrl = action.payload.id
        ? `/user/${action.payload.id}/update`
        : '/update_profile';
      axios
        .post(APP_URL + updatePhotoUrl, action.payload.data)
        .then(response => {
          action.payload.receiveErrors(null, response);
          store.dispatch(USER_ACTIONS.refreshProfileImage(response.data.userImage));
          if (action.payload.refreshPosts) {
            store.dispatch(POST_ACTIONS.resetPosts());
            store.dispatch(POST_ACTIONS.resetFilters());
          }
        })
        .catch(error => {
          CHECK_UNAUTHORIZED(error.response);
          action.payload.receiveErrors(error.response.message, null);
          action.payload.receiveErrors(error.response.message, null);
        });

      return state;
    case USER_ACTION_TYPES.UPDATE_PROFILE:
      const updateProfileUrl = action.payload.id
        ? `/user/${action.payload.id}/update`
        : '/update_profile';
      axios
        .post(APP_URL + updateProfileUrl, action.payload.data)
        .then(response => {
          action.payload.receiveErrors(null, response);
          if (response.data.flashName === 'error') return;
          let updatedUser = { ...state.usersDomain.user };
          if (response.data.auth_user) {
            if (!response.data.state_user.id) {
              store.dispatch(USER_ACTIONS.profileUpdated(response.data.user));
            } else {
              const stateUser = response.data.state_user;
              updatedUser = stateUser;
              store.dispatch(USER_ACTIONS.profileUpdated(updatedUser));
            }
          }
          store.dispatch(POST_ACTIONS.resetPosts());
          store.dispatch(POST_ACTIONS.resetFilters());
        })
        .catch(error => {
          CHECK_UNAUTHORIZED(error.response);
          action.payload.receiveErrors(error.response.message, null);
        });
      return state;
    case USER_ACTION_TYPES.REMOVE_IMAGE:
      const user = { ...state.usersDomain.user };
      user.imageUrl = action.payload.user.image_url;
      user.imageUid = AVATAR_GENERIC;
      user.imageThumbUid = action.payload.user.image_url;
      user.imageThumbMiniUid = action.payload.user.image_url;
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user,
        },
      };
    case USER_ACTION_TYPES.PROFILE_UPDATED:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user: action.payload.user,
        },
      };
    case USER_ACTION_TYPES.CHANGE_USERS_CAPACITY:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user: {
            ...state.usersDomain.user,
            planCapacityReached: action.payload,
          },
        },
      };
    case USER_ACTION_TYPES.ADD_LED_TEAM_ID:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user: {
            ...state.usersDomain.user,
            leadTeamsIds: [...state.usersDomain.user.leadTeamsIds, action.payload],
          },
        },
      };
    case USER_ACTION_TYPES.UNFOLLOW_USER:
      fetch(`${APP_URL}/unfollow-user/${action.payload}`, { method: 'put', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            store.dispatch(USER_ACTIONS.receiveUnfollowUserStatus(action.payload, json));
          });
        });
      return state;
    case USER_ACTION_TYPES.RECEIVE_UNFOLLOW_USER_STATUS:
      const userIndex = state.usersDomain.usersForShoutoutModal
        .map(user => user.id)
        .indexOf(action.payload.userId);
      const newState = JSON.parse(JSON.stringify(state));
      if (userIndex > -1) {
        newState.usersDomain.usersForShoutoutModal[userIndex].unfollowed =
          action.payload.json.unfollowed;
        newState.usersDomain.unfollowedUsersIds = action.payload.json.unfollowedUsersIds;
      }
      return newState;
    case USER_ACTION_TYPES.UNFOLLOW_WEEKLY_DIGEST:
      fetch(`${APP_URL}/unfollow-weekly-digest`, { method: 'put', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            store.dispatch(USER_ACTIONS.receiveWeeklyDigestStatus(json.isUnfollowed));
          });
        });
      return state;
    case USER_ACTION_TYPES.RECEIVE_WEEKLY_DIGEST_STATUS:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          weeklyDigest: action.payload.isUnfollowed,
        },
      };
    case USER_ACTION_TYPES.UPDATE_VIBE_STATUS:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user: {
            ...state.usersDomain.user,
            alreadyVibed: action.payload,
          },
        },
      };
    case USER_ACTION_TYPES.REFRESH_PROFILE_IMAGE:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user: {
            ...state.usersDomain.user,
            imageUrl: action.payload,
          },
        },
      };
    case USER_ACTION_TYPES.REFRESH_RECENT_TEAMS:
      if (action.payload) {
        return {
          ...state,
          usersDomain: {
            ...state.usersDomain,
            user: {
              ...state.usersDomain.user,
              recentSpaces: action.payload,
            },
          },
        };
      }
      return state;
    case USER_ACTION_TYPES.ONE_TIME_VIBE_MODAL_SHOWN:
      setTimeout(() => {
        fetch(`${APP_URL}/user/update-vibe-warning-count`, { method: 'put', headers })
          .then(response => CHECK_UNAUTHORIZED(response))
          .then(response => {
            response.json().then(json => {
              store.dispatch(
                USER_ACTIONS.updateOneTimeVibeModalStatus(json.shouldSeeOneTimeVibeModal)
              );
            });
          });
      });
      return state;
    case USER_ACTION_TYPES.UPDATE_ONE_TIME_VIBE_MODAL_STATUS:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user: {
            ...state.usersDomain.user,
            shouldSeeOneTimeVibeModal: action.payload,
          },
        },
      };
    case USER_ACTION_TYPES.UPDATE_USER_ALREADY_VIBED:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user: {
            ...state.usersDomain.user,
            alreadyVibed: true,
            shouldSeeInsightsModal: false,
          },
        },
      };
    case USER_ACTION_TYPES.UPDATE_USER_SHOULD_SEE_INSIGHTS_MODAL:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user: {
            ...state.usersDomain.user,
            shouldSeeInsightsModal: false,
          },
        },
      };
    case USER_ACTION_TYPES.CLOSE_FREE_PLAN_WARNING_MESSAGE:
      setTimeout(() => {
        fetch(`${APP_URL}/user/close_free_plan_warning_message`, { method: 'put', headers })
          .then(response => CHECK_UNAUTHORIZED(response))
          .then(response => {
            response.json().then(json => {
              store.dispatch(USER_ACTIONS.updateFreePlanWarningMessage());
            });
          });
      });
      return state;
    case USER_ACTION_TYPES.UPDATE_FREE_PLAN_WARNING_MESSAGE:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user: {
            ...state.usersDomain.user,
            freePlanWarningMessage: null,
          },
        },
      };
    case USER_ACTION_TYPES.HIDE_SAMPLE_POSTS:
      fetch(`${APP_URL}/user/hide_sample`, { method: 'put', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            store.dispatch(USER_ACTIONS.updateHideSamplePosts());
            store.dispatch(GROW_ACTIONS.resetPosts());
            store.dispatch(POST_ACTIONS.resetPosts());
            store.dispatch(GROW_ACTIONS.fetchPosts());
            store.dispatch(GROW_ACTIONS.fetchPostsCount());
            store.dispatch(POST_ACTIONS.fetchPosts());
            store.dispatch(ONBOARDING_ACTIONS.setRemoveSampleTeam(json));
          });
        });
      return state;
    case USER_ACTION_TYPES.UPDATE_HIDE_SAMPLE_POSTS:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user: {
            ...state.usersDomain.user,
            hideSamplePosts: !state.usersDomain.user.hideSamplePosts,
          },
        },
      };
    case USER_ACTION_TYPES.UPDATE_VISITED:
      const visited = {
        ...state.usersDomain.user.visited,
        [action.payload]: true,
      };

      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user: {
            ...state.usersDomain.user,
            visited,
          },
        },
      };
    case USER_ACTION_TYPES.TOGGLE_FEATURE:
      fetch(`${APP_URL}/toggle_feature/${action.payload}`, { method: 'put', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(json => {
            store.dispatch(USER_ACTIONS.receiveFeatureStatus(json.type, json.enabled));
          });
        });
      return state;
    case USER_ACTION_TYPES.RECEIVE_FEATURE_STATUS:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          feedEnabled:
            action.payload.type === 'feed' ? action.payload.enabled : state.usersDomain.feedEnabled,
          growEnabled:
            action.payload.type === 'grow' ? action.payload.enabled : state.usersDomain.growEnabled,
        },
      };
    case USER_ACTION_TYPES.WEEKLY_INSIGHTS_SHOWN:
      setTimeout(() => {
        fetch(`${APP_URL}/user/update-weekly-insights-shown`, { method: 'put', headers })
          .then(response => CHECK_UNAUTHORIZED(response))
          .then(response => {
            response.json().then(json => {
              store.dispatch(
                USER_ACTIONS.updateWeeklyInsightsShownStatus(json.weekly_insights_seen)
              );
            });
          });
      });
      return state;
    case USER_ACTION_TYPES.UPDATE_WEEKLY_INSIGHTS_SHOWN_STATUS:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          user: {
            ...state.usersDomain.user,
            weeklyInsightsSeen: action.payload,
          },
        },
      };
    case USER_ACTION_TYPES.UPDATE_ACCOUNT_DELETION_STATUS:
      return {
        ...state,
        usersDomain: {
          ...state.usersDomain,
          requestDeletionStatus: action.payload,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
