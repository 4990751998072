import React from 'react';
import DefaultFactorsScreen from './DefaultFactorsScreen';
import Factor from './Factor';

export default class SelectFactors extends React.Component {
  // INFO: all screens
  // - default_factors
  // - other_factors
  constructor(props) {
    super(props);

    this.maxFactors = 1;

    this.state = {
      screen: 'default_factors',
      allowToSelect: this._totalSelectedFactors() < this.maxFactors,
      showAllFactors: false,
      emptyWhyFactors: false,
      other: this.props.other,
    };
  }

  render() {
    document.getElementsByClassName('page-wrapper')[0].firstChild.classList.remove('full-screen');
    return (
      <DefaultFactorsScreen
        title={this.props.title}
        factors={this.props.factors}
        getFactors={this.getFactors.bind(this)}
        raw_text={this.props.raw_text}
        nextStep={this.nextStep.bind(this)}
        previousStep={this.previousStep.bind(this)}
        enable_vibe_comments={this.props.enable_vibe_comments}
        disabledSubmitButton={this.disabledSelectButton()}
        emptyWhyFactors={this.state.emptyWhyFactors}
        showAnonDescModal={this.props.showAnonDescModal}
        setValueState={this.setOtherState.bind(this)}
        value={this.state.other}
        submit={false}
        step={2}
      />
    );
  }

  setOtherState(e) {
    this.setState({ other: e.target.value });
  }

  addFactor(factor) {
    this.props.factors.forEach(function (selectedFactor) {
      if (selectedFactor.id === factor.id) {
        factor.selected = true;
      } else if (selectedFactor.selected == null) {
        selectedFactor.selected = false;
      }
    });
    this.props.factors.find(factor => (factor.alwaysVisible = true));

    // INFO: use == to avoid re-render every time
    if (this._totalSelectedFactors() === this.maxFactors) {
      this.setState({ allowToSelect: false });
    }

    // check if has vibe why factors
    if (factor.why.length === 0) {
      this.setState({ emptyWhyFactors: true });
    } else {
      this.setState({ emptyWhyFactors: false });
    }
    this.nextStep();
  }

  removeFactor(factor) {
    this.props.factors.forEach(function (selectedFactor) {
      if (selectedFactor.id === factor.id) {
        factor.selected = false;
      } else if (selectedFactor.selected == null) {
        selectedFactor.selected = false;
      }
    });

    // INFO: use == to avoid re-render every time
    if (this._totalSelectedFactors() === this.maxFactors - 1) {
      this.setState({ allowToSelect: true });
    }
    this.setState({ emptyWhyFactors: false });
  }

  previousStep() {
    const factor = this.props.factors.find(factor => factor.selected === true);
    if (factor) {
      this.removeFactor(factor);
    }
    this.props.previousStep({ vibe_factors: this.props.factors });
  }

  nextStep(params = {}) {
    this.props.nextStep({ vibe_factors: this.props.factors, other: this.state.other });
  }

  maxSelectionReach() {
    return !this.state.allowToSelect || this.state.other.length > 0;
  }

  disabledSelectButton() {
    const disableButton = this._totalSelectedFactors() <= this.maxFactors;
    return disableButton ? '' : 'disabled';
  }

  _totalSelectedFactors() {
    return this.props.factors.filter(factor => factor.selected === true).length;
  }

  getFactors(scope = {}) {
    return this.props.factors
      .filter(function (factor) {
        if (scope) {
          let keyValue = '';
          const keys = Object.keys(scope);
          for (let i = 0; i < keys.length; i++) {
            keyValue = keys[i];
            return factor[keyValue] === scope[keyValue];
          }
        }
        return false;
      })
      .map(factor => {
        return (
          <Factor
            factor={factor}
            disabled={this.maxSelectionReach()}
            addFactor={this.addFactor.bind(this)}
            removeFactor={this.removeFactor.bind(this)}
            key={factor.id}
          />
        );
      });
  }
}
