// This file contains names of 'domains' (top-level tree division) for store
// used in switch statement in root reducer
const DOMAINS = {
  INITIAL: 'INITIAL',

  USER: 'USER',
  POSTS: 'POSTS',
  TAGS: 'TAGS',
  SPACES: 'SPACES',
  COMMENTS: 'COMMENTS', // --- Comments don't have their domain in state tree, however they require quite a lot of actions
  USERS: 'USERS', // --- and thus they were separated from posts actions and reducers
  PLANS: 'PLANS',
  GROW: 'GROW',
  ONBOARDING: 'ONBOARDING',
  TEAMS: 'TEAMS',
};
export default DOMAINS;
