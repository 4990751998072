import React from 'react';
import awesome from '../../../images/vibe/awesome.svg';
import good from '../../../images/vibe/good.svg';
import okay from '../../../images/vibe/okay.svg';
import bad from '../../../images/vibe/bad.svg';
import terrible from '../../../images/vibe/terrible.svg';

export default class Sentiment extends React.Component {
  getImage(name) {
    switch (name) {
      case 'awesome':
        return awesome;
      case 'good':
        return good;
      case 'okay':
        return okay;
      case 'bad':
        return bad;
      case 'terrible':
      default:
        return terrible;
    }
  }

  render() {
    const classSelected = this.props.selected ? 'selected' : '';
    return (
      <div
        className={`vibe-sentiment ${classSelected}`}
        id={`${this.props.sentiment.name}-box`}
        onClick={() => this.props.selectChoice(this.props.sentiment.point)}
      >
        <img
          className="vibe-sentiment-images"
          src={this.getImage(this.props.sentiment.name)}
          id={this.props.sentiment.name}
          alt={this.props.sentiment.name}
        />
      </div>
    );
  }
}
