export const shouldRenderBlankState = (showBlankState, posts, fetchingPosts) => {
  const isNotSamplePost = posts.some(post => !post.sample);

  return showBlankState && !isNotSamplePost && !fetchingPosts;
};

export const getUniquePostsInDates = arrayToUnique => {
  return arrayToUnique.reduce((acc, el) => {
    const elDat = acc.find(accEl => accEl.date === el.date);
    if (elDat) {
      return acc.map(currentEl => {
        if (currentEl.date === elDat.date) {
          return {
            ...currentEl,
            post_ids: Array.from(new Set([...elDat.post_ids, ...el.post_ids])),
          };
        }
        return currentEl;
      });
    }
    return [...acc, el];
  }, []);
};
export const getDayName = date => {
  const day = date.getDay();
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days[day];
};
export const getDatesWithPosts = (dates, posts) =>
  dates.map(dayData => ({
    date: dayData.date,
    today: dayData.today,
    posts: posts.filter(post => dayData.post_ids.includes(post.id)),
  }));

export const filterSelectedPosts = post => {
  return (
    post.type === 'MyNote' ||
    post.type === 'SharedNote' ||
    post.type === 'MyWin' ||
    post.type === 'SharedWin' ||
    post.type === 'Shoutout' ||
    post.type === 'MeetingNote' ||
    post.type === 'SharedMeeting' ||
    post.type === 'TeamContribution' ||
    (post.type === 'MeetingPreparationNote' &&
      post.progress_status === 'complete' &&
      post.note?.visible_on_feed)
  );
};
