import React from 'react';
import { CHECK_UNAUTHORIZED, HEADERS } from 'helpers';
import MentionUserTooltip from 'components/shared/MentionUserTooltip';
import ReactDOM from 'react-dom';
import Auxiliary from 'components/layouts/Auxiliary';
import { APP_URL } from '../../../constants';

export const SHOW_MENTION_TOOLTIP = e => {
  const headers = HEADERS();
  if (!e.currentTarget.hasAttribute('hovered') && e.currentTarget.hasAttribute('slug')) {
    fetch(`${APP_URL}/mention_tooltip_user/${e.target.getAttribute('slug')}`, {
      method: 'get',
      headers,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => response.json())
      .then(json => {
        const tooltip = <MentionUserTooltip userData={json} />;
        const mention = e.target.innerHTML.replace(/&nbsp;/g, ' ');
        ReactDOM.render(
          <Auxiliary>
            {mention}
            {tooltip}
          </Auxiliary>,
          e.target
        );
      });
  }
  e.currentTarget.setAttribute('hovered', 'true');
};

export const HIDE_MENTION_TOOLTIP = e => {
  e.currentTarget.setAttribute('hovered', 'false');
};
