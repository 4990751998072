/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import { AVATAR_ERROR } from 'helpers';
import { Link } from 'react-router-dom';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import qs from 'qs';

const MarkedDone = ({ post, user }) =>
  post.status === 'resolved' ? (
    <div className="post-marked-done post-tile-marked-done">
      <img
        src={post.updater.image_url}
        alt={post.updater.first_name}
        className="img-circle img-xs post-updater-icon"
        onError={e => AVATAR_ERROR(e.target)}
      />
      <span>
        Marked as Resolved by&nbsp;
        <Link
          className="user-link"
          id="resolved-user"
          to={
            post.updater.id === user.id
              ? `/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`
              : `/user/${post.updater.id}`
          }
        >
          {`${post.updater.first_name} ${post.updater.last_name}`}
        </Link>
      </span>
    </div>
  ) : (
    ''
  );

export default MarkedDone;
