import React, { Component } from 'react';
import IssuesUserTile from './IssuesUserTile';
import Auxiliary from '../../layouts/Auxiliary';
import IssuesTeamTile from './IssuesTeamTile';
import FinalSetupModal from '../setup_mode/FinalSetupModal';
import InstructionsModal from '../setup_mode/InstructionsModal';
import CustomScrollbar from '../../shared/CustomScrollbar';

class CompanyIssues extends Component {
  state = {
    dragHelperVariables: {
      dragStartX: null,
      dragStartY: null,

      draggedUserDOM: null,
      draggedUserData: null,
      draggedElementStartX: null,
      draggedElementStartY: null,
    },
    sendingInvitations: false,

    teams: [],
    showInstructionsModal: false,
    showFinalSetupModal: false,
  };

  componentDidMount() {
    this.setState(
      {
        teams: this.props.teams.map(team => ({
          ...team,
          childrenUnfolded: this.props.teams.some(
            innerLoopTeam =>
              innerLoopTeam.parent_ids.some(id => id === team.id) &&
              innerLoopTeam.users.some(user => user.state !== 'active')
          ),
          users: team.users.map(user => ({
            ...user,
            marked_to_invite: false,
          })),
        })),
      },
      () => {
        window.addEventListener('mousemove', this.handleDragElement);
        window.addEventListener('mouseup', this.dragStop);
        window.addEventListener('scroll', this.handleBucketPosition);
        this.graphWrap = document.querySelector('.issues-graph');
        this.graphWrap.addEventListener('scroll', this.handleBucketPosition);
        this.bucket = document.querySelector('#main-team');
      }
    );
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleDragElement);
    window.removeEventListener('mouseup', this.dragStop);
    window.removeEventListener('scroll', this.handleBucketPosition);
    document
      .querySelector('.issues-graph')
      .removeEventListener('scroll', this.handleBucketPosition);
  }

  componentWillReceiveProps(props) {
    // ---Perform props check only after setting teams state as
    // ---cWRP is sometimes triggered by async redux action
    if (this.state.teams.length > 0) {
      this.setState({
        teams: props.teams.map(team => {
          const stateTeam = this.state.teams.find(stateTeam => stateTeam.id === team.id);
          return {
            ...team,
            users: stateTeam
              ? team.users.map(user => {
                  const stateUser = stateTeam.users.find(stateUser => stateUser.id === user.id);
                  return {
                    ...user,
                    marked_to_invite:
                      stateUser && stateUser.state === user.state
                        ? stateUser.marked_to_invite
                        : false,
                  };
                })
              : [],
            childrenUnfolded: stateTeam ? stateTeam.childrenUnfolded : false,
          };
        }),
      });
    }
  }

  handleBucketPosition = () => {
    if (this.graphWrap.scrollLeft > 0) this.bucket.classList.add('hide-horizontal-branch');
    else this.bucket.classList.remove('hide-horizontal-branch');
  };

  handleDragElement = e => {
    const element = this.state.dragHelperVariables.draggedUserDOM;
    if (element) {
      element.style.top = `${
        this.state.dragHelperVariables.draggedElementStartY -
        (this.state.dragHelperVariables.dragStartY - e.clientY)
      }px`;
      element.style.left = `${
        this.state.dragHelperVariables.draggedElementStartX -
        (this.state.dragHelperVariables.dragStartX - e.clientX)
      }px`;
    }
  };

  dragStop = () => {
    if (
      this.state.dragHelperVariables.draggedUserDOM ||
      this.state.dragHelperVariables.draggedTeamDOM
    ) {
      document.body.classList.remove('dragging');
      Array.from(document.querySelectorAll('.drag-capture-area')).forEach(node => {
        node.classList.remove('active');
      });
      const preservedElement =
        this.state.dragHelperVariables.draggedUserDOM ||
        this.state.dragHelperVariables.draggedTeamDOM;
      preservedElement.classList.add('stop-position-transition');
      preservedElement.style.transform = 'rotateZ(0)';
      preservedElement.style.top = `${
        preservedElement.getBoundingClientRect().top +
        window.scrollY -
        this.state.dragHelperVariables.draggedElementStartY
      }px`;
      preservedElement.style.left = `${
        preservedElement.getBoundingClientRect().left +
        window.scrollX -
        this.state.dragHelperVariables.draggedElementStartX
      }px`;
      preservedElement.style.transform = 'rotateZ(8)';
      preservedElement.classList.remove('dragged');
      this.setState({
        dragHelperVariables: {
          draggedUserDOM: null,
          draggedUserData: null,
          draggedTeamDOM: null,
          draggedTeamData: null,

          dragStartX: null,
          dragStartY: null,
          draggedElementStartX: null,
          draggedElementStartY: null,
        },
      });
      setTimeout(() => {
        preservedElement.style.position = '';
        preservedElement.classList.remove('stop-position-transition');
        preservedElement.style.top = 0;
        preservedElement.style.left = 0;
        preservedElement.style.transform = 'rotateZ(0)';
        preservedElement.parentNode.style.height = 'unset';
        preservedElement.parentNode.style.width = 'unset';
        // ---Transform has to be unset after all operations, as any transform causes its fixed positioned
        // children measuring position relatively to transformed parent instead of viewport
        setTimeout(() => (preservedElement.style.transform = 'unset'), 300);
      }, 0);
    }
  };

  generateChildren(parentTeam) {
    return (
      <div className="team-tree-wrap">
        {this.state.teams.filter(team => team.parent_id === parentTeam.id).length > 0 &&
        parentTeam.childrenUnfolded ? (
          <Auxiliary>
            {this.state.teams
              .filter(team => team.parent_id === parentTeam.id)
              .map(team => (
                <div
                  className={`team-subtree-wrap set-equal-branch-heights ${
                    this.state.teams
                      .filter(team => team.parent_id === parentTeam.id)
                      .map(team => team.id)
                      .indexOf(team.id) ===
                    this.state.teams.filter(team => team.parent_id === parentTeam.id).length - 1
                      ? 'hide-vertical-branch'
                      : ''
                  }`}
                  key={team.id}
                >
                  <div className="vertical-branch" />
                  <IssuesTeamTile
                    team={team}
                    teams={this.state.teams}
                    teamFolding={this.teamFolding}
                    moveUser={this.props.moveUser}
                    markInvitedUsers={this.markInvitedUsers}
                    dragHelperVariables={this.state.dragHelperVariables}
                    setDragHelperVariables={object => this.setState(object)}
                    sendingInvitations={this.state.sendingInvitations}
                  />
                  {this.generateChildren(team)}
                </div>
              ))}
          </Auxiliary>
        ) : (
          ''
        )}
      </div>
    );
  }

  markInvitedUsers = (markByType = null, user = null) => {
    const teamsCopy = JSON.parse(JSON.stringify(this.state.teams));
    const users = teamsCopy.reduce((a, b) => [...a, ...b.users], []);
    if (markByType === 'invited') {
      if (users.filter(user => user.state === 'invited').some(user => !user.marked_to_invite)) {
        users
          .filter(user => user.state === 'invited')
          .forEach(user => (user.marked_to_invite = true));
      } else {
        users
          .filter(user => user.state === 'invited')
          .forEach(user => (user.marked_to_invite = false));
      }
    } else if (markByType === 'pending') {
      if (users.filter(user => user.state === 'pending').some(user => !user.marked_to_invite)) {
        users
          .filter(user => user.state === 'pending')
          .forEach(user => (user.marked_to_invite = true));
      } else {
        users
          .filter(user => user.state === 'pending')
          .forEach(user => (user.marked_to_invite = false));
      }
    } else {
      users.find(stateUser => user.id === stateUser.id).marked_to_invite = !users.find(
        stateUser => user.id === stateUser.id
      ).marked_to_invite;
    }
    this.setState({ teams: teamsCopy });
  };

  teamFolding = team => {
    const teamsCopy = JSON.parse(JSON.stringify(this.state.teams));
    if (team.childrenUnfolded) {
      teamsCopy.forEach(stateTeam => {
        if (stateTeam.id === team.id || stateTeam.parent_ids.indexOf(team.id) !== -1)
          stateTeam.childrenUnfolded = false;
      });
    } else {
      teamsCopy[teamsCopy.findIndex(stateTeam => stateTeam.id === team.id)].childrenUnfolded = true;
    }
    this.setState({ teams: teamsCopy });
  };

  render = () => {
    const allUsers = this.state.teams
      ? this.state.teams.reduce((a, b) => [...a, ...b.users], [])
      : [];
    return this.state.teams.length > 0 ? (
      <div id="company-issues">
        <div className="company-issues-header">
          <div>
            <h1>Review & resend invitations</h1>
            <h2 onClick={() => this.setState({ showInstructionsModal: true })}>
              Learn more about invitation process
            </h2>
          </div>
          <div className="header-checkboxes-wrap">
            {allUsers.some(user => user.state === 'invited') ? (
              <div>
                <span
                  className={`invite-checkbox ${this.state.sendingInvitations ? 'disabled' : ''} ${
                    allUsers.every(
                      user =>
                        user.state !== 'invited' ||
                        (user.state === 'invited' && user.marked_to_invite)
                    )
                      ? 'selected'
                      : ''
                  }`}
                  onMouseDown={e => e.stopPropagation()}
                  onClick={() =>
                    !this.state.sendingInvitations ? this.markInvitedUsers('invited') : null
                  }
                />{' '}
                {allUsers.every(
                  user =>
                    user.state !== 'invited' || (user.state === 'invited' && user.marked_to_invite)
                )
                  ? `${
                      allUsers.filter(user => user.state === 'invited').length
                    } not-activated user${
                      allUsers.filter(user => user.state === 'invited').length === 1 ? '' : 's'
                    } selected`
                  : 'Select all not-activated users'}
              </div>
            ) : (
              ''
            )}
            {allUsers.some(user => user.state === 'pending') ? (
              <div>
                <span
                  className={`invite-checkbox ${this.state.sendingInvitations ? 'disabled' : ''} ${
                    allUsers.every(
                      user =>
                        user.state !== 'pending' ||
                        (user.state === 'pending' && user.marked_to_invite)
                    )
                      ? 'selected'
                      : ''
                  }`}
                  onMouseDown={e => e.stopPropagation()}
                  onClick={() =>
                    !this.state.sendingInvitations ? this.markInvitedUsers('pending') : null
                  }
                />{' '}
                {allUsers.every(
                  user =>
                    user.state !== 'pending' || (user.state === 'pending' && user.marked_to_invite)
                )
                  ? `${allUsers.filter(user => user.state === 'pending').length} un-invited user${
                      allUsers.filter(user => user.state === 'pending').length === 1 ? '' : 's'
                    } selected`
                  : 'Select all un-invited users'}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="issues-graph">
          <div id="main-team-wrap">
            <div
              id="main-team"
              className={`team-tile ${
                this.state.teams.length === 1 ? 'hide-horizontal-branch' : ''
              }`}
            >
              <div className="team-tile-header">
                <span>Unallocated users</span>
              </div>
              <p>
                You don’t need to create an overall account / company team. All users will
                automatically be added to this team after you finish the setup process.
              </p>
              <div className="main-team-members-list-wrap">
                <div className="main-team-members-list">
                  <CustomScrollbar />
                  {this.state.teams
                    .find(team => team.parent_ids.length === 0)
                    .users.sort((a, b) =>
                      a.last_name.toLowerCase() > b.last_name.toLowerCase() ? 1 : -1
                    )
                    .map(user => (
                      <IssuesUserTile
                        team={this.state.teams.find(team => team.parent_ids.length === 0)}
                        markInvitedUsers={this.markInvitedUsers}
                        key={user.id}
                        user={user}
                        dragHelperVariables={this.state.dragHelperVariables}
                        setDragHelperVariables={object => this.setState(object)}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
          {this.generateChildren(this.state.teams.filter(team => team.parent_ids.length === 0)[0])}
        </div>
        <div className="bottom-buttons-wrap">
          <div
            className={`cancel-button ${this.state.sendingInvitations ? 'disabled' : ''}`}
            onClick={() => (!this.state.sendingInvitations ? this.props.viewMode() : null)}
          >
            Cancel
          </div>
          <div
            className={`next-step-button ${
              this.state.sendingInvitations ||
              this.state.teams.reduce(
                (a, b) => [...a, ...b.users.filter(user => user.marked_to_invite)],
                []
              ).length === 0
                ? 'disabled'
                : ''
            }`}
            onClick={() => {
              if (
                this.state.teams.reduce(
                  (a, b) => [...a, ...b.users.filter(user => user.marked_to_invite)],
                  []
                ).length !== 0
              ) {
                this.props.sendInvitations(
                  this.state.teams.reduce(
                    (a, b) => [
                      ...a,
                      ...b.users.filter(user => user.marked_to_invite).map(user => user.id),
                    ],
                    []
                  )
                );
                this.setState({
                  sendingInvitations: true,
                  showFinalSetupModal: true,
                });
              }
            }}
          >
            Send invitations
          </div>
        </div>
        <FinalSetupModal
          showFinalSetupModal={this.state.showFinalSetupModal}
          close={() => this.setState({ showFinalSetupModal: false })}
          finishSetup={() => this.props.viewMode()}
        />
        <InstructionsModal
          showInstructionsModal={this.state.showInstructionsModal}
          close={() => this.setState({ showInstructionsModal: false })}
        />
      </div>
    ) : (
      ''
    );
  };
}

export default CompanyIssues;
