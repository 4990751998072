import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from '@emotion/styled';
import handleStickyScroll from '../feed/handleStickyScroll';

const PanelOverlay = styled.div`
  display: inline;
  position: static;
  background: rgba(32, 32, 32, 0);
  transition: background 0.2s 0.2s, height 0s 0.4s, width 0s 0.4s;
  float: right;
  min-width: 200px;
  @media (max-width: 1000px) {
    z-index: 1031;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    height: 0;
    &.open {
      height: 100vh;
      transition: background 0.2s;
      background: rgba(32, 32, 32, 0.7);
      .right-panel-wrap {
        overflow: scroll;
        transform: translateX(0);
        transition: transform 0.2s 0.2s;
      }
    }
  }
  .right-panel-wrap {
    top: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: transform 0.2s;
    width: 200px;
    padding-left: 0;
    @media (max-width: 1000px) {
      transform: translateX(100%);
      padding: 20px 20px 20px 15px;
      background: #f3f3f3;
      height: 100vh;
      position: absolute !important;
      right: 0;
      top: 0;
    }
    .right-panel-inner-wrap {
      max-height: calc(100vh - 96px);
      min-height: calc(100vh - 96px);
      overflow-y: scroll;
      padding-right: 20px;
      margin-right: -20px;
    }
  }
`;

class RightSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener('touchstart', this.handleTouchStart);
    document.addEventListener('touchmove', this.handleTouchMove);
    document.addEventListener('scroll', this.handleStickyScroll);

    this.panelParent = document.getElementsByClassName('parent-panel-wrap')[0];
    this.panel = document.getElementsByClassName('right-panel-wrap')[0];
  }

  componentWillUnmount() {
    document.removeEventListener('touchstart', this.handleTouchStart);
    document.removeEventListener('touchmove', this.handleTouchMove);
  }

  handleStickyScroll = () => {
    handleStickyScroll(this.panel, this.panelParent);
  };

  // ---Swiping listeners
  xDown = null;

  yDown = null;

  handleTouchStart = e => {
    this.xDown = e.touches[0].clientX;
    this.yDown = e.touches[0].clientY;
  };

  handleTouchMove = e => {
    if (!this.xDown || !this.yDown) {
      return;
    }
    const xUp = e.touches[0].clientX;
    const yUp = e.touches[0].clientY;

    const xDiff = this.xDown - xUp;
    const yDiff = this.yDown - yUp;
    if (
      Math.abs(xDiff) > Math.abs(yDiff) &&
      !document.querySelector('#navbar-mobile-overlay.open')
    ) {
      if (xDiff > 0) {
        if ((window.innerWidth * 7) / 8 < this.xDown) {
          setTimeout(() => this.setState({ mobileOpen: true }), 10);
        }
      } else {
        setTimeout(() => this.setState({ mobileOpen: false }), 10);
      }
    }
    this.xDown = null;
    this.yDown = null;
  };

  render() {
    return (
      <PanelOverlay className={` parent-panel-wrap ${this.state.mobileOpen ? 'open' : ''}`}>
        <div className="right-panel-wrap">
          <div className="right-panel-inner-wrap">{this.props.children}</div>
        </div>
      </PanelOverlay>
    );
  }
}

export default withRouter(RightSidebar);
