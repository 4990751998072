import React, { Component, Fragment } from 'react';
import fetchApi from 'fetchApi';
import LeadTile from './LeadTile';
import FlipSelect from '../../shared/FlipSelect';

class LeadList extends Component {
  state = {
    users: [],
    selectedUser: '',
  };

  fetchMembers = async () => {
    const response = await fetchApi(`/assign_lead/team/${this.props.team.id}`);
    this.setState({ users: response.members });
  };

  makeUserLead = e => {
    const user = this.state.users.find(function (user) {
      return user.id === e.value;
    });
    this.props.updateUserRole(user, this.props.team, true);
  };

  componentDidMount() {
    this.props.team && this.props.team.id && this.fetchMembers();
  }

  componentDidUpdate(prevProps) {
    if (this.props.team !== prevProps.team) {
      this.fetchMembers();
    }
  }

  getSelectUserOptions() {
    let { users } = this.state;
    if (users && users.length > 0) {
      users = users.map(user => ({ value: user.id, label: `${user.name} (${user.slug})` }));
    }
    return users;
  }

  render() {
    const { team } = this.props;

    return (
      <div className="team-leads-list">
        {this.props.setupStep >= 1 ? (
          this.props.team.team_leads.length > 0 ? (
            <>
              {this.props.team.team_leads
                .sort((a, b) => (a.last_name.toLowerCase() >= b.last_name.toLowerCase() ? 1 : -1))
                .map((user, index) => (
                  <Fragment key={user.id}>
                    <LeadTile
                      selectUser={this.props.selectUser}
                      team={team}
                      key={user.id}
                      user={user}
                      setupStep={this.props.setupStep}
                      updateUserRole={this.props.updateUserRole}
                    />
                  </Fragment>
                ))}
            </>
          ) : (
            <div className="no-users-placeholder-wrap" onMouseDown={e => e.stopPropagation()}>
              <FlipSelect
                options={this.getSelectUserOptions()}
                value={this.state.selectedUser}
                isSearchable
                onChange={e => this.makeUserLead(e)}
                clearable={false}
                inputProps={{ id: `assign-lead-input-${this.props.team.id}` }}
                className="select-component assign-lead orange"
                placeholder="Select lead"
              />
            </div>
          )
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default LeadList;
