import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import debounce from 'lodash/debounce';

import { PLAN_ACTIONS } from 'redux/actions/planActions';
import { SPACE_ACTIONS } from 'redux/actions/spaceActions';
import { BODY_CLASSES, HEADERS } from 'helpers';

import Header from '../partials/Header';
import OrganisationTable from '../partials/OrganisationTable';
import categories from '../categories';
import Page from '../../layouts/Page';
import fields from './fields';
import SpacesSidebar from './SpacesSidebar';
import AssignLeadModal from '../../spaces/AssignLeadModal';
import { APP_URL } from '../../../constants';

const Spaces = ({
  location,
  history,
  updateFlash,
  isMember,
  plan,
  user,
  displaySpaces,
  showUpgradeModal,
  fetchSpace,
  isAccountAdmin,
  userLeadTeamIds,
  spacesFromProps,
}) => {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState({});
  const [page, setPage] = useState(queryParams.page || 1);
  const [spaces, setSpaces] = useState({});
  const [searchTerm, setSearchTerm] = useState(queryParams.search_term || '');
  const [sort, setSort] = useState({
    order: (queryParams.sort && queryParams.sort.order) || 'asc',
    column: (queryParams.sort && queryParams.sort.column) || 'name',
  });
  const [loading, setLoading] = useState(true);
  const [assignLeadModalToggled, setAssignLeadModalToggled] = useState(false);
  const [editLeadModalToggled, setEditLeadModalToggled] = useState(false);
  const [assignTo, setAssignTo] = useState({});

  useLayoutEffect(() => {
    BODY_CLASSES();
  }, []);

  const setAssignToAndShowModal = (e, space) => {
    e.stopPropagation();
    setAssignTo(space);
    setAssignLeadModalToggled(true);
  };

  const setEditLeadsShowModal = (e, space) => {
    e.stopPropagation();
    setAssignTo(space);
    setEditLeadModalToggled(true);
  };

  const fetchSpaces = async () => {
    const headers = HEADERS();
    const params = {
      page,
      search_term: searchTerm,
      sort,
    };
    if (searchTerm !== spaces.searchTerm) {
      setPage(1);
      params.page = 1;
    }
    history.replace(`/spaces?${qs.stringify(params)}`);
    const response = await fetch(`${APP_URL}/spaces?${qs.stringify(params)}`, {
      method: 'get',
      headers,
    });
    if (response.ok && response.status === 200) {
      const json = await response.json();
      const rows = json.spaces.data.map(
        fields.rows(
          setAssignToAndShowModal,
          requestAccess,
          joinSpace,
          isMember,
          isAccountAdmin,
          userLeadTeamIds,
          setEditLeadsShowModal
        ).spaces
      );
      setSpaces({
        ...json.spaces,
        rows,
        searchTerm,
        page,
      });
      setSelectedItem(null);
      setSelectedIndex({});
      setLoading(false);
    }
  };

  const requestAccess = async (e, space) => {
    e.stopPropagation();
    const headers = HEADERS();
    const response = await fetch(`${APP_URL}/spaces/request_access/${space.id}`, {
      method: 'get',
      headers,
    });
    const json = await response.json();
    fetchSpace(space.id);
    fetchSpaces();
    updateFlash(json.flashName, json.message);
  };

  const joinSpace = async (e, space) => {
    e.stopPropagation();
    const headers = HEADERS();
    const response = await fetch(`${APP_URL}/spaces/join/${space.id}`, { method: 'get', headers });
    const json = await response.json();
    fetchSpace(space.id);
    fetchSpaces();
    updateFlash(json.flashName, json.message);
  };

  useEffect(() => {
    fetchSpaces();
    if (plan === 'free' && user.isAccountAdmin) {
      showUpgradeModal();
    }
  }, [page, searchTerm, sort.order, sort.column, spacesFromProps]);

  const handleFilter = () => debounce(setSearchTerm, 300);

  const handleSort = column => {
    switch (sort.order) {
      case 'desc':
        return setSort({
          column,
          order: 'asc',
        });
      case 'asc':
        return setSort({
          column: '',
          order: '',
        });
      case '':
      default:
        return setSort({
          column,
          order: 'desc',
        });
    }
  };

  const handleSelect = index => {
    setSelectedIndex(index);
    setSelectedItem(spaces.data[index]);
  };

  return !loading && displaySpaces ? (
    <>
      <Page.Wrapper>
        <Page.Content>
          <Header category={categories.SPACES} count={spaces.total} handleFilter={handleFilter()} />
        </Page.Content>
        <Page.SidebarPlaceholder />
      </Page.Wrapper>
      <Page.Wrapper>
        <Page.Content>
          <OrganisationTable
            columns={fields.columns.spaces}
            rows={spaces.rows}
            select={{
              multi: false,
              handleChange: handleSelect,
              index: selectedIndex,
              allowSelect: true,
            }}
            pagination={{
              index: spaces.page,
              total: spaces.last_page,
              handleChange: setPage,
            }}
            sort={{
              order: sort.order,
              handleChange: handleSort,
              column: sort.column,
            }}
          />
        </Page.Content>
        <Page.Sidebar>
          <SpacesSidebar
            spaces={spaces}
            selectedSpace={selectedItem}
            updateFlash={updateFlash}
            reloadData={fetchSpaces}
            plan={plan}
          />
        </Page.Sidebar>
        <AssignLeadModal
          showAssignLeadModal={assignLeadModalToggled}
          closeAssignLeadModalHandler={() => setAssignLeadModalToggled(false)}
          assignTo={assignTo}
          updateFlash={updateFlash}
          resetList={() => fetchSpaces()}
        />
      </Page.Wrapper>
    </>
  ) : null;
};

const mapStateToProps = state => ({
  spacesFromProps: state.spacesDomain.spaces,
  plan: state.plansDomain.plan,
  user: state.usersDomain.user,
  displaySpaces: state.plansDomain.plan !== 'free' || state.usersDomain.user.isAccountAdmin,
  isMember:
    !!state.usersDomain.user &&
    !state.usersDomain.user.isAccountAdmin &&
    !state.usersDomain.user.formalLeadTeamsIds.length > 0,
  isAccountAdmin: state.usersDomain.user.isAccountAdmin,
  userLeadTeamIds: state.usersDomain.user.leadTeamsIds,
});

const mapDispatchToProps = {
  showUpgradeModal: PLAN_ACTIONS.showUpgradeModal,
  fetchSpace: SPACE_ACTIONS.fetchFormalSpace,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Spaces));
