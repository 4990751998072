import React from 'react';

const RemoveFromProgressBoard = ({ id, removeNoteClickHandle }) => {
  const handleClick = () => {
    removeNoteClickHandle(id);
  };

  return <div onClick={handleClick}>Remove from Grow Board</div>;
};

export default RemoveFromProgressBoard;
