import React from 'react';
import styled from '@emotion/styled';

import Select from 'react-select';

const SelectStyled = styled(Select)`
  ${props =>
    props.sidebar ? 'width: 100%; margin: 0 0 20px 0;' : 'width: 230px; margin: 0 15px 0 auto;'}
  font-size: 13px;
  line-height: 15px;
  .Select-placeholder {
    color: #58575f;
    padding-left: 14px;
    line-height: 42px;
  }
  .Select-control {
    height: 42px;
    border-color: #e7e7e7;
    .Select-value {
      line-height: 42px;
    }
  }
  .Select-arrow {
    border-color: #6a6a6a transparent transparent;
  }
  #team-member-select {
    line-height: 24px;
  }
`;

const TeamMemberSelect = ({ teamMembers, onSelect, selectedMember, sidebar }) => {
  return (
    <SelectStyled
      id="team-member-select"
      placeholder="Filter by user or team"
      className="select-component"
      isSearchable
      onChange={member => onSelect(member)}
      value={selectedMember || ''}
      options={teamMembers}
      sidebar={sidebar}
    />
  );
};

export default TeamMemberSelect;
