import React from 'react';
import styled from '@emotion/styled';

const Button = styled.span`
  font-size: 13px;
  line-height: 140%;
  color: #11a9ff;
  position: relative;
  cursor: pointer;
  :after {
    opacity: 0.45;
    width: 100%;
    height: 1px;
    position: absolute;
    content: '';
    transition: 0.3s width;
    bottom: -4px;
    left: 0;
    background: #11a9ff;
  }
`;

const PostponeButton = ({ text, ...props }) => {
  return <Button {...props}>{text || 'Postpone tour'}</Button>;
};
export default PostponeButton;
