/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import styled from '@emotion/styled';
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const SingleWrapper = styled.div`
  display: table;
  width: 100%;
  @media (max-width: 500px) {
    display: block;
  }
`;

const LabelsWrapper = styled.div`
  display: table-cell;
  width: 50%;
  float: right;
  height: 175px;
  overflow-y: auto;
  display: flex;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f2f2f2;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
  }
  @media (max-width: 500px) {
    width: 100%;
    height: unset;
    padding: 15px 0;
    display: block;
  }
`;

const Labels = styled.div`
  margin: auto 0;
  padding-top: 8px;
`;

const LabelWrapper = styled.div`
  padding-bottom: 6px;
  font-size: 13px;
  line-height: 15px;
  color: #323232;
  font-weight: normal;
`;

const ChartWrapper = styled.div`
  width: 50%;
  float: left;
  display: table-cell;
  @media (max-width: 500px) {
    padding-top: 10px;
    width: 100%;
    display: block;
  }
`;

const LabelImage = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${props => props.color};
  display: inline-block;
  border-radius: 50%;
  margin-right: 12px;
  vertical-align: middle;
`;

const plugins = (data, empty) => {
  const total = data.reduce((a, b) => a + b, 0);
  const answersCount = data.filter(datum => datum !== 0).length;
  return {
    datalabels: {
      display: true,
      color: '#FFFFFF',
      font: {
        weight: 'bold',
        size: '13',
      },
      align: answersCount > 5 ? 'end' : 'center',
      offset: '-5',
      formatter(value, context) {
        if (empty) {
          return 'No responses';
        }
        if (value === 0) {
          return '';
        }
        const percent = (value * 100) / total;
        return `${Number(percent.toFixed(1))}%`;
      },
    },
  };
};

const splitString = string => {
  return string.match(/.{1,25}(\s|$)/g);
};

const tooltips = (labels, empty) => {
  return {
    callbacks: {
      label(item, data) {
        const value = data.datasets[0].data[item.index];
        const label = labels[item.index];
        if (label.length > 25) {
          return ` ${splitString(label)[0]}`;
        }
        return ` ${label}: ${value}`;
      },
      afterLabel(item, data) {
        const value = data.datasets[0].data[item.index];
        let label = labels[item.index];
        label = splitString(label);
        label.shift();
        label[label.length - 1] = `${label[label.length - 1]}: ${value}`;
        return label;
      },
    },
    enabled: !empty,
  };
};

const colors = [
  '#52BA05',
  '#11A9FF',
  '#FFB11B',
  '#FF7F63',
  '#B75D36',
  '#16D1B0',
  '#7AA0B6',
  '#ED71CA',
  '#005099',
  '#734A4A',
];
const CheckboxResults = ({ question }) => {
  let data = question.options.map(o => o.answers_count);
  const labels = question.options.map(o => o.content);
  const empty = data.every(datum => datum === 0);
  let chartColors = colors;
  if (empty) {
    data = [100];
    chartColors = ['#D5D5D5'];
  }
  return (
    <SingleWrapper>
      <ChartWrapper>
        <Pie
          data={{
            labels: [],
            datasets: [
              {
                data,
                backgroundColor: chartColors,
              },
            ],
          }}
          height="200%"
          options={{
            plugins: plugins(data, empty),
            tooltips: tooltips(labels, empty),
          }}
        />
      </ChartWrapper>
      <LabelsWrapper>
        <Labels>
          {labels.map((label, key) => (
            <LabelWrapper key={key}>
              <LabelImage color={colors[key]} />
              {label}
            </LabelWrapper>
          ))}
        </Labels>
      </LabelsWrapper>
    </SingleWrapper>
  );
};

export default CheckboxResults;
