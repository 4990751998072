/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/core';
import FactorsChartVertical from 'components/vibes/team-vibe-show/TeamVibeDashboard/FactorsChartVertical';
import FactorsChartHorizontal from 'components/vibes/team-vibe-show/TeamVibeDashboard/FactorsChartHorizontal';

const getFeedCategories = (withLineBreaks = true) => {
  const helper = withLineBreaks ? <br /> : ' ';
  return [
    <>
      Excitement
      {helper}
      &&nbsp;Energy
    </>,
    <>
      Learning
      {helper}
      &&nbsp;Growth
    </>,
    <>
      Autonomy&nbsp;&
      {helper}
      Independence
    </>,
    <>Valued</>,
    <>
      Clarity&nbsp;&
      {helper}
      Certainty
    </>,
    <>Being Connected</>,
    <>
      Safety&nbsp;&
      {helper}
      Comfort
    </>,
    <>Purpose</>,
    <>Productivity</>,
    <>External</>,
    <>Other</>,
  ];
};

const defaultData = [...Array(11)].fill('').map(() => ({
  current: {
    terrible: 0,
    bad: 0,
    okay: 0,
    good: 0,
    awesome: 0,
  },
  previous: {
    terrible: 0,
    bad: 0,
    okay: 0,
    good: 0,
    awesome: 0,
  },
}));

const FactorAnalysisChart = ({
  showPrevious,
  data,
  queryString,
  canSeeFeedback,
  feedbackCategory,
  setFeedbackCategory,
  chartType,
  dashboard = false,
}) => {
  const [chartData, setChartData] = useState(defaultData);
  useEffect(() => {
    if (data !== undefined) {
      setChartData(data);
    } else {
      setChartData(defaultData);
    }
  }, [data]);

  return chartType === 'vertical' ? (
    <>
      <FactorsChartVertical
        showPrevious={showPrevious}
        data={chartData}
        queryString={queryString}
        factors={getFeedCategories(false)}
        dashboard={dashboard}
      />
      <div className="factors-legend-wrap">
        {getFeedCategories(!dashboard).map((object, index) => (
          <div
            className={canSeeFeedback && feedbackCategory === index + 1 ? 'active' : ''}
            onClick={() => setFeedbackCategory && setFeedbackCategory(index + 1)}
            key={index}
          >
            {object}
          </div>
        ))}
      </div>
    </>
  ) : (
    <div className={`horizontal-wrap-helper ${dashboard && 'dashboard'}`}>
      <div className="factors-legend-wrap">
        {getFeedCategories(!dashboard).map((object, index) => (
          <div
            className={canSeeFeedback && feedbackCategory === index + 1 ? 'active' : ''}
            onClick={() => setFeedbackCategory && setFeedbackCategory(index + 1)}
            key={index}
          >
            {object}
          </div>
        ))}
      </div>
      <FactorsChartHorizontal showPrevious={showPrevious} data={chartData} dashboard={dashboard} />
    </div>
  );
};
export default FactorAnalysisChart;
