import { APP_URL } from 'constants.js';
import { HEADERS } from 'helpers';
import history from './history';

const defaultOptions = { method: 'get', json: true };

// TODO: add error handling and redirecting to error pages here.
export default function fetchApi(endpoint, options = defaultOptions) {
  const { method, json, ...remainingOptions } = { ...defaultOptions, ...options };

  const headers = HEADERS();
  return fetch(`${APP_URL}${endpoint}`, { method, headers, ...remainingOptions })
    .then(response => {
      response.timestamp = response.headers.get('heelix-timestamp');
      if (!json) {
        return response;
      }
      return response.json();
    })
    .catch(error => {});
}
