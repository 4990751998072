import { INITIAL_GROW_FILTERS, INITIAL_POST_FILTERS } from 'constants.js';
import DOMAINS from '../domains';
import postReducer from './postReducer';
import initialReducer from './initialReducer';
import commentReducer from './commentReducer';
import tagReducer from './tagReducer';
import userReducer from './userReducer';
import spaceReducer from './spaceReducer';
import planReducer from './plansReducer';
import growReducer from './growReducer';
import onboardingReducer from './onboardingReducer';
import teamReducer from './teamReducer';
import app from '../app';

const initialState = {
  usersDomain: {
    user: null,
    spaceId: null,
    feedEnabled: true,
    growEnabled: true,
  },
  postsDomain: {
    timestamp: null,
    totalPostsCount: 0, // --it's easier to perform lazy loading of new posts with their count instead of pages
    loadedPostsCount: 0,

    hasMorePostsToLoad: true,
    fetchingPosts: false,
    scrollPosition: 0,
    posts: [],
    filters: INITIAL_POST_FILTERS,

    singlePost: null, // should be set to null every time after leaving single post view

    submittingComment: false,

    fetchingComments: false,
  },
  teamsDomain: {
    teams: [],
    team: {
      thumbnail_details: [],
      hashtags: [],
    },
  },
  spacesDomain: {
    spaces: [],
    thumbColors: [],
  }, // we'll need to mark whether user sees content or vibe stats only of given group later
  tagsDomain: {
    hashtags: [],
    discussionTags: [], // orange ones
    feedTags: [],
    goals: [],
    companyValues: [],
  },
  plansDomain: {
    plan: '',
    showUpgradePlanModal: false,
    planLimitations: {},
  },
  growDomain: {
    timestamp: null,
    totalPostsCount: 0, // --it's easier to perform lazy loading of new posts with their count instead of pages
    loadedPostsCount: 0,
    hasMorePostsToLoad: true,
    fetchingPosts: false,
    scrollPosition: 0,
    posts: [],
    dates: [],
    fetchingComments: false,
    filters: INITIAL_GROW_FILTERS,
    tab: 'grow',
    postsCount: 0,
    fetchingPostsCount: false,
    post: [],
    selectedPosts: [],
    allPostsSelected: false,
  },
  onboardingDomain: {
    showTourModal: false,
    finishedTour: null,
    feedTourStep: -1,
    insightsTourStep: -1,
    growTourStep: -1,
    onboardingSpace: null,
    feedTourFinished: false,
    insightsTourFinished: false,
    growTourFinished: false,
    wasUserAdded: false,
    wasTeamAdded: false,
    wasUserMoved: false,
    wasCompanyStructureFetched: false,
    wasAddTeamButtonClicked: false,
    addChildTeamState: false,
  },
};
const rootReducer = (state = initialState, action) => {
  switch (action.domain) {
    case DOMAINS.INITIAL:
      return initialReducer(state, action);
    case DOMAINS.POSTS:
      return postReducer(state, action);
    case DOMAINS.COMMENTS:
      return commentReducer(state, action);
    case DOMAINS.TAGS:
      return tagReducer(state, action);
    case DOMAINS.USERS:
      return userReducer(state, action);
    case DOMAINS.SPACES:
      return spaceReducer(state, action);
    case DOMAINS.PLANS:
      return planReducer(state, action);
    case DOMAINS.GROW:
      return growReducer(state, action);
    case DOMAINS.ONBOARDING:
      return onboardingReducer(state, action);
    case DOMAINS.TEAMS:
      return teamReducer(state, action);
    default:
      return {
        ...state,
        // app: app(state, action).app,
      };
  }
};

export default (state, action) => ({
  ...rootReducer(state, action),
  app: app(state && state.app, action),
});
