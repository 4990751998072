import React from 'react';
import styled from '@emotion/styled';

const AssigneeOptionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
`;

const AssigneeOptionImage = styled.img`
  width: 26px;
  height: 26px;
  border: 1px solid #d8d8d8;
  border-radius: 50%;
  margin-right: 11px;
`;

const AssigneeOptionTitle = styled.span`
  font-size: 14px;
  color: #323232;
  line-height: 16px;
`;

const AssigneeOptionTeamDefault = styled.span`
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 11px;
  display: inline-block;
  font-weight: bold;
  line-height: 180%;
  text-align: center;
  text-shadow: none;
  position: relative;
`;
const getStyles = colors =>
  colors
    ? {
        backgroundColor: colors.background,
        color: colors.letters,
        borderColor: colors.border,
        borderWidth: '1px',
        borderStyle: 'solid',
      }
    : {};

const AssigneeOption = ({ value: { image, label, thumbnail_details = null } }) => {
  return (
    <div className="Select-value">
      <span className="Select-value-label">
        <AssigneeOptionWrapper>
          {image ? (
            <AssigneeOptionImage src={image} alt="" />
          ) : (
            <AssigneeOptionTeamDefault
              className={`space-logo`}
              style={getStyles(thumbnail_details.colors)}
            >
              {thumbnail_details.letters}
            </AssigneeOptionTeamDefault>
          )}
          <AssigneeOptionTitle>{label}</AssigneeOptionTitle>
        </AssigneeOptionWrapper>
      </span>
    </div>
  );
};

export default AssigneeOption;
