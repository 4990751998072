import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { AVATAR_GENERIC, AWS_S3_URL } from '../../constants';
import { BODY_CLASSES } from '../../helpers';
import { SPACE_ACTIONS } from '../../redux/actions/spaceActions';
import { USER_ACTIONS } from '../../redux/actions/userActions';
import SpaceLogo from '../spaces/SpaceLogo';
import SwitchButton from '../shared/buttons/SwitchButton';
import EmailNotificationsTooltip from './EmailNotificationsTooltip';
import MobileNotificationsTooltip from './MobileNotificationsTooltip';
import MentionNotificationsTooltip from './MentionNotificationsTooltip';
import CommentsNotificationsTooltip from './CommentsNotificationsTooltip';

const mapDispatchToProps = dispatch => ({
  unfollowUser: userId => dispatch(USER_ACTIONS.unfollowUser(userId)),
  unfollowSpace: (spaceId, postType) => dispatch(SPACE_ACTIONS.unfollowSpace(spaceId, postType)),
  fetchNotificationSettings: () => dispatch(SPACE_ACTIONS.fetchSpaceNotificationSettings()),
  unfollowWeeklyDigest: () => dispatch(USER_ACTIONS.unfollowWeeklyDigest()),
});

const mapStateToProps = state => ({
  users: state.usersDomain.usersForShoutoutModal,
  spaces: state.spacesDomain.notificationSettings,
  unfollowedUsersIds: state.usersDomain.unfollowedUsersIds,
  weeklyDigestSettings: state.usersDomain.weeklyDigest,
  feedEnabled: state.usersDomain.feedEnabled,
});

const initialState = {
  users: [],
  selectedUsers: [],
  spaces: [],
  hoverOverUserId: '',
};

class NotificationSettings extends Component {
  state = initialState;

  componentDidMount() {
    BODY_CLASSES(['notification_settings']);

    this.props.fetchNotificationSettings();
    this.setUsersState();
  }

  setUsersState(props = null) {
    const { unfollowedUsersIds, users } = props || this.props;
    const userArr = users.filter(user => unfollowedUsersIds.includes(user.id));
    this.setState({
      users,
      selectedUsers: userArr,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.users.length > 0) {
      this.setUsersState(nextProps);
    }
  }

  getSelectUserOptions() {
    let { users } = this.state;
    if (users.length > 0) {
      users = users.map(user => ({ value: user.id, label: `${user.name} (${user.slug})` }));
    }
    return users;
  }

  addUsersToList = model => {
    const userArr = this.props.users.find(user => user.id === model.value);
    const usersLeft = this.state.users.filter(user => user.id !== model.value);
    this.setState(prevState => ({
      selectedUsers: [...prevState.selectedUsers, userArr],
      users: usersLeft,
    }));
  };

  removeFromList(userId) {
    const userArr = this.state.selectedUsers.filter(user => user.id !== userId);
    const addUser = this.props.users.find(user => user.id === userId);
    this.setState(prevState => ({
      selectedUsers: userArr,
      users: [...prevState.users, addUser],
    }));
  }

  handleHoverOverUser(userId) {
    this.setState({
      hoverOverUserId: userId,
    });
  }

  handleStopHoverOverUser(userId) {
    if (this.state.hoverOverUserId === userId) {
      this.setState({
        hoverOverUserId: '',
      });
    }
  }

  unfollowUser = id => {
    this.props.unfollowUser(id);
  };

  render() {
    return (
      <form className="notifications_form">
        <div className="settings-container">
          <h2>Notification settings</h2>
          {this.props.feedEnabled && (
            <div className="notifications-group-wrap spaces-list">
              <div className="column-headers-wrap">
                <div className="main-header">By space</div>
                <div className="sub-header">
                  Email:
                  <br />
                  roll-ups
                  <br />
                  <span className="info-icon" data-tip data-for="email-tooltip">
                    ?
                  </span>
                </div>
                <div className="sub-header">
                  Mobile:
                  <br />
                  All new posts
                  <br />
                  <span className="info-icon" data-tip data-for="mobile-tooltip">
                    ?
                  </span>
                </div>
                <div className="sub-header">
                  Email & Mobile:
                  <br />
                  Mentions
                  <br />
                  <span className="info-icon" data-tip data-for="mention-tooltip">
                    ?
                  </span>
                </div>
                <div className="sub-header">
                  Email & Mobile:
                  <br />
                  Comments on my post
                  <br />
                  <span className="info-icon" data-tip data-for="comments-tooltip">
                    ?
                  </span>
                </div>
              </div>
              {this.props.spaces.map(space => (
                <div key={space.id} className="space-row">
                  <div className="space-details">
                    <SpaceLogo space={space} large />
                    <span className="space-name">{space.name}</span>
                  </div>
                  <div className="switch-wrap">
                    <SwitchButton
                      turnedOn={space.email_enabled}
                      onClick={() => this.props.unfollowSpace(space.space_id, 'email')}
                    />
                  </div>
                  <div className="switch-wrap">
                    <SwitchButton
                      turnedOn={space.mobile_enabled}
                      onClick={() => this.props.unfollowSpace(space.space_id, 'mobile')}
                    />
                  </div>
                  <div className="switch-wrap">
                    <SwitchButton
                      turnedOn={space.mention_enabled}
                      onClick={() => this.props.unfollowSpace(space.space_id, 'mention')}
                    />
                  </div>
                  <div className="switch-wrap">
                    <SwitchButton
                      turnedOn={space.comments_enabled}
                      onClick={() => this.props.unfollowSpace(space.space_id, 'comments')}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
          {this.props.feedEnabled && (
            <div className="notifications-group-wrap">
              <h3 className="main-header">By author</h3>
              <h4>Search users you want to turn on notifications for</h4>
              <Select
                options={this.getSelectUserOptions()}
                value={this.state.userId}
                isSearchable
                onChange={this.addUsersToList}
                clearable={false}
                inputProps={{ id: 'note-for-input' }}
                className="select-component"
                isMulti
                placeholder=""
              />
              <div className="unfollowed-users-list">
                {this.state.selectedUsers.map(user => (
                  <div
                    key={user.id}
                    className="user-row"
                    onMouseEnter={() => this.handleHoverOverUser(user.id)}
                    onMouseLeave={() => this.handleStopHoverOverUser(user.id)}
                  >
                    <div
                      className={`${
                        this.state.hoverOverUserId === user.id ? 'show' : ''
                      } pull-left cross`}
                      onClick={() => this.removeFromList(user.id)}
                    />
                    <div className="user-details">
                      <img className="user-img" src={user.image_url} alt={this.state.firstName} />
                      <span className="user-name">{user.name}</span>
                      <span className="user-team">
                        {user.role ? `${user.role}, ` : ''}
                        {user.team}
                      </span>
                    </div>
                    <div className="switch-wrap all-posts">
                      <SwitchButton
                        turnedOn={
                          !this.props.users[
                            this.props.users.map(selectedUser => selectedUser.id).indexOf(user.id)
                          ].unfollowed
                        }
                        onClick={() => this.unfollowUser(user.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="notifications-group-wrap">
            <h3 className="main-header">Weekly digest</h3>
            <div className="digest-notification">
              <div className="digest-details">
                Every Monday we'll let you know about the general state of your team
              </div>
              <div className="switch-wrap digest-switch">
                <SwitchButton
                  turnedOn={this.props.weeklyDigestSettings}
                  onClick={() => this.props.unfollowWeeklyDigest()}
                />
              </div>
            </div>
          </div>
          <EmailNotificationsTooltip id="email-tooltip" />
          <MobileNotificationsTooltip id="mobile-tooltip" />
          <MentionNotificationsTooltip id="mention-tooltip" />
          <CommentsNotificationsTooltip id="comments-tooltip" />
        </div>
      </form>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationSettings));
