import React from 'react';
import { connect } from 'react-redux';

import Buttons from 'components/vibes/createForms/partials/Buttons';
import { CONNECT_BUTTONS } from './buttons';

const FeedbackButtons = ({
  onClick,
  toggled,
  showMediaDropzone,
  showFileDropzone,
  shownFileDropzone,
  shownMediaDropzone,
}) => {
  return (
    <Buttons
      onClick={onClick}
      toggled={toggled}
      buttons={CONNECT_BUTTONS()}
      showMediaDropzone={showMediaDropzone}
      showFileDropzone={showFileDropzone}
      shownMediaDropzone={shownMediaDropzone}
      shownFileDropzone={shownFileDropzone}
    />
  );
};

const mapStateToProps = state => ({
  isLeadOrAdmin:
    state.usersDomain.user &&
    Boolean(
      state.usersDomain.user.isAccountAdmin || state.usersDomain.user.formalLeadTeamsIds.length
    ),
});
export default connect(mapStateToProps)(FeedbackButtons);
