import styled from '@emotion/styled';
import React from 'react';
import DateRange from 'components/vibes/team-vibe-show/partials/DateRange';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import Sidebar from 'components/organisation/partials/Sidebar';

const Wrap = styled.div`
  margin-bottom: 38px;
  ${props => props.css}
`;

const FeedDateRange = ({ location, isSchedule }) => {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  return (
    <Wrap>
      <Sidebar.Header>Date range</Sidebar.Header>
      <DateRange
        queryString={params}
        tabLink={location.pathname}
        hideThisLastWeek={false}
        isFeed
        portal
        isSchedule={isSchedule}
      />
    </Wrap>
  );
};

export default withRouter(FeedDateRange);
