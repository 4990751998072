import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as HomeIcon } from 'components/layouts/Navigation/feed_blue.svg';

const Wrapper = styled.div`
  padding: 8px 0;
  font-weight: 700;
`;
const HomeIconStyled = styled(HomeIcon)`
  width: 22px;
  height: 22px;
  margin-right: 15px;
  vertical-align: bottom;
`;
const HomeHeader = () => (
  <Wrapper>
    <HomeIconStyled />
    Home
  </Wrapper>
);
export default HomeHeader;
