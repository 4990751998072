/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React, { useRef, useState } from 'react';
import TextInput from 'react-autocomplete-input';
import { AUTOCOMPLETE_NATIVE_VALUE_RESET } from 'helpers';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

const Wrap = styled.div`
  margin-bottom: 15px;
`;

const InputWrap = styled.div``;

const TagError = styled.div`
  color: #cd3c40;
  margin-top: 10px;
`;

const ValueTag = styled.div`
  padding: 8px 30px 8px 18px;
  color: white;
  background: #11a9ff;
  position: relative;
  border-radius: 18px;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  display: inline-block;
  margin: 18px 10px 5px 0px;
`;

const ValueCross = styled.div`
  cursor: pointer;
  position: absolute;
  display: inline-block;
  height: 10px;
  width: 10px;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  &:before,
  &:after {
    border-radius: 2px;
    width: 10px;
    height: 2px;
    background: white;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

const ValuesPicker = ({ placeholder, initialValues, values, setValues }) => {
  const ref = useRef(null);

  const [value, setValue] = useState(null);

  const [error, setError] = useState(false);

  const removeValueHandler = value => {
    const valueIndex = values.indexOf(value);
    const chosenHelperArray = values;
    chosenHelperArray.splice(valueIndex, 1);
    setValues(chosenHelperArray || []);
  };

  return (
    <Wrap>
      <InputWrap>
        <TextInput
          ref={ref}
          trigger=""
          Component="input"
          placeholder={placeholder}
          options={initialValues ? initialValues.filter(value => values.indexOf(value) < 0) : []}
          onChange={e => {
            if (e.length < 70) {
              setValue(e);
              setError(false);
            } else {
              setError(true);
            }
          }}
          value={value}
          onBlur={e => {
            const element = e.target;
            setTimeout(() => {
              const val = element.value.trim();
              AUTOCOMPLETE_NATIVE_VALUE_RESET(element);
              if (val !== '' && values.indexOf(val) === -1) {
                setValues([...values, val]);
                setValue('');
                setError(false);
              } else {
                setValue('');
                setError(false);
              }
            }, 200);
          }}
          onKeyDown={e => {
            const val = e.target.value.trim();
            if (e.key === 'Enter' && val !== '' && values.indexOf(val) === -1) {
              e.preventDefault();
              setValues([...values, val]);
              setValue('');
              setError(false);
              AUTOCOMPLETE_NATIVE_VALUE_RESET(ReactDOM.findDOMNode(ref.current).firstChild);
            }
          }}
        />
      </InputWrap>
      {error && <TagError>The maximum length of the tag is 70 characters.</TagError>}
      <div>
        {values.map((value, index) => (
          <ValueTag key={index}>
            {value}
            <ValueCross onClick={() => removeValueHandler(value)} />
          </ValueTag>
        ))}
      </div>
    </Wrap>
  );
};

ValuesPicker.propTypes = {
  values: PropTypes.any.isRequired,
  setValues: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  initialValues: PropTypes.array.isRequired,
};

export default ValuesPicker;
