import React, { Component } from 'react';
import { AWS_S3_URL } from '../../../constants';

class ProfileToggle extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.props.onClick(e);
  }

  render() {
    return (
      <a onClick={this.handleClick}>
        <img
          src={`${AWS_S3_URL}public/images/icons/new_settings.svg`}
          alt="settings"
          id="settings-icon"
        />
        <span className="primary-sidebar-bottom-label">Settings</span>
      </a>
    );
  }
}

export default ProfileToggle;
