/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import Page from 'components/layouts/Page';
import { ReactComponent as Insights } from 'components/layouts/Navigation/insights.svg';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { HEADERS } from 'helpers';
import { APP_URL } from 'constants.js';
import TeamSelect from 'components/vibes/team-vibe-show/partials/TeamSelect';
import DateRange from 'components/vibes/team-vibe-show/partials/DateRange';
import DetailedFeedbackData from 'components/vibes//DetailedFeedback';
import styled from '@emotion/styled';
import Can from 'rbac/Can';
import { insights } from 'rbac/plans';
import UpgradeModal, { grayFilterCss } from 'pages/Insights/UpgradeModal';
import { css, jsx } from '@emotion/core';
import SampleContentBanner from '../shared/SampleContentBanner';

const WithoutAccessInfo = styled.div`
  padding: 50px 20px;
  text-align: center;
`;

const defaultFeedback = [...Array(10)].fill('').map((item, index) => ({
  category: index + 1,
  data: [],
}));

const DetailedFeedback = props => {
  const [chartType, setChartType] = useState('horizontal');
  const [usersCount, setUsersCount] = useState(0);
  const [detailedFeedbackData, setDetailedFeedbackData] = useState(defaultFeedback);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    const headers = HEADERS();
    const response = await fetch(
      `${APP_URL}/vibes/detailed_feedback?${qs.stringify(props.queryString)}`,
      { method: 'get', headers }
    );
    const json = await response.json();
    if (!json.enoughMembers) {
      setDetailedFeedbackData(defaultFeedback);
      if (json.error) {
        setDetailedFeedbackData(defaultFeedback);
        setError({ flashName: json.flashName, message: json.message });
      }
      return;
    }
    setDetailedFeedbackData(Object.values(json.detailedFeedback));
    setUsersCount(json.usersCount);
    setError(null);
  };

  const handleResize = () => {
    if (chartType === 'vertical' && window.innerWidth < 1080) {
      setChartType('horizontal');
    }
    if (chartType === 'horizontal' && window.innerWidth > 1080) {
      setChartType('vertical');
    }
  };

  window.onresize = debounce(handleResize, 200);

  useEffect(() => {
    if (error) {
      props.updateFlash(error.flashName, error.message);
    } else {
      props.closeFlash();
    }
  }, [error]);

  useEffect(() => {
    handleResize();
    return () => {
      window.onresize = null;
    };
  }, []);

  useEffect(() => {
    props.closeFlash();
    fetchData();
  }, [props.queryString.team, props.queryString.period]);

  const canSeeFeedback =
    props.user.isAccountAdmin ||
    props.user.formalLeadTeamsWithChildrenIds.includes(
      props.teams.find(team => team.id == props.queryString.team).id
    );
  const team = props.teams.find(team => team.id == props.queryString.team);
  return (
    <div
      className="team-vibe-dashboard"
      css={css`
        position: relative;
      `}
    >
      <SampleContentBanner queryString={props.queryString} />
      <Page.Header icon={Insights}>Triggers</Page.Header>
      <Can perform={insights.accessTriggers} no={() => <UpgradeModal />} />
      <Can perform={insights.accessTriggers}>
        {canSeeTriggers => (
          <Page.Paper cssProps={!canSeeTriggers && grayFilterCss}>
            <div className="team-vibe-dashboard">
              <div className="top-controls-wrap">
                <TeamSelect
                  queryString={props.queryString}
                  tabLink={props.location.pathname}
                  onlyLeadTeams={!props.user.isAccountAdmin}
                />
                <DateRange
                  queryString={props.queryString}
                  tabLink={props.location.pathname}
                  hideThisLastWeek={false}
                />
              </div>
            </div>
            {canSeeFeedback ? (
              <DetailedFeedbackData
                teamName={team ? team.name : ''}
                detailedFeedbackData={detailedFeedbackData}
              />
            ) : (
              <WithoutAccessInfo>You don't have access to this team triggers.</WithoutAccessInfo>
            )}
          </Page.Paper>
        )}
      </Can>
    </div>
  );
};

const mapStateToProps = state => ({
  plan: state.plansDomain.plan,
  teams: state.teamsDomain.teams,
  user: state.usersDomain.user,
});
export default connect(mapStateToProps)(DetailedFeedback);
