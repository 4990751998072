import DOMAINS from '../domains';

export const TEAM_ACTION_TYPES = {
  FETCH_TEAMS: 'FETCH_TEAMS',
  RECEIVE_TEAMS: 'RECEIVE_TEAMS',
};
export const TEAM_ACTIONS = {
  fetchTeams: (successCallback = () => {}) => ({
    domain: DOMAINS.TEAMS,
    type: TEAM_ACTION_TYPES.FETCH_TEAMS,
    payload: { successCallback },
  }),
  receiveTeams: json => ({
    domain: DOMAINS.TEAMS,
    type: TEAM_ACTION_TYPES.RECEIVE_TEAMS,
    payload: json,
  }),
};
