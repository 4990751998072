import React from 'react';
import styled from '@emotion/styled';
import { AWS_S3_URL, APP_URL } from 'constants.js';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import { POST_ACTIONS } from 'redux/actions/postActions';
import { clearActionBody, setAction, setShowActionModal } from 'redux/app/actions';
import { connect } from 'react-redux';
import { CHECK_UNAUTHORIZED, HEADERS } from 'helpers';
import PostponeButton from '../../onboarding/partials/PostponeButton';
import OnboardingButton from '../../onboarding/partials/OnboardingButton';

const Header = styled.h3`
  font-size: 16px;
  line-height: 19px;
  padding-right: 0;
  font-weight: bold;
  padding-bottom: 22px;
`;

const Image = styled.img`
  width: 175px;
  height: 148px;
  margin-right: 24px;
`;

const Wrapper = styled.div`
  display: block;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 31px;
  margin-left: 7px;
  a.btn {
    line-height: 36px;
    margin-right: 26px;
  }
`;

const dismissPost = (id, removeDismissedPost) => {
  const headers = HEADERS();
  const url = `/post/dismiss/${id}`;
  fetch(APP_URL + url, { method: 'put', headers })
    .then(response => CHECK_UNAUTHORIZED(response))
    .then(response => {
      response.json().then(() => {
        removeDismissedPost(id);
      });
    });
};

const MeetingAlertTile = ({
  post,
  removeDismissedPost,
  setAction,
  setShowActionModal,
  clearActionBody,
}) => (
  <div className="col-lg-6 col-xs-12 post-tile-wrap initial-hide">
    <div className="post-tile app-alert-tile">
      <div className="post-tile-body app-alert-body ">
        <Header>{post.content}</Header>
        <Image src={`${AWS_S3_URL}public/images/meeting.png`} />
        <Wrapper>
          <OnboardingButton
            label="Set up"
            submit
            onClick={e => {
              e.preventDefault();
              setShowActionModal(true);
              clearActionBody();
              setAction('create-note-meeting-schedule');
            }}
            width={83}
            height={38}
            top={10}
          />
          <PostponeButton
            text="Maybe later"
            onClick={() => dismissPost(post.id, removeDismissedPost)}
          />
        </Wrapper>
      </div>
    </div>
  </div>
);

const mapDispatchToProps = dispatch => ({
  removeDismissedPost: id => dispatch(POST_ACTIONS.removeDeletedPost(id)),
  setAction,
  setShowActionModal,
  clearActionBody,
});

export default connect(null, mapDispatchToProps)(MeetingAlertTile);
