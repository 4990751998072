import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Select from 'react-select';

export const HeaderWrap = styled.div`
  display: inline-flex;
`;

export const SidebarHeader = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
  margin-top: 30px;
`;

export const ButtonContainer = styled.div`
  margin-top: 18px;
`;

export const SidebarLabel = styled.label`
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  line-height: 17px;
  padding: 11px 14px;
  min-height: 42px;
  margin-bottom: 4px;
  color: #323232;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  cursor: pointer;
  background: #ffffff;
  width: 100%;
`;

export const SidebarInput = styled.input`
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;

  :checked + label {
    background: #11a9ff;
    color: #ffffff;
    font-weight: bold;
    border: 1px solid #11a9ff;
  }

  &:disabled + label {
    color: #a6a6a6 !important;
  }
`;

export const SidebarCheckbox = styled.input`
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;

  :checked + label {
    background: #11a9ff;
    color: #ffffff;
    font-weight: bold;
    border: 1px solid #11a9ff;
  }

  &:disabled + label {
    color: #a6a6a6 !important;
  }
`;

export const ComparisonButton = styled(Link)`
  display: inline-block;
  background: #11a9ff;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 36px;
  color: #ffffff;
  padding: 0 20px;
  margin-left: 36px;
  cursor: pointer;
`;

export const StyledSelect = styled(Select)`
  margin-top: 14px;
  .Select-control {
    height: 42px;
    font-size: 14px;
    border: 1px solid #e6e6e6;
  }

  .Select-control .Select-value {
    line-height: 42px;
  }
`;

export const SwitchContainer = styled.div`
  flex-direction: row;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: none;
  @media (min-width: 1200px) {
    display: flex;
    margin-left: 30px;
  }
`;
