import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';
import qs from 'qs';
import { CHECK_UNAUTHORIZED, HEADERS, PAGE_WRAPPER_CLASSES } from 'helpers';
import { APP_URL } from 'constants.js';
import { PLAN_ACTIONS } from 'redux/actions/planActions';
import { TEAM_ACTIONS } from 'redux/actions/teamActions';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import CompanyIssues from './issues_view/CompanyIssues';
import Loader from '../shared/Loader';
import CompanyView from './view_mode/CompanyView';
import CompanySetup from './setup_mode/CompanySetup';

const mapDispatchToProps = dispatch => ({
  fetchTeams: () => dispatch(TEAM_ACTIONS.fetchTeams()),
  showUpgradeModal: () => dispatch(PLAN_ACTIONS.showUpgradeModal()),
  setWasUserAdded: wasUserAdded => dispatch(ONBOARDING_ACTIONS.setWasUserAdded(wasUserAdded)),
  setWasTeamAdded: wasTeamAdded => dispatch(ONBOARDING_ACTIONS.setWasTeamAdded(wasTeamAdded)),
  setWasUserMoved: wasUserMoved => dispatch(ONBOARDING_ACTIONS.setWasUserMoved(wasUserMoved)),
  setWasCompanyStructureFetched: wasCompanyStructureFetched =>
    dispatch(ONBOARDING_ACTIONS.setWasCompanyStructureFetched(wasCompanyStructureFetched)),
});

const mapStateToProps = state => ({
  thumbColors: state.spacesDomain.thumbColors,
  wasCompanyStructureFetched: state.onboardingDomain.wasCompanyStructureFetched,
  plan: state.plansDomain.plan,
});

class CompanyStructure extends Component {
  modes = {
    setup: 'setup',
    view: 'view',
    issues: 'issues',
  };

  state = {
    mode: this.modes.view,
    fetchComplete: false,
    teams: [],
  };

  initialFetch = async (initial = true, mode = this.modes.setup) => {
    const headers = HEADERS();
    return fetch(`${APP_URL}/company_structure`, {
      method: 'get',
      headers,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          jsonResponse.filter(team => {
            team.parent_ids = team.parent_ids ? JSON.parse(team.parent_ids) : [];
          });
          this.setState(
            initial
              ? {
                  fetchComplete: true,
                  teams: jsonResponse,
                }
              : {
                  teams: jsonResponse,
                }
          );
          this.props.setWasCompanyStructureFetched(true);
        });
      });
  };

  componentDidMount() {
    PAGE_WRAPPER_CLASSES(['company-structure-alter-wrapper']);
    this.initialFetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if (!params.mode) {
      params.mode = this.state.mode;
      this.props.history.push(`/company_structure?${qs.stringify(params)}`);
    }
    if (params.mode !== this.state.mode) {
      if (prevState.mode !== this.state.mode) {
        params.mode = this.state.mode;
        if (this.state.mode !== this.modes.setup) {
          delete params.setupStep;
        }
      } else {
        if (params.mode !== this.modes.setup) {
          delete params.setupStep;
        }
        this.setState({ mode: params.mode });
      }
      this.props.history.push(`/company_structure?${qs.stringify(params)}`);
    }
  }

  componentWillUnmount() {
    this.props.setWasCompanyStructureFetched(false);
    PAGE_WRAPPER_CLASSES();
  }

  addUsers = users => {
    this.props.setWasUserAdded(true);
    this.setState({
      teams: this.state.teams.map(team => {
        if (team.parent_ids.length !== 0) return team;
        return {
          ...team,
          users: [
            ...users.map(user => ({
              ...user,
              is_lead: false,
            })),
            ...team.users,
          ],
        };
      }),
    });
  };

  removeUsers = ids => {
    const data = new FormData();
    const headers = HEADERS();
    data.append('_method', 'DELETE');
    ids.forEach(id => {
      data.append('user_ids[]', id);
    });

    fetch(`${APP_URL}/users`, {
      method: 'post',
      headers,
      body: data,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (response.ok) {
          this.setState({
            teams: this.state.teams.map(stateTeam => {
              return {
                ...stateTeam,
                users: stateTeam.users.filter(user => ids.indexOf(user.id) < 0),
                team_leads: stateTeam.team_leads.filter(lead => ids.indexOf(lead.id) < 0),
              };
            }),
          });
        }
      });
  };

  updateUserRole = (user, team, attach) => {
    const data = new FormData();
    const headers = HEADERS();
    data.append('_method', 'PUT');
    data.append('user_id', user.id);
    data.append('team_id', team.id);
    data.append('attach', attach);

    fetch(`${APP_URL}/users/update_role`, {
      method: 'post',
      headers,
      body: data,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (response.ok) {
          const teamsCopy = JSON.parse(JSON.stringify(this.state.teams));
          const teamIndex = teamsCopy.findIndex(stateTeam => stateTeam.id === team.id);
          teamsCopy[teamIndex].users = team.users.map(member => {
            if (member.id === user.id) {
              return {
                ...member,
                is_lead: attach,
              };
            }
            return {
              ...member,
            };
          });
          let teamLeads = teamsCopy[teamIndex].team_leads;
          if (attach) {
            teamLeads.push(user);
          } else {
            teamLeads = teamLeads.filter(function (lead) {
              return lead.id !== user.id;
            });
          }
          teamsCopy[teamIndex].team_leads = teamLeads;

          this.setState({ teams: teamsCopy });
        }
      });
  };

  makeUsersLead = team => {
    const data = new FormData();
    const headers = HEADERS();
    data.append('_method', 'PUT');
    team.users.forEach(user => {
      if (user.is_selected) data.append('user_ids[]', user.id);
    });
    data.append('team_id', team.id);

    fetch(`${APP_URL}/users/make_lead`, {
      method: 'post',
      headers,
      body: data,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (response.ok) {
          const teamsCopy = JSON.parse(JSON.stringify(this.state.teams));
          const teamIndex = teamsCopy.findIndex(stateTeam => stateTeam.id === team.id);
          const nonLeadIds = [];
          teamsCopy[teamIndex].users = team.users.map(user => {
            const isLead = user.is_selected ? !user.is_lead : user.is_lead;
            if (!isLead) nonLeadIds.push(user.id);
            const object = {
              ...user,
              is_lead: isLead,
            };
            delete object.is_selected;
            delete object.marked_to_invite;
            return object;
          });
          const teamLeads = team.users
            .filter(lead => !nonLeadIds.includes(lead.id))
            .concat(team.team_leads.filter(lead => !nonLeadIds.includes(lead.id)));
          teamsCopy[teamIndex].team_leads = teamLeads.filter(
            (item, index, self) => index === self.findIndex(t => t.id === item.id)
          );
          // ---Harmless antipattern - child components' state gets modified here without setState to avoid tricky callbacks
          // ---It's later immediately used for setting state in valid way inside child's componentWillReceiveProps
          team.users = team.users.map(user => ({
            ...user,
            is_selected: false,
          }));
          this.setState({ teams: teamsCopy });
        }
      });
  };

  moveUser = (team, helperVariables) => {
    const data = new FormData();
    const headers = HEADERS();
    data.append('_method', 'PUT');
    helperVariables.draggedUsersData.forEach(user => data.append('user_ids[]', user.id));
    data.append('team_id', team.id);
    // ---To make moving animation smooth, we need to move user right away and then - in case of (unlikely) backend error - revert that change
    const preservedUsersState = JSON.parse(JSON.stringify(helperVariables.draggedUsersData));
    fetch(`${APP_URL}/users/change_team`, {
      method: 'post',
      headers,
      body: data,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        this.props.setWasUserMoved(true);
        if (!response.ok) {
          this.setState({
            teams: this.state.teams.map(stateTeam => {
              if (stateTeam.id === preservedUsersState[0].team_id) {
                return {
                  ...stateTeam,
                  users: [...stateTeam.users, ...preservedUsersState],
                };
              }
              if (stateTeam.id === team.id) {
                return {
                  ...stateTeam,
                  users: stateTeam.users.filter(
                    user => !helperVariables.draggedUsersData.map(user => user.id).includes(user.id)
                  ),
                };
              }
              return stateTeam;
            }),
          });
        }
      });
    this.setState({
      teams: this.state.teams.map(stateTeam => {
        if (stateTeam.id === helperVariables.draggedUsersData[0].team_id) {
          return {
            ...stateTeam,
            users: stateTeam.users.filter(
              user => !helperVariables.draggedUsersData.map(user => user.id).includes(user.id)
            ),
          };
        }
        if (stateTeam.id === team.id) {
          const dataCopy = helperVariables.draggedUsersData.map(user => {
            const object = {
              ...user,
              is_lead: false,
              team_id: team.id,
            };
            delete user.is_selected;
            delete user.marked_to_invite;
            return object;
          });

          return {
            ...stateTeam,
            users: [...stateTeam.users, ...dataCopy],
          };
        }
        return stateTeam;
      }),
    });
  };

  moveSelectedUsers = team => {
    // ---For now this function is used to move users to main team (and to revert it - in  case of error)
    // ---We can scale it later to work for all teams if client requests such feature
    const data = new FormData();
    const headers = HEADERS();
    data.append('_method', 'PUT');
    data.append('team_id', this.state.teams.find(team => team.parent_ids.length === 0).id);
    team.users.forEach(user => {
      if (user.is_selected) data.append('user_ids[]', user.id);
    });

    const preservedUsersState = JSON.parse(
      JSON.stringify(team.users.filter(user => user.is_selected))
    );
    fetch(`${APP_URL}/users/change_team`, {
      method: 'post',
      headers,
      body: data,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (!response.ok) {
          this.setState({
            teams: this.state.teams.map(stateTeam => {
              if (stateTeam.parent_ids.length === 0) {
                return {
                  ...stateTeam,
                  users: stateTeam.users.filter(
                    user => !preservedUsersState.find(preservedUser => preservedUser.id === user.id)
                  ),
                };
              }
              if (stateTeam.id === team.id) {
                return {
                  ...stateTeam,
                  users: [...preservedUsersState, ...stateTeam.users],
                };
              }
              return stateTeam;
            }),
          });
        }
      });
    this.setState({
      teams: this.state.teams.map(stateTeam => {
        if (stateTeam.parent_ids.length === 0) {
          return {
            ...stateTeam,
            users: [
              ...stateTeam.users,
              ...team.users
                .filter(user => user.is_selected)
                .map(user => {
                  const object = {
                    ...user,
                    team_id: stateTeam.id,
                    is_lead: false,
                  };
                  delete object.is_selected;
                  delete object.marked_to_invite;
                  return object;
                }),
            ],
          };
        }
        if (stateTeam.id === team.id) {
          return {
            ...stateTeam,
            users: [
              ...team.users
                .filter(user => !user.is_selected)
                .map(user => {
                  const object = { ...user };
                  delete object.is_selected;
                  delete object.marked_to_invite;
                  return object;
                }),
            ],
          };
        }
        return stateTeam;
      }),
    });
  };

  addTeam = (name, parentTeam) => {
    const headers = HEADERS();
    headers.append('Content-Type', 'application/json');
    fetch(`${APP_URL}/spaces/formal`, {
      method: 'post',
      headers,
      body: JSON.stringify({
        parent_id: parentTeam.id,
        name,
      }),
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        const { ok } = response;
        response.json().then(jsonResponse => {
          if (ok) {
            this.props.setWasTeamAdded(true);
            parentTeam.childrenUnfolded = true;
            this.props.fetchTeams();
            this.setState({ teams: [...this.state.teams, jsonResponse] });
          }
        });
      });
  };

  moveTeam = (parentTeam, helperVariables) => {
    const teamsCopy = JSON.parse(JSON.stringify(this.state.teams));
    const preservedTeamsState = JSON.parse(JSON.stringify(this.state.teams));
    const updateParents = (newChild, parent) => {
      const index = teamsCopy.findIndex(stateTeam => stateTeam.id === newChild.id);
      teamsCopy[index].parent_id = parent.id;
      teamsCopy[index].parend_ids = [...parent.parent_ids, parent.id];
      const helper = teamsCopy.splice(index, 1);
      teamsCopy.push(...helper); // ---Moved team has to be placed at the end of teams array so it will also get rendered as last child after moving
      teamsCopy
        .filter(team => team.parent_id === newChild.id)
        .forEach(team => {
          updateParents(team, newChild);
        });
    };
    updateParents(helperVariables.draggedTeamData, parentTeam);

    const data = new FormData();
    const headers = HEADERS();
    data.append('_method', 'PUT');
    data.append('new_parent_id', parentTeam.id);

    fetch(`${APP_URL}/spaces/${helperVariables.draggedTeamData.id}/change_parent`, {
      method: 'post',
      headers,
      body: data,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (!response.ok) {
          this.props.fetchTeams();
          this.setState({ teams: preservedTeamsState });
        }
      });

    this.setState({ teams: teamsCopy });
  };

  sendInvitations = ids => {
    const headers = HEADERS();
    const data = new FormData();
    data.append('_method', 'PUT');
    ids.forEach(id => data.append('user_ids[]', id));
    fetch(`${APP_URL}/users/invite`, {
      method: 'post',
      headers,
      body: data,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        const teamsCopy = JSON.parse(JSON.stringify(this.state.teams));
        teamsCopy
          .reduce((a, b) => [...a, ...b.users], [])
          .filter(user => ids.some(id => id === user.id))
          .forEach(user => (user.state = 'invited'));
        this.setState({ teams: teamsCopy });
      });
  };

  deleteSpace = async team => {
    this.setState({ fetchComplete: false });
    const headers = HEADERS();
    const response = await fetch(`${APP_URL}/team_with_users/${team.id}`, {
      method: 'delete',
      headers,
    });
    const json = await response.json();
    await this.initialFetch(false);
    this.props.fetchTeams();
    this.props.updateFlash(json.flashName, json.message);
    this.setState({ fetchComplete: true });
  };

  setupMode = () => {
    this.setState({ mode: this.modes.setup });
  };

  viewMode = () => {
    this.setState({ mode: this.modes.view });
  };

  issuesMode = () => {
    this.setState({ mode: this.modes.issues });
  };

  render() {
    if (this.state.mode === this.modes.view && this.props.plan === 'free') {
      return <Redirect to="/spaces" />;
    }
    let view = '';
    if (this.state.fetchComplete) {
      switch (this.state.mode) {
        case this.modes.setup:
          view = (
            <CompanySetup
              plan={this.props.plan}
              showUpgradeModal={this.props.showUpgradeModal}
              teams={this.state.teams}
              viewMode={this.viewMode}
              addUsers={this.addUsers}
              addCSVUsers={this.addCSVUsers}
              moveUser={this.moveUser}
              removeUsers={this.removeUsers}
              moveSelectedUsers={this.moveSelectedUsers}
              makeUsersLead={this.makeUsersLead}
              updateUserRole={this.updateUserRole}
              addTeam={this.addTeam}
              moveTeam={this.moveTeam}
              sendInvitations={this.sendInvitations}
              deleteSpace={this.deleteSpace}
            />
          );
          break;
        case this.modes.view:
          view = (
            <CompanyView
              teams={this.state.teams}
              setupMode={this.setupMode}
              issuesMode={this.issuesMode}
              updateFlash={this.props.updateFlash}
              closeFlash={this.props.closeFlash}
            />
          );
          break;
        case this.modes.issues:
          view = (
            <CompanyIssues
              teams={this.state.teams}
              viewMode={this.viewMode}
              sendInvitations={this.sendInvitations}
              moveUser={this.moveUser}
            />
          );
      }
    } else {
      view = <Loader />;
    }
    return <div id="company-structure">{view}</div>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyStructure);
