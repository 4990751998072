import React, { useEffect } from 'react';
import LoadingOverlay from 'components/signup/LoadingOverlay';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { HEADERS } from 'helpers';
import { APP_URL } from 'constants.js';

const SlackRedirect = ({ history }) => {
  const handleSlack = async location => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (location.search && query.code) {
      const headers = HEADERS();
      const response = await fetch(`${APP_URL}/integrate_with_slack${location.search}`, {
        method: 'get',
        headers,
      });
      if (response.status === 401) {
        history.replace('/');
      }

      if (query.callback) {
        history.push(query.callback.replace('connect_slack=true', ''));
      } else {
        history.push('/');
      }
    } else {
      history.push('/');
    }
  };
  useEffect(() => {
    handleSlack(history.location);
  }, []);

  return (
    <div className="welcome">
      <LoadingOverlay toggled message="" height="100%" />
    </div>
  );
};

export default withRouter(SlackRedirect);
