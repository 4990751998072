import React, { Component } from 'react';

import { connect } from 'react-redux';
import Auxiliary from '../../layouts/Auxiliary';
import { COMMENT_ACTIONS } from '../../../redux/actions/commentActions';
import CommentItem from './CommentItem';

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  post: state.postsDomain.singlePost,

  fetchingComments: state.postsDomain.fetchingComments,
});

const mapDispatchToProps = dispatch => ({
  fetchComments: (postId, loadedCount) =>
    dispatch(COMMENT_ACTIONS.fetchComments(postId, loadedCount, true)),
});

class PostComments extends Component {
  render() {
    return (
      <Auxiliary>
        <ul className="comment-section">
          {this.props.post.comments.map(comment => (
            <CommentItem key={comment.id} comment={comment} />
          ))}
        </ul>
        {this.props.post.comments.length < this.props.post.total_comments_count ? (
          <div
            className="post-comments-unfold"
            onClick={() => {
              if (!this.props.fetchingComments)
                this.props.fetchComments(this.props.post.id, this.props.post.comments.length);
            }}
          >
            View all {this.props.post.total_comments_count} comments
          </div>
        ) : (
          ''
        )}
      </Auxiliary>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostComments);
