import PropTypes from 'prop-types';
import React from 'react';
import { getStringFromNumber } from 'helpers';

const Error = ({ postErrors, errorContentOptions, index }) => {
  const error = errorContentOptions
    ? errorContentOptions[`option${index + 1}`]
    : postErrors[`options.${index}`][0];
  return (
    <div className="fix-tile-error" key={index}>
      <span className="text-danger">{error}</span>
    </div>
  );
};

const PollOptions = ({ option, index, changeFieldContent, postErrors, errorContentOptions }) => {
  return (
    <>
      <div className="poll-option" key={index}>
        <input
          type="text"
          value={option}
          onChange={e => changeFieldContent(e, `option${index + 1}`)}
          placeholder={`Response option #${index + 1}`}
        />
      </div>
      {(postErrors && postErrors[`options.${index}`]) ||
        (errorContentOptions && errorContentOptions[`option${index + 1}`] && (
          <Error postErrors={postErrors} errorContentOptions={errorContentOptions} index={index} />
        ))}
    </>
  );
};

PollOptions.propTypes = {
  changeFieldContent: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  option: PropTypes.string.isRequired,
  postErrors: PropTypes.array.isRequired,
};

export default PollOptions;
