import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { toggleUserModal } from 'redux/app/actions';
import styled from '@emotion/styled';
import PostDetails from 'components/shared/posts/PostDetails';
import ShoutoutRecipientsImage from './ShoutoutRecipientsImage';

const mapStateToProps = state => ({
  user: state.usersDomain.user,
});

const mapDispatchToProps = dispatch => ({
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
});

const ConsolidatedShoutoutsWrap = styled.div`
  box-shadow: 0 -17px 0px -11px #fadd6a;
`;

const ShoutoutContent = styled.h3`
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #323232;
  margin-bottom: 30px;
`;

const ViewAllBtn = styled.a`
  display: inline-block;
  background: #11a9ff;
  padding: 10px 18px;
  border-radius: 4px;
  font-size: 15px;
  line-height: 16px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 30px;
  &:hover {
    color: #fff;
  }
`;

class ConsolidatedShoutoutsTile extends Component {
  render() {
    const { showSpaceInfo, post } = this.props;

    let content = (
      <>
        <div className="shoutout-receiver">
          <ShoutoutRecipientsImage recipients={post.recipients} postId={post.id} />
          <ShoutoutContent>
            {post.value_winners_date ? (
              <span>
                Last month’s Company Value champions
                <br />
                have been announced!
              </span>
            ) : (
              post.content
            )}
          </ShoutoutContent>
          <ViewAllBtn>View all</ViewAllBtn>
        </div>
        <PostDetails post={post} showSpaceInfo={showSpaceInfo} />
      </>
    );

    if (post.id > 0) {
      content = (
        <Link
          to={
            post.value_winners_date
              ? `/champions?month=${post.value_winners_date.month}&year=${post.value_winners_date.year}`
              : `/shoutouts`
          }
        >
          {content}
        </Link>
      );
    }

    return (
      <ConsolidatedShoutoutsWrap className="col-lg-6 col-xs-12 post-tile-wrap initial-hide">
        <div className="post-tile">
          <div className="post-tile-body">{content}</div>
        </div>
      </ConsolidatedShoutoutsWrap>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConsolidatedShoutoutsTile));
