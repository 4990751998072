import React from 'react';
import { SLACK_CLIENT_ID } from 'constants.js';
import qs from 'qs';
import { connect } from 'react-redux';

export const getSlackUrl = () => {
  const callback = encodeURIComponent(window.location.pathname + window.location.search);

  const params = {
    scope: 'chat:write,commands,im:history,im:read,team:read,users:read,users:read.email',
    user_scope: '',
    client_id: SLACK_CLIENT_ID,
    redirect_uri: `${window.location.origin}/slack_redirect?callback=${callback}`,
  };

  return `https://slack.com/oauth/v2/authorize?${qs.stringify(params)}`;
};

const SlackButton = ({ shouldSeeSlackButton }) => {
  return shouldSeeSlackButton ? (
    <a href={getSlackUrl()}>
      <img
        alt="Add to Slack"
        height="40"
        width="139"
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
      />
    </a>
  ) : null;
};

const mapStateToProps = state => ({
  shouldSeeSlackButton: state.usersDomain.user?.shouldSeeSlackButton,
});

export default connect(mapStateToProps)(SlackButton);
