import React from 'react';
import { EVENTS, STATUS } from 'react-joyride';
import { setAction, setShowActionModal, setShowAddUserModal } from 'redux/app/actions';
import { connect } from 'react-redux';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import { withRouter } from 'react-router-dom';
import { USER_ACTIONS } from 'redux/actions/userActions';
import { SETUP_STEPS } from 'onboardingSteps';
import JoyrideWrapper from './JoyrideWrapper';

class SetupTour extends React.Component {
  state = {
    run: false,
    steps: SETUP_STEPS,
  };

  componentDidMount() {
    this.props.setWasUserAdded(false);
    this.props.setWasTeamAdded(false);
    this.props.setWasUserMoved(false);
    this.props.setWasAddTeamButtonClicked(false);
    this.finishSetupStep(this.props.setupTourStep);
    if (this.props.wasCompanyStructureFetched) {
      this.setState({ run: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { run, steps } = this.state;
    if (
      this.props.wasCompanyStructureFetched &&
      this.props.wasCompanyStructureFetched !== prevProps.wasCompanyStructureFetched
    ) {
      this.setState({ run: true });
    }

    if (steps[this.props.setupTourStep]?.key === 'addTeamStep') {
      this.props.toggleAddChildTeam(false)
    }

    if (steps[this.props.setupTourStep]?.key === 'addTeamStep' ||
      steps[this.props.setupTourStep]?.key === 'firstUserTileStep') {
      setTimeout(() => {
        document.querySelector(steps[this.props.setupTourStep].target).scrollIntoView();
      }, 400);
    }

    if(steps[this.props.setupTourStep]?.key === 'teamLeadStep' ||
      steps[this.props.setupTourStep]?.key === 'addTeamBtnStep'
    ) {
      setTimeout(() => {
        document.querySelector(steps[this.props.setupTourStep].target).scrollIntoViewIfNeeded();
      }, 400);
    }

    if (steps[this.props.setupTourStep]?.key === 'addTeamStep' && this.props.wasAddTeamButtonClicked) {
      this.setState({ run: false });
      this.props.setSetupTourStep(this.props.setupTourStep + 1);

      setTimeout(() => {
        this.setState({ run: true });
      }, 500);
    }

    if (steps[this.props.setupTourStep]?.key === 'addTeamBtnStep' && this.props.wasTeamAdded) {
      this.setState({
        run: this.props.setupTourStep === 0 ? false : run,
      });
      this.props.setSetupTourStep(this.props.setupTourStep + 1);
    }

    if (
      steps[this.props.setupTourStep]?.key === 'addUserStep' &&
      this.props.showAddUserModal !== prevProps.showAddUserModal &&
      this.props.showAddUserModal === true
    ) {
      this.setState({
        run: false,
      });
      this.props.setSetupTourStep(this.props.setupTourStep + 1);

      setTimeout(() => {
        this.setState({
          run: true,
        });
      }, 500);
    }

    if (
      steps[this.props.setupTourStep]?.key === 'nextBtnStep' &&
      !this.props.showAddUserModal &&
      this.props.wasUserAdded
    ) {
      this.setState({
        run: this.props.setupTourStep === 0 ? false : run,
      });
      this.props.setSetupTourStep(this.props.setupTourStep + 1);
    }

    if (steps[this.props.setupTourStep]?.key === 'userTileStep' && this.props.wasUserMoved) {
      this.setState({
        run: this.props.setupTourStep === 0 ? false : run,
      });
      this.props.setSetupTourStep(this.props.setupTourStep + 1);
    }

    if (
      steps[this.props.setupTourStep]?.key === 'userTileStep' &&
      this.props.setupTourStep !== prevProps.setupTourStep
    ) {
      this.props.history.push(`/company_structure?mode=setup`);
    }

    if (
      steps[this.props.setupTourStep]?.key === 'nextStepBtn' &&
      this.props.location.search === '?mode=setup&setupStep=2'
    ) {
      this.setState({
        run: this.props.setupTourStep === 0 ? false : run,
      });
      this.props.setSetupTourStep(this.props.setupTourStep + 1);
    }

    if (
      steps[this.props.setupTourStep]?.key === 'sendInvitationsStep' &&
      this.props.location.search === '?mode=view'
    ) {
      this.setState({
        run: this.props.setupTourStep === 0 ? false : run,
      });
      this.props.setSetupTourStep(this.props.setupTourStep + 1);
    }

    if (this.props.setupTourStep !== prevProps.setupTourStep) {
      this.finishSetupStep(this.props.setupTourStep);
    }
  }

  componentWillUnmount() {
    this.props.setShowAddUserModal(false)
  }

  finishSetupStep = step => {
    const { steps } = this.state;
    const stepName = steps[step]?.key;
    switch (stepName) {
      case 'initialSetupStep':
        this.props.finishStep('start_setup');
        break;
      case 'addTeamBtnStep':
        this.props.finishStep('add_teams');
        break;
      case 'addUserStep':
        this.props.finishStep('add_user');
        break;
      case 'userTileStep':
        this.props.finishStep('move_user');
        break;
      case 'teamLeadStep':
        this.props.finishStep('team_lead');
        break;
      case 'sendInvitationsStep':
        this.props.finishStep('invite_users');
        break;
      case 'notificationStep':
        this.props.finishStep('unactive_users');
        break;
    }
  };

  handleJoyrideCallback = data => {
    const { action, index, status, type } = data;
    const { steps } = this.state;
    let skippedSteps = 0;
    if (action !== 'close' && [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (steps[index]?.key === 'keyPerformanceStep' && this.props.userAlreadyVibed === true) {
        skippedSteps = this.props.isLeadOrAdmin ? 1 : 2;
        this.props.setSetupTourStep(index + 1 + skippedSteps);
      } else {
        this.props.setSetupTourStep(index + 1);
      }
    } else if (action === 'close' || [STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ run: false });
      this.props.setSetupTourStep(-1);
      if (status === STATUS.FINISHED) {
        this.props.setFinishedTour('setup');
        this.props.setShowActionModal(false);
        this.props.toggleTourModal(true);
      }
      if (this.props.hideSamplePosts) {
        this.props.removeSampleTeam();
      }
    }
  };

  render() {
    const { steps, run } = this.state;
    return (
      <JoyrideWrapper
        run={run}
        stepIndex={this.props.setupTourStep}
        steps={steps}
        callback={this.handleJoyrideCallback}
      />
    );
  }
}

const mapStateToProps = state => ({
  action: state.app.action,
  showAddUserModal: state.app.showAddUserModal,
  wasUserAdded: state.onboardingDomain.wasUserAdded,
  wasTeamAdded: state.onboardingDomain.wasTeamAdded,
  wasUserMoved: state.onboardingDomain.wasUserMoved,
  wasCompanyStructureFetched: state.onboardingDomain.wasCompanyStructureFetched,
  wasAddTeamButtonClicked: state.onboardingDomain.wasAddTeamButtonClicked,
  setupTourStep: state.onboardingDomain.setupTourStep,
  isLeadOrAdmin: Boolean(
    state.usersDomain.user.isAccountAdmin || state.usersDomain.user.formalLeadTeamsIds.length
  ),
  userAlreadyVibed: !!state.usersDomain.user && state.usersDomain.user.alreadyVibed,
  isDemoAccount: !!state.usersDomain.user && state.usersDomain.user.isDemoAccount,
  plan: state.plansDomain.plan,
  hideSamplePosts: state.usersDomain.user.hideSamplePosts,
  feedEnabled: state.usersDomain.feedEnabled,
});

const mapDispatch = {
  setAction,
  setShowActionModal,
  setShowAddUserModal,
  setWasUserAdded: wasUserAdded => ONBOARDING_ACTIONS.setWasUserAdded(wasUserAdded),
  setWasTeamAdded: wasTeamAdded => ONBOARDING_ACTIONS.setWasTeamAdded(wasTeamAdded),
  setWasUserMoved: wasUserMoved => ONBOARDING_ACTIONS.setWasUserMoved(wasUserMoved),
  setWasCompanyStructureFetched: wasCompanyStructureFetched =>
    ONBOARDING_ACTIONS.setWasCompanyStructureFetched(wasCompanyStructureFetched),
  setWasAddTeamButtonClicked: wasAddTeamButtonClicked =>
    ONBOARDING_ACTIONS.setWasAddTeamButtonClicked(wasAddTeamButtonClicked),
  setSetupTourStep: step => ONBOARDING_ACTIONS.setSetupTourStep(step),
  toggleTourModal: toggle => ONBOARDING_ACTIONS.toggleTourModal(toggle),
  setFinishedTour: tour => ONBOARDING_ACTIONS.setFinishedTour(tour),
  finishStep: step => ONBOARDING_ACTIONS.finishStep(step),
  updateVibeStatus: vibed => USER_ACTIONS.vibeStatusUpdated(vibed),
  removeSampleTeam: () => ONBOARDING_ACTIONS.removeSampleTeam(),
  toggleAddChildTeam: addChildTeamState => ONBOARDING_ACTIONS.toggleAddChildTeam(addChildTeamState),
};

export default withRouter(connect(mapStateToProps, mapDispatch)(SetupTour));
