/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';

const VerticalCenterContainer = styled.div`
  margin-bottom: 0;
  margin-top: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;

  &:before {
    min-height: 0;
    -webkit-box-flex: 1;
    box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    content: '';
    display: block;
  }

  &:after {
    min-height: 0;
    -webkit-box-flex: 1;
    box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    content: '';
    display: block;
  }
`;

export default VerticalCenterContainer;
