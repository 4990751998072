/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import { withRouter } from 'react-router-dom';
import PostponeButton from './partials/PostponeButton';
import plane from './plane.svg';
import scooter from './scooter.svg';

const TourImage = ({ type, ...props }) => (
  <img
    css={css`
      display: block;
      padding: ${type === 'short' ? '29px 0px 32px 21px' : '34px 0px 28px 0'};
      margin: auto;
    `}
    {...props}
  />
);

const TourDescription = styled.div`
  color: #11a9ff;
  font-size: 15px;
  line-height: 140%;
  padding: 0 15px;
`;
const TourElement = ({ type, ...props }) => {
  const description =
    type === 'short'
      ? 'Speedy learning: Read a three slide summary'
      : 'Learn by doing: Guided and interactive tour to see it in action';
  const src = type === 'short' ? plane : scooter;
  return (
    <div
      css={css`
        width: 190px;
        height: 206px;
        background: #f9fdff;
        border: 1px solid #c8ebff;
        box-sizing: border-box;
        border-radius: 6px;
        display: inline-block;
        margin: 0 18px;
        vertical-align: middle;
        cursor: pointer;
      `}
      {...props}
    >
      <TourImage src={src} type={type} />
      <TourDescription
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </div>
  );
};

const SelectTour = ({
  nextStep,
  postponeTour,
  isAccountAdmin,
  isTeamLead,
  growEnabled,
  ...props
}) => {
  const { history } = props;
  const firstStepUrl = isAccountAdmin
    ? '/vibes/dashboard?insights_tour'
    : growEnabled
    ? '/grow_profile?grow_tour'
    : '/vibes/dashboard?insights_tour';
  return (
    <>
      <div
        css={css`
          font-size: 15px;
          padding: 9px 28px 27px 44px;
          line-height: 150%;
        `}
      >
        Two options for you: the ability to speed through it with a quick summary or a guided tour
        so you get a sense of exactly how it all works (recommended).
      </div>
      <div
        css={css`
          display: block;
          padding-bottom: 32px;
          padding-left: 4px;
        `}
      >
        <TourElement
          type="long"
          onClick={() => {
            history.push(firstStepUrl);
            postponeTour();
          }}
        />
        <TourElement type="short" onClick={() => nextStep('summary')} />
      </div>
      <PostponeButton onClick={() => postponeTour()} />
    </>
  );
};

const mapStateToProps = state => ({
  isAccountAdmin: !!state.usersDomain.user && Boolean(state.usersDomain.user.isAccountAdmin),
  isTeamLead: !!state.usersDomain.user && Boolean(state.usersDomain.user.formalLeadTeamsIds.length),
  growEnabled: state.usersDomain.growEnabled,
});

const mapDispatchToProps = dispatch => ({
  postponeTour: () => dispatch(ONBOARDING_ACTIONS.postponeTour()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectTour));
