import generateFileFormData from 'components/shared/fileUpload/generateFileFormData';

const types = {
  BUZZ: 'Buzz',
  POLL: 'Poll',
  FIX: 'Fix',
};

const generateFormData = async (type, state, props, allFilesSize, setState) => {
  const { mediaUrl, content, postErrors, isMention, files } = state;
  let data = new FormData();
  setState({
    disabledButton: true,
    errorContent: null,
    postErrors: null,
    generalError: null,
  });
  data.append('post[type]', type);
  data.append('post[audience_id]', props.spaceId ? props.spaceId : props.filters.space);
  data.append('post[content]', content);
  data.append('post_media_size', allFilesSize);
  data.append('post[validate_mentions]', !(postErrors && postErrors.mention_error && isMention));

  if (type === types.POLL) {
    data.append('options[]', state.option1);
    data.append('options[]', state.option2);
    data.append('options[]', state.option3);
    data.append('options[]', state.option4);
  } else if (type === types.FIX) {
    data.append('post[subtype]', state.subtype);

    for (let i = 0; i < state.subjects.length; i++) {
      data.append('post_subjects[]', state.subjects[i]);
    }
  }

  data = generateFileFormData(data, mediaUrl, files);
  return data;
};

export default generateFormData;
