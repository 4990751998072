/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import HowTo from './HowTo';
import Support from './Support';

const OnboardingResources = ({}) => {
  return (
    <div
      css={css`
        padding: 30px 35px;
      `}
    >
      <HowTo />
      <Support />
    </div>
  );
};

export default OnboardingResources;
