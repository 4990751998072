import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const Wrapper = styled.div`
  padding: 0 25px 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
`;
const Label = styled.h5`
  font-size: 11px;
  line-height: normal;
  margin: 0 4px 0 0;
  font-weight: normal;
  color: ${props => (props.isResolved ? '#52BA05' : '#11A9FF')};
  ${({ isCancelled }) =>
    isCancelled &&
    css`
      color: #979797;
    `};
  text-transform: none;
  white-space: nowrap;
`;
const SharedWith = ({ onClick, isResolved, children, isCancelled }) => (
  <Wrapper onClick={() => onClick()}>
    <Label isResolved={isResolved} isCancelled={isCancelled}>
      Shared with
    </Label>
    {children}
  </Wrapper>
);
export default SharedWith;
