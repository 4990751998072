import React, { Component } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { COMMENT_ACTIONS } from '../../redux/actions/commentActions';

const Wrapper = styled.div`
  padding-bottom: 24px;
`;

const CommentInputComponentWrapper = styled.div`
  width: calc(100% - 50px);
  display: flex;
  margin: 0 25px;
`;

const SendButton = styled.div`
  position: relative;
  font-weight: bold;
  font-size: 13px;
  color: #11a9ff;
  border: 1px solid #e7e7e7;
  border-left: 0;
  border-radius: 0 4px 4px 0;
  line-height: 38px;
  min-width: 60px;
  text-align: center;
  cursor: pointer;
`;

const ButtonCaption = styled.div`
  position: absolute;
  bottom: 0;
  left: 14px;
`;

const CommentInputWrapper = styled.div`
  border: 1px solid #e7e7e7;
  flex-grow: 1;
  min-height: 40px;
  padding: 10px 14px;
  font-size: 14px;
  line-height: 16px;
  background: #ffffff;
  border-radius: ${props => (props.showSendButton ? '4px 0 0 4px' : '4px')};
`;

const Input = styled.textarea`
  border: none;
  font-size: 12px;
  line-height: 16px;
  outline: none !important;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: auto;
  height: auto;
  resize: none;
  min-height: unset;
  vertical-align: middle;
`;

const CommentError = styled.div`
  font-size: 12px;
  margin: 7px 26px;
  color: #fe483c;
`;

class CommentInput extends Component {
  shiftPressed = false;

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.state = {
      comment: '',
      commentError: null,
      commentCharsLeft: 1000,
      showSendButton: false,
      rows: 1,
      minRows: 1,
      maxRows: 5,
    };
  }

  addCommentHandler = () => {
    const { comment } = this.state;
    const { postId, type, addComment, commentAddedHandler } = this.props;
    const data = {
      content: comment,
    };
    if (this.state.commentCharsLeft >= 0) {
      addComment(postId, data, type, commentAddedHandler);
      this.setState(
        {
          comment: '',
          showSendButton: false,
          commentCharsLeft: 1000,
          rows: 1,
        },
        () => this.inputRef.blur()
      );
    }
  };

  handleCommentChange = e => {
    const input = e.target.value;
    const textareaLineHeight = 16;
    const { minRows, maxRows } = this.state;
    const previousRows = e.target.rows;
    e.target.rows = minRows;
    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);
    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }
    if (currentRows >= maxRows) {
      e.target.rows = maxRows;
      e.target.scrollTop = e.target.scrollHeight;
    }
    this.setState({
      rows: currentRows < maxRows ? currentRows : maxRows,
      comment: input,
      commentError:
        input.length > 1000
          ? 'You’ve hit the maximum comment length which is 1000 characters.'
          : '',
      commentCharsLeft: 1000 - input.length,
      showSendButton: !!input.length,
    });
  };

  onKeyDown = e => {
    if (e.key === 'Enter' && e.target.value.trim() === '') {
      e.preventDefault();
      this.setState({ commentError: "This field can't be empty" });
    } else if (e.key === 'Enter' && !this.shiftPressed) {
      e.preventDefault();
      this.addCommentHandler();
      this.props.tileRealignHandler && this.props.tileRealignHandler();
    } else if (this.props.tileRealignHandler) {
      const element = e.target;
      const previousHeight = window.getComputedStyle(element).height;
      setTimeout(() => {
        if (previousHeight != window.getComputedStyle(element).height)
          this.props.tileRealignHandler();
      }, 10);
    }
    if (e.key === 'Shift') this.shiftPressed = true;
  };

  onKeyUp = e => {
    if (e.key === 'Shift') this.shiftPressed = false;
  };

  render() {
    const { comment, showSendButton, commentError, rows } = this.state;
    return (
      <Wrapper>
        <CommentInputComponentWrapper>
          <CommentInputWrapper showSendButton={showSendButton}>
            <Input
              ref={elem => (this.inputRef = elem)}
              rows={rows}
              placeholder="Add your comment here"
              value={comment}
              onChange={this.handleCommentChange}
              onKeyDown={this.onKeyDown}
              onKeyUp={this.onKeyUp}
            />
          </CommentInputWrapper>
          {showSendButton && (
            <SendButton
              onClick={() => {
                const event = {
                  key: 'Enter',
                  target: { value: { trim: () => true } },
                  preventDefault: () => {},
                };
                return this.onKeyDown(event);
              }}
            >
              <ButtonCaption>ADD</ButtonCaption>
            </SendButton>
          )}
        </CommentInputComponentWrapper>
        {commentError && <CommentError>{commentError}</CommentError>}
      </Wrapper>
    );
  }
}

const mapDispatch = dispatch => ({
  addComment: (postId, data, type, commentAddedHandler) =>
    dispatch(COMMENT_ACTIONS.addComment(postId, data, type, commentAddedHandler)),
});
export default connect(null, mapDispatch)(CommentInput);
