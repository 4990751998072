import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import {
  ADMIN_ROLE,
  APP_URL,
  AWS_S3_URL,
  COMPANY_OWNER_ROLE,
  TEAM_MANAGER_ROLE,
  TEAM_MEMBER_ROLE,
} from '../../../constants';
import { BODY_CLASSES } from '../../../helpers';

class ResendConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: false,
      message: '',
      redirect: false,
      loaded: false,
    };
  }

  componentDidMount() {
    BODY_CLASSES(['confirmations', 'new']);
    this.fetch();
  }

  fetch() {
    const url = this.props.location.pathname + this.props.location.search;
    fetch(APP_URL + url, { method: 'get' })
      .then(response => {
        if (response.status === 200 && response.ok === true) {
          response
            .json()
            .then(jsonResponse => {
              if (jsonResponse.error) {
                this.setState({ error: true });
                this.setState({ message: jsonResponse.error });
                if (jsonResponse.email) {
                  this.setState({ email: jsonResponse.email });
                  this.setState({ loaded: true });
                }
              } else {
                this.setState({ message: jsonResponse.message });
                localStorage.setItem('access_token', jsonResponse.accessToken);
                localStorage.setItem('user_first_name', jsonResponse.user.first_name);
                localStorage.setItem('user_last_name', jsonResponse.user.last_name);
                localStorage.setItem('user_image_uid', jsonResponse.user.image_uid);
                jsonResponse.roles.forEach(role => {
                  switch (role.name) {
                    case ADMIN_ROLE:
                      localStorage.setItem('is_admin', true);
                      break;
                    case COMPANY_OWNER_ROLE:
                      localStorage.setItem('is_company_owner', true);
                      break;
                    case TEAM_MANAGER_ROLE:
                      localStorage.setItem('is_team_manager', true);
                      break;
                    case TEAM_MEMBER_ROLE:
                      localStorage.setItem('is_team_member', true);
                      break;
                    default:
                  }
                });
                this.setState({ redirect: true });
              }
            })
            .catch(() => {});
        }
      })
      .catch(() => {});
  }

  send(e) {
    e.preventDefault();
    const data = new FormData();
    data.append('email', this.state.email);

    fetch(`${APP_URL}/confirmation/resend`, { method: 'post', body: data }).then(response => {
      if (response.status === 200 && response.ok === true) {
        response.json().then(jsonResponse => {
          if (jsonResponse.error) {
            this.setState({ error: true });
            this.setState({ message: jsonResponse.message });
          } else {
            this.setState({ message: jsonResponse.message });
            this.setState({ redirect: true });
          }
        });
      }
    });
  }

  render() {
    if (this.state.redirect) {
      if (localStorage.getItem('onboarding_flow')) {
        return (
          <Redirect
            push
            to={{
              pathname: '/',
            }}
          />
        );
      }
      return (
        <Redirect
          push
          to={{
            pathname: '/login',
            state: {
              message: this.state.message,
            },
          }}
        />
      );
    }
    const classes = this.state.error
      ? 'form-group email optional user_email has-error'
      : 'form-group email optional user_email';
    return this.state.loaded ? (
      <div>
        <div className="logo-public centered">
          <img
            src={`${AWS_S3_URL}public/images/Heelix-Logo-black.svg`}
            alt=""
            style={{ height: 56 }}
          />
        </div>
        <div className="floating-panel">
          <div className="panel-body">
            <div className="centered">
              <h1>Resend confirmation instructions</h1>
              <p>Enter your email address and we'll get you started.</p>
            </div>
            <form className="simple-form form-compact">
              <div className={classes}>
                <label className="email optional control-label" htmlFor="user_email">
                  Email
                </label>
                <input
                  autoFocus="autofocus"
                  className="string email optional form-control"
                  name="user[email]"
                  placeholder="your@email.com"
                  type="email"
                  id="user_email"
                  value={this.state.email}
                  onKeyPress={e => (e.key === 'Enter' ? this.send(e) : '')}
                  onChange={e => this.setState({ email: e.target.value })}
                />
                <span className="help-block">{this.state.message}</span>
              </div>
              <input
                className="btn btn-primary btn-full"
                name="commit"
                id="resend-confirmation-btn"
                type="submit"
                value="Resend confirmation instructions"
                onClick={e => this.send(e)}
              />
              <span className="help-block" id="help-message">
                {this.state.message}
              </span>
            </form>
            <div className="centered u-margin-top-sm">
              <div className="u-size0">
                Already have an account?&nbsp;
                <Link className="invisible-link ul-link" to="/login" id="sign-in-btn">
                  Sign in here
                </Link>
                <br />
                <Link
                  to="/password/new"
                  className="invisible-link ul-link"
                  id="forgot-password-btn"
                >
                  Forgot your password?
                </Link>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div />
    );
  }
}

export default ResendConfirmation;
