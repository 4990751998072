/** @jsx jsx */
import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import qs from 'qs';

import { getImage } from 'pages/Insights';
import { ReactComponent as Insights } from 'components/layouts/Navigation/insights.svg';
import Page from 'components/layouts/Page';

import SampleContentBanner from 'pages/Insights/shared/SampleContentBanner';
import { css, jsx } from '@emotion/core';
import Can from 'rbac/Can';
import { insights } from 'rbac/plans';
import UpgradeModal, { grayFilterCss } from 'pages/Insights/UpgradeModal';
import DateRange from './partials/DateRange';
import TeamSelect from './partials/TeamSelect';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../../helpers';
import { APP_URL } from '../../../constants';

const defaultState = {
  selectedPosition: 1,
  selectedCategory: null,
  inactiveMoods: [],
  loaded: false,
  moods: [
    { id: -2, name: 'terrible', color: '#FE483C' },
    { id: -1, name: 'bad', color: '#FF7F63' },
    { id: 0, name: 'okay', color: '#BEB49E' },
    { id: 1, name: 'good', color: '#8ED04C' },
    { id: 2, name: 'awesome', color: '#52BA05' },
  ],
  stats: [],
  dates: [],
  latestDate: '',
};

class TeamVibeMoodOverTime extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchData();
  }

  componentDidUpdate = prevProps => {
    if (JSON.stringify(prevProps.queryString) !== JSON.stringify(this.props.queryString)) {
      this.fetchData();
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchData = () => {
    this.props.closeFlash();
    const headers = HEADERS();
    const url = `${APP_URL}/vibe_mood_over_time_stats?${qs.stringify(this.props.queryString)}`;
    fetch(url, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (this._isMounted) {
          response.json().then(jsonResponse => {
            if (jsonResponse.error) {
              this.props.updateFlash(jsonResponse.flashName, jsonResponse.message);
              this.setState({ ...defaultState, inactiveMoods: this.state.inactiveMoods });
              return true;
            }
            if (jsonResponse.enoughMembers) {
              const maxValue = jsonResponse.stats
                ? Math.max.apply(
                    Math,
                    jsonResponse.stats.map(o =>
                      Math.max.apply(null, [o.awesome, o.good, o.okay, o.bad, o.terrible])
                    )
                  )
                : 10;
              this.setState({
                stats: jsonResponse.stats,
                dates: jsonResponse.dates,
                maxValue,
                loaded: true,
                latestDate: jsonResponse.latestDate,
              });
            } else {
              this.setState({ ...defaultState, inactiveMoods: this.state.inactiveMoods });
            }
            this.props.refreshRecentTeams(jsonResponse.recentSpaces);
          });
        }
      });
  };

  toggleLine(mood) {
    const arr = [...this.state.inactiveMoods];
    const index = arr.indexOf(mood);
    if (index !== -1) {
      arr.splice(index, 1);
      this.setState({ inactiveMoods: arr });
    } else {
      this.setState({ inactiveMoods: [...this.state.inactiveMoods, mood] });
    }
  }

  checkIfActive(index) {
    return !this.state.inactiveMoods.includes(index);
  }

  _customLabelX(value, index, values) {
    return moment(value).format('D MMM').toUpperCase();
  }

  _customLabelTooltip(tooltipItem, data) {}

  _customTitleTooltip(tooltipItem, data) {
    return `${tooltipItem[0].yLabel}`;
  }

  _customMaxValue(max) {
    return max && max > 0 ? Math.ceil(max / 10) * 10 : 10;
  }

  _options() {
    const max = this.state.maxValue;
    return {
      legend: false,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 45,
        },
      },
      plugins: {
        datalabels: {
          display: false,
        },
      },
      scales: {
        yAxes: [
          {
            position: 'right',
            scaleFontColor: '#706E84',
            scaleFontSize: 12,
            scaleFontFamily: 'Roboto',
            scaleFontStyle: 'Regular',
            ticks: {
              fontColor: '#C4C4C4',
              min: 0,
              max: this._customMaxValue(max),
              stepSize: max > 100 ? 25 : 10,
              padding: 9,
              callback(value, index, values) {
                const max = Math.max.apply(null, values);
                const val = parseInt(value, 10);
                if (val === 0 || val === Math.ceil(max / 10) * 10) return val;
                return '';
              },
            },
            gridLines: {
              zeroLineColor: '#F3F3F3',
              zeroLineWidth: 2,
              color: '#F3F3F3',
              drawBorder: false,
            },
            scaleLabel: {
              display: true,
              fontColor: '#C4C4C4',
              fontFamily: 'Roboto',
              lineHeight: '17px',
              labelString: 'Number of people',
              padding: 0,
              rotation: 180,
            },
          },
        ],
        xAxes: [
          {
            scaleFontColor: '#C4C4C4',
            scaleFontSize: 11,
            scaleFontFamily: 'Roboto',
            scaleFontStyle: 'Regular',
            ticks: {
              fontSize: 11,
              fontColor: '#C4C4C4',
              fontFamily: 'Roboto',
              padding: 10,
              callback: this._customLabelX,
            },
            gridLines: {
              display: false,
            },
          },
        ],
      },
      spanGaps: true,
      hover: {
        mode: 'nearest',
        intersect: true,
      },
      tooltips: {
        backgroundColor: 'white',
        titleFontFamily: 'Roboto',
        titleFontSize: 14,
        titleFontStyle: 'Bold',
        titleFontColor: '#575563',
        borderColor: 'rgba(0, 0, 0, 0.15)',
        borderWidth: 1,
        cornerRadius: 3,
        xPadding: 16,
        yPadding: 7,
        titleMarginBottom: 0,
        caretPadding: 10,
        yAlign: 'bottom',
        xAlign: 'center',
        callbacks: {
          title: this._customTitleTooltip,
          label: this._customLabelTooltip,
        },
      },
    };
  }

  _data() {
    const _ = require('underscore');
    const datasets = [];
    const { inactiveMoods } = this.state;
    const moods = this.state.moods.filter(o => !inactiveMoods.includes(o.id));
    for (let i = 0; i < moods.length; i++) {
      const mood = moods[i];
      const obj = {
        label: mood.name,
        fill: false,
        borderColor: mood.color,
        borderWidth: 2,
        pointHoverBackgroundColor: '#FFF',
        pointBackgroundColor: '#FFF',
        pointHoverBorderColor: mood.color,
        pointHoverRadius: 4,
        pointHitRadius: 4,
        lineTension: 0,
      };

      datasets.push({
        ...obj,
        data: this._buildGraphData(mood.name),
      });

      datasets.push({
        ...obj,
        label: `${mood.name} prediction`,
        data: this.buildDottedLine(mood.name),
        borderDash: [0, 7],
        borderCapStyle: 'square',
      });
    }
    return {
      labels: this.state.dates,
      datasets: this.state.loaded ? datasets : [],
    };
  }

  _buildGraphData(name) {
    const _ = require('underscore');
    const arr = [];
    const { stats } = this.state;

    this.state.dates.forEach(item => {
      if (item === this.state.latestDate) return;
      const stat = _.findWhere(stats, { date: item });
      if (stat) {
        arr.push(stat[name]);
      } else {
        arr.push(0);
      }
    });
    return arr;
  }

  buildDottedLine(name) {
    const _ = require('underscore');
    const arr = [];
    const { stats, latestDate } = this.state;
    const beforeLatestDate = moment(latestDate).subtract(7, 'days').format('YYYY-MM-DD');

    this.state.dates.forEach(item => {
      const stat = _.findWhere(stats, { date: item });
      const statName = stat && stat[name];
      if (item === latestDate) {
        arr.push(statName || null);
      } else if (item === beforeLatestDate) {
        arr.push(statName || 0);
      } else {
        arr.push(null);
      }
    });
    return arr;
  }

  render() {
    return (
      <div
        css={css`
          position: relative;
        `}
      >
        <SampleContentBanner queryString={this.props.queryString} />
        <Page.Header icon={Insights}>Mood over time</Page.Header>
        <Can perform={insights.accessMoodOverTime} no={() => <UpgradeModal />} />
        <Can perform={insights.accessMoodOverTime}>
          {canSeeFactors => (
            <Page.Paper cssProps={!canSeeFactors && grayFilterCss}>
              <div className="mood-over-time-vibe" id="mood-over-time-vibe">
                <div className="top-controls-wrap">
                  <TeamSelect
                    queryString={this.props.queryString}
                    tabLink={this.props.location.pathname}
                  />
                  <DateRange
                    queryString={this.props.queryString}
                    tabLink={this.props.location.pathname}
                    hideThisLastWeek={this.props.hideThisLastWeek}
                  />
                </div>
                <div className="chart-categories" id="chart-categories">
                  <ul id="categories-btns">
                    {this.state.moods.map(mood => (
                      <li
                        key={mood.id}
                        id={`category-${mood.name}`}
                        className={`category-btn ${mood.name}
                            ${this.checkIfActive(mood.id) && 'active'}`}
                        onClick={() => this.toggleLine(mood.id)}
                      >
                        {this.checkIfActive(mood.id) ? (
                          <img className="mood-icon" src={getImage(true, mood.id)} alt={mood.id} />
                        ) : (
                          <img className="mood-icon" src={getImage(false, mood.id)} alt={mood.id} />
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
                <div id="stats-wrapper">
                  <div id="mood-over-time-chart">
                    <Line data={this._data()} options={this._options()} />
                  </div>
                </div>
              </div>
            </Page.Paper>
          )}
        </Can>
      </div>
    );
  }
}

export default TeamVibeMoodOverTime;
