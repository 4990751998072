import React from 'react';
import WhyFactorsScreen from './WhyFactorsScreen';
import WhyFactor from './WhyFactor';

export default class SelectWhyFactors extends React.Component {
  // INFO: all screens
  // - default_factors
  // - other_factors
  constructor(props) {
    super(props);

    this.maxFactors = 1;

    this.state = {
      screen: 'why_factors',
      allowToSelect: this._totalSelectedFactors() < this.maxFactors,
      showAllFactors: false,
      message: this.props.message,
    };
  }

  render() {
    document.getElementsByClassName('page-wrapper')[0].firstChild.classList.remove('full-screen');
    return (
      <WhyFactorsScreen
        type={this.props.type}
        title={this.props.title}
        factors={this.props.whyFactors}
        getFactors={this.getFactors.bind(this)}
        raw_text={this.props.raw_text}
        nextStep={this.nextStep.bind(this)}
        previousStep={this.previousStep.bind(this)}
        enable_vibe_comments={this.props.enable_vibe_comments}
        disabledSubmitButton={this.disabledSelectButton()}
        showAnonDescModal={this.props.showAnonDescModal}
        whyFactors={this.props.whyFactors}
        setMessageState={this.setMessageState.bind(this)}
        message={this.state.message}
      />
    );
  }

  setMessageState(e) {
    this.setState({ message: e.target.value });
  }

  addFactor(factor) {
    this.props.whyFactors.forEach(function (selectedFactor) {
      if (selectedFactor.id === factor.id) {
        factor.selected = true;
      } else if (selectedFactor.selected == null) {
        selectedFactor.selected = false;
      }
    });
    this.props.whyFactors.find(factor => (factor.alwaysVisible = true));

    // INFO: use == to avoid re-render every time
    if (this._totalSelectedFactors() === this.maxFactors) {
      this.setState({ allowToSelect: false });
    }
    this.nextStep();
  }

  removeFactor(factor) {
    this.props.whyFactors.forEach(function (selectedFactor) {
      if (selectedFactor.id === factor.id) {
        factor.selected = false;
      } else if (selectedFactor.selected == null) {
        selectedFactor.selected = false;
      }
    });

    // INFO: use == to avoid re-render every time
    if (this._totalSelectedFactors() === this.maxFactors - 1) {
      this.setState({ allowToSelect: true });
    }
  }

  previousStep() {
    const whyFactor = this.props.whyFactors.find(factor => factor.selected === true);
    if (whyFactor) {
      this.removeFactor(whyFactor);
    }
    this.props.previousStep({ why_vibe_factors: this.props.whyFactors });
  }

  nextStep(params = {}) {
    this.props.nextStep({ why_vibe_factors: this.props.whyFactors, message: this.state.message });
  }

  maxSelectionReach(factors = null) {
    return !this.state.allowToSelect || this.state.message;
  }

  disabledSelectButton() {
    if (this.props.whyFactors.length === 0) {
      return '';
    }
    const disableButton = this._totalSelectedFactors() <= this.maxFactors;
    return disableButton ? '' : 'disabled';
  }

  _totalSelectedFactors() {
    return this.props.whyFactors.filter(factor => factor.selected === true).length;
  }

  getFactors() {
    return this.props.whyFactors.map(factor => {
      return (
        <WhyFactor
          factor={factor}
          disabled={this.maxSelectionReach(this.props.whyFactors)}
          addFactor={this.addFactor.bind(this)}
          removeFactor={this.removeFactor.bind(this)}
          key={factor.id}
        />
      );
    });
  }
}
