import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';

class OfflineInvoices extends Component {
  trueFalseLabel(booleanVal, trueVal, falseVal) {
    const classes = booleanVal ? 'label label-success' : 'label label-danger';
    return <div className={classes}>{booleanVal ? trueVal : falseVal}</div>;
  }

  toCurrency(number) {
    return number.toFixed(2);
  }

  render() {
    return (
      <tr key={this.props.invoice.id}>
        <td style={{ width: '40px' }}>
          <img
            src={this.props.invoice.image}
            alt={this.props.invoice.account.name}
            className="shadow"
            style={{ width: '20px', height: '20px' }}
          />
        </td>
        <td>
          <Link className="primary-link" to={`/admin/accounts/${this.props.invoice.account.id}`}>
            {this.props.invoice.account.name}
          </Link>
        </td>
        <td>{this.props.invoice.code}</td>
        <td>{this.trueFalseLabel(this.props.invoice.sent, 'sent', 'unsent')}</td>
        <td>{this.trueFalseLabel(this.props.invoice.paid, 'paid', 'unpaid')}</td>
        <td className="hidden-sm hidden-xs">
          <TimeAgo date={`${this.props.invoice.created_at} ${this.props.user.time_zone}`} />
        </td>
        <td className="hidden-sm hidden-xs">
          ${this.toCurrency(this.props.invoice.amount_due)} in{' '}
          <TimeAgo date={`${this.props.invoice.date_due} ${this.props.user.time_zone}`} />
        </td>
        <td>
          <Link
            to={`/admin/offline_invoices/${this.props.invoice.id}/edit`}
            className="primary-link"
          >
            edit
          </Link>
        </td>
      </tr>
    );
  }
}

export default OfflineInvoices;
