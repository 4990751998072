import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import styled from '@emotion/styled';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  INSIGHTS_COMPARISON_COLOR_1,
  INSIGHTS_COMPARISON_COLOR_2,
} from 'pages/Insights/OrganisationInsights/ScoreComparison/layout';

const ChartWrap = styled.div`
  height: 280px;
  margin-top: -20px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #323232;
  text-align: center;
`;

const Description = styled.span`
  font-weight: normal;
  font-size: 14px;
  color: #c4c4c4;
  margin-left: 12px;
`;

const filterKeys = (keys, width = 600) => {
  // the second to last label always needs to be displayed, div needs to be a divisor of 40
  // this will only work as long as keys count is 40
  let div = 4;
  if (width < 380) {
    div = 8;
  } else if (width < 500) {
    div = 5;
  }
  return keys
    .map((key, index) => ((index + 1) % div === div - 1 ? key : ''))
    .map(key => key && moment(key).format('D MMM'));
};

const customTitleTooltip = (tooltipItem, data) => {
  if (!tooltipItem.length) return;
  const value = data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index];
  return `${value}`;
};

const customPeriodCalculate = (rawDates, queryString) => {
  let firstIndex = 0;
  let lastIndex = rawDates.length - 1;
  const start = moment(queryString.start_date);
  const end = moment(queryString.end_date);

  const dates = rawDates.map(date => moment(date));

  for (let i = 0; i < dates.length; i++) {
    if (dates[i].isAfter(start)) {
      firstIndex = i;
      break;
    }
  }

  for (let i = firstIndex; i < dates.length; i++) {
    if (dates[i].isAfter(end)) {
      lastIndex = i;
      break;
    }
  }

  return { multiplier: lastIndex - firstIndex, offset: rawDates.length - lastIndex + 1 };
};

const getBackground = (rawDates, dates, queryString, showPrevious) => {
  const colors = [];
  const data1 = [];
  const data2 = [];
  let multiplier;
  let offset = 0;
  switch (queryString.period) {
    case '30':
    default:
      multiplier = 4;
      break;
    case '60':
      multiplier = 8;
      break;
    case '90':
      multiplier = 12;
      break;
    case '7':
      multiplier = 1;
      break;
    case '-7':
      multiplier = 1;
      offset = 1;
      break;
    case 'custom':
      const custom = customPeriodCalculate(rawDates, queryString);
      multiplier = custom.multiplier;
      offset = custom.offset;
      break;
  }
  dates.forEach((_, index) => {
    if (dates.length - index > multiplier * 2 + offset || dates.length - index <= offset) {
      colors.push('');
      data1.push(0);
      data2.push(0);
    } else if (dates.length - index > multiplier + offset) {
      if (!showPrevious) {
        colors.push('');
        data1.push(0);
        data2.push(0);
      } else {
        colors.push('rgba(205, 234, 247, 0.3)');
        data1.push(100);
        data2.push(-100);
      }
    } else {
      colors.push('rgba(205, 234, 247, 0.6)');
      data1.push(100);
      data2.push(-100);
    }
  });
  return { colors, data1, data2 };
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 40,
    },
  },
  spanGaps: true,
  scales: {
    xAxes: [
      {
        barPercentage: 1.25,
        position: 'bottom',
        stacked: true,
        gridLines: {
          display: false,
          drawBorder: true,
          borderDash: [10, 500],
          borderDashOffset: -210,
        },
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          fontColor: '#C4C4C4',
          fontFamily: 'Roboto',
          padding: 27,
          callback(value) {
            return value;
          },
        },
      },
    ],
    yAxes: [
      {
        position: 'right',
        gridLines: {
          drawBorder: false,
          color: '#F3F3F3',
          zeroLineColor: '#F3F3F3',
          zeroLineWidth: 2,
        },
        ticks: {
          max: 100,
          stepSize: 100 / 3,
          min: -100,
          fontSize: 12,
          fontColor: '#706E84',
          callback(value) {
            const val = parseInt(value, 10);
            if (val === 100 || val === -100 || val === 0) return val;
            return '';
          },
        },
      },
    ],
  },
  legend: false,
  tooltips: {
    backgroundColor: 'white',
    titleFontFamily: 'Roboto, sans-serif',
    titleFontSize: 14,
    titleFontStyle: 'Bold',
    titleFontColor: '#575563',
    borderColor: 'rgba(0, 0, 0, 0.15)',
    borderWidth: 1,
    cornerRadius: 3,
    xPadding: 16,
    yPadding: 7,
    titleMarginBottom: 0,
    caretPadding: 10,
    yAlign: 'bottom',
    xAlign: 'center',
    filter: item => item.datasetIndex === 0,
    callbacks: {
      title: customTitleTooltip,
      label: () => {},
    },
    mode: 'nearest',
  },
  hover: {
    mode: 'nearest',
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
};

const processVibeStats = (vibeStats, setNormalLine, setStats, setPredictionLine) => {
  if (!vibeStats) return false;
  const vibes = Object.values(vibeStats);
  const keys = Object.keys(vibeStats);
  const hasOnlyOneStat = vibes.filter(vibe => vibe == null).length + 1 === vibes.length;
  const stats = vibes.map(function (vibe, index) {
    return (vibes.length - index) % 2 === 0 || (hasOnlyOneStat && vibes.length - index === 1)
      ? vibe
      : null;
  });

  const normalLine = [...stats];
  setNormalLine(normalLine);
  const lastNotNullIndex = normalLine.map(Number.isInteger).lastIndexOf(true);
  if (!stats[stats.length - 2]) {
    stats[stats.length - 2] = normalLine[lastNotNullIndex];
  }
  setStats(stats);

  setPredictionLine(() => {
    const line = Array(stats.length).fill(null);
    if (lastNotNullIndex !== line.length - 2) {
      line[lastNotNullIndex] = vibes[lastNotNullIndex];
      line[line.length - 2] = vibes[line.length - 2] ?? vibes[lastNotNullIndex];
    }
    return line;
  });

  return keys;
};

const generateChartDataset = (color, stats, normalLine, predictionLine) => [
  {
    label: `Vibe dot${color}`,
    data: Object.values(stats),
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderColor: color,
    fill: true,
    showLine: false,
    pointBorderWidth: 6,
    pointRadius: 1,
    pointHitRadius: 8,
    pointBorderColor: color,
    pointHoverRadius: 4,
    pointHoverBorderWidth: 2,
    pointHoverBackgroundColor: '#FFFFFF',
    type: 'line',
  },
  {
    label: `Vibe line${color}`,
    data: Object.values(normalLine),
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderColor: color,
    lineTension: 0.1,
    fill: true,
    borderWidth: 2,
    pointBorderWidth: 0,
    pointRadius: 0,
    pointHitRadius: 0,
    pointBorderColor: color,
    pointHoverRadius: 0,
    pointHoverBorderWidth: 0,
    pointHoverBackgroundColor: '#FFFFFF',
    type: 'line',
  },
  {
    label: `Prediction Dotted Line${color}`,
    data: Object.values(predictionLine),
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderColor: color,
    borderDash: [0, 6],
    borderCapStyle: 'square',
    lineTension: 0.1,
    fill: true,
    borderWidth: 2,
    pointBorderWidth: 6,
    pointRadius: 0,
    pointHitRadius: 0,
    pointBorderColor: color,
    pointHoverRadius: 0,
    pointHoverBorderWidth: 0,
    pointHoverBackgroundColor: '#FFFFFF',
    type: 'line',
  },
];

const ScoreHistoryChart = ({
  queryString,
  vibeStats,
  showPrevious,
  comparisonMode,
  vibeStats2,
}) => {
  const [stats, setStats] = useState([]);
  const [predictionLine, setPredictionLine] = useState([]);
  const [normalLine, setNormalLine] = useState([]);
  const [stats2, setStats2] = useState([]);
  const [predictionLine2, setPredictionLine2] = useState([]);
  const [normalLine2, setNormalLine2] = useState([]);
  const [dates, setDates] = useState([]);
  const [rawDates, setRawDates] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const chartRef = useRef(null);
  const barRef = useRef(null);

  useEffect(() => {
    const chartCanvas = chartRef.current.getElementsByTagName('canvas')[0];
    chartCanvas.style.maxWidth = '100%';
  }, []);

  useEffect(() => {
    const keys = processVibeStats(vibeStats, setNormalLine, setStats, setPredictionLine);

    if (comparisonMode) {
      processVibeStats(vibeStats2, setNormalLine2, setStats2, setPredictionLine2);
    }

    if (keys) {
      setDates(filterKeys(keys, barRef.current?.chartInstance?.chart?.width));
      setRawDates(keys);
      setLoaded(true);
    }
  }, [vibeStats, vibeStats2]);

  return (
    <>
      <Title>
        Score history<Description>Last 9 months</Description>
      </Title>
      <ChartWrap ref={chartRef} id="score-history-chart">
        <Bar
          ref={barRef}
          type="bar"
          data={() => {
            const background = getBackground(rawDates, dates, queryString, showPrevious);
            return {
              labels: dates,
              datasets: loaded
                ? [
                    ...(!comparisonMode
                      ? []
                      : generateChartDataset(
                          INSIGHTS_COMPARISON_COLOR_2,
                          stats2,
                          normalLine2,
                          predictionLine2
                        )),
                    ...generateChartDataset(
                      INSIGHTS_COMPARISON_COLOR_1,
                      stats,
                      normalLine,
                      predictionLine
                    ),
                    {
                      type: 'bar',
                      label: 'data1',
                      data: background.data1,
                      backgroundColor: background.colors,
                      borderWidth: 0,
                      hoverBackgroundColor: background.colors,
                    },
                    {
                      type: 'bar',
                      label: 'data2',
                      data: background.data2,
                      backgroundColor: background.colors,
                      borderWidth: 0,
                      hoverBackgroundColor: background.colors,
                    },
                  ]
                : [],
            };
          }}
          options={{
            ...options,
            onResize: e => setDates(filterKeys(rawDates, e.width)),
          }}
        />
      </ChartWrap>
    </>
  );
};

ScoreHistoryChart.propTypes = {
  queryString: PropTypes.shape({
    period: PropTypes.string.isRequired,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }).isRequired,
  vibeStats: PropTypes.object.isRequired,
  showPrevious: PropTypes.bool,
  comparisonMode: PropTypes.bool,
  vibeStats2: PropTypes.object,
};

ScoreHistoryChart.defaultProps = {
  comparisonMode: false,
  vibeStats2: null,
};

export default ScoreHistoryChart;
