/** @jsx jsx */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { css, jsx } from '@emotion/core';

const PerformanceTooltips = () => (
  <>
    <ReactTooltip
      id="no-vibes-performance-tooltip"
      css={css`
        && {
          background: #e1f2fa;
          margin-top: 0px !important;
          max-width: 224px;
          :after {
            border-top: solid 8px #e1f2fa !important;
            bottom: -8px;
            border-left-width: 8px !important;
            border-right-width: 8px !important;
            width: 0;
          }
        }
      `}
      place="top"
      type="light"
      effect="solid"
    />
    <ReactTooltip
      id="performance-tooltip"
      css={css`
        && {
          margin-top: -10px !important;
          :after {
            border-top: solid 8px #fff !important;
            bottom: -8px;
            border-left-width: 8px !important;
            border-right-width: 8px !important;
            width: 0;
          }
        }
      `}
      place="top"
      type="light"
      effect="solid"
    />
    <ReactTooltip
      id="performance-icon-tooltip"
      css={css`
        && {
          background: #e1f2fa;
          margin-top: -10px !important;
          max-width: 224px;
          :after {
            border-top: solid 8px #e1f2fa !important;
            bottom: -8px;
            border-left-width: 8px !important;
            border-right-width: 8px !important;
            width: 0;
          }
        }
      `}
      place="top"
      type="light"
      effect="solid"
    />
  </>
);

export default PerformanceTooltips;
