import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { APP_URL, AVATAR_GENERIC, AWS_S3_URL } from 'constants.js';
import { CHECK_UNAUTHORIZED, HEADERS } from 'helpers';
import Select from 'react-select';
import { connect } from 'react-redux';
import { USER_ACTIONS } from 'redux/actions/userActions';
import { resetCSVUsers } from 'redux/app/actions';
import ModalValueError from './ModalValueError';

class AddUsersModal extends Component {
  formValueTemplate = {
    email: '',
    first_name: '',
    last_name: '',
    role: '',
    team_id: '',
  };

  generateValidationMessages = () => {
    return this.props.csvUsers.map(user => this.formValueTemplate);
  };

  state = {
    quickAddingValue: '',
    quickAddingMode: this.props.csvUsers.length ? false : this.props.state === 'pending',
    rowsCount: Boolean(this.props.csvUsers.length) ? this.props.csvUsers.length : 1,
    formValues: this.props.csvUsers.length ? this.props.csvUsers : [this.formValueTemplate],
    validationMessages: this.props.csvUsers.length ? this.generateValidationMessages() : [{}],
    generalFailureMessage: '',
    showOmittedMessage: false,
    fetching: false,
  };

  userId = -1;

  upperFirst = string => {
    if (!(string && string.length > 1)) {
      return string;
    }
    string = string.trim();
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  quickAdding = () => {
    const formValues = [];
    this.state.quickAddingValue.split(',').forEach(email => {
      const userData = email.split('@')[0];
      let firstName;
      let lastName;
      if (userData) {
        firstName = userData.split('.')[0];
        lastName = userData.split('.')[1];
      }
      firstName = this.upperFirst(firstName);
      lastName = this.upperFirst(lastName);
      formValues.push({
        email,
        first_name: firstName || '',
        last_name: lastName || '',
        role: '',
      });
    });
    if (
      this.state.rowsCount === 1 &&
      Object.values(this.state.formValues[0]).filter(value => value !== '').length === 0
    ) {
      this.setState({
        quickAddingValue: '',
        quickAddingMode: false,
        formValues: [...formValues],
        rowsCount: formValues.length,
        validationMessages: [...Array(formValues.length).fill('').map(Object)],
      });
    } else {
      this.setState({
        quickAddingValue: '',
        quickAddingMode: false,
        formValues: [...this.state.formValues, ...formValues],
        rowsCount: this.state.formValues.length + formValues.length,
        validationMessages: [
          ...this.state.validationMessages,
          ...Array(formValues.length).fill('').map(Object),
        ],
      });
    }
  };

  sendData = () => {
    this.setState({ fetching: true });
    const users = [];
    this.state.formValues.forEach(valuesSet => {
      users.push({
        email: valuesSet.email.trim().toLowerCase(),
        first_name: valuesSet.first_name.trim(),
        last_name: valuesSet.last_name.trim(),
        role: valuesSet.role.trim(),
        team_id: this.props.state === 'invited' ? valuesSet.team_id : this.props.mainTeamId,
        state: this.props.state,
      });
    });
    const headers = HEADERS();
    headers.append('Content-Type', 'application/json');
    fetch(`${APP_URL}/users`, {
      method: 'post',
      headers,
      body: JSON.stringify(users),
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        this.setState({ fetching: false });
        const { ok } = response;
        response.json().then(jsonResponse => {
          if (ok) {
            this.handleClose();
            this.props.addUsers(jsonResponse.users, jsonResponse.message);
            this.setState({
              quickAddingMode: this.props.state === 'pending',
              formValues: [this.formValueTemplate],
              validationMessages: [{}],
              rowsCount: 1,
            });
            this.props.changeUsersCapacity(jsonResponse.planCapacityReached);
          } else if (jsonResponse.message) {
            this.setState({ generalFailureMessage: jsonResponse.message });
          } else {
            const validationMessages = [
              ...Array(this.state.formValues.length).fill('').map(Object),
            ];
            Object.keys(jsonResponse).forEach(key => {
              const keySplit = key.split('.');
              validationMessages[parseInt(keySplit[0], 10)][keySplit[1]] = jsonResponse[key][0];
            });
            this.setState({
              validationMessages,
              showOmittedMessage: true,
            });
          }
        });
      });
  };

  teamOptions() {
    const opts = [];
    this.props.teams.forEach(team => {
      if (team.account_id) {
        opts.push({
          value: team.id,
          label: team.name,
        });
      }
    });
    return opts;
  }

  removeRow(rowId) {
    const newFormValues = this.state.formValues.filter((row, rowIndex) => rowIndex !== rowId);
    const newValidationMessages = this.state.validationMessages.filter(
      (message, messageIndex) => messageIndex !== rowId
    );
    this.setState({
      rowsCount: this.state.rowsCount - 1,
      formValues: newFormValues,
      validationMessages: newValidationMessages,
    });
  }

  setRowValue = (name, index, value) => {
    const formValues = this.state.formValues.map((values, valuesIndex) =>
      valuesIndex === index
        ? {
            ...values,
            [name]: value,
          }
        : values
    );
    const validationMessages = this.state.validationMessages.map((messages, messagesIndex) =>
      messagesIndex === index
        ? {
            ...messages,
            [name]: '',
          }
        : messages
    );
    this.setState({
      formValues,
      validationMessages,
    });
    this.checkIfHasErrorMessages(validationMessages);
  };

  checkIfHasErrorMessages = messages => {
    const hasErrorMessage = messages.find(
      message => message.first_name || message.last_name || message.email || message.team_id
    );
    this.setState({ showOmittedMessage: hasErrorMessage });
  };

  handleClose = () => {
    this.props.resetCSVUsers();
    this.props.close();
  };

  render() {
    const isPending = this.props.state === 'pending';
    const enableButton =
      !this.state.fetching &&
      this.state.formValues.find(e => {
        for (const prop in e) {
          const has = e.hasOwnProperty(prop);
          if (has && e[prop]) {
            return true;
          }
        }
      });
    const isOnboarding = this.props.setupTourStep > 0;
    return (
      <Modal
        show={this.props.showUsersModal}
        onHide={!isOnboarding && this.handleClose}
        dialogClassName={`add-users-modal ${
          !isOnboarding && this.state.quickAddingMode ? '' : 'one-by-one'
        }`}
        backdrop
        keyboard
      >
        <div className="close-cross" onClick={!isOnboarding && this.handleClose} />
        <h1>Add users</h1>
        {!isOnboarding && this.state.quickAddingMode ? (
          <>
            <p>
              Add your users by entering their email addresses, separated by commas. They won’t
              receive an email invitation until the end of the setup process.
            </p>
            <textarea
              value={this.state.quickAddingValue}
              onChange={e =>
                this.setState({
                  quickAddingValue: e.target.value,
                })
              }
            />
            <div
              className="switch-method-wrap"
              onClick={() => this.setState({ quickAddingMode: false })}
            >
              <img src={AWS_S3_URL + AVATAR_GENERIC} alt="avatar generic" />{' '}
              <span>Add users manually, one by one</span>
            </div>
            <div className="buttons-wrap">
              <div className="next-step-button" onClick={this.quickAdding}>
                Next step
              </div>
              <div className="cancel-button" onClick={!isOnboarding && this.handleClose}>
                Cancel
              </div>
            </div>
          </>
        ) : (
          <>
            {isPending && (
              <p className="one-by-one-subtitle">
                They won’t receive an email invitation until the end of the setup process.
              </p>
            )}
            <div className="adding-info">
              {this.state.showOmittedMessage &&
                'You’re missing a mandatory field, please review and complete to add a new user.'}
            </div>
            <div className="form-wrap">
              {[...Array(this.state.rowsCount)].map((object, index) => (
                <div key={index} className="form-row-wrap">
                  <div
                    className={`column-wrap ${
                      this.state.validationMessages[index].email ? 'error' : ''
                    }`}
                  >
                    <p>Email</p>
                    <input
                      value={this.state.formValues[index].email}
                      onChange={e => this.setRowValue('email', index, e.target.value)}
                    />
                    <ModalValueError
                      message={this.state.validationMessages[index].email}
                      isMounted={Boolean(this.state.validationMessages[index].email)}
                      delayTime={400}
                    />
                  </div>
                  <div
                    className={`column-wrap ${
                      this.state.validationMessages[index].first_name ? 'error' : ''
                    }`}
                  >
                    <p>First name</p>
                    <input
                      value={this.state.formValues[index].first_name}
                      onChange={e => this.setRowValue('first_name', index, e.target.value)}
                    />
                    <ModalValueError
                      message={this.state.validationMessages[index].first_name}
                      isMounted={Boolean(this.state.validationMessages[index].first_name)}
                      delayTime={400}
                    />
                  </div>
                  <div
                    className={`column-wrap ${
                      this.state.validationMessages[index].last_name ? 'error' : ''
                    }`}
                  >
                    <p>Last name</p>
                    <input
                      value={this.state.formValues[index].last_name}
                      onChange={e => this.setRowValue('last_name', index, e.target.value)}
                    />
                    <ModalValueError
                      message={this.state.validationMessages[index].last_name}
                      isMounted={Boolean(this.state.validationMessages[index].last_name)}
                      delayTime={400}
                    />
                  </div>
                  <div
                    className={`column-wrap ${
                      this.state.validationMessages[index].role ? 'error' : ''
                    }`}
                  >
                    <p>
                      Role/Position
                      <span>(optional)</span>
                    </p>
                    <input
                      value={this.state.formValues[index].role}
                      onChange={e => this.setRowValue('role', index, e.target.value)}
                    />
                    <ModalValueError
                      message={this.state.validationMessages[index].role}
                      isMounted={Boolean(this.state.validationMessages[index].role)}
                      delayTime={400}
                    />
                  </div>
                  {!isPending && (
                    <div
                      className={`column-wrap ${
                        this.state.validationMessages[index].team_id ? 'error' : ''
                      }`}
                    >
                      <p>Team</p>
                      <Select
                        name="invites[team_id]"
                        onChange={e => this.setRowValue('team_id', index, e ? e.value : -1)}
                        value={this.state.formValues[index].team_id}
                        options={this.teamOptions()}
                        clearable={false}
                        placeholder=""
                      />
                      <ModalValueError
                        message={this.state.validationMessages[index].team_id}
                        isMounted={Boolean(this.state.validationMessages[index].team_id)}
                        delayTime={400}
                      />
                    </div>
                  )}
                  <div>
                    {this.state.rowsCount > 1 && (
                      <div className="cross" onClick={() => this.removeRow(index)} />
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className={`general-error ${this.state.generalFailureMessage ? 'visible' : ''}`}>
              {this.state.generalFailureMessage}
            </div>
            {!isOnboarding && (
              <div className="switch-method-wrap">
                <img src={AWS_S3_URL + AVATAR_GENERIC} alt="avatar generic" />{' '}
                <span
                  onClick={() =>
                    this.setState({
                      rowsCount: this.state.rowsCount + 1,
                      formValues: [...this.state.formValues, this.formValueTemplate],
                      validationMessages: [...this.state.validationMessages, {}],
                    })
                  }
                >
                  Add another user
                </span>
                {isPending && (
                  <>
                    {' or '}
                    <span onClick={() => this.setState({ quickAddingMode: true })}>
                      Invite multiple people at once
                    </span>
                  </>
                )}
              </div>
            )}
            <div className="buttons-wrap">
              <div
                className={`next-step-button ${!enableButton ? 'disabled' : ''}`}
                onClick={enableButton && this.sendData}
              >
                {isPending ? 'Next step' : 'Invite'}
              </div>
              <div className="cancel-button" onClick={!isOnboarding && this.handleClose}>
                Cancel
              </div>
            </div>
          </>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  teams: state.teamsDomain.teams,
  csvUsers: state.app.csvUsers,
  setupTourStep: state.onboardingDomain.setupTourStep,
});
const mapDispatchToProps = dispatch => ({
  resetCSVUsers: () => dispatch(resetCSVUsers()),
  changeUsersCapacity: isReached => dispatch(USER_ACTIONS.changeUsersCapacity(isReached)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUsersModal);
