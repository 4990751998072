import React from 'react';
import styled from '@emotion/styled';
import { GROW_ACTIONS } from 'redux/actions/growActions';
import { toggleNoteModal } from 'redux/app/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Wrap = styled.div`
  background: #46b700;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 39px;
  cursor: pointer;
`;

const ConfirmButton = ({ confirmNote, toggleNoteModal, noteId }) => {
  const handleClick = () => {
    confirmNote(noteId);
    toggleNoteModal(false);
  };

  return <Wrap onClick={handleClick}>Confirm?</Wrap>;
};

ConfirmButton.propTypes = {
  noteId: PropTypes.number.isRequired,
};

const mapDispatch = dispatch => ({
  confirmNote: id => dispatch(GROW_ACTIONS.confirmNote(id)),
  toggleNoteModal: toggled => dispatch(toggleNoteModal(toggled)),
});

export default connect(null, mapDispatch)(ConfirmButton);
