import qs from 'qs';
import fetchApi from 'fetchApi';
import { PLAN_ACTIONS } from 'redux/actions/planActions';
import { filterSelectedPosts, getUniquePostsInDates } from 'pages/Grow/Utils/utils';
import { GROW_ACTION_TYPES, GROW_ACTIONS } from '../actions/growActions';
import { APP_URL } from '../../constants';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';
import store from '../store';

const growReducer = (state, action) => {
  const pageLength = 10;
  const headers = HEADERS();
  switch (action.type) {
    case GROW_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        growDomain: {
          ...state.growDomain,
          fetchingPosts: true,
        },
      };
    case GROW_ACTION_TYPES.FETCH_MANY:
      const tab = action.payload || state.growDomain.tab;
      const isTabCorrect = [
        'grow',
        'my-notes',
        'meetings',
        'actions',
        'achievements',
        'profile',
        'your_schedule',
      ].includes(tab);

      const queryString = qs.stringify({
        ...state.growDomain.filters,
        loaded: state.growDomain.loadedPostsCount,
        tab: isTabCorrect ? tab : 'grow',
      });

      (async () => {
        const response = await fetchApi(`/grow/feed?${queryString}`, { json: false });
        const json = await response.json();
        json.timestamp = response.timestamp;
        store.dispatch(GROW_ACTIONS.receivePosts(json));
      })();

      return {
        ...state,
        growDomain: {
          ...state.growDomain,
          fetchingPosts: true,
          tab,
        },
      };
    case GROW_ACTION_TYPES.RECEIVE_MANY:
      if (action.payload.timestamp < state.growDomain.timestamp) {
        return state;
      }

      if (action.payload.loaded === '0') {
        const { dates, posts, timestamp } = action.payload;

        return {
          ...state,
          growDomain: {
            ...state.growDomain,
            timestamp,
            posts,
            dates,
            fetchingPosts: false,
            loadedPostsCount: pageLength, // + action.payload.length was replaced by pageLength to avoid loading the post doubled by update over and over again
            hasMorePostsToLoad: posts.length >= pageLength,
            postErrors: null,
          },
        };
      }
      const ids = state.growDomain.posts.map(post => post.id);
      const filteredPosts = action.payload.posts.filter(post => ids.indexOf(post.id) < 0);
      const updatedPosts = [...state.growDomain.posts, ...filteredPosts];
      const postsAvailableToSelect = [...updatedPosts]
        .filter(post => filterSelectedPosts(post))
        .map(post => post.id);

      return {
        ...state,
        growDomain: {
          ...state.growDomain,
          timestamp: action.payload.timestamp,
          posts: updatedPosts,
          dates: getUniquePostsInDates([...state.growDomain.dates, ...action.payload.dates]),
          fetchingPosts: false,
          loadedPostsCount: state.growDomain.loadedPostsCount + pageLength, // + action.payload.length was replaced by pageLength to avoid loading the post doubled by update over and over again
          hasMorePostsToLoad: action.payload.posts.length >= pageLength,
          postErrors: null,
          allPostsSelected:
            JSON.stringify(state.growDomain.selectedPosts.sort()) ===
            JSON.stringify(postsAvailableToSelect.sort()),
        },
      };
    case GROW_ACTION_TYPES.RESET_MANY:
      return {
        ...state,
        growDomain: {
          ...state.growDomain,
          posts: [],
          loadedPostsCount: 0,
          hasMorePostsToLoad: true,
          postErrors: null,
        },
      };
    case GROW_ACTION_TYPES.SAVE_SCROLL:
      return {
        ...state,
        growDomain: {
          ...state.growDomain,
          scrollPosition: action.payload,
        },
      };
    case GROW_ACTION_TYPES.RESET_FILTERS:
      return {
        ...state,
        growDomain: {
          ...state.growDomain,
          filters: {
            types: [],
            hashtags: [],
            users: [],
          },
        },
      };
    case GROW_ACTION_TYPES.FILTERS_FROM_OBJECT:
      return {
        ...state,
        growDomain: {
          ...state.growDomain,
          filters: action.payload,
        },
      };
    case GROW_ACTION_TYPES.FETCH_POSTS_COUNT:
      const query = qs.stringify({
        tab: state.growDomain.tab,
        ...state.growDomain.filters,
      });
      fetch(`${APP_URL}/grow/posts_count?${query}`, { method: 'get', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            store.dispatch(GROW_ACTIONS.receivePostsCount(jsonResponse));
          });
        });
      return {
        ...state,
        growDomain: {
          ...state.growDomain,
          fetchingPostsCount: true,
        },
      };
    case GROW_ACTION_TYPES.RECEIVE_POSTS_COUNT:
      return {
        ...state,
        growDomain: {
          ...state.growDomain,
          postsCount: action.payload.postsCount,
          fetchingPostsCount: false,
        },
      };
    case GROW_ACTION_TYPES.RESOLVE_NOTE:
    case GROW_ACTION_TYPES.CONFIRM_NOTE:
      fetch(
        `${APP_URL}/member_notes/${
          action.type === GROW_ACTION_TYPES.RESOLVE_NOTE ? 'resolve' : 'confirm'
        }/${action.payload.id}`,
        { method: 'put', headers }
      )
        .then(response => response.json())
        .then(json => {
          store.dispatch(GROW_ACTIONS.receiveResolveStatus(action.payload.id, json));
          store.dispatch(GROW_ACTIONS.reloadProgressBoard());
        });
      return state;
    case GROW_ACTION_TYPES.RECEIVE_RESOLVE_STATUS:
      const postIndex = state.growDomain.posts
        .map(post => (post.note ? post.note.id : null))
        .indexOf(action.payload.id);
      const newState = JSON.parse(JSON.stringify(state));
      if (postIndex > -1) {
        newState.growDomain.posts[postIndex].note = {
          ...newState.growDomain.posts[postIndex].note,
          ...action.payload.json.note,
        };
      }
      return newState;
    case GROW_ACTION_TYPES.DELETE_POST:
      const reloadFeed = action.payload.type === 'ManagerPromptNote';
      const endpoint = action.payload.type.includes('Win') ? 'win' : 'member_notes';
      fetch(`${APP_URL}/${endpoint}/${action.payload.id}`, { method: 'delete', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(() => {
          store.dispatch(GROW_ACTIONS.setLoadingPosts());
          if (reloadFeed) {
            store.dispatch(GROW_ACTIONS.resetPosts());
            store.dispatch(GROW_ACTIONS.fetchPosts());
          } else {
            store.dispatch(GROW_ACTIONS.removeDeletedPost(action.payload.id, action.payload.type));
          }
          store.dispatch(GROW_ACTIONS.reloadProgressBoard());
          store.dispatch(PLAN_ACTIONS.fetchPlanLimitations());
        });
      return state;
    case GROW_ACTION_TYPES.REMOVE_DELETED_POST:
      const type = action.payload.type.includes('Win') ? 'win' : 'note';
      const newPosts = state.growDomain.posts.filter(post =>
        !post[type] ? true : post[type].id !== action.payload.id
      );

      const newPostsIds = newPosts.map(post => post.id);
      const postIdsToRemove = state.growDomain.posts
        .filter(post => !newPostsIds.includes(post.id))
        .map(post => post.id);
      const newDates = state.growDomain.dates
        .map(date => {
          const newPostIds = date.post_ids.filter(
            postIdInDate => !postIdsToRemove.includes(postIdInDate)
          );
          return { ...date, post_ids: newPostIds };
        })
        .filter(date => date.post_ids.length);
      return {
        ...state,
        growDomain: {
          ...state.growDomain,
          dates: newDates,
          posts: newPosts,
          loadedPostsCount: state.growDomain.loadedPostsCount - 1,
          fetchingPosts: false,
        },
      };
    case GROW_ACTION_TYPES.RECEIVE_POST:
      return {
        ...state,
        growDomain: {
          ...state.growDomain,
          post: action.payload,
        },
      };
    case GROW_ACTION_TYPES.RELOAD_PROGRESS_BOARD:
      return {
        ...state,
        growDomain: {
          ...state.growDomain,
          reloadProgressBoard: !state.growDomain.reloadProgressBoard,
        },
      };
    case GROW_ACTION_TYPES.RESET_POSTS_SELECTION:
      return {
        ...state,
        growDomain: {
          ...state.growDomain,
          selectedPosts: [],
          allPostsSelected: false,
        },
      };
    case GROW_ACTION_TYPES.SELECT_POST:
      const id = action.payload.id;
      const array = [...state.growDomain.selectedPosts];
      const indexOf = array.indexOf(id);
      if (indexOf < 0) {
        array.push(id);
      } else {
        array.splice(indexOf, 1);
      }
      return {
        ...state,
        growDomain: {
          ...state.growDomain,
          selectedPosts: array,
        },
      };
    case GROW_ACTION_TYPES.SELECT_ALL_POSTS:
      const filteredPostsIds = [...state.growDomain.posts]
        .filter(post => filterSelectedPosts(post))
        .map(post => post.id);
      return {
        ...state,
        growDomain: {
          ...state.growDomain,
          selectedPosts: filteredPostsIds,
          allPostsSelected: true,
        },
      };
    case GROW_ACTION_TYPES.REMOVE_DELETED_POSTS:
      const posts = [...state.growDomain.posts].filter(
        post => !state.growDomain.selectedPosts.includes(post.id)
      );
      const dates = state.growDomain.dates
        .map(date => {
          const newPostIds = date.post_ids.filter(
            postIdInDate => !state.growDomain.selectedPosts.includes(postIdInDate)
          );
          return { ...date, post_ids: newPostIds };
        })
        .filter(date => date.post_ids.length);
      return {
        ...state,
        growDomain: {
          ...state.growDomain,
          posts: posts,
          dates: dates,
          loadedPostsCount:
            state.growDomain.loadedPostsCount - state.growDomain.selectedPosts.length,
          fetchingPosts: false,
        },
      };
    default:
      return state;
  }
};
export default growReducer;
