import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import qs from 'qs';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';

const getFilterFromType = type => {
  switch (type) {
    case 'note':
      return 'my-notes';
    case 'meeting':
      return 'meetings';
    case 'win':
      return 'achievements';
    case 'goal':
    case 'manager prompt':
      return 'actions';
    default:
      return null;
  }
};

export const handleTabRedirect = (type, location, push) => {
  const isOnGrowProfile = location.pathname === growProfileUrl;
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const filter = getFilterFromType(type);
  const redirectQuery = qs.stringify({ tab: filter });
  return isOnGrowProfile && query?.tab === filter
    ? null
    : push(`/${growProfileUrl}?${redirectQuery}`);
};

export const getResponseTypeFromNoteType = type => {
  switch (type) {
    case noteTypes.managerPrompt:
      return 'manager prompt';
    case noteTypes.meetingPreparation:
    case noteTypes.meeting:
    case noteTypes.quickMeeting:
      return 'meeting';
    case noteTypes.goal:
      return 'goal';
    default:
      return 'note';
  }
};
