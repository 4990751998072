import Dropdown, { DropdownWrap } from 'components/shared/Dropdown';
import React from 'react';
import Delete from 'pages/Grow/GrowPost/NoteDropdown/Delete';
import AddToProgressBoard from 'pages/Grow/GrowPost/NoteDropdown/AddToProgressBoard';
import RemoveFromProgressBoard from 'pages/Grow/GrowPost/NoteDropdown/RemoveFromProgressBoard';
import PropTypes from 'prop-types';
import Resolve from 'pages/Grow/GrowPost/NoteDropdown/Resolve';
import Convert from 'pages/Grow/GrowPost/NoteDropdown/Convert';
import { connect } from 'react-redux';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';

const NoteDropdown = ({
  postId,
  object,
  type,
  progressStatus,
  removeNote,
  removeNoteClickHandle,
  userId,
  addToProgressBoardEnabled,
  preparationNote,
}) => {
  const allowEditing =
    (object.owner_id === userId || object.author_id === userId) &&
    object.type !== noteTypes.managerPromptPreparation;

  return (
    <DropdownWrap>
      <Dropdown type={type} assignee={Boolean(object.assignee)}>
        {(type.includes('Note') || ['MeetingStepNote', 'SharedMeetingStep'].includes(type)) &&
          (object.type === noteTypes.goal || object.type === noteTypes.meetingStep) &&
          object.status !== 'resolved' && <Resolve id={object.id} />}
        {allowEditing && !type.includes('MeetingStepNote') && (
          <Convert
            note={object}
            type="edit"
            progressStatus={object.type !== noteTypes.meetingPreparation ? progressStatus : null}
            preparationNote={preparationNote}
          />
        )}
        {type.includes('Note') && progressStatus == null && addToProgressBoardEnabled === true && (
          <AddToProgressBoard id={postId} />
        )}
        {type.includes('Note') && progressStatus !== null && removeNote && (
          <RemoveFromProgressBoard id={postId} removeNoteClickHandle={removeNoteClickHandle} />
        )}
        {type.includes('Note') &&
          object.owner_id === userId &&
          object.type === noteTypes.freeText && <Convert note={object} type="goal" />}
        {type.includes('Note') &&
          object.owner_id === userId &&
          (object.type === noteTypes.freeText || object.type === noteTypes.goal) && (
            <Convert note={object} type="win" />
          )}
        {(object.owner_id === userId || object.author_id === userId) &&
          !type.includes('MeetingStepNote') && <Delete object={object} type={type} />}
      </Dropdown>
    </DropdownWrap>
  );
};

NoteDropdown.propTypes = {
  postId: PropTypes.number,
  object: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  progressStatus: PropTypes.string,
  addToProgressBoardEnabled: PropTypes.bool,
};

NoteDropdown.defaultProps = {
  addToProgressBoardEnabled: false,
};

const mapStateToProps = state => ({
  userId: state.usersDomain.user.id,
});

export default connect(mapStateToProps)(NoteDropdown);
