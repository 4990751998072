import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Modal } from 'react-bootstrap';
import { CSVReader } from 'react-papaparse';

import { setCSVUsers, resetCSVUsers, setShowAddUserModal } from 'redux/app/actions';
import { ReactComponent as FileIcon } from 'components/company_structure/setup_mode/assets/file_upload.svg';
import { ReactComponent as QuestionIcon } from 'components/company_structure/setup_mode/assets/question_mark.svg';
import { connect } from 'react-redux';
import { downloadCsv } from '../../../helpers';

const CSVModal = styled(Modal)`
  .modal-content {
    padding: 36px 48px;
    text-align: center;
    width: 680px;
  }
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #11a9ff;
  margin-bottom: 36px;
`;

const CloseCross = styled.div`
  top: 0;
  right: 0;
  position: absolute;
  width: 30px;
  height: 30px;
  background: #11a9ff;
  border-radius: 100%;
  cursor: pointer;
  transform: translate(50%, -50%);
  &:before,
  &:after {
    display: block;
    content: '';
    height: 2px;
    width: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    background: white;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

const UploadIcon = styled(FileIcon)`
  margin-right: 20px;
`;

const DropzoneWrapper = styled.div`
  height: 140px;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
`;

const DropzonePlaceholder = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(0%, -50%);
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const DropzonePlaceholderTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropzonePlaceholderTitle = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #11a9ff;
  margin: 0 0 6px 0;
  padding: 0;
`;

const DropzonePlaceholderSubtitle = styled.h4`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #c4c4c4;
  margin: 0;
  padding: 0;
  text-align: left;
`;

const Hightlight = styled.span`
  font-weight: 900;
  text-decoration: underline;
`;

const Hint = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
  align-items: center;
`;

const QuestionMark = styled(QuestionIcon)`
  margin-right: 10px;
`;

const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const QuestionDescription = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
`;

const DownloadTemplateLink = styled.a`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #11a9ff;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    color: #11a9ff;
  }
`;

const SubmitButton = styled.button`
  margin: 0;
  padding: 11px 24px;
  outline: none;
  background-color: #11a9ff;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  border: 1px solid #11a9ff;
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: #fe483c;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 20px;
  text-align: left;
`;

const CSVInputStyles = {
  dropArea: {
    borderWidth: '1px',
    borderColor: '#11a9ff',
    borderRadius: 20,
  },
  dropAreaActive: {
    borderColor: '#11a9ff',
  },
  dropFile: {
    width: '100%',
    height: '100%',
    background: 'transparent',
  },
  fileSizeInfo: {
    color: '#11a9ff',
    backgroundColor: 'transparent',
    borderRadius: 3,
    lineHeight: 1,
    marginBottom: '0.5em',
    padding: '0 0.4em',
  },
  fileNameInfo: {
    color: '##11a9ff',
    backgroundColor: 'transparent',
    borderRadius: 3,
    fontSize: 14,
    lineHeight: 1,
    padding: '0 0.4em',
  },
  removeButton: {
    color: '#11a9ff',
  },
  progressBar: {
    backgroundColor: '#11a9ff',
  },
};

const AddCSVUsersModal = ({
  close,
  csvUsers,
  showAddCSVUserModal,
  setCSVUsers,
  resetCSVUsers,
  setShowAddUserModal,
}) => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);

  const handleOnDrop = data => {
    setProcessing(true);
    setError(null);
    const users = data
      .filter(user => Object.keys(user.data).length <= 4 && !Boolean(user.errors.length))
      .map(user => user.data);
    setCSVUsers(users);
    setError(
      Boolean(users.length) && users.length <= 500
        ? null
        : 'The file does not contain valid data or there are too many users in file.'
    );
    setFileUploaded(Boolean(users.length));
    setProcessing(false);
  };

  const handleOnError = () => {
    setProcessing(false);
    setError('Something went wrong. Please try again.');
  };

  const handleOnRemoveFile = () => {
    setFileUploaded(false);
    setError(null);
    resetCSVUsers();
  };

  const handleOnSubmit = () => {
    setProcessing(true);
    if (!Boolean(csvUsers.length) || error) return;
    setShowAddUserModal(true);
  };

  const handleOnClose = () => {
    resetCSVUsers();
    close();
  };

  const handleDownloadCSV = () => {
    const data = [
      ['email', 'first_name', 'last_name', 'role'],
      ['mail@example.com', 'John', 'Doe', 'Management'],
    ];
    downloadCsv(data, 'heelix_users_sample.csv');
  };

  return (
    <CSVModal
      show={showAddCSVUserModal}
      onHide={handleOnClose}
      dialogClassName="add-csv-users-modal"
      backdrop
      keyboard
    >
      <CloseCross onClick={handleOnClose} />
      <Title>Add users by uploading CSV file</Title>
      <DropzoneWrapper>
        <CSVReader
          config={{ header: true }}
          onDrop={handleOnDrop}
          onError={handleOnError}
          addRemoveButton
          onRemoveFile={handleOnRemoveFile}
          style={CSVInputStyles}
        >
          <DropzonePlaceholder>
            <UploadIcon />
            <DropzonePlaceholderTitleWrapper>
              <DropzonePlaceholderTitle>
                Drop your CSV file here or <Hightlight>choose file manually</Hightlight>
              </DropzonePlaceholderTitle>
              <DropzonePlaceholderSubtitle>500 users max</DropzonePlaceholderSubtitle>
            </DropzonePlaceholderTitleWrapper>
          </DropzonePlaceholder>
        </CSVReader>
      </DropzoneWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Hint>
        <QuestionMark />
        <DownloadWrapper>
          <QuestionDescription>
            Looking for advice on how to better organise your CSV file?&nbsp;
          </QuestionDescription>{' '}
          <DownloadTemplateLink onClick={handleDownloadCSV}>
            Download our template here
          </DownloadTemplateLink>
        </DownloadWrapper>
      </Hint>
      <SubmitButton
        disabled={!Boolean(csvUsers.length) || !fileUploaded || processing || error}
        onClick={handleOnSubmit}
      >
        Save & close
      </SubmitButton>
    </CSVModal>
  );
};

const mapStateToProps = state => ({
  csvUsers: state.app.csvUsers,
});

const mapDispatchToProps = dispatch => ({
  resetCSVUsers: () => dispatch(resetCSVUsers()),
  setCSVUsers: users => dispatch(setCSVUsers(users)),
  setShowAddUserModal: toggle => dispatch(setShowAddUserModal(toggle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCSVUsersModal);
