import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as TrendSuccess } from 'pages/Grow/GrowPost/assets/trend_success.svg';
import {
  BlueButtonStyled,
  Container,
  Content,
  GrayButton,
  Picture,
} from 'pages/Grow/GrowPost/ManagerPrompts/layout';
import { withRouter } from 'react-router-dom';
import { handleSeeMore, noteShape } from 'pages/Grow/GrowPost/ManagerPrompts/common';
import { GROW_ACTIONS } from 'redux/actions/growActions';
import { connect } from 'react-redux';

const ManagerAlertDeterioration = ({ note, completeActionPlan, history }) => {
  return (
    <Container>
      <Picture>
        <TrendSuccess />
      </Picture>
      <Content>{note.content}</Content>
      <BlueButtonStyled onClick={() => completeActionPlan(note)}>Mark as complete</BlueButtonStyled>
      <GrayButton
        onClick={() =>
          handleSeeMore(history.push, note.action_plan.team_id, note.action_plan.factor_position)
        }
      >
        See more
      </GrayButton>
    </Container>
  );
};

ManagerAlertDeterioration.propTypes = {
  note: PropTypes.shape(noteShape).isRequired,
  completeActionPlan: PropTypes.func.isRequired,
};

const mapDispatch = {
  completeActionPlan: GROW_ACTIONS.completeActionPlan,
};

export default withRouter(connect(null, mapDispatch)(ManagerAlertDeterioration));
