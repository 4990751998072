/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { css, jsx } from '@emotion/core';
import ReactDOM from 'react-dom';

const FlipSelect = ({ onOpen = () => undefined, ...props }) => {
  const [flipped, setFlipped] = useState(true);
  let ref = null;

  const handleFlip = () => {
    if (ref) {
      const element = ReactDOM.findDOMNode(ref);
      setFlipped(element.getBoundingClientRect().bottom + 200 > window.innerHeight);
    }
  };

  useEffect(() => {
    handleFlip();
  });

  useEffect(() => {
    document.addEventListener('resize', handleFlip, true);
    document.addEventListener('scroll', handleFlip, true);
    return () => {
      document.removeEventListener('resize', handleFlip);
      document.removeEventListener('scroll', handleFlip);
    };
  }, []);

  return (
    <Select
      onOpen={() => {
        handleFlip();
        return onOpen();
      }}
      ref={inst => (ref = inst)}
      css={
        flipped &&
        css`
          .Select-menu-outer {
            top: auto;
            bottom: 100%;
            border-bottom-color: #e6e6e6;
            border-top-color: #ccc;
            border-radius: 4px 4px 0 0;
            z-index: 2;
          }
        `
      }
      {...props}
    />
  );
};

export default FlipSelect;
