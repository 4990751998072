/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import OnboardingButton from './partials/OnboardingButton';

const Survey = ({ nextStep, ...props }) => {
  return (
    <>
      <div
        css={css`
          font-size: 16px;
          padding: 13px 29px 16px 29px;
          line-height: 150%;
        `}
      >
        Before we go any further, we <b>are not</b> a survey tool. We improve&nbsp; business
        performance by measuring the emotional state of your&nbsp; people in real-time. If you're
        after a survey tool, get in touch and we&nbsp; can recommend who to speak with.
      </div>
      <div
        css={css`
          display: block;
          padding-bottom: 32px;
          text-align: center;
        `}
      >
        <OnboardingButton onClick={() => nextStep()} label="Continue tour" submit />
        <OnboardingButton href="mailto:hello@heelix.com" label="Get in touch" />
      </div>
    </>
  );
};

export default Survey;
