import React from 'react';
import { Button, Header } from './layout';
import DateWrapper from 'pages/Grow/Meeting/DateWrapper';
import PropTypes from 'prop-types';

const MeetingPreparationAlert = ({ note, user, editAgendaModal }) => {
  return (
    <>
      <Header>
        {note.title}{' '}
        {note.member ? `${note.member.first_name} ${note.member.last_name}` : (note.team ? note.team.name: "")}
      </Header>
      <DateWrapper note={note} user={user} />
      <Button onClick={editAgendaModal}>Begin preparation</Button>
    </>
  );
};

MeetingPreparationAlert.propTypes = {
  note: PropTypes.shape({
    title: PropTypes.string.isRequired,
    member: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }),
    team: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    steps: PropTypes.array.isRequired,
    media: PropTypes.array.isRequired,
    files: PropTypes.array.isRequired,
    additional_notes: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({}).isRequired,
  editAgendaModal: PropTypes.func.isRequired,
};

export default MeetingPreparationAlert;
