/** @jsx jsx */
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import Masonry from 'react-masonry-component';
import qs from 'qs';
import styled from '@emotion/styled';
import {
  clearActionBody,
  setAction,
  setShowActionModal,
  toggleNoteModal,
  toggleUserModal,
} from 'redux/app/actions';
import Page from 'components/layouts/Page';
import Sidebar from 'components/organisation/partials/Sidebar';
import Loader from 'components/shared/Loader';
import { css, jsx } from '@emotion/core';
import { INITIAL_GROW_FILTERS, APP_URL } from 'constants.js';
import FeedDateRange from 'components/shared/FeedDateRange';
import GrowContext from 'pages/Grow/GrowContext';
import {
  HIDE_MENTION_TOOLTIP,
  SHOW_MENTION_TOOLTIP,
} from 'components/shared/tooltips/MentionTooltip';
import { BODY_CLASSES, CHECK_UNAUTHORIZED, downloadCsv, FORMAT_FILTERS, HEADERS } from 'helpers';
import { GROW_ACTIONS } from 'redux/actions/growActions';
import BlankState from 'components/grow/blank_state/BlankState';
import { shouldRenderBlankState } from 'pages/Grow/Utils/utils';
import GrowPost from './GrowPost';
import Filter from './Filter';

import oldPostStyles from './GrowPost/style.sass';
import FeedHeader from './FeedHeader';

import GrowPostModal from './GrowPostModal';
import DropdownBlue from './DropdownBlue';
import ScheduleSection from './ScheduleSection';
import SchedulePost from './SchedulePost';
import TeamMemberSelect from './ProgressBoard/TeamMemberSelect';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';

const MasonryStyled = styled(Masonry)`
  margin-left: -10px;
  margin-right: -10px;
  ${oldPostStyles}
`;

const Counter = styled.span`
  padding: 3px 7px 3px 6px;
  background: #ffffff;
  border-radius: 14px;
  font-size: 11px;
  margin-left: 11px;
  color: #c4c4c4;
`;

class Feed extends Component {
  static contextType = GrowContext;

  initialFilters = INITIAL_GROW_FILTERS;

  state = {
    exportModeOn: false,
    selectedPosts: [],
    selectedMember: null,
    growPostId: '',
    growPostType: '',
    postRenderAlignment: false,
    showBlankState: true,
  };

  closeBlankState = () => this.setState({ showBlankState: false });

  handleSelectPost = (id, post, postsType) => {
    const array = [...this.state.selectedPosts];
    if (postsType === 'achievement') {
      id = post.id;
    }
    const indexOf = array.indexOf(id);
    if (indexOf < 0) {
      this.setState({
        selectedPosts: [...this.state.selectedPosts, id],
      });
    } else {
      array.splice(indexOf, 1);
      this.setState({ selectedPosts: array });
    }
  };

  handlePostModal = id => {
    const headers = HEADERS();
    fetch(`${APP_URL}/grow_post/${id}`, {
      method: 'get',
      headers,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => response.json())
      .then(response => {
        if (response.post) {
          this.setPostData(response.post, response.growPostId, response.growPostType);
        }
      })
      .catch(() => {});
  };

  openPostModal = (post, growPostId, growPostType) => {
    if (post.total_comments_count > 2) {
      this.handlePostModal(growPostId);
    } else {
      this.setPostData(post, growPostId, growPostType);
    }
  };

  setPostData = (post, growPostId, growPostType) => {
    this.setState({
      growPostId,
      growPostType,
    });
    this.props.receivePost(post);
    this.props.toggleNoteModal(true);
  };

  closeNoteModal = () => {
    this.props.toggleNoteModal(false);
  };

  changeExportMode = () => {
    if (this.state.exportModeOn) {
      this.setState({ selectedPosts: [] });
    }
    this.setState({ exportModeOn: !this.state.exportModeOn });
  };

  resetPostsSelection = () => {
    this.setState({ selectedPosts: [] });
  };

  selectAllPosts = (event, posts) => {
    this.setState({ selectedPosts: event.target.checked ? posts : [] });
  };

  cancelExport = () => {
    this.setState({
      exportModeOn: false,
      selectedPosts: [],
    });
    window.scrollTo(0, 0);
  };

  exportSelected = () => {
    const { exportUrl } = this.props;
    const { selectedPosts } = this.state;
    if (exportUrl && selectedPosts.length) {
      const url = `/${exportUrl}/report`;
      const params = `?${qs.stringify({ data: selectedPosts }, { arrayFormat: 'brackets' })}`;
      this.cancelExport();
      const headers = HEADERS();
      fetch(APP_URL + url + params, {
        method: 'get',
        headers,
      })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            downloadCsv(jsonResponse.data, jsonResponse.fileName);
          });
        })
        .catch(() => {});
    }
  };

  componentDidMount() {
    const { plan, setErrorPage } = this.props;
    BODY_CLASSES(['grow', 'index', 'jobvibe-future']);
    if (plan === 'free') {
      setErrorPage(101);
    }
    const { search } = this.props.history.location;
    const emptySearch = search === '' || search.includes('google_calendar');
    if (this.props.history.location.pathname === `/${this.props.pageUrl}` && emptySearch) {
      this.props.filtersFromObject(this.initialFilters);
      this.context.setCallback(() => this.handleFetch());
    }
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(props) {
    const filtersCopy = JSON.parse(JSON.stringify(this.props.filters));
    const qsFilters = qs.parse(window.location.href.split('?')[1]);
    if (
      window.location.href.split('?')[1] !== 'undefined' &&
      window.location.href.indexOf(this.props.pageUrl) > 0
    ) {
      Object.keys(filtersCopy).map(key => {
        filtersCopy[key] = qsFilters[key] ? qsFilters[key] : this.initialFilters[key];
      });
      if (JSON.stringify(filtersCopy) !== JSON.stringify(this.props.filters)) {
        this.props.filtersFromObject(filtersCopy);
        this.context.setCallback(() => this.handleFetch());
        this.handlePostModal(filtersCopy.post);
      }
      if (this.props.postsCount !== props.postsCount && this.props.fetchMeetingFilters) {
        this.props.fetchMeetingFilters();
      }
    }
  }

  handleFetch = () => {
    this.props.resetPosts();
    this.props.fetchPosts(this.props.pageUrl);
    this.props.fetchPostsCount();
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY + window.innerHeight >= document.body.offsetHeight - 500) {
      if (
        this.props.fetchingPosts === false &&
        this.props.hasMorePostsToLoad &&
        window.scrollY > 0
      ) {
        this.props.fetchPosts(this.props.pageUrl);
      }
    }
  };

  // ---Hashtags listener and filter apply
  hashtagClickedHandler = e => {
    e.preventDefault();
    this.props.history.push(
      `feed?${qs.stringify(
        FORMAT_FILTERS(e.currentTarget, {
          space: this.props.filters.space,
          types: [],
          hashtags: [],
          string: '',
        })
      )}`
    );
  };

  mentionClickedHandler = e => {
    e.preventDefault();
    const mentionedId = parseInt(e.currentTarget.getAttribute('user-id'));
    if (mentionedId === this.props.user.id) {
      this.props.history.push(`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`);
    } else {
      return this.props.toggleUserModal(true, mentionedId);
    }
  };

  getPostsIds = (posts, type) => {
    const postIds = [];
    posts.forEach(post => {
      switch (type) {
        case 'win':
          postIds.push(post.win_id);
          break;
        case 'note':
        case 'meeting':
          postIds.push(post.note_id);
          break;
        case 'shoutout':
          postIds.push(post.post_id);
          break;
        case 'achievement':
          postIds.push(post.id);
          break;
      }
    });
    return postIds;
  };

  getPostId = (post, type) => {
    switch (type) {
      case 'win':
        return post.win_id;
      case 'note':
      case 'meeting':
        return post.note_id;
      case 'shoutout':
        return post.post_id;
      case 'achievement':
        return post.id;
      default:
        return post.id;
    }
  };

  tileRealignHandler = () => {
    this.setState({ postRenderAlignment: true });
  };

  openCreateModal = action => {
    this.props.clearActionBody();
    this.props.setAction(action);
    this.props.setShowActionModal(true);
  };

  handleMemberFilterSelect = member => {
    this.setState({ selectedMember: member ? member.value : '' });
    const filters = JSON.parse(JSON.stringify(this.props.filters));
    filters.member = member ? member.value : '';
    this.props.history.push(`/${this.props.pageUrl}?${qs.stringify(filters) || ' '}`);
  };

  render() {
    const {
      pageTitle,
      showCreateNoteButton,
      showCreateMeetingButton,
      posts,
      postsType,
      isLeadOrAdmin,
      BlankSpaceComponent,
      fetchingPosts,
      blankStateProps,
    } = this.props;
    const { exportModeOn, selectedPosts, showBlankState, selectedMember } = this.state;
    const isMeeting = postsType === 'meeting';
    const isSchedule = postsType === 'schedule';
    const firstMeetingNoteIndex = posts.findIndex(post => post.type === 'MeetingNote');
    const firstMeetingPreparationNoteIndex = posts.findIndex(
      post => post.type === 'MeetingPreparationNote'
    );

    const members =
      this.props.users && this.props.users.length
        ? this.props.users.map(teamMember => {
            return { value: teamMember.id, label: teamMember.name };
          })
        : [];

    return (
      <>
        <FeedHeader
          title={pageTitle}
          exportModeOn={exportModeOn}
          exportSelected={this.exportSelected}
          changeExportMode={this.changeExportMode}
          resetPostsSelection={this.resetPostsSelection}
          selectAllPosts={this.selectAllPosts}
          posts={this.getPostsIds(posts, postsType)}
          selectedPosts={selectedPosts}
          showCreateMeetingButton={showCreateMeetingButton}
          showBackButton={isSchedule}
          hideCounter={isSchedule}
          allowExport={!isSchedule}
        />
        {shouldRenderBlankState(showBlankState, posts, fetchingPosts) && BlankSpaceComponent && (
          <BlankState
            Component={BlankSpaceComponent}
            blankStateProps={blankStateProps}
            closeBlankState={this.closeBlankState}
            openModal={this.openCreateModal}
          />
        )}
        <Page.Section>
          <Page.Wrapper>
            <Page.Content
              style={{
                flex: '1',
                padding: '0 15px',
                overflowX: 'visible',
              }}
            >
              {isMeeting && <ScheduleSection />}
              <MasonryStyled
                onLayoutComplete={() => {
                  const renderedHashtags = document.getElementsByClassName('inline-hashtag');
                  Array.from(renderedHashtags).forEach(hashtag => {
                    hashtag.removeEventListener('click', this.hashtagClickedHandler);
                    hashtag.addEventListener('click', this.hashtagClickedHandler, false);
                  });

                  Array.from(document.getElementsByClassName('inline-mention')).forEach(mention => {
                    mention.removeEventListener('mouseover', SHOW_MENTION_TOOLTIP);
                    mention.addEventListener('mouseover', SHOW_MENTION_TOOLTIP, false);
                    mention.removeEventListener('mouseout', HIDE_MENTION_TOOLTIP);
                    mention.addEventListener('mouseout', HIDE_MENTION_TOOLTIP, false);
                    mention.addEventListener('click', this.mentionClickedHandler, false);
                  });
                }}
              >
                {!isSchedule &&
                  posts.map((post, index) => (
                    <GrowPost
                      key={post.id}
                      isFirstMeetingPreparationNote={index === firstMeetingPreparationNoteIndex}
                      isFirstMeetingNote={index === firstMeetingNoteIndex}
                      post={post}
                      exportModeOn={exportModeOn}
                      handleSelectPost={id => this.handleSelectPost(id, post, postsType)}
                      isPostSelected={
                        selectedPosts
                          ? selectedPosts.includes(this.getPostId(post, postsType))
                          : false
                      }
                      tileRealignHandler={this.tileRealignHandler}
                      openPostModal={this.openPostModal}
                    />
                  ))}
                {isSchedule && posts.map(post => <SchedulePost key={post.id} post={post} />)}
              </MasonryStyled>
              {this.props.hasMorePostsToLoad && <Loader />}
            </Page.Content>
            <Page.Sidebar>
              {this.props.types && (
                <>
                  <Sidebar.Header>Display in my feed</Sidebar.Header>
                  {this.props.types
                    .filter(
                      type =>
                        !(
                          !this.props.feedEnabled &&
                          ['given-shoutouts', 'received-shoutouts'].includes(type.filter)
                        )
                    )
                    .map((type, index) => (
                      <Filter
                        key={index}
                        label={type.caption}
                        type={type.filter}
                        checked={this.props.filters.types.indexOf(type.filter) < 0}
                        filter={e =>
                          this.props.history.push(
                            `/${this.props.pageUrl}?${
                              qs.stringify(FORMAT_FILTERS(e.currentTarget, this.props.filters)) ||
                              ' '
                            }`
                          )
                        }
                      />
                    ))}
                </>
              )}
              <FeedDateRange isSchedule={isSchedule} />
              {showCreateNoteButton && (
                <DropdownBlue
                  options={[
                    {
                      label: 'Add goal',
                      onClick: () => this.openCreateModal('create-note-goal'),
                    },
                    {
                      label: 'Add win',
                      onClick: () => this.openCreateModal('create-note-win'),
                    },
                    ...(isLeadOrAdmin
                      ? [
                          {
                            label: 'Private team note',
                            onClick: () => this.openCreateModal('create-note-private'),
                          },
                        ]
                      : []),
                  ]}
                  onClick={() => this.openCreateModal('create-note')}
                  css={css`
                    width: 185px;
                  `}
                >
                  Create note
                </DropdownBlue>
              )}
              {isMeeting && (
                <>
                  <Sidebar.Header>By person</Sidebar.Header>
                  <TeamMemberSelect
                    onSelect={this.handleMemberFilterSelect}
                    selectedMember={selectedMember}
                    teamMembers={members}
                    sidebar
                  />
                </>
              )}
            </Page.Sidebar>
            {this.props.post && (
              <GrowPostModal
                showNote={this.props.showNoteModal}
                showActionModal={this.props.showActionModal}
                closeNoteModal={this.closeNoteModal}
                post={this.props.post}
                growPostId={this.state.growPostId}
                growPostType={this.state.growPostType}
              />
            )}
          </Page.Wrapper>
        </Page.Section>
      </>
    );
  }
}

Feed.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  setErrorPage: PropTypes.func.isRequired,
  pageTitle: PropTypes.string.isRequired,
  pageUrl: PropTypes.string.isRequired,
  types: PropTypes.object,
  showCreateNoteButton: PropTypes.bool,
  BlankSpaceComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

Feed.defaultProps = {
  showCreateNoteButton: false,
};

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  filters: state.growDomain.filters,
  posts: state.growDomain.posts,
  postsCount: state.growDomain.postsCount,
  post: state.growDomain.post,
  fetchingPosts: state.growDomain.fetchingPosts,
  hasMorePostsToLoad: state.growDomain.hasMorePostsToLoad,
  loadedPostsCount: state.growDomain.loadedPostsCount,
  spaces: state.spacesDomain.spaces
    ? state.spacesDomain.spaces.filter(
        space =>
          !space.account_default_space &&
          (space.is_member || space.is_team_lead || space.is_visitor)
      )
    : [],
  isLeadOrAdmin: Boolean(
    state.usersDomain.user.isAccountAdmin || state.usersDomain.user.formalLeadTeamsIds.length
  ),
  showNoteModal: state.app.showNoteModal,
  showActionModal: state.app.showActionModal,
  feedEnabled: state.usersDomain.feedEnabled,
});

const mapDispatch = {
  fetchPosts: url => GROW_ACTIONS.fetchPosts(url),
  resetPosts: GROW_ACTIONS.resetPosts,
  saveScroll: position => GROW_ACTIONS.saveScroll(position),
  filtersFromObject: object => GROW_ACTIONS.filtersFromObject(object),
  resetFilters: () => GROW_ACTIONS.resetFilters(),
  fetchPostsCount: GROW_ACTIONS.fetchPostsCount,
  receivePost: post => GROW_ACTIONS.receivePost(post),
  toggleUserModal: (toggled, id) => toggleUserModal(toggled, id),
  toggleNoteModal: toggled => toggleNoteModal(toggled),
  setAction,
  setShowActionModal,
  clearActionBody,
};

export default connect(mapStateToProps, mapDispatch)(Feed);
