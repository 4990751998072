import React, { Component } from 'react';
import delayUnmounting from '../../HOC/delayUnmounting';

class LeadsWrapTitle extends Component {
  state = {
    message: '',
    mounted: false,
  };

  componentDidMount() {
    this.height = window.getComputedStyle(this.self).getPropertyValue('height');
    this.self.style.height = 0;
    setTimeout(() => {
      if (!this.self) return;
      this.self.style.height = this.height;
      this.setState({
        message: this.props.message,
        mounted: true,
      });
    }, 0);
  }

  componentWillReceiveProps(props) {
    if (!props.isMounted) this.self.style.height = 0;
    else this.self.style.height = this.height;
    if (this.state.mounted !== props.isMounted) this.setState({ mounted: !this.state.mounted });
  }

  render() {
    return (
      <h3 className={`${this.state.mounted ? 'mounted' : ''}`} ref={el => (this.self = el)}>
        {this.props.content}
      </h3>
    );
  }
}

export default delayUnmounting(LeadsWrapTitle);
