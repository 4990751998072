import { withRouter } from 'react-router-dom';
import qs from 'qs';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { APP_URL } from '../../../constants';
import { CHECK_UNAUTHORIZED } from '../../../helpers';
import InviteWelcome from './InviteWelcome';
import steps from '../steps';

const InviteWelcomeContainer = ({ location, handleNames, handleToken, handleGoogle }) => {
  const [data, setData] = useState({});
  const token = qs.parse(location.search, { ignoreQueryPrefix: true }).invitation_token;

  useEffect(() => {
    fetchToken();
  }, []);

  const fetchToken = async () => {
    if (localStorage != null) localStorage.clear();

    const response = await fetch(`${APP_URL}/invitation/data?invitation_token=${token}`, {
      method: 'get',
    });
    const authorized = CHECK_UNAUTHORIZED(response);
    const json = await authorized.json();
    if (json.redirect) {
      window.location.href = json.redirect;
      return;
    }

    if (json.error) {
      handleToken(json);
    } else {
      setData({ ...json });
      handleToken(token);
    }
  };

  const onGoogleFailure = () => {
    window.history.push(`/login?step${steps.inviteError}`);
  };

  const onGoogleSuccess = async data => {
    const response = await fetch(
      `${APP_URL}/lookup_google?token=${data.accessToken}&invitationToken=${token}`
    );
    const json = await response.json();
    if (json.user === 'invited') {
      handleGoogle(data.accessToken);
    } else if (json.error) {
      handleGoogle({ error: json.error });
    } else {
      handleGoogle({ error: 'Invalid login credentials' });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    handleNames({
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
    });
  };

  return data.teamName ? (
    <InviteWelcome
      handleNames={handleSubmit}
      googleFailure={onGoogleFailure}
      googleResponse={onGoogleSuccess}
      {...data}
    />
  ) : null;
};

InviteWelcomeContainer.propTypes = {
  handleNames: PropTypes.func.isRequired,
  handleToken: PropTypes.func.isRequired,
  handleGoogle: PropTypes.func.isRequired,
};

export default withRouter(InviteWelcomeContainer);
