import React from 'react';
import { Link } from 'react-router-dom';
import { AWS_S3_URL } from '../../../constants';
import Owners from '../../shared/modals/Owners';

const PostOwners = props => {
  let ownersToDisplay = [...props.owners];

  if (props.owners.length === 1) {
    return (
      <div className="post-owners" id={`owners-${props.postId}`}>
        <div className="media-left media-middle">
          <img
            src={
              props.owners[0].image_uid !== null
                ? props.owners[0].image_url
                : `${AWS_S3_URL}public/images/defaults/user.jpg`
            }
            alt={props.owners[0].first_name}
            className="img-circle img-xs post-owner-single-icon"
          />
        </div>
        <p>
          Owned by
          <Link to={`/user/${props.owners[0].id}`} className="user-link">
            <strong>
              {' '}
              {props.owners[0].first_name} {props.owners[0].last_name}
            </strong>
          </Link>
        </p>
      </div>
    );
  }

  if (props.owners.length > 7) {
    ownersToDisplay = props.owners.slice(0, 7);
  }

  return (
    <div className="media-multiple post-owners" id={`owners-${props.postId}`}>
      <div
        className="media-left media-middle"
        onClick={props.showAllOwners}
        style={{ paddingRight: `${45 + (ownersToDisplay.length - 1) * 18}px` }}
      >
        {ownersToDisplay.map(owner => (
          <img
            key={owner.id}
            src={
              owner.image_uid !== null
                ? owner.image_url
                : `${AWS_S3_URL}public/images/defaults/user.jpg`
            }
            alt={owner.first_name}
            className="img-circle img-xs post-owner-multiple-icon"
          />
        ))}
      </div>
      <p onClick={props.showAllOwners}>
        Owned by
        <strong> multiple people</strong>
      </p>
      <Owners
        owners={props.owners}
        show={props.show}
        user={props.user}
        toggle={props.showAllOwners}
        ownersCount={props.owners.length}
      />
    </div>
  );
};

export default PostOwners;
