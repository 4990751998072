/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useCallback, useEffect, useState } from 'react';
import PlanModal from 'rbac/components/PlanModal/PlanModal';
import styled from '@emotion/styled';
import { ReactComponent as GreenArrow } from 'components/payments/PaymentChoose/green-arrow.svg';
import {
  ComparisonContainer,
  ComparisonDescription,
  ComparisonItem,
  ComparisonTitle,
} from 'components/payments/layout';
import fetchApi from 'fetchApi';
import UpgradeModal from 'components/payments/PaymentChoose/UpgradeModal';

const SaveContainer = styled.div`
  color: #52ba05;
  font-family: 'Kalam', serif;
  position: absolute;
  font-size: 16px;
  line-height: 26px;
  right: 0;
  bottom: 7px;
  display: flex;
  flex-direction: column;
`;
const GreenArrowStyled = styled(GreenArrow)`
  margin-left: 14px;
`;

const AnnualModalContent = ({ data }) => {
  const [width, setWidth] = useState(0);

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  return (
    <>
      <PlanModal.Title
        css={css`
          font-size: 18px;
          line-height: 21px;
          justify-content: center;
          align-items: center;
        `}
      >
        <PlanModal.Pig
          css={css`
            width: 30px;
            height: 30px;
            flex-shrink: 0;
            margin-right: 12px;
          `}
        />
        Switch to annual billing
      </PlanModal.Title>
      <PlanModal.Content
        css={css`
          font-size: 14px;
          margin-bottom: 25px;
        `}
      >
        By switching to annual billing you’ll gain more flexibility as well as a nice discount to go
        with it. Sound good? Check out what you’ll save below and hit the button to proceed.
      </PlanModal.Content>
      <ComparisonContainer>
        <ComparisonItem>
          <ComparisonTitle>Monthly payments</ComparisonTitle>
          <ComparisonDescription>
            ${data.perUser} per month x {data.users} = ${data.total}
          </ComparisonDescription>
        </ComparisonItem>
        <ComparisonItem>
          <ComparisonTitle>Annual billing</ComparisonTitle>
          <ComparisonDescription>
            <span
              css={css`
                position: relative;
              `}
            >
              {data.upgradePlanDetails}
              {data.upgradePlanMoreDetails && (
                <div>
                  <strong>{data.upgradePlanMoreDetails}</strong>
                </div>
              )}
              {Boolean(data.saveText) && (
                <SaveContainer
                  ref={measuredRef}
                  css={css`
                    right: -${width + 10}px;
                  `}
                >
                  {data.saveText}
                  <GreenArrowStyled />
                </SaveContainer>
              )}
            </span>
          </ComparisonDescription>
        </ComparisonItem>
      </ComparisonContainer>
    </>
  );
};

const SwitchToAnnualModal = ({ show, setShow }) => {
  const [prices, setPrices] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const response = await fetchApi('/payments/annual_modal');
    if (!response.show) return setShow(false);
    setPrices({
      perUser: response.perUserPrice,
      total: response.totalPrice,
      annual: response.annualPrice,
      saveText: response.saveText,
      users: response.activeUsers,
      ...response,
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <UpgradeModal
      data={prices}
      loading={loading}
      show={show}
      content={() => <AnnualModalContent data={prices} />}
      handleClose={() => setShow(false)}
    />
  );
};

export default SwitchToAnnualModal;
