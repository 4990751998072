import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SwitchButton from '../shared/buttons/SwitchButton';

class EditPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPasswordForm: false,
    };
  }

  passChange() {
    this.setState({ showPasswordForm: !this.state.showPasswordForm });
    this.props.passChange(!this.state.showPasswordForm);
  }

  render() {
    const { showPasswordForm } = this.state;
    return (
      <div className="form-group password-section">
        <div className="hidden u-margin-top-md" />
        <label className="control-label">Password</label>
        <p className="password-info">
          Your password is used to log in to both the mobile app and web app. Changing your password
          will log you out of all other devices.
        </p>
        <div
          className="form-group boolean optional checkbox-form-group"
          id="password-change-switch"
        >
          <SwitchButton turnedOn={showPasswordForm} onClick={() => this.passChange()} />
          <span>I'd like to change my password</span>
        </div>
        <div
          className="js-password-fields"
          style={{ display: this.state.showPasswordForm ? 'block' : 'none' }}
        >
          <div className="form-group password optional user_current_password">
            <label htmlFor="user_current_password" className="password optional control-label">
              Current password
            </label>
            <input
              type="password"
              className="password optional form-control"
              id="user_current_password"
              name="user[current_password]"
              value={this.props.currentPassword}
              onChange={e => this.props.setCurrentPassword(e)}
            />
          </div>
          <div className="form-group password optional user_password">
            <label htmlFor="user_password" className="password optional control-label">
              New password
            </label>
            <input
              type="password"
              className="password optional form-control"
              id="user_password"
              placeholder="Password"
              value={this.props.newPassword}
              onChange={e => this.props.setNewPassword(e)}
            />
          </div>
        </div>
        <div className="form-group notification-section">
          <label className="control-label">Notifications</label>
          <p>
            <span>
              You can manage all notifications at the
              <Link to="/notification_settings"> notification settings page</Link>
            </span>
          </p>
        </div>
      </div>
    );
  }
}

export default EditPasswordForm;
