import React, { Component } from 'react';

class LeadTile extends Component {
  state = {
    mounted: false,
  };

  componentDidMount() {
    setTimeout(() => this.setState({ mounted: true }), 5);
  }

  render() {
    return (
      <div
        className={`lead-tile-background ${this.state.mounted ? 'mounted' : ''}`}
        onMouseDown={e => e.stopPropagation()}
      >
        <div className="lead-tile">
          <span className="lead-tooltip">LEAD</span>
          <span className="user-name">
            {this.props.user.first_name} {this.props.user.last_name}
          </span>
          {this.props.setupStep === 1 && (
            <div
              className="cross"
              onClick={() => this.props.updateUserRole(this.props.user, this.props.team, false)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default LeadTile;
