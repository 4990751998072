import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ReCaptcha } from 'components/shared/Recaptcha';
import WelcomeContainer from '../WelcomeContainer';
import LoadingOverlay from '../LoadingOverlay';
import steps from '../steps';
import ErrorPage from '../ErrorPage';
import { HEADERS } from '../../../helpers';
import { APP_URL, CAPTCHA_KEY } from '../../../constants';

const EmailConfirmation = ({ email, step, history, selectStep }) => {
  const captcha = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [formEmail, setFormEmail] = useState(email);

  useEffect(() => {
    if (!email) history.push('/login');
  }, []);

  const setTitle = () => {
    switch (step) {
      case steps.newUser1:
        return "Looks like you're new!";
      case steps.newUser2:
        return 'Please verify your email';
    }
  };

  const verifyMail = async token => {
    const data = new FormData();
    data.append('email', formEmail);
    data.append('captcha_token', token);

    const headers = HEADERS();
    const response = await fetch(`${APP_URL}/confirmation/send`, {
      method: 'post',
      body: data,
      headers,
    });
    const json = await response.json();
    if (json.error) {
      setError(json.message);
    }

    selectStep(steps.newUser2);
    setIsLoading(false);
  };

  const onLoadRecaptcha = () => {
    if (captcha.current) {
      captcha.current.reset();
    }
  };

  const verifyCallback = token => {
    setIsLoading(true);
    verifyMail(token);
    captcha.current.reset();
  };

  const challengeCaptcha = e => {
    e.preventDefault();
    setFormEmail(e.target.email.value);
    captcha.current.execute();
  };

  return error ? (
    <ErrorPage title="Something went wrong" body={error} />
  ) : (
    <WelcomeContainer
      title={setTitle()}
      body={
        <>
          <LoadingOverlay toggled={isLoading} message="Verifying email" height="40%" />
          {step === steps.newUser1 ? (
            <form
              onSubmit={challengeCaptcha}
              action=""
              className="simple_form form-compact "
              id="new_user"
            >
              <div className="form-group email required user_email email-confirmation">
                <input
                  name="email"
                  type="email"
                  className="string email required form-control"
                  id="user_email"
                  placeholder="Start with your email"
                  value={formEmail}
                  onChange={e => setFormEmail(e.target.value)}
                  required
                />
              </div>
              <button
                disabled={isLoading}
                type="submit"
                className="btn btn-primary btn-full"
                id="sign-in-btn"
              >
                Continue with account creation
              </button>
            </form>
          ) : step === steps.newUser2 ? (
            <div className="verify-email">
              <div>
                Once you verify your email address, you and your team will be able to use Heelix
                Connect and Perform Pro <strong>free</strong> for 21 days.
              </div>
              <div>
                At the end of your 21 days you’ll be moved to our Free Product but we’ll also give
                you the option to keep access to our great features by upgrading to Connect and
                Perform or Connect and Perform Pro.
              </div>
              <div className="verify-didnt-receive">
                Didn't receive an email? <a onClick={selectStep}>Click here</a>.
              </div>
            </div>
          ) : null}
          <ReCaptcha
            ref={captcha}
            size="invisible"
            render="explicit"
            sitekey={CAPTCHA_KEY}
            onloadCallback={onLoadRecaptcha}
            verifyCallback={verifyCallback}
          />
        </>
      }
    />
  );
};

EmailConfirmation.propTypes = {
  email: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
  selectStep: PropTypes.func.isRequired,
};

export default withRouter(EmailConfirmation);
