import React from 'react';
import { PropTypes } from 'prop-types';
import { ReactComponent as CloseIcon } from 'images/close.svg';
import { ReactComponent as GrowBoardIcon } from 'images/grow/blankStates/grow-board.svg';
import Can from 'rbac/Can';
import { grow } from 'rbac/plans';
import {
  Button,
  ButtonsWrapper,
  CloseIconButton,
  Link,
  TextWrapper,
} from './BlankSpacesItems.styles';

const GrowBoardBlankSpaceContent = ({
  turnOffSampleContent,
  openModal,
  closeBlankState,
  isHideSamplePosts,
}) => (
  <>
    <GrowBoardIcon />
    <TextWrapper>
      The Grow board is a simple way to manage your goals (and even your tasks). We’ve created a few
      sample tasks to play with. You can create your own ones by hitting the + button at any
      time.&nbsp;
      <Can
        perform={grow.seeSampleContent}
        yes={() => (
          <Link onClick={turnOffSampleContent}>
            Toggle {isHideSamplePosts ? 'on' : 'off'} all sample content.
          </Link>
        )}
      />
    </TextWrapper>
    <ButtonsWrapper>
      <Button onClick={() => openModal('create-note-goal')}>Create goal</Button>
    </ButtonsWrapper>
    <CloseIconButton onClick={closeBlankState}>
      <CloseIcon />
    </CloseIconButton>
  </>
);

GrowBoardBlankSpaceContent.propTypes = {
  turnOffSampleContent: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeBlankState: PropTypes.func.isRequired,
  isHideSamplePosts: PropTypes.bool.isRequired,
};

export default GrowBoardBlankSpaceContent;
