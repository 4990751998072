import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import {
  SHOW_MENTION_TOOLTIP,
  HIDE_MENTION_TOOLTIP,
} from 'components/shared/tooltips/MentionTooltip';
import styled from '@emotion/styled';
import { BODY_CLASSES } from '../../helpers';
import MarkAsDone from './shared/MarkAsDone';
import { POST_ACTIONS } from '../../redux/actions/postActions';
import { USER_ACTIONS } from '../../redux/actions/userActions';
import Discussion from './PostShow/Discussion';
import Post from './PostShow/Post';
import Shoutout from './PostShow/Shoutout';
import Poll from './PostShow/Poll';
import PollAlert from './PostShow/PollAlert';
import FeedbackAlert from './PostShow/FeedbackAlert';
import BackButton from '../shared/buttons/BackButton';

const mapStateToProps = state => ({
  user: state.usersDomain.user,

  teams: state.teamsDomain.teams,
  spaces: state.spacesDomain.spaces,

  discussionTags: state.tagsDomain.discussionTags,
  hashtags: state.tagsDomain.hashtags,

  post: state.postsDomain.singlePost,
});
const mapDispatchToProps = dispatch => ({
  fetchPost: (id, failureCallback) => dispatch(POST_ACTIONS.fetchPost(id, failureCallback)),
  resetPost: id => dispatch(POST_ACTIONS.resetPost(id)),

  deletePost: id => dispatch(POST_ACTIONS.deletePost(id)),
  updateRemainingShoutouts: type => dispatch(POST_ACTIONS.updateRemainingShoutouts(type)),

  ownPost: id => dispatch(POST_ACTIONS.ownPost(id)),
  pinPost: id => dispatch(POST_ACTIONS.pinPost(id)),

  unfollowPost: postId => dispatch(POST_ACTIONS.unfollowPost(postId)),
  unfollowUser: userId => dispatch(USER_ACTIONS.unfollowUser(userId)),
});

const MuteWrap = styled.h3`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
`;

class PostShow extends Component {
  constructor(props) {
    super(props);
    this.id = props.match.params.id;
    this.state = {
      redirect: false,
      markingAsDone: false,
      editing: this.props.location.state && this.props.location.state.editing,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.location.pathname !== this.props.location.pathname) {
      this.id = newProps.match.params.id;
      this.props.fetchPost(this.id);
    }
    if (this.props.post && !newProps.post) {
      this.props.history.replace('/');
    }
  }

  componentDidUpdate() {
    Array.from(document.getElementsByClassName('inline-mention')).forEach(mention => {
      mention.removeEventListener('mouseover', SHOW_MENTION_TOOLTIP);
      mention.addEventListener('mouseover', SHOW_MENTION_TOOLTIP, false);
      mention.removeEventListener('mouseout', HIDE_MENTION_TOOLTIP);
      mention.addEventListener('mouseout', HIDE_MENTION_TOOLTIP, false);
    });
  }

  componentDidMount() {
    BODY_CLASSES(['jobvibe-future']);
    this.props.closeFlash();
    this.props.fetchPost(this.id, () => this.props.setErrorPage(404));
  }

  componentWillUnmount() {
    this.props.resetPost();
  }

  deletePostHandler = () => {
    if (window.confirm('Are you sure you want to delete this discussion?')) {
      this.props.deletePost(this.props.post.id);
      if (this.props.post.type === 'Shoutout') {
        this.props.updateRemainingShoutouts('removed');
      }
    }
  };

  unfollowUser = id => {
    this.props.unfollowUser(id);
    this.props.fetchPost(this.id);
  };

  changeEditingState = () => {
    this.setState({ editing: !this.state.editing });
  };

  shouldShowPost = spaceId => {
    const { spaces } = this.props;
    return spaces.map(space => space.id).includes(spaceId);
  };

  render() {
    if (this.state.redirect) return <Redirect to="/" />;
    if (this.props.post && this.props.user) {
      const showSpaceInfo = this.shouldShowPost(this.props.post.space_id);
      const showPostMenu =
        this.props.post.type !== 'SampleShoutout' &&
        this.props.post.type !== 'LeadFeedbackAlert' &&
        this.props.post.type !== 'AdminFeedbackAlert' &&
        this.props.post.type !== 'MonthlyValueWinners' &&
        this.props.post.type !== 'ConsolidatedShoutouts' &&
        this.props.post.subtype !== 'PollAlert';
      let post;
      switch (this.props.post.type) {
        case 'Fix':
          post = (
            <Discussion
              post={this.props.post}
              editing={this.state.editing}
              showSpaceInfo={showSpaceInfo}
              changeEditingState={this.changeEditingState}
            />
          );
          break;
        case 'Buzz':
          post = (
            <Post
              post={this.props.post}
              editing={this.state.editing}
              showSpaceInfo={showSpaceInfo}
              changeEditingState={this.changeEditingState}
            />
          );
          break;
        case 'Shoutout':
        case 'SampleShoutout':
          post = (
            <Shoutout
              post={this.props.post}
              editing={this.state.editing}
              showSpaceInfo={showSpaceInfo}
              changeEditingState={this.changeEditingState}
            />
          );
          break;
        case 'Poll':
          if (this.props.post.subtype === 'PollAlert') {
            post = (
              <PollAlert
                post={this.props.post}
                editing={this.state.editing}
                changeEditingState={this.changeEditingState}
              />
            );
          } else {
            post = (
              <Poll
                post={this.props.post}
                editing={this.state.editing}
                showSpaceInfo={showSpaceInfo}
                changeEditingState={this.changeEditingState}
              />
            );
          }
          break;
        case 'LeadFeedbackAlert':
        case 'AdminFeedbackAlert':
          post = (
            <FeedbackAlert
              post={this.props.post}
              editing={this.state.editing}
              changeEditingState={this.changeEditingState}
            />
          );
          break;
      }
      return (
        <div className="post-show">
          <BackButton />
          <div className="row vibe-explore-wrap" />
          <div className="post-show-inner-wrap">
            {post}
            <MarkAsDone
              postId={this.props.post.id}
              closeModal={() => {
                this.setState({ markingAsDone: false });
              }}
              show={this.state.markingAsDone}
            />
            {showPostMenu ? (
              <div className="post-menu">
                {this.props.post.has_admin_permissions ? (
                  <h3 onClick={() => this.props.pinPost(this.props.post.id)}>
                    {this.props.post.pinned ? 'Unpin it' : 'Pin it'}
                  </h3>
                ) : (
                  ''
                )}
                {this.props.post.type === 'Fix' && this.props.post.status !== 'resolved' ? (
                  <h3 onClick={() => this.props.ownPost(this.props.post.id)}>
                    {this.props.post.is_owner ? 'Stop owning' : 'Own it'}
                  </h3>
                ) : (
                  ''
                )}
                {this.props.post.type === 'Fix' && this.props.post.status !== 'resolved' ? (
                  <h3 onClick={() => this.setState({ markingAsDone: true })}>Mark as resolved</h3>
                ) : (
                  ''
                )}
                {this.props.user.id !== this.props.post.user.id && (
                  <MuteWrap onClick={() => this.unfollowUser(this.props.post.user.id)}>
                    {this.props.post.author_is_unfollowed ? 'Follow ' : 'Mute posts from '}
                    {`${this.props.post.user.first_name} ${this.props.post.user.last_name}`}
                  </MuteWrap>
                )}
                {this.props.user.id !== this.props.post.user.id ? (
                  <h3 onClick={() => this.props.unfollowPost(this.props.post.id)}>
                    {this.props.post.is_unfollowed
                      ? 'Turn on notifications for this post'
                      : 'Turn off notifications for this post'}
                  </h3>
                ) : (
                  ''
                )}
                {this.props.post.user.id === this.props.user.id &&
                (this.props.post.type === 'Poll' ? !this.props.post.has_votes : true) ? (
                  <h3 onClick={() => this.setState({ editing: !this.state.editing })}>
                    {this.state.editing ? 'Cancel editing' : 'Edit post'}
                  </h3>
                ) : (
                  ''
                )}
                {this.props.post.user.id === this.props.user.id ||
                this.props.post.has_admin_permissions ? (
                  <h3 onClick={() => this.deletePostHandler()}>Delete post</h3>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <div className="post-menu" />
            )}
          </div>
        </div>
      );
    }
    return <div />;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PostShow));
