import React, { useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import WelcomeContainer from '../WelcomeContainer';
import { PASSWORD_REGEX } from '../../../helpers';

const SetPassword = ({ handlePassword, location }) => {
  const [passwordError, setPasswordError] = useState(false);

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isIntegration = () => Boolean(query.appleToken);

  const handleSubmit = e => {
    e.preventDefault();
    if (isIntegration()) {
      return handlePassword({
        first_name: e.target.first_name.value,
        last_name: e.target.last_name.value,
      });
    }

    const password = e.target.password.value;
    if (!PASSWORD_REGEX.test(password)) {
      return setPasswordError(true);
    }
    handlePassword({
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      password,
    });
  };

  const setTitle = () => {
    if (passwordError) return <span className="error-color">Something's not right...</span>;
    return 'Almost there!';
  };

  return (
    <WelcomeContainer
      className="text-center"
      title={setTitle()}
      body={
        <>
          <div className="password-set">
            <div>
              Add your name {!isIntegration() && 'and create a password '}for your new account
            </div>
            <form
              onSubmit={handleSubmit}
              action=""
              className="simple_form form-compact password-form"
              id="new_user"
            >
              <div className="form-inline">
                <div className="form-group">
                  <input
                    required
                    name="first_name"
                    type="text"
                    className="string required form-control"
                    placeholder="First name"
                  />
                </div>
                <div className="form-group">
                  <input
                    required
                    name="last_name"
                    type="text"
                    className="string required form-control"
                    placeholder="Last name"
                  />
                </div>
              </div>
              {!isIntegration() && (
                <>
                  <div className="form-group  required password ">
                    <input
                      required
                      name="password"
                      type="password"
                      className={`string email required form-control ${
                        passwordError ? 'error' : ''
                      }`}
                      placeholder="Password"
                    />
                  </div>
                  <div
                    className={`password-description ${passwordError ? 'description-error' : ''}`}
                  >
                    Must be at least 8 characters long and contain one digit, one special character,
                    one uppercase and one lowercase character
                  </div>
                </>
              )}
              <button type="submit" className="btn btn-primary btn-full" id="sign-in-btn">
                Set name{!isIntegration() && ' and password'}
              </button>
            </form>
          </div>
        </>
      }
    />
  );
};

SetPassword.propTypes = {
  handlePassword: PropTypes.func.isRequired,
};

export default withRouter(SetPassword);
