import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { APP_URL, AWS_S3_URL } from '../../../constants';
import { BODY_CLASSES, CHECK_UNAUTHORIZED, HEADERS } from '../../../helpers';
import Flash from '../../shared/Flash';

class NewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      email: '',
      error: false,
      message: '',
      flash: false,
    };
  }

  componentDidMount() {
    BODY_CLASSES(['password', 'new']);
  }

  requestResetLink = e => {
    e.preventDefault();
    this.setState({ sending: true });
    const data = new FormData();
    data.append('email', this.state.email);

    const headers = HEADERS();
    fetch(`${APP_URL}/password`, { method: 'post', headers, body: data })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState(
            {
              error: jsonResponse.error,
              message: jsonResponse.message,
              flash: jsonResponse.flash,
              sending: false,
              email: '',
            },
            () => window.scrollTo(0, 0)
          );
        });
      });
  };

  render() {
    const { error, message, email, sending, flash } = this.state;
    const classes = error
      ? 'form-group email optional user_email has-error'
      : 'form-group email optional user_email';
    return (
      <div>
        {flash ? (
          <Flash
            closeFlash={() => this.setState({ flash: false, message: '' })}
            flashMessage={message}
            flashType="notice"
            dismissable
          />
        ) : (
          ''
        )}
        <div className="logo-public centered">
          <img
            src={`${AWS_S3_URL}public/images/Heelix-Logo-black.svg`}
            alt=""
            style={{ height: 56 }}
          />
        </div>
        <div className="floating-panel u-margin-bottom-md">
          <div className="panel-body">
            <div className="centered">
              <h1>Enter your email</h1>
              <p>Enter your email address and we'll get you going again.</p>
            </div>
            <form className="simple_form new_user">
              <div className={classes}>
                <label className="email optional control-label" htmlFor="user_email">
                  Email
                </label>
                <input
                  autoFocus="autofocus"
                  autoComplete="off"
                  className="string email optional form-control"
                  id="user_email"
                  name="user[email]"
                  placeholder="your@email.com"
                  type="email"
                  onChange={e => this.setState({ email: e.target.value })}
                  value={email}
                />
                {error && (
                  <span className="help-block" id="help-message">
                    {message}
                  </span>
                )}
              </div>
              <input
                className="btn btn-primary btn-full"
                name="commit"
                type="submit"
                disabled={sending || email === ''}
                id="reset-password-btn"
                value="Request reset link"
                onClick={this.requestResetLink}
              />
            </form>
            <div className="centered u-margin-top-sm">
              <div className="u-size0">
                Already have an account?&nbsp;
                <Link className="invisible-link ul-link" to="/login" id="sign-in-btn">
                  Sign in here
                </Link>
                <br />
                <Link
                  className="invisible-link ul-link"
                  to="/confirmation/new"
                  id="new-confirmation-btn"
                >
                  Didn't receive confirmation instructions?
                </Link>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewPassword;
