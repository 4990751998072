/** @jsx jsx */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import { APP_URL } from 'constants.js';
import { BODY_CLASSES, CHECK_UNAUTHORIZED, HEADERS } from 'helpers';
import PlanModal from 'rbac/components/PlanModal/PlanModal';
import { css, jsx } from '@emotion/core';
import { connect } from 'react-redux';
import Can from 'rbac/Can';
import { general } from 'rbac/plans';
import styled from '@emotion/styled';
import planTypes from 'components/payments/PaymentChoose/planTypes';
import StripeForm from '../payments/StripeForm';
import PaymentsUnsubscribe from '../payments/PaymentsUnsubscribe';
import LoadingScaleBox from '../shared/LoadingScaleBox';
import Auxiliary from '../layouts/Auxiliary';
import crownImg from '../../images/crown.png';
import grayCrown from './gray-crown.svg';
import { ReactComponent as CheckMark } from './blue-checkmark.svg';
import FeatureSwitch from './FeatureSwitch';
import store from '../../redux/store';
import { SPACE_ACTIONS } from 'redux/actions/spaceActions';
import { getIndexOfObjectInArray } from '../../helpers';

const Feed = {
  title: 'Home Feed',
  content:
    'The feed enables your team to collaborate to solve issues, give each other shoutouts, share photos / videos / files and more.',
};

const Grow = {
  title: 'Grow',
  content:
    'Grow is your personal portal where you can create helpful meeting templates for your 1:1s, create and track goals, log your wins and more.',
};

const AnnualBar = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

const GreenLink = styled(Link)`
  color: #52ba05;
  font-size: 14px;
  line-height: 16px;
  margin-left: 14px;
  :hover,
  :focus {
    color: #3b8603;
    text-decoration: none;
  }
`;

class SettingsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {},
      accountName: '',
      card: null,
      cancelAtPeriodEnd: false,
      subscriptionEnd: false,
      invoices: [],
      showModal: false,
      message: '',
      plan: {},
      usersCount: 0,
      subscriptionCanceled: false,
      showTitle: false,
      totalInclGst: 0,
      amountInclGst: 0,
      taxPercent: 0,
      stripeUpgradePlan: null,
      loaded: false,
      countries: [],
    };
  }

  componentDidMount() {
    BODY_CLASSES(['settings', 'edit', 'heelix', 'settings-page', 'new-payments-page']);
    this.props.setHeader('Edit Account');
    const headers = HEADERS();
    fetch(`${APP_URL}/settings`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState(
            {
              account: jsonResponse.account,
              accountName: jsonResponse.account.name,
              plan: jsonResponse.account.plan,
              usersCount: jsonResponse.account.users_count,
            },
            this.getData()
          );
        });
      })
      .catch(() => {});

    this.props.closeFlash();
  }

  getData() {
    const headers = HEADERS();

    fetch(`${APP_URL}/settings_additional`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({
            card: jsonResponse.card,
            cancelAtPeriodEnd: jsonResponse.cancelAtPeriodEnd,
            subscriptionEnd: jsonResponse.subscriptionEnd,
            subscriptionCanceled: jsonResponse.subscriptionCanceled,
            invoices: jsonResponse.invoices,
            totalInclGst: jsonResponse.totalInclGst,
            amountInclGst: jsonResponse.amountInclGst,
            taxPercent: jsonResponse.taxPercent,
            stripeUpgradePlan: jsonResponse.stripeUpgradePlan,
            countries: jsonResponse.countries,
            shippingData: jsonResponse.shippingData,
            loaded: true,
          });
        });
      })
      .catch(() => {});
  }

  unsubscribeModal(e) {
    e.preventDefault();
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  addMessage(message) {
    this.setState({ message });
  }

  changeName(e) {
    e.preventDefault();
    const headers = HEADERS();
    const data = new FormData();
    data.append('accountName', this.state.accountName);

    fetch(`${APP_URL}/settings`, { method: 'post', headers, body: data })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.props.updateFlash(jsonResponse.flashName, jsonResponse.message);

          let defaultSpaceIndex = getIndexOfObjectInArray(
            store.getState().spacesDomain.spaces,
            'account_default_space',
            true
          );
          store.dispatch(SPACE_ACTIONS.updateSpaceName(defaultSpaceIndex, this.state.accountName));
        });
      })
      .catch(() => {});
  }

  showTitle() {
    this.setState({ showTitle: !this.state.showTitle });
  }

  render() {
    let subscriptionInfoContent = <LoadingScaleBox />;
    let invoicesContent = null;
    const includeGst = this.state.taxPercent != 0;
    const { userCount, stripeUpgradePlan } = this.state;
    const currency = stripeUpgradePlan && stripeUpgradePlan.currency.toUpperCase();
    const isAnnual = stripeUpgradePlan?.interval === 'year';

    if (this.state.loaded) {
      if (this.state.subscriptionCanceled && this.state.subscriptionEnd) {
        subscriptionInfoContent = (
          <p>
            Your subscription has been cancelled. You will remain on Heelix Pro until the end of
            your current payment cycle ({this.state.subscriptionEnd}), at which time your account
            will be automatically converted to Heelix Free.
          </p>
        );
      } else if (this.state.plan.requires_subscription) {
        subscriptionInfoContent = (
          <Auxiliary>
            <p id="plan-info">
              <img src={crownImg} alt="" className="crown-img" />
              Your team is on <strong>{this.state.plan.name}</strong> plan with &nbsp;
              {this.state.usersCount} active&nbsp;
              {this.state.usersCount > 1 ? 'users' : 'user'}.&nbsp;
              <Link className="js-unsubscribe-modal" to="/payments/choose_plan">
                Change plan
              </Link>
            </p>
            {this.props.planSize !== 'custom' && (
              <>
                <h3 className="settings-page-title small-font">Credit card details</h3>
                <div className="credit-card-details-wrapper">
                  <StripeForm
                    method="post"
                    path="/settings"
                    card={this.state.card}
                    countries={this.state.countries}
                    ctaText="Update"
                    cancelPath="/"
                    editToggle="true"
                    cardLastFour={this.state.card && this.state.card.last4}
                    addMessage={this.addMessage.bind(this)}
                    showTitle={this.showTitle.bind(this)}
                    shippingData={this.state.shippingData}
                  />
                </div>
                <div id="monthly-payment-info">
                  Based on{' '}
                  {isAnnual ? (
                    'your plan'
                  ) : (
                    <>
                      <strong>{this.state.usersCount} </strong>
                      {this.state.usersCount > 1 ? 'users' : 'user'}
                    </>
                  )}
                  , we'll charge you
                  <strong>
                    {' '}
                    {currency} ${isAnnual ? this.state.amountInclGst : this.state.totalInclGst}{' '}
                  </strong>
                  {!includeGst ? '' : ' inc GST'} next{' '}
                  {this.state.stripeUpgradePlan ? this.state.stripeUpgradePlan.interval : 'month'}.{' '}
                  {!isAnnual && (
                    <>
                      <div>
                        Any new users you add will be charged at
                        <strong>
                          {' '}
                          {currency} ${this.state.amountInclGst}
                        </strong>{' '}
                        per{' '}
                        {this.state.stripeUpgradePlan
                          ? this.state.stripeUpgradePlan.interval
                          : 'month'}
                        {includeGst ? ' inc GST' : ''}.
                      </div>
                      <AnnualBar>
                        <PlanModal.Pig width="30" height="30" />
                        <GreenLink to="/payments/choose_plan">
                          Switch to annual billing for more flexibility and value
                        </GreenLink>
                      </AnnualBar>
                    </>
                  )}
                </div>
              </>
            )}
          </Auxiliary>
        );
      } else {
        subscriptionInfoContent = (
          <Auxiliary>
            <p>
              <img src={grayCrown} alt="" className="crown-img" />
              Your team is on <strong>{this.state.plan.name}</strong> with {this.state.usersCount}{' '}
              active {this.state.usersCount > 1 ? 'users' : 'user'}.&nbsp;
              {this.state.plan.free && !!this.state.plan.trial_ends_in && (
                <span>
                  Your free trial of all features will end in {this.state.plan.trial_ends_in}&nbsp;
                  {this.state.plan.trial_ends_in === 1 ? 'day' : 'days'}.&nbsp;
                </span>
              )}
              {this.state.plan.managed && this.state.account.managed_plan_capacity > 0 && (
                <span>
                  &nbsp;Your limit is set to&nbsp;
                  <strong>{this.state.account.managed_plan_capacity}</strong>.
                </span>
              )}
            </p>
            <Can
              perform={general.seePlanUpgradeOption}
              data={{
                isAccountAdmin: true,
                isOnTrial: this.props.planSize === 'trial',
                isManaged: this.props.isManaged,
              }}
              yes={() => (
                <div>
                  <PlanModal
                    css={css`
                      margin-bottom: 20px;
                      max-width: 700px;
                    `}
                  >
                    <PlanModal.TopBar />
                    <PlanModal.Content>
                      <PlanModal.Title
                        css={css`
                          font-size: 14px;
                          font-weight: normal;
                        `}
                      >
                        <PlanModal.Crown
                          css={css`
                            width: 28px;
                            height: 28px;
                            flex-shrink: 0;
                            margin-right: 12px;
                          `}
                        />
                        Upgrade from ${planTypes[25].prices.pro.usd} USD per month to get access to
                        rich dashboards, run dynamic polls, greater access to performance tools,
                        integrations, support and more
                      </PlanModal.Title>
                      <PlanModal.List
                        css={css`
                          margin-top: 23px;
                          margin-bottom: 25px;
                        `}
                      >
                        {[
                          'Deep-dive into the data to explore your team’s emotional state',
                          'Create more than 8 meeting notes, wins and unlock recurring meetings',
                          'Access advanced polls and more',
                        ].map(content => (
                          <PlanModal.ListItem>
                            <CheckMark
                              css={css`
                                margin-right: 19px;
                                flex-shrink: 0;
                                margin-top: 6px;
                              `}
                            />
                            {content}
                          </PlanModal.ListItem>
                        ))}
                      </PlanModal.List>
                      <PlanModal.Button
                        onClick={() => this.props.history.push('/payments/choose_plan')}
                      >
                        View plans & pricing
                      </PlanModal.Button>
                    </PlanModal.Content>
                  </PlanModal>
                </div>
              )}
            />
          </Auxiliary>
        );
      }

      if (this.state.invoices?.length > 0) {
        invoicesContent = (
          <div className="row">
            <div className="col-xs-12">
              <div id="invoices-settings-wrapper">
                <h3 className="settings-page-title small-font">History</h3>
                <div>
                  <table className="table-vr col-xs-12 col-lg-8 u-margin-bottom-md">
                    <tbody>
                      <tr>
                        <th>Date & time</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th />
                      </tr>
                      {this.state.invoices.map(invoice => (
                        <tr key={invoice.id}>
                          <td>{invoice.date}</td>
                          <td>Monthly subscription</td>
                          <td>
                            <CurrencyFormat
                              value={invoice.amount_due / 100}
                              displayType="text"
                              thousandSeparator
                              decimalScale={2}
                              prefix="$"
                            />
                          </td>
                          <td>
                            <a href={invoice.invoice_pdf} target="_blank">
                              Download invoice
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="settings-wrapper">
        {/* TODO: remove PaymentsUnsubscribe component */}
        {this.state.showModal ? (
          <PaymentsUnsubscribe
            show
            closeModal={this.closeModal.bind(this)}
            getData={this.getData.bind(this)}
            addMessage={this.addMessage.bind(this)}
            updateFlash={this.props.updateFlash.bind(this)}
          />
        ) : (
          ''
        )}
        <div className="row">
          <div className="col-xs-12">
            <h1 id="settings-title" className="text-center">
              Account settings
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div id="company-name-form-wrapper">
              <form className="simple_form edit_account" id="edit_account">
                <div className="row clearfix">
                  <div className="col-md-8">
                    <div className="form-group account-name-label">
                      <label htmlFor="account_name" className="string required control-label">
                        Account settings
                      </label>
                      <p className="help-block company-name-desc">
                        The name of this account, usually the company or division name.
                      </p>
                    </div>
                    <div className="form-group string required account_name">
                      <div className="col-xs-8 no-gutter">
                        <input
                          type="text"
                          className="string required form-control"
                          id="account_name"
                          name="account[name]"
                          value={this.state.accountName}
                          onChange={e => this.setState({ accountName: e.target.value })}
                        />
                      </div>
                      <div className="col-xs-4">
                        <input
                          className="btn btn-md btn-secondary"
                          id="update-company-name-btn"
                          type="submit"
                          value="Update"
                          onClick={e => this.changeName(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <hr className="settings-divider" />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div id="notifications-settings-wrapper">
              <h3 className="settings-page-title">Notification settings</h3>
              <Link to="/notification_settings">Click here to access notification settings</Link>
            </div>
            <hr className="settings-divider" />
          </div>
        </div>
        <Can
          perform={general.seeFeaturesSwitches}
          yes={() => (
            <div className="row">
              <div className="col-xs-12">
                <div id="account-settings-wrapper">
                  <h3 className="settings-page-title">Heelix features</h3>
                  <FeatureSwitch
                    enabled={this.props.feedEnabled}
                    type="Feed"
                    title={Feed.title}
                    content={Feed.content}
                  />
                  <FeatureSwitch
                    enabled={this.props.growEnabled}
                    type="Grow"
                    title={Grow.title}
                    content={Grow.content}
                  />
                </div>
                <hr className="settings-divider" />
              </div>
            </div>
          )}
        />
        <div className="row">
          <div className="col-xs-12">
            <div id="subscription-settings-wrapper">
              <h3 className="settings-page-title">Your subscription</h3>
              {subscriptionInfoContent}
            </div>
          </div>
        </div>
        {invoicesContent}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({
  feedEnabled: state.usersDomain.feedEnabled,
  growEnabled: state.usersDomain.growEnabled,
  planSize: state.plansDomain.planSize,
  isManaged: state.plansDomain.isManaged,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingsEdit));
