/** @jsx jsx */
import React, { Component } from 'react';
import { css, jsx } from '@emotion/core';

class PollOptionsExpired extends Component {
  checkIfSelected(id) {
    return this.props.poll.selected_poll_option_id === id;
  }

  countWidth(id) {
    const votesForOption = this.props.poll.poll_options
      .filter(function (option) {
        return option.id === id;
      })
      .shift();

    if (votesForOption && votesForOption.vote_count === 0) {
      return '0%';
    }
    let maxValue = 0;
    this.props.poll.poll_options.forEach(vote => {
      if (vote.vote_count > maxValue) {
        maxValue = vote.vote_count;
      }
    });
    return `${(votesForOption.vote_count * 100) / maxValue}%`;
  }

  render() {
    let pollOptions = null;
    if (this.props.poll !== null) {
      pollOptions = this.props.poll.poll_options
        .sort((obj1, obj2) =>
          obj1.vote_count > obj2.vote_count ? -1 : obj1.vote_count < obj2.vote_count ? 1 : 0
        )
        .map(option => (
          <div className="pool-option-box" key={option.id}>
            <div className="option-content" key={option.id}>
              <div
                css={css`
                  display: flex;
                `}
              >
                <div
                  css={css`
                    min-width: 1%;
                    word-wrap: break-word;
                  `}
                >
                  {option.content}
                </div>
                <div
                  className="vote-value pull-right"
                  css={css`
                    margin-left: auto;
                  `}
                >
                  {' '}
                  {option.vote_count}
                </div>
              </div>
              <div className="vote-progress-bar">
                <div
                  style={{ width: this.countWidth(option.id) }}
                  className={
                    this.checkIfSelected(option.id) ? 'vote-color-bar selected' : 'vote-color-bar'
                  }
                />
              </div>
            </div>
          </div>
        ));
    }

    return <div className="poll-options-expired">{pollOptions}</div>;
  }
}

export default PollOptionsExpired;
