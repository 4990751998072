import styled from '@emotion/styled';

export const CloseIconButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
`;

export const Button = styled.button`
  min-width: 115px;
  height: 39px;
  background: #11a9ff;
  border-radius: 4px;
  border: 1px solid #11a9ff;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
`;

export const TextWrapper = styled.p`
  margin: 18px 0;
  text-align: center;
`;

export const Link = styled.a`
  display: block;
  cursor: pointer;
  @media (min-width: 768px) {
    display: inline-block;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  & > button {
    font-weight: bold;
  }
`;

export const MeetingsButtonsWrapper = styled(ButtonsWrapper)`
  width: 201px;
  & > div {
    width: 100%;
    button {
      font-size: 14px;
    }
  }
`;

export const ButtonAchievements = styled(Button)`
  &:last-child {
    margin-left: 16px;
  }
`;

export const ButtonLink = styled.a`
  min-width: 115px;
  height: 39px;
  background: #11a9ff;
  border-radius: 4px;
  border: 1px solid #11a9ff;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  text-align: center;
  padding: 11px 17px;
  display: flex;
  align-items: center;
  font-weight: bold;
  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
`;
