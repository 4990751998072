import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TimeAgo from 'react-timeago';
import { COMMENT_ACTIONS } from 'redux/actions/commentActions';
import {
  FORMAT_CONTENT_WITH_MARKUPS,
  FORMATTER,
  GET_MENTIONS_FROM_CONTENT,
  htmlspecialchars_decode,
} from 'helpers.js';
import PostMedia from 'pages/Grow/GrowPost/PostMedia';
import { toggleUserModal } from 'redux/app/actions';
import styled from '@emotion/styled';
import {
  SHOW_MENTION_TOOLTIP,
  HIDE_MENTION_TOOLTIP,
} from 'components/shared/tooltips/MentionTooltip';
import striptags from 'striptags';
import MentionsInputWrapper from '../../shared/MentionsInputWrapper';
import CommentDropdown from '../../shared/posts/CommentDropdown';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import qs from 'qs';

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  post: state.postsDomain.singlePost,
  hashtags: state.tagsDomain.hashtags,

  fetchingComments: state.postsDomain.fetchingComments,
});

const mapDispatchToProps = dispatch => ({
  updateComment: (postId, commentId, data) =>
    dispatch(COMMENT_ACTIONS.updateComment(postId, commentId, data)),
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
});

const CommentWrapper = styled.div`
  width: 100%;
`;

const Icon = styled.i`
  cursor: pointer;
`;

class CommentItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      content: '',
      lightboxIsOpen: false,
      currentImage: 0,
      errorComment: null,
      commentCharsLeft: 1000,
    };
  }

  changeEditingMode = () => {
    this.setState({
      editing: true,
      content: FORMAT_CONTENT_WITH_MARKUPS(this.props.comment.content),
    });
  };

  updateComment() {
    if (this.state.commentCharsLeft >= 0) {
      const data = new FormData();
      data.append('comment[content]', this.state.content);
      data.append('_method', 'PUT');
      this.props.updateComment(this.props.postId, this.props.comment.id, data);
      this.setState({
        editing: false,
      });
    }
  }

  componentDidUpdate() {
    Array.from(document.getElementsByClassName('inline-mention')).forEach(mention => {
      mention.removeEventListener('mouseover', SHOW_MENTION_TOOLTIP);
      mention.addEventListener('mouseover', SHOW_MENTION_TOOLTIP, false);
      mention.removeEventListener('mouseout', HIDE_MENTION_TOOLTIP);
      mention.addEventListener('mouseout', HIDE_MENTION_TOOLTIP, false);
    });
  }

  commentContentChangedHandler = (e, unformattedContent) => {
    const input = e.target.value;
    this.setState({
      content: input,
      commentCharsLeft: 1000 - unformattedContent.length,
      errorComment:
        unformattedContent.length > 1000
          ? 'You’ve hit the maximum comment length which is 1000 characters.'
          : '',
    });
  };

  render() {
    return (
      <li className="user-comment">
        <div
          key={this.props.comment.id}
          className={this.state.editing ? 'post-comment editing' : 'post-comment'}
        >
          <Link
            id={`comment-from-user-${this.props.comment.user.id}`}
            to=""
            onClick={e => {
              e.preventDefault();
              if (this.props.comment.user.id === this.props.user.id) {
                this.props.history.push(`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`);
              } else {
                return this.props.toggleUserModal(true, this.props.comment.user.id);
              }
            }}
          >
            {this.props.comment.user.first_name}&nbsp;{this.props.comment.user.last_name}&nbsp;
          </Link>
          {!this.state.editing ? (
            <>
              {this.props.comment.content && (
                <span
                  className="comment-content-text"
                  dangerouslySetInnerHTML={{
                    __html: striptags(this.props.comment.content, ['a', 'br']),
                  }}
                />
              )}
              <span className="time-ago">
                <TimeAgo
                  date={`${this.props.comment.created_at} ${this.props.comment.user.time_zone}`}
                  formatter={FORMATTER}
                  title=""
                />
              </span>
              <div className="comment-show-media">
                <PostMedia media={this.props.comment.media} />
              </div>
            </>
          ) : (
            <CommentWrapper>
              <MentionsInputWrapper
                focus={this.state.editing}
                value={htmlspecialchars_decode(this.state.content)}
                contentChangedHandler={this.commentContentChangedHandler}
                className={this.state.errorComment ? 'error' : ''}
                placeholder="Add a comment ..."
                mentions={GET_MENTIONS_FROM_CONTENT(this.props.comment.content)}
                onKeyDown={e => {
                  if (e.key === 'Escape') {
                    this.setState({ editing: false });
                  }
                  if (e.key === 'Enter' && e.target.value.trim() === '') {
                    // ---Prevent empty comments
                    e.preventDefault();
                  } else if (e.key === 'Enter' && !this.shiftPressed) {
                    // --- Submit if shift not pressed
                    e.preventDefault();
                    this.updateComment();
                  }
                  if (e.key === 'Shift') this.shiftPressed = true;
                }}
                onKeyUp={e => {
                  if (e.key === 'Shift') this.shiftPressed = false;
                }}
              />
              {this.state.errorComment ? (
                <div className="comment-error-edit">{this.state.errorComment}</div>
              ) : (
                ''
              )}
            </CommentWrapper>
          )}
          {this.state.editing && (
            <>
              <Icon className="fa fa-check pull-right" onClick={() => this.updateComment()} />
              <Icon
                className="fa fa-close pull-right"
                onClick={() => this.setState({ editing: false })}
              />
            </>
          )}
          {!this.state.editing &&
          (this.props.user.leadTeamsIds.includes(this.props.post.audience_id) ||
            this.props.user.id === this.props.comment.author_id) ? (
            <CommentDropdown
              id={`comment_${this.props.comment.id}`}
              commentId={this.props.comment.id}
              postId={this.props.post.id}
              type="post"
              editVisible={this.props.user.id === this.props.comment.author_id}
              deleteVisible={
                this.props.user.leadTeamsIds.includes(this.props.post.audience_id) ||
                this.props.user.id === this.props.comment.author_id
              }
              changeEditingMode={this.changeEditingMode}
            />
          ) : null}
        </div>
      </li>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommentItem));
