import React, { Component } from 'react';
import Page from 'components/layouts/Page';
import styled from '@emotion/styled';
import PostsMasonry from 'components/feed/PostsMasonry';
import { connect } from 'react-redux';
import { BODY_CLASSES, CHECK_UNAUTHORIZED, HEADERS } from 'helpers.js';
import { APP_URL, INITIAL_QUESTIONNAIRES_FILTERS } from 'constants.js';
import qs from 'qs';
import FeedDateRange from 'components/shared/FeedDateRange';
import Loader from 'components/shared/Loader';
import { toggleUserModal } from 'redux/app/actions';
import { POST_ACTIONS } from 'redux/actions/postActions';
import debounce from 'lodash/debounce';
import { animateScroll as scroll } from 'react-scroll';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';

const PostsCounter = styled.div`
  display: inline-block;
  vertical-align: bottom;
  padding: 3px 7px 3px 6px;
  background: #ffffff;
  border-radius: 3px;
  font-size: 11px;
  line-height: 13px;
  font-weight: bold;
  color: #6a6a6a;
  margin-left: 15px;
  position: relative;
  bottom: 4px;
`;

class QuestionnairesIndex extends Component {
  state = {
    postsCount: 0,
    posts: [],
  };

  initialFilters = INITIAL_QUESTIONNAIRES_FILTERS;

  componentDidUpdate(prevProps, prevState) {
    const filtersCopy = JSON.parse(JSON.stringify(this.props.filters));
    const qsFilters = qs.parse(window.location.href.split('?')[1]);
    const newFilters = window.location.search;
    if (this.state.currentFilters !== newFilters) {
      this.setState({ currentFilters: newFilters });
      Object.keys(filtersCopy).map(key => {
        filtersCopy[key] = qsFilters[key] ? qsFilters[key] : this.initialFilters[key];
      });
      if (JSON.stringify(filtersCopy) !== JSON.stringify(this.props.filters)) {
        this.debouncedFetch(filtersCopy);
      }
    }
    if (this.props.posts !== prevProps.posts) {
      this.fetchPostsCount(filtersCopy);
    }
  }

  handleFetch = filtersCopy => {
    this.props.filtersFromObject(filtersCopy);
    this.props.resetPosts();
    this.props.fetchQuestionnaires(0);
    this.fetchPostsCount(filtersCopy);
  };

  debouncedFetch = debounce(this.handleFetch, 1000, { leading: true });

  componentDidMount() {
    window.onscroll = () => {
      if (
        this.props.loadedPostsCount !== 0 &&
        window.innerHeight + window.scrollY + this.fetchTriggerThreshold >=
          document.body.offsetHeight &&
        !this.props.fetchingPosts &&
        this.props.hasMorePostsToLoad &&
        window.scrollY > 0
      ) {
        this.props.fetchQuestionnaires(this.props.loadedPostsCount);
      }
    };
    BODY_CLASSES(['vibes', 'index', 'jobvibe-future']);
    const qsFilters = qs.parse(window.location.href.split('?')[1]);
    const hasFilters = Object.keys(qsFilters).some(param =>
      Object.keys(this.initialFilters).includes(param)
    );
    if (this.props.location.pathname === '/questionnaires') {
      const filtersCopy = JSON.parse(JSON.stringify(this.initialFilters));
      if (hasFilters) {
        Object.keys(filtersCopy).map(key => {
          filtersCopy[key] = qsFilters[key] ? qsFilters[key] : this.initialFilters[key];
        });
      }
      this.props.filtersFromObject({
        ...filtersCopy,
      });
      this.props.resetPosts();
      this.props.fetchQuestionnaires(0);
      this.fetchPostsCount(this.props.filters);
    }
    scroll.scrollTo(this.props.scrollPosition, { duration: 0 });
  }

  fetchTriggerThreshold = 1000;

  mentionClickedHandler = e => {
    e.preventDefault();
    const mentionedId = parseInt(e.currentTarget.getAttribute('user-id'));
    if (mentionedId === this.props.user.id) {
      this.props.history.push(`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`);
    } else {
      return this.props.toggleUserModal(true, mentionedId);
    }
  };

  shouldShowPost = teamId => {
    const { teams } = this.props;
    return teams.map(team => team.id).includes(teamId);
  };

  tileRealignHandler() {
    this.setState({ postRenderAlignment: true });
  }

  fetchPostsCount = filters => {
    const headers = HEADERS();
    const queryString = qs.stringify({ ...filters });
    fetch(`${APP_URL}/questionnaires/posts_count?${queryString}`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (response.ok && response.status === 200) {
          response.json().then(jsonResponse => {
            this.setState({
              postsCount: jsonResponse.postsCount,
            });
          });
        }
      })
      .catch(function () {});
  };

  render() {
    const { user, posts, hasMorePostsToLoad, fetchingPosts } = this.props;
    const { postsCount } = this.state;
    return (
      <>
        <Page.Section
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 20px 0',
          }}
        >
          <Page.Wrapper className="grow-feed-header">
            <Page.Header
              style={{
                padding: '0 15px',
                marginBottom: '0',
                width: '300px',
              }}
            >
              Questionnaires
              <PostsCounter>{postsCount}</PostsCounter>
            </Page.Header>
          </Page.Wrapper>
        </Page.Section>
        <Page.Section
          style={{
            padding: '14px 0',
          }}
        >
          <Page.Wrapper>
            <Page.Content
              style={{
                flex: '1',
                overflowX: 'visible',
              }}
            >
              <PostsMasonry
                posts={posts}
                tileRealignHandler={this.tileRealignHandler.bind(this)}
                user={user}
                hasMorePostsToLoad={hasMorePostsToLoad}
                shouldShowPost={this.shouldShowPost}
                mentionClickedHandler={this.mentionClickedHandler}
                vibeTile=""
              />
              {fetchingPosts ? <Loader /> : ''}
            </Page.Content>
            <Page.Sidebar>
              <FeedDateRange />
            </Page.Sidebar>
          </Page.Wrapper>
        </Page.Section>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  filters: state.postsDomain.filters,
  teams: state.teamsDomain.teams,
  posts: state.postsDomain.posts,
  fetchingPosts: state.postsDomain.fetchingPosts,
  hasMorePostsToLoad: state.postsDomain.hasMorePostsToLoad,
  loadedPostsCount: state.postsDomain.loadedPostsCount,
  scrollPosition: state.postsDomain.scrollPosition,
});

const mapDispatchToProps = dispatch => ({
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
  fetchQuestionnaires: loaded => dispatch(POST_ACTIONS.fetchQuestionnaires(loaded)),
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  resetFilters: () => dispatch(POST_ACTIONS.resetFilters()),
  filtersFromObject: object => dispatch(POST_ACTIONS.filtersFromObject(object)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnairesIndex);
