/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import styled from '@emotion/styled';

const MultipleWrapper = styled.div`
  display: table;
  width: 100%;
  padding-top: 15px;
`;

const OptionLabel = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
  float: left;
  line-height: 140%;
  word-break: break-word;
  padding-right: 15px;
  position: relative;
  width: 100%;
`;

const Count = styled.div`
  float: right;
  color: #11a9ff;
  position: absolute;
  right: 0;
  bottom: 0;
`;

const OptionWrapper = styled.div`
  display: block;
  width: 100%;
  display: table-row;
`;

const OptionResults = styled.div`
  height: 8px;
  background: #d3efff;
  margin: 5px 0;
  border-radius: 8px;
`;

const SelectedResults = styled.div`
  opacity: ${props => (props.mostPopularAnswer ? '1' : '0.5')};
  background: #11a9ff;
  border-radius: 8px;
  width: ${props => props.width};
  height: 8px;
`;

const ProgressBarWrapper = styled.div`
  display: table-row;
`;

const countWidth = (option, selectedOptions, options) => {
  let maxValue = 0;
  options.forEach(opt => {
    if (opt.answers_count > maxValue) {
      maxValue = opt.answers_count;
    }
  });
  return option.answers_count !== 0 ? `${(option.answers_count * 100) / maxValue}%` : '0%';
};

const MultipleResults = ({ question, user, selectedOptions }) => (
  <MultipleWrapper>
    {question.options.map(option => {
      const maxValue = Math.max(...question.options.map(o => o.answers_count), 0);
      const mostPopularAnswer = maxValue === option.answers_count;

      return (
        <>
          <OptionWrapper>
            <OptionLabel>
              {option.content}
              <Count>{option.answers_count}</Count>
            </OptionLabel>
          </OptionWrapper>
          <ProgressBarWrapper>
            <OptionResults>
              <SelectedResults
                mostPopularAnswer={mostPopularAnswer}
                width={countWidth(option, selectedOptions, question.options)}
              />
            </OptionResults>
          </ProgressBarWrapper>
        </>
      );
    })}
  </MultipleWrapper>
);

export default MultipleResults;
