export const insights = {
  accessDashboard: 'insights.accessDashboard',
  accessFactorAnalysis: 'insights.accessFactorAnalysis',
  accessPerformanceOverTime: 'insights.accessPerformanceOverTime',
  accessMoodOverTime: 'insights.accessMoodOverTime',
  accessMoodVsFactor: 'insights.accessMoodVsFactor',
  accessTriggers: 'insights.accessTriggers',
  accessOrganisationInsights: 'insights.accessOrganisationInsights',
};

export const grow = {
  addNote: 'grow.addNote',
  addManagerNote: 'grow.addManagerNote',
  addGoal: 'grow.addGoal',
  addWin: 'grow.addWin',
  addMeeting: 'grow.addMeeting',
  seeSampleContent: 'grow.seeSampleContent',
  seeShoutoutLimitPrompt: 'grow.seeShoutoutLimitPrompt',
};

export const integrations = {
  addGoogleCalendar: 'integrations.addGoogleCalendar',
};

export const general = {
  seePlanUpgradeOption: 'general.seePlanUpgradeOption',
  seeFeaturesSwitches: 'general.seeFeaturesSwitches',
  seeOrgChart: 'general.seeOrgChart',
  accessQuestionnaires: 'general.accessQuestionnaires',
};

export const spaces = {
  addNew: 'spaces.addNew',
  accessView: 'spaces.accessView',
};

export const limits = {
  free: {
    [grow.addNote]: 8,
    [grow.addManagerNote]: 8,
    [grow.addGoal]: 8,
    [grow.addWin]: 8,
    [grow.addMeeting]: 8,
  },
  connectPerform: {
    [grow.addGoal]: 50,
    [grow.addWin]: 50,
    [grow.addMeeting]: 50,
  },
  connectPerformPro: {},
};

const plans = {
  free: {
    static: [insights.accessDashboard],
    dynamic: {
      [grow.addNote]: ({ notesCount }) => notesCount < limits.free[grow.addNote],
      [grow.addManagerNote]: ({ privateNotesCount }) =>
        privateNotesCount < limits.free[grow.addManagerNote],
      [grow.addGoal]: ({ goalsCount }) => goalsCount < limits.free[grow.addGoal],
      [grow.addWin]: ({ winsCount }) => winsCount < limits.free[grow.addWin],
      [grow.addMeeting]: ({ meetingsCount }) => meetingsCount < limits.free[grow.addMeeting],
      [grow.seeShoutoutLimitPrompt]: ({ isAccountAdmin }) => isAccountAdmin,

      [general.seePlanUpgradeOption]: ({ isAccountAdmin, isManaged }) =>
        !isManaged && isAccountAdmin,

      [spaces.accessView]: ({ spaceId, mainSpaceId }) => +spaceId === +mainSpaceId,
    },
  },
  connectPerform: {
    static: [
      insights.accessDashboard,
      insights.accessFactorAnalysis,
      insights.accessPerformanceOverTime,
      insights.accessMoodOverTime,
      insights.accessMoodVsFactor,
      insights.accessTriggers,

      grow.addNote,
      grow.addManagerNote,
      grow.seeSampleContent,

      integrations.addGoogleCalendar,
      general.seeFeaturesSwitches,
      general.seeOrgChart,
      general.accessQuestionnaires,

      spaces.addNew,
      spaces.accessView,
    ],
    dynamic: {
      [grow.addGoal]: ({ goalsCount }) => goalsCount < limits.connectPerform[grow.addGoal],
      [grow.addWin]: ({ winsCount }) => winsCount < limits.connectPerform[grow.addWin],
      [grow.addMeeting]: ({ meetingsCount }) =>
        meetingsCount < limits.connectPerform[grow.addMeeting],
      [grow.seeShoutoutLimitPrompt]: ({ isAccountAdmin }) => isAccountAdmin,

      [general.seePlanUpgradeOption]: ({ isOnTrial, isAccountAdmin, isManaged }) =>
        !isManaged && (isOnTrial || isAccountAdmin),
    },
  },
  connectPerformPro: {
    static: [
      insights.accessDashboard,
      insights.accessFactorAnalysis,
      insights.accessPerformanceOverTime,
      insights.accessMoodOverTime,
      insights.accessMoodVsFactor,
      insights.accessTriggers,
      insights.accessOrganisationInsights,

      grow.addNote,
      grow.addGoal,
      grow.addWin,
      grow.addMeeting,
      grow.addManagerNote,
      grow.seeSampleContent,

      integrations.addGoogleCalendar,
      general.seeFeaturesSwitches,
      general.seeOrgChart,
      general.accessQuestionnaires,

      spaces.addNew,
      spaces.accessView,
    ],
    dynamic: {
      [general.seePlanUpgradeOption]: ({ isOnTrial, isManaged }) => !isManaged && isOnTrial,
    },
  },
};

export default plans;
