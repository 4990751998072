import styled from '@emotion/styled';

export const Name = styled.span`
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  color: #11a9ff;
  vertical-align: middle;
  word-break: break-word;
  margin: 0 40px;
  display: block;
`;

export const Image = styled.img`
  margin-right: 14px;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Description = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #323232;
  padding: ${props => (props.tile ? '0 0 18px 0' : '0 48px 18px')};
  @media (max-width: 600px) {
    padding: 0 0 18px 0;
  }
  word-break: break-word;
`;

export const ShortAnswer = styled.textarea`
  border: 1px solid rgba(243, 243, 243, 0.7);
  ${props => props.disabled && 'height: 40px !important;'}
  min-height: 40px !important;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  &:disabled {
    background-color: #ffffff;
  }
  &::-webkit-input-placeholder,
  &::placeholder {
    color: #c4c4c4;
  }
  padding: 10px 15px;
`;

export const OptionWrapper = styled.div`
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  ${props => !props.flex && 'flex-wrap: wrap'};
  &:after { 
   content: " ";
   display: block; 
   height: 0; 
   clear: both;
`;

export const QuestionContent = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  padding-bottom: ${props => (props.multiple ? 6 : 16)}px;
  ${props => props.modal && 'padding-right: 22px; padding-left: 22px;'}
  word-break: break-word;
`;

export const QuestionWrapper = styled.div`
  padding-bottom: 15px;
`;

export const Button = styled.button`
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  padding: 10px 20px;
  background: #11a9ff;
  margin: auto;
  display: block;
  &:disabled {
    background: #d5d5d5;
    color: #ffffff;
  }
`;

export const Anonymous = styled.span`
  font-size: 11px;
  line-height: 13px;
  color: #adadad;
  margin-left: 8px;
  margin-right: 14px;
`;

export const ViewResults = styled.span`
  color: #11a9ff;
  cursor: pointer;
  font-size: 11px;
  line-height: 13px;
  padding-bottom: 30px;
`;
