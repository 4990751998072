import React, { Component } from 'react';
import delayUnmounting from '../../HOC/delayUnmounting';

class ModalValueError extends Component {
  state = {
    message: '',
    mounted: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        message: this.props.message,
        mounted: true,
      });
    }, 0);
  }

  componentWillReceiveProps(props) {
    if (this.state.mounted !== props.isMounted) this.setState({ mounted: false });
  }

  render() {
    return (
      <div className={`value-error ${this.state.mounted ? 'mounted' : ''}`}>
        {this.state.message}
      </div>
    );
  }
}

export default delayUnmounting(ModalValueError);
