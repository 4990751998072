/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { NavLink, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'qs';

import { getUrl, HEADERS, LOCALSTORAGE_GET, SIGN_OUT } from 'helpers';

import { ReactComponent as Crown } from 'images/insights/crown.svg';
import { noScrollBar } from 'styles';
import Can from 'rbac/Can';
import { general, insights, spaces } from 'rbac/plans';
import BackButton from 'components/layouts/TopBar/BackButton';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import GrowHeader from 'components/layouts/TopBar/GrowHeader';
import { ReactComponent as Bell } from './bell.svg';
import { ReactComponent as SearchIcon } from './search.svg';
import { ReactComponent as QuestionMark } from './questionmark.svg';
import Notifications from './Notifications';
import Onboarding from './Onboarding';
import { PLAN_ACTIONS } from '../../../redux/actions/planActions';
import SpaceHeader from './SpaceHeader';
import HomeHeader from './HomeHeader';
import ShoutoutsHeader from './ShoutoutsHeader';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 0;
  width: 100%;
  transition: padding 0.4s;
  .frame-wrapper--navigation-collapsed & {
    padding-left: 63px;
  }
  @media (min-width: 768px) {
    padding-left: 230px;
  }
`;
const Content = styled.div`
  padding: 12px 30px;
  border-bottom: solid 1px #e1e1e1;
  height: 60px;
  background: #fff;
  overflow: scroll;
  @media (min-width: 1200px) {
    overflow: hidden;
  }
`;
const Right = styled.div`
  float: right;
  display: table;
  padding-left: 20px;
`;
const Search = props => {
  const { location: search } = props;
  // eslint-disable-next-line no-restricted-globals
  let { pathname } = location;
  let query = qs.parse(search, { ignoreQueryPrefix: true });

  const [value, setValue] = useState(query.string || '');

  useEffect(() => {
    if (query.string !== value) {
      setValue(query.string || '');
    }
  }, [query.string]);

  if (!(pathname === '/' || pathname === '/feed')) {
    pathname = '/';
    query = {};
  }

  const handleSearch = () => {
    query.string = value;
    props.history.push(`${pathname}?${qs.stringify(query)}`);
  };

  return (
    <div
      css={css`
        position: relative;
        vertical-align: bottom;
        display: none;
        @media (min-width: 1200px) {
          display: inline-block;
        }
      `}
    >
      <input
        css={css`
          background: #f3f3f3;
          border: none;
          border-radius: 22px;
          font-size: 14px;
          line-height: 34px;
          height: 34px;
          width: 241px;
          padding: 0 20px;
          outline: none;
          transition: background 0.4s;
          cursor: pointer;
          :focus {
            background: #fff;
          }
        `}
        value={value}
        onChange={e => setValue(e.currentTarget.value)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSearch();
          }
        }}
      />
      <SearchIcon
        css={css`
          position: absolute;
          top: 8px;
          right: 14px;
          cursor: pointer;
        `}
        onClick={handleSearch}
      />
    </div>
  );
};
Search.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
const SearchWithRouter = withRouter(Search);
const NotificationButton = ({ unread, ...props }) => (
  <button
    type="button"
    css={css`
      border: none;
      background: #f3f3f3;
      border-radius: 50%;
      margin-left: 20px;
      width: 36px;
      height: 36px;
      line-height: 10px;
      text-indent: 2px;
      position: relative;
      outline: none;
      padding: 0;
      ${unread &&
      css`
        :after {
          content: '';
          display: block;
          width: 7px;
          height: 7px;
          background: #fe483c;
          border-radius: 50%;
          position: absolute;
          top: -3px;
          right: -3px;
          border: solid 3px #f3f3f3;
          box-sizing: content-box;
        }
      `}
    `}
    {...props}
  >
    <Bell />
  </button>
);
NotificationButton.propTypes = {
  unread: PropTypes.bool.isRequired,
};
const OnboardingButton = ({ openOnboarding, setOpenOnboarding, ...props }) => (
  <button
    type="button"
    {...props}
    css={css`
      border: none;
      background: #f3f3f3;
      border-radius: 50%;
      margin-left: 20px;
      width: 36px;
      height: 36px;
      line-height: 10px;
      text-indent: 2px;
      position: relative;
      outline: none;
      padding: 0;
    `}
  >
    <QuestionMark />
  </button>
);

const linkCss = css`
  color: #323232;
  border-radius: 55px;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 16px;
  display: inline-block;
  margin-right: 10px;
  :hover {
    text-decoration: none;
  }
`;

const Link = styled(NavLink)`
  ${linkCss}
  &.active {
    background: #f3f3f3;
    color: #11a9ff;
    font-weight: bold;
  }
`;

const InactiveLink = styled.a`
  ${linkCss};
  color: #d5d5d5;
  :hover {
    color: #d5d5d5;
    cursor: pointer;
  }
`;

export const LINKS = [
  {
    '/grow_board': 'Grow Board',
    '/meetings': 'Meetings',
    '/member_notes': 'Notes',
    '/achievements': 'Achievements',
    '/grow_profile': 'Profile',
  },
  {
    '/vibes/dashboard': 'Dashboard',
    '/vibes/factor_analysis': 'Factor analysis',
    '/vibes/performance_over_time': 'Performance over time',
    '/vibes/mood_over_time': 'Mood over time',
    '/vibes/mood_vs_factor': 'Mood vs Factor',
    '/vibes/detailed_feedback': 'Triggers',
  },
  {
    '/users': 'Users',
    '/teams': 'Teams',
    '/spaces': 'Spaces',
    '/company_structure': 'Org chart',
  },
  {
    '/organisation_insights/score': 'Heelix score & factors',
    '/organisation_insights/heatmap': 'Heatmap',
  },
];

const mapLinksToPlanPermissions = {
  Dashboard: insights.accessDashboard,
  'Factor analysis': insights.accessFactorAnalysis,
  'Performance over time': insights.accessPerformanceOverTime,
  'Mood over time': insights.accessMoodOverTime,
  'Mood vs Factor': insights.accessMoodVsFactor,
  Triggers: insights.accessTriggers,
  Spaces: spaces.accessView,
  'Org chart': general.seeOrgChart,
};

// names of get params which should be shared across links in the same group
const SHARED_PARAMS = ['team', 'id', 'period', 'start_date', 'end_date', 'view', 'factor'];

const Links = ({
  location: { pathname, search },
  isAdminOnFreeAccount,
  showUpgradeModal,
  isRegularUser,
  feedEnabled,
  showPrompt,
}) => {
  const group = LINKS.find(gr => !!gr[pathname]) || [];
  const paramsObj = qs.parse(search, { ignoreQueryPrefix: true });
  const filtered = Object.entries(paramsObj).filter(([key]) => SHARED_PARAMS.includes(key));
  // condition here prevents sharing period, start_date, end_date params on grow
  const params = LINKS[0][pathname] ? {} : Object.fromEntries(filtered);
  const isInsights = Boolean(LINKS[1][pathname]);
  return (
    <div
      css={css`
        overflow-x: scroll;
        ${noScrollBar}
      `}
    >
      <div
        css={css`
          width: max-content;
          margin-top: 2px;
        `}
      >
        {Object.entries(group)
          .filter(([url, name]) => {
            return !(url === '/spaces' && !feedEnabled);
          })
          .map(([url, name]) => {
            const link = (
              <Link
                className={name === 'Factor analysis' ? 'factor_target' : ''}
                key={name}
                to={`${url}?${qs.stringify(params)}`}
                isActive={(match, location) => location.pathname.includes(url)}
                onClick={e => {
                  if ((url === '/spaces' || url === '/company_structure') && isAdminOnFreeAccount) {
                    e.preventDefault();
                    showUpgradeModal();
                  }
                }}
              >
                {name}
              </Link>
            );
            if (!mapLinksToPlanPermissions.hasOwnProperty(name)) return link;
            return (
              !(isRegularUser && url === '/vibes/detailed_feedback') && (
                <Can
                  perform={mapLinksToPlanPermissions[name]}
                  yes={() => link}
                  no={() => (
                    <InactiveLink
                      onClick={() => {
                        isInsights &&
                          showPrompt({
                            type: 'InsightsPrompt',
                            color: 'yellow',
                          });
                      }}
                    >
                      {name} <Crown />
                    </InactiveLink>
                  )}
                />
              )
            );
          })}
      </div>
    </div>
  );
};
Links.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  isAdminOnFreeAccount: PropTypes.bool.isRequired,
  isRegularUser: PropTypes.bool.isRequired,
  showUpgradeModal: PropTypes.func.isRequired,
};

const mapState = state => ({
  isAdminOnFreeAccount: state.plansDomain.plan === 'free' && state.usersDomain.user.isAccountAdmin,
  isRegularUser:
    !!state.usersDomain.user &&
    !state.usersDomain.user.isAccountAdmin &&
    state.usersDomain.user.formalLeadTeamsIds.length === 0,
  isFreePlan: !!state.plansDomain.plan && state.plansDomain.plan === 'free',
  feedEnabled: state.usersDomain.feedEnabled,
});

const mapDispatch = {
  showUpgradeModal: PLAN_ACTIONS.showUpgradeModal,
  showPrompt: PLAN_ACTIONS.showPrompt,
};

const LinksWithRouter = withRouter(connect(mapState, mapDispatch)(Links));

const TopBar = ({ isFreePlan, resetTours }) => {
  const [open, setOpen] = useState(false);
  const [openOnboarding, setOpenOnboarding] = useState(false);
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    const accessToken = LOCALSTORAGE_GET('access_token');
    const headers = HEADERS(accessToken);
    fetch(getUrl('notifications/'), {
      method: 'get',
      headers,
    })
      .then(response => {
        if (response.status === 401 || response.status === 403) {
          SIGN_OUT();
        }
        return response.json();
      })
      .then(({ notifications: count }) => {
        setNotifications(count || []);
      })
      .catch(console.error);
  }, []);

  const hasUnread = !!notifications.find(notification => notification.state === 'unread');
  return (
    <Wrapper>
      <Content>
        <Right>
          <SearchWithRouter />
          <div
            css={css`
              float: right;
              display: flex;
            `}
          >
            {!isFreePlan && (
              <OnboardingButton
                openOnboarding={openOnboarding}
                setOpenOnboarding={setOpenOnboarding}
                onClick={() => {
                  resetTours();
                  setOpenOnboarding(!openOnboarding);
                }}
              />
            )}
            <Onboarding
              open={openOnboarding}
              onClose={() => {
                const elements = document.getElementsByClassName('wistia_popover_overlay').length;
                if (elements === 0) {
                  setOpenOnboarding(false);
                }
              }}
              setOpenOnboarding={setOpenOnboarding}
              tab="create-post"
            />

            <NotificationButton
              unread={hasUnread}
              onClick={() => {
                setOpen(true);
                if (hasUnread) {
                  const headers = HEADERS();
                  fetch(getUrl('notifications/mark_as_rendered'), {
                    method: 'get',
                    headers,
                  })
                    .then(() => {
                      setNotifications(
                        notifications.map(notification => ({
                          ...notification,
                          state: 'rendered',
                        }))
                      );
                    })
                    .catch(console.error);
                }
              }}
            />
          </div>
        </Right>
        <Switch>
          <Route exact path="/" render={HomeHeader} />
          <Route exact path="/champions" render={BackButton} />
          <Route exact path="/shoutouts" render={ShoutoutsHeader} />
          <Route exact path={`/${growProfileUrl}`} render={() => <GrowHeader title="timeline" />} />
          <Route exact path="/grow_board" render={() => <GrowHeader title="board" />} />
          <Route
            path="/feed"
            render={({ location: { search } }) => {
              const spaceId = +qs.parse(search, { ignoreQueryPrefix: true }).space;
              return <SpaceHeader id={spaceId} />;
            }}
          />
          <Route render={LinksWithRouter} />
        </Switch>
      </Content>
      <Notifications
        open={open}
        notifications={notifications}
        onClose={() => {
          setOpen(false);
        }}
      />
    </Wrapper>
  );
};

export default TopBar;
