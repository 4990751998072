import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Issue from './Issue';
import LeadsWrapTitle from './LeadsWrapTitle';
import SpaceLogo from '../../spaces/SpaceLogo';

class ViewTeamTile extends Component {
  render() {
    const membersCount =
      this.props.teams
        .filter(stateTeam => stateTeam.parent_ids.indexOf(this.props.team.id) > -1)
        .map(stateTeam => stateTeam.users.length)
        .reduce((a, b) => a + b, 0) + this.props.team.users.length;
    const subsCount = this.props.teams.filter(
      stateTeam => stateTeam.parent_id === this.props.team.id
    ).length;

    const classes = ['team-tile'];
    if (
      ((this.props.teams.map(team => team.parent_id).indexOf(this.props.team.id) === -1 ||
        !this.props.team.childrenUnfolded) &&
        !this.props.search) ||
      (this.props.search &&
        !this.props.teams.some(
          team => team.displayInSearch && team.parent_id === this.props.team.id
        ))
    )
      classes.push('hide-horizontal-branch');
    if (
      ((this.props.team.parent_ids.length === 0 && this.props.team.users.length > 0) ||
        this.props.team.users.find(user => user.state === 'pending' || user.state === 'invited')) &&
      this.props.showIssues
    )
      classes.push('show-issues');
    if (this.props.team.searchMatches) {
      classes.push('search-match');
    }

    return (
      <div onClick={() => this.props.showModal(this.props.team)} className={classes.join(' ')}>
        {this.props.teams.filter(team => team.parent_id === this.props.team.id).length > 0 ? (
          <div
            className={`unfold-button ${this.props.team.childrenUnfolded ? 'unfolded' : ''} ${
              this.props.search ? 'nonfunctional' : ''
            }`}
            onClick={e => {
              e.stopPropagation();
              if (!this.props.search) {
                this.props.teamFolding(this.props.team);
              }
            }}
          >
            <span>{subsCount}</span>
          </div>
        ) : (
          ''
        )}
        <div className="team-tile-header">
          <SpaceLogo space={this.props.team} className="team-letters" />
          <span>{this.props.team.name}</span>
        </div>
        <div className="team-info-wrap">
          {`${membersCount} member${membersCount > 1 || membersCount === 0 ? 's' : ''}`} <span />{' '}
          {`${subsCount} sub${subsCount > 1 || subsCount === 0 ? 's' : ''}`}
        </div>
        <div className="issues-wrap">
          {this.props.team.parent_ids.length === 0 && this.props.team.users.length > 0 ? (
            <Issue
              delayTime={400}
              isMounted={this.props.showIssues}
              message={`${this.props.team.users.length} unallocated user${
                this.props.team.users.length > 1 ? 's' : ''
              }`}
            />
          ) : (
            ''
          )}
          {this.props.team.parent_ids.length > 0 &&
          this.props.team.users.filter(user => user.state === 'pending').length > 0 ? (
            <Issue
              delayTime={400}
              isMounted={this.props.showIssues}
              message={`${
                this.props.team.users.filter(user => user.state === 'pending').length
              } uninvited user${
                this.props.team.users.filter(user => user.state === 'pending').length > 1 ? 's' : ''
              }`}
            />
          ) : (
            ''
          )}
          {this.props.team.parent_ids.length > 0 &&
          this.props.team.users.filter(user => user.state === 'invited').length > 0 ? (
            <Issue
              delayTime={400}
              isMounted={this.props.showIssues}
              message={`${
                this.props.team.users.filter(user => user.state === 'invited').length
              } non-activated user${
                this.props.team.users.filter(user => user.state === 'invited').length > 1 ? 's' : ''
              }`}
            />
          ) : (
            ''
          )}
        </div>
        <div
          className={`leads-wrap ${
            this.props.team.team_leads.length ||
            this.props.team.users.some(user => user.is_lead) ||
            (this.props.search !== '' &&
              this.props.team.users.some(
                user =>
                  `${user.first_name.toLowerCase()} ${user.last_name.toLowerCase()}`.indexOf(
                    this.props.search.toLowerCase()
                  ) > -1
              ))
              ? ''
              : 'empty'
          }`}
        >
          <LeadsWrapTitle
            content="Lead"
            isMounted={Boolean(
              this.props.team.users.some(user => user.is_lead) || this.props.team.team_leads.length
            )}
            delayTime={400}
          />
          {[...this.props.team.users.filter(user => user.is_lead), ...this.props.team.team_leads]
            .reduce(
              (users, user) => (users.some(el => el.id === user.id) ? users : [...users, user]),
              []
            )
            .map(user => (
              <Link to={`/user/${user.id}`} className="lead-tile" key={user.id}>
                <img src={user.image_url} />{' '}
                <span>
                  {user.first_name} {user.last_name}
                </span>
              </Link>
            ))}
          <LeadsWrapTitle
            content="Users matching search"
            isMounted={
              this.props.search !== '' &&
              this.props.team.users.some(
                user =>
                  `${user.first_name.toLowerCase()} ${
                    user.last_name ? user.last_name.toLowerCase() : ''
                  }`.indexOf(this.props.search.toLowerCase()) > -1
              )
            }
            delayTime={400}
          />
          {this.props.search !== ''
            ? this.props.team.users
                .filter(
                  user =>
                    `${user.first_name.toLowerCase()} ${
                      user.last_name ? user.last_name.toLowerCase() : ''
                    }`.indexOf(this.props.search.toLowerCase()) > -1
                )
                .map(user => (
                  <Link to={`/user/${user.id}`} className="searched-user-tile" key={user.id}>
                    <img src={user.image_url} />{' '}
                    <span>
                      {user.first_name} {user.last_name}
                    </span>
                  </Link>
                ))
            : ''}
        </div>
      </div>
    );
  }
}

export default ViewTeamTile;
