import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  BlueLine,
  BlueTriangleStyled,
  ChartCenterLine,
  ChartLines,
  CheckBox,
  MembersColumn,
  NameColumn,
  NameColumnContainer,
  OrangeLine,
  OrangeTriangle,
  ScoreChartColumn,
  ScoreColumn,
  TagLabel,
} from './layout';
import DottedLine from '../DottedLine';
import ChartBar from './ChartBar';
import { getScore } from './Score';
import teamShape from '../teamShape';

const Team = ({
  history,
  location,
  id,
  name,
  score,
  previous_score,
  members_count,
  isHierarchical,
  depth,
  teams,
  checkedValues,
  setCheckedValues,
  index,
  setSelectedTeamId,
  isParticipationView,
  redirectToDashboard,
  tagLabel,
  showPrevious,
}) => (
  <>
    <NameColumn isHierarchical={isHierarchical} depth={depth}>
      <NameColumnContainer>
        {tagLabel ? (
          <TagLabel>{name}</TagLabel>
        ) : (
          <>
            {isHierarchical && <DottedLine depth={depth} nextDepth={teams?.[index + 1]?.depth} />}
            <CheckBox
              depth={depth}
              isChecked={checkedValues.includes(id)}
              onClick={() =>
                setCheckedValues(values => {
                  if (values.includes(id)) return values.filter(v => v !== id);
                  return values.length >= 2 ? values : [...values, id];
                })
              }
            />
            {name}
          </>
        )}
      </NameColumnContainer>
    </NameColumn>
    <MembersColumn
      depth={depth}
      isHierarchical={isHierarchical}
      onClick={() => (tagLabel ? {} : setSelectedTeamId(id))}
    >
      {members_count}
    </MembersColumn>
    <ScoreColumn
      depth={depth}
      score={score}
      isHierarchical={isHierarchical}
      isParticipationView={isParticipationView}
      onClick={() => (tagLabel ? {} : redirectToDashboard(history, location, id))}
    >
      {!tagLabel && getScore(score, isParticipationView)}
    </ScoreColumn>
    <ScoreChartColumn>
      <ChartLines>
        <ChartCenterLine />
      </ChartLines>
      {showPrevious && (
        <OrangeLine
          score={teams.find(team => team.depth === 0)?.previous_score}
          isParticipationView={isParticipationView}
          depth={tagLabel ? 1 : depth}
        >
          {depth === 0 && !tagLabel && <OrangeTriangle />}
        </OrangeLine>
      )}
      <BlueLine
        score={teams.find(team => team.depth === 0)?.score}
        isParticipationView={isParticipationView}
        depth={tagLabel ? 1 : depth}
      >
        {depth === 0 && !tagLabel && <BlueTriangleStyled />}
      </BlueLine>
      {!tagLabel && (
        <ChartBar
          depth={depth}
          score={score}
          previous_score={previous_score}
          isParticipationView={isParticipationView}
          onClick={() => (tagLabel ? {} : redirectToDashboard(history, location, id))}
          showPrevious={showPrevious}
        />
      )}
    </ScoreChartColumn>
  </>
);

Team.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  previous_score: PropTypes.number,
  depth: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  members_count: PropTypes.number.isRequired,
  isHierarchical: PropTypes.bool.isRequired,
  isParticipationView: PropTypes.bool.isRequired,
  checkedValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  setCheckedValues: PropTypes.func.isRequired,
  setSelectedTeamId: PropTypes.func.isRequired,
  redirectToDashboard: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(teamShape).isRequired,
  showPrevious: PropTypes.bool.isRequired,
};

Team.defaultProps = {
  tagLabel: false,
  previous_score: null,
};

export default withRouter(Team);
