/** @jsx jsx */
import React, { Component } from 'react';
import { css, jsx } from '@emotion/core';
import { Link, Redirect, withRouter } from 'react-router-dom';

import CreditCard from 'components/payments/CreditCard';
import qs from 'qs';
import { APP_URL, AWS_S3_URL, STRIPE_KEY } from '../../constants';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';
import CvvModal from './PaymentsNew/CvvModal';

class StripeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editToggle: false,
      cardError: '',
      cardNumber: '',
      card: {},
      cardLastFour: '',
      ctaText: '',
      cancelPath: '',
      path: '',
      method: 'post',
      showForm: true,
      redirect: '',
      disableButton: false,
      stripeUpgradePlan: '',
      companyName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      zip: '',
      state: '',
      selectedCountry: '',
      expiry: '',
      showModal: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    window.Stripe.setPublishableKey(STRIPE_KEY);
    this.setState({
      ctaText: this.props.ctaText,
      cancelPath: this.props.cancelPath,
      path: this.props.path,
    });
    this.setState({});
    if (this.props.method) {
      this.setState({ method: this.props.method });
    }
    if (this.props.editToggle) {
      this.setState({ editToggle: this.props.editToggle });
      this.setState({ showForm: false });
    }
    if (this.props.cardLastFour) {
      this.setState({ cardLastFour: this.props.cardLastFour });
    }
    if (this.props.card) {
      this.setState({ card: this.props.card });
    }
    if (this.props.shippingData) {
      if (this.props.shippingData.name) {
        this.setState({ companyName: this.props.shippingData.name });
      }
      if (this.props.shippingData.address) {
        this.setState({
          city: this.props.shippingData.address.city,
          addressLine1: this.props.shippingData.address.line1,
          addressLine2: this.props.shippingData.address.line2,
          zip: this.props.shippingData.address.postal_code,
          state: this.props.shippingData.address.state,
          selectedCountry: this.props.shippingData.address.country,
        });
      }
    }

    this.handleQuery();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.handleQuery();
  }

  handleQuery = () => {
    if (this.state.showForm) return;

    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if (query.hasOwnProperty('editCard')) {
      this.setState({ showForm: true }, () => {
        window.scrollTo(0, this.formRef.current.getBoundingClientRect().top - 200);
      });
    }
  };

  handleSubmit(e) {
    e.preventDefault();
    e.persist();
    window.Stripe.card.createToken(e.currentTarget, (status, result) => {
      if (result.error) {
        this.setState({ cardError: result.error.message });
      } else {
        this.stripeTokenHandler(
          result.id,
          e.target.country.value,
          result.card.country,
          e.target.zip.value,
          e.target.address_line_1.value,
          // e.target.address_line_2.value,
          '',
          e.target.city.value,
          e.target.state.value,
          e.target.company_name.value,
          this.props.planId
        );
      }
    });
  }

  stripeTokenHandler(
    token,
    chosenCountry,
    cardCountry,
    zip,
    addressLine1,
    addressLine2,
    city,
    state,
    companyName,
    plan
  ) {
    this.setState({ ctaText: 'Please wait...' });
    this.setState({ disableButton: true });

    const data = new FormData();
    data.append('token', token);
    data.append('chosen_country', chosenCountry);
    data.append('card_country', cardCountry);
    data.append('address_line_1', addressLine1);
    data.append('address_line_2', addressLine2);
    data.append('city', city);
    data.append('state', state);
    data.append('zip', zip);
    data.append('company_name', companyName);
    data.append('plan', plan);

    const headers = HEADERS();
    fetch(APP_URL + this.state.path, { method: this.state.method, headers, body: data })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          if (jsonResponse.last4) {
            this.setState({ cardLastFour: jsonResponse.last4 });
          }
          if (jsonResponse.stripeUpgradePlan) {
            this.setState({ stripeUpgradePlan: jsonResponse.stripeUpgradePlan });
          }
          if (jsonResponse.cardError) {
            this.setState({ cardError: jsonResponse.cardError });
            this.setState({ ctaText: 'Pay now' });
            this.setState({ disableButton: false });
          }
          if (jsonResponse.redirect) {
            this.setState({ redirect: jsonResponse.redirect });
          }
          if (this.state.editToggle) {
            this.setState({ showForm: !this.state.showForm });
            this.setState({ ctaText: this.props.ctaText });
            this.setState({ disableButton: false });
            this.props.addMessage(jsonResponse.message);
          }
        });
      })
      .catch(() => {});
  }

  changeCardNumber(e) {
    e.preventDefault();
    this.setState({ cardNumber: e.target.value });
    const cardTypes = this.refs.card_types.childNodes;
    const cardType = window.Stripe.card.cardType(e.target.value);
    if (cardType !== 'Unknown') {
      cardTypes.forEach(type => {
        type.classList.remove('active');
      });
      const type = cardType.toLowerCase();
      this.refs[type] && this.refs[type].classList.add('active');
    } else {
      cardTypes.forEach(type => {
        type.classList.remove('active');
      });
    }
  }

  toggleArea(e) {
    e.preventDefault();
    this.setState({ showForm: !this.state.showForm });
    this.props.showTitle();
  }

  handleExpiryChange = e => {
    const date = e.target.value;
    if (!date.match(/\d|^$/)) return;
    const indexOfSlash = this.state.expiry.indexOf('/');
    if (indexOfSlash === -1 && date.match(/^\d{2}$/)) {
      this.setState({ expiry: `${date}/` });
    } else if (indexOfSlash !== -1 && date.match(/^\d{2}$/)) {
      this.setState({ expiry: date.slice(0, -1) });
    } else {
      this.setState({ expiry: date });
    }
  };

  openCvvModal = () => {
    this.setState({ showModal: true });
  };

  closeCvvModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const displayForm = this.state.showForm ? 'block' : 'none';
    const displayArea = !this.state.showForm ? 'block' : 'none';
    if (this.state.redirect !== '') {
      return (
        <Redirect
          push
          to={{
            pathname: `/payments/${this.state.redirect}`,
            state: {
              stripeUpgradePlan: this.state.stripeUpgradePlan,
              cardError: this.state.cardError,
            },
          }}
        />
      );
    }
    return (
      <div>
        {this.state.editToggle && !this.state.showForm ? (
          <CreditCard
            editOnClick={e => this.toggleArea(e)}
            lastFourDigits={this.state.cardLastFour}
            name={this.state.card.name}
            brand={this.state.card.brand}
            css={css`
              max-width: 240px;
              @media (min-width: 890px) {
                float: left;
                margin-right: 36px;
              }
            `}
          />
        ) : (
          ''
        )}
        <form onSubmit={this.handleSubmit} style={{ display: displayForm }} ref={this.formRef}>
          <div className="card-details">
            <div className="payment-errors errors">{this.state.cardError}</div>
            <div className="form-group row u-margin-bottom-none">
              <div className="col-md-8">
                <label htmlFor="card_number">Credit card number</label>
                <input
                  type="text"
                  data-stripe="number"
                  id="card_number"
                  className="form-control stretch"
                  value={this.state.cardNumber}
                  required
                  maxLength={16}
                  onChange={e => this.changeCardNumber(e)}
                />
              </div>
              <div className="col-lg-4 card-types u-margin-top-md" ref="card_types">
                <img
                  src={`${AWS_S3_URL}public/images/cards/Visa2.png`}
                  alt="Visa"
                  style={{ height: '28px' }}
                  className="u-margin-right-xs u-margin-top-xxs"
                  ref="visa"
                />
                <img
                  src={`${AWS_S3_URL}public/images/cards/MasterCard2.png`}
                  alt="MasterCard"
                  style={{ height: '28px' }}
                  className="u-margin-right-xs u-margin-top-xxs"
                  ref="mastercard"
                />
                <img
                  src={`${AWS_S3_URL}public/images/cards/AMEX2.png`}
                  alt="AMEX"
                  style={{ height: '28px' }}
                  className="u-margin-top-xxs"
                  ref="american express"
                />
              </div>
            </div>
            <div className="form-group row u-margin-bottom-none">
              <div className="col-md-8">
                <label htmlFor="card_holder">Name on card</label>
                <input
                  type="text"
                  data-stripe="name"
                  id="card_holder"
                  className="form-control stretch"
                  required
                />
              </div>
            </div>
            <div className="form-group row clearfix">
              <div className="col-xs-7 col-md-4 col-lg-5 ">
                <div className="">
                  <div>
                    <label htmlFor="month">
                      Expiry date <span className="expiry-date-format">MM/YEAR</span>
                    </label>
                  </div>
                  <input
                    type="text"
                    className="form-control u-margin-right-xs"
                    name="expiry"
                    placeholder="MM/YEAR"
                    data-stripe="exp"
                    id="expiry"
                    required
                    onChange={this.handleExpiryChange}
                    value={this.state.expiry}
                    pattern={'\\d{2}\\/\\d{4}$'}
                    maxLength={7}
                  />
                </div>
              </div>
              <div className="col-xs-5 col-md-4 col-lg-3 ">
                <div className="">
                  <div className="cvv">
                    <div className="cvv-label">
                      <label htmlFor="cvv">CVV</label>
                    </div>
                    <div className="cvv-what-is">
                      <a
                        href=""
                        onClick={e => {
                          e.preventDefault();
                          this.openCvvModal();
                        }}
                      >
                        What is CVV?
                      </a>
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    data-stripe="cvc"
                    id="cvv"
                    maxLength={4}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="col-xs-12 no-gutter">
                <h3 id="stripe-address-title">Company address</h3>
              </div>
            </div>
            <div className="form-group row u-margin-bottom-none">
              <div className="col-md-8">
                <label htmlFor="company_name">Company name</label>
                <input
                  type="text"
                  defaultValue={this.state.companyName}
                  name="company_name"
                  id="company_name"
                  className="form-control"
                  required
                />
              </div>
            </div>
            <div className="form-group row u-margin-bottom-none">
              <div className="col-md-8">
                <label htmlFor="address_line_1">Address line 1</label>
                <input
                  type="text"
                  defaultValue={this.state.addressLine1}
                  name="address_line_1"
                  id="address_line_1"
                  className="form-control"
                  required
                />
              </div>
            </div>
            <div className="form-group row u-margin-bottom-none">
              <div className="col-md-8">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  defaultValue={this.state.city}
                  name="city"
                  id="city"
                  className="form-control"
                  required
                />
              </div>
            </div>
            <div className="form-group row u-margin-bottom-none">
              <div className="col-xs-12 col-md-5">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  defaultValue={this.state.state}
                  className="form-control"
                />
              </div>
              <div className="col-xs-12 col-md-3">
                <label htmlFor="zip">Postcode / ZIP</label>
                <input
                  type="text"
                  name="zip"
                  id="zip"
                  defaultValue={this.state.zip}
                  className="form-control"
                  required
                />
              </div>
            </div>
            <div className="form-group row u-margin-bottom-none">
              <div className="col-md-8">
                <label htmlFor="country">Country</label>
                <select
                  name="country"
                  id="country"
                  required
                  value={this.state.selectedCountry}
                  onChange={event => {
                    this.props.selectCountry && this.props.selectCountry(event.target.value);
                    return this.setState({ selectedCountry: event.target.value });
                  }}
                  className="form-control"
                >
                  <option value="">&nbsp;</option>
                  {this.props.countries && this.props.countries.map((country, index) => (
                      <option key={index} value={country.code}>
                        {country.name}
                      </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row u-margin-bottom-none">
              <div className="col-md-8">
                {this.state.editToggle ? (
                  <Link
                    to="/"
                    id="card-summary"
                    className="u-margin-right-xs"
                    onClick={e => this.toggleArea(e)}
                  >
                    Cancel
                  </Link>
                ) : null}
                <input
                  type="submit"
                  className="btn btn-primary btn-block"
                  id="submit-payment"
                  name="commit"
                  disabled={this.state.disableButton}
                  value={this.state.ctaText}
                />
              </div>
            </div>
          </div>
        </form>
        <CvvModal handleClose={this.closeCvvModal} show={this.state.showModal} />
      </div>
    );
  }
}

export default withRouter(StripeForm);
