import styled from '@emotion/styled';
import { jsx, css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import listArrow from './listArrow.svg';

const Header = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #6a6a6a;
  margin-top: 10px;
  margin-bottom: 14px;
`;

const ActionWrap = styled.div`
  color: #11a9ff;
  font-size: 13px;
  line-height: 28px;
  margin-left: 2px;
`;

const Action = styled.div`
  cursor: pointer;
`;

const ListArrow = styled.img`
  margin-right: 12px;
`;

const Actions = ({ actions }) => (
  <ActionWrap>
    {actions.map(
      ({ label, onClick, visible = true }, index) =>
        visible && (
          <Action onClick={onClick} key={index}>
            <ListArrow src={listArrow} alt="" />
            {label}
          </Action>
        )
    )}
  </ActionWrap>
);

Actions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      visible: PropTypes.bool,
    }).isRequired
  ).isRequired,
};

const Filter = styled.div`
  color: #6a6a6a;
  font-size: 13px;
  line-height: 27px;
  cursor: pointer;
  ${props =>
    props.selected &&
    css`
      color: #11a9ff;
      font-weight: bold;
    `}
`;

const FilterCount = styled.div`
  display: inline-block;
  font-weight: bold;
  font-size: 11px;
  line-height: 18px;
  width: 23px;
  text-align: center;
  color: #c4c4c4;
  background: #ffffff;
  border-radius: 14px;
  margin-left: 5px;
`;

const Filters = ({ filters, selectedKey, onChange }) =>
  filters.map(({ label, count, key }) => (
    <Filter selected={key === selectedKey} onClick={() => onChange(key)} key={key}>
      {label}
      <FilterCount>{count}</FilterCount>
    </Filter>
  ));

Filters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  selectedKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Sidebar = { Header, Actions, Filters };
export default Sidebar;
